import { formatDate } from '@angular/common';
import { SecurityManagerService } from "./../../../services/security-manager.service";
import { Component, OnInit, HostListener, Input, ViewChild, OnDestroy, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService, PatientService, RxService } from "../../../services";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CollectionView, SortDescription } from "@grapecity/wijmo";
import * as moment from "moment";
import { SearchTrackPatient } from "src/app/models/track-patient.model";
import { CommonUtil, RxUtils } from "src/app/utils";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { RxModel } from "src/app/models";
import * as wjcCore from  '@grapecity/wijmo';
import { Subject, Subscription, forkJoin } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RphVerifyHistComponent } from "../../rph-verification/action-buttons/rph-verify-hist/rph-verify-hist.component";
import { CommonDrugpickVerfHistComponent } from '../../shared/common-drugpick-verf-hist/common-drugpick-verf-hist.component';
import { Verificationsenum } from 'src/app/models/Verifications.enum';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: "app-track-rx",
    templateUrl: "./track-rx.component.html"
})
export class TrackRxComponent implements OnInit, OnDestroy {
    rxInfo: any;
    prescNumber: number;
    search: SearchTrackPatient;
    totalCount: number;
    pageCount: number;
    pageNumber = 1;
    pageSize = 15;
    trackPatientWJ: CollectionView;
    activeHeaders: any;
    dateVal: any;
    hideFilterForm = true;
    searchFG: FormGroup;
    patientInfo: any;
    hasLogs: boolean;
    routeFrom: string;
    today: any;
    minDate: { year: number; month: number; day: number };
    yesterday: Date;
    childItem: { Field: any; NewValue: any; OldValue: any; UpdatedBy: any };
    expandedRow: any;
    isAnimated = true;
    auditData: any;
    drugpickData = null;
    rphData = null;
    unsubscribe$ : Subject<void> = new Subject();
    labelPrintData = [];
    groupFG: FormGroup;

    @ViewChild("flex", { static: true }) flex: wjcGrid.FlexGrid;
    pickUpDet: any;
    prscRfId: number;
    editRxData: any;
    matchedValue = 0;
    actvHeaders: string[];
    focus: string;
    rxRecdBy: any;
    createdDate: any;
    modifiedDate: any;
    groupByNames = ["Function"];
    rxRecivedData: any;
    rxRecvDate: any;
    rxData: any;
    rphInfoData: any;
    isRxVerification = false;
    IsHistory = false;
    dphVerifHistory: any;
    drugPickVerfStatus: any;
    serviceStack = [];

    @Input() set RxInfo(ri: RxModel) {
        // if (ri["pickupRx"]) {
        //     if (ri["pickupRx"]["IsPickedUp"]) {
        //     this.pickUpDet = ri["pickupRx"];
        //     }
        // }
        if (ri && ri.Prescription) {
            this.rxInfo = ri;
            this.prscRfId = ri.PrescReFill.Id;
            this.prescNumber = +ri.Prescription.PrescNum;
            this.search.entityId = this.prescNumber;
            // this.search.Initail = this.prescNumber;
            this.getTrackPatientLogs();
        }
    }

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && this._modalService.hasOpenModals()) {
             if (event.which === 67) { // c
                 event.preventDefault();
                this.previousPage();
            }
        }
    }


    constructor(
        private _actvRoute: ActivatedRoute,
        private _fb: FormBuilder,
        private _secuManager: SecurityManagerService,
        private _commonServ: CommonService,
        private _rxUtils: RxUtils,
        private _rxService: RxService,
        private _route: Router,
        private _modalService: NgbModal,
        private _cmnUtil: CommonUtil
    ) {
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.today = moment().format("MM/DD/YYYY");
        const today = new Date();
        this.yesterday = new Date(today.setDate(today.getMonth() - 3));
        this.searchFG = this._fb.group({
            LogDtTm: null,
            ToDtTm: null
        });
        this.clear();

        this.search = {
            Document: null,
            Function: null,
            FromLogDate: null,
            ToLogDate: null,
            Initail: null,
            OverrideInitial: null,
            PageSize: null,
            PageNumber: null,
            entityId: Number(this.prescNumber)
        };
    }

    ngOnInit() {
        this.show();
        this.createFG();
        if(this.rxInfo && this.rxInfo["rxType"]){
            this.routeFrom = this.rxInfo["rxType"];
        }
    }

    async getTrackPatientLogs() {
        this.serviceStack.push(this._rxService.getPickupDetails(this.prscRfId));
        this.getLabelPrintAudit();
        this.getRxRecivedAudit();
        this.getRxAudit();
        this.drugpickData = this.rxInfo["drugPickVerif"];
        if (this.drugpickData && this.drugpickData.length > 0) {
            this.drugPickVerfStatus = (((["p", "P", "P ", "p "].indexOf(this.drugpickData[this.drugpickData.length - 1].Result) > -1) && !this.drugpickData[this.drugpickData.length - 1].IgnoreInventoryCalculations) || (((this.drugpickData.drugPickVerificationStatus)&&["p", "P", "p ", "P "].indexOf(this.drugpickData.drugPickVerificationStatus) > -1) && this.drugpickData.PrescReFill.IsIou))
             ? (!this.drugpickData[this.drugpickData.length - 1].IsReverified ? Verificationsenum.verified : Verificationsenum.reverified) :
             this.drugpickData[this.drugpickData.length - 1].Result && this.drugpickData[this.drugpickData.length - 1].Result.trim().toLowerCase() === "rv" ? "Re-verify" : "Not Verified";
            }
        this.rphData = this.rxInfo["rPHVerifs"];
        if (this.rphData && this.rphData.length) {
            this.rxData = this.rphData.filter(v => ((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"]));
            this.rphInfoData = this.rphData.filter(v => !((v["Status"]).trim().toLowerCase() === "r" || v["IsForRxVerification"]));
        }
    }

    async getLabelPrintAudit() {
        const search = {
            Document: "Rx",
            Function: "PrintLabel",
            FromLogDate: null,
            ToLogDate: null,
            PageSize: null,
            PageNumber: null,
            entityId: Number(this.rxInfo.PrescReFill.Id)
        };
        this.serviceStack.push(this._secuManager.getAuditLog(search,true));
    }

    async getRxRecivedAudit() {
        const search = {
            Document: "Rx",
            Function: "Rx Received",
            FromLogDate: null,
            ToLogDate: null,
            PageSize: null,
            PageNumber: null,
            entityId: Number(this.rxInfo.PrescReFill.Id)
        };
        this.serviceStack.push(this._secuManager.getAuditLog(search,true));
    }
    async getRxAudit() {
        const search = {
            Document: "Rx",
            Function: "EditRx,DME,WCOM,MiscRxInfo,DUR,ClinicalInfo,PickUpInfo,AdditionalInsuranceAdd,PayRecords,PrintLabel,Notes,Documents",
            FromLogDate: null,
            ToLogDate: null,
            PageSize: null,
            PageNumber: null,
            entityId: Number(this.rxInfo.PrescReFill.Id)
        };
        this.serviceStack.push(this._secuManager.getAuditLog(search,true));
        this.endpointJoin();
    }

    
    async endpointJoin(){
        const resp = await forkJoin(this.serviceStack).toPromise();
        if(resp && resp.length > 0) {
        this.pickUpDet = resp[0];
        const labelprintData = resp[1];
        this.labelPrintData = labelprintData ? labelprintData["AuditViewModel"] : null;
        const rxRecivedData = resp[2];
        const recvby = rxRecivedData && rxRecivedData["AuditViewModel"] && rxRecivedData["AuditViewModel"].length > 0
         ? rxRecivedData["AuditViewModel"][0] : null;
         if (recvby && recvby["delta"] && recvby["delta"]["changed"]) {
            this.rxRecivedData = recvby["delta"]["changed"].find(v => v["name"] === "Received By");
            this.rxRecvDate = recvby;
         } else {
            this.rxRecivedData = null;
            this.rxRecvDate = null;
         }
         this.auditData = resp[3];
         this.generateTrackPatWJ(this.auditData);
        }
    }

    createFG() {
        this.groupFG = this._fb.group({
            columnGrp: [],
            dateGrp: [],
            userGrp: []
        });
    }
    generateTrackPatWJ(data) {
        const datavalue = [];
        if (data && data.AuditViewModel.length > 0) {
            data.AuditViewModel.reverse();
            data.AuditViewModel.map((Value) => {
                if (Value && Value.delta && Value.delta.changed && Value.delta.changed.length && Value.delta.original && Value.delta.original.length) {
                    if (Value.delta.original.length > Value.delta.changed.length) {
                            Value.delta.original.map((audit, i) => {
                                const oldVal = audit.value;
                                const newVal = (Value.delta.changed && Value.delta.changed[i]) ? Value.delta.changed[i].value : "";
                                const k = {};
                                k["Function"] = Value.action;
                                k["Column"] = audit.name;
                                k["OldValue"] = oldVal ? (this._cmnUtil.checkDateTimeFields(oldVal) ?
                                (audit.name === "Measure Time" || audit.name === "Picked Up Time" ? moment.utc(oldVal).local().format("HH:mm:ss") :
                                 moment.utc(oldVal).local().format("MM/DD/YYYY")) : (audit.name === "Is Shown As Popup?" ? (oldVal ? "YES" : "NO"): oldVal)) : (audit.name === "Is Shown As Popup?" ? (oldVal ? "YES" : "NO"): oldVal);
                                k["NewValue"] = newVal ? (this._cmnUtil.checkDateTimeFields(newVal) ?
                                (audit.name === "Measure Time"|| audit.name === "Picked Up Time" ? moment.utc(newVal).local().format("HH:mm:ss") :
                                 moment.utc(newVal).local().format("MM/DD/YYYY")) : (audit.name === "Is Shown As Popup?" ? (newVal ? "YES" : "NO"): newVal)) : (audit.name === "Is Shown As Popup?" ? (newVal ? "YES" : "NO"): newVal);
                                k["DateTime"] = Value.created;
                                k["By"] = Value.username;
                                datavalue.push(k);
                        });
                    } else {
                        Value.delta.changed.map((audit, i) => {
                            const oldVal = (Value.delta.original && Value.delta.original.length &&  Value.delta.original[i]) ? Value.delta.original[i].value : "";
                            const newVal = audit.value;
                            const k = {};
                                k["Function"] = Value.action;
                                k["Column"] = audit.name;
                                k["OldValue"] = oldVal ? (this._cmnUtil.checkDateTimeFields(oldVal) ?
                                (audit.name === "Measure Time" || audit.name === "Picked Up Time"? moment.utc(oldVal).local().format("HH:mm:ss") :
                                 moment.utc(oldVal).local().format("MM/DD/YYYY")) : (audit.name === "Is Shown As Popup?" ? (oldVal ? "YES" : "NO"): oldVal)) : (audit.name === "Is Shown As Popup?" ? (oldVal ? "YES" : "NO"): oldVal);
                                k["NewValue"] = newVal ? (this._cmnUtil.checkDateTimeFields(newVal) ?
                                (audit.name === "Measure Time" || audit.name === "Picked Up Time"? moment.utc(newVal).local().format("HH:mm:ss") :
                                 moment.utc(newVal).local().format("MM/DD/YYYY")) : (audit.name === "Is Shown As Popup?" ? (newVal ? "YES" : "NO"): newVal)) : (audit.name === "Is Shown As Popup?" ? (newVal ? "YES" : "NO"): newVal);
                                k["DateTime"] = Value.created;
                                k["By"] = Value.username;
                                datavalue.push(k);
                    });
                }
                } else if (Value && Value.delta && Value.delta.original && Value.delta.original.length) {
                    Value.delta.original.map((audit, i) => {
                                const oldVal = audit.value;
                                const newVal = (Value.delta.changed && Value.delta.changed[i]) ? Value.delta.changed[i].value : "";
                                const k = {};
                                k["Function"] = Value.action;
                                k["Column"] = audit.name;
                                k["OldValue"] = oldVal ? (this._cmnUtil.checkDateTimeFields(oldVal) ?
                                (audit.name === "Measure Time" || audit.name === "Picked Up Time"? moment.utc(oldVal).local().format("HH:mm:ss") :
                                 moment.utc(oldVal).local().format("MM/DD/YYYY")) : (audit.name === "Is Shown As Popup?" ? (oldVal ? "YES" : "NO"): oldVal)) : (audit.name === "Is Shown As Popup?" ? (oldVal ? "YES" : "NO"): oldVal);
                                k["NewValue"] = newVal ? (this._cmnUtil.checkDateTimeFields(newVal) ?
                                (audit.name === "Measure Time" || audit.name === "Picked Up Time"? moment.utc(newVal).local().format("HH:mm:ss") :
                                 moment.utc(newVal).local().format("MM/DD/YYYY")) : (audit.name === "Is Shown As Popup?" ? (newVal ? "YES" : "NO"): newVal)) : (audit.name === "Is Shown As Popup?" ? (newVal ? "YES" : "NO"): newVal);
                                k["DateTime"] = Value.created;
                                k["By"] = Value.username;
                                datavalue.push(k);
                    });
                } else if (Value && Value.delta && Value.delta.changed && Value.delta.changed.length) {
                    Value.delta.changed.map((audit, i) => {
                                const newVal = audit.value;
                                const boolVal=typeof(newVal) === "boolean" ? (newVal ? "YES" : "NO") : null
                                const k = {};
                                k["Function"] = Value.action;
                                k["Column"] = audit.name;
                                k["OldValue"] = "";
                                k["NewValue"] = newVal ? (this._cmnUtil.checkDateTimeFields(newVal) ?
                                (audit.name === "Measure Time" || audit.name === "Picked Up Time"? moment.utc(newVal).local().format("HH:mm:ss") :
                                 moment.utc(newVal).local().format("MM/DD/YYYY")) : (boolVal ? boolVal : newVal)) : (boolVal ? boolVal : newVal);
                                k["DateTime"] = Value.created;
                                k["By"] = Value.username;
                                datavalue.push(k);
                    });
                }
            });
            const sortDesc = new SortDescription("DateTime", false);
            this.trackPatientWJ = new CollectionView(
                datavalue.map(audit => {
                    const k = {};
                    k["Function"] = audit.Function;
                    k["Column"] = audit.Column;
                    k["Old Value"] = audit.OldValue || audit.OldValue === 0 ? audit.OldValue.toString() : null;
                    k["New Value"] = audit.NewValue || audit.NewValue === 0 ? audit.NewValue.toString() : null;
                    k["DateTime"] = audit.DateTime ? moment(audit.DateTime).format("MM/DD/YYYY HH:mm:ss") : "";
                    k["By"] = audit.By;
                    return k;
                }),
                {
                    groupDescriptions: this.groupByNames,
                    sortDescriptions: [sortDesc],
                    sortComparer: (a: any, b: any) => {
                        return moment(a).isValid() && moment(b).isValid()
                            ? moment(a).diff(moment(b)) : null;
                    }
                });
        }
        this.actvHeaders = [
            "Column",
            "Old Value",
            "New Value",
            "DateTime",
            "By"
        ];
    }
    // setDueDateTimeVals(dueDateTime) {
    //     return (!(["", null, "null"].includes(dueDateTime))) ? moment.utc(dueDateTime).local().format("MM/DD/YYYY") : "";
    // }

    getGridItem(value) {
        this.expandedRow = value;
        return value.items;
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.search.PageNumber = this.pageNumber;
        this.getTrackPatientLogs();
    }
    setPageSize(size: number) {
        this.pageSize = size;
        this.search.PageSize = size;
        this.getTrackPatientLogs();
    }

    getFilteredData() {
        this.show();
        const searchData = this.searchFG.value;
        this.search.FromLogDate = this.searchFG.value["LogDtTm"];
        this.search.ToLogDate = this.searchFG.value["ToDtTm"];
        this.getTrackPatientLogs();
    }

    show() {
        this.hideFilterForm = !this.hideFilterForm;
    }

    cancel() {
        // TODO:
        // this._rxUtils.updateLatestRxWithEditPatInfo(
        //     this.patientId,
        //     this.routeFrom
        // );
    }

    loadedRows(grid) {
        grid.collapseGroupsToLevel(0);
    }

    /*resize(grid) { // not in use
        setTimeout(() => {
            grid.autoSizeRow(this.expandedRow + 1, false);
        }, 50);
    }*/

    init(flex: wjcGrid.FlexGrid) {
        // flex.collapseGroupsToLevel(0);
    }

    clear() {
        this.searchFG.controls["LogDtTm"].patchValue(this.today);
        this.searchFG.controls["ToDtTm"].patchValue(this.today);
    }

    previousPage() {
        // this.subscriptions.add(
        //     this._actvRoute.queryParams.subscribe(params => {
        //         if (params && params.fm) {
        //             if (params.fm === "er") {
        //                 this._route.navigate(["eprx/rx"], {
        //                     queryParams: { fm: params.fm }
        //                 });
        //             }
        //         }
        //     })
        // );
        this.IsPopUpClosed.emit("Patient1");
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    WijimoGroupBy() {
        const groupByNames = ["Function"];
        const cntrlName = [{ cntrlN: "columnGrp", headN: "Column" },
         { cntrlN: "dateGrp", headN: "DateTime" }, { cntrlN: "userGrp", headN: "By" }];
        cntrlName.map(val => {
            if (this.groupFG.value[val["cntrlN"]]) {
                groupByNames.push(val["headN"]);
            }
        });
        this.groupByNames = groupByNames;
        this.matchedValue = 0;
        this.generateTrackPatWJ(this.auditData);
        if(this.flex)
            this.flex.collapseGroupsToLevel(0);
    }

    loadedWJRows(flex: wjcGrid.FlexGrid) {
        flex.collapseGroupsToLevel(0);
    }

    // checkDateTimeFields(value) {
    //     if ((moment(value, "MM-dd-yyyy", true).isValid()) || (moment(value, "yyyy-MM-dd", true).isValid())
    //         || (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSSSS", true).isValid())
    //         || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ", true).isValid()) || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid())) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    openVerfHist(val) {
        const modelRef = this._modalService.open(RphVerifyHistComponent, {
            centered: true,
                backdrop: false,
                keyboard: false
            });
        modelRef.componentInstance.RxInfo = this.rxInfo;
        modelRef.componentInstance.IsRxVerification = val ? true : false;
        modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    modelRef.close();
                });
    }

    openDrugVerfHist() {
            if (this.rxInfo) {
                this.dphVerifHistory = this.rxInfo.drugPickVerif ? [...this.rxInfo.drugPickVerif] : [];
                if (this.dphVerifHistory &&  this.dphVerifHistory.length)  {
                    this.dphVerifHistory = this.dphVerifHistory.sort((a, b) => {
                        return <any>new Date(b.VerifDtTm) - <any>new Date(a.VerifDtTm);
                    });
                this.dphVerifHistory.map(elm => {
                    elm["PrescNum"] = this.rxInfo.Prescription.PrescNum;
                    elm["RefillNum"] = this.rxInfo.PrescReFill.ReFillNum;
                    elm["PartialfillNum"] = this._commonServ.checkIsPartialFill(this.rxInfo.PrescReFill, this.rxInfo.Prescription) ? this.rxInfo.PrescReFill.PartialFillNo : " ";
                });
            }
                const modelRef = this._modalService.open(CommonDrugpickVerfHistComponent,
                    { backdrop: "static", size: "lg", centered: true });
                modelRef.componentInstance.DPVVerfHist = this.dphVerifHistory;
                modelRef.componentInstance.isPopup = true;
                modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        modelRef.close();
                });
        }
    }
}
