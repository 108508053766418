import { Component, OnInit, ViewChild, Input, HostListener, ElementRef, AfterViewInit, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { CommonService, AlertService } from "../../../services";
import { BucketService } from "../../../services/bucket.service";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DrugPriceCheckService } from "src/app/services/drug-price-check.service";
import * as wjcCore from "@grapecity/wijmo";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { RxStore } from "src/app/store";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import { FormatsUtil } from "src/app/utils/formats.util";
import { PaginationModel } from "src/app/models/elastc-rx.model";
import { CommonWarnorconfirmPopupComponent } from "../common-warnorconfirm-popup/common-warnorconfirm-popup.component";

@Component({
    selector: "app-low-inv-buck-trans",
    templateUrl: "./low-inv-buck-trans.component.html"
})
export class LowInvBuckTransComponent implements OnInit, AfterViewInit {
    drugBucketList: any;
    drugList: any[];
    selectdDrug: any;
    drugQnty: any;
    buck1drugQnty: any;
    buck2drugQnty: any;
    selectdBuck1: any;
    selectdBuck2: any;
    BucketTransFormGroup: FormGroup;
    WarningMessage = "";
    SuggestData: any;
    titleText = "Bucket Transfer";
    drugEqs: any;
    DrugEqWJ: any;
    eqDrugHeaders: string[];
    BucketWJ: wjcCore.CollectionView;
    bucketHeaders: string[];
    showContent = "lowInv";
    changedDrug: "";
    initContent: string;
    focusTo: string;
    unsubscribe$: Subject<void> = new Subject();
    errorMessage: string;

    @ViewChild("warningContent", { static: true })
    private WarningModal;
    drugInfo: any;
    buckInfo: any;
    bucketId: number;

    // edit bucket vars

    EditBucketrmGrp: FormGroup;
    BucketHistWJ: any;
    bucketHistHeaders: string[];
    bucketTypeDData: any[];
    // specificTypeDData: any[];
    bucketCatDropData: any[];
    bucketPriceDropData: any[];

    modalChangeTrack: string[] = [];
    orderQnty: number;
    qntyInhand: number;
    BucketList: any[] = [];
    eqDrugList: any[] = [];
    invSelctdBuck: any;
    invSelctdDrug: any;
    isBucketExists: boolean;
    isBucketNameExists: boolean;
    selectdBuckCode: any;
    selectBuckName: any;
    isDrugFocused = false;
    disableTransfer = false;
    totalCount: number;
    
    paginationModel: PaginationModel = new PaginationModel();
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    // HiststartIndex = 0;

    @ViewChild("drugSearch")
    drugSearch: ElementRef;
    modalRef: any;
    selectedDrugName = true;
    buckdrugName: any;
    eqDrugWJ: wjcCore.CollectionView;
    actvHeaders: string[];
    equListWJ: wjcGrid.FlexGrid;
    _wijHeight: string;
    @ViewChild("ERRORMSG", { static: true })
    ERRORMSG: any;
    prevQtyEnter: number;
    warnConfirmModel: any;

    @Input()
    set DrugInfo(pi: any) {
        this.drugInfo = pi;
    }

    @Input()
    set BucketInfo(bi: any) {
        this.buckInfo = bi;
    }

    @Input()
    set BucketId(buckId: number) {
        this.bucketId = buckId;
    }

    @Input()
    set OrderQnty(OQ: number) {
        this.orderQnty = OQ;
    }

    @Input()
    set QntyInHand(QH: number) {
        this.qntyInhand = QH;
    }

    @Input()
    set ModalType(sm: string) {
        this.showContent = sm;
        this.initContent = sm;
    }

    @Input() bucketDropsData: any;

    @Input() invntryFm: any;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @ViewChild("BucketHistoryGrid")
    flex: wjcGrid.FlexGrid;
    // @HostListener("window:keydown", ["$event"])
    // keyEventEsc(event: KeyboardEvent) {
    //     if (event.which === 27 && this.modalRef && this._modalService.hasOpenModals()) { // esc
    //         this.modalRef.close();
    //         this.modalRef = null;
    //         event.preventDefault();
    //     }
    // }
    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.which === 27 && this._modalService.hasOpenModals()) { // esc
            if (this.modalRef) {
                this.modalRef.close();
                event.preventDefault();
            } else {
                this.backToprev();
                this.modalRef = null;
                event.preventDefault();
            }
        }
    }

    constructor(
        public activeModal: NgbActiveModal,
        private _fb: FormBuilder,
        private _modalService: NgbModal,
        private _commonServ: CommonService,
        private _BucketSer: BucketService,
        private _router: Router,
        private _alertService: AlertService,
        private _drugPriceCheckService: DrugPriceCheckService,
        private _rxStore: RxStore,
        private _formUtils: ValidateFormFieldsUtils,
        private _buckUtils: DrugBucketUtil,
        public _formatsUtil:FormatsUtil
    ) {
         this._commonServ.priceSchedule$
         .pipe(takeUntil(this.unsubscribe$))
         .subscribe(resp => {
            if (resp) {
                this.bucketPriceDropData = resp["PriceScheduleList"];
            }
        });

        this.EditBucketrmGrp = this._fb.group({
            Id: [],
            Code: [],
            Name: [],
            Type: [],
            IsDefault: [],
            IsBucketPriceUsed: [false],
            PriceSchId: [],
            TotalValue: [],
            TotalItems: [],
            CreatedDtTm: [],
            pageNumber: this.paginationModel.pageNumber,
            pageSize: this.paginationModel.pageSize
        });
    }

    ngOnInit() {
        this.eqDrugHeaders = [
            "Is Pref",
            "Drug",
            "NDC",
            "Manufacturer",
            "Size",
            "AWP",
            "Avail Qty",
            "Bucket",
            "Disp Date"
        ];
        this.bucketHeaders = ["Bucket Name", "Qty in Hand"];
        if (this.showContent === "lowInv") {
            this.titleText = "Low Inventory";
            this.selectdDrug = this.drugInfo;
            this.getEquivalentDrugs(this.drugInfo.id);
            this.BucketList = this._rxStore.rxBucket$["source"]["value"];
            this.buckInfo = this.BucketList.find(val => val["Id"] === this.bucketId);
        } else if (this.showContent === "editBuck") {
            this.titleText = "Edit Bucket";
            this.getDropdownsData();
        }
        this.getDrugBucketList();
        this.createNPatchFG();
    }
    createNPatchFG() {
        this.BucketTransFormGroup = this._fb.group({
            Drug: null,
            DrugQnty: null,
            Bucket1: null,
            Buck1Qnty: null,
            Buck1TransQnty: null,
            Bucket2: null,
            Buck2Qnty: null,
            Buck2TransQnty: null
        });
    }
    ngAfterViewInit() {
        // if (this.drugSearch && this.drugSearch["searchBox"]) {
        //     this.drugSearch["searchBox"].nativeElement.focus();
        // }
        setTimeout(() => {
            this.isDrugFocused = true;
        }, 10);
    }

    get Drug(): FormControl {
        return this.BucketTransFormGroup.get("Drug") as FormControl;
    }


    async getDrugBucketlistAndPatch(Id?) {
        const resp = await this._BucketSer.getBucketsForDropdowns(Id ? Id : 0).toPromise();
        if (resp) {
            this.drugBucketList = resp;
        } else {
            this.drugBucketList = null;
        }
    }

    getDrugBucketList() {
        this._BucketSer.getBucketsForDropdowns()
        .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.drugBucketList = resp;
                }
            });
    }

  async  optedDrug(data: any) {
        if (data) {
            this.createNPatchFG();
            this.BucketTransFormGroup.controls.Drug.patchValue(data.id);
            this.selectdDrug = data;
            this.selectedDrugName = data.drugname;
            const resp = await this._BucketSer.getDrugQTYbyId(data.id).toPromise();
            this.drugQnty = resp;
            this.BucketTransFormGroup.controls.DrugQnty.patchValue(resp);
            if (resp > 0) {
                this.disableTransfer = false;
                setTimeout(() => {
                    if (document.getElementById("bucket1Id")) {
                        document.getElementById("bucket1Id").focus();
                    }
                }, 100);
            } else {
                this.disableTransfer = true;
            }
            this.getDrugBucketlistAndPatch(data.id);
        } else {
            this.drugBucketList = null;
            this.selectdDrug = null;
            this.drugQnty = null;
            this.selectedDrugName = false;
            this.disableTransfer = false;
            this.createNPatchFG();
            this.BucketTransFormGroup.markAsTouched();
            setTimeout(() => {
                this.BucketTransFormGroup.markAsUntouched();
                this.BucketTransFormGroup.updateValueAndValidity();
            }, 100);
            // this.buck1drugQnty = null;
            // this.selectdBuck1 = null;
            // this.buck2drugQnty = null;
            // this.selectdBuck2 = null;
        }

    }

  async  optedBucket1(bucketId: any) {
        if (this.BucketTransFormGroup.value.Bucket2 && this.BucketTransFormGroup.value.Bucket2 === bucketId.Id) {
            this.errorMessage = "Please select Another Bucket.";
            this.focusTo = "bucket1";
            this.BucketTransFormGroup.patchValue({
                Bucket1: null,
                Buck1TransQnty: null,
                Buck2TransQnty: null
            });
            this._modalService.open(this.ERRORMSG, { centered: true , keyboard: false, backdrop: false,
                                    windowClass: "large--content"});
        } else if (bucketId && bucketId.Id && !this.checkInvMangOrNot(bucketId.Id)) {
            this.errorMessage = " Inventory for  " + (this.selectedDrugName  + " ").toUpperCase() +
                 (this.selectdDrug.strength || "" + " ").toUpperCase() + (this.selectdDrug.drugformname || "" + " ").toUpperCase() +
                  "  drug has not been managed before on " +
                this.buckInfo.Name.toUpperCase() + " bucket. Please choose another bucket.";
            this.focusTo = "bucket1";
            this._modalService.open(this.ERRORMSG, { centered: true, keyboard: false, backdrop: false,
                                    windowClass: "large--content"});
            this.BucketTransFormGroup.patchValue({
                Bucket1: null,
                Buck1TransQnty: null,
                Buck2TransQnty: null
            });

        } else if (bucketId && (!this.BucketTransFormGroup.value.Bucket2 || (this.BucketTransFormGroup.value.Bucket2 !== bucketId.Id))) {
            this.BucketTransFormGroup.controls.Bucket1.patchValue(bucketId.Id);
            const resp = await this._BucketSer.getDrugQTYinHand(this.selectdDrug.id, bucketId.Id).toPromise();
                    if (resp) {
                        this.BucketTransFormGroup.patchValue({
                            Buck1Qnty: resp,
                            Buck1TransQnty: null,
                            Buck2TransQnty: null
                        });
                        document.getElementById("Buck1TransQnty").focus();
                    } else {
                        this.BucketTransFormGroup.patchValue({
                            Buck1Qnty: 0,
                            Buck1TransQnty: null,
                            Buck2TransQnty: null
                        });
                        document.getElementById("Buck1TransQnty").focus();
                    }
        }

    }

    checkInvMangOrNot(Id: number): boolean {
        this.buckInfo = this.drugBucketList.find(list => list.Id === Id);
        if (this.buckInfo && !this.buckInfo.IsInvManaged) {
            return false;
        }
        return true;
    }

    async optedBucket2(bucketId: any) {
        if (this.BucketTransFormGroup.value.Bucket1 && this.BucketTransFormGroup.value.Bucket1 === bucketId.Id) {
            this.errorMessage = "Please select Another Bucket.";
            this.focusTo = "bucket2";
            this.BucketTransFormGroup.controls.Bucket2.patchValue(null);
           this._modalService.open(this.ERRORMSG, { centered: true , keyboard: false, backdrop: false,
                                    windowClass: "large--content"});
        } else if (bucketId && bucketId.Id && !this.checkInvMangOrNot(bucketId.Id)) {
            this.errorMessage = " Inventory for  " + (this.selectedDrugName + " ").toUpperCase() +
                (this.selectdDrug.strength || "" + " ").toUpperCase() + (this.selectdDrug.drugformname || "" + " ").toUpperCase() +
                "  drug has not been managed before on " +
                this.buckInfo.Name.toUpperCase() + " bucket. Please choose another bucket.";
            this.focusTo = "bucket2";
            this._modalService.open(this.ERRORMSG, { centered: true, keyboard: false, backdrop: false,
                                    windowClass: "large--content"});
            this.BucketTransFormGroup.controls.Bucket2.patchValue(null);
        } else if (bucketId && (!this.BucketTransFormGroup.value.Bucket1 || (this.BucketTransFormGroup.value.Bucket1 !== bucketId.Id))) {
            this.BucketTransFormGroup.controls.Bucket2.patchValue(bucketId.Id);
            const resp = await this._BucketSer.getDrugQTYinHand(this.selectdDrug.id, bucketId.Id).toPromise();
                    if (resp) {
                        this.BucketTransFormGroup.controls.Buck2Qnty.patchValue(resp);
                        if (this.BucketTransFormGroup.valid) {
                            setTimeout(() => {
                                document.getElementById("transfernow").focus();
                            }, 20);
                        }
                    } else {
                        this.BucketTransFormGroup.controls.Buck2Qnty.patchValue(0);
                    }
        }
    }

    editBucket1() {
        this.bucketId = this.selectdBuck1.Id;
        this.titleText = "Edit Bucket";
        this.modalChangeTrack.push("buckTrans");
        this.showContent = "editBuck";
        this.getDropdownsData();
        this._commonServ.priceSchedule$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.bucketPriceDropData = resp["PriceScheduleList"];
            }
        });
    }

    editBucket2() {
        this.bucketId = this.selectdBuck2.Id;
        this.titleText = "Edit Bucket";
        this.showContent = "editBuck";
        this.modalChangeTrack.push("buckTrans");
        this.getDropdownsData();
        this._commonServ.priceSchedule$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.bucketPriceDropData = resp["PriceScheduleList"];
            }
        });
    }

    onTransQntyChange(event, val) {
        if(this.BucketTransFormGroup.value?.Buck1Qnty > 0) {
            if (val > this.BucketTransFormGroup.value.Buck1Qnty) {               
                this.getCommonWarnMsg(event,"Transfer Qty should not be greater than available Qty.","transferQty")
            } else if (val && val <= 0) {
                this.getCommonWarnMsg(event,"Transfer Qty should  be greater than zero(0).","transferQty")
            } else {
                this.BucketTransFormGroup.controls.Buck2TransQnty.patchValue(val);
            }
        } else {
            this.getCommonWarnMsg(event,"Selected bucket Qty should be greater than zero(0).","bucket1");
            this.BucketTransFormGroup.patchValue({Buck1TransQnty: null});
        }
        
    }

    getCommonWarnMsg(event,err,fcc) {
        event.preventDefault();
        this.errorMessage = err;
        this.focusTo = fcc;
        this.warnConfirmModel = this._modalService.open(CommonWarnorconfirmPopupComponent, { keyboard: false, centered: true,  backdrop: false, windowClass: "large--content max-modal-34" });
            this.warnConfirmModel.componentInstance.warnMsg = this.errorMessage;
            this.warnConfirmModel.componentInstance.okButton = true;
            this.warnConfirmModel.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(async (resp: any) => { 
            if(this.warnConfirmModel) {
                this.warnConfirmModel.close();
                this.warnConfirmModel = null;
            }     
            if(resp) {
                this.focusAferPopup(event)
            }
            });
    }

  async  transferBucket(event) {
        if (!this.BucketTransFormGroup.value.Bucket1 || !this.BucketTransFormGroup.value.Buck1Qnty) {
            this.getCommonWarnMsg(event,"Please select Bucket 1 with available Qty > Zero(0).","bucket1");
        } else if (!this.BucketTransFormGroup.value.Bucket2) {
            this.getCommonWarnMsg(event,"Please select Bucket 2.","bucket2");
        } else if ((!this.BucketTransFormGroup.value.Buck1TransQnty ||  this.BucketTransFormGroup.value.Buck1TransQnty <= 0 )) {
            this.getCommonWarnMsg(event,"Transfer Quantity should be greater than Zero(>0).","transferQty");
            this.BucketTransFormGroup.patchValue({Buck1TransQnty: null, Buck2TransQnty: null });
        } else if (( this.BucketTransFormGroup.value.Buck1TransQnty > this.BucketTransFormGroup.value.Buck1Qnty )) {
            this.getCommonWarnMsg(event,"Transfer Quantity should not be greater than Available Quantity.","transferQty");
            this.BucketTransFormGroup.patchValue({Buck1TransQnty: null, Buck2TransQnty: null });
        } else if (this.BucketTransFormGroup.valid) {
            const resp1 = await this._BucketSer.
            getDrugQTYinHand(this.BucketTransFormGroup.value.Drug , this.BucketTransFormGroup.value.Bucket1).toPromise();
            if (resp1) {
                if (resp1 === this.BucketTransFormGroup.value.Buck1Qnty) {
                    const dataToPost = {
                        TransferDtTm: moment(Date.now()).format("MM/DD/YYYY"),
                        FromBucketId: this.BucketTransFormGroup.controls.Bucket1.value,
                        ToBucketId: this.BucketTransFormGroup.controls.Bucket2.value,
                        DrugId: this.BucketTransFormGroup.controls.Drug.value,
                        Qty: this.BucketTransFormGroup.controls.Buck1TransQnty.value,
                        UnitCost: null
                    };
                    const resp = await  this._BucketSer.bucketTransfer(dataToPost).toPromise();
                        if (resp) {
                            this._alertService.success("Transfer Success.");
                            this.backToprev();
                        } else {
                            this._alertService.error("Something went wrong.");
                            this.selectdDrug = null;
                            this.createNPatchFG();
                            this.drugQnty = null;
                            this.selectedDrugName = false;
                        }
                } else {
                    this._alertService.success("Drug Quantity is updated,Please do  Bucket Transfer again.");
                    this.selectdDrug = null;
                    this.createNPatchFG();
                    this.drugQnty = null;
                    this.selectedDrugName = false;
                }
            }
        } else {
            this._alertService.error("Something went wrong");
            this.selectdDrug = null;
            this.createNPatchFG();
            this.drugQnty = null;
            this.selectedDrugName = false;
        }
    }


    focusAferPopup(ev) {
        ev.preventDefault();
        setTimeout(() => {
            if (this.focusTo === "bucket1") {
                document.getElementById("bucket1Id").focus();
            } else if (this.focusTo === "bucket2") {
                document.getElementById("bucket2Id").focus();
            } else if (this.focusTo === "transferQty") {
                document.getElementById("Buck1TransQnty").focus();
            }
        }, 20);
    }

    // low inventory code

    getEquivalentDrugs(drugId: number) {
        this._drugPriceCheckService
            .getQtyInHandEquivalents(drugId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp["length"] > 0) {
                    this.drugEqs = resp;
                    this.eqDrugList = resp;
                    this.generateEquiWJ(resp);
                } else {
                    this.eqDrugList = [];
                }
            });
    }

    generateEquiWJ(data) {
       this.eqDrugWJ =  new CollectionView(
            data.map(val => {
                const j = {};
                j["Id"] = val["DrugId"];
                j["Drug Name"] = val["Drugname"];
                j["NDC#"] =  j["NDC"] = val["DrugCode"];
                j["Manufacture"] = val["ManufactName"];
                j["Pkg Size"] = val["PackSize"];
                j["AWP"] = val["AWPPack"] ? "$" + val["AWPPack"].toFixed(3) : "$0.000";
                j["Qty In Hand"] = this._buckUtils.getQtyInHand(val["manageinventory"]);
                j["Discontinued"] = val["Discontinued"] ? moment(val["Discontinued"]).format("MM/DD/YYYY") : null;
                j["U.Cost"] = val["UnitCost"] ? "$" + val["UnitCost"].toFixed(3) : "$0.000";
                j["Pref"] =  val["IsPrefered"] ? true : false;
                j["Last Dispensed"] = val["LastDispensed"] ? moment(val["LastDispensed"]).format("MM/DD/YYYY") : null;
                return j;
            })
        );
        this.actvHeaders = [
            "Drug Name",
            "NDC",
            "Manufacture",
            "Pkg Size",
            "AWP",
            "Qty In Hand",
            "Last Dispensed",
            "Discontinued",
            "Pref",
            "U.Cost"
        ];
        if (this.equListWJ) {
       this.equListWJ.focus();
        }
    }

    getDrugBuckets(drugId: number, bucketId: number) {
        this._BucketSer.getBucketByDrugId(drugId, bucketId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.BucketList = resp.DrugQtyBucketList;
            }
        });
    }

    init(flex: wjcGrid.FlexGrid) {
        this.equListWJ = flex;
        flex.columnHeaders.rows[0].height = 25;
        flex.columnHeaders.rows[0].wordWrap = true;
        const element = document.getElementById("lowInvGrid");
        if (element) {
            this._wijHeight =
                window.innerHeight -
                element.getBoundingClientRect().top +
                window.scrollY -
                470 +
                "px";
        }
    }

    focusOutFromWJ(event) {
        event.preventDefault();
        if (document.getElementById("lowInvCancel")) {
            setTimeout(() => {
                document.getElementById("lowInvCancel").focus();
            }, 20);
        }
    }

    focusOutFromCancelButton(event) {
        event.preventDefault();
        if (this.equListWJ) {
            this.equListWJ.focus();
        }
    }

    onRowDblclicked(s: any, e: any) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem && ht.cellType === 1) {
            this.optedInvDrug(s.selectedRows[0].dataItem);
        }
    }

    keyeventOnWj(grid) {
        if (this.eqDrugList && this.eqDrugList.length !== 0) {
            event.preventDefault();
            this.optedInvDrug(grid.selectedItems[0]);
        }
    }

    optedInvBucket(checkedVal: any) {
        this.invSelctdBuck = checkedVal;
        // else {
        //     this.invSelctdBuck.splice(
        //         this.invSelctdBuck.findIndex(
        //             elem =>
        //             checkedVal.id ===
        //                 elem.id
        //         ),
        //         1
        //     );
        // }
    }

    optedInvDrug(checkedVal: any) {
        const data = this.eqDrugList.filter(
            (n: any) => n.DrugId === checkedVal["Id"]
        );
            this.invSelctdDrug = data[0];
            this.replaceWithCurrentDrug();
    }
    optedInvBuckDrug(item: any) {
        this.invSelctdBuck = item;
    }
    changeBucket() {
        if (!this.invSelctdBuck) {
            return;
        }
        const dataTosend = {
            optionSelected: "changeBuck",
            bucketId: this.invSelctdBuck["Id"]
        };
        this.activeModal.close(dataTosend);
    }
    replaceWithCurrentDrug() {
        if (!this.invSelctdDrug) {
            return;
        }
        this._commonServ
            .getRxDrugDetailsById(
                this.invSelctdDrug["DrugId"]
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    const dataTosend = {
                        optionSelected: "changeDrug",
                        eqDrug: resp,
                        bucketId: 1
                    };
                    if (this.invntryFm !== "Rx") {
                        this.activeModal.close(dataTosend);
                    } else {
                        this.IsPopUpClosed.emit(dataTosend);
                    }
                } else {
                    return;
                }
            });
    }

    openPopUpModal(content, warningmsg) {
        this.WarningMessage = warningmsg;
        this.modalRef = this._modalService.open(content, { centered: true , keyboard: false, windowClass: "large--content"});
    }

    // edit bucket logic

    get BEName(): FormControl {
        return this.EditBucketrmGrp.get("Name") as FormControl;
    }
    get BEType(): FormControl {
        return this.EditBucketrmGrp.get("Type") as FormControl;
    }

    get IsBucketPriceUsed(): FormControl {
        return this.EditBucketrmGrp.get("IsBucketPriceUsed") as FormControl;
    }

    generateBucketHistWJ(BuckHistList: any) {
        this.BucketHistWJ = new wjcCore.CollectionView(
            BuckHistList.map((BucketHist, i) => {
                const j = {};
                j["Id"] = BucketHist.DrugId;
                j["Drug Name"] = BucketHist.Name;
                j["Drug NDC"] = BucketHist.DrugNDC;
                j["Quantity Pack"] = BucketHist.QtyPack ? BucketHist.QtyPack.toFixed(3) : "0.000";
                j["Quantity In Hand"] = BucketHist.qty ? BucketHist.qty.toFixed(3) : "0.000";
                j["Unit Price AWP"] = BucketHist.UnitPriceAWP ? "$" + (BucketHist.UnitPriceAWP).toFixed(3) : "$0.000";
                j["Unit Cost"] = BucketHist.UnitPriceCost ? "$" + (BucketHist.UnitPriceCost).toFixed(3) : "$0.000";
                j["Transaction Name"] = BucketHist.TransactionByType;
                j["Transaction Type"] = BucketHist.TransType;
                // j["Unit Bucket Price"] = BucketHist.UnitPrice ? "$" + (BucketHist.UnitPrice).toFixed(3) : "$0.000";
                return j;
            })
        );
        this.bucketHistHeaders = [
            "Drug Name",
            "Drug NDC",
            "Quantity Pack",
            "Quantity In Hand",
            "Unit Price AWP",
            "Unit Cost",
            "Transaction Name",
            "Transaction Type"
            // "Unit Bucket Price"
        ];
    }

    getDropdownsData() {
        if (this.bucketDropsData) {
            this.bucketTypeDData = this.bucketDropsData["BucketCat"];
            if (this.bucketId != null && this.bucketId !== undefined) {
                this.getDetails(this.bucketId);
            }
        }
    }

    updateBucket() {
        if (this.isBucketExists && this.isBucketNameExists) {
            this._alertService.error("Bucket code  &&  Name already exists.");
        } else if (this.isBucketExists) {
            this._alertService.error("Bucket code already exists.");
        } else if (this.isBucketNameExists) {
            this._alertService.error("Bucket Name already exists.");
        } else {
            if (this.EditBucketrmGrp.valid) {
                const data = this.EditBucketrmGrp.getRawValue();
                const dataToPost = {
                    Id: data.Id,
                    Code: data.Code,
                    Name: data.Name,
                    ContextTypeId: data.Type,
                    // SpecificId: data.SpecificType,
                    IsBucketPriceUsed: data
                        .IsBucketPriceUsed,
                    PriceSchId: data.PriceSchId,
                    CreatedDtTm: data.CreatedDtTm,
                    IsDefault: data.IsDefault
                };
                this._BucketSer.updateBucket(dataToPost)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this._alertService.success("Bucket Updated Successfully");
                        this.backToprev();
                    } else {
                        this._alertService.error("Something went wrong");
                    }
                });
            }
        }
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getDetails(id: any) {
        const data: any = {
            pageNumber : this.EditBucketrmGrp.value["pageNumber"],
            pageSize : this.EditBucketrmGrp.value["pageSize"],
            BucketId : id
        };
        this._BucketSer.getBucketById(id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp.length > 0) {
                this.selectdBuckCode = resp[0]["Code"];
                this.selectBuckName = resp[0]["Name"];
                this.EditBucketrmGrp.patchValue({
                    Id: resp[0]["Id"],
                    Code: resp[0]["Code"],
                    Name: resp[0]["Name"],
                    Type: resp[0]["ContextTypeId"],
                    IsBucketPriceUsed: resp[0]["IsBucketPriceUsed"],
                    PriceSchId: resp[0]["PriceSchId"],
                    CreatedDtTm: resp[0]["CreatedDtTm"],
                    IsDefault: resp[0]["IsDefault"]
                });
            }
        });
        this._BucketSer.getBucketHistbyId(data)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(histResp => {
            if (histResp) {
                if (histResp.bucket.length > 0) {
                this.EditBucketrmGrp.controls.TotalValue.patchValue(
                    histResp["TotalCost"] ? histResp["TotalCost"].toFixed(3) : "0.000"
                );
                this.EditBucketrmGrp.controls.TotalItems.patchValue(
                    histResp["TotalItems"]
                );
                this.totalCount = histResp["TotalItems"];
                this.generateBucketHistWJ(histResp["bucket"]);
                } else {
                    this.totalCount = 0;
                }
            } else {
                this.totalCount = 0;
            }
        });
    }
    backToprev() {
        const state = this.modalChangeTrack.pop();
        if (state !== undefined && state != null) {
            this.showContent = state;
            if (state === "lowInv") {
                this.titleText = "Low Inventory Warning!";
            } else if (state === "buckTrans") {
                this.titleText = "Bucket Transfer";
            } else if (state === "editBuck") {
                this.titleText = "Edit Bucket";
            }
        } else {
            this.activeModal.close({
                optionSelected: "continue"
            });
        }
    }

    async onBucketCodeChange() {
        await this._formUtils.blurAndDelay();
        if (this.selectdBuckCode !== this.EditBucketrmGrp.value["Code"]) {
            this._BucketSer.isBucketAlreadyExist(this.EditBucketrmGrp.value["Code"])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.isBucketExists = resp;
                this.onBucketNameChange();
            });
        } else {
            this.onBucketNameChange();
        }
    }

    onBucketNameChange() {
        if (this.selectBuckName !== this.EditBucketrmGrp.value["Name"]) {
            this._BucketSer.isBucketNameAlreadyExist(this.EditBucketrmGrp.value)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                this.isBucketNameExists = resp;
                this.updateBucket();
                } else {
                    this.updateBucket();
                }
            });
        } else {
            this.updateBucket();
        }
    }

    setPage(page: number) {
        this.EditBucketrmGrp.patchValue({
            pageNumber: page
        });
        this.getDropdownsData();
    }

    setSize(size: any) {
        this.EditBucketrmGrp.patchValue({
            pageNumber: this.paginationModel.pageNumber,
            pageSize: size
        });
        this.getDropdownsData();
    }
}
