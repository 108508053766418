import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CollectionView } from '@grapecity/wijmo';
import { EPatient } from 'src/app/models';
import * as wjcGrid from "@grapecity/wijmo.grid"
import { Subject } from 'rxjs';
import { WijimoUtil } from 'src/app/utils';
import * as moment from 'moment';
import { AlertService, CommonService, PatientService, UserService } from 'src/app/services';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { StorageService } from 'src/app/services/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonWarnorconfirmPopupComponent } from '../common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import { FormatsUtil } from 'src/app/utils/formats.util';
import { DecimalPipe } from '@angular/common';
import { DateCheckUtil } from 'src/app/utils/date-checks.util';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-patient-external-history',
  templateUrl: './patient-external-history.component.html',
  styleUrls: ['./patient-external-history.component.scss']
})
export class PatientExternalHistoryComponent implements OnInit {
  @ViewChild("PATEXTERNALHIST", {static: true})
  PATEXTERNALHIST: any;

  @Input() patientFG : FormGroup;
  @Input() patientId: number;
  @Input() rxPatientModel : EPatient;
  @Input() FrmEditPatient : boolean;

  @Output() ClosePatHistModel = new EventEmitter<any>();
  @Output() EmittoSaveExternalHist = new EventEmitter<any>();
  @Output() AddorUpdateExternalHist = new EventEmitter<any>();
  isDataExists = false;
  externalPatientWJ: CollectionView;
  @ViewChild("externalPatient") externalPatient: wjcGrid.FlexGrid;
  unsubscribe$: Subject<void> = new Subject();
  wjHeaders: any;
  modelRef: any;
  userName: string;
  openTrnsReqPopup: boolean;
  selectedRxInfo : any;
  iseditRecord: boolean;
  id: any;
  currentEditItem: any;
  isItemAlreadyAdded: boolean;
  today: string;
  maxDate: { year: number; month: number; day: number; };
  isFillDateMin: boolean;
  drugInformation: any;
  IsTransferRequestAllowd: boolean;
  constructor(private _wijimoUtils: WijimoUtil, private _userService: UserService,
    private _alertSvc: AlertService, private _modalService: NgbModal, private _dateUtil: DateCheckUtil,
    private _patService : PatientService, private _commonSrv: CommonService,
    private _cdr: ChangeDetectorRef,public _storageService: StorageService) { }

  ngOnInit(): void {
    this.today = this._dateUtil.getCurrentDate();
    this.maxDate = {
        year: moment(this.today).year(),
        month: moment(this.today).month() + 1,
        day: moment(this.today).date()
    };
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$.subscribe(w => {
        if (w && w["WijmoKey"] && w["WijmoKey"] === "PatientExternalHistoryWJ") {
            this.patchDefaultValHeaders();
        }
    });
    this.IsTransferRequestAllowd = environment.TransferInRequest;
    this.getPatientExternalHistory();
  }

  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("PatientExternalHistoryWJ");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("PatientExternalHistoryWJ", storedWJ);
  }

  init(flex: wjcGrid.FlexGrid) {
    flex.columnHeaders.rows[0].wordWrap = true;
    flex.autoSizeRow(0, true);   
  }

  loadedWJRows(flex: wjcGrid.FlexGrid) {
  flex.collapseGroupsToLevel(0);
  }

  getPatientExternalHistory(){
    this._patService.getPatientExternalHistory(this.patientId).pipe(takeUntil(this.unsubscribe$)).subscribe((resp)=>{
      if(resp && resp.length){
        this.generateExternHistList(resp);
        this.isDataExists = true;
      } else{
        this.isDataExists = false;
        this.externalPatientWJ = new CollectionView(null);
      }
    })
  }
  
  generateExternHistList(data: any) {
    this.externalPatientWJ = new CollectionView(
      data?.map((patRx, i) => {
          const k = {};
          k["Id"] = patRx?.id ? patRx?.id : null;
          k["Rx#"] = patRx?.prescNum ? patRx?.prescNum : null;
          k["Fill Date"] = patRx?.rxFilledDate ? moment.utc(patRx?.rxFilledDate).local().format(MsgConfig.DATE_FORMAT) : null;
          k["Expiration Date"] = patRx?.rxExpirationDate ? moment.utc(patRx?.rxExpirationDate).local().format(MsgConfig.DATE_FORMAT) : null;
          k["Ordered Date"] = patRx?.rxOrderedDate ? moment.utc(patRx?.rxOrderedDate).local().format(MsgConfig.DATE_FORMAT) : null;
          k["Drug Name"] = (patRx?.drugName?.trim()).toUpperCase();
          k["Refills Left"] = patRx?.refillsLeft ? patRx?.refillsLeft : null;
          k["Active Rx?"] = patRx?.isPrescriptionActive;
          k["Notes"] = patRx?.notes ? patRx?.notes : null;
          k["Source Pharmacy"] = patRx?.sourcePharmacyName ? patRx?.sourcePharmacyName : null;
          k["Rph/Tech Initials"] = patRx?.createdBy ? patRx?.createdBy : null;
          k["newRecord"] = patRx?.newRecord ? patRx?.newRecord : false;
          k["editRecord"] = patRx?.editRecord ? patRx?.editRecord : false;
          k["drugNdc"] = patRx?.ndc ? patRx?.ndc : null;
          k["drugId"] = patRx?.drugId ? patRx?.drugId : null;
          k["NPINum"] = patRx?.sourcePharmacyNPI ? patRx?.sourcePharmacyNPI : null;
          return k;
        })
      );
  }

  
  closePatHistModel(){
    if(this.modelRef){
      this.modelRef.close();
      this.modelRef = null;
    }
    this.ClosePatHistModel.emit(true);
    this.currentEditItem = null;
    this.drugInformation = null;
  }

  addNewPrescription() {
    if (!this.externalPatientWJ) {
        this.externalPatientWJ = new CollectionView([]);
    }
    this.isDataExists = true;
    this.iseditRecord = false;
    const technician = this._userService.getToken("TechUserName");
    if (!technician) {
        this.userName = this._userService.getToken("PhUserName");
    } else {
        this.userName = this._userService.getToken("TechUserName");
    }
    const requiredFields = ['Drug Name','Fill Date'];
    const hasIncompleteRow = this.externalPatientWJ?.items?.some((item: any) => {
      return requiredFields?.some(field => !item[field] || item[field]?.toString().trim() === '');
    });
    if (hasIncompleteRow) {
        this.isItemAlreadyAdded = false;
        this._cdr.detectChanges();
        this.isItemAlreadyAdded = true;
        return;
    } else{
      this.isItemAlreadyAdded = false;
    }
    let newRow = this.externalPatientWJ.addNew();
    this.isItemAlreadyAdded = true;
    newRow["Rx#"] = null;
    newRow["Drug Name"] = null; 
    newRow["Refills Left"] = null;
    newRow["Expiration Date"] = null;
    newRow["Ordered Date"] = null;
    newRow["Fill Date"] = null;
    newRow["Active Rx?"] = true;
    newRow["Rph/Tech Initials"] = this.userName;
    newRow["Source Pharmacy"] = null;
    newRow["newRecord"] = true;
    newRow["editRecord"] = false;
    this.externalPatientWJ.commitNew();
    this.currentEditItem = newRow;
    this.AddorUpdateExternalHist.emit(this.currentEditItem);
  }
  getEnteredData(event,item,rxID){
    if(rxID === "ExpirationDateID"){
      let today = moment().format(MsgConfig.DATE_FORMAT);
      item["Expiration Date"] = event ? event : null;
      item["Active Rx?"] = moment(today, MsgConfig.DATE_FORMAT).isAfter(event) ? false : true;
    }
    if(rxID === "FillDateID"){
      item["Fill Date"] = event ? event : null;
      if (item["Ordered Date"] && item["Fill Date"] && moment(item["Ordered Date"], MsgConfig.DATE_FORMAT).isAfter(moment(item["Fill Date"], MsgConfig.DATE_FORMAT))) {
        this.modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
          backdrop: false,
          keyboard: false, windowClass: "large--content"
          });
        this.modelRef.componentInstance.warnMsg = "Fill Date cannot be less than Ordered Date.";
        this.modelRef.componentInstance.IsHeaderText = "Warning!!";
        this.modelRef.componentInstance.okButton = true;
        this.modelRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response: any) => {
          if(this.modelRef){
            this.modelRef.close();
            this.modelRef = null;
          }
          item["Fill Date"] = null;
          this.isFillDateMin = true;
        });
      } else{
        this.isFillDateMin = false;
      }
    }
    if(rxID === "OrderedDateID"){
      item["Ordered Date"] = event ? event : null;
    }
    if(rxID === "refillsLeftID"){
        item["Refills Left"] = event?.target?.value  ? event?.target?.value : null;
    }
    if(rxID === "rxID"){
      item["Rx#"] =  event?.target?.value  ? event?.target?.value : null;
    }
    if(rxID === "notesID"){
        item["Notes"] =  event?.target?.value  ? event?.target?.value : null;
    }
    if(rxID === "drugNameID"){
      item["Drug Name"] = event ? event?.drugname + " " + event?.strength + " " + event?.drugformname : null;
      item["drugNdc"] = event?.ndc ? event?.ndc : null;
      item["drugId"] = event?.id ? event?.id : null;
    }
    this.EmittoSaveExternalHist.emit(this.currentEditItem)
  }

  selectDrug(event, item, rxId){
    if(rxId === "drugNameID"){
      this.getEnteredData(event, item, rxId);
    }
  }

  selectedPharmDetails(event){
    this.currentEditItem["Source Pharmacy"] = event?.PharmName;
    this.currentEditItem["NPINum"] = event?.NPINum;
  }

  saveExternHist(){
    const item = this.currentEditItem;
    if (!this.currentEditItem){
      this._alertSvc.error("Please add required fields");
    } else if (!item?.['Drug Name'] || !item?.['Fill Date']) {
      this._alertSvc.error(!item?.['Drug Name'] ? 'Please select Drug' : !item?.['Fill Date'] ? 'Please select Fill Date' : 'Please add required fields');
      return false;
    } else {
        if(item) {
          const payload = {
            id: item.Id,
            patientId: this.patientId,
            prescNum: item["Rx#"] ? item["Rx#"] : null,
            drugName: item["Drug Name"] ? item["Drug Name"] : null,
            ndc: item["drugNdc"] ? item["drugNdc"] : null,
            refillsLeft: item["Refills Left"] ? item["Refills Left"] : null,
            rxExpirationDate: item["Expiration Date"] ? moment(item["Expiration Date"]).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN) : null,
            rxOrderedDate: item["Ordered Date"] ? moment(item["Ordered Date"]).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN) : null,
            rxFilledDate: item["Fill Date"] ? moment(item["Fill Date"]).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN) : null,
            sourcePharmacyName: item["Source Pharmacy"] ? item["Source Pharmacy"] : null,
            sourcePharmacyNPI: item["NPINum"] ? item["NPINum"] : null,
            notes: item["Notes"] ? item["Notes"] : null,
            drugId: item["drugId"] ? item["drugId"] : null
          };
          this.selectedRxInfo = payload;
          if(!this.iseditRecord){
              this._patService.addPatientExternalHistory(payload).subscribe((resp)=>{
                if(resp){
                  this._alertSvc.success(MsgConfig.SAVE_SUCCESS);
                  this.currentEditItem = null;
                  this.drugInformation = null;
                  this.isItemAlreadyAdded = false;
                  this.getPatientExternalHistory();
                } else{
                  this._alertSvc.error("Unable to add external history");
                }
            });
          } else{
                this._patService.updatePatientExternalHistory(payload).pipe(takeUntil(this.unsubscribe$)).subscribe((res)=>{
                if(res){
                  this._alertSvc.success(MsgConfig.UPDATE_SUCCESS);
                  item['editRecord'] = false;
                  this.currentEditItem = null;
                  this.drugInformation = null;
                  this.isItemAlreadyAdded = false;
                  this.iseditRecord = false;
                  this.getPatientExternalHistory();
                } else {
                  this._alertSvc.error("Unable to update external history");
                }
            });
          }
        }
    }
  }

  onFormatItem(e: wjcGrid.FormatItemEventArgs) {
    if (e?.panel?.cellType === wjcGrid.CellType.ColumnHeader) {
      const headerText = e.panel.columns[e.col].header;
      if (headerText === 'Drug Name' || headerText === 'Fill Date') {
        e.cell.textContent = ''; 
        const textNode = document.createTextNode(headerText);
        const span = document.createElement('span');
        span.style.color = 'red';
        span.textContent = ' *';
        e.cell.appendChild(textNode);
        e.cell.appendChild(span);
      }
    }
  }
  
 
  TransferIn(event){
    if(event?.drugId){
    this._commonSrv.getDrugInfo(event?.drugId).subscribe((resp)=>{
      if(resp && resp.Drug){
        this.drugInformation = this.generateGridData(resp.Drug);
      }
    })
    }
    this.openTrnsReqPopup = true;
  }

  generateGridData(drug) {
    if (drug) {
      let j = {};
      j["drugname"] = drug?.Name;
      j["strength"] = drug?.Strength;
      j["drugformname"] = drug?.DrugFormName;
      j["drugclass"] = drug?.DrugClass;
      j["ndc"] = drug.DrugCode;
      j["isbranded"] = drug?.IsBranded;
      j["qtypack"] = drug?.QtyPack;
      j["manufacturername"] = drug?.ManufactName;
      j["unitpriceawp"] = drug?.UnitPriceAWP;
      j["unitpricecost"] = drug?.UnitPriceCost;
      j["id"] = drug?.Id;
      return j;
    }
    return null;
  }
  
  closeTRxREQPopUpModal(event){
    this.openTrnsReqPopup = false;
  }

  editExternHist(item){
    item.editRecord = true;
    this.iseditRecord = true;
    item.newRecord = false;
    this.isItemAlreadyAdded = false;
    this.AddorUpdateExternalHist.emit(item);
    this.currentEditItem = item;
  }

  deleteExternHist(event){
    const payload = {
      "patientId": this.patientId,
      "externalHistoryId": event?.Id
    }
    this._patService.deletePatientExternalHistory(payload).pipe(takeUntil(this.unsubscribe$)).subscribe((resp)=>{
      if(resp){
        this._alertSvc.success(MsgConfig.DELETE_SUCCESS);
        this.isItemAlreadyAdded = false;
        this.getPatientExternalHistory();
      } else{
        this._alertSvc.error("Unable to delete external history");
      }
    })
  }

  ngOnDestroy() {
    this._storageService.getWijmoGridDataInSession(this.wjHeaders,"PatientExternalHistoryWJ")
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
