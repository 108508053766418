import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from "@angular/core";
import { DrugTabEnum, DrugHeaderTitles } from "./drug.constants";
import { CommonUtil, DrugUtils, ValidateFormFieldsUtils, NewAuditUtils } from "src/app/utils";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DrugStore } from "src/app/store";
import { Observable } from "rxjs";
import { CommonService, EditDrugService, AlertService, DrugService, NotesLogService } from "src/app/services";
import { SystemData, MiscInfo, DrugAuditModel, AccCategoryE, DrugFileE } from "src/app/models";
import { DrugTabUtils } from "src/app/utils/drug-tabs.utils";
import { MsgConfig } from "src/app";
import { CompIngredListComponent } from "../shared";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { AuditLogUtils } from "src/app/utils/audit-log.util";
import * as Sentry from "@sentry/browser";
import { PrivMaskGuard } from "src/app/guards";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-drug",
    templateUrl: "./drug.component.html"
})
export class DrugComponent implements OnInit {
    @Input() FocusOn: String;
    @Input() drugId: any;
    @Input() addDrugModal: FormGroup;
    @Input() openMMS: boolean;
    @Input() IsFromErx: boolean;
    @Input() buckId: any;
    @Input() IsFromEDI: boolean;
    @Input() BuckIdEDI: any;
    @Input() drugFlow: any;
    @Input() rxBillStatusId: any;
    @Input() checkPayRec: any;
    @Input() islatestrefill : any;
    @Input() isOriginalRx : any;
    @Input() isPartialRefStatus: any;
    @Input() frmAction: boolean;
    @Input() openNPatchFrmMMS: boolean;
    @Input() MMSDataDPV: any;
    @Input() resetDrugInfo: any;
    @Input() enableDrugClassFromEDI : boolean;
    @Output() CloseDrugModal = new EventEmitter<any>();

    currTab: string = "DRUGINFO";
    tabEnum = DrugTabEnum;
    headerTitle = DrugHeaderTitles;
    drugFG: FormGroup;
    originalData: any;
    drugInfo$: Observable<any>;
    dropDownValues: any;
    systemData$: Observable<SystemData>;
    priceSchedule$: Observable<any>;
    originalMiscData: MiscInfo;
    miscInfo: MiscInfo;
    drugMiscFG: FormGroup;
    drugCatFG: FormGroup;
    formGroupInvalid: boolean;
    popUpType: any;
    resetToFirst: boolean;
    isFmComp: boolean;
    compoundDrugData: any;
    doAddDrugToBucket: boolean;
    HeaderType: any;
    drugCatFGOldData: FormGroup;
    originalDrugPriceVals: any;

    @ViewChild("COMPDRUGPATCH", { static: true })
    COMPDRUGPATCH: any;

    @ViewChild("DrugInfoTab") DrugInfoTab: any;

    @ViewChild("MarkAsPrefDrug", {static: true})
    MarkAsPrefDrug: any;
    modalRef: any;
    drugCatNew: boolean;
    drugCatData: any;
    today: string;
    minDate: { year: number; month: number; day: number; };
    drugInfoFG: FormGroup;
    focusField: any;
    defaultSig: any;
    DrugList = [];
    selectedDrugItem: string;
    modalRefIsPref: any;
    typeVal: any;
    DrugFromName: any;
    drugCategoryList: any = [];
    manuFactDrug: any;
    openFromMMS = false;
    unsubscribe$ : Subject<void> = new Subject();
    insuResData: any;
    drugPreferredStatus: any;
    isValueChanged: boolean = false;
    isProgrammaticChange: boolean;
    constructor(private _drugUtils: DrugUtils, private _drugStore: DrugStore, private _editDrgSvc: EditDrugService,
        private _commonService: CommonService, private _drugTabUtil: DrugTabUtils, private _alrtsvc: AlertService, private _notesLogSvc: NotesLogService,
        private _modalService: NgbModal,private _formUtils:ValidateFormFieldsUtils, private _drugSvc: DrugService, private _dateCheckUtil: DateCheckUtil, private _cdr: ChangeDetectorRef,  private _auditUtils: NewAuditUtils, private _newAuditUtils: AuditLogUtils
        , private _fb: FormBuilder,private _privMask:PrivMaskGuard, private _alertSvc:AlertService, private _formatsUtil: FormatsUtil) {
        this.systemData$ = this._commonService.systemData$;
        this.priceSchedule$ = this._commonService.priceSchedule$;
        this.currTab = this.openNPatchFrmMMS ? "" : "DRUGINFO";
        this.getDropDownVal();
    }

    ngOnInit() {
        this.drugFG = (this.addDrugModal && !this.drugId) ? this.addDrugModal : this._drugUtils.createDrugDataModal();
        this.drugMiscFG = this._drugTabUtil.createMiscFG();
        this.getDrugInfo();
        this.compoundDrugData = this._drugStore.drugCompInfo$["source"]["value"];
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.isValueChanged = false;
        this.subscribeToControlChanges(this.drugFG);
    }
    get drugInfo(): FormGroup {
        return  this.drugFG.get("Drug") as FormGroup;
    }

    onCancel(value) {
        this._commonService.storeOverridePrivNotes(null);
        if(this.isValueChanged){
            this.popUpType = value;
            if (value && value !== "cancel") {
                this.closeModal("Cancel");
            }
        } else {
            this.closeModal("Cancel");
        }
    }

    closeModal(type, Id?: any, oldNewData?: any) {
        this._drugStore.storePrescribedCompInfo(null);
        this._drugStore.storeCompInfo(null);
        this.CloseDrugModal.emit({Type: type, drugId: Id, oldNewDrugData: oldNewData});
        this.drugFG.reset();
    }

    changDrugActiveTab(value) {
       if (value === "Patient11") {
            this.getDrugCategory();
        } else {
            this.currTab = this.tabEnum[value];
        }
        this._cdr.detectChanges();
        if (this.DrugInfoTab && value === "Patient1") {
            this.DrugInfoTab.getDrugBucketListNPatch(null, true);
        }
    }
    subscribeToControlChanges(group: FormGroup) {
        Object.keys(group.controls).forEach((controlName) => {
          const control = group.get(controlName);
          if (control instanceof FormGroup) {
            this.subscribeToControlChanges(control);
          } else if (control) {
            control.valueChanges.subscribe(() => {
              if (!this.isProgrammaticChange && control.dirty) {
                this.isValueChanged = true;
              } else{
                this.isValueChanged = false;
              }
            });
          }
        });
      }
      
    async getDrugInfo(type?: any, selectTab?: boolean) {
        this.originalData = this.drugId ? await this._drugUtils.getDrugData(this.drugId) : this.drugFG.value;
        this.defaultSig = this.originalData?.Drug?.DefaultSigCode
        this.setOrgPriceVals(this.originalData && this.originalData["Drug"] ? this.originalData["Drug"] : null);
        this._drugStore.storeDrugInfo(Object.assign({}, this.originalData));
        if (!this.originalData?.["Drug"]?.["DrugBucket"] && this.drugFG?.value?.['Drug']?.['DrugBucket'])
            this.originalData["Drug"]["DrugBucket"] = this.drugFG.value['Drug']['DrugBucket']
        this.drugInfo$ = this._drugStore.drugInfo$;
        this.isProgrammaticChange = true;
        await this._drugUtils.patchDrugInfo(this.drugInfo$["source"]["value"], this.drugFG, this.drugId,
         (this.drugId || this.IsFromErx) ? "New" : "Add");
         if (this.drugId) {
            this.insuResData = await this._drugTabUtil.getDrugInsuRest(this.drugId, 1, 30);
         }
        this.currTab = !type ? "DRUGINFO" : this.tabEnum[type];
        this.drugFG.markAsPristine();
        this.isProgrammaticChange = false;
        if (!type) {
            this.currTab = this.resetDrugInfo ? "COUNSELLING" : "DRUGINFO";
        } else if (!selectTab) {
            this.changDrugActiveTab(type);
        }
        if (this.BuckIdEDI) {
            this.buckId = this.BuckIdEDI;
        }
        if (this.DrugInfoTab) {
            this.DrugInfoTab.getDrugBucketListNPatch(this.buckId ? this.buckId : null, true);
        }
        this.getMiscInfo();
    }

    setOrgPriceVals(orgData) {
        this.originalDrugPriceVals = [];
        const a = {
            UnitPriceAWP : orgData && orgData["UnitPriceAWP"] ? orgData["UnitPriceAWP"] : '0.00',
            UnitPriceCost : orgData && orgData["UnitPriceCost"] ? orgData["UnitPriceCost"] : '0.00',
            CostPack : orgData && orgData["CostPack"] ? orgData["CostPack"] : '0.00' ,
            AWPPack : orgData && orgData["AWPPack"] ? orgData["AWPPack"] : '0.00',
            DirectUnitPrice : orgData && orgData["DirectUnitPrice"] ? orgData["DirectUnitPrice"] : '0.00',
            Surcharge : orgData && orgData["Surcharge"] ? orgData["Surcharge"] : '0.00',
            PerOfBrand : orgData && orgData["PerOfBrand"] ? orgData["PerOfBrand"] : '0.00',
            PriceSchId : orgData && orgData["PriceSchId"] ? orgData["PriceSchId"] : null,
            AlternateCost : orgData && orgData["AlternateCost"] ? orgData["AlternateCost"] : '0.00',
            DrugTypeId : orgData && orgData["DrugTypeId"] ? orgData["AlternateCost"] : '0.00',
        };
        this.originalDrugPriceVals.push(a);

    }
    async getDropDownVal() {
        this.dropDownValues = await this._drugUtils.getDrugDefaultvalbyPost({DrugManufactNeeded  : true ,SigNeeded : false ,DrugFormNeeded : true, VendorsNeeded : true, VaccineNeeded: true});
        if (this.dropDownValues && this.openNPatchFrmMMS) {
            this.currTab = "";
            this._cdr.detectChanges();
            this.openFromMMS=this.openNPatchFrmMMS;
            this.currTab = "DRUGINFO";
        }
    }

    async getMiscInfo() {
        if (!this.originalMiscData) {
            this.originalMiscData = await this._drugTabUtil.getDrugMiscInfo(this.drugId ? this.drugId : 0);
            this.originalMiscData  = this.originalMiscData ? this.originalMiscData : this.drugMiscFG.value;
            this.miscInfo = Object.assign({}, this.originalMiscData);
            this._drugTabUtil.patchMiscInfo(this.miscInfo, this.drugMiscFG);
            this.drugMiscFG.markAsPristine();
        }
        this.isValueChanged = false;
        this.drugMiscFG.markAsPristine();
        this.drugFG.markAsPristine();
    }
    getDrugCategoryList(response) {
        const destination = [];
        if (response && response["length"] > 0) {
            response.map(val => {
                if (val["IsActive"]) {
                    destination.push(val["Name"].toUpperCase());
                }
            });
        }
        const fg = this._fb.group({categories: []});
        fg.controls["categories"].patchValue(destination && destination.length ? destination : []);
        return {FG: fg, isNew: !(response && response["length"] > 0), categoryData: response};
    }
    async getDrugCategory() {
        this._editDrgSvc.getDrugCategoriesList(this.drugId).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if(resp) {
                this.drugCategoryList = resp;
                const resp1 = this.getDrugCategoryList(resp["drugCats"]);
                  this.drugCatFG = resp1["FG"];
                  this.drugCatNew = resp1["isNew"];
                   this.drugCatData = resp1["categoryData"];
                   this.currTab = "DRUGCATEGORY";
                   this.drugCatFG.markAsPristine();
            }
        });
        this.drugCatFGOldData = (this.drugCatFG && this.drugCatFG.value && this.drugCatFG.value["categories"]) ? (this.drugCatFG.value["categories"]).toString() : "";
    }

    saveDrug(type?: any) {
        const drugInfDtFG = this._fb.group({
            DiscontDt: this.drugInfo.controls["DiscontDt"].value,
            LastUpdateDt: this.drugInfo.controls["LastUpdateDt"].value
        })
        const dateChecks = [{
            control: "DiscontDt",
            minDate: "",
            maxDate: "",
            formGroup : drugInfDtFG,
            fieldTab : "DRUGINFO",
            isRequired: false,
            id: "DiscontDt"
        },
        {
            control: "LastUpdateDt",
            minDate: "",
            maxDate: this.minDate,
            formGroup : drugInfDtFG,
            fieldTab : "DRUGINFO",
            isRequired: false,
            id: "LastUpdateDt"
        }
    ];
        const datecCheckFields: any = this._dateCheckUtil.checkDateValidationAtTimeofSaveAllFormGroups(dateChecks);
        if (datecCheckFields) {
            if (datecCheckFields) {
                this.currTab = datecCheckFields["fieldTab"];
                this._cdr.detectChanges();
                this.FocusOn = datecCheckFields["id"];
                this._cdr.detectChanges();
            }
            return;
        } else {
        let focusOnId = null;
        const drugFGValue = this.drugFG.getRawValue();
        if (this.drugFG && this.drugFG.valid && drugFGValue["Drug"] && parseFloat(drugFGValue["Drug"]["UnitPriceAWP"]) !== 0 &&
        !(parseFloat(drugFGValue["Drug"]["UnitPriceCost"]) === 0 &&
         this._commonService.getSetttingValue("RxSettings", "WARN_BLANK_UNIT_COST") === "1")) {
        const drugFg: any = this.drugFG.value["Drug"];
        if ([drugFg["BrandOrGenNDC"], drugFg["ReplaceNDC"]].indexOf(drugFg["DrugCode"]) === -1) {
            if (this.drugId) {
                this.updateDrug();
            } else {
                this.addDrug(type);
            }
        } else {
            this.resetTab();
            setTimeout(() => {
                if (drugFg["BrandOrGenNDC"] === drugFg["DrugCode"]) {
                    this._alrtsvc.error(((drugFg["ReplaceNDC"] === drugFg["DrugCode"]) ? "Replacement NDC and Generic/Brand NDC" :
                    "Generic/Brand NDC") + " cannot be same as Drug NDC.", false, "long");
                    if (document.getElementById("BrandOrGenNDC")) {
                        document.getElementById("BrandOrGenNDC").focus();
                    }
                } else {
                    this._alrtsvc.error("Replacement NDC cannot be same as Drug NDC.", false, "long");
                    if (document.getElementById("ReplaceNDC")) {
                        document.getElementById("ReplaceNDC").focus();
                    }
                }
            }, 1000);
        }
        } else if (!this.drugFG.valid) {
            this._formUtils.validateAllFormFields(this.drugFG);
            this.resetTab();
           // this._alrtsvc.error("Enter Required Feilds", false, "long");
            setTimeout(() => {
                if (document.querySelector(".ng-invalid")) {
                    const element: any = document.querySelector(".ng-invalid");
                    (<HTMLInputElement>element).focus();
                }
            }, 200);
            this.formGroupInvalid = true;
         } else if (drugFGValue["Drug"] && parseFloat(drugFGValue["Drug"]["UnitPriceAWP"]) === 0 &&
         (parseFloat(drugFGValue["Drug"]["UnitPriceCost"]) === 0 &&
         this._commonService.getSetttingValue("RxSettings", "WARN_BLANK_UNIT_COST") === "1")) {
            this.resetTab();
            this._alrtsvc.error("Drug Unit Price and Unit Cost Price cannot be blank", false, "long");
            focusOnId = "UnitPriceAWP";
         } else if (drugFGValue["Drug"] && parseFloat(drugFGValue["Drug"]["UnitPriceAWP"]) === 0) {
            this.resetTab();
            this._alrtsvc.error("Drug Unit Price cannot be blank", false, "long");
            focusOnId = "UnitPriceAWP";
        } else if (drugFGValue["Drug"] && (parseFloat(drugFGValue["Drug"]["UnitPriceCost"]) === 0 &&
        this._commonService.getSetttingValue("RxSettings", "WARN_BLANK_UNIT_COST") === "1")) {
            this.resetTab();
            this._alrtsvc.error("Drug Unit Cost Price cannot be blank", false, "long");
            focusOnId = "UnitPriceCost";
        }

        if (focusOnId) {
            setTimeout(() => {
                const elem: any = document.getElementById(focusOnId);
                if (elem) {
                    elem.focus();
                    elem.select();
                }
            }, 500);
        }
        this.isValueChanged = false;
    }
    }

    async checkHasCompMiscInfo(forIng?: string) {
        let result = false;
        if (forIng) {
            if (forIng === "add") {
                const dataToPost = this._drugStore.drugCompInfo$["source"]["value"];
                if (dataToPost && dataToPost.list && dataToPost.list.length > 0) {
                    result = false;
                } else {
                    result = true;
                }
            } else if (forIng === "update") {
                const resp = await this._drugSvc.getDrugRefIngredients(this.drugId, 0).toPromise();
                if (resp && resp["list"]) {
                    result = false;
                } else {
                    result = true;
                }
            }
        } else {
            if (this.drugMiscFG.value["DrugMisc"]) {
                for (const keys in this.drugMiscFG.value["DrugMisc"]) {
                    if (["DoseFrmDescId", "CompoundTypId", "AdmnstrnRouteId", "AdmnstrnRoute2Id", "DispUnitFormId"].indexOf(keys) > -1) {
                        if (this.drugMiscFG.value["DrugMisc"][keys] === null) {
                            result = true;
                        }
                    }
                }
            } else {
                result = true;
            }
        }
        return result;
    }

    async updateDrug() {
        if (this.drugFG.valid) {
            this.formGroupInvalid = false;
            const drugInfo = this.drugFG.getRawValue();
            if (drugInfo && drugInfo["Drug"] && drugInfo["Drug"]["DrugTypeId"] === 2 && await this.checkHasCompMiscInfo()) {
                this._alrtsvc.warning(" Please enter the compound related values for the compound drug.");
                this.isFmComp = true;
                this.currTab = "MISCINFO";
            } else if (drugInfo && drugInfo["Drug"] && drugInfo["Drug"]["DrugTypeId"] === 2 && await this.checkHasCompMiscInfo("update")) {
                this._alrtsvc.warning("You can not save compound drug without ingredients.");
                this.isFmComp = false;
                this.openCompoundDrug();
            } else {
                this.isFmComp = false;
                this._drugUtils.updatePriceHistory(this.drugFG);
                const newlyAddOrDeleList: any = [];
                if(this.drugCategoryList['userDrugCats'] && this.drugCategoryList['userDrugCats'].length) {
                    this.drugCategoryList['userDrugCats'].map((val: any) => {
                        val["DrugId"] = this.drugId;
                        val["DrugCatId"] =    val["Id"]  = val["Id"];
                        if (this.drugCatFG && this.drugCatFG.value["categories"]
                         && this.drugCatFG.value["categories"].indexOf(val.Name.toUpperCase()) !== -1) {
                            val.IsActive = true;
                            val.isNewlyAdded = true;
                            newlyAddOrDeleList.push(val);
                        } else 
                            val.IsActive = false;
                    });
                }
                if(this.drugCategoryList['drugCats'] && this.drugCategoryList['drugCats'].length) {
                    this.drugCategoryList['drugCats'].map((val: any) => {
                        if (this.drugCatFG && this.drugCatFG.value["categories"]
                            && this.drugCatFG.value["categories"].indexOf(val.Name.toUpperCase()) !== -1) {
                            val.IsActive = true;
                        } else {
                            val.isNewlyAdded = false;
                            val.IsActive = false;
                            newlyAddOrDeleList.push(val);
                        }
                            
                    });
                }
                const totalFGChanged = {
                    drugInfo: [this.drugFG, this._editDrgSvc.updateDrugInfo(this.drugFG.getRawValue()) ],
                    miscInfo: [this.drugMiscFG, this._editDrgSvc.updateMisc(this.drugMiscFG.getRawValue()) ]
                };
                const updateServices = [];
                Object.keys(totalFGChanged).map(tabName => {
                    if (totalFGChanged[tabName][0] && totalFGChanged[tabName][0].dirty && tabName !== "drugInfo") {
                        updateServices.push(totalFGChanged[tabName][1]);
                    } else if (totalFGChanged[tabName][0] && tabName === "drugInfo") {
                        updateServices.push(totalFGChanged[tabName][1]);
                    }
                });
                if (updateServices.length > 0) {
                    const resp = await this._drugUtils.saveDrugInfo(updateServices);
                    await this.saveDrugCat(newlyAddOrDeleList);
                    if (resp && resp.findIndex((val: any) => val < 0) === -1) {
                        if (resp[0] === 2) {
                            this._alrtsvc.error("Quick Code must be unique.", false, "long");
                            if (document.getElementById("QuickCode")) {
                                document.getElementById("QuickCode").focus();
                            }
                            if (this.drugCatFG) {
                                this.drugCatFG.markAsPristine();
                            }
                            this.drugMiscFG.markAsPristine();
                        } else if (resp[0] !== 1) {
                            const systemData = this.systemData$["source"]["value"];
                            const priceSchedule = this.priceSchedule$["source"]["value"];
                            const drugStatus = [{'Id': true, 'Name': 'Active'}, {'Id': false, 'Name': 'InActive'}];
                            const drugFdaApproved = [{'Id': 0, 'Name': 'No'}, {'Id': 1, 'Name': 'Yes'}, {'Id': 2, 'Name': 'Unknown'}];
                            const DropdownsData = {"NdcQualifier": systemData.NdcQualifier, "UnitDoseIndication": systemData.UnitDoseIndication, "FDA_Form" : this.dropDownValues.FDA_Form, "PriceScheduleList": priceSchedule.PriceScheduleList, "Vendor": this.dropDownValues.Vendor, "drugStatus": drugStatus, "DrugType": systemData.DrugType, "fdaApproved": drugFdaApproved};
                            const drugDataModel = new DrugAuditModel().drugInfo;
                            this._auditUtils.saveAuditChange(
                                this.originalData,
                                this.drugFG.getRawValue(),
                                "Edit Drug",
                                "Drug",
                                this.drugId,
                                drugDataModel,
                                false,
                                DropdownsData
                            );
                            const drugMiscDataModel = new DrugAuditModel().drugMiscInfo;
                            this._auditUtils.saveAuditChange(
                                this.originalMiscData,
                                this.drugMiscFG.getRawValue(),
                                "Edit Drug",
                                "Drug",
                                this.drugId,
                                drugMiscDataModel,
                                false,
                                this.miscInfo
                            );
                            this._alrtsvc.success(MsgConfig.UPDATE_SUCCESS);
                            const oldNewData = [this.originalDrugPriceVals[0], this.drugFG.getRawValue()["Drug"]];
                            this.closeModal("Update", this.drugId, oldNewData);
                        } else {
                            this._alrtsvc.error(MsgConfig.UPDATE_FAIL);
                            this.closeModal("Update", this.drugId);
                        }
                    } else {
                        this._alrtsvc.error(MsgConfig.UPDATE_FAIL);
                        this.closeModal("Update", this.drugId);
                    }
                } else {
                    await this.saveDrugCat(newlyAddOrDeleList);
                    this.closeModal("Update", this.drugId);
                }
            }
        }
    }

    async saveDrugCat(drugCatData) {
        if (this.drugCatFG && this.drugCatFG.dirty) {
            const resp = await this._editDrgSvc.addOrUpdateDrugCategory(drugCatData).toPromise();
            if (resp) {
                const oldData = this.drugCatNew ? null : {"Categories": this.drugCatFGOldData};
                const newData = {"Categories": (this.drugCatFG.value["categories"].join(", ")).toString()};
                this._newAuditUtils.getChangedValues(
                    oldData,
                    newData,
                    "Drug Categories",
                    "Drug",
                    this.drugId,
                );
            }
        }
    }

    async handleAddResponse(resp, type?: any) {
        this.HeaderType = null;
        if (resp !== -1) {
            if (resp === 2) {
                this.resetTab();
                this._alrtsvc.error("Quick Code must be unique.", false, "long");
                if (document.getElementById("QuickCode")) {
                    document.getElementById("QuickCode").focus();
                }
            } else if (resp === 1) {
                this.resetTab();
                this._alrtsvc.error("NDC code must be unique.", false, "long");
                if (document.getElementById("DrugCode")) {
                    document.getElementById("DrugCode").focus();
                }
            } else {
                this.HeaderType = type;
                this.drugId = resp;
                // audit log
                const systemData = this.systemData$["source"]["value"];
                const priceSchedule = this.priceSchedule$["source"]["value"];
                const drugStatus = [{'Id': true, 'Name': 'Active'}, {'Id': false, 'Name': 'InActive'}];
                const drugFdaApproved = [{'Id': 0, 'Name': 'No'}, {'Id': 1, 'Name': 'Yes'}, {'Id': 2, 'Name': 'Unknown'}];
                const DropdownsData = {"NdcQualifier": systemData.NdcQualifier, "UnitDoseIndication": systemData.UnitDoseIndication, "FDA_Form" : this.dropDownValues.FDA_Form, "PriceScheduleList": priceSchedule.PriceScheduleList, "Vendor": this.dropDownValues.Vendor, "drugStatus": drugStatus, "DrugType": systemData.DrugType, "fdaApproved": drugFdaApproved};
                const drugDataModel = new DrugAuditModel().drugInfo;
                this._auditUtils.saveAuditOnAdd(
                    this.drugFG.getRawValue(),
                    "Add Drug",
                    "Drug",
                    this.drugId,
                    drugDataModel,
                    DropdownsData
                );
                if (this._commonService.overridePrivNotes$["source"]["value"] && this._commonService.overridePrivNotes$["source"]["value"].frm == 'Drug') {
                    this._notesLogSvc.addNotesLog('Drug',resp, {Note: [this._commonService.overridePrivNotes$["source"]["value"].Note], NoteCat : null}).subscribe(resp => {
                        if (resp) 
                            this._commonService.storeOverridePrivNotes(null);
                    })
                }
                this._alrtsvc.success(MsgConfig.SAVE_SUCCESS);
                const drugInfo = this.drugFG.getRawValue();
                if (drugInfo["Drug"] && drugInfo["Drug"]["DrugTypeId"] === 2) {
                    await this.saveCompoundDrug();
                }
                if(this.currTab === "MISCINFO"){
                    this.currTab = "DRUGINFO"
                }
                this.doAddDrugToBucket = false;
                setTimeout(() => {
                    this.doAddDrugToBucket = true;
                }, 100);
            }
        } else {
            this._alrtsvc.error(MsgConfig.SAVE_FAIL);
            this.closeModal("Cancel");
        }
    }

    afterAddDrugToBuckComplete(val?: any) {
        this.doAddDrugToBucket = false;
        if (!this.HeaderType) {
            this.closeModal("Add", this.drugId);
        } else {
            this.getDrugInfo(this.HeaderType);
        }
    }

    async addDrug(type?: any) {
        this.DrugList = [];
        const drugFGValue = this.drugFG.getRawValue();
        if (this.drugFG.valid && drugFGValue["Drug"] && parseFloat(drugFGValue["Drug"]["UnitPriceAWP3Digits"]) !== 0) {
            this.formGroupInvalid = false;
            if (drugFGValue && drugFGValue["Drug"] && drugFGValue["Drug"]["DrugTypeId"] === 2 && await this.checkHasCompMiscInfo()) {
                this._alrtsvc.warning(" Please enter the compound related values for the compound drug.");
                this.isFmComp = true;
                this.currTab = "MISCINFO";
            } else if (drugFGValue && drugFGValue["Drug"] && drugFGValue["Drug"]["DrugTypeId"] === 2 && await this.checkHasCompMiscInfo("add")) {
                this.isFmComp = false;
                this._alrtsvc.warning("You can not save compound drug without ingredients");
                this.openCompoundDrug();
            }  else {
                this.dropDownValues.FDA_Form.map((id: any) => {
                    if (id.FDAFormID === drugFGValue["Drug"]["DrugFormId"]) {
                        this.DrugFromName = id.FormName;
                    }
                });
                const PrefDrugStng = this._commonService.getSetttingValue(
                    "DrugSettings", "ONE_PREF_DRUG_PER_CLASS");
                    if (PrefDrugStng === "1" && drugFGValue["Drug"] && this.drugFG.controls["isFromMMS"].value && drugFGValue["Drug"]["IsPreferred"]) {
                        const EquList = await this._drugSvc.getEquivalentDrug(null, null, false, null, false,
                             null, false, drugFGValue["Drug"]["Therapeutic"], drugFGValue["Drug"]["TherapeuticCode"]).toPromise();
                        const PrefList = EquList?.filter((item: any) => {
                            return item.ispreferred;
                        });
                        if (PrefList && PrefList.length > 0) {
                            PrefList.map((item) => {
                                if (item && item.ispreferred) {
                                    const name: any =
                                        (item.drugname
                                            ? item.drugname.trim().toUpperCase()
                                            : "") +
                                        " " +
                                        (item.strength
                                            ? item.strength.trim().toUpperCase()
                                            : "") +
                                        " " +
                                        (item.drugformname
                                            ? item.drugformname.trim().toUpperCase()
                                            : "") +
                                        " - " +
                                        (this._formatsUtil.getNDCFormat(item?.drugcode));
                                    if (name) {
                                        this.DrugList.push(name);
                                    }
                                }
                            });
                            this.selectedDrugItem =
                                (drugFGValue["Drug"] && drugFGValue["Drug"]["Name"]
                                ? drugFGValue["Drug"]["Name"].trim().toUpperCase()
                                : "") +
                             " " +
                            (drugFGValue["Drug"] && drugFGValue["Drug"]["Strength"]
                            ? drugFGValue["Drug"]["Strength"].trim().toUpperCase()
                            : "") +
                            " " +
                            (this.DrugFromName
                            ? this.DrugFromName.trim().toUpperCase()
                            : "") +
                            " - " +
                            (drugFGValue["Drug"] && drugFGValue["Drug"]["DrugCode"] ? this._formatsUtil.getNDCFormat(drugFGValue["Drug"]["DrugCode"]): null);
                            // (drugFGValue["Drug"] && drugFGValue["Drug"]["DrugCode"]
                            // ? drugFGValue["Drug"]["DrugCode"].substr(0, 5) +
                            // "-" +
                            // drugFGValue["Drug"]["DrugCode"].substr(5, 4) +
                            // "-" +
                            // drugFGValue["Drug"]["DrugCode"].substr(9, 11)
                            // : null);
                            this.typeVal = type;
                            this.modalRefIsPref = this._modalService.open(this.MarkAsPrefDrug, {
                            centered: true,
                            backdrop: false,
                            keyboard: false,
                            windowClass: "large--content",
                            });
                        } else {
                            this.drugFG.controls["Drug"].patchValue({IsPreferred : true});
                            this.saveDrugContinue(type);
                        }
                    } else {
                        this.saveDrugContinue(type);
                    }
            }
        } else {
            this._formUtils.validateAllFormFields(this.drugFG);
        }
    }

    resetTab() {
        this.resetToFirst = false;
        setTimeout(() => {
            this.resetToFirst = true;
        }, 100);
    }

    async saveCompoundDrug() {
        let saveComp = false;
        const dataToPost = this._drugStore.drugCompInfo$["source"]["value"];
        if (dataToPost && dataToPost.list && dataToPost.list.length > 0) {
            saveComp = true;
            dataToPost["CompDrugId"] = this.drugId;
            dataToPost.list.map(elem => {
                elem.CompDrugId = this.drugId;
                return elem;
            });
        }
        const miscData = this.drugMiscFG.getRawValue();
        if (miscData["DrugLot"] && miscData["DrugLot"].length > 0) {
            miscData["DrugLot"].map((cntrl, i) => {
                miscData["DrugLot"][i]["DrugId"] = this.drugId;
            });
        }
        miscData["DrugMisc"]["DrugId"] = this.drugId;
        await this._editDrgSvc.updateMisc(miscData).toPromise();
        if (saveComp) {
            const resp = await this._editDrgSvc.AddIngredientDrug(dataToPost).toPromise();
            if (!resp) {
                this._alrtsvc.error(MsgConfig.WRONG_MSG);
            }
        }
    }

    openCompWithData(value) {
        this.compoundDrugData = value;
        this.openCompoundDrug();
    }

    openCompoundDrug() {
        if (!this._privMask.canActivate(AccCategoryE.DrugFile,DrugFileE.CompoundFormulation)) {
                this._alertSvc.error(MsgConfig.NO_PRIVS_ACCESS);
         }
        else
        {
            const modelRef = this._modalService.open(
                CompIngredListComponent,
                {
                    size: "lg",
                    backdrop: "static",
                    keyboard: false,
                    windowClass: "md-x-lg",
                    centered : true   
                }
            );
            modelRef.componentInstance.DrugData = this.compoundDrugData;
            modelRef.componentInstance.DrugId = this.drugId;
            modelRef.componentInstance.IsFromDrug = true;
            modelRef.componentInstance.ErxPrescribedDrugs = this._drugStore.drugPrescribedCompInfo$["source"]["value"];
            modelRef.componentInstance.EmitDrugData.pipe(takeUntil(this.unsubscribe$)).subscribe(drugData => {
                this._drugStore.storeCompInfo(drugData);
                this.compoundDrugData = drugData;
                this.confirmToChangeCompValues();
                modelRef.close();
            });
        }

    }

    confirmToChangeCompValues() {
        const newUnitAwp = (this.compoundDrugData.totalAwp / this.compoundDrugData.totalCount).toFixed(3);
        const oldUnitAwp = this.drugFG.value["Drug"] && this.drugFG.value["Drug"]["UnitPriceAWP"] ? this.drugFG.value["Drug"]["UnitPriceAWP"] : 0;
        const newUnitCost = (this.compoundDrugData.totalCost / this.compoundDrugData.totalCount).toFixed(3);
        const oldUnitCost = this.drugFG.value["Drug"] && this.drugFG.value["Drug"]["UnitPriceCost"] ? this.drugFG.value["Drug"]["UnitPriceCost"] : 0;
        if (
            (oldUnitAwp && parseFloat(newUnitAwp) !== parseFloat(oldUnitAwp) &&
                (Math.abs(parseFloat(newUnitAwp) - parseFloat(oldUnitAwp)) > 0.05))
            ||
            (oldUnitCost && parseFloat(newUnitCost) !== parseFloat(oldUnitCost) &&
                (Math.abs(parseFloat(newUnitCost) - parseFloat(oldUnitCost)) > 0.05))
        ) {
            this.modalRef = this._modalService.open(this.COMPDRUGPATCH, { backdrop: false, windowClass: "large--content", centered: true, keyboard: false});
        }
    }

    patchCompValuesToDrug() {
        this.drugFG.controls["Drug"].patchValue({
            QtyPack: this.compoundDrugData.totalCount.toFixed(3),
            AWPPack: this.compoundDrugData.totalAwp.toFixed(2),
            CostPack: this.compoundDrugData.totalCost.toFixed(2),
            UnitPriceAWP: (this.compoundDrugData.totalAwp / this.compoundDrugData.totalCount).toFixed(4),
            UnitPriceAWP3Digits : (this.compoundDrugData.totalAwp / this.compoundDrugData.totalCount).toFixed(3),
            UnitPriceCost: (this.compoundDrugData.totalCost / this.compoundDrugData.totalCount).toFixed(3)
        });
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
    }

    async continueMarkPref() {
        if (this.modalRefIsPref) {
            this.modalRefIsPref.close();
            this.modalRefIsPref = null;
        }
        this.drugFG.controls["Drug"].patchValue({Id: 0});
        await this._drugSvc.getUpdatePrefDrug(this.drugFG.controls["Drug"].value).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if (resp && resp.length > 0) {
                resp.map((item: any) => {
                    if (item.DrugId === 0) {
                        this.drugFG.controls["Drug"].patchValue({IsPreferred : item.IsPreferred});
                        this.saveDrugContinue(this.typeVal);
                    }
                });
            }
        });
    }
    manFactForDrug(event){
        this.manuFactDrug = event;
    }
    cancelMarkPref() {
        if (this.modalRefIsPref) {
            this.modalRefIsPref.close();
            this.modalRefIsPref = null;
        }
        this.drugFG.controls["Drug"].patchValue({IsPreferred : false});
        this.saveDrugContinue(this.typeVal);
    }

    async saveDrugContinue(type) {
        this.isFmComp = false;
        this.drugFG.controls["Drug"].patchValue({Instructions: " "});
        const resp = await this._editDrgSvc.addNewDrug(this.drugFG.value["Drug"]).toPromise();
        this.handleAddResponse(resp, type ? type : null);
    }

    drugPreferred(event) {
        this.drugPreferredStatus = null;
        this._cdr.detectChanges()
        this.drugPreferredStatus = event ? "true" : "false";
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}