import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { UserService } from './user.service';
import { wijimoIdsList, WjGridSaveModel } from '../models';
import { CommonUtil } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class WijmoAutoSaveService {
  savedData: any = []
  excludeheaders =  [" ", null, "Action", "Actions", "Select", "View Docs", "Tag", "ACTIONS", "View Trans Result", "IsDeleted?"];
     excludeWijmoKeys  = ["prescListWJ","insuListWJ","insuListWJ","facilityListWJ","priceSchdListWJ","discountListWJ","drugWarnListWJ","diagnosisListWJ","rxFollowUpListWJ","patCategoryListWJ","presSpecalityListWJ","payorFileListWJ","pharmacyListWJ","drugSearchListWJ","drugCatoriesList","sigListWJ","intakeQueueWjList","drugOtherPriceswj","patDiagnosisListWJ","DocumentsListWJ","patClinicalInfoListWJ","prescHistoryListWJ","drugMiscInfoListWJ","batchScanListWJ","reviewdrugVerfWJ","patListWJ","priceListWJ","doseScheduleListWJ","vendorListWJ","usersListWJ","rolesListWJ","loginHistoryListWJ","overrideLogListWJ","phyInvListWJ","enterInvListWJ","bucketListwj","invTransListWJ","faxLogListWJ1","faxLogListWJ2","batchedFaxListWJ","patAdditionalInsuListWJ","drugInsuRestListWJ","patDeliveryAddListWJ","drugInvVendorListWJ","scheduledTaskListWJ","recallDeletedRxListWJ","drugPriceUpdateListWJ","primeescSignByDateListWJ","printRxWithAttDocsListWJ","scheduledTaskExecHistListWJ","fileRecordsWJ","topPerfomersPatientListWj","topPerfomersInsideListWj","topPerfomersPrescriberListWj","topPerfomersDrugListWj","topPerfomersInsListWj","topPerfomersFacListWj","CntrlRxAuditTrailWj","logicalAccessCtrlWj","messageLogWj","messageQueueWj","geographyDistributionWj","drugThearpauticWj","labelDesignListWJ","manageInvwj","physicalInvWj","receiveInvWj","dispensedInvWj","DURValNewRxWJ","prescClinicAddListPopupWJ","prescClinicAddListWJ"];
  constructor(private _commonSer: CommonService, private _userService: UserService, private _comnUtil: CommonUtil) { }

  async storeWijimoStatus(flex, WijmoKey,wjHeaders, clientWidth) {
    this.savedData = [];
    this.checkGridWidthAtSave(flex,WijmoKey,wjHeaders, clientWidth);
    const d = this.getWJSavedData(true,WijmoKey);
    let data: WjGridSaveModel = new WjGridSaveModel();
    if (d) {
        data = d;
    }
    data["WijmoKey"] = WijmoKey;
    data["WijmoId"] = wijimoIdsList[WijmoKey];
    data["ColumnOrder"] = JSON.stringify(flex);
    this.savedData = data;
    await this._comnUtil.saveWjGridDet(data);
}

checkGridWidthAtSave(flex,WijmoKey,wjHeaders,clientWidth) {
    if (clientWidth) {
        const gridWidth = +clientWidth;
        let actionColumnWidth = 0;
        let visibleColumnsCount = 0;
        let visibleColumnsWidth = 0;
        let wjHeadersWidth = 0;
        flex.map(v => {
            wjHeaders.map((wj) => {
                if (v.header === wj.hName && v.isVisible && !(this.excludeheaders.includes(v.header)) ) {
                        visibleColumnsCount++;
                        visibleColumnsWidth = visibleColumnsWidth + v.width;
                        wjHeadersWidth = wjHeadersWidth + wj.width;
                }
            });
            if (this.excludeheaders.includes(v.header)) {
                actionColumnWidth = actionColumnWidth + v.width;
            }
        });
        const isExcludeCheck = this.excludeWijmoKeys.includes(WijmoKey);
        if (visibleColumnsWidth < gridWidth) {
            const diff  = (gridWidth - visibleColumnsWidth) - actionColumnWidth - 7;
            const eachWidth = diff > 0 ? diff / visibleColumnsCount : 0;
            flex.map(v => {
                wjHeaders.map((wj) => {
                            if (v.header === wj.hName) {
                                if (!(this.excludeheaders.includes(v.header)) && (v.isVisible === true)) {
                                    if (visibleColumnsCount <= 11) {
                                        v.width = isExcludeCheck ? wj.width :  (eachWidth>=0 ?  v.width + eachWidth : v.width);
                                    } else {
                                        v.width = wj.width ; 
                                    }
                                } 
                            } else if (this.excludeheaders.includes(v.header)) {
                                v.width = isExcludeCheck ? wj.width :  v.width;
                            }
                });
            });
        } else {
            flex.map(v => {
                wjHeaders.map((wj) => {
                            if (v.header === wj.hName) {
                                if (!(this.excludeheaders.includes(v.header)) && (v.isVisible === true)) {
                                    v.width = isExcludeCheck ? wj.width : v.width;
                                }  
                            }  else if (this.excludeheaders.includes(v.header)) {
                                v.width = v.width;
                            }
                });
            });
        }
    }
}
getWJSavedData(type: boolean, WijmoKey) {
  const savedData = this._commonSer.wjSavedList$["source"]["value"];
  let result = null;
  if (savedData && savedData.length) {
      const data = savedData.find(v => v["WijmoKey"] === WijmoKey && v["UserId"] === +this._userService.getToken("UserId"));
      if (data) {
          result = type ? data : JSON.parse(data["ColumnOrder"]);
      }
  }
  return result;
}
}