import { InsuCarrier, Insurances } from "./patient-Info.model";
import { InsuTransOther } from "./insurance.model";
import { DME, RxExtraValues, WCOM } from "./rx-popup.model";
import { Documents } from ".";

export class RxModel {
    Prescription: Prescription = null;
    PrescReFill: PrescReFill = null;
    RxBill: RxBill = null;
    RxFile: ERxFile = null;
    RefillInsu: RefillInsu = null;
    SigCodes: SigCodes = null;
    RxOtherAmount: RxOtherAmount = null;
    Prescriber: EPrescriber = null;
    Patient: EPatient = null;
    Drug: EDrug = null;
    RxAdminDoseSchedule: RxAdminDoseSchedule = null;
    HOADetails:IHoaDoseSchedule = null;
    insuranceInfo: EInsuranceInfo = null;
    drugPickVerif?: DrugPickVerif[] = null;
    prescRefill_Observtions: PrescRefill_Observtions = null;
    rxDelivery?: RxDelivery = null;
    rPHVerifs?: RPHVerifs[] = null;
    PrescRefill_RxTags?: PrescRefill_RxTags = null;
    trackRx?: TrackRx = null;
    drugPickVerificationStatus: string = null;
    rph1VerificationStatus: string = null;
    rph2VerificationStatus: string = null;
    FollowUpStatus: string = null;
    RphAppUserId: number = null;
    islatestrefill: number = null;
    ResponseStatus: string = null;
    compounddrugs: Compounddrugs = null;
    IsDocumentExists : boolean  = false;
    DueDtTm: string = null;
    FileDtTm: string = null;
    RxPay?: RxPay[] = null;
    isnewpatient: number = null;
    isOriginalRx: boolean = null;
    pickupRx: PickupRx = null;
    dme: DME = null;
    workmancomp: WCOM = null;
    pharmacy?: EPharmacy = null;
    refillNumberasPerSettings: any = null;
    eRxDetailsViewModel: ERxDetailsViewModel = null;
    RxExpirationDate: string = null;
    Documents: Documents[] = null;
    MaxDailyDose: any = null;
    FaxRefillId: number = 0;
    IsPrescnumExists: boolean = false;
    IsMedGuideExists: boolean = false;
    rphverifDtTm: any = null;
    IsFromTransmissionRejectScreen: any = null;
    rxExpiryDateByInsuranceOrControlSettings: string = null;
    rxRelatedCodeInfo: ECodeInfo = null;
    TreatmentTypeId: number = null;
    TreatmentTypeName: string = null;
    OrigProFee = 0;
    DrugPickOffsetAlgorithm: string =null;
    IsDURExists:any = null;
    refillinsu: RefillInsuPriority[] = null;
    islatestpartialrefill: number = null;
    IouDetails: IouDetails = null;
    SubClarifIdComb: any = null;
    IsDSCSAExists : boolean = false;
    RefillInsuList :any = null;
    isSupplyDaysChanged: boolean = false;
    PriorApp: any = null;
    rxExtraInfo: RxExtraValues = null;
}
 export class RxNotes {
    AppUserId: string = null;
    CreateDtTm: string = null;
    Id: string = null;
    IsActive: boolean = false;
    IsDeleted: boolean = false;
    IsShownAsPopup: boolean = false;
    ModifiedDtTm: string = null;
    Name: string = null;
    NoteCatId: string = null;
    TenantId: string = null;
    xNote: string = null;

 }
export class Prescription {
    UserName: string = null;
    Id: number = null;
    TenantId: number = null;
    PrescNum: number = null;
    PrescSN: string = null;
    PrescriberId: number = null;
    PatientId: number = null;
    DrugId: number = null;
    Qty: number = null;
    ReFillNum: number = null;
    Remarks: string = null;
    Days: number = null;
    IsDiscontinued: boolean = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    RxRecdById: number = null;
    UpdatedById: number = null;
    RxReceiptDtTm: any = null;
    RxStatusId = 1;
    PAType: string = null;
    IsAllType4AllRef: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
    IsManualRx: boolean = null;
    PrescriberAddressId: any = null;
    OldPrescNum:number = null;
    isAlreadyProcessed: boolean = false;
}

export class PrescReFill {
    IsNewPrescRefill: boolean = null;
    isVerified: boolean = null;
    PrescNum: string = null;
    DawCode: any = null;
    TechFirtsName: any = null;
    TechLastName: any = null;
    PharmFirstName: any = null;
    PharmLastName: any = null;
    UpdatedByUser: any = null;
    IsActive: any = null;
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    DrugId: number = null;
    ReFillNum: number = 0;
    RxBillId: number = null;
    SalesPerId: number = null;
    DeliveryAddsId: number = null;
    DeliverModeId: number = null;
    FillDtTm: any = null;
    NextFillDt: any = null;
    StatusId = 1;
    DeliveryStatusId: number = null;
    DeliveryPerId: number = null;
    LotNum: string = null;
    ExpDt: any = null;
    OrderDtTm: string = null;
    OrderDays = 0;
    SupplyDays = 0;
    PriorAuthNum: string = null;
    TechInitials: string = null;
    RxOriginId: number = null;
    RegPharmacist: string = null;
    UnitPriceAWP: number = null;
    TotalRxCost: number = null;
    UnitPriceUNC = 0;
    PriceSchId: number = null;
    DiscSchId: number = null;
    Price = 0;
    ProfFee = 0;
    OtherAmnt: number = null;
    Discount: number = null;
    CoPay: number = null;
    DispQty = 0;
    OrdQty = 0;
    NoOfLabels: number = null;
    IsPRN: boolean = null;
    IsNonRx: boolean = null;
    IsHOADefined:boolean = null;
    TechnicianId: number = null;
    PharmacistId: number = null;
    RxTransStateId: number = null;
    IsDeleted: boolean = null;
    DeleteDtTm: Date = null;
    IsRxHold: boolean = null;
    DAWId: number = null;
    UpdatedById: number = null;
    Is340B: boolean = null;
    BucketId: number = null;
    SplProgramId: number = null;
    IsControlSubmitted: boolean = null;
    IsControlSubmittedToPh:boolean = null;
    IsCopayPaid: boolean = null;
    ControlSubmittedToPhDtTm: Date = null;
    ControlSubmittedDtTm: Date = null;
    PhSubmissionStatusId: any = null;
    PAType: string = null;
    BalDue = 0;
    TotalAmt = 0;
    GroupTransStatus: string = null;
    IsControlRxRecalled: boolean = null;
    OrderStatus: string = null;
    LotExpiryDate: Date = null;
    IsManuaBalanceDue = false;
    CreatedDtTm: string = null;
    ModifiedDtTm: any = null;
    SubmissionStatusId: any = null;
    PartialFillNo = 1;
    PartialIndStatus: string = null;
    QtyRemRef = 0;
    erQtyRem = 0;
    BucketName: string = null;
    OrderDtTmCopy: string = null;
    FillDtTmCopy : string = null;
    OrigProfFee: any = null;
    DrugLotId: any = null;
    BillingRefNum : any = null;
    OweQty = 0;
    IsIou: boolean = false;
    IsEquivalentDisp: boolean = false;
    IsOverFillRx: boolean = false;
    primeRxAuthRefillCount: any = null;
    ImmunizationId: any = null;
    NumberOfFills: any = null;
    isCostRefreshed : boolean = null;
    isLotExpDisabled : boolean = null;
    DSCSAId: number = null;
    ReferencePrescReFillId : number = null;
    TotalRxAmount : number = null;
    CarryFwdBillSequence:boolean = true;
}

export class PartialCummulativeDetails {
    PrescId: number = null;
    ReFillNum: number = null;
    TenantId: number = null;
    TotCoPay: number = null;
    TotDispQty: number = null;
    TotFills: number = null;
    TotIngCost: number = null;
    TotOrdDays: number = null;
    TotOrdQty: number = null;
    TotOtherAmnt: number = null;
    TotRemQty: number = null;
    TotSupplyDays: number = null;
    TotUNC: number = null;
}

export class RxBill {
    RxStatus: any = null;
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    DrugId: number = null;
    Qty: number = null;
    DosageInstId: number = null;
    DrugPriceId: any = null;
    ServiceChrg: number = null;
    DeliveryChrg: number = null;
    InsuChrg: number = null;
    CoPayChrg = 0;
    StatusId = 1;
    BillAs: string = null;
}

export class RefillInsu {
    Id: number = null;
    TenantId: number = null;
    RefillId: number = null;
    InsuId: any = null;
    InsuPriId = 1;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    InsurerCode: string = null;
}

export class SigCodes {
    Id: number = null;
    TenantId: number = null;
    RxRefillId: number = null;
    SigCode: string = null;
    Description: string = null;
    AlternateLangDescription: string = null;
}

export class RxOtherAmount {
    Name: any = null;
    Remarks: any = null;
    OtherAmntMisc?: OtherAmntMisc[] = [];
    Id: number = null;
    TenantId: number = null;
    PrescReFillId: number = null;
    ProfFee: number = null;
    Incentive: number = null;
    FlatSalesTax: number = null;
    SalesTaxPer: number = null;
    SalesTax: number = null;
    FaxeSalesTax: number = null;
    SalesTaxBasis: any = null;
    PaidAmnt: any = null;
    CostBasisCatId: any = null;
    IsManaulSaleTax = false;
    CostBasisCatName: string = null;
    SalesTaxBasisName: string = null;
    OtherAmntCatName: string = null;
}



export class OtherAmntMisc {
    OtherAmntCatId = 1;
    Amount = 0.0;
}

export class EPrescriber {
    prescriberid: number = null;
    prescriberfirstname: string = null;
    prescribermiddlename: any = null;
    prescriberlastname: string = null;
    prescriberquickcode: string = null;
    prescriberphoneid: number = null;
    prescriberemailid: number = null;
    prescribernotes: string = null;
    activestatusid: number = null;
    prescriberlicensenumber: string = null;
    medaidnum: string = null;
    prescriberdeanum: string = null;
    otheridnum: string = null;
    statespid: string = null;
    prescribercatname: string = null;
    prescribertypeid: number = null;
    prescribercatcode: string = null;
    prescidtypename: string = null;
    prescriberidtypeid: number = null;
    prescriberemail: string = null;
    prescriberaddress1: string = null;
    prescriberzipcode: string = null;
    prescribercity: string = null;
    prescriberstate: string = null;
    prescribernpinum: string = null;
    prescriberspinum: string = null;
    presoncreateddate: string = null;
    personmodifieddate: string = null;
    mobile: string = null;
    telephone: string = null;
    worknumber: string = null;
    fullname: string = null;
    faxnumber: string = null;
    prescprimaryspeciality: any = null;
    prescsecondaryspeciality: any = null;
    presctertiaryspeciality: any = null;
    prescprimaryspecialityid: number = null;
    prescsecondaryspecialityid: number = null;
    presctertiaryspecialityid: number = null;
    fulladdress: any = null;
    PrescriberAddresses?: PrescriberAddressesEntity[] = null;
    primeAddressId: number = null;
    facilityId: number = null;
    IsPrescNotestAvailable: boolean = false;
    prescriberUniqueId: string = '';
}

export class PrescriberAddressesEntity {
    StateName: string = null;
    SPINum: string = null;
    CityName: string = null;
    Id: number = null;
    TenantId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    ZipCode: string = null;
    CountryId: number = null;
    StateId: number = null;
    CityId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
}

export class EPatient {
    personcreateddate: string = null;
    telephone: string = null;
    address1: string = null;
    fulladdress: string = null;
    worknumber: string = null;
    personmodifieddate: string = null;
    gender: string = null;
    dob: string = null;
    city: string = null;
    lastname: string = null;
    zipcode: string = null;
    mobile: string = null;
    email: string = null;
    patientid: number = null;
    addresscreateddate: string = null;
    addressmodifieddate: string = null;
    chartnum: string = null;
    address2: string = null;
    firstname: string = null;
    langname: string = null;
    fullname: string = null;
    state: string = null;
    middlename: string = null;
    isdeceased: boolean = null;
    insurance?: InsuranceEntity[] = null;
    CustomCategory?: string[] = null;
    actstatus: number = null;
    tenantid: number = null;
    insuranceid: any = null;
    PatientFacilityInfo: PatientFacilityInfo = null;
    PatientInfo: EPatientInfo = null;
    patallergies?: PatallergiesEntity[] = null;
    notes: string = null;
    specialProgramId: number = null;
    isEZCapReq: boolean = null;
    isRefillReminderSet: boolean = null;
    isPrintAllowed: boolean = null;
    isPkgAllowed: boolean = null;
    DiscSchCd: string = null;
    BrandPriceCd: string = null;
    GenpriceCd: string = null;
    IsAppliedCashRx: boolean = null;
    Diagnosis?: string[] = null;
    patFamilyId: number = null;
    patFamilyName: string = null;
    isPatientAck: boolean = null;
    privacyAckDt: any = null;
    gendername: string = null;
    Charge: any = null;
    ConcatenatedInsuTypes: any = null;
    ConcatenatedPolicyNums: any = null;
    Is340B: boolean = null;
    pdcscore: any = null;
    packagingPatient: string = '';
    InterfaceId: number = 2;
    IsPatientNotestAvailable: boolean = false;
    patientUniqueId: string = '';
    // IsWorkflowPatient: boolean = null;
}
export class InsuranceEntity {
    patientidtype: any = null;
    insuranceid: number = null;
    insurerid: number = null;
    insucardid: number = null;
    cardholderfirstname?: string = null;
    cpgenericval?: number = null;
    relation?: string = null;
    insugrpid: number = null;
    insuPriId?: number = null;
    pernum?: string = null;
    cpbrandval?: number = null;
    chlastname?: string = null;
    groupname: string = null;
    expirydt?: string = null;
    idproofid: any = null;
    insurancename: string = null;
    copaycatid: any = null;
    insuplanId: number = null;
    policynum: string = null;
    relcatid?: number = null;
    insuType: string = null;
    binnum: string = null;
    InsuPriority?: string = null;
    InsurerPhone: any = null;
    IsCash: boolean = null;
    InsurerCode: string = null;
    ProcessorNum?: string = null;
    Id: number = null;
    NcpdpId?: number = null;
    hasPayRecords: boolean = null;
}
export class PatientFacilityInfo {
    PatFacility: PatFacility = null;
    Facilities?: FacilitiesEntity[] = null;
    Organization: Organization = null;
    FacLocation?: FacLocationEntity[] = null;
    FacStation?: FacStationEntity[] = null;
}
export class PatFacility {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    FacilityId: number = null;
    PrescribName: string = null;
    AltPresName: string = null;
    PrimPrescbId: number = null;
    FacLocId: number = null;
    FacStationId: number = null;
    FacPatNum: string = null;
    AdmitDt: Date = null;
    FloorNum: number = null;
    RoomNum: string = null;
    BedNum: number = null;
    IsCycleFillAllowed: boolean = null;
    IsUnitDoseAllowed: boolean = null;
    IsPrintMedSheet: boolean = null;
    DiagnosisNote: string = null;
    OrderNote: string = null;
    DietNote: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
    PrimePrescName: string = null;
    FacilityName: string = null;
    FacLocationName: string = null;
    FacStationName: string = null;
}
export class FacilitiesEntity {
    Id: number = null;
    FacCode: string = null;
    IsCMSQ: boolean = null;
    Facility_Id: string = null;
    POFormCode: string = null;
    POTextCode: string = null;
    Remarks: string = null;
    Beds: string = null;
    FacilityCatId: any = null;
    StandingOrder: string = null;
    PharmServiceCatId: number = null;
    PatResiCatId: number = null;
    IsPrintStndgOrd: boolean = null;
    IsPrintPatTreat: boolean = null;
    IsPrintHOA: boolean = null;
    IsPrintDosePerDay: boolean = null;
    IsRxAdmin: boolean = null;
}
export class Organization {
    Id: number = null;
    TenantId: number = null;
    Name: string = null;
    ContPerson: string = null;
    WebURL: string = null;
    PrimeAddsId: number = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    SwitchCD: any = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
}
export class FacLocationEntity {
    Id: number = null;
    TenantId: number = null;
    FacilityId: number = null;
    FacLocNum: number = null;
    Name: string = null;
    Address?: string = null;
    Phone?: string = null;
    Remarks: any = null;
    IsActive: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
}
export class FacStationEntity {
    Id: number = null;
    TenantId: number = null;
    FacilityId: number = null;
    FacStnNum: number = null;
    Name: string = null;
    Remarks: any = null;
    IsActive: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
}
export class EPatientInfo {
    IdProofType: any = null;
    SmokerCode: string = null;
    DeliveryMode: string = null;
    PregnancyCode: string = null;
    NameSuffix: any = null;
    Saluation: string = null;
    DiscountSchName: string = null;
    Id: number = null;
    TenantId: any = null;
    PatientId: number = null;
    DeliveryModeId: any = null;
    PregnancyCdId: any = null;
    SmokerCdId: any = null;
    ServicePlaceId: any = null;
    EmployerNum: string = null;
    HomePlan: string = null;
    HomePlanNum: string = null;
    FacCode: any = null;
    SSNum: string = null;
    DriveLicenseNum: string = null;
    PrimPrescbId: any = null;
    EligibleCdId: any = null;
    SalutationId: any = null;
    NameSuffixId: any = null;
    SpeciesCdId: any = null;
    IsDeceased: boolean = null;
    SalesPersonId: any = null;
    IsPrivacyAcked: boolean = null;
    PrivacyAckDt: string = null;
    ResidenceCatId: any = null;
    IsFullyInsured: boolean = null;
}
export class PatallergiesEntity {
    ClassId: number = null;
    Name: string = null;
    AllergyCatId: number = null;
    Description?: string = null;
}
export class EDrug {
    awppack: number = null;
    costpack: number = null;
    directunitprice: number = null;
    discontdt: string = null;
    drugbandname: string = null;
    drugclass: number = null;
    drugcode: string = null;
    drugcreateddate: string = null;
    drugformid: number = null;
    measureunitname: string = null;
    drugtypeid: number = null;
    measureunitid: number = null;
    drugtype: string = null;
    drugunitid: number = null;
    drugunit: string = null;
    priceschdid: number = null;
    genericname: string = null;
    id: number = null;
    isactive: boolean = null;
    ispreferred: boolean = null;
    isdeleted: boolean = null;
    manufactid: number = null;
    billingndc: string = null;
    brandorgenndc: string = null;
    replacendc: string = null;
    oldndc: string = null;
    upc: string = null;
    ndcqualiId: number = null;
    qtypack: number = null;
    drugname: string = null;
    quickcode: string = null;
    ndc: string = null;
    strength: string = null;
    unitdoseindid: number = null;
    unitdoseind: string = null;
    unitpricecost: number = null;
    drugformname: string = null;
    manufacturername: string = null;
    unitpriceawp = 0;
    therapeutic: number = null;
    therapeuticcode: number = null;
    dispensedDate: any = null;
    elasticsearchid: string = null;
    surcharge: number = null;
    perofbrand: number = null;
    isdiscountable: boolean = null;
    isexclfrmupdate: boolean = null;
    ispriceupdateauto: boolean = null;
    drugformSigCodeId: any = null;
    drugformsigcode: any = null;
    druguseSigCodeId: number = null;
    drugusesigcode: string = null;
    sigid: any = null;
    defaultSigCode: string = null;
    instructions: string = null;
    defaultqty: number = null;
    defaultdays: number = null;
    notes: string = null;
    isFDAapprovednull: boolean = null;
    isincinrefillrem: boolean = null;
    isbranded: boolean = null;
    isinvmangd: boolean = null;
    iswarningcodechkd: boolean = null;
    warningcode: any = null;
    qtyInHand: number = null;
    quantityInHandEqui: number = null;
    drugfullname: string = null;
    druglabelname: string = null;
    drugdescription?: any = null;
    dosageinformation?: any = null;
    drugCounsellingDescription: any = null;
    isdiscontdate: string = null;
    lastDispensedDate: string = null;
    genericAvailability: string = null;
    equivalentAvailability: string = null;
    manageinventory: any = null;
    Is340B: boolean = null;
    isDoseCheckDisabled:boolean = true;
    DispUnitFormId: any = null;
    IsDrugNotestAvailable:boolean = false;
    IsDrugReseted: boolean = false;
    drugUniqueId: string = '';
    ExpirationDate:string = null;
    GTIN:string = null;
    LotNum:string = null;
    SerialNumber:string = null;
    IsDiscontinued:boolean = false;
    IsManualDrugEnter:boolean = false;
    ndc10:number = null;
    licenseTypeId: number = null;
    equivalenceCodes: any = null;
    productModifierIds: any = null;
    productId: number = null;
    isAllowDisc: boolean = false;
    DiagnosisId: any = null;
    // IsTriplicate: boolean = null;
}
export class DrugdescriptionEntity {
    ndc: string = null;
    drugitemid: number = null;
    version: number = null;
    descrip: string = null;
    shape: string = null;
    color_frnt: string = null;
    color_back: string = null;
    id_front: string = null;
    id_back: string = null;
    flavorname: string = null;
    coatingname: string = null;
    scoringname: string = null;
}
export class DosageinformationEntity {
    AGEBANDNAME: string = null;
    FromAgeDays: string = null;
    ToAgeDays: string = null;
    MINDAILYUNITS: string = null;
    MAXDAILYUNITS: string = null;
    USUALDAILYUNITS: string = null;
    INGREDIENTNAME: string = null;
    MINDAILYDOSE: string = null;
    MAXDAILYDOSE: string = null;
    USUALDAILYDOSE: string = null;
    FREQUENCY: number = null;
    FREQUENCYDAYS: number = null;
    LIFETIMEMAX: string = null;
    PATIENTEXPERIENCE: string = null;
    DOSINGTYPE: string = null;
    HEPATICIMPAIRMENTMAYREQUIREDOSEADJUSTMENT: boolean = null;
    RENALIMPAIRMENTMAYREQUIREDOSEADJUSTMENT: boolean = null;
}
export class RxAdminDoseSchedule {
    RxAdmin: ERxAdmin = null;
    RxDoseSchs?: ERxDoseSchs[] = null;
    PrescriptionId: number = null;
    dosePerDay: any = null;
}
export class IHoaDoseSchedule {
    StartDate: any = null;
    EndDate:any = null;
    RepeatDays:number = null;
    DosePerDay:number = null;
    FormatId:number = null;
    HOAAJSONModel: IHoaJsonData[] = null;

}
export class IHoaJsonData{
    date:string = null;
    doseDetails: IDoseDetails[] = null;
}

export class IDoseDetails{
    doseTime:string = null;
    doseQty:number = null;
}
export class ERxAdmin {
    Id: number = null;
    TenantId: number = null;
    RxId: number = null;
    PrescRefillId: number = null;
    StartDt: string = null;
    EndDt: string = null;
    RepeatDays: number = null;
    IsActive: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
}
export class ERxDoseSchs {
    Id: number = null;
    TenantId: number = null;
    RxAdminId: number = null;
    DoseSeqNum: number = null;
    DoseQty = 0;
    DoseTime: any = null;
    DoseTimeCatId = 1;
    DoseSlot: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}
export class EInsuranceInfo {
    insurance: Insurances = null;
    organization: IOrganization = null;
    insuCarrier: InsuCarrier = null;
    insuPlan: InsuPlan = null;
    InsuTransOther: InsuTransOther = null;
}

export class IOrganization {
    Id: number = null;
    TenantId: number = null;
    Name: string = null;
    ContPerson: any = null;
    WebURL: any = null;
    PrimeAddsId: number = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    SwitchCD: any = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
}

export class InsuPlan {
    Id: number = null;
    TenantId: number = null;
    InsuCarrierId: number = null;
    PlanCode: string = null;
    PharmacyNum: string = null;
    InsuPlanIdQualifId: number = null;
    ProcessorNum: string = null;
    ProcCatId: number = null;
    PayorId: number = null;
    IsMedicaidSup: boolean = null;
    IsMedigapSup: boolean = null;
    IsMedicareSup: boolean = null;
    MedicaidAgNum: string = null;
    Remarks: string = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
}

export class PrescRefill_Observtions {
    Id: number = null;
    PrescRefillId: number = null;
    IsDrugDisContraInd: boolean = null;
    IsDrugToDrugIntra: boolean = null;
    IsDupDrugTherapy: boolean = null;
    IsDrugAllergy: boolean = null;
    IsPregLact: boolean = null;
    IsAdvrDrugReaction: boolean = null;
    IsDrugLifeStyle: boolean = null;
    CreatedDtTm: any = null;
    ModifiedDtTm: string = null;
}
export class DrugPickVerif {
    UserName: string = null;
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    PrescRefillId: number = null;
    Result: string = null;
    BottleNDC: string = null;
    VerifiedById: number = null;
    VerifDtTm: Date = null;
}
export class RxDelivery {
    Id: number = null;
    TenantId: number = null;
    RxId: number = null;
    IsShipped: boolean = null;
    IsFacility: boolean = null;
    FacilityId: number = null;
    AddressId: number = null;
    RefillNum: number = null;
    PriorityId: number = null;
    ReqdDtTm: Date = null;
    DeliveryInstru: string = null;
    Remarks: string = null;
    DeliveryMode: any = null;
    PrescRefillId: number = null;
    CreatedDtTm: any = null;
    ModifiedDtTm: any = null;
    IsActive: any = null;
    IsDeleted: any = null;

}

export class RPHVerifs {
    UserName: string = null;
    RefillNum: any = null;
    PrescNum: any = null;
    CallFrom: any = null;
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    PrescRefillId: number = null;
    VerifiedById: number = null;
    VerifDtTm: Date = null;
    Observation: string = null;
    Status: string = null;
    Reason: string = null;
}
export class PrescRefill_RxTags {
    Name: string = null;
    Action: string = null;
    Id: number = null;
    TenantId: number = null;
    PrescRefillId: number = null;
    RxTagId: number = null;
    IsDeleted: boolean = null;
}

export class RxPay {
    PrescNum: any = null;
    RefillNum: number = null;
    RejectReason: string = null;
    TransClaimLogId: any = null;
    InsuId: any = null;
    Id: number = null;
    RxId: number = null;
    PrescRefillId: number = null;
    FillNo: number = null;
    CoverageCD: number = null;
    Ins_Code: string = null;
    RecType: string = null;
    CopayOnly: any = null;
    DatePaid: string = null;
    OthPYRPDQ: any = null;
    ICostPaid: any = null;
    DFEEPaid: any = null;
    PTPayment: any = null;
    FSTaxAmt: any = null;
    PSTaxAmt: any = null;
    PSTaxRate: any = null;
    INCAMTPaid: any = null;
    PSFEEPaid: any = null;
    OthAMTPaid: any = null;
    OthPayRecg: any = null;
    TotAmtPaid: any = null;
    OthPayQ1: any = null;
    OthPayAmt1: any = null;
    OthPayQ2: any = null;
    OthPayAmt2: any = null;
    OthPayQ3: any = null;
    OthPayAmt3: any = null;
    TaxExempt: any = null;
    BASISOFREI: any = null;
    BOC_DFEE: any = null;
    BOC_Copay: any = null;
    BOC_FSTax: any = null;
    BOC_PSTax: any = null;
    Auth_No: any = null;
    REJCodes: string = null;
    Amt_PERDED: any = null;
    Amt_SaleTx: any = null;
    Amt_COPINS: any = null;
    Amt_PRODSL: any = null;
    Amt_EXCMAX: any = null;
    PSTaxBasisPaid: any = null;
    AccumDedAmount: any = null;
    RemDedAmount: any = null;
    RemBenefitAmount: any = null;
    AmtProcFee: any = null;
    PatSTaxAmt: any = null;
    PlanSTaxAmt: any = null;
    AmtCoIns: any = null;
    BOC_CoIns: any = null;
    EstGenSavings: any = null;
    SpendAccAmtRem: any = null;
    HPlanFundAmt: any = null;
    AmtProvNetSel: any = null;
    AmtProdSelBrand: any = null;
    AmtProdSelNPForm: any = null;
    AmtProdSelNPFormB: any = null;
    AmtCovGap: any = null;
    IngCostContRAmt: any = null;
    DFeeContRAmt: any = null;
    InsNetPaid: any = null;
    IsDeleted: boolean = null;
    TenantId: number = null;
    DIRFee: any = null;
}
export class PickupRx {
    prescNum: number = null;
    reFillNum: number = null;
    Id: number = null;
    TenantId: number = null;
    RxRefillId: number = null;
    ExpDtTm: string = null;
    IsPickedUp: boolean = null;
    ActDtTm: string = null;
    PkFirstName: string = null;
    PkLastName: string = null;
    RelationCatId: any = null;
    PkIdNum: any = null;
    PkIdProofId: any = null;
    PkIdIssuer: any = null;
    Remarks: any = null;
    HandOverById: any = null;
    SignatureImage: any = null;
}

export class CloneRx {
    PatientId: number = null;
    PrescriberId: number = null;
    DrugId: number = null;
    RxId: number = null;
    IsProcessed: boolean = null;
    eRxId: number = null;
}

export class ErxDetailsData {
    drugName: string = null;
 drugNdc: string = null;
drugStrength: number = null;
erxId: number = null;
erxNote: string = null;
erxOpTransactionId :number = null;
erxOperationId: number = null;
isCompound: boolean = null;
orderedDate: string = null;
patientDOB: string = null;
patientName: string = null;
prescriberName: string = null;
prescriberNpi: string = null;
refills:  number = null;
refillsQualifier: string = null;
rxDaw:  number = null;
rxDays: string = null;
rxNotes: string = null;
rxQty: string = null;
rxSig: string = null;
}

export class RxFollowTag {
    Name: string = null;
    Action: string = null;
    Id: number = null;
    TenantId: number = null;
    PrescRefillId: number = null;
    RxTagId: number = null;
    IsDeleted: boolean = null;
}

export class RxInputParams {
    patientId: number = null;
    prescriberId: number = null;
    drugId: number = null;
    prescNum: string = null;
    refillNum: number = null;
    remarks: string = null;
    rxId: number = null;
    prescRefillId: number = null;
    PartialfillNum: number = null;
}

export class RefillRxStats {
    rxId: number = null;
    status: string = null;
}

export class NoteOrderStatus {
    Id: number = null;
    RxId: number = null;
    DiscontReasonId: number = null;
    PrescReFillId: number = null;
    TenantId: number = null;
    Note: string = null;
}


export class TrackRx {
    rxModifiedDate: Date = null;
    lastModifiedBy: any = null;
    pickUpBy: string = null;
    pickUpRxDate: Date = null;
    rxEnteredDate: Date = null;
    rxEnteredBy: string = null;
    drugPickVerificationStatus: string = null;
    drugPickVerificationBy: string = null;
    drugPickVerificationDate: Date = null;
    pharmacistVerificationStatus: string = null;
    pharmacistVerificatioBy: string = null;
    pharmacistVerificationDate: Date = null;
    pharmacistInitials: string = null;
    technicianInitials: string = null;
}

export class Compounddrugs {
    drugId: number = null;
    ingPropPer: number = null;
    drugName: string = null;
    drugNdc: string = null;
}

export class EPharmacy {
    name: string = null;
    phone: string = null;
    fax: string = null;
    address1: string = null;
    address2: string = null;
    deanumber: string = null;
}

export class ERxDetailsViewModel {
    PrescName: string = null;
    PrescNpi: string = null;
    PrescPhone: string = null;
    PrescSsn: string = null;
    PrescDea: any = null;
    PrescAddress: string = null;
    PrescSpi: string = null;
    Prescriberagent: any = null;
    PatName: string = null;
    MiddleName: any = null;
    PatGender: string = null;
    PatPhone: any = null;
    PatAddress: any = null;
    PatBirthDt: string = null;
    DrugName: string = null;
    DrugNdc: string = null;
    DrugStrength: string = null;
    DrugForm: string = null;
    DrugPotency: string = null;
    IsControlDrug: boolean = null;
    IsSIInDrugCoverageStatusCodes: boolean = null;
    Diagnoses?: (DiagnosesEntity)[] | null = null;
    NameOfDrug: string = null;
    DrugStrengthFromTag: any = null;
    DrugClass: number = null;
    strength: string = null;
    DrugUnitId: number = null;
    DrugFormId: number = null;
    RxNum: string = null;
    RefillNum: string = null;
    RxQty: string = null;
    RxDays: any = null;
    RxDaw: string = null;
    RxOrdDt: string = null;
    EffectiveDate: any = null;
    PrsOrdRef: string = null;
    RxSig: string = null;
    RxMsg: string = null;
    DawName: string = null;
    RefillsQtyQualifier: string = null;
    Transaction: string = null;
    MsgType: string = null;
    DocIDs: string = null;
    MsgRef: string = null;
    OtherInfo: any = null;
    Message: string = null;
    DigestValue: any = null;
    SignatureValue: any = null;
    X509Data: any = null;
    IsDigitalSignaturePresent: boolean = null;
    Xml: string = null;
    ProcessErxId: number = null;
    ErxId: number = null;
    ErxOperationId: number = null;
    ErxOpTransactionId: number = null;
    rxInformtion: any = null;
    OriginalRxDetails: any = null;
    cancelRxResponseCodes: any = null;
    notProcessedErxId: any = null;
    PresLastName: string = null;
    PresFirstName: any = null;
    PresAddrL1: string = null;
    PresAddrL2: string = null;
    PresCity: string = null;
    PresState: string = null;
    PresZip: string = null;
    PrescriberOriginalZip: string = null;
    ClinicName: any = null;
    PatLastName: string = null;
    PatFirstName: string = null;
    PatAddrL1: any = null;
    PatAddrL2: any = null;
    PatCity: any = null;
    PatState: any = null;
    PatZip: any = null;
    PatientOriginalZip: any = null;
    DateWritten1: string = null;
    DateWrittenYY1: string = null;
    DateWrittenMM1: string = null;
    DateWrittenDD1: string = null;
    DateWritten2: string = null;
    DateWrittenYY2: string = null;
    DateWrittenMM2: string = null;
    DateWrittenDD2: string = null;
    EffectiveDate1: string = null;
    EffectiveDateYY1: string = null;
    EffectiveDateMM1: string = null;
    EffectiveDateDD1: string = null;
    EffectiveDate2: string = null;
    EffectiveDateYY2: string = null;
    EffectiveDateMM2: string = null;
    EffectiveDateDD2: string = null;
    XmlData: any = null;
    RXRefillNo: any = null;
    PayerName: string = null;
    MutuallyDefined: any = null;
    PayerId: any = null;
    BINLocationNumber: any = null;
    GroupId: any = null;
    CLastName: string = null;
    CFirstName: string = null;
    MaxDailyDose: string = null;
    IsNormalRx: boolean = false;
    rxOrdDt: any = null;
    prescRefillId: any = null;
    SuperVisorName:any = null;
    SuperVisorDea:any = null;
    SuperVisorNpi:any = null;
    diagnosis: any = null;
    PrescReFillId: any = null;
    isCompoundDrug : boolean = null;
  }
  export class DiagnosesEntity {
    RECID: string = null;
    DIAGCODE: string = null;
    DIAGDESCR: string = null;
  }

  export class ERxFile {
    Id: number = null;
    TenantId: number = null;
    PrescId: number = null;
    PrescRefillId: number = null;
    FileDtTm: Date = null;
    DueDtTm: Date = null;
    Remarks: string = null;
    IsDeleted: boolean = null;
    DeferredReason: number = null;
    DeferredReasonName: string = null;
  }

  export class ECodeInfo {
    BillTo: string = null;
    BucketCode: string = null;
    DawCode: string = null;
    DeliveryModeCode: string = null;
    DiscCode: string =  null;
    InsuPriority: string = null;
    PriceCode: string =  null;
    SplProgram: string =  null;
  }

  export class PrimaryInsurance {
    InsuPlanId: number = null;
    TenantId: number = null;
    refillDueConsumptionThreshold: number = null;
  }

export class RefillInsuPriority {
    CreatedDtTm: any = null;
    Id: any = null;
    InsuId: any = null;
    InsuPriId: any = null;
    InsurerId: any = null;
    ModifiedDtTm: any = null;
    RefillId: any = null;
    TenantId: any = null;
 }
 export class IouDetails {
    Id: number = null;
    DrugId: number = null;
    BottleNDC: string = null;
    BucketId: number = null;
    DispQty: any = null;
    DispDtTm: Date = null;

 }

 export class CurrentViewModel {
    patientId : null;
    Billstatus : "";
    StartDate:  null;
    EndDate : null;
    PageNumber : 0;
    PageSize : 0;
    OrderByColumn: null;
    IsShowExpRefills : false;
    drug : null;
    GroupView: false;
    showCurrentRx: false;
    AllowRefillingTransferRx: null;
    fromRph: false;
 }

 export class RxExtraInfo {
    couponInfo: null;
    diagCode: null;
   prescInfo: null;
   procModifier: null;
    rxExtra: null;
 }

 export class PaginationModel {
    pageNumber : number = 1;
    pageSize : number = 60;
 }
 export class eRxTRxREQModel {
    XferPharmId: string  = "";
    SelctDrugType: boolean  = false;
    eRxTransferNotes: string = "";
 }

 export class SendPARequestModel {
    VendorId : number;
    PrescrefillId : number;
    PrescId : number;
    ins_code : string;
    PrescNum : number;
    RefillNum : number;
    FillNo : number;
    IsPaNotIniated: boolean;
    PriorAuthId : number;
 }