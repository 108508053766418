import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { constant } from '../app.constants';

const reqOptns = {
  headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
  providedIn: "root"
})
export class LabelQueueService {

  constructor(private _http: HttpClient) { }
  getLabelQueue(data) {
    return this._http.post<any>(constant.POST_Get_LabelRx_Queue, data, reqOptns);
}
deleteRxsFromLabelQueue(queue: any): Observable<any> {
  return this._http.put<any>(constant.Delete_LabelQUEUE, queue);
}
postLabelQueueUrl(queue: any): Observable<any> {
  return this._http.post<any>(constant.PUT_LabelQUEUE, queue);
}
postForDefaultLabelQueueFilters(queue: any): Observable<any> {
  return this._http.post<any>(constant.POST_QueueFilters, queue);
}
getLabelQueueFilters(): Observable<any> {
  return this._http.get<any>(constant.GET_QueueFilters);
}
deleteLabelQueueDelivery(prescRefillId): Observable<any> {
  return this._http.put<any>(constant.DELETE_Devlivery, prescRefillId);
}

IsExistInLabelQueue(prescReFillId): Observable<string> {
    return this._http.get<any>(constant.Exist_In_LabelQueue.replace("{prescRefillId}", prescReFillId)
    );
}
}
