

<div class="exprx--common-block erx--body insurance--body">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        Your Pharmacy
    </div>
    <div
    class="eprx--block__content pt-0"  [ngClass]="IsFromRequestInfo?'height_155':'height_180'">
        <div class="row row-space-transfer-in   col">
            <div class="label--data"><span>
                <label>
                    <b>
                        {{requestPharmacyDetails.PharmacyName ?
                            (requestPharmacyDetails.PharmacyName | uppercase) : msgConfig.EMPTY_Data}}
                    </b>
                </label>
        </span></div>
        </div>
        <div class="row row-space-transfer-in">
            <div class="col">
                <label>{{pharmacyabels.Contact}}</label>
                <div class="label--data"><span>{{requestPharmacyDetails.TelephoneNumber ?
                        requestPharmacyDetails?.TelephoneNumber : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col">
                <label>{{pharmacyabels.Fax}}</label>
                <div class="label--data"><span>{{requestPharmacyDetails?.fax ?
                        requestPharmacyDetails.fax : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col">
                <label>{{pharmacyabels.NPI}}</label>
                <div class="label--data"> <span>{{ (requestPharmacyDetails?.NPINum) ? requestPharmacyDetails.NPINum : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col">
                <label>{{pharmacyabels.DEA}}</label>
                <div class="label--data"><span>{{ (requestPharmacyDetails?.DEARegistration) ? requestPharmacyDetails.DEARegistration : msgConfig.EMPTY_Data}}</span></div>
            </div>
        </div>
        <div class="row row-space-transfer-in">
            <div class="col-6">
                <label>{{pharmacyabels.Address}}</label>
                <div class="label--data "><span>{{requestPharmacyDetails.pharmAddress ?
                        requestPharmacyDetails.pharmAddress : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col-6">
                <label>{{pharmacyabels.Pharmacist_Name}}</label>
                <div class="label--data"><span>{{pharmacistName ? pharmacistName : msgConfig.EMPTY_Data}}</span></div>
            </div>
        </div>
    </div>
</div>

