import { Injectable } from "@angular/core";
import { CommonService } from "../services";
import { CommonStore } from "../store";
import { StorageService } from "../services/storage.service";

@Injectable({
    providedIn: "root"
})

export class InterfaceUtils {
  constructor(private _comnSvc: CommonService, private _commonStore: CommonStore, private _storageSvc: StorageService) { }
  async setInterfaceMode(TenantId, result?) {
    const reqTenantId= {
        "RequestingTenantId": TenantId+""
    }
        const response = result ? result : await this._comnSvc.getInterfacePharmSettings(reqTenantId, true).toPromise();
            if (response) {
                const secretKey = response.applicationSharedAccessKey;
                sessionStorage.setItem("InterfaceSecretKey", this._comnSvc.encryptData(JSON.stringify(secretKey)));
                this._commonStore.storeInterfacePharmConfigSettings(response.pharmacyConfigurations);
                if (this._storageSvc.getLocalStorage("GUIDEyecon") && this._comnSvc.getDecryptLocalStorage("GUIDEyecon") && response.pharmacyConfigurations?.length && response.pharmacyConfigurations[0].configurationDetail?.sendData?.length) {
                    const eyconDet = response.pharmacyConfigurations[0].configurationDetail.sendData.find((machnDet: any) => ((machnDet.guid === (this._comnSvc.getDecryptLocalStorage("GUIDEyecon"))) && machnDet.receiveMode === "I" && machnDet.active))
                    this._comnSvc.setEncryptLocalStorage("ImmediateMode", (eyconDet ? "true" : "false"))
                    this._comnSvc.setEncryptLocalStorage("EyeconIP", (eyconDet ? eyconDet.ipAddress : null))
                } else {
                    this._comnSvc.setEncryptLocalStorage("ImmediateMode", "false")
                    this._comnSvc.setEncryptLocalStorage("EyeconIP", null)
                }
            }
    }
}
