
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { CommonService, RxService, AlertService, InsuranceService, AdditionalInsuranceService, PatPreDrugModalService } from "src/app/services";
import { SystemData, RxModel, RxPay, RxTransmisParams, Insurances, InsuCards, InsuGroups, InsuCarrier, SearchInsurance, InsuranceEntity } from "src/app/models";
import { Subscription  } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RxPricingUtil, RxBillingUtil, NRxUtils, NRxSaveUtils, ValidateFormFieldsUtils, TransmissionUtils, NewAuditUtils, PopupsCommonLogicUtil, CommonUtil } from "src/app/utils";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RxStore } from "src/app/store";
import { Observable } from "../../../../../node_modules/rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    map
} from "../../../../../node_modules/rxjs/operators";
import { RegEx } from "src/app/app.regex";
import * as moment from "moment";
import { MsgConfig } from "src/app";
import { PatAuditLog } from "../../../models";
import { CommonWarnorconfirmPopupComponent } from "../common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: "app-next-bill-to-insurance",
    templateUrl: "./next-bill-to-insurance.component.html",
    styles: []
})
export class NextBillToInsuranceComponent implements OnInit {
    subscriptions = new Subscription();
    otherCoverId = 1;
    modelRef: any;
    nxtTransmitData = new RxTransmisParams();
    disableNotInsList: any = [];
    @Input() payRecords: RxPay[];
    @Input() rxInfo: RxModel;
    @Input() systemData: SystemData;
    @Input() nxtInsuLst: InsuranceEntity[];
    @Input() nxtInsuToBill: any;
    @Input() rxFG: FormGroup;
    @Input() pricSchdlLst: FormGroup;
    @Input() rxAndActionType: any;
    @Input() billingPriority: any;

    @Output()
    CanclSecndryInsPopUp = new EventEmitter<any>();

    @Output()
    NextBillToInsInfo = new EventEmitter<any>();

    @Output()
    NextBillToInsuarnce = new EventEmitter<any>();


    @ViewChild("NEXTINSUTOBILL", { static: true })
    NEXTINSUTOBILL: any;
    policyRequired: boolean;
    insuCode: string;
    insuName: string;
    insuranceFG: FormGroup;
    insuranceData: any;
    filteredGroups: any;
    regex: any;
    minDate: { year: number; month: number; day: number; };
    formGroupInvalid: boolean;
    modelRef2: any;
    BIN = "";
    PCN = "";
    PrevInsu: any;
    InsName = "";
    otherCoverCode: any;
    selectedInsurance: any;
    netPaid: any = "";
    billCode: any = "";
    unsubscribe$: Subject<void> = new Subject();
    carryFwdBillSequence: any;
    M3PInsu = null;
    isQuatBillingAllow = false;
    // nxtInsuToBill: any;

    constructor(
        private _rxService: RxService,
        private _alertServ: AlertService,
        private _mdlServ: NgbModal,
        private _insurService: InsuranceService,
        private _additionalInsu: AdditionalInsuranceService,
        private _rxPricingUtil: RxPricingUtil,
        private _billUtls: RxBillingUtil,
        private _rxStore: RxStore,
        private _nrxUtil: NRxUtils,
        private _nrxSaveUtil: NRxSaveUtils,
        private _fb: FormBuilder,
        public _commonSvc: CommonService,
        private _validUtils: ValidateFormFieldsUtils,
        private _ppdims: PatPreDrugModalService, private _transUtils: TransmissionUtils,
        private _newAuditUtil: NewAuditUtils,
        private _popupLogics: PopupsCommonLogicUtil,
        public _commUtil: CommonUtil,
    ) {}

    ngOnInit() {
        this.disableTransmittedInsurances();
        this.sorTChanges();
        this.otherCoverId = 1;
        if (this.payRecords && this.payRecords.length > 0) {
            this.payRecords = this.payRecords.filter(p => (p.RecType !== "R" || (p.RecType === "R" && p.REJCodes === "70")));
        }
        this.insuranceData = this._commonSvc.insuranceData$["source"]["value"];
        this.regex = RegEx;
        this.binNPcn();
        this.showNextInsuToBill();
        this.M3PInsu = this._commUtil.isPatientHasM3P(this.nxtInsuLst);
        this.isQuatBillingAllow = this.M3PInsu && !this._commUtil.isM3PBilled(this.M3PInsu) && this._commUtil.isParentInsuBilled(this.M3PInsu)
    }
    sorTChanges() {
        if (this.nxtInsuLst && this.nxtInsuLst.length) {
            let cnxtInsuLst: any, c1nxtInsuLst: any;
            cnxtInsuLst =  this.nxtInsuLst.filter((item: any) => {
                return (item.insuPriId); });
                c1nxtInsuLst =  this.nxtInsuLst.filter((item: any) => {
                    return !(item.insuPriId); });
                this.nxtInsuLst = [ ...cnxtInsuLst.sort((a: any, b: any) => {
                    return (parseInt(a.insuPriId, 0) - parseInt(b.insuPriId, 0));
                    }), ...c1nxtInsuLst];
        }
    }
    binNPcn() {
        this.payRecords =   this.rxInfo.RxPay;
        if (this.payRecords && this.payRecords.length > 0 && this.rxInfo.Patient.insurance.length > 0) {
            const insCoverage = ["P : ", "S : ", "T : "];
            var newArray  = [];
            const payRecords1 = this.payRecords.filter((item: any) => { return !(item['CoverageCD'])});
            if(payRecords1 && payRecords1.length) {
                let primaryCoverageCD: any   =  payRecords1.sort((a, b) => {
                    return <any>new Date(b.DatePaid) - <any>new Date(a.DatePaid);
                });
                if(primaryCoverageCD && primaryCoverageCD.length) {
                    newArray.push(primaryCoverageCD[0]);
                }
            }

            const payRecords2 = this.payRecords.filter((item: any) => { return (item['CoverageCD'] == 1 && this.nxtInsuToBill && this.nxtInsuToBill.insuPriId !== 2)});
            if(payRecords2 && payRecords2.length) {
                let secondayryCoverageCD: any   =  payRecords2.sort((a, b) => {
                    return <any>new Date(b.DatePaid) - <any>new Date(a.DatePaid);
                });
                if(secondayryCoverageCD && secondayryCoverageCD.length) {
                    newArray.push(secondayryCoverageCD[0]);
                }
            }
            const payRecords3 = this.payRecords.filter((item: any) => { return (item["CoverageCD"] == 2 && this.nxtInsuToBill && this.nxtInsuToBill.insuPriId !== 3);
            });
            if(payRecords3 && payRecords3.length) {
                let terCoverageCD: any   =  payRecords3.sort((a, b) => {
                    return <any>new Date(b.DatePaid) - <any>new Date(a.DatePaid);
                });
                if(terCoverageCD && terCoverageCD.length) {
                    newArray.push(terCoverageCD[0]);
                }
            }
            newArray.forEach((ins, i) => {
                const insCoverage = ["P : ", "S : ", "T : "];
             //   if (ins.RecType !== "R" || (ins.RecType === "R" && ins.REJCodes === "70")) {
                    const INS = this.rxInfo.Patient.insurance.find(type => type.insuType.trim() === ins.Ins_Code.trim());
                    this.InsName += INS ? insCoverage[ins.CoverageCD] +
                                    (i < (this.payRecords.length - 1) ? INS.insurancename + ((i != (newArray.length - 1) )? ", " : '') : INS.insurancename) : "--";

                    this.BIN += INS ? insCoverage[ins.CoverageCD] +
                                    (i < (this.payRecords.length - 1) ? INS.binnum + ((i != (newArray.length - 1) )? ", " : '') : INS.binnum) : "--";

                                    INS.ProcessorNum  = (INS.ProcessorNum && INS.ProcessorNum.trim())  ? INS.ProcessorNum  : "--";

                    this.PCN += INS ? insCoverage[ins.CoverageCD] +
                                    (i < (this.payRecords.length - 1) ? INS.ProcessorNum + ((i != (newArray.length - 1) )? ", " : '') : INS.ProcessorNum) : "--";

                   this.netPaid += INS ? ((insCoverage[ins.CoverageCD] +
                                    (i < (this.payRecords.length - 1) ? ((ins.TotAmtPaid ? ("$" + ins.TotAmtPaid.toFixed(2) +
                                    ((i != (newArray.length - 1) )? ", " : '')): ( (i != (newArray.length - 1)) ? "$0.00, " : "$0.00"))) :
                                     (ins.TotAmtPaid ? ("$" + ins.TotAmtPaid.toFixed(2)) : ((i != (newArray.length - 1)) ? "$0.00, " : "$0.00"))) )): "--";

                   this.billCode += INS ? insCoverage[ins.CoverageCD] +
                                    (i < (this.payRecords.length - 1) ? (ins.Ins_Code + ((i != (newArray.length - 1) )? ", " : '')) : (ins.Ins_Code ? ins.Ins_Code  : "")) : "--";
             //  }
            });
        }
    }

    async getPriceCalDetails() {
        let rxType = null;
        if (this.rxFG) {
            rxType = this.rxFG.get("rxType").value;
        }
        if(this.nxtInsuToBill && this.nxtInsuToBill.insuplanId) {
            const resp = await this._insurService.getInsSettingsInfo(this.nxtInsuToBill.insuplanId).toPromise();
                if (resp && this.rxFG) {
                    this._insurService.saveInsuSettings(resp);
                    await this._billUtls.getInsuranceManualCalc(this.rxFG, resp);
                    await this._billUtls.getPriceDetlsNdCalc(null, this.rxFG, this.pricSchdlLst, resp, rxType);
                } else {
                    this.rxInfo = await this._rxPricingUtil.triggerPriceSchedule(null, this.rxInfo, this.pricSchdlLst, resp, rxType);
                }
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    showNextInsuToBill() {
        this.disableNotInsList = this._transUtils.getRemainingInsuranceToBill(this.rxInfo, this.payRecords, this.systemData, true);
        this.carryFwdBillSequence = this.rxInfo['PrescReFill']['CarryFwdBillSequence'];
        if (this.billingPriority == 4) {
            this.otherCoverId = this.systemData?.OtherCoverage?.filter((item) => item?.Name == "8")[0]?.Id;
        }
        this.modelRef = this._mdlServ.open(this.NEXTINSUTOBILL, {
            backdrop: "static",
            size: "lg",
            windowClass: "md-x1-lg modal-center-to-page",
            keyboard: false
        });
    }

    async onChangeValue(insurence?: any, prop?: any) {
        this.otherCoverId = 1;
        if (prop === "insuranceid") {
            this.nxtInsuToBill = insurence;
            let insuList = null;
            if (this.nxtInsuLst) {
                insuList = this.nxtInsuLst.filter((ins: any) =>
                        ins.insuranceid === insurence.insuranceid)[0];
            }
            if (this.rxFG) {
                const refillInsu = this.rxFG.controls["RefillInsu"] as FormGroup;
                refillInsu.controls["InsuId"].setValue(insurence.insuranceid);
                refillInsu.controls["InsuPriId"].setValue(this.rxInfo.RefillInsu.InsuPriId);
            }
            await this.checkHasBinInsuPayerSet(insuList);
            if (this.M3PInsu?.insuCode == insurence?.insuType?.trim()?.toUpperCase()) {
                this.otherCoverId = this.systemData?.OtherCoverage?.filter((item) => item?.Name == "8")[0]?.Id;
            }
        } else {
           this.otherCoverId = insurence;
        }
    }

    async checkHasBinInsuPayerSet(insuList: any) {
        const resp = await this._rxService
                .getBInInsuPayerSetCheck(insuList.insurerid).toPromise();
        if (!resp) {
            this._alertServ.error("Insurer's BIN# with 6 digits and NCPDP payer set is required for transmission.");
        } else {
            const resp1 = await this._insurService.getTransSettingsInfo(this.nxtInsuToBill.insuplanId).toPromise();
            if (resp1) {
                this.otherCoverId = resp1["DefOthrCovCode"] ? +resp1["DefOthrCovCode"] : this.otherCoverId;
            }
        }
    }

    async initiateSecondaryInsurance() {
        let insuList = null;
        if (this.nxtInsuLst && this.nxtInsuToBill) {
            insuList = this.nxtInsuLst.filter((ins: any) =>
                    ins.insuranceid === this.nxtInsuToBill.insuranceid)[0];
        }
        let M3PInsu = this._commUtil.isPatientHasM3P(this.rxInfo.Patient.insurance);
        let maxBillPriority = M3PInsu && !this._commUtil.isM3PBilled(M3PInsu) && this._commUtil.isParentInsuBilled(M3PInsu) ? 4 : 3;
        if (this.nxtInsuToBill && this.nxtInsuLst && this.nxtInsuLst.length && +this.rxInfo.RefillInsu.InsuPriId < maxBillPriority) {
            this.rxInfo.RefillInsu.InsuPriId = this.rxInfo.RefillInsu.InsuPriId + 1;
            this.rxInfo.RefillInsu["InsuId"] = this.nxtInsuToBill["insuranceid"];
        }
        if (insuList) {
            const resp = await this._rxService
                .getBInInsuPayerSetCheck(insuList.insurerid).toPromise();
            if (!resp) {
                this._alertServ.error("Insurer's BIN# with 6 digits and NCPDP payer set is required for transmission.");
            } else {
                if (this.modelRef) {
                    this.modelRef.close();
                }
                if (this.nxtInsuToBill && this.nxtInsuToBill.insuplanId) {
                    await this.getPriceCalDetails();
                }

                const message: any =  this.rxFG ? await this._popupLogics.CheckRxPriceCalculations(this.rxFG): null;
                if (message) {
                    const modalRef = this._mdlServ.open(CommonWarnorconfirmPopupComponent, {
                        backdrop: false,
                        keyboard: false, windowClass: "large--content"
                    });
                    modalRef.componentInstance.warnMsg = message.messageText;
                    modalRef.componentInstance.okButton = true;
                   modalRef.componentInstance.IsPopUpClosed
                   .pipe(takeUntil(this.unsubscribe$))
                   .subscribe((resp: any) => {
                        if (resp) {
                           modalRef.close();
                        }
                        this.emitPopUpClosed();
                    });
                } else
                         this.updateRxInfoForBillAs(insuList);
            }
        } else {
            this._alertServ.error("Insurer's BIN# with 6 digits and NCPDP payer set is required for transmission.");
        }
    }

    async updateRxInfoForBillAs(insurence: any) {
        this.rxInfo = this.rxFG ? this.rxFG.getRawValue() : this.rxInfo;
        const oldInfo = this._rxStore.rxInfo$["source"]["value"] ? this._rxStore.rxInfo$["source"]["value"] :
         Object.assign({}, this.rxInfo);
        this.rxInfo.RefillInsu["InsuId"] = insurence.insuranceid;
        if (!(this.rxInfo["Prescription"]["RxStatusId"] === 2 || this.rxInfo["PrescReFill"]["StatusId"] === 2)) {
            this.rxInfo["RxFile"] = null;
        }
        this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
        const resp = await this._nrxSaveUtil.updateRxInfo(this.rxInfo, oldInfo);
        if (resp && !isNaN(+resp.RxNum) && +resp.RxNum > 0) {
            this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
        } else if (resp && isNaN(+resp.Message)) {
            if (resp.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                this._alertServ.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
            } else if (typeof resp.Message === "string") {
            this._alertServ.error(resp.Message);
            }
        }
        await this.saveRxExtraValuesFromTrasns(insurence);
        this._rxStore.storeRxInfo(this.rxInfo);
    }

    async saveOtherCov() {
        this.otherCoverId = this.otherCoverId ? this.otherCoverId : 1;
        await this._rxService.saveRxExtraValuesFromTrasns(
                this.rxInfo.PrescReFill.Id, this.otherCoverId).toPromise();
    }

    async saveRxExtraValuesFromTrasns(insurence?) {
        await this.saveOtherCov();
        this.rxInfo["PrescReFill"]["ModifiedDtTm"] = new Date();
        this.getAndSaveNewRxInfo(insurence);
    }

    async getAndSaveNewRxInfo(insurence?) {
        if (this.rxInfo && this.rxInfo.Prescription && this.rxInfo.Prescription.PrescNum) {
            let rxInfo: any = await this._nrxUtil.getandStoreRxInfo(+this.rxInfo.Prescription.PrescNum, this.rxInfo.PrescReFill.Id, false, null, null, null, true);
            if (this.rxFG) {
                rxInfo = this._nrxUtil.editRxPatchValues(rxInfo);
                this._nrxUtil.createPatchStoreRxFG(rxInfo);
            }
            this.NextBillToInsuarnce.emit(insurence);
            this.NextBillToInsInfo.emit(this.rxInfo);
        }
    }

    emitPopUpClosed() {
        if (this.modelRef) {
            this.modelRef.close();
        }
        this.CanclSecndryInsPopUp.emit();
    }

    async patchInsuranceValue(Id) {
        const insurance = this.insuranceData ? this.insuranceData.find(d => d["Id"] === +Id) : null;
        if (insurance) {
            this.insuranceFG.controls["InsuCard"].patchValue({PolicyNum: null, ExpiryDt: "12/31/2099"});
            this.selectedInsType({item: insurance});
        }
    }

    routeToAddInsurance() {
        this._ppdims._patPreDrugModal$.next("insurance");
        const modalRef = this._ppdims.modalInstanceRef;
        modalRef.componentInstance.insuId = null;
        modalRef.componentInstance.SkipUpdateBS = true;
        modalRef.componentInstance.CloseInsuModal
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(async resp => {
            modalRef.close();
            if (resp && resp["Type"] !== "Cancel") {
                const search = new SearchInsurance();
                const data = await this._commonSvc.getInsuData(search).toPromise();
                this._commonSvc._insuranceData$.next(data["Records"]);
                this.insuranceData = this._commonSvc.insuranceData$["source"]["value"];
                setTimeout(() => {
                    this.insuranceFG.controls["Insgroup"].patchValue({Insgroup: null});
                    this.insuranceFG.controls["Insurance"].patchValue({InsuGrpId: 0});
                    if (document.getElementById("searchBoxInsType")) {
                        document.getElementById("searchBoxInsType").focus();
                    }
                    this.patchInsuranceValue(resp["insuId"]);
                }, 1000);
            }
            this._ppdims._patPreDrugModal$.next(null);
        });
    }

    async openPatInsuAddModal(content) {
        this.policyRequired = false;
        this.createPatInsuAddFG();
        this.insuCode = "";
        this.insuName = "";
        await this.setPatInsuAddDefVal();
        this.modelRef2 = this._mdlServ.open(content, { size: "lg" , centered: true, backdrop: false, keyboard: false });
    }

    checkFGValid() {
        if (this.insuranceFG.value["Insurance"]["InsurerId"] && (!this.policyRequired || (this.policyRequired &&
            this.insuranceFG.value["InsuCard"]["PolicyNum"] && this.insuranceFG.value["InsuCard"]["PolicyNum"].trim() !== ""))) {
            this.formGroupInvalid = false;
            this.callSaveOrUpdate();
        } else {
            this.formGroupInvalid = true;
            this._validUtils.validateAllFormFields(this.insuranceFG);
        }
    }

    async checkForInsuranceDuplication() {
        const insuCFG: any = this.insuranceFG.controls["InsuCard"];
        const newFG: any = this.insuranceFG.controls["Insurance"];
        if (insuCFG.value.PolicyNum && insuCFG.value.PolicyNum.trim()) {
            const resp = await this._commonSvc.checkIfPolicyNoExists(insuCFG.value.PolicyNum, newFG.value.InsurerId,
                insuCFG.value.Id).toPromise();
            if (resp) {
                const insuCardsFG: any = this.insuranceFG.controls["InsuCard"];
                insuCardsFG.controls["PolicyNum"].setErrors({ invalid: true });
                insuCardsFG.controls["PolicyNum"].markAsTouched();
                this._alertServ.error("Insurance Id already exists.");
                document.getElementById("InsuId").focus();
            } else {
                this.callSaveOrUpdate();
            }
        } else {
            this.callSaveOrUpdate();
        }
    }

    callSaveOrUpdate() {
        const additionalModel = new PatAuditLog().additionalModel;
        const insuCardsFG: any = this.insuranceFG.controls["InsuCard"];
        insuCardsFG.controls["PolicyNum"].setErrors(null);
        if (this.insuranceFG.valid) {
            const insuranceData = this.insuranceFG.value;
            this._additionalInsu
                .addPatientInsurance(insuranceData)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(async resp => {
                    if (this.modelRef2) {
                        this.modelRef2.close();
                        this.modelRef2 = null;
                    }
                    if (resp) {
                        this._newAuditUtil.saveAuditOnAdd(
                        insuranceData,
                        "AdditionalInsurance",
                        "Patient",
                        this.rxInfo["Patient"]["patientid"],
                        additionalModel);
                        this._alertServ.success("Insurance added successfully.");
                        await this.updateInsuList();
                    } else {
                        this._alertServ.error("Insurance add unsuccessful.");
                    }
                });
        } else {
            this._validUtils.validateAllFormFields(this.insuranceFG);
        }
    }

    async updateInsuList() {
        const patInfo = await this._commonSvc.getRxPatientDetailsById(this.rxInfo["Patient"]["patientid"]).toPromise();
        if (this.rxFG) {
            this.rxFG.controls["Patient"].patchValue(patInfo);
        }
        this.rxInfo["Patient"] = patInfo;
        this._rxStore.storeRxInfo(this.rxFG ? this.rxFG.getRawValue() : this.rxInfo);
        this.nxtInsuLst = this._transUtils.getRemainingInsuranceToBill(this.rxInfo, this.payRecords, this.systemData);
         this.disableNotInsList = this._transUtils.getRemainingInsuranceToBill(this.rxInfo, this.payRecords, this.systemData, true);
        this.nxtInsuToBill = this._transUtils.checkHasNextInsurncsFmEditRx(this.rxInfo, this.payRecords, this.nxtInsuLst, true);
        this.sorTChanges();
        this.disableTransmittedInsurances();
    }

    disableTransmittedInsurances() {
        if (this.payRecords && this.payRecords.length && this.nxtInsuLst && this.nxtInsuLst.length) {
            this.nxtInsuLst.find(ins => {
                this.payRecords.find(pay => {
                    if ((ins.InsurerCode.toLowerCase()).trim() === (pay.Ins_Code.toLowerCase()).trim() && pay.RecType.toLowerCase() === "p") {
                        ins["hasPayRecords"] = true;
                    }
                });
            });
    }
    }

    createPatInsuAddFG() {
        this.insuranceFG = this._fb.group({
            patientid: this.rxInfo["Patient"]["patientid"],
            Insurance: this._fb.group(new Insurances()),
            InsuCard: this._fb.group(new InsuCards()),
            Insgroup: this._fb.group(new InsuGroups()),
            InsuCarrier: this._fb.group(new InsuCarrier())
        });
    }

    setPatInsuAddDefVal() {
        this.insuranceFG.controls["Insurance"].patchValue({
            CPBrandVal: 0, CPGenericVal: "0", RelCatId: 2,
            CoPayCatId: 1, PerNum: "01", IsPatAssigned: true, ActiveStatusId: 1,
        });
        this.insuranceFG.controls["InsuCard"].patchValue({
            ExpiryDt: "12/31/2099", IsActive: true
        });
        const today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(today).year(),
            month: moment(today).month() + 1,
            day: moment(today).date()
        };
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => (term.length < 1 ? [] : this.filterByPcnBin(term)))
        )

    filterByPcnBin(term) {
        const searchTerms = term.split(",");
        const insuranceList = Object.assign([], this.insuranceData);
        if (insuranceList && insuranceList.length > 0) {
            ["e1comm", "..."].map(codes => {
                const index = insuranceList.findIndex(val => (val["InsurerCode"]).toLowerCase() === codes);
                if (index > -1) {
                    insuranceList.splice(index, 1);
                }
            });
        }
        let filteredData = insuranceList.filter(v => {
            let valExists = false;
            if (v.SubmissionType === "9") {
                ["PlanCode", "InsuranceName", "BinNum"].forEach(function (key) {
                    const val = v[key] ? v[key] : "";
                    valExists =
                        valExists ||
                        val
                            .toString()
                            .toLowerCase()
                            .startsWith(searchTerms[0].toLocaleLowerCase());
                });
            }
            return valExists;
        });
        if (searchTerms[1]) {
            filteredData = filteredData.filter(v => {
                let valExists = false;
                const val = v["ProcessorNum"] ? v["ProcessorNum"] : "";
                valExists =
                    valExists ||
                    val
                        .toString()
                        .toLowerCase()
                        .startsWith(searchTerms[1].toLocaleLowerCase());
                return valExists;
            });
        }
        return filteredData.splice(0, 20);
    }

    formatter = (x: any) => {
        this.insuName = x.InsuranceName;
        return x.InsurerCode;
    }

    selectedInsType(val) {
        this.insuName = val.item.InsuranceName;
        this.insuCode = val.item.InsurerCode;
        const newFG: any = this.insuranceFG.controls["Insurance"];
        const newIC: any = this.insuranceFG.controls["InsuCarrier"];
        newFG.controls["InsurerId"].setValue(val.item.InsuCarrierId);
        newFG.controls["InsuGrpId"].setValue(null);
        newIC.controls["InsurerCode"].setValue(val.item.InsurerCode);
        this.isInsuranceExist(this.rxInfo["Patient"]["patientid"], val.item.InsuCarrierId, val.item.Id, newFG.controls["Id"].value);
    }

    async isInsuranceExist(patientId, insurerId, insuId, insuranceId, IsNew?: boolean) {
        let isInsuActv = null;
        const isInsuExs = IsNew ? false : await this._additionalInsu.isInsuranceExistForPatient(patientId,
             insurerId, insuranceId).toPromise();
        isInsuActv = await this._additionalInsu.isInsuranceActiveFrmInsFilePatient(insurerId).toPromise();
        if (isInsuExs) {
            this._alertServ.error("Patient already has the selected Insurance.");
            this.insuName = " ";
            this.insuCode = " ";
            this.insuCode = null;
            const newFG: any = this.insuranceFG.controls["Insurance"];
            const newIC: any = this.insuranceFG.controls["InsuCarrier"];
            newIC.controls["InsurerCode"].setValue(null);
            newFG.controls["InsurerId"].setValue(null);
        } else if (!isInsuActv) {
            this._alertServ.error("The selected Insurance is inactive, hence cannot be added.");
            this.insuName = " ";
            this.insuCode = " ";
            this.insuCode = null;
            const newFG: any = this.insuranceFG.controls["Insurance"];
            const newIC: any = this.insuranceFG.controls["InsuCarrier"];
            newIC.controls["InsurerCode"].setValue(null);
            newFG.controls["InsurerId"].setValue(null);
        } else {
            const insurance = this.insuranceData.find(d => d["InsuCarrierId"] === +this.insuranceFG.value["Insurance"]["InsurerId"]);
            if (insuId) {
                const insuStng = await this._insurService.getInsSettingsInfo(insuId).toPromise();
                if (insuStng && insuStng["InsuPlanPrice"]) {
                    const newFG: any = this.insuranceFG.controls["Insurance"];
                    newFG.controls["IsPatAssigned"].setValue(insuStng["InsuPlanPrice"]["IsAssigntAccepted"]);
                }
            }
            if (insurance) {
                this.checkIsPolicyRequired(insurance);
            }
            this.filterGroupsBasedOnInsurerId(insurerId);
        }
    }

    checkIsPolicyRequired(val) {
        const insuCardsFG: any = this.insuranceFG.controls["InsuCard"];
        if (insuCardsFG.value["PolicyNum"] === "" || insuCardsFG.value["PolicyNum"] === " ") {
            insuCardsFG.controls["PolicyNum"].setValue(null);
        }
        if (val.SubmissionType === "9" || !val.SubmissionType) {
            this.policyRequired = true;
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.required]);
            insuCardsFG.controls["PolicyNum"].markAsUntouched();
        } else {
            this.policyRequired = false;
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.nullValidator]);
            insuCardsFG.controls["PolicyNum"].setErrors(null);
            setTimeout(() => {
                insuCardsFG.controls["PolicyNum"].markAsUntouched();
            }, 200);
        }
    }

    filterGroupsBasedOnInsurerId(insuCarrierId) {
        const newFG = this.insuranceFG.controls["Insurance"] as FormGroup;
        const newIG = this.insuranceFG.controls["Insgroup"] as FormGroup;
        this._additionalInsu.getInsuGroups(insuCarrierId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp && resp["length"] > 0) {
                this.filteredGroups = resp;
                if (newIG.value["Name"] && !newFG.value["InsuGrpId"] &&
                this.filteredGroups.find(val => val["Name"] === newIG.value["Name"])) {
                    const matchedGroup = this.filteredGroups.find(val => val["Name"] === newIG.value["Name"]);
                    newFG.controls["InsuGrpId"].patchValue(matchedGroup["Id"]);
                    newIG.controls["Id"].patchValue(matchedGroup["Id"]);
                } else if (!newFG.value["InsuGrpId"]) {
                    newIG.controls["Name"].patchValue(null);
                    newFG.controls["InsuGrpId"].patchValue(null);
                    newIG.controls["Id"].patchValue(null);
                }
            } else {
                newFG.controls["InsuGrpId"].patchValue(null);
                newIG.controls["Id"].patchValue(null);
            }
        });
    }

    isTypedValue(Value) {
        const newIG = this.insuranceFG.controls["Insgroup"] as FormGroup;
        if (Value && Value.value && Value.value !== "") {
            newIG.controls["Name"].patchValue(Value.value["Name"]);
            newIG.controls["InsurerId"].patchValue(this.insuranceFG.value.Insurance.InsurerId);
            newIG.controls["IsActive"].patchValue(true);
            newIG.controls["Id"].patchValue(null);
        } else {
            newIG.controls["Name"].patchValue(null);
            newIG.controls["InsurerId"].patchValue(null);
            newIG.controls["IsActive"].patchValue(false);
        }
    }

    selectedPriority(selectedInsu) {
        const newFG = this.insuranceFG.controls["Insurance"] as FormGroup;
        if (selectedInsu !== 0) {
            this._additionalInsu.checkIfInsuPriorityExists(this.rxInfo["Patient"]["patientid"], selectedInsu)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (!resp) {
                    newFG.controls.InsuPriId.patchValue(null);
                    this._alertServ.error("Insurance exists with selected priority.");
                }
            });
        } else {
            newFG.controls.InsuPriId.patchValue(null);
        }
    }

    focusOutFromGroup() {
        if (document.getElementById("primInsRela12")) {
        const data: any = document.getElementById("primInsRela12").getElementsByTagName("input")[0];
        setTimeout(() => {
            data.focus();
        }, 100);
        }
    }
    
    askFrBillSequenceConfirm(val) {
        this.carryFwdBillSequence = val;
        this.rxInfo["PrescReFill"]["CarryFwdBillSequence"] = val;        
    }
}
