import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { MsgConfig } from 'src/app/app.messages';
import { CommonService, RphVerificationService } from 'src/app/services';
import { AuditLogUtils, NRxUtils } from 'src/app/utils';

@Component({
  selector: 'app-verification-checks',
  templateUrl: './verification-checks.component.html',
  styleUrls: ['./verification-checks.component.scss']
})
export class VerificationChecksComponent implements OnInit {
  verfData: any;
  verfMsg: any;
  verifiedArray:any=[];
  @ViewChild("VERFCONFIRMATION", { static: true })
  VERFCONFIRMATION: any;
  @Input() rxFG: any;
  @Input() flow: any;
  @Input() selectedRxList:any;
  @Output()IsPopUpClosed = new EventEmitter();

  constructor(private _modalService: NgbModal,  private _nrxUtils: NRxUtils, private _auditUtils: AuditLogUtils,private _rphServ: RphVerificationService,private _commonServ: CommonService) { }


  ngOnInit() {
    this.checkVerifications()
  }

  checkVerifications () {
    this.verfData = "";
    this.verfMsg = "";
    if(this.flow !== "ExcludeDPVANDRPH" && !this.selectedRxList) {
      if (this.rxFG.value["drugPickVerificationStatus"] && (this.rxFG.value["drugPickVerificationStatus"]).toLowerCase() === "p") {
        this.verfData = this.verfData + "D";
        this.verfMsg = this.verfMsg + "Drug Pick verification ";
      }
      if (!this.selectedRxList && this.rxFG.value["rph2VerificationStatus"] && (this.rxFG.value["rph2VerificationStatus"]).trim().toLowerCase() === "v") {
        this.verfData = this.verfData + "R";
        this.verfMsg = this.verfMsg + (this.verfMsg && this.verfMsg.length > 0 ?
          "and Pharmacist Verification " : "Pharmacist Verification " );
      }
    }
    if (!this.selectedRxList && this.rxFG.value["rph1VerificationStatus"] && (this.rxFG.value["rph1VerificationStatus"]).trim().toLowerCase() === "r") {
      this.verfData = this.verfData + "X";
      this.verfMsg = this.verfMsg + (this.verfMsg && this.verfMsg.length > 0 ?
        "and Rx Verification" : "Rx Verification " );
    }
    if(this.selectedRxList?.length){  
      this.selectedRxList.map((item)=>{
        const rxNo = item.PrescNum + "-" + item.Refillnum + (item.Partialfillno ? "-" + item.Partialfillno : "");
        this.verifiedArray.push(rxNo);
      })
          this.verfData = this.verfData + "X";
          this.verfMsg = this.verfMsg + (this.verfMsg && this.verfMsg.length > 0 ?
            "and Rx Verification" : "Rx Verification " );
    }
    this._modalService.open(this.VERFCONFIRMATION, {centered: true, backdrop: false, windowClass: "large--content"});    
  }

     

  async removeVerfication(removeVerf) {
    if (removeVerf && !this.selectedRxList?.length) {
      await this._nrxUtils.updateVerfStatus(this.rxFG.getRawValue(), this.verfData, this.rxFG, true);
      if (this.verfMsg.includes("Drug Pick verification")) {
          this._auditUtils.getChangedValues(
              null,
              {"DP Verification Status": "Re-Verify"},
              "DP Verification",
              "Rx",
              this.rxFG.value.PrescReFill.Id
          );
      }
      if (this.verfMsg.includes("Pharmacist Verification")) {
          this._auditUtils.getChangedValues(
              null,
              {"Rph Verification Status": "Re-Verify"},
              "Rph Verification",
              "Rx",
              this.rxFG.value.PrescReFill.Id
          );
      }
      if (this.verfMsg.includes("Rx Verification")) {
          this._auditUtils.getChangedValues(
              null,
              {"Rx Verification Status": "Re-Verify"},
              "Rx Verification",
              "Rx",
              this.rxFG.value.PrescReFill.Id
          );
      }
      this.IsPopUpClosed.emit(null);
    } 
    else if(removeVerf && this.selectedRxList?.length){
      const payload:any=[]
      this.selectedRxList.map((item:any)=>{
        const data={
          PrescRefillId:item.PrescRefillId,
          PartialfillNum: item.PartialFillNo,
          PrescNum:item.PrescNum,
          Refillnum:item.Refillnum,
          Status: "RV",
          VerifDtTm: moment(Date.now()).format(MsgConfig.DATE_FORMAT),
          PrescId:item.PrescId,
          IsForRxVerification:true,
          SendReadyForPickupAlerts:  this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
          SendReadyForPickupAlertsWhen: this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When")
          
        }
        payload.push(data)
      })
      await this._rphServ.SendBatchRphVerificationDetails(payload).toPromise();
      this.IsPopUpClosed.emit(null);
    }
    else {
      this.IsPopUpClosed.emit(null);
    }
  }

}
