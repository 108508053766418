<div class="row suggest_display">
    <div class="col pr-0 input-group">
        <input 
        id="pharmacy-search" 
        type="text" 
        class="form-control height_325"
        [(ngModel)]="searchTerm"
        [ngbTypeahead]="!isFrmPatExternHist ? search : null"
        appChangeDebounce
        [title]="''"
        [minLength]="3"
        [resultTemplate]="!isFrmPatExternHist ? rt : null"
        (selectItem)="selectedPharmacyDetails($event.item)"
        [inputFormatter]="!isFrmPatExternHist ? inputFormatter : null"
        placeholder="{{ isFrmPatExternHist ? 'Enter Pharmacy Name' : 'Enter pharmacy name, phone, or #address (e.g., #Main Street).'}}"
        (keyup.enter)="isFrmPatExternHist ? triggerPharmacySearch($event.target.value) : null"/>
          <div class="search--icon" *ngIf="!isFrmPatExternHist">
            <i class="far fa-search" (click)="inputClearedBySearchIcon()"></i>
        </div> &nbsp;
        <i *ngIf="!isFrmPatExternHist" class="fas fa-info-circle" style="line-height: 2"  [ngbTooltip]="'Enter pharmacy name, phone, or #address (e.g., #Main Street).'"></i>
      </div>
      <div class="col-2 px-0" *ngIf="!isFrmPatExternHist">
        <button aria-hidden="true" id="pharmAdd" class="hotkey_success" title="Add Pharmacy"   (click)="openAddPopUp()" appShortcutKey [AltKey]="'a'"><span>A</span>ADD</button>
         </div>
      <div class="col-1 pl-0 patient-edit--transfer-in" *ngIf="!isFrmPatExternHist">
        <i class="fa-kit fa-pharmacy-icon" title="Pharmacy" [ngClass]="selectedPharmDeatails ? (selectedPharmDeatails.ServiceLevels ? 'color_green' : 'unavailable') : ''"></i> 
      </div>
</div>

<ng-template #rt let-x="result">
    <div class="row pharmacy_suggest"  (click)="selectedPharmacyDetails(x)">
        <div class="col-8 px-0 text-wrap">
            <label>Name</label>&nbsp;
            <span>{{ x?.PharmName ? (x?.PharmName | uppercase) :  msgConfig.EMPTY_Data}}</span>
        </div>
        <div class="col-4 px-0 ">
            <label>NPI#</label>&nbsp;
            <span>{{x?.NPINum ? x?.NPINum : msgConfig.EMPTY_Data}}</span>
        </div>
        <div class="col-4 px-0 mt-2">
            <label>Phone#</label>&nbsp;
            <span>{{x?.Phone ? this._commonUtil.getPhoneFormat(x?.Phone) : msgConfig.EMPTY_Data}}</span>
        </div>
        <div class="col-4 px-0 mt-2">
            <label>Fax#</label>&nbsp;
            <span>{{(x?.Fax) ? this._commonUtil.getPhoneFormatForWrongFax(x?.Fax): msgConfig.EMPTY_Data}}</span>
        </div> 
        <div class="col-8 pl-0 mt-2 text-wrap">
            <label>Address</label>&nbsp;
            <span>{{ getPatientAddress(x) | uppercase }}</span>
        </div>
        <div class="col-4 px-0 mt-2">
            <label>XFER Service</label>&nbsp;
            <span [ngClass]="x?.ServiceLevels ? 'color_green' : 'unavailable'">{{x?.ServiceLevels? 'Available': 'Unavailable'}}</span>
        </div>
    </div>
   
  </ng-template>
  
  <ng-template #patExternPhamracySuggest let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Pharmacy Search</h4>
      <button type="button" class="close" (click)="d('Cross click')">
        <span aria-hidden="true" class="close-button">&times;</span>
      </button>
    </div>
    <div class="modal-body edit-patient insurance--body">
      <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-8">
                    <label class="margin_left_0_8rem">Search</label>
                    <input
                        autofocus 
                        type="text" 
                        class="form-control search--input__box margin_left_10px" 
                        placeholder="Search Pharmacy"
                        [(ngModel)]="popupSearchTerm"
                        (keyup.enter)="searchPhamracy()"
                    />
                </div>
                <div class="col-4 margin_top_1rem">
                    <button class="hotkey_success" (click)="searchPhamracy()" appShortcutKey 
                    [AltKey]="'f'"><span>F</span>Search
                </button>
                <button class="hotkey_success" (keydown.enter)="clearInputFiled()" (click)="clearInputFiled()"
                    appShortcutKey [AltKey]="'l'">
                    <span>L</span> Clear
                </button>
                </div>
            </div>
            <div *ngIf="PharmacyNamesList && PharmacyNamesList.length > 0; else noData" class="extern_search_result">
                <div *ngFor="let x of PharmacyNamesList">
                    <ng-container *ngTemplateOutlet="rt; context: { result: x }"></ng-container>
                </div>
              </div>
              <ng-template #noData>
                <app-no-data-found></app-no-data-found>
              </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
  