import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[BarcodeDetector]'
})
export class BarcodeDetectorDirective {
    isBarcodedevicestr="";
    timeoutHandler: any = 0;
    inputString = "";
    @Input('BarcodeDetector') enableBarcode: boolean = false;
    @Input('forRxSerialBarCode') forRxSerialBarCode: boolean;

    @Output() EmitBarcode = new EventEmitter<any>();

    @HostListener("window:keypress", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (this.enableBarcode) {
            this.inputString += event.key
            let inputStr = this.el.nativeElement.value;
            if (!inputStr && this.timeoutHandler) {
                clearTimeout(this.timeoutHandler);
            } else if (inputStr && inputStr.length === 1) {
                this.timeoutHandler = setTimeout(() => {
                    inputStr = this.el.nativeElement.value;
                    if ((!this.forRxSerialBarCode && inputStr && inputStr.length >= 10) || (this.forRxSerialBarCode) ) {
                        this.isBarcodedevicestr=inputStr;
                        this.EmitBarcode.emit(this.inputString);
                        clearTimeout(this.timeoutHandler);
                        this.el.nativeElement.value = "";
                        this.inputString = "";
                    }
                }, 300);
            }
        }
    }

    
    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {   
        if (event.key === 'Backspace' && this.enableBarcode) {
            const target = event.target as HTMLInputElement;
            if (target && 'value' in target) {
               this.inputString = target.value
               if(this.isBarcodedevicestr){
                this.inputString="";
               }
               else{
                this.inputString = target.value.slice(0, -1);
               }
             
            }
        }
    }
    constructor(private el: ElementRef) {}

}
