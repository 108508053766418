


<div class="editpatient"  >
    <div class="row content--heading"  *ngIf="documentFr && (documentFr.From == 'Rx')">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <i class="far fa-user-md"></i>
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0"> Documents
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
            <button class="hotkey_success"  appShortcutKey [AltKey]="'c'" (click)="previousPage()" id="CancelButton"><span>C</span>Cancel</button>

        </div>
    </div>
    <div class="eprx--block__content">
        <div class="editpatient--body newrx--body edit--drug insurance--body erx--body">
            <div class="row exprx--common-block height_50rem">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15 flex--one">
                    <div class="box-default">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                <div class="col-sm-12 padding-0">
                                    <div class="row">
                                        <div class=" col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            Documents Information
                                        </div>
                                        <div class=" text-right pull-right col-lg-2 col-md-4 col-sm-4 col-xs-4 padding_left_2rem"
                                            >
                                            <span >Subcategory</span>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding--left__7 margin_5_0_0_0px padding_right_20px my-1 mt-1 line_height_1_8rem"
                                            >
                                            <eprx-select  [LabelText]="''"  [ControlName]="'DocSubCatIdForDefault'"
                                            [FormGroupName]="docUploadFG"  [HasBindLabel2]="false"
                                                [HasMultiple]="false"  [BindLabel]="'Name'"
                                                [BindValue]="'Id'" [LabelForId]="'Id'" [List]="subCatDropdowns"
                                                (TriggerSelectValue)="selectSubCategory($event)" [RxSelectId]="'docSubCatId'"
                                                [IsFirstElem]="true">
                                            </eprx-select>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right">
                                            <button class="hotkey_success hotkey_primary--Override" title="Add"
                                                (click)="opendocModal(docData)" (keydown.tab)="focusOutFmAdd($event)"
                                                appShortcutKey [AltKey]="'a'"><span>A</span>ADD DOCUMENT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div class="col">
                                    <div class="exprx--common-block">
                                        <wj-flex-grid #documentInfo class="grid" [headersVisibility]="'Column'"
                                        (drop)="this._storageService.storeWijmoGridDataInSession(documentInfo)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(documentInfo)"
                                            [itemsSource]="documentsWJ" [isReadOnly]="true" [selectionMode]="'Row'"
                                            (keydown)="addEventonWijimo(docData, $event)"
                                            (dblclick)="onRowDblclicked(documentInfo, $event)"
                                            (initialized)="Init(docData,documentInfo)" [ngClass]="!showGrid ? 'no-data':''"
                                            (keydown.tab)="focusOnCancel($event)" [allowSorting]=true [columnPicker]="'DocumentsListWJ'">
                                            <wj-flex-grid-column [header]="'Actions'" [width]="'1.2*'" [minWidth]="122">
                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                    <div class="text-center">
                                                        <i class="far  fa-edit  actions actions--Highlightfocus--icon " title="Edit"
                                                            (click)="editDocPopup(docData , item)"></i>
                                                        <i class="far fa-trash-alt  actions danger-actve" title="Delete"
                                                            (click)="deleteDocPopup(deleteInfo)"></i>
                                                        <!-- <i class="far fa-file-download  actions " title="Download" (click)="downloadSelectedFile(item)"></i> -->
                                                        <i class="far fa-tv  actions actions--Highlightfocus--icon" title="Preview"
                                                            (click)="previewSelectedFile(item.data)"></i>
                                                        <i class="far fa-print actions" title="Print" (click)="printTheDocOrImage(item.data)"></i>
                                                    </div>
                                                    <ng-template #deleteInfo let-c="close" let-d="dismiss">
                                                        <div class="modal-header">
                                                            <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
                                                            <button type="button" [tabindex]="-1" class="close"
                                                                aria-label="Close" (click)="d('Cross click')">
                                                                <span aria-hidden="true" class="close-button">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body edit-patient">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <table class="table table-hover">
                                                                        <tbody>
                                                                            <label>{{warningText?.deleteConfrmMsg}}</label>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="hotkey_primary"
                                                                (click)="c('Close click')" (click)="deleteDocPopup(addToQueue)"
                                                                appShortcutKey InputKey="y"><b>Y</b> YES </button>
                                                            <button type="button" class="hotkey_primary"
                                                                (click)="c('Close click')" appShortcutKey
                                                                InputKey="n"><b>N</b>NO</button>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #addToQueue let-c="close" let-d="dismiss">
                                                        <div class="modal-header">
                                                            <h4 class="modal-title">Document Status</h4>
                                                            <button type="button" [tabindex]="-1" class="close"
                                                                aria-label="Close" (click)="d('Cross click')">
                                                                <span aria-hidden="true" class="close-button">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body edit-patient">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <table class="table table-hover">
                                                                        <tbody>
                                                                            <label>This Document is no longer used by the application,
                                                                                Do you want to send it to Document Queue for
                                                                                future use?</label>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" autofocus class="hotkey_primary"
                                                                (click)="c('Close click')" (click)="deleteDoc(item,true)"
                                                                appShortcutKey InputKey="q"><b>Q</b> Queue </button>
                                                            <button type="button" class="hotkey_primary"
                                                                (click)="c('Close click')" (click)="deleteDoc(item,false)"
                                                                appShortcutKey InputKey="d"><b>D</b>Delete</button>
                                                        </div>
                                                    </ng-template>

                                                </ng-template>
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                                *ngFor="let rxheader of wjHeaders; let i = index" title={{rxheader.hName}}
                                                [width]="rxheader['width']">
                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                    <div>
                                                        <span title={{item[rxheader.hName]}}>
                                                            {{item[rxheader['hName']]}}
                                                        </span>
                                                    </div>

                                                </ng-template>
                                            </wj-flex-grid-column>
                                            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                                        </wj-flex-grid>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 padding-sm-15 flex--one">
                    <div class="box box-default box-solid border-0">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Document preview
                            </div>
                        </div>
                        <div class="eprx--block__content" *ngIf="showGrid">
                            <div class="row">
                                <div class="col">
                                    <div [ngClass]="fileTypeToShow == 'pdf' ? 'exprx--common-block height_42rem' : 'exprx--common-block imagePreview'" *ngIf="imageToShow">
                                        <lib-ngx-image-zoom *ngIf="fileTypeToShow == 'image' && imageToShow" [thumbImage]=imageToShow
                                            [fullImage]=imageToShow [magnification]="1" [zoomMode]='hover'
                                            [enableLens]="true" [lensHeight]="100" [lensWidth]="200" >
                                        </lib-ngx-image-zoom>
                                       <!--  <img *ngIf="fileTypeToShow == 'image'" [src]="imageToShow" height="100%" width="100%"> -->
                                        <iframe *ngIf="fileTypeToShow == 'pdf'" [src]="imageToShow" class="text-center width_100per border_3px_solid_black"
                                            toolbar="0" width="100%" height="100%" type="application/pdf"
                                           ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #docData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" *ngIf="!editMode">Attach Document</h4>
        <h4 class="modal-title" *ngIf="editMode">Edit Document</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close-button" (click)="d('Cross click');closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient insurance--body">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <div class="row col-sm-12">
                            <div  class="height_38 col-3">
                                <div class="row">
                                  <!---  <div *ngIf="editMode"   [ngClass]="showEditImage?'col-12' : 'col-6'">
                                        <eprx-input [AutoFocus]="true" [LabelText]="'Document ID'" [PlaceHolder]="''"
                                            [ControlName]="'Id'" [FormGroupName]="docUploadFG" [MaxLength]="10"

                                            [ReadOnly]="true">
                                        </eprx-input>
                                    </div>-->
                                    <div class="col-12">
                                        <eprx-input [AutoFocus]="editMode ? true : false" [MaxLength]="150"
                                            [LabelText]="'Title'" [PlaceHolder]="''" [ControlName]="'Name'"
                                            [FormGroupName]="docUploadFG" [IsRequired]="true"
                                            [InputErrors]="docUploadFG?.controls?.Name?.errors"
                                            [ErrorDefs]="{required: 'Required'}" [InputType]="'TEXT'"
                                            [MarkAsTouched]="docUploadFG?.controls?.Name?.touched">
                                        </eprx-input>
                                    </div>
                                    <div class="col-12">
                                        <label class="control-label form-group custom--input col-sm-12 padding-0">
                                            <span> Date </span>
                                            <input type="text" name="searchBoxCityType"

                                                class="form-control search--input__box" placeholder="To Start Search..."
                                                value="{{today}}" [readOnly]="true">
                                        </label>
                                    </div>
                                    <div class="col-12">
                                        <label class="control-label form-group custom--input col-sm-12 padding-0">
                                            <span> Category </span>
                                            <input type="text" name="searchBoxCityType"

                                                class="form-control search--input__box" value="{{functionScreen}}"
                                                [readOnly]="true">
                                        </label>
                                    </div>
                                    <div class="col-12"  [formGroup]="docUploadFG">
                                        <input class="form-check-input" type="checkbox" [id]="4" formControlName="PrintWithLabel" />
                                        <label class="form-check-label" [for]="4">
                                            Print  With  Label
                                        </label>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <eprx-select [LabelText]="'Subcategory'" [ControlName]="'DocSubCatId'"
                                            [FormGroupName]="docUploadFG" [HasMultiple]="false" [HasBindLabel2]="false"
                                            [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'"
                                            [List]="subCatDropdownsList" [IsRequired]="true"
                                            [InputErrors]="docUploadFG?.controls?.DocSubCatId?.errors"
                                            [ErrorDefs]="{required: 'Required'}"
                                            [MarkAsTouched]="docUploadFG?.controls?.DocSubCatId?.touched">
                                        </eprx-select>
                                    </div>
                                    <div class="col-12"  [formGroup]="docUploadFG">
                                        <label class="control-label full--width">
                                            <span>Comments</span>
                                            <textarea [rows]="editMode ? 18: 8" cols="50" formControlName="Remarks" maxlength="500"
                                                class="custom--text__area" placeholder="Comments"></textarea>
                                        </label>
                                    </div>
                                    <div class="col-12 ml-3" *ngIf="!editMode">
                                        <app-upload class="doc-align-top" [showScanner]="true"
                                            (UploadedFile)="fileUploaded($event)">
                                        </app-upload>
                                    </div>
                                </div>
                            </div>
                            <div  class="col-9" >
                                <div class="row">
                                    <div class="col-12 imagePreview" [ngClass]="showEditImage?'px-0' : 'pr-0'">
                                        <lib-ngx-image-zoom *ngIf="addFileType == 'image' && !showEditImage && src" [thumbImage]=src [fullImage]=src
                                            [magnification]="1" [zoomMode]='hover' [enableLens]="true"
                                            [lensWidth]="200" [circularLens]="false" [lensHeight]="100"
                                            [lensWidth]="200">
                                        </lib-ngx-image-zoom>
                                        <div *ngIf="addFileType == 'pdf' && !showEditImage" class="pdf-container view-sect"
                                           >
                                            <pdf-viewer [src]="innerHtml" [fit-to-page]="true" [original-size]="true"
                                                [show-borders]="true" [autoresize]="true" [show-all]="false" class="d-block"
                                            >
                                            </pdf-viewer>
                                        </div>
                                        <app-image-editor-ref [selectedDocuement]="selectedDocuement"  *ngIf="addFileType == 'image' && showEditImage" [ImageFile]="uploadedFile" (IsPopUpClosed)="onEditImageClose($event)"></app-image-editor-ref>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="saveDocuments()" *ngIf="!editMode " appShortcutKey
            [AltKey]="'s'"><span>S</span>SAVE</button>
      <button type="button" class="hotkey_success" (click)="editImage()" *ngIf="addFileType == 'image' && !showEditImage" appShortcutKey
            [AltKey]="'e'"><span>E</span>Edit Image</button>
        <button type="button" class="hotkey_success" (click)="updateDocuments()" *ngIf="editMode" appShortcutKey
            [AltKey]="'u'"><span>U</span>Update</button>
        <button type="button" class="hotkey_success" (click)="c('Close click');closeModal()" appShortcutKey
            [AltKey]="'c'"><span>C</span>CANCEL</button>
    </div>
</ng-template>
