import { TransferPharmacyComponent } from "./../transfer-pharmacy/transfer-pharmacy.component";

import { Router, ActivatedRoute } from "@angular/router";
import {  RxService, UserService, PatPreDrugModalService, NotesLogService, DrugService } from "src/app/services";
import { BaseUtils } from "src/app/utils/base.utils";
import { SearchNoteParams, SystemSetting } from "src/app/models";
import { AlertService } from "./../../../services/alert.service";
import { State } from "./../../../models/patient-Info.model";
import {
    PharmacyData,
    Pharmacy,
    PharmacyDatas,
    RxTransferData,
    Pharmphone
} from "./../../../models/rxTransfer.model";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    OnDestroy,
    ChangeDetectorRef,
    AfterViewInit,
    Input,
    Output,
    EventEmitter,
    HostListener,
    NgZone
} from "@angular/core";
import { RxTransferService } from "../../../services/rx-transfer.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { City, RxPatientModel } from "../../../models";
import { CommonService } from "../../../services";
import { RegEx } from "./../../../app.regex";
import * as moment from "moment";
import { NewAuditUtils, NRxSaveUtils, RxUtils } from "src/app/utils";
import { ValidateFormFieldsUtils } from "src/app/utils";
import { PrintService } from "src/app/services/print.service";
import { MsgConfig } from "src/app";
import { CommonUtil } from "../../../utils";
import { FormatsUtil } from "src/app/utils/formats.util";
import { PharmacyLabels } from "src/app/models/labels.models";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { environment } from "src/environments/environment";
import { NotesMainCat } from "src/app/models/notes.enum";
;

declare global {
    interface Window {
      RTCPeerConnection: any;
      mozRTCPeerConnection: RTCPeerConnection;
      webkitRTCPeerConnection: RTCPeerConnection;
    }
  }
@Component({
    selector: "app-rx-transfer-in",
    templateUrl: "./rx-transfer-in.component.html"
})
export class RxTransferInComponent implements OnInit, AfterViewInit, OnDestroy {
    rxTransferFG: FormGroup;
    rxNumberFG: FormGroup;
    pharmFG: FormGroup;
    addPharFG: FormGroup;
    insuranceInfoFG: any;
    rxTransferDet: RxTransferData = new RxTransferData();
    pharmcyDet: PharmacyData = new PharmacyData();
    citiesList: City[] = [];
    Cities: any;
    statesList: City[] = [];
    states: any;
    stateId: any;
    PharmacyNames: PharmacyDatas = new PharmacyDatas();
    public citySelected = null;
    public stateSelected = null;
    Phone1: any;
    Phone2: any;
    Fax: any;
    hideFilterForm: boolean;
    hideRxdetail: boolean;
    TodayDate: any;
    regex: any;
    route: string;
    searchText: string;
    showRxTransferIn: boolean;
    isedit = false;
    warningMessage: string;
    modalRef: any;
    modalRef1: any;
    modalRef2: any;
    model : any;
    pharmacyabels = new PharmacyLabels();
    selectedpharmacyDetails: any;
    selectedPatientDetails: any;
    msgConfig = MsgConfig;
    @ViewChild("warningInfo", { static: true })
    warningInfo: ElementRef;
    @ViewChild("deceased", { static: true })
    deceased: ElementRef;

    @ViewChild("FAX", { static: true })
    TOFAX: any;

    @ViewChild("myInput", { read: ElementRef })
    myInput: ElementRef;

    @ViewChild("UPDATE", { static: true })
    UPDATE: any;

    @ViewChild("FAXCHANGE", { static: true })
    FAXCHANGE: any;

    @ViewChild("DiscontinuedRx", { static: true})
    DiscontinuedRx: any;

    zipmaskPattern: string;
    newPharId: any;
    minDate: { year: any; month: any; day: any };
    showRxTransferOut: boolean;
    showNewRxTrIN: boolean;
    rxNumber: number;
    rxPatientModel: RxPatientModel;
    rxPrescriberModel: any;
    rxDrugModel: any;
    rxModelFG: FormGroup;
    getPharmNanes: any;
    newRxPhone: any;
    @Output()
    IsNewRxSaved = new EventEmitter();
    formGroupInvalid: boolean;
    pharmFGInvalid: boolean;
    openPopUP: any;
    scndpharmValid = false;
    systemStng: SystemSetting;
    addPopUp = false;
    cities: any;
    AutoFoucus1 = true;
    AutoFoucus2 = false;
    actvmodal: string;
    disabled: boolean;
    deceasedPop: any;
    warningMsgDeceased: any;
    RfOrRx: any;
    AutoFoucus3 = false;
    refillNos = "";
    recvRph: string;
    patientId: any;
    modalRef3: any;
    prtObj: any;
    holdRx: boolean;
    detailsForOut: any;
    isFiledNew: any;
    fileFG: FormGroup;
    focusOn: number;
    faxFG: FormGroup;
    logPhaId: any;
    hasFaxNumber = false;
    prevSavedInfo: any;
    refillNosFrFile: any;
    isFiledRx: boolean;
    qtyTranFrFille: number;
    transferdetbeforediscont: any;
    RemRefQty: any;
    PrescReFillId: any;
    refillnum: any;
    respdata: any;
    refillsList: any;
    oldFax: any;
    transferInOutResponse: Object;
    transferInOutResp: Object;
    rxStatus: any;
    unsubscribe$: Subject<void> = new Subject();
    grpTransOutRxs: any;
    restoreFG: FormGroup;
    restoreParams: any;
    holdFromPat: any;
    DataResult: any;
    subscriptions = new Subscription();
    @Input()
    set RxNumber(rxNo: number) {
        this.rxNumber = rxNo;
        this.showNewRxTrIN = true;
    }
    @Input()
    set ReFillNo(refillNo: number) {
        this.refillnum = refillNo;
        this.showNewRxTrIN = true;
    }
    @Input()
    set PrescRefillId(id: any) {
        this.PrescReFillId = id;
        this.showNewRxTrIN = true;
    }
    @Input() frmPatHis: any;
    @Input() RxTransferType: string;

    @Input()
    set RxOutNum(rxNo: number) {
        if (rxNo) {
            this.rxTransferFG.controls["prescnumid"].patchValue(rxNo);
            this.rxNumber = rxNo;
            this.getRxDetails();
        }
    }

    @Input()
    set RxTransferInfo(data: any) {
        this.hideRxdetail = true;
        this.rxTransferDet.Rxtransfer = data;
    }

    @Input() rxInfoNew: any;
    @Input() set PatientDetails(patient: any) {
        this.selectedPatientDetails = patient;
    }
    @Input()
    set RxTransferPhone(phn: any) {
        this.newRxPhone = phn;
    }

    @Input()
    set PrevSavedData(data: any) {
        if (data) {
            this.prevSavedInfo = data;
            this.rxTransferFG.patchValue(data);
            this.getPharmcyDetails();
        } else {
            this.prevSavedInfo = null;
        }
    }

    @Input()
    set saveNewRxTXIN(data: any) {
        if (data) {
            data && data === "print" ? this.printNewIn() : this.RxTransferIn();
        }
    }
    @Input()
    set GrpTransOutList(data: any) {
        if (data && data.length) {
            this.grpTransOutRxs = data;
        }
    }

    @Input()
    set patchTransferInfo(data: any) {
        if (data) {
            this.patchTransferINOut(data);
        }
    }

    @Input()
    set clearRxData(clearData) {
        if (clearData) {
            this.clearRxdata();
        }
    }

    @Input() typeResp: any;
    @Input() IsOnlyButtons: any;
    
    @Input()
    set holdRxFrmPatHist(data: any) {
        if (data) {
            this.holdFromPat = data;
            this.unHoldRx(this.typeResp);
        }
    }

    @Input() currentTrOutRxIndex: any;

    @Input() frmNewRx: boolean;
    @Input() RxType: string;
    @Output() IsPopUpClosed = new EventEmitter();
    localIP: string | null = null;
    error: string | null = null;
    markasTransferIn = false;
    localIp = sessionStorage.getItem('LOCAL_IP');
    ipRegex = new RegExp(
        /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
      );
    @HostListener("window:keyup", ["$event"])
    keyEvent(event: KeyboardEvent) {
        if (event.which === 27) {
            if (this.actvmodal === "popUp") {
                this.clearRxdata();
            }
        }
    }

    constructor(
        private _fb: FormBuilder,
        private modalService: NgbModal,
        public _commonServ: CommonService,
        private _rxSer: RxTransferService,
        private _rxService: RxService,
        private _alertService: AlertService,
        private _cdr: ChangeDetectorRef,
        private _baseUtls: BaseUtils,
        private _actvRoute: ActivatedRoute,
        private _rxUtils: RxUtils,
        private _route: Router,
        private _userService: UserService,
        private _printser: PrintService,
        private _formUtils: ValidateFormFieldsUtils,
        private _user: UserService,
        private _patPreDrugService: PatPreDrugModalService,
        private _nrxSaveUtil: NRxSaveUtils,
        private _nwAudtUtl: NewAuditUtils,
        public _formatsUtil:FormatsUtil,
        public _commonUtil: CommonUtil,
        private _patientUtil: PatientInfoUtil,
        private _notesLogSvc: NotesLogService,
        private _drugServ: DrugService
    ) {
        this.markasTransferIn = environment.IseRxTransferRequest;
        this.logPhaId = this._user.getToken("UserId") ? +this._user.getToken("UserId") : null;
        if (localStorage.getItem("Restore") && this._commonServ.DecryptData(localStorage.getItem("Restore"))) {
            this.restoreParams = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("Restore")));
        }
        this.rxTransferFG = this._fb.group({
            Note:null,
            prescnumid: null,
            OrgRxNum: null,
            OrgRxDtTm: null,
            OrgLastRxFillDtTm: null,
            XferPharmIdxId: this.restoreParams ? this.restoreParams.otherPhamName : null,
            OrgPharmacistNm: this.restoreParams ? this.restoreParams.origPharmcistName : null,
            PharmacistId: this._user.getToken("UserId")
                ? +this._user.getToken("UserId")
                : null,
            TransferDtTm: null,
            Remarks: null,
            SigCode: null,
            SigDescription: null,
            RefillorRx: false,
            function: true,
            refillnum: null,
            IsFiledRx: false,
            IsFmPrint: false,
            Refills: 0,
            PartialFillNo: null,
            PartialFillQty:  null,
            RefillsCount: null,
            RefillsTransferredList: null,
            PrescRefillId: null,
            transferType: null
        });
        this.rxNumberFG = this._fb.group({
            rxnum: null
        });
        this.pharmFG = this._fb.group({
            pharnum: null
        });
        this.faxFG = this._fb.group({
            fax: [null],
            tofax: [null]
        });
        this.addPharFG = this._fb.group({
            TransferPharmacies: this._fb.group(new Pharmacy()),
            phone1: null,
            phone2: null,
            Fax: null,
            Phone: this._fb.array([new Pharmphone()])
        });
        this.regex = RegEx;
        this.route = window.location.href;
    }

    ngOnInit() {
        this._commonServ.systemSettings$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            this.systemStng = resp;
        });
        this.RfOrRx = this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
        this.getPharmachyNames("intial");
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        if (this.showNewRxTrIN) {
            this.setNewRxfields();
        }
        if (localStorage.getItem("RestoreAddrData") && this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData")) && this.frmPatHis && this.frmPatHis["Type"] === "Out") {
            this.DataResult = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData")));
            this.hideFilterForm = true;
            if(this.DataResult?.TransferPharmacy?.Fax)
                this.faxFG.patchValue({
                    fax: this.DataResult?.TransferPharmacy?.Fax
                });
        }
        if (this.frmPatHis) {
            this.searchText = this.frmPatHis["Type"] === "In" ? "Rx To Transfer IN" : "Rx To Transfer OUT";
            this.showRxTransferIn = this.frmPatHis["Type"] === "In";
            this.showRxTransferOut = this.frmPatHis["Type"] === "Out";
        } else if (this.route.includes("TransferRxIn")) {
            this.searchText = "Rx To Transfer IN";
            this.showRxTransferIn = true;
        } else if (this.route.includes("TransferRxOut")) {
            this.showRxTransferOut = true;
            this.searchText = "Rx To Transfer OUT";
        } else if (this.route.includes("rx") || this.showNewRxTrIN) {
            this.showNewRxTrIN = true;
        }
        if (this.frmPatHis) {
            this.showRxTransferIn = this.frmPatHis["Type"] === "In";
            this.showRxTransferOut = this.frmPatHis["Type"] === "Out";
            this.rxTransferFG.controls["prescnumid"].patchValue(this.frmPatHis["rxNo"]);
            this.rxNumber = this.frmPatHis["rxNo"];
            this.rxTransferFG.controls["PrescRefillId"].patchValue(this.frmPatHis["prescRefillId"]);
            this.PrescReFillId = this.frmPatHis["prescRefillId"];
            this.rxTransferFG.controls["refillnum"].patchValue(this.frmPatHis["RefillNo"]);
            this.refillnum = this.frmPatHis["RefillNo"];
            // this.getRxDetails();
        }
        setTimeout(() => {
           const index = this.PharmacyNames.pharmacistNames
           && this.PharmacyNames.pharmacistNames.length
           && this.PharmacyNames.pharmacistNames.findIndex(val => val["PharmacistId"] === this.rxTransferFG.value.PharmacistId);
            if (index && this.PharmacyNames.pharmacistNames
                && this.PharmacyNames.pharmacistNames.length && this.PharmacyNames.pharmacistNames[index]) {
                this.recvRph = this.PharmacyNames.pharmacistNames[index].PharmacistName;
            }
        }, 3000);

        const faxNumberSetting = this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Number");
        if (faxNumberSetting && faxNumberSetting.length) {
            this.hasFaxNumber = true;
        } else {
            this.hasFaxNumber = false;
        }
    }
    setNewRxfields() {
        this.rxTransferFG.controls["PharmacistId"].patchValue(this.logPhaId);
        this.rxTransferFG.controls["function"].patchValue(true);
        this.rxTransferFG.controls["prescnumid"].patchValue(this.rxNumber);
        this.TodayDate = moment.utc().local().format("MM/DD/YYYY");
        this.rxTransferFG.controls["TransferDtTm"].patchValue(this.TodayDate);
        this.rxTransferFG.controls["OrgRxDtTm"].patchValue(
            this.prevSavedInfo && this.prevSavedInfo.OrgRxDtTm
                ? moment.utc(this.prevSavedInfo.OrgRxDtTm).local().format("MM/DD/YYYY")
                : this.TodayDate
        );
        this.rxTransferFG.controls["OrgLastRxFillDtTm"].patchValue(
            this.prevSavedInfo && this.prevSavedInfo.OrgLastRxFillDtTm
                ? moment.utc(this.prevSavedInfo.OrgLastRxFillDtTm).local().format(
                    "MM/DD/YYYY"
                )
                : this.TodayDate
        );
        this.rxTransferFG.controls["SigDescription"].patchValue(
            this.prevSavedInfo ? this.prevSavedInfo.SigDescription : null
        );
        this.rxTransferFG.controls["PrescRefillId"].patchValue(this.PrescReFillId);
        this.rxTransferFG.controls["refillnum"].patchValue(this.refillnum);
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewInit() {
        if (this.frmPatHis) {
            this.focusOn = this.frmPatHis["Type"] === "In" ? 2 : 3;
            if(!this.markasTransferIn) {
                setTimeout(() => {
                    if (document.getElementById(this.focusOn === 2 ? "OriginalRxN" : "TransOutDate")) {
                        document.getElementById(this.focusOn === 2 ? "OriginalRxN" : "TransOutDate").focus();
                    }
                }, 1000);
            }
        } else if (this.route.includes("TransferRxIn")) {
            this.focusOn = 2;
        } else if (this.route.includes("TransferRxOut")) {
            this.focusOn = 3;
        } else if (this.route.includes("rx") || this.showNewRxTrIN) {
            this.focusOn = 2;
            if (document.getElementById("OriginalRxN")) {
                document.getElementById("OriginalRxN").focus();
            }
        }
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    alertPopUp() {
        const Id = this.rxTransferFG.controls["prescnumid"].value;
        this._rxSer.RxTransferpopUp(Id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {});
    }

    getPharmachyNames(type?: any) {
      this._rxSer.getPharmacyNames(this.frmNewRx ? true : false)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp => {
        if (resp) {
            this.getPharmNanes = resp;
            this.PharmacyNames.pharmNames = this.arrangePharmDet(resp["pharmNames"]);
            this.PharmacyNames.pharmacistNames = resp["pharmacistNames"];
            if (this._user.getToken("TechUserName") && type) {
                this.logPhaId = null;
                if (resp["pharmacistNames"] && resp["pharmacistNames"].length) {
                   const d = resp["pharmacistNames"].find(v => v["PharmacistName"] === this._user.getToken("PhUserName"));
                   this.logPhaId = d ? d["PharmacistId"] : null;
                }
                this.rxTransferFG.controls["PharmacistId"].patchValue(this.logPhaId);
            } else {
                this.logPhaId = this._user.getToken("UserId") ? +this._user.getToken("UserId") : null;
            }
        }
      });
    }

    arrangePharmDet(data) {
        let pharmaDetails = [];
        if (data && data["pharmNamesViewModels"] && data["pharmNamesViewModels"].length) {
            pharmaDetails = data["pharmNamesViewModels"].map(v => {
                const k = {};
                k["Id"] = v["Id"];
                k["PharmacyName"] = v["PharmacyName"];
                let phoneDet = null;
                if (data["Phones"] && data["Phones"].length) {
                    phoneDet = data["Phones"].filter(d => d["Id"] === v["Id"]);
                }
                if(data["addresses"] && data["addresses"].length){
                    const addrs = data["addresses"].filter(a=> a["id"] === v["Id"]);
                    k["addresses"] = ( addrs[0]?.add1 ?  addrs[0].add1 + "," + " "
                    + (addrs[0]?.city ?  addrs[0]?.city + "," + " " : "")  +
                    (addrs[0]?.state ? addrs[0]?.state + "," + " " : "") +
                     (addrs[0]?.zipcode ? addrs[0]?.zipcode : "") : "" );

                }
                if (phoneDet && phoneDet.length) {
                    const ph12 = phoneDet.filter(pd => pd["PhoneCatId"] === 3);
                    k["Phone1"] = ph12 && ph12.length ? ph12[0]["Phone"] : null;
                    k["Phone2"] = ph12 && ph12.length > 1 ? ph12[1]["Phone"] : null;
                    const fax = phoneDet.filter(pd => pd["PhoneCatId"] === 4);
                    k["Fax"] = fax && fax.length ? fax[0]["Phone"] : null;
                } else {
                    k["Phone1"] = null;
                    k["Phone2"] = null;
                    k["Fax"] = null;
                }
                k["NPI"] = v.NPINum ? v.NPINum : null;
                return k;
            });
        }
        return pharmaDetails;
    }

    async getRxDetails(type?) {
        if (this.rxTransferFG.controls["prescnumid"].value) {
            const Id = this.rxTransferFG.controls["prescnumid"].value;
            const refillId = this.rxTransferFG.controls["refillnum"].value;
            const resp = await this._rxSer.getRxTransferPopups(Id, this.showRxTransferOut ? true : false, refillId).toPromise();
            if (this.showRxTransferIn) {
                this.holdRx = false;
                if (resp === 0 || resp === -1 || resp === 1 || resp === 4 || resp === 3) {
                    this.actvmodal = "popUp";
                    this.modalRef = this.modalService.open(this.warningInfo, {centered: true, backdrop: false, windowClass: "large--content"});
                    this.warningMessage = resp === 4 ? "This prescription has already been Transferred Out." :
                    resp === 3 ? "Rx is already Transferred In." : "Rx not found. Please re-enter Rx.";

                    if (document.getElementById("myInput")) {
                        document.getElementById("myInput").focus();
                    }
                } else if (resp === 2) {
                    this.getTrasferDetails();
                }
            } else if (this.showRxTransferOut) {
                if(resp === -1){
                    this.actvmodal = "popUp";
                    if (this.modalRef) {
                        this.modalRef.close();
                        this.modalRef = null;
                    }
                    this.modalRef = this.modalService.open(this.warningInfo, {centered: true, backdrop: false, windowClass: "large--content"});
                    this.warningMessage = "Rx not found. Please re-enter Rx.";
                    if (document.getElementById("myInput")) {
                        document.getElementById("myInput").focus();
                    }
                } else if (resp === 2 || resp === 3) {
                    this.holdRx = false;
                    this.getTrasferDetails(type);
                } else if (resp === 5 || resp === 4 || resp === 6 || resp === 7) {
                    this.holdRx = resp === 6 ? true : false;
                    this.actvmodal = "popUp";
                    this.modalRef = this.modalService.open(this.warningInfo, {centered: true, backdrop: false, windowClass: "large--content"});
                    this.warningMessage = resp === 5 ? "No Quantity left on this Rx – Cannot be Transferred Out." :
                    resp === 6 ? "Hold Rx cannot be Transferred Out. <br/> Please Unhold if you wish to Transfer Out." :
                    resp === 4 ? "Rx is already Transferred Out." :
                    resp === 7 ? "Rx#: " + Id + " is expired. Cannot be Transferred out." : "Rx not found.Please re-enter Rx.";

                    if (document.getElementById("myInput")) {
                        document.getElementById("myInput").focus();
                    }
                }
            }
        }
    }

    selectedValue(e) {
        if (e && e.value) {
            this.recvRph = e.value.PharmacistName;
        }
    }

    updateFaxToPharm() {
        if (this.faxFG.valid) {
            this.modalRef3 = this.modalService.open(this.FAXCHANGE , {
                centered: true,
                backdrop: false,
                windowClass: "large--content"
            });
        } else {
            this._formUtils.validateAllFormFields(this.faxFG);
        }
    }

    faxChanged() {
        this.closeModalForFaxChanged(true);
        if(this.pharmcyDet && this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.PharmId && this.faxFG && this.faxFG.value && this.faxFG.value.fax){
            this._rxSer.updateFaxToPharm(this.pharmcyDet.TransferPharmacy.PharmId, this.faxFG.value.fax)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.openFax();
                }
            });
        }
    }

    closeModalForFaxChanged(val?: any) {
        if (this.modalRef3) {
            this.modalRef3.close();
            this.modalRef3 = null;
            }
        if (!val) {
            if (this.oldFax !== this.faxFG.value.fax) {
                this.faxFG.controls["fax"].patchValue(this.oldFax);
            }
        }
    }

    checkFaxNum() {
        let upfax: any;
        if (this.pharmcyDet && this.pharmcyDet.Phone && this.pharmcyDet.Phone[2]) {
            if (this.pharmcyDet.Phone[2].Phone === " ") {
                upfax =  null ;
            } else {
                upfax = this.pharmcyDet.Phone[2].Phone;
            }
        }
        this.faxFG.controls["fax"].patchValue( this.faxFG.value["fax"] ?  this.faxFG.value["fax"] : upfax);
    }

    async printNewIn() {
        if (this.rxTransferFG.valid) {
            this.rxTransferFG.controls["RefillorRx"].setValue(true);
            this.rxTransferFG.controls["function"].setValue(true);
            this.rxTransferFG.controls["refillnum"].setValue(this.rxTransferDet.Rxtransfer.Refillno);
            const value = this.rxTransferFG.getRawValue();
            const data = {
                transferType: "TransferIn",
                IsRxNotTransferredIn: true,
                OrgLastRxFillDtTm: value["OrgLastRxFillDtTm"],
                OrgPharmacistNm: value["OrgPharmacistNm"],
                OrgRxDtTm: value["OrgRxDtTm"],
                OrgRxNum: value["OrgRxNum"],
                PharmacistId: this.recvRph,
                Remarks: value?.Note?.xNote,
                TransferDtTm: value["TransferDtTm"],
                XferPharmId: value["XferPharmIdxId"],
                IsFiledRx: value["IsFiledRx"],
                RxNum: this.rxTransferFG.value["prescnumid"],
                Refills: this.rxTransferDet && this.rxTransferDet.Rxtransfer ? this.rxTransferDet.Rxtransfer.Aref : 0,
                OrdQty: this.rxTransferDet && this.rxTransferDet.Rxtransfer ? this.rxTransferDet.Rxtransfer.Qty : 0,
                PatientId: this.rxInfoNew && this.rxInfoNew.Patient ? this.rxInfoNew.Patient.patientid : null,
                PrescriberId: this.rxInfoNew && this.rxInfoNew.Prescriber ? this.rxInfoNew.Prescriber.prescriberid : null,
                DrugId: this.rxInfoNew && this.rxInfoNew.Drug ? this.rxInfoNew.Drug.id : null,
                Sig: this.rxInfoNew && this.rxInfoNew.SigCodes ? this.rxInfoNew.SigCodes.Description : null,
                DawId: this.rxInfoNew && this.rxInfoNew.PrescReFill ? this.rxInfoNew.PrescReFill.DAWId : null,
            };
            const resp = await this._rxSer.printReport(data).toPromise();
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                await this.printReport(resp);
                this.IsNewRxSaved.emit(value);
            }
        } else {
            this.formGroupInvalid = true;
        }
    }

    async print() {
        const data = {FromPrescRefillId: this.transferInOutResp["PrescRefillId"],
        transferType: this.showRxTransferOut ? "TransferOut" : "TransferIn",
        RxNum: this.rxNumber, IsRxNotTransferredIn: false};
        const resp = await this._rxSer.printReport(data).toPromise();
        if (resp && resp.size > 0 && resp.type === "application/pdf") {
            await this.printReport(resp);
        }
    }

    getPrintObject() {
        this.prtObj = {
            FromPrescRefillId: this.transferInOutResp["PrescRefillId"],
            PharmacyId: null,
            TenantId: null,
            TransferDate: this.rxTransferFG.value.TransferDtTm,
            phone : this.pharmcyDet && this.pharmcyDet.Phone && this.pharmcyDet.Phone[0] && this.pharmcyDet.Phone[0].Phone ? this.pharmcyDet.Phone[0].Phone : null,
            OtherPharmacist: this.rxTransferFG.value.OrgPharmacistNm,
            PharmacyName: this.pharmcyDet && this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.PharmName ? this.pharmcyDet.TransferPharmacy.PharmName : null,
            FaxNum: this.pharmcyDet && this.pharmcyDet.Phone && this.pharmcyDet.Phone[2] && this.pharmcyDet.Phone[2].Phone ? this.pharmcyDet.Phone[2].Phone : null,
            ReceivingRPH: this.recvRph,
            Remarks: this.rxTransferFG.value.Remarks,
            Refills: this.rxTransferDet.Rxtransfer.RxStatusId === 6 ?
            this.rxTransferDet.Rxtransfer.Refillno + this.refillNos ? (" , " + this.refillNos) : "" :
            this.rxTransferDet.Rxtransfer.Refillno !== this.rxTransferDet.Rxtransfer.Aref ?
            !(+this.RfOrRx) ? (this.rxTransferDet.Rxtransfer.Refillno ? this.rxTransferDet.Rxtransfer.Refillno : 0) + 1 :
            this.refillNos : null,
            Qty : this.rxTransferDet.Rxtransfer.TotalQty,
            PartialFillQty: this.showRxTransferOut ? this.rxTransferDet.Rxtransfer.RxStatusId !== 6 &&
            (this.rxTransferDet.Rxtransfer.Refillno === this.rxTransferDet.Rxtransfer.Aref ||
                this.rxTransferDet.Rxtransfer.FillRemainingQty) ? this.rxTransferDet.Rxtransfer.FillRemainingQty : null : null,
        };
    }

    updateFax() {
        this.checkFaxNum();
        this.oldFax = this.faxFG.value.fax;
        this.modalRef1 = this.modalService.open(this.UPDATE, {
            centered: true,
            backdrop: false,
            windowClass: "large--content"
        });
    }

    openFax() {
        if (this.faxFG.valid) {
            this.closeModal();
            this.faxFG.controls["tofax"].patchValue(this.faxFG.value.fax);
            this.checkFaxLen();
            // this.modalRef2 = this.modalService.open(this.TOFAX, {
            //     centered: true,
            //     backdrop: false
            // });
            this.RxTransferIn('fax');
        }
    }

    fax() {
        this.getPrintObject();
        const sendFax = {
            FaxTo:  this.pharmcyDet && this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.PharmName ? this.pharmcyDet.TransferPharmacy.PharmName : null,
            FaxFrom: this._userService.getToken("PharmacyName"),
            FaxNumber: this.faxFG.value.tofax,
            RxNo: this.rxTransferFG.value.prescnumid,
            transferTo: this.prtObj,
            FaxPrefix: this._commonServ.getSetttingValue("FaxAndReportsSettings", "Fax_Prefix")
        };

        this.subscriptions.add(
            this._rxSer.sendFax(sendFax).subscribe(
                (resp) => {
                    if (resp) {
                        this._alertService.success("Fax sent successfully.");
                        this.closeModal(); // Assuming this closes the modal for fax.
                        this.routeBackToPrevScreen("Save");
                    } else {
                        this._alertService.error("Fax sent unsuccessfully.");
                    }
                    if (this.subscriptions) {
                        this.subscriptions.unsubscribe();
                        this.subscriptions = new Subscription();
                    }
                }));
    }

    closeModal() {
        if (this.modalRef1) {
            this.modalRef1.close();
            this.modalRef1 = null;
        }
    }

    // closeModalForFax() {
    //     this.modalRef2.close();
    //     this.modalRef2 = null;
    // }

    toFaxNumber(e) {
      this.checkFaxLen();
    }

    checkFaxLen() {
        if ( this.faxFG.controls["tofax"].value.length >= 10 ) {
            this.disabled = false;
        } else {
            this.disabled = true;
        }
    }

    printReport(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printser.printPdf(base64data);
        }.bind(this);
    }

    async getTrasferDetails(type?) {
        const Id = this.rxTransferFG.controls["prescnumid"].value;
        const refillId = !this.showRxTransferOut ? this.rxTransferFG.controls["PrescRefillId"].value : null;
        const resp: any = await this._rxSer.getRxTransferDetails(Id, this.showRxTransferOut ? true : false, refillId).toPromise();
        if (resp) {
            if (resp["Rxtransfer"].OrderStatus &&
                resp["Rxtransfer"].OrderStatus.trim().toLowerCase() === "d" && this.showRxTransferOut) {
                this.transferdetbeforediscont = resp;
                this.modalRef = this.modalService.open(this.DiscontinuedRx, {
                    centered: true,
                    backdrop: false,
                    windowClass: "large--content"
                });
            } else {
                this.patchTransferINOut(resp, type);
            }
        }
    }

    async setOrderStatus() {
        await this._rxService.putRxDiscontinuedOrderStatus(this.transferdetbeforediscont.PrescId).toPromise();
        this.patchTransferINOut(this.transferdetbeforediscont);
    }

    patchTransferINOut(response, type?) {
        this.showRxTransferIn = this.showRxTransferIn ? this.showRxTransferIn :
        (this.frmPatHis && this.frmPatHis["Type"] === "In");
        this.showRxTransferOut = this.showRxTransferOut ? this.showRxTransferOut :
        (this.frmPatHis && this.frmPatHis["Type"] === "Out");
        this.hideRxdetail = true;
        this.patientId = response["Rxtransfer"]["PatientId"];
        this.rxTransferDet.Rxtransfer = response["Rxtransfer"];
        if (this.rxTransferDet.Rxtransfer.RxStatus === 2 || this.rxTransferDet.Rxtransfer.StatusId === 2
             || this.rxTransferDet.Rxtransfer.RxStatusId === 6) {
            this.isFiledRx = true;
        } else {
            this.isFiledRx = false;
        }
        if (this.showRxTransferOut) {
            if (!localStorage.getItem("Restore") && !this._commonServ.DecryptData(localStorage.getItem("Restore"))) {
                this.restoreFG = this._fb.group({
                    origPharmcistName: null,
                    otherPhamName: null
                });
            }
            this.rxTransferDet.Rxtransfer.RxNum = this.frmPatHis ? this.frmPatHis["rxNo"] : "";
            this.refillNos = "";
            for (let i = this.rxTransferDet.Rxtransfer.Refillno + 1; i <= this.rxTransferDet.Rxtransfer.Aref; i++) {
                this.refillNos = this.refillNos + i;
                if (i !== this.rxTransferDet.Rxtransfer.Aref) {
                    this.refillNos = this.refillNos + " , ";
                }
            }
            if (this.isFiledRx) {
                this.RfOrRx = this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT");
                let fillQty = 0;
                if (!(+this.RfOrRx)) {
                if (this.rxTransferDet.Rxtransfer.FillNo > 1) {
                     this.refillsList = this.rxTransferDet.Rxtransfer.Refillno;
                    if (this.rxTransferDet.Rxtransfer.Aref === this.rxTransferDet.Rxtransfer.Refillno) {
                        this.refillsList = this.refillsList + "- Remaining Fill Qty" ;
                        this.refillNosFrFile = this.refillsList;
                        fillQty = fillQty + this.rxTransferDet.Rxtransfer.FillRemainingQty + this.rxTransferDet.Rxtransfer.FillDispQty;
                    } else {
                        this.refillsList = this.refillsList + 1;
                        this.refillNosFrFile = this.refillsList;
                        this.RemRefQty = ((this.refillNos.split(",").length) * this.rxTransferDet.Rxtransfer.Qty);
                        fillQty = 0;
                        this.isFiledRx = false;
                    }
                } else {
                    this.refillsList = this.rxTransferDet.Rxtransfer.Refillno;
                    this.refillNosFrFile = this.refillsList;
                    fillQty = 0;
                }
            } else {
                if (this.rxTransferDet.Rxtransfer.FillNo > 1) {
                    this.refillsList = this.rxTransferDet.Rxtransfer.Refillno;
                    if (this.rxTransferDet.Rxtransfer.Aref === this.rxTransferDet.Rxtransfer.Refillno) {
                        this.refillsList = this.refillsList + "- Remaining Fill Qty" ;
                        this.refillNosFrFile = this.refillsList;
                    } else {
                        this.refillsList = this.refillsList + "- RFQ";
                        this.refillNosFrFile = this.refillsList + ", " + this.refillNos;
                        this.RemRefQty = ((this.refillNos.split(",").length) * this.rxTransferDet.Rxtransfer.Qty);
                    }
                    fillQty = fillQty + this.rxTransferDet.Rxtransfer.FillRemainingQty + this.rxTransferDet.Rxtransfer.FillDispQty;
                } else {
                    this.refillNosFrFile = this.rxTransferDet.Rxtransfer.Refillno + ", " + this.refillNos;
                }
            }
                this.qtyTranFrFille = fillQty;
            }
        }
        this.rxTransferFG.controls["OrgRxDtTm"].patchValue(moment.utc(
            this.rxTransferDet.Rxtransfer.OriginalRxDate).local().format("MM/DD/YYYY"));
        this.rxTransferFG.controls["OrgLastRxFillDtTm"].patchValue(moment.utc(
            this.rxTransferDet.Rxtransfer.RxFillDate).local().format("MM/DD/YYYY"));
        this.rxTransferFG.controls["TransferDtTm"].patchValue(moment.utc().local().format("MM/DD/YYYY"));
        this.rxTransferFG.controls["SigCode"].patchValue(this.rxTransferDet.Rxtransfer.SigCode);
        this.rxTransferFG.controls["SigDescription"].patchValue(this.rxTransferDet.Rxtransfer.SigDescription);
        this.rxTransferFG.controls["function"].patchValue(this.showRxTransferOut ? true : false);
        this.rxTransferFG.controls["PharmacistId"].patchValue(this.logPhaId);
        this.focusOn = this.showRxTransferOut ? 3 : 2;
        if(!this.markasTransferIn) {
            setTimeout(() => {
                if (document.getElementById(this.focusOn === 2 ? "OriginalRxN" : "TransOutDate")) {
                    document.getElementById(this.focusOn === 2 ? "OriginalRxN" : "TransOutDate").focus();
                }
            }, 500);
        }
        /* if (this.showRxTransferOut && (type === "hold" && this.holdFromPat) && this.grpTransOutRxs?.length) {
            this.grpTransOutRxs.push(this.rxTransferDet.Rxtransfer.RxNum + "-" +this.rxTransferDet.Rxtransfer.Refillno + (this.frmPatHis.FillNo ? "-"+this.frmPatHis.FillNo : " "));
         } */
        this.rxStatus = this._commonUtil.rxStatusForTransferInOut(this.rxTransferDet);

    }


    unHoldRx(type) {
        if (this.frmPatHis) {
            this.rxTransferFG.controls["prescnumid"].patchValue(this.frmPatHis["rxNo"]);
        }
        this._commonServ
            .getRxDetailsByPrescNos(
                [this.rxTransferFG.controls["prescnumid"].value],
                null
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(async resp => {
                if (resp && resp.length > 0) {
                    const dataInfo: any = resp[0];
                    const oldInfo = Object.assign({}, resp[0]);
                    if (type === "hold") {
                        dataInfo["PrescReFill"]["IsRxHold"] = false;
                    } else if (type === "file" && !this.isFiledNew) {
                        dataInfo["Prescription"]["RxStatusId"] = 1;
                        dataInfo["PrescReFill"]["StatusId"] = 1;
                        dataInfo["RxFile"] = null;
                    } else if (type === "file" && this.isFiledNew) {
                        if (this.modalRef) {
                            this.modalRef.close();
                            this.modalRef = null;
                        }
                        sessionStorage.setItem(
                            "CreatingNewFile",
                            "" + this.rxTransferFG.controls["prescnumid"].value
                        );
                        this._actvRoute.queryParams
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe(params => {
                            if (params && params.fm) {
                                sessionStorage.setItem(
                                    "FrmTransOut",
                                    "" + params.fm
                                );
                                sessionStorage.setItem(
                                    "IdTransOut",
                                    "" + this.rxTransferFG.controls["prescnumid"].value
                                );
                            } else {
                                sessionStorage.setItem(
                                    "FrmTransOut",
                                    "" + true
                                );
                            }
                        });
                        sessionStorage.removeItem("ErxType");
                        dataInfo.RxPay = null;
                        this._rxUtils.storeERXInfo(
                            dataInfo,
                            this.rxTransferFG.controls["prescnumid"].value,
                            "nrf"
                        );
                    }
                    if (!this.isFiledNew) {
                        const resps = await this._nrxSaveUtil.updateRxInfo(dataInfo, oldInfo);
                        if (resps && !isNaN(+resps.RxNum) && +(resps.RxNum) > 0) {
                            if (this.modalRef) {
                                this.modalRef.close();
                                this.modalRef = null;
                            }
                            this.holdRx = false;
                            this.focusOn = 3;
                            setTimeout(() => {
                                if (type === "hold") {
                                    this.getRxDetails(type);
                                } else {
                                    this.patchTransferINOut(this.detailsForOut);
                                }
                            }, 5);
                        } else if (isNaN(+resps.Message)) {
                            if (resps.Message === MsgConfig.MULTIPLE_USER_SAVE) {
                                this._alertService.error(MsgConfig.MULTIPLE_USER_SAVE_MSG);
                            } else if (typeof resps.Message === "string") {
                            this._alertService.error("Unable to save.Missing " + resps.Message + " fields.");
                            }
                        } else {
                            this._alertService.error("UnHold Unsuccessfull");
                        }
                    }
                }
            });
    }

    createNewFiledRx(val) {
        this.isFiledNew = val;
    }

    patchRefillListAndQty() {
        let refillsList;
        let partialQty = null;
        if (!this.isFiledRx &&
            ((!(+this.RfOrRx) &&
                this.rxTransferDet.Rxtransfer.Refillno === this.rxTransferDet.Rxtransfer.Aref) ||
                (+this.RfOrRx && this.rxTransferDet.Rxtransfer.FillRemainingQty))) {
            partialQty = +this.RfOrRx ? this.rxTransferDet.Rxtransfer.PartialFillQty : this.rxTransferDet.Rxtransfer.FillRemainingQty;

            refillsList = refillsList + this.rxTransferDet.Rxtransfer.Refillno + "-" +
            (this.rxTransferDet.Rxtransfer.FillNo + 1);
            if (+this.RfOrRx) {
                refillsList = this.rxTransferDet.Rxtransfer.PartialFillsList  +
                (((this.rxTransferDet.Rxtransfer.PartialFillsList && this.rxTransferDet.Rxtransfer.RefillsList)) ?  ", " : "" )+  (this.rxTransferDet.Rxtransfer.RefillsList);
            }
        } else if (this.isFiledRx) {
            if (!(+this.RfOrRx)) {
                if (this.rxTransferDet.Rxtransfer.FillNo > 1) {
                    refillsList = this.rxTransferDet.Rxtransfer.Refillno + "-" +
                    this.rxTransferDet.Rxtransfer.FillNo;
                    partialQty = this.rxTransferDet.Rxtransfer.FillDispQty + this.rxTransferDet.Rxtransfer.FillRemainingQty;
                } else {
                    refillsList = this.rxTransferDet.Rxtransfer.Refillno;
                    partialQty = null;
                }
            } else {
                if (this.rxTransferDet.Rxtransfer.PartialFillQty > 0) {
                    refillsList = this.rxTransferDet.Rxtransfer.PartialFillsList  +
                    (((this.rxTransferDet.Rxtransfer.PartialFillsList && this.rxTransferDet.Rxtransfer.RefillsList)) ?  ", " : "" )+  (this.rxTransferDet.Rxtransfer.RefillsList);
                    partialQty = this.rxTransferDet.Rxtransfer.PartialFillQty;
                } else {
                    refillsList = this.rxTransferDet.Rxtransfer.PartialFillsList  +
                (((this.rxTransferDet.Rxtransfer.PartialFillsList && this.rxTransferDet.Rxtransfer.RefillsList)) ?  ", " : "" )+  (this.rxTransferDet.Rxtransfer.RefillsList);
                    partialQty = null;
                }
            }
        } else if (!this.isFiledRx && !(+this.RfOrRx)) {
            refillsList = refillsList + (this.rxTransferDet.Rxtransfer.Refillno + 1);
        } else if (+this.RfOrRx) {
            refillsList = this.rxTransferDet.Rxtransfer.PartialFillsList  +
            (((this.rxTransferDet.Rxtransfer.PartialFillsList && this.rxTransferDet.Rxtransfer.RefillsList)) ?  ", " : "" )+  (this.rxTransferDet.Rxtransfer.RefillsList);
        }
        return {refillList: refillsList, parQty: partialQty};
    }

   async RxTransferIn(type?: any ) {
       if (this.showRxTransferOut) {
           this.rxTransferFG.controls["RefillorRx"].setValue((this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT") &&
                   this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT") === "1") ? true : false);
           this.rxTransferFG.controls["function"].setValue(false);
           this.rxTransferFG.controls["refillnum"].setValue(this.rxTransferDet.Rxtransfer.Refillno);
           const det = this.patchRefillListAndQty();
           this.rxTransferFG.controls["PartialFillQty"].patchValue(det ? det.parQty : null);
           this.rxTransferFG.controls["RefillsCount"].patchValue(this.rxTransferDet.Rxtransfer.RefillsCount);
           this.rxTransferFG.controls["RefillsTransferredList"].patchValue(det ? det.refillList : null);
        } else {
            this.rxTransferFG.controls["RefillorRx"].setValue(true);
            this.rxTransferFG.controls["function"].setValue(true);
            this.rxTransferFG.controls["refillnum"].setValue(this.rxTransferDet.Rxtransfer.Refillno);
        }
        if (this.rxTransferFG.valid) {
            this.rxTransferFG.patchValue({
                IsFiledRx : this.isFiledRx,
                IsFmPrint : type && type === "print" ? true : false,
                Refills: this.rxTransferDet["Rxtransfer"].Refillsleft,
            });
            let dataInfo = this.rxTransferFG.value;
            dataInfo.PrescId = this.rxTransferDet.Rxtransfer.PrescId;
            dataInfo.PrescRefillId = this.rxTransferDet.Rxtransfer["PrescrefillId"];
            if (!this.showNewRxTrIN) {
                if(environment.IseRxTransferRequest)
                          dataInfo['IsTransOrigin'] = true;
                this.transferInOutResp = await this._rxSer.postRxTransferInOut(dataInfo).toPromise();
                if(type ==="fax") {
                    this.fax();
                }
                if (this.transferInOutResp && this.transferInOutResp["Response"]) {
                    if (this.transferInOutResp["Response"] === 2) {
                        let errorMessage = "Rx transfer for the selected Rx#:" + (this.rxTransferFG?.controls?.["prescnumid"]?.value)  + " has been initiated by a different user.";
                        this._alertService.error(errorMessage);
                    } else {
                        this._alertService.success(type ==="fax" ? "Rx Transfer-Out and fax completed successfully." : (this.showRxTransferOut ? "Rx Transfer-Out completed successfully."
                        : "Rx Transfer-In completed successfully."));
                        if(environment.IseRxTransferRequest)
                                this.saveNotes();
                        if (type && type === "print") {
                            await this.print();
                        }
                    }
                    const resetContrFrIn = [
                        "OrgRxNum",
                        "OrgRxDtTm",
                        "OrgLastRxFillDtTm",
                        "TransferDtTm",
                        "OrgPharmacistNm",
                        "PharmacistId",
                        "Remarks",
                        "XferPharmIdxId"
                    ];
                    const resetContrFrOut = [
                        "OrgRxDtTm",
                        "OrgLastRxFillDtTm",
                        "TransferDtTm",
                        "OrgPharmacistNm",
                        "PharmacistId",
                        "Remarks",
                        "XferPharmIdxId"
                    ];
                    resetContrFrIn.map(val => {
                        if (
                            val === "OrgRxDtTm" ||
                            val === "OrgLastRxFillDtTm"
                        ) {
                            this.rxTransferFG.controls[val].patchValue(
                                this.TodayDate
                            );
                        } else if (val === "PharmacistId") {
                            this.rxTransferFG.controls[val].patchValue(this.logPhaId
                            );
                        } else {
                            this.rxTransferFG.controls[val].reset();
                            this.rxTransferFG.controls[
                                val
                            ].markAsUntouched();
                        }
                        this.pharmcyDet = new PharmacyData();
                    });

                    resetContrFrOut.map(val => {
                        if (
                            val === "OrgRxDtTm" ||
                            val === "OrgLastRxFillDtTm"
                        ) {
                            this.rxTransferFG.controls[val].patchValue(
                                this.TodayDate
                            );
                        } else {
                            this.rxTransferFG.controls[val].reset();
                            this.rxTransferFG.controls[
                                val
                            ].markAsUntouched();
                        }
                        this.pharmcyDet = new PharmacyData();
                    });

                    this.hideRxdetail = false;
                    this.rxTransferFG.controls["prescnumid"].reset();
                    this.rxTransferFG.controls[
                        "prescnumid"
                    ].markAsTouched();
                    this.hideFilterForm = false;
                    this.saveAuditRx();
                    this.routeBackToPrevScreen("Save");
                } else {
                    if (this.showRxTransferOut) {
                        this._alertService.error(
                            "Rx Transfer-Out unsuccessful.",
                            false,
                            "long"
                        );
                    } else {
                        this._alertService.error(
                            "Rx Transfer-In unsuccessful.",
                            false,
                            "long"
                        );
                    }
                }
            } else {
                if(environment.IseRxTransferRequest)
                     this.saveNotes(dataInfo);
                else 
                   this.IsNewRxSaved.emit(dataInfo);
                if (this.showRxTransferOut) {
                    this._alertService.success( type === "fax" ? "Rx Transfer-Out and Fax completed successfully." : "Rx Transfer-Out completed successfully");
                } else {
                    this._alertService.success("Rx Transfer-In completed successfully.");
                }
            }
        } else {
            this.formGroupInvalid = true;
            this.IsNewRxSaved.emit(false);
        }
    }
    saveNotes(dataInfo?) {
        const pharmacistNotes = this.rxTransferFG.value.Note;
        if(!this.showRxTransferOut) { // saved pharmacist notes for mark as transfer in
            if(pharmacistNotes && pharmacistNotes.xNote && (!pharmacistNotes.NoteCatId || pharmacistNotes.Name)) {
                const notesCatList: any = this._commonServ.notesCatList$["source"]["value"];
                const mainCat = notesCatList.find((val: any) => { if (val.CategoryName.toUpperCase() === NotesMainCat.RxAnnonations.toUpperCase()) return val });
                this._drugServ.addoRUpdateNoteCategory(
                    {
                      Name: pharmacistNotes.Name,
                      NoteCategoryId: mainCat?.CategoryId,
                      IsActive: true,
                      CreatedDtTm: null,
                      Id: 0
                    },
                    "SAVE"
                  ).subscribe((resp: number) => {
                    if (resp) {
                      this.getNotesSubacatList({
                        Name: pharmacistNotes.Name,
                        pharmacistNotes:pharmacistNotes
                      });
                    }
                  });
            } else {
                if(this.RxType === 'nr') 
                    this.IsNewRxSaved.emit(dataInfo);
                else
                     this.addNotesLog(pharmacistNotes);
            }
        }
    }
getNotesSubacatList(catObj?: any) {
  this._notesLogSvc
  .getNotesLog(
    NotesMainCat.RxAnnonation,0,
    "1973-01-01",
    moment(Date.now()).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN),
      new SearchNoteParams()
  )
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe((resp: any) => {
      if(resp)
      {
          if(catObj?.Name) {
            let manualSubCat = resp?.NoteCat?.find((item: any) => (catObj.Name.toUpperCase() === item?.Name?.toUpperCase()));
            if(catObj?.pharmacistNotes)
            {
                catObj.pharmacistNotes.NoteCatId  =  manualSubCat ? manualSubCat.Id : 0, 
                 this.addNotesLog(catObj.pharmacistNotes);
            }
          }
      }
  });
}
addNotesLog(pharmacistNotes) {
    if(pharmacistNotes)
    {
        pharmacistNotes["PopupScreenCodes"] = pharmacistNotes["PopupScreenCodes"]?.length ? pharmacistNotes["PopupScreenCodes"]?.toString() : null;
        pharmacistNotes["IsfromTransferIn"]   = true;
        pharmacistNotes["IsShownAsPopup"]  = pharmacistNotes["PopupScreenCodes"]?.length ? true : false;
        if(this.RxType !== 'nr') {
    this._notesLogSvc
        .addNotesLog(NotesMainCat.RxAnnonation, this.rxInfoNew?.PrescReFill?.Id ?  this.rxInfoNew?.PrescReFill?.Id : (this.rxTransferDet.Rxtransfer["PrescId"] ?
            this.rxTransferDet?.Rxtransfer?.PrescId : 0), 
            {Note: [pharmacistNotes] , NoteCat: null, AnnotationNotes : []})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
        if(resp)
            this._alertService.success(MsgConfig.Notes_Saved);
        }); 
        }  
        else {
            this.rxTransferFG.value.Note = pharmacistNotes;
            this.IsNewRxSaved.emit(this.rxTransferFG.value);
        }
    } else {
        this.IsNewRxSaved.emit(this.rxTransferFG.value);
    }
}
    saveAuditRx() {
        let valueStatus: any;
        if ((this.rxTransferDet.Rxtransfer.RxStatus === 1
            || this.rxTransferDet.Rxtransfer.StatusId === 1) && this.rxTransferDet.Rxtransfer.RxStatusId === 1) {
            valueStatus = "U";
        } else if ((this.rxTransferDet.Rxtransfer.RxStatus === 1
            || this.rxTransferDet.Rxtransfer.StatusId === 1) && this.rxTransferDet.Rxtransfer.RxStatusId === 2) {
            valueStatus ="B";
        } else if (this.rxTransferDet.Rxtransfer.RxStatus === 2 || this.rxTransferDet.Rxtransfer.StatusId === 2
                    || this.rxTransferDet.Rxtransfer.RxStatusId === 6) {
            valueStatus = "F";
        }
        if (this.showRxTransferOut) {
            this._nwAudtUtl.SaveAuditOnDelete(
                [{name: "Status" , value: valueStatus}],
                [{name: "Status" , value: "T"}],
                "EditRx",
                "Rx",
                this.rxTransferDet.Rxtransfer["PrescrefillId"]);
        } else if (this.showRxTransferIn) {
            this._nwAudtUtl.SaveAuditOnDelete(
                [{name: "Status" , value: valueStatus}],
                [{name: "Status" , value: valueStatus === "B" ? "BI" : valueStatus === "U" ? "UI" : "FI"}],
                "EditRx",
                "Rx",
                this.rxTransferDet.Rxtransfer["PrescrefillId"]);
        }
    }

    getPharmcyDetails(event?:any) {
        this.hideFilterForm = true;
        if ((event && event.value) || !event) {
            const Id = this.rxTransferFG.controls["XferPharmIdxId"].value;
            if (this.frmPatHis && this.frmPatHis["Type"] === "Out" && this.restoreFG && this.restoreFG.controls) {
                this.restoreFG.controls["otherPhamName"].patchValue(Id);
                localStorage.setItem("Restore", this._commonServ.encryptData(JSON.stringify(this.restoreFG.value)));
            }
            this._rxSer.getPharmacyDetails(Id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.pharmcyDet.TransferPharmacy = resp["TransferPharmacies"];
                    if(this.pharmcyDet.TransferPharmacy)
                        this.pharmcyDet.TransferPharmacy['FullAddress'] = this._patientUtil.getPatientAddress({
                            address1: this.pharmcyDet.TransferPharmacy.AddressLine1, 
                            city: this.pharmcyDet.TransferPharmacy.CityName, 
                            state: this.pharmcyDet.TransferPharmacy.StateName,
                            zipcode: this.pharmcyDet.TransferPharmacy.ZipCode 
                        }) 
                    this.pharmcyDet.Phone = resp["Phone"];
                    if (this.pharmcyDet?.Phone?.length) {
                        if (this.pharmcyDet.Phone[0]) {
                            if (this.pharmcyDet.Phone[0].Phone === " ") {
                                this.Phone1 = null;
                            } else {
                                this.Phone1 = this.pharmcyDet.Phone[0].Phone;
                            }
                        } else {
                            this.Phone1 = null;
                        }
                        if (this.pharmcyDet.Phone[1]) {
                            if (this.pharmcyDet.Phone[1].Phone === " ") {
                                this.Phone2 = null;
                            } else {
                                this.Phone2 = this.pharmcyDet.Phone[1].Phone;
                            }
                        } else {
                            this.Phone2 = null;
                        }
                        if (this.pharmcyDet.Phone[2]) {
                            if (this.pharmcyDet.Phone[2].Phone === " ") {
                                this.Fax = null;
                            } else {
                                this.Fax = this.pharmcyDet.Phone[2].Phone;
                            }
                        } else {
                            this.Fax = null;
                        }
                    } else {
                        this.Phone1 = null;
                        this.Phone2 = null;
                        this.Fax = null;
                    }
                    if (this.pharmcyDet?.TransferPharmacy) {
                        this.citySelected = new City();
                        this.citySelected.Id = this.pharmcyDet.TransferPharmacy.CityId;
                        this.citySelected.Name = this.pharmcyDet.TransferPharmacy.CityName;
                        this.citySelected.StateId = this.pharmcyDet.TransferPharmacy.StateId;
                        this.stateSelected = new State();
                        this.stateSelected.Id = this.pharmcyDet.TransferPharmacy.StateId;
                        this.stateSelected.Name = this.pharmcyDet.TransferPharmacy.StateName;
                    }
                    for (const key in this.pharmcyDet) {
                        if (key === "Phone") {
                            this.generateFA(key, this.pharmcyDet.Phone);
                        }
                    }
                    if (this.showRxTransferOut) {
                        const restoreData = {
                            TransferPharmacy: {
                                AddressLine1: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.AddressLine1 ? this.pharmcyDet.TransferPharmacy.AddressLine1 : "--",
                                StateName: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.StateName ? this.pharmcyDet.TransferPharmacy.StateName : "--",
                                CityName: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.CityName ? this.pharmcyDet.TransferPharmacy.CityName : "--",
                                ZipCode: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.ZipCode ? this.pharmcyDet.TransferPharmacy.ZipCode : "--",
                                Phone1: this.Phone1,
                                Phone2: this.Phone2,
                                Fax: this.Fax,
                                DEANum: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.DEANum ? this.pharmcyDet.TransferPharmacy.DEANum : "--",
                                CityId: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.CityId ? this.pharmcyDet.TransferPharmacy.CityId : null,
                                CountryId: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.CountryId ? this.pharmcyDet.TransferPharmacy.CountryId : null,
                                Extension: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.Extension ? this.pharmcyDet.TransferPharmacy.Extension : null,
                                NPINum: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.NPINum ? this.pharmcyDet.TransferPharmacy.NPINum : null,
                                OrgAddId: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.OrgAddId ? this.pharmcyDet.TransferPharmacy.OrgAddId : null,
                                OrgID: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.OrgID ? this.pharmcyDet.TransferPharmacy.OrgID : null,
                                PharmId: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.PharmId ? this.pharmcyDet.TransferPharmacy.PharmId : null,
                                PharmName: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.PharmName ? this.pharmcyDet.TransferPharmacy.PharmName : null,
                                Remarks: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.Remarks ? this.pharmcyDet.TransferPharmacy.Remarks : null,
                                StateId: this.pharmcyDet.TransferPharmacy && this.pharmcyDet.TransferPharmacy.StateId ? this.pharmcyDet.TransferPharmacy.StateId : null
                            },
                            Phone: this.pharmcyDet.Phone ? this.pharmcyDet.Phone : null,
                        };
                        localStorage.setItem("RestoreAddrData", this._commonServ.encryptData(JSON.stringify(restoreData)));
                    }
                    if (this.DataResult) {
                        this.DataResult = null;
                    }
                }
            });
        } else {
            this.pharmcyDet.TransferPharmacy = null;
            this.pharmcyDet.Phone = null;
            this.Phone1 = null;
            this.Phone2 = null;
            this.Fax = null;
            if (this.addPharFG) {
                this.addPharFG.reset();
            }
            if (this.DataResult) {
                this.DataResult = null;
            }
        }
        
    }

    clearRxdata() {
        if (this.frmPatHis) {
            if (this.modalRef) {
                this.modalRef.close();
                this.modalRef = null;
            }
            this.IsPopUpClosed.emit(null);
        } else {
            this.patientId = null;
            this.hideRxdetail = false;
            this.rxTransferFG.controls["prescnumid"].patchValue(null);
            this.rxTransferFG.reset();
            this.pharmcyDet = new PharmacyData();
            this.rxTransferFG.controls["RefillorRx"].patchValue(false);
            if (this.modalRef) {
                this.modalRef.close();
                this.modalRef = null;
            }
            this.focusOn = 1;
        }
    }

    openAddPopUp() {
        this.addPharFG.controls["TransferPharmacies"].reset();
        this.addPharFG.controls["TransferPharmacies"].markAsUntouched();
        this.addPharFG.controls["phone1"].reset();
        this.addPharFG.controls["phone1"].markAsUntouched();
        this.addPharFG.controls["phone2"].reset();
        this.addPharFG.controls["phone2"].markAsUntouched();
        this.addPharFG.controls["Fax"].reset();
        this.addPharFG.controls["Fax"].markAsUntouched();
        this.citySelected = null;
        this.stateSelected = null;
        this.isedit = false;
        this.addPopUp = true;
        this.openTransferPharmacy();
    }
    closeCommonPopUp(content) {
        this.addPopUp = content;
    }

    setDefaultValues() {
        const msgAry = new Array<any>();
        for (let i = 1; i < 4; i++) {
            const message = new Pharmphone();
            if (i === 1 || i === 2) {
                message.PhoneCatId = 3;
            } else if (i === 3) {
                message.PhoneCatId = 4;
            }
            msgAry.push(message);
        }
        this.generateFA("Phone", msgAry);
    }
    generateFA(key: string, values: any[]) {
        if (values) {
            const extraCostFGs = values.map(val => this._fb.group(val));
            const extraCostFormArray = this._fb.array(extraCostFGs);
            this.addPharFG.setControl(key, extraCostFormArray);
        }
    }
    addNewPharResult(data) {
        this.newPharId = data;
        this.getPharmachyNames();
        this.rxTransferFG.controls["XferPharmIdxId"].patchValue(this.newPharId);
        this.rxTransferFG.controls.XferPharmIdxId.markAsTouched();
        this.getPharmcyDetails();
    }
    editPopUp() {
        this.isedit = true;
        this.citySelected = new City();
        this.pharmcyDet = this.DataResult ? this.DataResult : this.pharmcyDet;
        if (this.pharmcyDet.TransferPharmacy) {
            if (this.pharmcyDet.TransferPharmacy.CityId) {
                this.citySelected.Id = this.pharmcyDet.TransferPharmacy.CityId;
                this.citySelected.Name =  this.pharmcyDet.TransferPharmacy.CityName;
                this.citySelected.StateId = this.pharmcyDet.TransferPharmacy.StateId;
            }
            this.stateSelected = new State();
            if (this.pharmcyDet.TransferPharmacy.StateId) {
                this.stateSelected.Id = this.pharmcyDet.TransferPharmacy.StateId;
                this.stateSelected.Name =  this.pharmcyDet.TransferPharmacy.StateName;
            }
            if (this.pharmcyDet.TransferPharmacy.NPINum) {
                this.pharmcyDet.TransferPharmacy.NPINum = this.pharmcyDet.TransferPharmacy.NPINum;
                //  this.pharmcyDet.TransferPharmacy.NPINum = this.pharmcyDet.TransferPharmacy.NPINum.toString();
            }
        }
        for (const key in this.pharmcyDet) {
            if (key) {
                if (key === "TransferPharmacy" && this.pharmcyDet[key]) {
                    this.addPharFG.controls["TransferPharmacies"].patchValue(
                        this.pharmcyDet[key]
                    );
                }
                if (key === "Phone") {
                    for (const keys in this.pharmcyDet[key] &&
                        this.pharmcyDet[key]) {
                        if (keys) {
                            if (keys === "0") {
                                this.addPharFG.controls["phone1"].patchValue(
                                    this.pharmcyDet[key][keys]["Phone"]
                                );
                            } else if (keys === "1") {
                                this.addPharFG.controls["phone2"].patchValue(
                                    this.pharmcyDet[key][keys]["Phone"]
                                );
                            } else if (keys === "2") {
                                this.addPharFG.controls["Fax"].patchValue(
                                    this.pharmcyDet[key][keys]["Phone"]
                                );
                            }
                        }
                    }
                }
            }
        }
        this.addPopUp = true;
        this.openTransferPharmacy();
    }

    openTransferPharmacy() {
        this.modalRef = this.modalService.open(TransferPharmacyComponent, {
            centered: true
        });
        this.modalRef.componentInstance.PharmacyFG = this.addPharFG;
        this.modalRef.componentInstance.IsEditMode = this.isedit;
        this.modalRef.result
            .then(res => {
                if (res.AddValue) {
                    this.addNewPharResult(res.AddValue);
                }
                if (res.Editvalue) {
                    this.editPharResult(res.Editvalue);
                }
                this.getPharmachyNames();
                this.closeCommonPopUp(res.IsPopUpClosed);
            })
            .catch(error => error);
    }

    editPharResult(data) {
        if (data) {
            this.getPharmachyNames();
            this.getPharmcyDetails();
        }
    }

    routeBackToPrevScreen(type) {
        if (this.frmPatHis) {
            if (type === "Save") {
                this.IsNewRxSaved.emit(null);
            } else {
                this.IsPopUpClosed.emit(null);
            }
        } else if (type !== "Save") {
            this._route.navigate(["eprx/dashboard"]);
        } else {
            if (document.getElementById("RxNum")) {
                document.getElementById("RxNum").focus();
            }
            this.patientId = null;
        }
    }

    openPatientHist() {
        this._patPreDrugService._patPreDrugModal$.next("patHist");
        const modalRef = this._patPreDrugService.modalInstanceRef;
        modalRef.componentInstance.patientID = this.patientId;
        modalRef.componentInstance.popUpOpenFr = this.showRxTransferIn ? "In" : "Out";
        modalRef.componentInstance.IsPopUpClosed
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(closed => {
            modalRef.close();
            this._patPreDrugService._patPreDrugModal$.next(null);
        });
        modalRef.componentInstance.emitPickedRx
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(emitPickedRx => {
            modalRef.close();
            if (emitPickedRx && emitPickedRx.length > 0) {
                if (!emitPickedRx[0]["Patient"].isdeceased) {
                    this.gotoRxTransfer(emitPickedRx);
                } else {
                    if (this.showRxTransferOut) {
                        this.warningMsgDeceased = emitPickedRx[0] ?
                        (emitPickedRx[0].Patient.lastname + ", " + emitPickedRx[0].Patient.firstname).toUpperCase() : "";
                        this.deceasedPop = this.modalService.open(this.deceased , {
                            centered: true,
                            backdrop: false,
                            keyboard: false,
                            windowClass: "large--content"
                        });
                    } else {
                        this.gotoRxTransfer(emitPickedRx);
                    }
                }
            }
            this._patPreDrugService._patPreDrugModal$.next(null);
        });
    }

    gotoRxTransfer(emitPickedRx) {
        this.rxTransferFG.controls["prescnumid"].patchValue((emitPickedRx[0]["Prescription"] &&
            emitPickedRx[0]["Prescription"]["PrescNum"]) ? +emitPickedRx[0]["Prescription"]["PrescNum"] : null);
            this.rxTransferFG.controls["PrescRefillId"].patchValue((emitPickedRx[0]["PrescReFill"] &&
            emitPickedRx[0]["PrescReFill"]["Id"]) ? +emitPickedRx[0]["PrescReFill"]["Id"] : null);
            this.rxTransferFG.controls["refillnum"].patchValue((emitPickedRx[0]["PrescReFill"] &&
           (emitPickedRx[0]["PrescReFill"]["ReFillNum"]) >= 0)  ? +emitPickedRx[0]["PrescReFill"]["ReFillNum"] : null);
        this.getRxDetails();
    }

    closeModalForDeceased() {
        if (this.deceasedPop) {
            this.deceasedPop.close();
            this.deceasedPop = null;
        }
        if (document.getElementById("RxNum")) {
            document.getElementById("RxNum").focus();
        }
    }

    get TransferPharmacies(): FormGroup {
        return this.addPharFG.get("TransferPharmacies") as FormGroup;
    }

    get Phone(): FormControl {
        return this.addPharFG.get("Phone") as FormControl;
    }

    get CityId(): FormControl {
        return this.TransferPharmacies.get("CityId") as FormControl;
    }

    get StateId(): FormControl {
        return this.TransferPharmacies.get("StateId") as FormControl;
    }
    get ZipCode(): FormControl {
        return this.TransferPharmacies.get("ZipCode") as FormControl;
    }
    get OrgRxNum(): FormControl {
        return this.rxTransferFG.get("OrgRxNum") as FormControl;
    }

    get PharmName(): FormControl {
        return this.TransferPharmacies.get("PharmName") as FormControl;
    }
    get AddressLine1(): FormControl {
        return this.TransferPharmacies.get("AddressLine1") as FormControl;
    }

    get PharmacistId(): FormControl {
        return this.rxTransferFG.get("PharmacistId") as FormControl;
    }

    get XferPharmIdxId(): FormControl {
        return this.rxTransferFG.get("XferPharmIdxId") as FormControl;
    }

    get TransferDtTm(): FormControl {
        return this.rxTransferFG.get("TransferDtTm") as FormControl;
    }

    get OrgRxDtTm(): FormControl {
        return this.rxTransferFG.get("OrgRxDtTm") as FormControl;
    }

    get OrgLastRxFillDtTm(): FormControl {
        return this.rxTransferFG.get("OrgLastRxFillDtTm") as FormControl;
    }

    get OrgPharmacistNm(): FormControl {
        return this.rxTransferFG.get("OrgPharmacistNm") as FormControl;
    }

    focusOutFmPharm(event) {
        if (
            this.rxTransferFG.valid &&
            (this.showRxTransferOut || this.showRxTransferIn)
        ) {
            if (document.getElementById("Transfer")) {
                event.preventDefault();
                setTimeout(() => {
                    document.getElementById("Transfer").focus();
                }, 10);
            }
        } else {
            if (document.getElementById("cancel")) {
                event.preventDefault();
                setTimeout(() => {
                    document.getElementById("cancel").focus();
                }, 10);
            }
        }
    }

    focusRotation(event) {
        if (!this.hideRxdetail) {
            this.focusOutFmPharm(event);
        }
    }

    patchOtherPharmcistNm(val) {
        if (val && this.restoreFG && this.restoreFG.controls) {
            this.restoreFG.controls["origPharmcistName"].patchValue(val);
            localStorage.setItem("Restore", this._commonServ.encryptData(JSON.stringify(this.restoreFG.value)));
        }
    }

    transferSingleNotes(note: any) {
        if(note["PopupScreenCodes"]?.length) {
            note["PopupScreenCodes"] = note["PopupScreenCodes"]?.toString();
            note["IsShownAsPopup"] = true;
        } else {
            note["PopupScreenCodes"] = null;
            note["IsShownAsPopup"] = false;
        }
        note["IsfromTransferIn"]   = true;
        this.rxTransferFG.controls['Note'].patchValue(note);
    }
}
