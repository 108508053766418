import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { QuestionTypeAssessEnum } from 'src/app/models/messaging.enum';
import { AlertService, CommonService, UserService } from 'src/app/services';
import * as _ from "lodash";
import { MsgConfig } from 'src/app/app.messages';
@Component({
  selector: 'app-clinical-assessment',
  templateUrl: './clinical-assessment.component.html',
  styleUrls: ['./clinical-assessment.component.scss']
})
export class ClinicalAssessmentComponent implements OnInit {
  formDetails: any;
  sectionsList: any;
  secNameList: any;
  @ViewChild("AssessmentForm", {static: true})
  AssessmentForm: any;
  modelRef: any;
  qsnTypeEnums: any;
  @Input() rxNo: any;
  @Input() patientName: any;
  @Input() drugName: any;
  @Input() formEntityDetails: any;
  @Input() precscRefId: any;
  patientInfo: any;
  assessmFormFG: FormGroup;
  age: string;
  toggleFG: FormGroup;
  questionsList: any;
  toggleQsns: any[]=[];
  responseList: any[]=[];
  responseListFG: FormGroup;
  opnId: any[]=[];
  opnChildId: any[]=[];
  prevQsnId: any;
  QsnIputVal: any;
  markComplete: boolean = false;
  viewMode: boolean;
  childQsnFG: FormGroup;
  childQsns: any[]=[];
  directQsnsFG: FormGroup;
  dirctQsns: any[]=[];
  dateTimeFG: FormGroup;
  dateTimeQsns: any[]=[];
  textQsnFG: FormGroup;
  textQsns: any[] = [];
  maxDate: any;
  today: any;
  MsgConfig: any;
  @Input()
  set patInfo(info) {
    this.patientInfo = info;
    if (info && info.dob) {
      const dob = moment(info.dob);
      const years = moment().diff(dob, "year");
      dob.add(years, "years");
      const months = moment().diff(dob, "months");
      dob.add(months, "months");
      this.age = years + " Yrs, " + months + " M";
  } else {
      this.age = null;
  }
  }
  @Output()IsPopupClosed = new EventEmitter();

  constructor(private _cmnSvc: CommonService, private _mdlService: NgbModal, private _fb: FormBuilder, private _userServ: UserService, private _alrtSvc: AlertService) {
    this.assessmFormFG = this._fb.group({
      StartDate: null,
      textDisplay: null
    });
    this.toggleFG = this._fb.group({
    });
    this.directQsnsFG = this._fb.group({});
    this.childQsnFG = this._fb.group({});
    this.dateTimeFG = this._fb.group({});
    this.textQsnFG = this._fb.group({});
    this.responseListFG = this._fb.group({
      responseId: null,
      questionId: "",
      optionId: [],
      answer: ""
    });
   }

  ngOnInit(): void {
    this.today = moment().format("MM/DD/YYYY");
    this.maxDate = {
      year: moment(this.today).year(),
      month: moment(this.today).month() + 1,
      day: moment(this.today).date()
    };
    this.qsnTypeEnums = QuestionTypeAssessEnum;
    this.MsgConfig = MsgConfig;
    this.getFormDetails();
  }

  async getFormDetails() {
    if (this.formEntityDetails?.fillResponseId?.length) {
      const dataForm = {
        prescrefillId: this.precscRefId,
        patientId: this.patientInfo.patientid
      }
      this.viewMode = true;
      this.formDetails = await this._cmnSvc.viewSavedFormResponse(dataForm).toPromise();
      this.responseList = this.formDetails?.responses;
    } else {
      this.viewMode = false;
      this.formDetails = await this._cmnSvc.getFormDetails(this.formEntityDetails?.formId).toPromise();
    }
    this.sectionsList = this.formDetails ? (this.viewMode && this.formDetails?.form ? this.formDetails?.form?.sections : this.formDetails?.sections) : [];
    this.questionsList = this.sectionsList?.length ? this.sectionsList.map((item: any) => item.questions) : [];
    if (this.questionsList?.length) {
    this.questionsList.map((item: any) => {
      item.filter((subItem: any) => {
        if (subItem.questionTypeId === 4) {
          this.toggleQsns.push(subItem);
        }
        if (subItem?.childQuestions?.length) {
          this.childQsns.push(subItem.childQuestions[0])
        }
        if (subItem.questionTypeId === QuestionTypeAssessEnum.DropDown) {
          this.dirctQsns.push(subItem);
        }
        if (subItem.questionTypeId === QuestionTypeAssessEnum.Date) {
          this.dateTimeQsns.push(subItem);
        }
        if (subItem.questionTypeId === QuestionTypeAssessEnum.Text) {
          this.textQsns.push(subItem);
        }
      })
    });
    }
    if (this.toggleQsns?.length) {
      this.toggleQsns.map((tgl: any, i) => {
        const valueCntrl = false;
        this.toggleFG.addControl("Toggle"+tgl.questionId, new FormControl(valueCntrl));
      })
    }
    if (this.childQsns?.length) {
      this.childQsns.map((cldqsn: any) => {
        const valueCntrl = "";
        this.childQsnFG.addControl("childQsn"+cldqsn.questionId, new FormControl(valueCntrl));
        if (this.viewMode && cldqsn.response && cldqsn.response.optionId?.length) {
            this.childQsnFG.controls["childQsn"+cldqsn.questionId].patchValue(cldqsn.isMultiSelect ? cldqsn.response.optionId : cldqsn.response.optionId[0])
        }
        if (this.viewMode && cldqsn.response && cldqsn.response.answer) {
          this.childQsnFG.controls["childQsn"+cldqsn.questionId].patchValue(cldqsn.response.answer)
        }
      })
    }
    if (this.dirctQsns?.length) {
      this.dirctQsns.map((drctQsn: any) => {
        const valueCntrl = "";
        this.directQsnsFG.addControl("drctQsn"+drctQsn.questionId, new FormControl(valueCntrl));
        if (this.viewMode && drctQsn.response && drctQsn.response.optionId?.length) {
            this.directQsnsFG.controls["drctQsn"+drctQsn.questionId].patchValue(drctQsn.isMultiSelect ? drctQsn.response.optionId : drctQsn.response.optionId[0])
        }
        if (this.viewMode && drctQsn.response && drctQsn.response.answer) {
          this.directQsnsFG.controls["drctQsn"+drctQsn.questionId].patchValue(drctQsn.response.answer)
        }
      })
    }
    if (this.dateTimeQsns?.length) {
      this.dateTimeQsns.map((dateQsn: any) => {
        const valueCntrl = "";
        this.dateTimeFG.addControl("dateQsn"+dateQsn.questionId, new FormControl(valueCntrl));
        if (this.viewMode && dateQsn.response && dateQsn.response.answer) {
            this.dateTimeFG.controls["dateQsn"+dateQsn.questionId].patchValue(dateQsn.response.answer)
        }
      })
    }
    if (this.textQsns?.length) {
      this.textQsns.map((txtQsn: any) => {
        const valueCntrl = "";
        this.textQsnFG.addControl("Text"+txtQsn.questionId, new FormControl(valueCntrl));
        if (this.viewMode && txtQsn.response && txtQsn.response.answer) {
            this.textQsnFG.controls["Text"+txtQsn.questionId].patchValue(txtQsn.response.answer)
        }
      })
    }
    
    this.markComplete = this.viewMode ? this.formDetails?.isCompleted : false;
    this.modelRef = this._mdlService.open(this.AssessmentForm, {centered: true, backdrop:false, keyboard: false, size:"lg", windowClass:"screen__fit--Center"})
  }

  displayDrop(event, selcQsnId, selcQsnItem) {
    if (this.responseList.length) {
      const existRespIndex = this.responseList.findIndex((item: any) => item.questionId === selcQsnId);
      if (existRespIndex > -1)
        this.responseList.splice(existRespIndex, 1)
      selcQsnItem.response = null;
    }
      const data = {
        responseId: null,
        questionId: selcQsnId,
        optionId: null,
        answer: "",
        toggleValue: event
      };
      selcQsnItem.response = data;
      this.responseList.push(data);
      if (!event) {
        const existChldRespIndex = selcQsnItem?.childQuestions && selcQsnItem?.childQuestions?.length ? this.responseList.findIndex((item: any) => item.questionId === selcQsnItem.childQuestions[0].questionId) : -1;
        if (existChldRespIndex > -1) {
          this.responseList.splice(existChldRespIndex, 1)
          if (selcQsnItem?.childQuestions && selcQsnItem?.childQuestions?.length && selcQsnItem.childQuestions[0].response && selcQsnItem.childQuestions[0].response.optionId?.length) {
            this.childQsnFG.controls["childQsn"+selcQsnItem.childQuestions[0].questionId].patchValue(selcQsnItem.childQuestions[0].isMultiSelect ? [] : "")
          }
          if (selcQsnItem?.childQuestions && selcQsnItem?.childQuestions?.length && selcQsnItem.childQuestions[0].response && selcQsnItem.childQuestions[0].response.answer) {
            this.childQsnFG.controls["childQsn"+selcQsnItem.childQuestions[0].questionId].patchValue("");
          }
          selcQsnItem.childQuestions[0].response = null;
        }
      }
    }

  getChildVal(eveValue, QsnId, chldQsn) {
    this.opnChildId = [];
    let objid: any;
    let newOptnAdded;
    let replaceOptn: any[]=[];
    let donotAdd = false;
    if (this.responseList.length && ((!chldQsn.isMultiSelect && !eveValue) || (chldQsn.isMultiSelect && !eveValue.length))) {
      donotAdd = true;
      const existRespIndex = this.responseList.findIndex((item: any) => item.questionId === chldQsn.questionId);
      if (existRespIndex > -1)
        this.responseList.splice(existRespIndex, 1)
      chldQsn.response = null;
    } else if (chldQsn.isMultiSelect && this.responseList.length && eveValue?.length && eveValue[eveValue.length - 1].optionId) {
      const existRespOptn = this.responseList.find((item: any) => item.questionId === chldQsn.questionId);
      if (existRespOptn) {
        donotAdd = true;
        eveValue.map((optnItem: any) => {
          replaceOptn.push(optnItem.optionId) 
        })
        existRespOptn.optionId = replaceOptn;
        console.log(this.responseList, "AfterremoveVal");
        chldQsn.response.optionId = replaceOptn;
      }
    }
    if (!donotAdd) {
      if ((chldQsn.isMultiSelect && eveValue.length && !eveValue[eveValue.length - 1].optionId) || (!chldQsn.isMultiSelect && !eveValue.optionId)) {
        newOptnAdded = true;
        const ObjectID = require("bson-objectid");
        objid = ObjectID().toHexString();
      } else {
        newOptnAdded = false;
        objid = chldQsn.isMultiSelect && eveValue.length ? eveValue[eveValue.length - 1].optionId : eveValue.optionId
      }
      if (chldQsn.response?.optionId?.length && chldQsn.isMultiSelect) {
        chldQsn.response?.optionId.map((itemchildOpn: any) => this.opnChildId.push(itemchildOpn))
      }
      this.opnChildId.push(objid)
      const data1 = {
        responseId: null,
        questionId: QsnId,
        optionId: this.opnChildId,
        answer: "",
        toggleValue: false
      };
      chldQsn.response = data1;
      if (this.responseList?.length) {
        const existRespIndex = this.responseList.findIndex((item: any) => item.questionId === chldQsn.questionId);
        if (existRespIndex > -1) {
          this.responseList.splice(existRespIndex, 1)
        }
      }
      this.responseList.push(data1);
      if (newOptnAdded){
      const optnData = {
        childQuestions: null,
        createdById: this._userServ.getToken("UserId"),
        createdDateTime: moment.utc().format(),
        description: chldQsn.isMultiSelect ? eveValue[eveValue.length - 1].description : eveValue.description,
        displayOrder: null,
        isDefault: false,
        modifiedById: this._userServ.getToken("UserId"),
        modifiedDateTime: moment.utc().format(),
        optionId: objid
      };
      chldQsn.options = !chldQsn.options ? [] : chldQsn.options;
      chldQsn.options.push(optnData);
      }
    }
    
  }

  getSelcVal(eventVal, selcQsn) {
    this.opnId = [];
    let newOptnAdded;
    let objid: any;
    let replaceOptn: any[]=[];
    let donotAdd = false;
    if (this.responseList.length && ((!selcQsn.isMultiSelect && !eventVal) || (selcQsn.isMultiSelect && !eventVal.length))) {
      donotAdd = true;
      const existRespIndex = this.responseList.findIndex((item: any) => item.questionId === selcQsn.questionId);
      if (existRespIndex > -1)
        this.responseList.splice(existRespIndex, 1)
      selcQsn.response = null;
    } else if (selcQsn.isMultiSelect && this.responseList.length && eventVal?.length && eventVal[eventVal.length - 1].optionId) {
      const existRespOptn = this.responseList.find((item: any) => item.questionId === selcQsn.questionId);
      if (existRespOptn) {
        donotAdd = true;
        eventVal.map((optnItem: any) => {
          replaceOptn.push(optnItem.optionId) 
        })
        existRespOptn.optionId = replaceOptn;
        console.log(this.responseList, "AfterremoveVal");
        selcQsn.response.optionId = replaceOptn;
      }
    }
    if (!donotAdd) {
      if ((selcQsn.isMultiSelect && eventVal.length && !eventVal[eventVal.length - 1].optionId) || (!selcQsn.isMultiSelect && !eventVal.optionId)) {
        newOptnAdded = true;
        const ObjectID = require("bson-objectid");
        objid = ObjectID().toHexString();
      } else {
        newOptnAdded = false;
        objid = selcQsn.isMultiSelect && eventVal.length ? eventVal[eventVal.length - 1].optionId : eventVal.optionId
      }
      if (selcQsn.response?.optionId?.length && selcQsn.isMultiSelect) {
        selcQsn.response?.optionId.map((itemOpn: any) => this.opnId.push(itemOpn))
      }
      this.opnId.push(objid);
      const dataVal1 = {
        responseId: null,
        questionId: selcQsn.questionId,
        optionId: this.opnId,
        answer: "",
        toggleValue: false
      };
      selcQsn.response = dataVal1;
      if (this.responseList?.length) {
        const existRespIndex = this.responseList.findIndex((item: any) => item.questionId === selcQsn.questionId);
        if (existRespIndex > -1) {
          this.responseList.splice(existRespIndex, 1)
        }
      }
      this.responseList.push(dataVal1);
      if (newOptnAdded){
        const optnData = {
          childQuestions: null,
          createdById: this._userServ.getToken("UserId"),
          createdDateTime: moment.utc().format(),
          description: selcQsn.isMultiSelect ? eventVal[eventVal.length - 1].description : eventVal.description,
          displayOrder: null,
          isDefault: false,
          modifiedById: this._userServ.getToken("UserId"),
          modifiedDateTime: moment.utc().format(),
          optionId: objid
        };
        selcQsn.options = !selcQsn.options ? [] : selcQsn.options;
        selcQsn.options.push(optnData);
      }
    }
    
    
  }

  patchDateFields(event, selcQsn) {
    const dataValDateTime = {
      responseId: null,
      questionId: selcQsn.questionId,
      optionId: null,
      answer: event,
      toggleValue: false
    };
    selcQsn.response = dataValDateTime;
    if (this.responseList?.length) {
      const existRespIndex = this.responseList.findIndex((item: any) => item.questionId === selcQsn.questionId);
      if (existRespIndex > -1) {
        this.responseList.splice(existRespIndex, 1)
      }
    }
    this.responseList.push(dataValDateTime);
  }

  patchTexts(event, selcQsn) {
    const dataValText = {
      responseId: null,
      questionId: selcQsn.questionId,
      optionId: null,
      answer: event && event.target ? event.target.value: "",
      toggleValue: false
    };
    selcQsn.response = dataValText;
    if (this.responseList?.length) {
      const existRespIndex = this.responseList.findIndex((item: any) => item.questionId === selcQsn.questionId);
      if (existRespIndex > -1) {
        this.responseList.splice(existRespIndex, 1)
      }
    }
    this.responseList.push(dataValText);
  }

  ChangeStatus(event) {
    this.markComplete = event;
  }
  async saveForm() {
    if (this.directQsnsFG.valid && this.dateTimeFG.valid && this.childQsnFG.valid && this.textQsnFG.valid) {
      const saveData = {
        fillResponseId: this.viewMode && this.formEntityDetails?.fillResponseId?.length ? this.formEntityDetails.fillResponseId[0]: null,
        tenantId: this._userServ.getToken("TenantId"),
        formId: this.formEntityDetails?.formId,
        prescrefillId: this.precscRefId,
        patientId: this.patientInfo.patientid,
        responses: this.responseList,
        form: null,
        isDeleted: false,
        createdById: this._userServ.getToken("UserId"),
        createdDateTime: moment.utc().format(),
        modifiedById: this._userServ.getToken("UserId"),
        modifiedDateTime: moment.utc().format(),
        isCompleted: this.markComplete,
        dueDate: null,
        fillDate: null,
        updatedOptions: this.sectionsList
      }
      if (this.viewMode) {
        this._cmnSvc.updateFormDetails(saveData).subscribe((savedResp: any) => {
          if (savedResp) {
            this.closeModal();
          }
        })
      } else {
        this._cmnSvc.saveFormDetails(saveData).subscribe((savedResp: any) => {
          if (savedResp) {
            this.closeModal();
          }
        })
      }
    } else {
      if (!this.dateTimeFG.valid) {
        this._alrtSvc.error(MsgConfig.INVALID_DATES_ERROR);
      } else {
        this._alrtSvc.error(MsgConfig.MANDATORY_FIELDS);
      }
        
    }
  }

  closeModal() {
    if (this.modelRef) {
      this.modelRef.close();
      this.modelRef = null;
    }
    this.opnId = [];
    this.responseList = [];
    this.IsPopupClosed.emit();
  }

}
