<div class="modal-header" *ngIf="!FrmEditPatient">
    <span class="modal-title">External History - {{ rxPatientModel?.lastname | uppercase}}<span *ngIf="rxPatientModel?.lastname">,&nbsp;</span>{{ rxPatientModel?.firstname | uppercase}}</span>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePatHistModel()">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body height_75_14vh pat_extenal_hist_modal">
    <div class="eprx--block__content erx--body insurance--body">
        <div class="col-sm-12 padding-0">
            <div class="row">
                <div class="col-12 editpatient exprx--common-block eprx--block__content" *ngIf="rxPatientModel">
                    <app-rx-patient-card [RxPatientInfo]="rxPatientModel" [HasPatientHistoryVisb]="true">
                    </app-rx-patient-card>
                </div>
            </div>
            <div class="row margin_top_15px">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <wj-flex-grid #externalPatient id="inline--suggest--display--pat" [headersVisibility]="'Column'" [isReadOnly]="true" [ngClass]="!isDataExists ? 'wjm-grd-custom-height-44 no-data':'wjm-grd-custom-height-44'"
                    [itemsSource]="externalPatientWJ" [selectionMode]="'None'" (formatItem)="onFormatItem($event)"
                    (drop)="this._storageService.storeWijmoGridDataInSession(externalPatient)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(externalPatient)">
                    <wj-flex-grid-column [header]="'Actions'" [width]="'0.9*'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <i *ngIf="item?.newRecord" class="far fa-edit actions in-active" title="Please add the record first."></i>
                            <i *ngIf="!item?.newRecord" class="far fa-edit actions" title="Edit" (click)="editExternHist(item)"></i>
                            <i *ngIf="item?.newRecord" class="far fa-trash-alt actions in-active" title="Please add the record first."></i>
                            <i *ngIf="!item?.newRecord" class="far fa-trash-alt actions danger-actve" title="Delete" (click)="deleteExternHist(item)"></i>
                            <i *ngIf="item?.newRecord && IsTransferRequestAllowd" class="fa-kit fa-light-prescription-bottle-circle-arrow-left actions in-active" title="Please add the record first."></i>
                            <i *ngIf="!item?.newRecord && IsTransferRequestAllowd" class="fa-kit fa-light-prescription-bottle-circle-arrow-left actions" (click)="TransferIn(item)" title="Transfer In"></i>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']"  [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                        *ngFor="let rxheader of wjHeaders; let i = index"
                        [width]="rxheader['width']">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div *ngIf="(item['newRecord'] || item['editRecord'])">
                                <div *ngIf="rxheader['hName'] === 'Rx#'" class="col-12 mb-0 pl-0 mt-2 inline-Dropdown--height">
                                    <eprx-input [RxSelectId]="'rxID'" [AutoFocus]="item?.editRecord ? true : false"
                                    [LabelText]="''" [PlaceHolder]="''" [InputValue]="item[rxheader.hName]" 
                                    [InputType]="'NUMBER'" (EmitOnChangeValue)="getEnteredData($event, item, 'rxID')" [HasControl]="false" [cssStyle]="true" (TriggerOnEnterValue)="getEnteredData($event, item, 'rxID')">
                                    </eprx-input>
                                </div>
                                <div *ngIf="rxheader['hName'] === 'Drug Name'" class="col-12 mb-0 pl-0 mt-2">
                                    <div class="seconday-search--grid">
                                        <app-searchbox-global id="Drug" [RxSelectId]="'drugNameID'" [SearchFrm]="'patExtern'"
                                            [SearchFor]="'Drug'" [IsAddNew]="false" [IsHide]="true" [InputValue]="item[rxheader.hName]"
                                            [DisableSerchFil]="true" [IsHideDrugFilters]="true" [DisableRadioButtons]="true"
                                            (SelectedDrug)="selectDrug($event, item, 'drugNameID')" [IsFocused]="!item?.editRecord ? true : false"  [ClearInputValue]="item[rxheader.hName]">
                                        </app-searchbox-global>
                                    </div>
                                </div>
                                <div *ngIf="rxheader['hName'] === 'Refills Left'" class="col-12 mb-0 mt-2 pl-0 inline-Dropdown--height">
                                    <eprx-input [RxSelectId]="'refillsLeftID'"
                                    [LabelText]="''" [PlaceHolder]="''" [InputValue]="item[rxheader.hName]" 
                                    [InputType]="'NUMBER'" (EmitOnChangeValue)="getEnteredData($event, item, 'refillsLeftID')" [HasControl]="false" [cssStyle]="true" (TriggerOnEnterValue)="getEnteredData($event, item, 'refillsLeftID')">
                                    </eprx-input>
                                </div>
                                <div *ngIf="rxheader['hName'] === 'Ordered Date'" class="col-12 mb-0 mt-2 pl-0 inline-Dropdown--height">
                                    <eprx-date-picker [PlaceHolder]="''" [IsAppendTo]="true"  [MinDate]="" [MaxDate]="maxDate"
                                    [HasControl]="false" [InputValue]="item[rxheader.hName]" (EmitChangeValue)="getEnteredData($event, item, 'OrderedDateID')"
                                      [RxSelectId]="'OrderedDateID'" (TriggerOnDateSelect)= "getEnteredData($event, item, 'OrderedDateID')" [AutoFocus]="focusExpDt" (EmitBlurValue)="getEnteredData($event, item, 'OrderedDateID')">
                                    </eprx-date-picker>
                                </div>
                                <div *ngIf="rxheader['hName'] === 'Fill Date'" class="col-12 mb-0 mt-2 pl-0 inline-Dropdown--height">
                                    <eprx-date-picker [PlaceHolder]="''" [IsAppendTo]="true" [MinDate]="" [MaxDate]="maxDate" [AutoFocus]="isFillDateMin ? true : false"
                                    [HasControl]="false" [InputValue]="item[rxheader.hName]" (TriggerOnEnterValue)="getEnteredData($event, item, 'FillDateID')"
                                      [RxSelectId]="'FillDateID'" (TriggerOnDateSelect)="getEnteredData($event, item, 'FillDateID')" [AutoFocus]="focusExpDt">
                                    </eprx-date-picker>
                                </div>
                                <div *ngIf="rxheader['hName'] === 'Expiration Date'" class="col-12 mb-0 mt-2 pl-0 inline-Dropdown--height">
                                    <eprx-date-picker [IsAppendTo]="true" (EmitChangeValue)="getEnteredData($event, item, 'ExpirationDateID')"
                                    [HasControl]="false" [MinDate]="minDate" [InputValue]="item[rxheader.hName]"
                                      [RxSelectId]="'ExpirationDateID'" (TriggerOnDateSelect)="getEnteredData($event, item, 'ExpirationDateID')" [AutoFocus]="focusExpDt" (EmitBlurValue)="getEnteredData($event, item, 'ExpirationDateID')">
                                    </eprx-date-picker>
                                </div>
                                <div *ngIf="rxheader['hName'] === 'Source Pharmacy' && IsTransferRequestAllowd" class="col-12 mb-0 mt-2 pl-0" container="body">
                                    <app-pharmacy-suggest [isFrmPatExternHist]="true"
                                    (selectedPharm)="selectedPharmDetails($event)"></app-pharmacy-suggest>
                                </div>
                                <div *ngIf="rxheader['hName'] === 'Notes'" class="col-12 mb-0 mt-2 pl-0 inline-Dropdown--height">
                                    <eprx-input [RxSelectId]="'notesID'"
                                    [LabelText]="''" [PlaceHolder]="''" [InputValue]="item[rxheader.hName]" (EmitOnChangeValue)="getEnteredData($event, item, 'notesID')" [HasControl]="false" [cssStyle]="true" (TriggerOnEnterValue)="getEnteredData($event, item, 'notesID')">
                                    </eprx-input>
                                </div>
                                <div class="col-12 mb-0 pl-0 margin_left_0_5rem" *ngIf="(rxheader['hName'] === 'Rph/Tech Initials')" >
                                    {{ item[rxheader['hName']] }}
                                </div>
                                <div class="text-center" *ngIf="(rxheader['hName'] === 'Active Rx?')" >
                                    <span *ngIf="item[rxheader['hName']] === true">
                                        <i class="far fa-check " title="Active"></i>
                                    </span>
                                    <span *ngIf="item[rxheader['hName']] !== true">
                                        <i class="far fa-times  fa-uncheck" title="Expired"></i>
                                    </span>
                                </div>
                            </div>
                            <div *ngIf="!item['newRecord'] && !item?.editRecord">
                                <div class="col-12 mb-0 pl-0 margin_left_0_5rem" *ngIf="(rxheader['hName'] === 'Source Pharmacy' && IsTransferRequestAllowd)">
                                    {{ item[rxheader['hName']] }}
                                </div>
                                <div class="col-12 mb-0 pl-0 margin_left_0_5rem" *ngIf="!(rxheader['hName'] === 'Active Rx?' || rxheader['hName'] === 'Source Pharmacy')">
                                    {{item[rxheader['hName']]}}
                                </div>
                                <div class="text-center" *ngIf="(rxheader['hName'] === 'Active Rx?')">
                                    <span *ngIf="item[rxheader['hName']] === true">
                                        <i class="far fa-check " title="Active"></i>
                                    </span>
                                    <span *ngIf="item[rxheader['hName']] !== true">
                                        <i class="far fa-times  fa-uncheck" title="Expired"></i>
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                </wj-flex-grid>
                </div>
            </div>
            <div class="row margin_top_15px">
                <div class="col-12">
                    <div class="row">
                        <div class="col-10">
                            <div class="col-2" 
                                [ngClass]="isItemAlreadyAdded ? 'add_new_prescription_disable color_light_gray' : 'add_new_prescription'" 
                                [title]="isItemAlreadyAdded ? 'Please fill the current prescription details before adding a new one.' : 'Add New Prescription'"
                                (click)="!isItemAlreadyAdded ? addNewPrescription() : ''">
                                <i class="fa-kit fa-circle-plus-sharp-solid"></i> Add New Prescription
                            </div>
                        </div>
                        <div class="col-2 text-right float-right" *ngIf="!FrmEditPatient">
                            <button class="hotkey_success" (click)="saveExternHist()" appShortcutKey 
                                [AltKey]="iseditRecord ? 'u' : 's'"><span>{{ iseditRecord ? 'U' : 'S' }}</span> {{ iseditRecord ? 'Update' : 'Save' }}
                            </button>
                            <button class="hotkey_success" (keydown.enter)="closePatHistModel()" (click)="closePatHistModel()"
                                appShortcutKey [AltKey]="'c'">
                                <span>C</span> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-transfer-in *ngIf="openTrnsReqPopup" (ClosePopUpModal)="closeTRxREQPopUpModal($event)" [DrugInformationFrmExtHist]="drugInformation" [PatientDetails]="rxPatientModel" [isFrmPatExternHist]="true"> </app-transfer-in>
</div>