<ng-template #SingleNotesPopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="continueSavingRx()">
        <h4 header>
            {{HeaderText}}
        </h4>
        <span body>
            <div class="eprx-font-16">
                {{BodyText}}
            </div>
            <div class="eprx-row eprx-form-group mt-4"> <!---eprx-form-group -> to avoid  curret margin bottom for form-group class-->
                <div class="eprx-col-12  pl-0" >
                    <div class="eprx-card">
                       <div class="eprx-row">
                        <div class="eprx-card-header">
                            {{commonlabels?.Notes}}
                        </div>
                       </div>
                       <div class="eprx-row">
                        <div  class="eprx-card-label--data eprx-col-12  px-0">
                           <label>Category:</label> {{ MainCategory ? (MainCategory === 'RxAnnotation' ? "Rx Annotation" : MainCategory) : 'Rx Annotation'}}
                        </div>
                       </div>
                       <div class="eprx-row">
                       
                       <div  class="eprx-card-label--data eprx-col-6  px-0">
                        <eprx-select [RxSelectId]="'notesLogCategry'" [AddItem]="true" [SelectOnTab]="true"
                            [LabelText]="'Subcategory'" [PlaceHolder]="'Subcategory'" [IsTitle]="true" 
                            [IsRequired]="subCatInvalid" [FormGroupInvalid]="formGroupInvalid"
                            [ControlName]="'NoteCatId'" [FormGroupName]="notesLogFG" [List]="notesLogInfo?.NoteCat" 
                            [BindLabel]="'Name'"  [secondCntrlN]="'Name'" [bindScnCntrlV]="'Name'"  [BindValue]="'Id'" [addItemMaxLength]="15" [AutoFocus]="true"
                            [LabelForId]="'Name'" (TriggerSelectValue)="checkMandatory()"   [InformationTool]="'Add a subcategory manually if it is not available in the list.'">
                        </eprx-select>
                       </div>
                       <div  class="eprx-card-label--data eprx-col-6 pr-0">
                        <!-- <eprx-input [RxSelectId]="'MainCategory'"  [LabelText]="'Category'" [HasControl]="false" [InputValue]="MainCategory" [IsDisabled]="true" >
                            </eprx-input> -->
                                <eprx-select [RxSelectId]="'popupscreen'"
                            [LabelText]="'Popup Screen'" [IsTitle]="true"
                            [PlaceHolder]="''"
                            [ControlName]="'PopupScreenCodes'" 
                            [HasMultiple]="true" [FormGroupName]="notesLogFG"
                            [List]="[{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, 
                                {Name:'RPh Vrf', Id:3, type:'rph'}, {Name: 'DPV', Id: 5, type:'dpv'}]" [BindLabel]="'Name'"
                            [BindLabel]="'Name'" [BindValue]="'type'" [LabelForId]="'Name'"
                            (TriggerSelectValue)="filterAllinList(notefg , PopupScreenCodes)">
                        </eprx-select>
                    </div>
                        </div>      
                        <div class="eprx-row">  
                            <div class="eprx-card-label--data eprx-col-12 px-0" [formGroup]="notesLogFG">
                                <angular-editor formControlName="xNote" [config]="editorConfig"></angular-editor>
                              </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </span>
        <button footer type="submit" class="hotkey_success"  ngbAutofocus  appShortcutKey  [AltKey]="'o'"
            (keydown.enter)="continueSavingRx()" (click)="continueSavingRx()">
            <span>O</span> Ok
        </button>
    </app-custom-modal>
</ng-template>
<div class="exprx--common-block erx--body insurance--body eprx-transfer-req" *ngIf="IsNotPopup">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        Pharmacist Notes
    </div>
    <div
    class="eprx--block__content pt-0" [style.height]="TransferIn? '180px' : ''">
    <div class="row"> <!---eprx-form-group -> to avoid  curret margin bottom for form-group class-->
        <div  class="eprx-card-label--data eprx-col-12  px-0">
            {{HeaderText}}
         </div>
        <div class="col-12  pl-0" >
            <div class="col-12">
               <div class="eprx-row" [ngClass]="TransferIn? 'mt-1' : ''"> 
                <div  class="eprx-card-label--data   px-0" *ngIf="!requestedNotesInfo">
                   <label>Category:</label> {{ MainCategory ? (MainCategory === 'RxAnnotation' ? "Rx Annotation" : MainCategory) : 'Rx Annotation'}}
                </div>
                <div  class="eprx-card-label--data   px-0" *ngIf="requestedNotesInfo">
                    <label>Category:</label> {{requestedNotesInfo?.NoteType}}
                 </div>
               </div>
               <div class="eprx-row mt-1">
               
               <div  class="eprx-card-label--data eprx-col-6  px-0">
                <eprx-select [RxSelectId]="'notesLogCategry'" [AddItem]="true" [SelectOnTab]="true" *ngIf="!requestedNotesInfo"
                    [LabelText]="'Subcategory'" [PlaceHolder]="'Subcategory'" [IsTitle]="true" 
                    [IsRequired]="subCatInvalid" [FormGroupInvalid]="formGroupInvalid"
                    [DropdownPosition]="'top'"
                    [ControlName]="'NoteCatId'" [FormGroupName]="notesLogFG" [List]="notesLogInfo?.NoteCat" 
                    [BindLabel]="'Name'"  [secondCntrlN]="'Name'" [bindScnCntrlV]="'Name'"  [BindValue]="'Id'" [addItemMaxLength]="15"
                    [LabelForId]="'Name'" (TriggerSelectValue)="checkMandatory()"   [InformationTool]="'Add a subcategory manually if it is not available in the list.'">
                </eprx-select>
                    <label  *ngIf="requestedNotesInfo">Subcategory </label>
                    <div  *ngIf="requestedNotesInfo">{{requestedNotesInfo?.NoteCatId}}</div>
               </div>
               <div  class="eprx-card-label--data eprx-col-6 pr-0">
                <!-- <eprx-input [RxSelectId]="'MainCategory'"  [LabelText]="'Category'" [HasControl]="false" [InputValue]="MainCategory" [IsDisabled]="true" >
                    </eprx-input> -->
                        <eprx-select [RxSelectId]="TransferIn ? 'popupcodes' : 'popupscreen'"
                    [LabelText]="'Popup Screen'" [IsTitle]="true"
                    [PlaceHolder]="''" *ngIf="!requestedNotesInfo"
                    [ControlName]="'PopupScreenCodes'" 
                    [HasMultiple]="true" [FormGroupName]="notesLogFG" 
                    [List]="[{Name:'All', Id: 0, type:'all'}, {Name: 'Edit Rx', Id: 4, type:'er'}, 
                        {Name:'RPh Vrf', Id:3, type:'rph'}, {Name: 'DPV', Id: 5, type:'dpv'}]" [BindLabel]="'Name'"
                    [BindLabel]="'Name'" [BindValue]="'type'" [LabelForId]="'Name'"
                    (TriggerSelectValue)="filterAllinList(notefg , PopupScreenCodes)">
                </eprx-select>
                <label  *ngIf="requestedNotesInfo">Popup Screen</label>
                <div  *ngIf="requestedNotesInfo">{{requestedNotesInfo?.PopupScreenCodes}}</div>
            </div>
                </div>      
                <div class="eprx-row transfer-in">  
                    <div class="eprx-card-label--data eprx-col-12 px-0 mb-2" [formGroup]="notesLogFG" *ngIf="!requestedNotesInfo">
                        <angular-editor formControlName="xNote" [config]="editorConfig"></angular-editor>
                      </div>
                      <div  *ngIf="requestedNotesInfo">{{requestedNotesInfo?.xNote}}</div>
                </div>
              
            </div>
        </div>
    </div>
    </div>
</div>