<ng-template #TransferRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closePopup()"  >
        <h4 header>
            {{awaitInfo ?  (IsFromProcess ? 'Transfer Out(Response)' : 'Transfer In Request Info') : 'Rx Transfer In'}}
        </h4>
        <div body class="eprx-transfer-req patient-edit--transfer-in">
            <!--Steppers-->
            <div class="row" *ngIf="!awaitInfo && !IsFromProcess">
                <div class="col-12 row m-0">
                    <div class="col-5 row m-0" style="padding-top: 0px !important;">
                        <div class="col-12 text-center">
                            <div> <i  [ngClass]="eRxModelFG.value['patientID'] ? 'fas fa-check-circle' : 'fa-kit fa-circle-1-solid'"></i> Select Patient</div>
                            <div class="stepper1"
                            [ngClass]=" eRxModelFG.value['patientID'] ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                        </div>
                        </div>
                    </div>
                    <div class="col-2 text-center row pl-0" style="padding-top: 0px !important;">
                        <div class="col-12 tex-center">
                            <div>  <i  [ngClass]="(eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn'] || RxTransferType === 'MarkTransferIn') ? 'fas fa-check-circle' : 'fa-kit fa-circle-2-solid'"></i> Select Transfer Type</div>
                            <div class="stepper2"
                            [ngClass]=" (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn']) ? 'eprx-eRx_transferReq-border-select' : 'eprx-eRx_transferReq-border-unselect'">
                        </div>
                        </div>
                    </div>
                    <div class="col-5 text-center row" style="padding-top: 0px !important;">
                        <div class="col-12 tex-center">
                            <div> <i  [ngClass]="eRxModelFG.controls['targetPharmacy']?.value?.BusinessName ? 'fas fa-check-circle' : 'fa-kit fa-circle-3-solid'"></i> Select Pharmacy</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Transfer In requested info -->
            <div class="row m-0 row-space1" *ngIf="awaitInfo && !IsFromProcess">
                <div class="col-3">
                    <label>Transaction ID</label>
                    <div>{{(awaitInfo?.Jobject?.TransactionId) ? awaitInfo?.Jobject?.TransactionId : msgConfig.EMPTY_Data}}</div>
                </div>
                <div class="col-2">
                    <label>Transaction Date and Time</label>
                    <div>{{(awaitInfo?.PrescriptionData?.PrescriptionData?.MessageDate) ? (awaitInfo?.PrescriptionData?.PrescriptionData?.MessageDate) : msgConfig.EMPTY_Data }}</div>
                </div>
                <div class="col-2">
                    <label>Message Type</label>
                    <div>{{(currentDetails ? (currentDetails['Message Type'] | uppercase) : msgConfig.EMPTY_Data)}}</div>
                </div>
                <div class="col-2">
                    <label>Transaction Status</label>
                    <div>{{(currentDetails ? (currentDetails['Status'] | uppercase) : msgConfig.EMPTY_Data)}}</div>
                </div>
                <div class="col-3">
                    <label>Error Description</label>
                    <div>{{(awaitInfo?.Jobject?.ErrorDescription) ? awaitInfo?.Jobject?.ErrorDescription : msgConfig.EMPTY_Data}}</div>
                </div>
            </div>
             <!--Patient & Transfer Type & Pharmacy selection-->
            <div class="row row-space1">
                <div class="col-5">
                    <app-patient-selection [RequestedInfo]="awaitInfo?.Patient" [IsfrmPatHis]="IsfrmPatHis"  [SelectedPatientDetailsfrmRx]="selectedPatientDetails" (SelectedPatientID)="selectedPatientID($event)"></app-patient-selection>
                </div>
                <div class="col-2 px-0">
                    <app-transfer-type [RequestedInfo]="awaitInfo"  [eRxModelFG]="eRxModelFG" [IsHistory]="true" [IsfrmPatHis]="IsfrmPatHis" (SelectedDrugDetails)="selectedDrugDetails($event)" (SelectedTransType)="selectedTransTypeDetails($event)"></app-transfer-type>
                </div>
                <div class="col-5">
                    <app-pharmacy-selection [HeaderTitle]="IsFromProcess ? 'From Pharmacy Details' : 'To Pharmacy Details'" [RequestedInfo]="awaitInfo" (selectedPharm)="selectedToPharmacy($event)"></app-pharmacy-selection>
                </div>
            </div>
            <!--Transfer Type-->
            <div class="row row-space1" *ngIf="!IsFromProcess">
                <div class="col-12"> 
                    <app-transfer-request-details [SelectedTransferTypeDetails]="selectedDruginformation ? selectedDruginformation : selectedTransferTypeDetails"></app-transfer-request-details>
                </div>
            </div>
              <!---To display the Transferable and informational Rx's Based on Transfer In request-->
            <div class="row row-space1" *ngIf="IsFromProcess">
                <div class="col-12" > 
                    <app-tranferable-information-rxs [SelectedRxList]="rxsList" (emitRxSelection)="getTheSelectedRxs($event)" (showFullscreenMode)="showFullscreenMode($event)" [currentDetails]="currentDetails" [SelectedRxList]="selectedRxList" [RequestedInfo]="awaitInfo"></app-tranferable-information-rxs>
                </div>
            </div>
               <!---Your Pharmacy details & Notes-->
            <div class="row row-space1">
                <div class="col-5">
                    <app-current-pharmacy (RequestPharmacyDetails)="requestPharmacyDetails($event)" [IsFromRequestInfo]="(awaitInfo && !IsFromProcess) ? true : false" [LabelName]="'Your Pharmacy'"></app-current-pharmacy>
                </div>
                <div class="col-4 px-0" *ngIf="(!awaitInfo || IsFromProcess)">
                    <app-single-notes [RequestedInfo]="awaitInfo" [TransferIn]="'TransferInReq'"  (TransferNotes)="transferSingleNotes($event)" [IsNotPopup]="true" 
                        [MainCategory]="'eRx'"></app-single-notes>
                </div>
                <div class="col-4 px-0" *ngIf="awaitInfo && !IsFromProcess">
                   <app-notes-details [RequestedInfo]="awaitInfo" [MainCategory]="'eRx'"></app-notes-details>
                </div>
                <div class="col-3 text-center transfer-in_icon">
                      <img src="/assets/Transfer In Req.svg"   [ngbTooltip]="'Transfer In'" triggers="mouseover:mouseleave"
                      [autoClose]="true" placement="top" tooltipClass="search-tooltip title-tooltip">
                </div>
            </div>
        </div>
        <!--Footer-->
        <button *ngIf="!awaitInfo && !IsFromProcess"   footer 
            [ngbTooltip]="'Rx Transfer In Request.'" triggers="mouseover:mouseleave"
            [autoClose]="true" placement="top" tooltipClass="search-tooltip title-tooltip"
            [ngClass]="(eRxModelFG.valid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn'])) ? 'hotkey_success' : 'inactive'"
            (click)="eRxModelFG.valid && (eRxModelFG.value['drugID'] || eRxModelFG.value['IsTransferIn']) ? rxTransfer('InReq') : ''" appShortcutKey [AltKey]="'q'"><span>Q</span> Send Transfer In Req
        </button>
       <div footer *ngIf="IsFromProcess" >
            <button footer autofocus class="hotkey_success" (click)="rxTransfer('Approved')" appShortcutKey
            [AltKey]="'a'"><span>A</span>Approve
            </button>
            <button footer    class="hotkey_success" (click)="rxTransfer('Denied')" appShortcutKey
            [AltKey]="'d'"><span>D</span> DENY
            </button>
       </div>
        <button footer  class="hotkey_success" (click)="closePopup()"  appShortcutKey
            [AltKey]="'c'"><span>C</span> {{ awaitInfo ? 'CLOSE' : 'CANCEL'}}
        </button>

    </app-custom-modal>
</ng-template>

<ng-template #RejectRequest let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="acceptOrRejectRequest('ok')">
        <h4 header>Reject the Transfer In Request</h4>
        <span body> 
            <div class="row">
                <div class="col-md-12">
                    <eprx-select [LabelText]="'Reason'" [PlaceHolder]="''" [List]="systemData?.erxTransferDenialReason" [HasMultiple]="false" [BindLabel]="'Description'" [ControlName]="'ResponseReasonCode'" [isFirstElem]="true"
                    [FormGroupName]="RxTransferConfirm"
                        [BindValue]="'Description'" [LabelForId]="'ValDescr'" [HasControl]="false" (TriggerSelectValue)="cancelRxReasonSelected($event?.value)" 
                        [AutoFocus]="true"></eprx-select>
                </div>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="acceptOrRejectRequest('accepted')" appShortcutKey [AltKey]="'o'">
            <span>O</span> Ok
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #NonandTransferrableRxs let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeRxPopup()">
        <h4 header>Transfer Request Details</h4>
        <span body>
            <app-tranferable-information-rxs   [currentDetails]="currentDetails"  [RequestedInfo]="awaitInfo" (emitRxSelection)="getTheSelectedRxs($event)" [IsFullscreenMode]="isFullscreenMode" [SelectedRxList]="rxsList" ></app-tranferable-information-rxs>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="closeRxPopup()" appShortcutKey
            [InputKey]="'o'"><b>O</b> OK</button>
    </app-custom-modal>
</ng-template>