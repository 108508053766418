<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Miscellaneous Patient Information
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row">
            <div class="col">
                <eprx-select [RxSelectId]="'Delivery'" [LabelText]="'Delivery Method'" [PlaceHolder]="''" [secondCntrlN]="'DeliveryMode'" [ControlName]="'DeliveryModeId'" [FormGroupName]="morePatFG"
                [IsFirstElem]="true" [bindScnCntrlV]="'Name'" [BindLabel]="'Code'" [BindLabel2]="'Name'"  [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="''" [List]="morePatInfo?.DeliveryModes" [Show2Values]="true">
                </eprx-select>
            </div>
            <div class="col">
                <eprx-select [LabelText]="'Pregnancy Code'" [PlaceHolder]="''" [secondCntrlN]="'PregnancyCode'" [ControlName]="'PregnancyCdId'" [FormGroupName]="morePatFG"
                [bindScnCntrlV]="'Name'" [BindLabel]="'Code'" [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="''" [List]="morePatInfo?.PregnancyCodes" [Show2Values]="true">
                </eprx-select>
            </div>
            <div class="col">
                <eprx-select [LabelText]="'Smoker Code'" [PlaceHolder]="''" [secondCntrlN]="'SmokerCode'" [ControlName]="'SmokerCdId'" [FormGroupName]="morePatFG"
                [bindScnCntrlV]="'Name'" [BindLabel]="'Code'" [BindLabel2]="'Name'" [ShowLabel2]="true" [BindValue]="'Id'" [LabelForId]="''" [List]="morePatInfo?.SmokerCodes" [Show2Values]="true">
                </eprx-select>
            </div>
            <div class="col form-group custom--input">
                <eprx-select [LabelText]="'Place of Services'" [PlaceHolder]="''"  [secondCntrlN]="'ServicePlaceName'" [ControlName]="'ServicePlaceId'" [FormGroupName]="morePatFG"
                    [HasMultiple]="false" [HasBindLabel2]="true" [Show2Values]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [bindScnCntrlV]="'Name'"
                    [BindValue]="'Id'" [LabelForId]="''" [List]="morePatInfo?.ServicePlaces">
                </eprx-select>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <eprx-input [LabelText]="'Employer ID'" [PlaceHolder]="''" [ControlName]="'EmployerNum'" [FormGroupName]="morePatFG"
                [MaxLength]="15">
                </eprx-input>
            </div>
            <div class="col">
                <eprx-input [LabelText]="'Home Plan'" [PlaceHolder]="''" [ControlName]="'HomePlan'" [FormGroupName]="morePatFG" [MaxLength]="3">
                </eprx-input>
            </div>
            <div class="col">
                <eprx-input [LabelText]="'Plan ID'" [PlaceHolder]="''" [ControlName]="'HomePlanNum'" [FormGroupName]="morePatFG" [MaxLength]="8">
                </eprx-input>
            </div>
            <div class="col">
                <eprx-input [LabelText]="'Social Security#'" [PlaceHolder]="''" [ControlName]="'SSNum'" [FormGroupName]="morePatFG"
                    [InputErrors]="morePatFG?.get('SSNum')?.errors" [ErrorDefs]="{pattern: 'Invalid!'}" [ValidPattern]="regex?.AlphaNumeric" [MaxLength]="11"
                    [MaskPattern]="'000-00-0000'">
                </eprx-input>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <eprx-input [LabelText]="'Drivers License#'" [PlaceHolder]="''" [ControlName]="'DriveLicenseNum'" [FormGroupName]="morePatFG"
                                [InputErrors]="morePatFG?.get('DriveLicenseNum')?.errors" [ErrorDefs]="{pattern: 'Invalid!'}" [ValidPattern]="regex?.AlphaNumeric">
                                </eprx-input>
                            </div>
                            <div class="col-6">
                                <eprx-date-picker  [RxSelectId]="'DriverLicExpDt'"  [LabelText]="'Expiration Date'" [PlaceHolder]="''" 
                                [ControlName]="'DriverLicExpDt'" [FormGroupName]="morePatFG" 
                                [MinDate]="minDate" [IsRequired]="morePatFG.value['DriveLicenseNum']" 
                                [ErrorDefs]="{required: 'Required',minDate:'Min Date'}" [InputErrors]="morePatFG?.get('DriverLicExpDt')?.errors">
                                </eprx-date-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <eprx-select [RxSelectId]="'PatIdType'" [LabelText]="'Patient ID Type'" [PlaceHolder]="'Patient ID Type'" [ControlName]="'PatientQualifId'" [FormGroupName]="morePatFG"
                [List]="systemData?.PatientQualifier ? (systemData?.PatientQualifier) : []" [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'"
                [secondCntrlN]="'PatientQualifName'" [bindScnCntrlV]="'Remarks'" [Show2Values]="true" [show2ValInInputhidden]="true"
                >
                </eprx-select>
               <div class="d-inline tooltip-patient-file i-icon-left" [ngbTooltip]="msgToolTip"  tooltipClass="search-tooltip-pat"  triggers="mouseover:mouseleave" [autoClose]="true" container="body" placement="right" >𝒊</div>
                <ng-template #msgToolTip>
                    <div class="tooltip--top--more">
                        <div class="row text-left">
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Social Security Number</label>
                            </div>
                           <p class="font-innertext-size">
                            Code indicating that the information to follow is the 9digit number assigned to an individual by the Social Security Administration for various purposes, including paying and reporting taxes.
                           </p>
                           <div class="col-md-12 font-header-size">
                            <label class="text-decoration-underline"
                            >Facility ID Number</label>
                            </div>
                            <p class="font-innertext-size">
                            ID number assigned by the LTC Facility to the patient.
                             </p>
                             <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Driver's License Number</label>
                            </div>
                            <p class="font-innertext-size">
                                Indicator defining the information to follow as the patient's license to operate a motor vehicle.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">U.S. Military ID</label>
                            </div>
                            <p class="font-innertext-size">
                                An identification number given to an active or retired member of the US Armed Services or their dependents.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Non-SSN-based patient identifier assigned by health plan</label>
                            </div>
                            <p class="font-innertext-size">
                                An identification number given to a member by the health plan that is not based on the member's SSN.
                            </p>
                            <div class="col-md-12 font-header-size">
                            <label class="text-decoration-underline">SSN-based patient identifier assigned by health plan</label>
                            </div>
                            <p class="font-innertext-size">
                                An identification number given to a member by the health plan that is based on the member's SSN with modifications so the number is not equal to the SSN.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Medicaid ID</label>
                            </div>
                            <p class="font-innertext-size">
                                a number assigned by a state Medicaid agency.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">State Issued ID</label>
                            </div>
                            <p class="font-innertext-size">
                                An ID issued by a state for the purpose of identifying the individual for legal requirements.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Passport ID</label>
                            </div>
                            <p class="font-innertext-size">
                                A document number found within an official identification document that is supplied to an individual by a national government.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Medicare HIC</label>
                            </div>
                            <p class="font-innertext-size">
                                The identification of person assigned by Medicare.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Employer Assigned ID</label>
                            </div>
                            <p class="font-innertext-size">
                                The identification of a person assigned by the employer.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Paver/PBM Assigned ID</label>
                            </div>
                            <p class="font-innertext-size">
                                The identification of a person assigned by the payer or pharmacy benefit manager.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Alien Number (Government Permanent Residence Residence Number)</label>
                            </div>
                            <p class="font-innertext-size">
                                The ID number assigned by the government for the individual in the country as a permanent resident.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Government Student VISA Number</label>
                            </div>
                            <p class="font-innertext-size">
                                The ID number assigned by the government for the individual in the country on a student VISA.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Indian Tribal ID</label>
                            </div>
                            <p class="font-innertext-size">
                                An ID assigned by an Indian Tribal Authority to identify an individual.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Other</label>
                            </div>
                            <p class="font-innertext-size">
                                Different from those implied or specified.
                            </p>
                            <div class="col-md-12 font-header-size">
                                <label class="text-decoration-underline">Medical Record Identification Number (EHR)</label>
                            </div>
                            <p class="font-innertext-size">
                                A unique number assigned to each patient by the provider of service to assist in retrieval of medical records.
                            </p>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="col-3">
                <eprx-input [RxSelectId]="'PatIdVal'" [LabelText]="'ID#'" [PlaceHolder]="'ID#'" [ControlName]="'IdProofVal'" [FormGroupName]="morePatFG" [MaxLength]="50">
                </eprx-input>
            </div>
            <div class="col-3 form-group custom--input">
                <eprx-select [LabelText]="'Eligibility Clarification Code'" [PlaceHolder]="''" [updateDropDownPos]="true" [secondCntrlN]="'EligibleCdName'" [ControlName]="'EligibleClId'" [FormGroupName]="morePatFG"
                    [HasMultiple]="false" [HasBindLabel2]="true" [Show2Values]="true" [BindLabel2]="'Remarks'" [BindLabel]="'Name'"
                    [bindScnCntrlV]="'Remarks'"  [BindValue]="'Id'" [LabelForId]="''" [List]="morePatInfo?.EligibilityCodes" [show2ValInInputhidden]="true">
                </eprx-select>
            </div>
        </div>
        <div class="row">
            <!-- <div class="col-2">
                <label class="col-md-12 padding-0"> Patient Assignment? </label>
                <eprx-radio [ControlName]="'IsFullyInsured'" [FormGroupName]="morePatFG" [IsRequired]="true" [MarkAsTouched]="morePatFG?.get('IsFullyInsured')?.touched" [List]="['Yes', 'No']"
                    [Name]="'IsFullyInsured'" [ValueList]="[true, false]" [IDForList]="['PIsFullyInsured3', 'PIsFullyInsured4']">
                </eprx-radio>
            </div> -->
            <div class="col-3">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <label class="col-md-12 padding-0"> Deceased </label>
                                <eprx-toggle  [ControlName]="'IsDeceased'" [FormGroupName]="morePatFG" [IsChecked]="morePatFG?.get('IsDeceased')?.value" 
                                [RxselectId]="'IsdeceasedPat'">
                                </eprx-toggle>
                            </div>
                            <div class="col-6">
                                <label class="col-md-12 padding-0"> Privacy Acknowledgement? </label>
                                <eprx-toggle [ControlName]="'IsPrivacyAcked'" [FormGroupName]="morePatFG" (TriggerSelectValue)="onAckChange()" [IsChecked]="morePatFG?.get('IsPrivacyAcked')?.value"  [RxselectId]="'IsprivacyackedPat'">
                                </eprx-toggle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3"  *ngIf="!morePatFG.value['IsPrivacyAcked']">
                <eprx-date-picker   [RxSelectId]="'PrivacyAckDtID'"  [LabelText]="'Privacy Acknowledgement Date'" [PlaceHolder]="''" [ControlName]="'PrivacyAckDt'" [FormGroupName]="morePatFG" [IsDisabled]="true" 
                    [MinDate]="" [MaxDate]=""  [MarkAsTouched]="morePatFG?.get('PrivacyAckDt')?.touched">
                </eprx-date-picker>
            </div>
            <div class="col-3" *ngIf="morePatFG.value['IsPrivacyAcked']">
                <eprx-date-picker  [RxSelectId]="'PrivacyAckDtID'"  [LabelText]="'Privacy Acknowledgement Date'" [PlaceHolder]="''" [ControlName]="'PrivacyAckDt'" [FormGroupName]="morePatFG"
                    [MinDate]="" [MaxDate]="maxDate" [IsRequired]="morePatFG.value['IsPrivacyAcked']" [MarkAsTouched]="morePatFG?.get('PrivacyAckDt')?.touched" [FormGroupInvalid]="formGroupInvalid">
                </eprx-date-picker>
            </div>
            <div class="col-3">
                <eprx-select [LabelText]="'Patient Residence'" [PlaceHolder]="''" [secondCntrlN]="'ResidenceCatName'" [ControlName]="'ResidenceCatId'" [FormGroupName]="morePatFG"
                    [HasMultiple]="false" [HasBindLabel2]="true" [Show2Values]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'"
                    [bindScnCntrlV]="'Name'"  [IsAppendTo]="true" [LabelTitle1]="'Id'" [LabelTitle2]="'Name'" [BindValue]="'Id'" [LabelForId]="''" [List]="morePatInfo?.ResidenceCatogiries">
                </eprx-select>
            </div>
            <div class="col-3 seconday-search--grid common_global_search_max_content_display_for_Presc line_height_1">
                <label> Primary Provider </label>
                <app-searchbox-global [SearchFor]="'Prescriber'" [DisableSerchFil]="true" [DisableRadioButtons]="true"
                   [notSelectSingleMatch]="true" [IsAddNew]="false" (SelectedPrescriber)="selectedPresInfo($event)" [ClearInputValue]="prescInputValue">
                </app-searchbox-global>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <eprx-select [RxSelectId]="'speciesCode'" [LabelText]="'Species Code'" [PlaceHolder]="''"
                        [ControlName]="'SpeciesCdId'" [FormGroupName]="morePatFG"
                        [BindLabel]="'Name'" [BindValue]="'Id'"
                        [List]="morePatInfo?.SpeciesCodes" (TriggerSelectValue) = "clearValues()">
            </eprx-select>
            </div>
            <div class="col-3">
                <eprx-input [LabelText]="'Pet Owner\'s Last Name'" [PlaceHolder]="''" [ControlName]="'PetOwnerLastName'" [FormGroupName]="morePatFG"  [ValidPattern]="regex?.AlphaNumeric" [IsDisabled]="morePatFG?.controls['SpeciesCdId']?.value !== 2" [RxSelectId] = "'PetOwnerLastName'">
                </eprx-input>
            </div>
            <div class="col-3">
                <eprx-input [LabelText]="'Pet Owner\'s First Name'" [PlaceHolder]="''" [ControlName]="'PetOwnerFirstName'" [FormGroupName]="morePatFG"  [ValidPattern]="regex?.AlphaNumeric" [IsDisabled]="morePatFG?.controls['SpeciesCdId']?.value !== 2" [RxSelectId] = "'PetOwnerFirstName'">
                </eprx-input>
            </div>
            <div class="col-3">
                <eprx-date-picker  [RxSelectId]="'petOWnerDOB'"  [LabelText]="'Pet Owner\'s DOB'" [PlaceHolder]="''" [ControlName]="'PetOwnerDOB'" [FormGroupName]="morePatFG" [IsDisabled]="morePatFG?.controls['SpeciesCdId']?.value !== 2" [MinDate]="" [MaxDate]="maxDate">
                </eprx-date-picker>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <eprx-select [RxSelectId]="'patientRaces'" [LabelText]="'Patient\'s Race'" [PlaceHolder]="''" [secondCntrlN]="'PatientRaceName'"
                        [ControlName]="'PatientRaceId'" [FormGroupName]="morePatFG" [HasMultiple]="false" [HasBindLabel2]="true" [bindScnCntrlV]="'Name'"
                        [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                        [List]="morePatInfo?.PatientRaces">
            </eprx-select>
            </div>
            <div class="col-3">
                <eprx-select [RxSelectId]="'comorbStatus'" [LabelText]="'Comorbidity Status'" [PlaceHolder]="''" [secondCntrlN]="'PatientComorbidityStatusName'"
                        [ControlName]="'PatientComorbidityStatusId'" [FormGroupName]="morePatFG" [HasMultiple]="false" [HasBindLabel2]="true" [bindScnCntrlV]="'Name'"
                        [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                        [List]="morePatInfo?.ComorbidityStatuses" [show2ValInInputhidden]="true" [IsTitle]="true">
            </eprx-select>
            </div>
            <div class="col-3">
                <eprx-select [RxSelectId]="'patientEthnicity'" [LabelText]="'Ethnicity'" [PlaceHolder]="''" [secondCntrlN]="'PatientEthnicityName'"
                        [ControlName]="'PatientEthnicityId'" [FormGroupName]="morePatFG" [HasMultiple]="false" [HasBindLabel2]="true" [bindScnCntrlV]="'Name'"
                        [Show2Values]="true" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                        [List]="morePatInfo?.PatientEthnicities">
            </eprx-select>
            </div>
            
            <div class="col-3">
                <eprx-input [LabelText]="'Maiden Middle Name'" [PlaceHolder]="''" [ControlName]="'MaidenMiddleName'" [FormGroupName]="morePatFG"  [ValidPattern]="regex?.AlphaNumeric" [RxSelectId] = "'maidenMiddleName'" [MaxLength]="50" [ErrorDefs]="{pattern: 'Invalid!'}">
                </eprx-input>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <eprx-select [LabelText]="'Patient Relation'" [PlaceHolder]="''" [ControlName]="'PatientRelationId'" [FormGroupName]="morePatFG"
                [HasMultiple]="false" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''"
                [Show2Values]="true" [List]="systemData?.RelationCat ? (systemData?.RelationCat | filterRelation:'Pick') : []"
                [secondCntrlN]="'PatientRelRemarks'" [bindScnCntrlV]="'Remarks'">
            </eprx-select>
            </div>
            <div class="col-3">
                <eprx-input [LabelText]="'Guardian\'s First Name'" [PlaceHolder]="''" [ControlName]="'GuardienFirstName'" [FormGroupName]="morePatFG"  [ValidPattern]="regex?.AlphaNumeric" [RxSelectId] = "'guardianFirstNameId'" [MaxLength]="50" [ErrorDefs]="{pattern: 'Invalid!'}">
                </eprx-input>
            </div>
            <div class="col-3">
                <eprx-input [LabelText]="'Guardian\'s Last Name'" [PlaceHolder]="''" [ControlName]="'GuardienLastName'" [FormGroupName]="morePatFG"  [ValidPattern]="regex?.AlphaNumeric" [RxSelectId] = "'guardianLastNameId'" [MaxLength]="50" [ErrorDefs]="{pattern: 'Invalid!'}" (TriggerOnTabbing)="focusToFirst($event)">
                </eprx-input>
            </div>
            <div class="col-3">
                <eprx-select [LabelText]="'Patient Salutation'" [PlaceHolder]="''" [secondCntrlN]="'Saluation'" [ControlName]="'SalutationId'" [FormGroupName]="morePatFG"
                [bindScnCntrlV]="'Name'"   [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [List]="[{'Id': 1, 'Name': 'Mr.'}, {'Id': 2, 'Name': 'Miss'}, {'Id': 3, 'Name': 'Mrs'}]">
                </eprx-select>
            </div>
            <div class="col-3">
                <eprx-select [LabelText]="'Patient Suffix'" [PlaceHolder]="''" [secondCntrlN]="'NameSuffix'" [ControlName]="'NameSuffixId'" [FormGroupName]="morePatFG"
                    [HasMultiple]="false" [HasBindLabel2]="true" [Show2Values]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'"
                    [bindScnCntrlV]="'Name'"  [BindValue]="'Id'" [LabelForId]="''" [List]="morePatInfo?.NameSuffixes">
                </eprx-select>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-6 form-group custom--input seconday-search--grid">
                <label> Primary Provider </label>
                <app-searchbox-global [SearchFor]="'Prescriber'" [DisableSerchFil]="true" [DisableRadioButtons]="true"
                   [notSelectSingleMatch]="true" [IsAddNew]="false" (keydown.tab)="focusToFirst($event)" (SelectedPrescriber)="selectedPresInfo($event)" [ClearInputValue]="prescInputValue">
                </app-searchbox-global>
            </div>
        </div> -->
    </div>
</div>
