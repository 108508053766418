

<ng-template #ISPATIENTDISEASED let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();patientIsDeceased()">
        <h4 header> Warning!!</h4>
        <span body>
            Patient is Deceased.
        </span>
        <button footer id="ISPATIENTDISEASED" ngbAutofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()" (click)="patientIsDeceased()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #HIGHRISKPATIENT  let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();isPatientHasNotes()">
        <h4 header> High Risk Patient </h4>
        <span body>
            <div *ngIf="years > geriatricAge"> <b>GERIATRIC (Over Age of {{geriatricAge}})</b> </div>
            <div *ngIf="years < youthUnderAge"> <b>Youth (Under Age of {{youthUnderAge}})</b> </div>
            <div *ngIf="pregantPatient && patientData?.gender === 'Female' && patientData?.PatientInfo?.PregnancyCdId === 3">
                <div>&nbsp;</div>
                <b>Patient is Pregnant </b> </div>
            <!-- <div>
             <div>&nbsp;</div> <b>ACB Score: {{acbScore? acbScore: 0}}</b>
            </div> -->
        </span>
        <button footer ngbAutofocus type="submit" id="HIGHRISKPATIENT" class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()" (click)="isPatientHasNotes()">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<!-- <ng-template #PRIVACYACK let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title"> Privacy Acknowledgement does not exist on File </h4>
    </div>
        <div class="modal-body">
            <form class="form-group custom--input was-validated">
                <span class="inline-flex">
                    <div class="custom-control custom-radio">
                        <input type="radio"
                        class="custom-control-input"
                        id="c"
                        name="PrivAck"
                        [checked]="privcAck === 'c'"
                        (change)="selectedPrivAckValue('c');"
                        >
                        <label class="custom-control-label align-adjust-with-radio" for="c">
                                (C)ontinue
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio"
                        class="custom-control-input"
                        id="s"
                        name="PrivAck"
                        [checked]="privcAck === 's'"
                        (change)="selectedPrivAckValue('s');"
                        >
                        <label class="custom-control-label align-adjust-with-radio" for="s">
                                (S)ignature
                        </label>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio"
                        class="custom-control-input"
                        id="r"
                        name="PrivAck"
                        [checked]="privcAck === 'r'"
                        (change)="selectedPrivAckValue('r');"
                        >
                        <label class="custom-control-label align-adjust-with-radio" for="r">
                                (R)efuse
                        </label>
                    </div>
                </span>
            </form>
        </div>
</ng-template> -->


<ng-template #PATIENTNOTES let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();checkBillTo()">
        <h4 header> Pop-up Note </h4>
        <span body>
            <div class="notes_pop">
                <div class="row">
                    <h5 class="col-12">
                        Patient Notes
                    </h5>
                </div>
                <div *ngFor="let notes of notesLog?.Note; let i=index" class="col-xs-12">
                    <div class="row border--bottom" *ngIf="notes.IsShownAsPopup">
                        <hr class="w-95">
                        <div class="col-6"> {{notes?.ModifiedDtTm | datetimeUtc}} </div>
                        <div class="col-6">
                            <span class="text-center">
                                <input class="form-check-input" type="checkbox" [id]="'notes'+i" name=""
                                    [checked]="notes.IsShownAsPopup" (change)="changeStatus(notes,$event?.target?.checked)"/>
                                <label class="form-check-label" [for]="'notes'+i">Show as Pop-up ?</label>
                            </span>
                        </div>
                        <div class="col-12 mt-3 word-break" >{{notes?.xNote}}</div>
                    </div>
                </div>
            </div>
        </span>
        <button footer ngbAutofocus id="PatientNotesDeflActn" type="submit" class="hotkey_primary" appShortcutKey
            InputKey="o" (click)="closeModal()" (click)="checkBillTo()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #BILLTO let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();selctCloseBillToPopup()">
        <h4 header *ngIf="rxType !== 'rf'"> Select Insurance To Bill</h4>
        <h4 header *ngIf="rxType === 'rf'">
            <span *ngIf="!frmInsuFileInact">The Insurance selected for the Last fill has been <span *ngIf="insuDelete === 'D'">deleted from Patient.</span> <span *ngIf="insuDelete === 'I'">inactivated.</span>
             Please select the insurance to Bill.</span>
            <span *ngIf="frmInsuFileInact"> Select Insurance To Bill</span></h4>
        <span body>
            <div class="eprx--block__content scrool--vert min-height_0rem">
                <div *ngIf="(insureList?.length === 0)" class="text-center">
                    <h1>There are no Insurances for this patient.</h1>
                </div>
                <span *ngIf="insureList?.length > 0">

                    <wj-flex-grid #insuranceToBill [headersVisibility]="'Column'" [itemsSource]="insuranceToBillWJ" [isReadOnly]="true" (initialized)="init(insuranceToBill)" [columnPicker]="'insuranceToBillWj'" [selectionMode]="'None'">
                        <wj-flex-grid-column [header]="''" [width]="35">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div *ngIf="!frmInsuFileInact">
                                <span *ngIf="insureList?.length > 1 && (item?.insuranceid == (((rxFG.controls?.isNewFrmExisting?.value === true || rxFG.controls?.fileToFill?.value || rxFG.value['fileToFillNewRx']) && InsuId) ? InsuId?.InsuId : patientData?.insuranceid))" >
                                    <input [id]="item?.insuranceid" type="radio" name="SelectedIns" (change)="selectedInsurence($event?.target?.checked, item, true)" (keydown.enter)="closeModal()" (keydown.enter)="selctCloseBillToPopup(true)"
                                    [value]="item?.insuranceid" [checked]="true" [disabled]="false" autofocus/>
                                </span>
                                <span *ngIf="insureList?.length <= 1 || (item?.insuranceid != (((rxFG.controls?.isNewFrmExisting?.value === true || rxFG.controls?.fileToFill?.value || rxFG.value['fileToFillNewRx']) && InsuId) ? InsuId?.InsuId : patientData?.insuranceid))" >
                                    <input [id]="item?.insuranceid" type="radio" name="SelectedIns" (change)="selectedInsurence($event?.target?.checked, item, true)" (keydown.enter)="closeModal()" (keydown.enter)="selctCloseBillToPopup(true)"
                                    [value]="item?.insuranceid" [disabled]="isDisabled(item)" [title]="!item['Active Status'] ? 'Insurance is marked as Inactive' : (item['ParentInsuId'] ? getTitle(insureList) : '')"/>
                                </span>
                            </div>
                                <span *ngIf="frmInsuFileInact">
                                    <input [id]="item?.insuranceid" type="radio" name="SelectedIns" (change)="selectedInsurence($event?.target?.checked, item, true)" (keydown.enter)="closeModal()" (keydown.enter)="selctCloseBillToPopup(true)"
                                    [value]="item?.insuranceid" [checked]="isCheckedIns(item)" [disabled]="disabled || isDisabled(item)" 
                                    [title]="disabled ? (disablPayRecExist ? 'Insurance is already used for Transmission' : 'Insurance is marked as Inactive') : (item['ParentInsuId'] ? getTitle(insureList) : '')" autofocus/>
                                </span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']"
                        [width]="rxheader['width']" *ngFor="let rxheader of wjHeaders">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div [title]="rxheader['hName']">
                                    {{item[rxheader['hName']]}}
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                </span>
            </div>
        </span>
    <span footer *ngIf="!frmInsuFileInact">
        <button index="1" ngbAutofocus id="BillToDeflActnOK" #BillToDeflActnOK class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()" (click)="selctCloseBillToPopup(true)"> <b>O</b> Ok</button>
        <button *ngIf="!lastInsuUsed" index="2" class="hotkey_primary mr-0" id="BillToDeflActnCancl" #BillToDeflActnCancl  appShortcutKey InputKey="c" (click)="closeModal()" (click)="selctCloseBillToPopup()"><b>C</b> Cancel
        </button>
    </span>
    <span footer *ngIf="frmInsuFileInact">
        <button index="3" ngbAutofocus id="BillToDeflActnOK1" #BillToDeflActnOK1 class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()"
        (click)="selctCloseBillToPopup(true)"> <b>O</b> Ok</button>
        <button index="4" class="hotkey_primary mr-0" id="BillToDeflActnCancl2" #BillToDeflActnCancl2  appShortcutKey InputKey="c" (click)="closeModal()" (click)="selctCloseBillToPopup()"><b>C</b> Cancel
        </button>
    </span>
    </app-custom-modal>
</ng-template>

<ng-template #RefillInsInactive let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
    </div>
    <div class="modal-body">
        <p>The Insurance for the selected Rx is Inactive, would you like to refill with the same Insurance and activate it automatically?</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
        (click)="setInsuranceActive(); c('Close click')"><b>y</b>Yes</button>

    <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
        (click)="setPatientPrimaryInsurance(); c('Close click')"><b>n</b>No</button>
    </div>


</ng-template>

<ng-template #ALLInsInactive let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
    </div>
    <div class="modal-body">
        <p>All insurances are inactive. Please activate at least one insurance</p>
    </div>
    <div class="modal-footer" >
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'o'"
        (click)="ProceedBack(); c('Close click')"><b>o</b>OK</button>
    </div>
</ng-template>

<ng-template #INSUNOTES let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();openFiledRxCountPopUp()">
        <h4 header> Pop-up Note </h4>
        <span body>
           <div class="notes_pop">
            <div class="row">
                <h5 class="col-12">
                    Insurance Notes
                </h5>
            </div>
            <div *ngFor="let notes of notesLog?.Note; let i=index" class="col-xs-12">
                <div class="row" *ngIf="notes.IsShownAsPopup">
                    <hr class="w-95">
                    <div class="col-6"> {{notes?.ModifiedDtTm | datetimeUtc}} </div>
                    <div class="col-6">
                        <span class="text-center">
                            <input class="form-check-input" type="checkbox" [id]="'notes'+i" name=""
                                [checked]="notes.IsShownAsPopup" (change)="changeStatus(notes,$event?.target?.checked)"/>
                            <label class="form-check-label" [for]="'notes'+i">Show as Popup ?</label>
                        </span>
                    </div>
                    <div class="col-12 word-break ">{{notes?.xNote}} </div>
                </div>
            </div>
           </div>
        </span>
        <button footer ngbAutofocus id="insuNotesDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()"
            (click)="openFiledRxCountPopUp()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #INSUREFILLSETTINGS let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();isInsurenceHasNotes()">
        <h4 header>Warning!!</h4>
        <span body>
            Received number of refills are {{currentRefillNumberFromErx ? currentRefillNumberFromErx : 0}}. Max allowed Refills for the selected insurance is  {{maxRefillsAllowed ? maxRefillsAllowed : 0}}
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button class="hotkey_primary" (click)="closeModal()" (click)="isInsurenceHasNotes()"  appShortcutKey InputKey="o">
                    <b>O</b> Ok </button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #FILEDRXPOP let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();pat340BSettingsInfo()">
        <h4 header> Deferred Rx Information</h4>
        <span body>
            There are {{noOfFileRx}} Deferred Rx(s) for this patient. To view the Deferred Rx(s) go to Patient's Rx history and select
            Deferred Rx filter.
        </span>
        <button footer id="FILEDRXPOP" ngbAutofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()" (click)="pat340BSettingsInfo()"> <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #INSUDELETEWARNING let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();closeWarnPatientInsuranceChange()">
        <h4 header> Patient Insurance <span *ngIf="insuDelete === 'D'">Deleted</span> <span *ngIf="insuDelete === 'I'">Inactivated</span></h4>
        <span body>
            <span *ngIf="rxType === 'rf'">
                <span *ngIf="insuDelete === 'D'">
                The Insurance selected for the Last fill has been deleted from Patient.
                </span>
                The Insurance selected on the Last fill of this Rx has been
                <span *ngIf="insuDelete === 'I'">
                     inactivated.</span>
                </span>
            <span *ngIf="rxType !== 'rf'">Selected Insurance <b>{{lastInsuUsed?.insurancename | uppercase}}</b> is <span *ngIf="insuDelete === 'D'">deleted from Patient.</span> <span *ngIf="insuDelete === 'I'">inactivated.</span></span>

            Bill to on this Rx will now be changed to Patient's primary insurance, i.e., <b>{{optedInsurence?.insurancename | uppercase}}</b>.
        </span>
        <button footer index="1" ngbAutofocus id="INSUDELETEWARNING1" #INSUDELETEWARNING1 class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()"
            (click)="closeWarnPatientInsuranceChange()"> <b>O</b> Ok</button>
    </app-custom-modal>
</ng-template>

<ng-template #INSUCHANGEWARNING let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeWarnandtriggerPatPopUpsCompl();closeModal()">
        <h4 header> Patient Insurance Changed </h4>
        <span body>
            Patient's Primary Insurance has been changed from<b> {{lastInsuUsed?.insurancename | uppercase}}</b> to <b>{{optedInsurence?.insurancename | uppercase}}</b>.
             Bill to for this Rx will be changed to <b>{{optedInsurence?.insurancename | uppercase}}</b> as well.
        </span>
        <button footer index="1" ngbAutofocus id="INSUCHANGEWARNING1" #INSUCHANGEWARNING1 class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()"
            (click)="closeWarnPatientInsuranceChange()"> <b>O</b> Ok</button>
        <button footer index="2" class="hotkey_primary mr-0" id="INSUCHANGEWARNING1" #INSUCHANGEWARNING1 appShortcutKey InputKey="c" (click)="closeModal()"
            (click)="closeWarnandtriggerPatPopUpsCompl()"><b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>


<ng-template #INSUEXPIREWARNING let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();isInsurenceHasNotes()">
        <h4 header> Patient Insurance Expired </h4>
        <span body>
            <div class="row">
                <div class="col-12">
                    <p><label>{{optedInsurence?.insurancename | uppercase}}</label> Insurance card expired on <label>{{optedInsurence?.expirydt ? (optedInsurence?.expirydt | date:'MM/dd/yyyy') : ''}}</label> </p>
                </div>
                <div class="col-12">
                    Check the Insurance card and Update the expiration date
                </div>
            </div>
        </span>
        <button footer index="1" ngbAutofocus id="INSUEXPIREWARNING" #INSUEXPIREWARNING class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeModal()"
            (click)="isInsurenceHasNotes()"> <b>O</b> Ok</button>
    </app-custom-modal>
</ng-template>

<ng-template #ISPATIENT340B let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');setRxAs340BRx(false)">
        <h4 header> Confirmation </h4>
        <span body>
            Do you wish to mark this Rx as 340B?
        </span>
        <button footer id="ISPATIENT340BYES" ngbAutofocus class="hotkey_primary" appShortcutKey InputKey="y" (click)="c('Close click');setRxAs340BRx(true)"> <b>Y</b> YES
        </button>
        <button footer id="ISPATIENT340BNO"  class="hotkey_primary" appShortcutKey InputKey="n" (click)="c('Close click');setRxAs340BRx(false)"> <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #INACTIVEINSURANCE let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal();selctCloseBillToPopup()">
        <h4 header> Warning!! </h4>
        <span body>
            <span *ngIf="rxType !== 'rf'" [innerHTML]="warnMsg"></span>
            <span *ngIf="rxType === 'rf'">
                <div *ngIf="(insuranceList?.length > 2 || (insuranceList?.length === 2 && !cashIns))">
                The Insurance <span class='font-weight-bold'> {{insuranceName ? insuranceName.toUpperCase() : ""}}
                </span> selected for the Last fill has been inactivated. Please select the insurance to Bill.
                </div>
                <div *ngIf="(insuranceList?.length === 2 && cashIns?.length > 0)">
                The Insurance <span class='font-weight-bold'> {{insuranceName ? insuranceName.toUpperCase() : ""}}
                </span> selected for the Last fill has been inactivated. Continue Refill with the <span class="font-weight-bold">{{notCashIns[0]?.insurancename.toUpperCase()}}</span>
                 insurance to Bill.
                </div>
                <div *ngIf="(insuranceList?.length === 1 && !cashIns?.length)">
                The Insurance <span class='font-weight-bold'> {{insuranceName ? insuranceName.toUpperCase() : ""}}
                </span> selected for the Last fill has been inactivated. Continue Refill with the <span class="font-weight-bold">{{notCashIns[0]?.insurancename}}</span>
                 insurance to Bill.
                </div>
                <div *ngIf="(insuranceList?.length === 1 && cashIns?.length > 0)">
                The Insurance <span class='font-weight-bold'> {{insuranceName ? insuranceName.toUpperCase() : ""}}
                </span> selected for the Last fill has been inactivated. Continue Refill with <span class='font-weight-bold'> CASH </span> to Bill.
                </div>
            </span>
        </span>
        <span footer *ngIf="(rxType === 'rf')">
        <button id="InActvIns" *ngIf="(insuranceList?.length > 2  || (insuranceList?.length === 2 && !cashIns))" autofocus class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="openBillToPopupModal();c('Close click')"> <b>O</b> Ok
        </button>
        <button id="InActvIns1" *ngIf="((insuranceList?.length === 2 || insuranceList?.length === 1) && cashIns?.length > 0)"
        autofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="selctCloseBillToPopup();c('Close click')"> <b>O</b> Ok
        </button>
        <button id="InActvIns1" *ngIf="(insuranceList?.length === 1 && !cashIns?.length)"
            autofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="selctCloseBillToPopup();c('Close click')"> <b>O</b> Ok
            </button>
    </span>
        <span footer *ngIf="(rxType !== 'rf')">
            <button  id="InActvIns2" *ngIf="insuranceList?.length > 1"
            autofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="openBillToPopupModal();c('Close click')"> <b>O</b> Ok
            </button>
            <button  id="InActvIns3" *ngIf="(insuranceList?.length === 1 && cashIns?.length > 0)"
            autofocus class="hotkey_primary" appShortcutKey InputKey="o" (click)="selctCloseBillToPopup();c('Close click')"> <b>O</b> Ok
            </button>
        </span>

    </app-custom-modal>
</ng-template>
