import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { PaginationModel, PatRxHistoryInputParams, SystemData } from 'src/app/models';
import { AlertService, CommonService, ErxService } from 'src/app/services';
import { StorageService } from 'src/app/services/storage.service';
import { ColorCode, CommonUtil, WijimoUtil } from 'src/app/utils';
import { CollectionView } from "@grapecity/wijmo";
import { FormatsUtil } from 'src/app/utils/formats.util';
import { IntegerFloatPipe } from 'src/app/pipes/integer-float-format.pipe';
import * as wjcGrid from "@grapecity/wijmo.grid"
import { ColorCodeRx } from 'src/app/utils/rx-color.util';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-tranferable-information-rxs',
  templateUrl: './tranferable-information-rxs.component.html',
  styleUrls: ['./tranferable-information-rxs.component.scss']
})
export class TranferableInformationRxsComponent implements OnInit {
  wjHeaders: any;
   unsubscribe$ : Subject<void> = new Subject();
   @Input() RequestedInfo: any;
   @Input() currentDetails: any;
   @Input() IsFullscreenMode: any;
   @Output() emitRxSelection = new EventEmitter();
   @Output() showFullscreenMode = new EventEmitter();
   totalCount: number;
   transferrableWJ: any;
   nonTransferrableWJ: any;
   isDataExists1 = true;
   isDataExists2 = true;
   rxList: any = [];
   isOriginalRequest = false;
   paginationModel: PaginationModel = new PaginationModel();
   pageNumber = this.paginationModel.pageNumber;
   pageSize = this.paginationModel.pageSize;
  systemData: SystemData;
   integerFloatFormat = new IntegerFloatPipe();
   selectedItemList: any = [];
   awaitInfo: any;
   IseRxTRxREQ: boolean = false;
   modelRef: any;
   @Input()
  set SelectedRxList(list : any){
    if(list)
    {
          this.selectedItemList = [...list];
          if (this.rxList .TransferableRx?.length)
              this.generatingWJCols(this.rxList .TransferableRx, "TransferableRx");
          if (this.rxList ?.NonTransferableRx?.length)
              this.generatingWJCols(this.rxList .NonTransferableRx, "NonTransferableRx");
    }
  }
  constructor(private _wijimoUtils: WijimoUtil,
    public _storageService: StorageService,
    private _commonSer: CommonService,
            private _comnUtil: CommonUtil,
            private _formatsUtil: FormatsUtil,
            private _colorCode: ColorCode,
            private _alertServ: AlertService,
            private _colorCodeRx: ColorCodeRx,
            private _modalService: NgbModal,
            private _patientUtil: PatientInfoUtil,
              private _eRxService:ErxService) {
                this._commonSer.systemData$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.systemData = resp;
                    }
                });
               }

  ngOnInit(): void {
    this._storageService.setSessionStorage("fromDrugGrid", true);
    this.getRxsForTransferInReq();
    this.patchDefaultValHeaders();
            this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
                if (w && w["WijmoKey"] && w["WijmoKey"] === "RxsTransferIn") {
                    this.patchDefaultValHeaders();
                }
            });
   }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("RxsTransferIn");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("RxsTransferIn", storedWJ);
    
}
    getRxsForTransferInReq() {
        const payload = new PatRxHistoryInputParams();
        payload.patientId = this.RequestedInfo?.Patient?.patientid;
        // payload.drug  = this.currentDetails?.DrugId;
        payload.PageNumber = 0;
        payload.PageSize = this.paginationModel.pageSize;
        this._eRxService.getRxsForTransferInReq(payload).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if (resp) {
                this.rxList = resp;
                if (resp.TransferableRx?.length)
                    this.generatingWJCols(resp.TransferableRx, "TransferableRx");
                else {
                    this.transferrableWJ = new CollectionView(null);
                    this.isDataExists1 = false;
                }
                if (resp?.NonTransferableRx?.length)
                    this.generatingWJCols(resp.NonTransferableRx, "NonTransferableRx");
                else {
                    this.nonTransferrableWJ = new CollectionView(null);
                    this.isDataExists2 = false;
                }
            } 
        });
    }
 generatingWJCols(rxItemHist, type) {
        const mapDataSet=[];
        rxItemHist?.map((item, i) => {
        const k = {};
        const rxItem = item.erxViewModel;
        const qtyModel = item.qtyModel;
        k["RowId"] = ((this.pageNumber - 1) * this.pageSize) + i;
        k["Rx#"] = rxItem.Prescription.PrescNum;
        k["Auth#"] = rxItem.Prescription.ReFillNum;
        k["IsSelected"] = this.selectedItemList?.find((item: any) =>  item.Prescription.PrescNum === rxItem.Prescription.PrescNum ) ? true : false;
        rxItem["IsTransfferable"] = (type == 'NonTransferableRx') ? false : true;
        k["totalItem"] = rxItem;
        k["totalObj"] = item;
        k["index"] = i;
        k["Ref"] = k["Ref#"] = rxItem.PrescReFill.ReFillNum;
        k["Fill#"] = this._commonSer.checkIsPartialFill(rxItem.PrescReFill, rxItem.Prescription)
        ? rxItem.PrescReFill.PartialFillNo : "";
        k["NDC"] = (rxItem && rxItem.Drug && rxItem.Drug?.ndc) ? this._formatsUtil.getNDCFormat(rxItem.Drug.ndc): "";
        k["Insurance"] = this._commonSer.rxInsurance(rxItem);
        const dispQty = this._formatsUtil.threeFixed(rxItem?.PrescReFill?.DispQty)
        const ordQty = this._formatsUtil.threeFixed(rxItem?.PrescReFill?.OrdQty)
        k["Disp Qty/Ord Qty"] = dispQty + "/" + ordQty;
        k["Status"] =this._comnUtil.getRxStatusinSignleCharacter(rxItem);
        k["Drug Name"] = (rxItem?.Drug?.drugname?.trim() + " " + rxItem?.Drug?.strength?.trim() + " " + rxItem?.Drug?.drugformname?.trim()).toUpperCase();
        k["DrugClass"] = rxItem?.Drug.drugclass;
        k["IsPicked"] = rxItem.pickupRx ? rxItem.pickupRx.IsPickedUp === true ? true : false : false;
        k["Days Supply"] = Math.round(rxItem.PrescReFill?.SupplyDays);
        k["Sig"] = rxItem.SigCodes && rxItem.SigCodes.Description ? rxItem.SigCodes.Description.toUpperCase() : "";
        k["Fill Date"] = moment.utc(rxItem.PrescReFill.FillDtTm).local().format(
            "MM/DD/YYYY hh:mm:ss a"
        );
        k["Refills Left"] = rxItem.PrescReFill.OrdQty ?  this.integerFloatFormat.transform(qtyModel?.TotalDispensedQty/rxItem.PrescReFill.OrdQty) : 0;
        k["Patient Name"] =
        (rxItem.Patient?.lastname &&  rxItem.Patient?.firstname) ?  (rxItem.Patient?.lastname + ", " + rxItem.Patient?.firstname).toUpperCase() : '';
        const originN = this.systemData.RxOrigin.find(v => v["Id"] === rxItem.PrescReFill.RxOriginId);
        k["Rx Origin"] = originN ? originN["Name"] : "";
        k["Sig"] = rxItem.SigCodes ? rxItem.SigCodes.SigCode : "";
        k["Prescriber Name"] = rxItem.Prescriber ? (
          rxItem.Prescriber.prescriberlastname + ", " + rxItem.Prescriber.prescriberfirstname).toUpperCase() : null;
        k["License#"] = k["License#"] = rxItem.Prescriber?.prescriberlicensenumber ? rxItem.Prescriber?.prescriberlicensenumber : "";
        k["DEA#"] = rxItem.Prescriber?.prescriberdeanum ? this._formatsUtil.getDeaFormat(rxItem.Prescriber.prescriberdeanum) : "";
        k["NPI#"] = rxItem.Prescriber?.prescribernpinum ? this._formatsUtil.getDeaFormat(rxItem.Prescriber.prescribernpinum) : "";
        k['PrescriberInfo'] = rxItem?.Prescriber?.PrescriberAddresses?.find((item: any) => item.Id === rxItem?.Prescriber?.primeAddressId);
        k["PrescriberAddress"] = this._patientUtil.getPatientAddress({
            address1: rxItem.Prescriber?.prescriberaddress1,
            address2: rxItem.Prescriber?.prescriberaddress2,
            city: rxItem.Prescriber?.prescribercity,
            state: rxItem.Prescriber?.prescriberstate,
            zipcode: rxItem.Prescriber?.prescriberzipcode,

        });
        k["T.Rx Qty Rem"] =  this._formatsUtil.threeFixed(qtyModel?.TotalDispensedQty);
        mapDataSet.push(k); 
        });
        if( type === 'TransferableRx') {
           this.transferrableWJ   = new CollectionView(mapDataSet, {
            sortComparer: (a: any, b: any) => {
                return moment(a).isValid() && moment(b).isValid() && (typeof(a) == 'string') && (typeof(b) == 'string') 
                    ? moment(a).diff(moment(b))
                    : null;
            }
        });
        } else {
        this.nonTransferrableWJ  = new CollectionView(mapDataSet, {
            sortComparer: (a: any, b: any) => {
                return moment(a).isValid() && moment(b).isValid() && (typeof(a) == 'string') && (typeof(b) == 'string') 
                    ? moment(a).diff(moment(b))
                    : null;
            }
        });
        }
        
        return mapDataSet;
    }
    toggleColorLegend() {
      this._alertServ.showHelpText({ RxStatus: true, DrugName: true, Status: true });
  }

  selectedItems(checkedVal) {
    if(!checkedVal.IsSelected) { // selected Item
        this.selectedItemList.push(checkedVal.totalItem);
    } else {
        const index =  this.selectedItemList.filter((item: any) => { return item.index === checkedVal.index; });
        this.selectedItemList.splice(index,1);
    }
    this.emitRxSelection.emit(this.selectedItemList);
  }

  getDetails() {
    this._eRxService.getAwaitErx(this.currentDetails.ID, this.currentDetails.Version, this.currentDetails.ErxOperationId, this.currentDetails?.PatientId).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
        if (resp) {
            this.awaitInfo = resp;
            this.IseRxTRxREQ = true;
        }
    });
 }

 showFullScreen() {
    this.showFullscreenMode.emit(this.selectedItemList);
 }

 closeRxPopup() {
    this._storageService.removedSessionStorage("FullScreen");
    console.log(this.selectedItemList, "Dfdf");
      if(this.modelRef) {
        this.modelRef.close();
        this.modelRef = null;
      }
 }

  itemFormatter = (panel, r, c, cell) => {
          const row = panel.rows[r];
          if (row && row.dataItem) {
              cell.style.background = "";
              if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                  const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(row.dataItem["totalItem"], "RxObject", "Rx");
                  if (colors && colors.bg && colors.color) {
                      cell.style.background = colors.bg;
                      cell.style.color = colors.color;
                  } else {
                      cell.style.background = "#FFF";
                      cell.style.color = "#000";
                  }
                  cell.style.fontWeight = "bold";
                  cell.style.paddingLeft = "1rem";
              }  else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Status") {
                              const colors = this._colorCode.showRxStatusColorRefactoring(row.dataItem);
                              if(colors && colors.bg && colors.color) {
                                  cell.style.background = colors.bg;
                                  cell.style.color = colors.color;
                              } else {
                                  cell.style.background = "#FFF";
                                  cell.style.color = "#000";
                              }
                              cell.style.fontWeight = "bold";
                              cell.style.paddingLeft = "1rem";
            }  else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                  const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                  if(colors && colors.bg && colors.color) {
                      cell.style.background = colors.bg;
                      cell.style.color = colors.color;
                  } else {
                      cell.style.background = "#FFF";
                      cell.style.color = "#000";
                  }
                  cell.style.fontWeight = "bold";
                  cell.style.paddingLeft = "1rem";
              }    else {
                  cell.style.backgroundColor = "#FFF";
                  cell.style.color = "#000";
                  cell.style.fontWeight = "normal";
                  cell.style.textAlign = "initial";
              }
          }
      };
  
    init(flex: wjcGrid.FlexGrid) {
        //flex.columnHeaders.rows[0].wordWrap = true;
            flex.autoSizeRow(0, true);
    }
}
