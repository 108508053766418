
<div class="exprx--common-block erx--body insurance--body patient-edit--transfer-in my-0">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        Transfer Request Details
    </div>
    <div
    class="eprx--block__content px-0 pt-0" style="height: 8rem;">
    <div class="row offset-4 col-4  offset-4 mt-2" *ngIf="transferTypedeatails === 'All'">
        <div class="col-12 text-center">
            <img src="/assets/All.svg">
        </div>
        <div class="col-12 text-center mt-2">
            Requesting all transferable Rx(s).
        </div>
       
    </div>
    <div *ngIf="transferTypedeatails && transferTypedeatails !== 'All'" class="rx-transfer-in ">
        <wj-flex-grid 
    #TransferRequestedDetails
     [headersVisibility]="'Column'" 
      [itemsSource]="searchRequestedList"
      [isReadOnly]="true"
      id="requestRequested"
        [selectionMode]="'Row'"
         [allowSorting]="true"
        [ngClass]="['wjm-grd-custom-height-transfer-rx', !totalCount ? 'no-data':'']">
          <wj-flex-grid-column
         [header]="rxheader['hName']"
         [binding]="rxheader['hName']"
         [visible]="rxheader['isVisible']"
         [width]="rxheader['width']"
          *ngFor="let rxheader of wjHeaders; let i = index">
            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                <div  title = {{item[rxheader.hName]}}>
                    {{ item[rxheader['hName']] }}
                </div>
            </ng-template>
            <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                <div title={{rxheader.hName}}>
                    {{ rxheader.hName }}
                </div>
            </ng-template>
        </wj-flex-grid-column>
        <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
    </wj-flex-grid>
    </div>
    </div>
</div>