import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild} from "@angular/core";
import { InsuTabEnum, InsuHeaderTitles, InsuPlanOtherDef, InsuPlanPriceDef } from "./insurance.constants";
import { CommonService, InsuranceService, AlertService, NotesLogService } from "src/app/services";
import { InsuranceUtil, ValidateFormFieldsUtils } from "src/app/utils";
import { FormGroup } from "@angular/forms";
import { InsuranceStore } from "src/app/store";
import { InsuranceSettings, InsuTransOther, SearchInsurance } from "src/app/models";
import { MsgConfig } from "src/app/app.messages";
import { forkJoin } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-insurance-ref",
    templateUrl: "./insurance-ref.component.html"
})
export class InsuranceRefComponent implements OnInit {
    currTab: any = "PLANINFO";
    insuHeaders = InsuHeaderTitles;

    @Input() insuId: any;
    @Input() openMMS: boolean;
    @Input() insuDataFrmEligibilty: any;
    @Input() addPlanFG: FormGroup;
    @Input() SkipUpdateBS: boolean;
    @Input() selectedRow: any;
    @Output() CloseInsuModal = new EventEmitter<any>();

    systemData$: any;
    planInfo$: any;

    originalData: any;
    stngOriginalData: InsuranceSettings;
    transOriginalData: InsuTransOther;
    billOriginalData: any;

    insuStngInfo: InsuranceSettings;
    insuTransInfo: InsuTransOther;
    dropDownData: any;
    tabEnum = InsuTabEnum;
    insuBillInfo: any;
    popUpType: string;
    planInfoFG: FormGroup;
    stngFG: FormGroup;
    transStngFG: FormGroup;
    insuBillFG: FormGroup;
    formGroupInvalid: boolean;
    resetToFirst = false;
    insuDataFmCopy: boolean;

    @ViewChild("UNIQUEINPLANCODE", { static: true })
    UNIQUEINPLANCODE: any;
    isValueChanged: boolean = false;

    constructor(private _commonService: CommonService, private _insuUtils: InsuranceUtil,
        private _alrtsvc: AlertService, private _notesLogSvc: NotesLogService,
        private _insuStore: InsuranceStore, private _insuSvc: InsuranceService, private _renderer: Renderer2,
        private _formUtils: ValidateFormFieldsUtils, private _modalSvc: NgbModal) {
        this.systemData$ = this._commonService.systemData$;
        if (!this.addPlanFG) {
            this.planInfoFG = this._insuUtils.createInsuFG();
        } else {
            this.planInfoFG = this.addPlanFG;
        }
        this.stngFG = this._insuUtils.createInsuStngFG();
        this.transStngFG = this._insuUtils.createTransStngFG();
        this.insuBillFG = this._insuUtils.createInsuBillFG();
        this.makeFGPristine();
    }

    makeFGPristine() {
        this.planInfoFG.markAsPristine();
        this.stngFG.markAsPristine();
        this.transStngFG.markAsPristine();
        this.insuBillFG.markAsPristine();
    }

    ngOnInit() {
        this.getDropDownData();
        if (this.insuId) {
            this.getPlanInfo();
        } else {
            if (this.insuDataFrmEligibilty && this.insuDataFrmEligibilty.length > 0) {
                this.patDataFrmEligibility();
            }
            this._insuUtils.patchPlanInfoDefVal(this.planInfoFG);
            this._insuStore.storeInsuData(Object.assign({}, this.planInfoFG.getRawValue()));
            this.planInfo$ = this._insuStore.insuInfo$;
            this.currTab = "PLANINFO";
            this.planInfoFG.markAsPristine();
            this.isValueChanged = false;
            this.planInfoFG.valueChanges.subscribe(value => {
                if (this.planInfoFG.dirty) {
                    this.isValueChanged = true;
                } else {
                    this.isValueChanged = false;
                }
            });
        }
    }

    patDataFrmEligibility() {
        this.planInfoFG.controls.InsuCarrier.patchValue({
            // InsurerCode: this.insuDataFrmEligibilty[4],
            BINNum: this.insuDataFrmEligibilty[0]
        });
        this.planInfoFG.controls.InsuPlan.patchValue({
            ProcessorNum: this.insuDataFrmEligibilty[2]
        });
    }

    async getDropDownData() {
        this.dropDownData = await this._insuUtils.getDropDownData();
    }

    async getPlanInfo(type?: any,selectTab?:boolean) {
        this.originalData = await this._insuUtils.getInsuranceInfo(this.insuId);
        this._insuStore.storeInsuData(Object.assign({}, this.originalData));
        this.planInfo$ = this._insuStore.insuInfo$;
        this._insuUtils.patchPlanInfo(this.planInfo$["source"]["value"], this.planInfoFG);
        this.planInfoFG.markAsPristine();
        this.isValueChanged = false;
        this.planInfoFG.valueChanges.subscribe(value => {
            if (this.planInfoFG.dirty) {
                this.isValueChanged = true;
            } else {
                this.isValueChanged = false;
            }
        });
        if (!type) {
            this.currTab = "PLANINFO";
        } else  if (!selectTab) {
            this.selectedTab(type);
        }
    }

    async getInsuStngInfo(frmCopy?: any) {
        if (!this.stngOriginalData) {
            this.stngOriginalData = await this._insuUtils.getInsuStngInfo(this.insuId);
            this._insuSvc.saveOldInsuSettings(this.stngOriginalData);
            if (!this.stngOriginalData) {
                this.stngOriginalData = {"InsuPlanOther" : Object.assign({}, InsuPlanOtherDef),
                 "InsuPlanPrice": Object.assign({}, InsuPlanPriceDef)};
            } else if (this.stngOriginalData) {
                if (!this.stngOriginalData["InsuPlanOther"]) {
                    this.stngOriginalData["InsuPlanOther"] = Object.assign({}, InsuPlanOtherDef);
                }
                if (!this.stngOriginalData["InsuPlanPrice"]) {
                    this.stngOriginalData["InsuPlanPrice"] = Object.assign({}, InsuPlanPriceDef);
                }
            }
            this.insuStngInfo = Object.assign({}, this.stngOriginalData);
            this._insuUtils.patchInsuStngsInfo((this.insuStngInfo &&
                (this.insuStngInfo["InsuPlanOther"] || this.insuStngInfo["InsuPlanPrice"])
            ? this.insuStngInfo : this.stngFG.value), this.stngFG);
            this.stngFG.markAsPristine();
        }
        if (!frmCopy) {
            this.currTab = "SETTINGS";
        }
    }

    async getTransStngInfo(frmCopy?: any) {
        if (!this.transOriginalData) {
            this.transOriginalData = await this._insuUtils.getTransStngInfo(this.insuId);
            this.insuTransInfo = Object.assign({}, this.transOriginalData);
            this._insuUtils.patchTransStngInfo(this.insuTransInfo, this.transStngFG);
            this.transStngFG.markAsPristine();
        }
        if (!frmCopy) {
        this.currTab = "TRANSSTNGS";
        }
    }

    async getInsuBillInfo() {
        if (!this.billOriginalData) {
            this.billOriginalData = await this._insuUtils.getInsuBillInfo(this.insuId);
            this.billOriginalData  = this.billOriginalData ? this.billOriginalData : this.insuBillFG.value;
            this.billOriginalData["InsuPlanId"] = this.insuId;
            this.insuBillInfo = Object.assign({}, this.billOriginalData);
            this._insuUtils.patchInsuBillInfo(this.insuBillInfo, this.insuBillFG);
            this.insuBillFG.markAsPristine();
        }
        this.currTab = "INSUBILLSTNGS";
    }

    onCopyInsu() {
        if(this.currTab == "TRANSSTNGS"){
            this.transStngFG.markAsDirty();
        }
        if (this.insuId) {
            this.getInsuStngInfo(true);
            this.getTransStngInfo(true);
        } else {
            this.insuDataFmCopy = true;
        }
    }

    selectedTab(name) {
        if (name === "Patient2") {
            this.getInsuStngInfo();
        } else if (name === "Patient3") {
            this.getTransStngInfo();
        } else if (name === "Patient8") {
            this.getInsuBillInfo();
        } else {
            this.currTab = InsuTabEnum[name];
        }
    }

    closeInsuModal(type, Id?: any, oldNewInsStngsData?: any) {
        const result = { Type: type, insuId: Id ? Id : this.insuId, "oldNewdata": oldNewInsStngsData };
        this.CloseInsuModal.emit(result);
    }

    onCancel(value) {
        this._commonService.storeOverridePrivNotes(null);
        if(this.isValueChanged){
            this.popUpType = value;
            if (value && value !== "cancel") {
                this.closeInsuModal("Cancel");
            }
        } else {
            this.closeInsuModal("Cancel");
        }
    }

    onSaveClick() {
        this._formUtils.blurAndDelay();
        if (this.insuId) {
            this.updateInsurance();
        } else {
            this.addInsurance();
        }
    }

    setInsuStngValue(type?) {
        let result = false;
        const FG = this.stngFG.value;
        const resp = this.stngOriginalData;
        if(type === 'IsOrderToFirstfillchanged') {
            if (resp && resp["InsuPlanOther"] && FG["InsuPlanOther"]["OrderToFillDays"]) {
                if (resp["InsuPlanOther"]["OrderToFillDays"] == FG["InsuPlanOther"]["OrderToFillDays"]) {
                    result = false;
                } else {
                    result = true;
                }
            } else if (!(resp && resp["InsuPlanOther"]) && (FG["InsuPlanOther"]["OrderToFillDays"] &&
            (FG["InsuPlanOther"]["OrderToFillDays"] + "").trim() !== "")) {
                result = true;
            } else {
                result = false;
            }
        } else {
            if (resp && resp["InsuPlanOther"] && FG["InsuPlanOther"]["OrderToLastRefill"]) {
                if (resp["InsuPlanOther"]["OrderToLastRefill"] == FG["InsuPlanOther"]["OrderToLastRefill"]) {
                    result = false;
                } else {
                    result = true;
                }
            } else if (!(resp && resp["InsuPlanOther"]) && (FG["InsuPlanOther"]["OrderToLastRefill"] &&
            (FG["InsuPlanOther"]["OrderToLastRefill"] + "").trim() !== "")) {
                result = true;
            } else {
                result = false;
            }
        }
      
        return result;
    }

    async updateInsurance() {
        if (this.planInfoFG.valid) {
            this.formGroupInvalid = false;
            this.stngFG.controls["InsuPlanPrice"].patchValue({InsuPlanId: this.insuId});
            this.stngFG.controls["InsuPlanOther"].patchValue({InsuPlanId: this.insuId});
            this.stngFG.controls["IsOrderToLastfillchanged"].patchValue(this.setInsuStngValue());
            this.stngFG.controls["IsOrderToFirstfillchanged"].patchValue(this.setInsuStngValue('IsOrderToFirstfillchanged'));
            this.transStngFG.controls["InsuPlanId"].patchValue(this.insuId);
            this.insuBillFG.controls["InsuPlanId"].patchValue(this.insuId);
            const totalFGChanged = {
                planInfo: [this.planInfoFG, this._insuSvc.updateInsurance(this.planInfoFG.getRawValue()), this.originalData],
                stngs: [this.stngFG, this._insuSvc.updateInsSettings(this.stngFG.value), this.stngOriginalData],
                transStng: [this.transStngFG, this._insuSvc.updateTransInsSettings(this.transStngFG.value), this.transOriginalData],
                insuBill: [this.insuBillFG, this._insuSvc.saveInsBilling(this.insuBillFG.value, this.insuId), this.billOriginalData]
            };
            this._insuSvc.saveInsuSettings(this.stngFG.value);
            const updateServices = [];
            const updateSeq = [];
            Object.keys(totalFGChanged).map(tabName => {
                if (totalFGChanged[tabName][0].dirty) {
                    updateServices.push(totalFGChanged[tabName][1]);
                    updateSeq.push(tabName);
                }
            });
            if (updateServices.length > 0) {
                const resp = await this._insuUtils.updateInsuranceInfo(updateServices);
                if (resp && resp.findIndex(val => val <= 0) === -1) {
                    this._alrtsvc.success(MsgConfig.UPDATE_SUCCESS);
                    this._insuUtils.callAuditMethod(updateSeq, totalFGChanged, this.insuId, this.dropDownData);
                    if (!this.SkipUpdateBS && (this.currTab === 'PLANINFO')) {
                        this.getInsusUpdateObserv();
                    }
                    const oldNewInsStngsData = [this.stngOriginalData, this.stngFG.value];
                    this.closeInsuModal("Update", this.insuId, oldNewInsStngsData);
                } else {
                    this._alrtsvc.error(MsgConfig.UPDATE_FAIL);
                    this.closeInsuModal("Update", this.insuId);
                }
            } else {
                this.closeInsuModal("Update", this.insuId);
            }
        } else {
            this.formGroupInvalid = true;
            this.resetCurrTab();
        }
    }

    resetCurrTab() {
        this.resetToFirst = false;
        setTimeout(() => {
            this.resetToFirst = true;
        }, 100);
    }

    async addInsurance(type?: any) {
        let isExist = null;
        const info = this.planInfoFG.getRawValue();
        isExist = await this._insuSvc.checkIfInsuCodeExists(info["InsuCarrier"]["InsurerCode"]).toPromise();
        if (isExist) {
            this._modalSvc.open(this.UNIQUEINPLANCODE, { keyboard: false, backdrop: false, windowClass: "large--content"});
        } else {
            if (this.planInfoFG.valid) {
                this.formGroupInvalid = false;
                const resp = await this._insuUtils.addinsuranceInfo(this.planInfoFG.getRawValue(), this.dropDownData);
                if (resp === -1) {
                    this.resetCurrTab();
                    this._insuUtils.insuCodeExist(this.planInfoFG);
                    if (this._renderer.selectRootElement("#InsuCodeId")) {
                        this._renderer.selectRootElement("#InsuCodeId").focus();
                    }
                } else if (resp > 0) {
                    this._alrtsvc.success(MsgConfig.SAVE_SUCCESS);
                    if (this._commonService.overridePrivNotes$["source"]["value"] && this._commonService.overridePrivNotes$["source"]["value"].frm == 'Insurance') {
                        this._notesLogSvc.addNotesLog('Insurance',resp, {Note: [this._commonService.overridePrivNotes$["source"]["value"].Note], NoteCat : null}).subscribe(resp => {
                            if (resp) 
                                this._commonService.storeOverridePrivNotes(null);
                        })
                    }
                    if (this.insuDataFmCopy) {
                        this.stngFG.controls["InsuPlanPrice"].patchValue({InsuPlanId : resp});
                        this.stngFG.controls["InsuPlanOther"].patchValue({InsuPlanId : resp});
                        this.stngFG.controls["IsOrderToLastfillchanged"].patchValue(this.setInsuStngValue());
                        this.stngFG.controls["IsOrderToFirstfillchanged"].patchValue(this.setInsuStngValue('IsOrderToFirstfillchanged'));
                        this.transStngFG.controls["InsuPlanId"].patchValue(resp);
                        await forkJoin([this._insuSvc.updateInsSettings(this.stngFG.value),
                            this._insuSvc.updateTransInsSettings(this.transStngFG.value)]).toPromise();
                    }
                    if (!this.SkipUpdateBS &&  (this.currTab === 'PLANINFO')) {
                        this.getInsusUpdateObserv();
                    }
                    if (!type) {
                        this.closeInsuModal("Add", resp);
                    } else {
                        this.insuId = resp;
                        this.getPlanInfo(type);
                    }
                } else {
                    this._alrtsvc.error(MsgConfig.SAVE_FAIL);
                    this.closeInsuModal("Cancel");
                }
            } else {
                this.formGroupInvalid = true;
                this.resetCurrTab();
                if (document.querySelector("input.ng-invalid")) {
                    const element: any = document.querySelector("input.ng-invalid");
                    (<HTMLInputElement>element).focus();
                }
            }
        }
    }

    async resetPlanCode() {
        this.planInfoFG.controls["InsuCarrier"].patchValue({ InsurerCode: null });
        await this.resetCurrTab();
        if (this._renderer.selectRootElement("#InsuCodeId")) {
            this._renderer.selectRootElement("#InsuCodeId").focus();
        }
    }

    async getInsusUpdateObserv() {
        const search = new SearchInsurance();
        const data = await this._commonService.getInsuData(search).toPromise();
        if(data && data["Records"]) {
            this._commonService._insuranceData$.next(data["Records"]);
        }
    }
}
