import { RxUtils, ValidateFormFieldsUtils, NewAuditUtils, WijimoUtil, CommonUtil } from "src/app/utils";
import { InsuCarrier, PatientInfo } from "./../../../models/patient-Info.model";
import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    AfterViewInit,
    OnDestroy,
    HostListener,
    OnChanges,
    SimpleChanges
    } from "@angular/core";
import {
    AdditionalInsurance,
    SystemData,
    InsuCards,
    Insurances,
    InsuGroups,
    PatAuditLog,
    SearchInsurance
} from "../../../models";
import { CollectionView } from "../../../../../node_modules/@grapecity/wijmo";
import { AdditionalInsuranceService } from "src/app/services/additional-insurance.service";
import { CommonService, AlertService, PatientService, InsuranceService } from "src/app/services";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { RegEx } from "src/app/app.regex";
import { Observable, Subject } from "../../../../../node_modules/rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil
} from "../../../../../node_modules/rxjs/operators";
import * as moment from "moment";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { InsuranceRefComponent } from "../../insurance-ref/insurance-ref.component";
import { constants } from "http2";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { take } from "rxjs-compat/operator/take";
import { StorageService } from "src/app/services/storage.service";


@Component({
    selector: "app-additional-insurance",
    templateUrl: "./additional-insurance.component.html"
})
export class PAdditionalInsuranceComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() patientInfo: PatientInfo;
    @Input() patientInfoFG: FormGroup;
    @Input() IsNavigateFromPatientInfoTab: boolean;

    @Input() systemData: SystemData;
    @Input() patientInsurances: any[];
    @Input() insuranceData: any;



    wjHeaders: any;
    patientInsuWJ: CollectionView;
    insuranceFG: FormGroup;
    regex: any;
    billingOptions: any = this.getBillingOptions();
    modelRef: any;
    editMode: boolean;
    rowId: any;
    rowDetails: any;
    filteredGroups: any[] = [];
    IsSubmitted = false;
    showNewGroup = false;
    inputValue = "";
    updateInsu: boolean;
    isGroupRequired = false;
    originalData: any;
    modalRef: any;
    oldInsuModal: any = null;
    wijimoId: wjcGrid.FlexGrid;
    status: number;
    patId: any;
    formGroupInvalid = false;
    isRefreshGrid: boolean = false;
    InsuIdFor340b: any;
    isInputTouched: boolean = true;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    isM3PIns: any;

    @Input()
    set IsNavigateFromPatTabForRefresh(lt: boolean) {
        this.isRefreshGrid = lt;
    }

    @Input() editInsuranceData: any;

    @ViewChild("searchBoxInsType")
    searchBoxInsType: ElementRef;

    @ViewChild("PrimaryInsuranceDelete", { static: true })
    PrimaryInsuranceDelete: ElementRef;

    @ViewChild("addInsurance", { static: true }) editInsur: ElementRef;

    @ViewChild("setInsuranceStatusContent", { static: true })
    setInsuranceStatusContent: ElementRef;

    @ViewChild("instance")
    instance: NgbTypeahead;

    @ViewChild("InsuList")
    flex: wjcGrid.FlexGrid;

    @ViewChild("InsuranceNotFound")
    InsuranceNotFound: ElementRef;

    insuName: any;
    policyRequired: boolean;
    today: string;
    minDate: { year: number; month: number; day: number; };
    focusField: any;

    get Insurance(): FormGroup {
        return this.insuranceFG.get("Insurance") as FormGroup;
    }

    get InsuCard(): FormGroup {
        return this.insuranceFG.get("InsuCard") as FormGroup;
    }

    get InsuCarrier(): FormGroup {
        return this.insuranceFG.get("InsuCarrier") as FormGroup;
    }

    get InsuranceGroup(): FormGroup {
        return this.insuranceFG.get("Insgroup") as FormGroup;
    }

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event && event.altKey && !this.modalService.hasOpenModals()) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.Cancel();
            }
        } else if (event && event.which === 27 && this.modalService.hasOpenModals()) { // esc
                if (this.modelRef) {
                this.modelRef.close();
                event.preventDefault();
            }
        }
    }

    constructor(
        private _additionalInsu: AdditionalInsuranceService,
        private _commonServ: CommonService,
        private _fb: FormBuilder,
        private modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private _newAuditUtil: NewAuditUtils,
        private _patientService: PatientService,
        private _validUtils: ValidateFormFieldsUtils,
        private _patUtils: PatientInfoUtil,
        private _insuServ: InsuranceService,
        private _rxUtils:RxUtils,
        private _wijimoUtils: WijimoUtil,
        private _dateUtil: DateCheckUtil,
        public _storageService: StorageService
    ) {
        this.createFG();
        this.regex = RegEx;
        this.today = moment().format("MM/DD/YYYY");
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
    }

    ngOnInit() {
        if (this.patientInfo && this.patientInfo.Patient) {
            this.patId = this.patientInfo.Patient.Id;
        }
        this.InsuIdFor340b = +this._commonServ.getSetttingValue("ThreeFourtyBSettings", "Ins_Code_340BSettings");
        if (this.patientInsurances && this.patientInsurances.length) {
            this.getPatientInsurancesWJ(this.patientInsurances);
        }
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "patAdditionalInsuListWJ") {
                this.patchDefaultValHeaders();
            }
        });
        if(this.IsNavigateFromPatientInfoTab) {
          const Insu340B: any  = this._patUtils.get340BInsurance(this.patientInfo);
          if(Insu340B)
               this.selectedInsType(Insu340B,"Frm340B", this.insuranceFG.controls["Insurance"]);
          this.insuName = Insu340B.InsuranceName;
          this.inputValue = Insu340B.InsurerCode ? Insu340B.InsurerCode.trim() : Insu340B.InsurerCode;
          this.modelRef = this.modalService.open(this.editInsur, { size: "lg" , centered: true, backdrop: false, keyboard: false });
        }
        this.getpatientInsuList();
        if (this.editInsuranceData) {
            this.OpenEditInsu(this.editInsur, null, null, this.editInsuranceData)
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("patAdditionalInsuListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("patAdditionalInsuListWJ", storedWJ);
    }
    ngAfterViewInit() {
        this._cdr.detectChanges();
    }
    ngOnDestroy() {
        this._storageService.getWijmoGridDataInSession(this.wjHeaders,"patAdditionalInsuListWJ")
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    ngOnChanges(changes: SimpleChanges) {

    }

    createFG() {
        this.insuranceFG = this._fb.group({
            patientid: this.patId,
            IsDef340BInsu : false,
            Insurance: this._fb.group(new Insurances()),
            InsuCard: this._fb.group(new InsuCards()),
            Insgroup: this._fb.group(new InsuGroups()),
            InsuCarrier: this._fb.group(new InsuCarrier())
        });
    }

    setDefaultValues() {
        this.Insurance.controls["CPBrandVal"].setValue(0);
        this.Insurance.controls["CPGenericVal"].setValue("0");
        this.Insurance.controls["RelCatId"].setValue(2);
        this.Insurance.controls["CoPayCatId"].setValue(1);
        this.Insurance.controls["PerNum"].setValue("01");
        this.InsuCard.controls["ExpiryDt"].setValue("12/31/2099");
        this.Insurance.controls["InsuPriId"].setValue(null);
        this.Insurance.controls["IsPatAssigned"].setValue(true);
        this.Insurance.controls["ActiveStatusId"].setValue(1);
        this.Insurance.controls["Remarks"].setValue("");
        this.InsuCard.controls["LastName"].setValue("");
        this.InsuCard.controls["FirstName"].setValue("");
        this.InsuCard.controls["IsActive"].setValue(true);
        this.Insurance.controls["ModifiedDtTm"].setValue(
            moment(Date.now()).format("MM/DD/YYYY")
        );
        this.Insurance.controls["CreatedDtTm"].setValue(
            moment(Date.now()).format("MM/DD/YYYY")
        );
        this.insuranceFG.controls.patientid.setValue(this.patId);
        this.InsuCard.controls.ExpiryDt.setValue("12/31/2099");
    }

    async getpatientInsuList(latestPatInfo?: any) {
        const patientInsurances = await this._patUtils.getPatientInsurances(this.patId, this.patientInfo, latestPatInfo);
        if (patientInsurances && patientInsurances.length) {
            this.getPatientInsurancesWJ(patientInsurances);
        } else {
            this.isDataExists = false;
            this.patientInsuWJ = new CollectionView(null)
        }
    }

    selectedPriority(selectedInsu) {
        if (selectedInsu && selectedInsu.Id !== 0 && (!this.editMode ||
                (this.editMode && this.oldInsuModal && selectedInsu && selectedInsu.Id !== this.oldInsuModal.PriorityId))) {
            this._additionalInsu.checkIfInsuPriorityExists(this.patId, selectedInsu.Id).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (!resp) {
                    this.Insurance.controls.InsuPriId.patchValue(null);
                    this._alertService.error("Insurance exists with selected priority.");
                }
            });
        } else if (!selectedInsu) {
            this.Insurance.controls.InsuPriId.patchValue(null);
        }
    }

    addInsu() {
        this.IsSubmitted = true;
        //this.checkForInsuranceDuplication("add");
        this.callSaveOrUpdate("add");
    }

    checkFGValid(type) {
        const addInsFG = this._fb.group({
            ExpiryDt: this.InsuCard.controls["ExpiryDt"].value
        });
        const dateChecks = [{
            control: "ExpiryDt",
            minDate: this.minDate,
            maxDate: "",
            isRequired: true
        }];
         const dateCheckFields: any = this._dateUtil.checkDateValidationAtTimeofSave(addInsFG, dateChecks);
            if (dateCheckFields) {
                return;
            } else if (this.insuranceFG.value["Insurance"]["InsurerId"] &&  this.InsuCard.value["ExpiryDt"] && (!this.policyRequired ||
        (this.policyRequired && this.InsuCard.value["PolicyNum"]  && this.InsuCard.value["PolicyNum"].trim() !== ""))) {
            this.formGroupInvalid = false;
            if (type === "add") {
                this.addInsu();
            } else {
                this.updatePatientInsurance();
            }
        } else {
            this.formGroupInvalid = true;
            this._validUtils.validateAllFormFields(this.insuranceFG);
           // this._alertService.error("Please enter required fields.");
        }
        this.isM3PIns = null;
    }

    addPatientInsurance() {
        const additionalModel = new PatAuditLog().additionalModel;
        if (this.insuranceFG.valid) {
            if (this.showNewGroup) {
                this.Insurance.controls.InsuGrpId.patchValue(null);
            }
            const insuranceData = this.insuranceFG.value;
            this._additionalInsu
                .addPatientInsurance(insuranceData).pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if(this.modelRef) {
                        this.modelRef.close();
                          this.modelRef = null;
                    }
                    const dropDownsData = {"billingOptions": this.billingOptions};
                    if (resp) {
                        this._newAuditUtil.saveAuditChange(
                            null,
                            this.insuranceFG.value,
                            "AdditionalInsurance",
                            "Patient",
                            this.patId,
                            additionalModel,
                            false,
                            dropDownsData
                            );
                        this._alertService.success("Insurance added successfully.");
                        this.getpatientInsuList();
                    } else {
                        this._alertService.error("Insurance add unsuccessful.");
                    }
                });
        } else {
            this._validUtils.validateAllFormFields(this.insuranceFG);
            // this._alertService.error("Please enter required fields.");
        }
    }

    Init(content,s) {
        s?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                this.oldInsuModal = null;
                this.insuranceFG.reset();
                this.editMode = true;
                this.rowId = null;
                if (e.which !== 13) {
                    s.hitTest(e);
                }
                const info = s.collectionView["_src"][s.selection.row]
                this.rowId = info["Row Id"];
                const insuModel: any = this.patientInsurances[this.rowId];
                this.oldInsuModal = insuModel;
                this.onEditInsurancePatchAndOpenModal(insuModel);
                this.modelRef = this.modalService.open(content, { size: "lg", centered: true, backdrop: false, keyboard: false });
            }
        });
    }

    OpenEditInsu(content, s: any, e: any, insuData?) {
        if (!insuData)
            e.preventDefault();
        this.oldInsuModal = null;
        this.insuranceFG.reset();
        this.editMode = true;

        if (!insuData) {
            this.rowId = null;
            if (e.which !== 13) {
            s.hitTest(e);
            }
            const info = s.selectedRows[0].dataItem;
            this.rowId = info["Row Id"];
        }
        const insuModel: any = insuData ? insuData : this.patientInsurances[this.rowId];
        this.oldInsuModal = insuModel;
        this.onEditInsurancePatchAndOpenModal(insuModel);
        this.modelRef = this.modalService.open(content, { size: "lg", centered: true, backdrop: false, keyboard: false });
        this.editInsuranceData = null
    }

    onEditInsurancePatchAndOpenModal(insuModel) {
        this.insuranceFG.patchValue({
            patientid: this.patId,
            Insurance: {
                Id: insuModel.InsuranceId,
                InsurerId: insuModel.InsurerId,
                InsuGrpId: insuModel.InsGroupId,
                RelCatId: insuModel.RelcatId,
                Relation: insuModel.RelationName,
                InsuCardId: insuModel.InsucardId,
                InsuPriId: insuModel.PriorityId,
                CoPayCatId: insuModel.CoPayCatId,
                CPBrandVal: insuModel.CpbrandVal,
                CPGenericVal: insuModel.CpgenericVal,
                PerNum: insuModel.PerNum,
                Remarks: insuModel.Remarks,
                IsPatAssigned: insuModel.IsPatAssigned,
                IdProofId: insuModel.IdproofId,
                ActiveStatusId: insuModel.IsActive === 1 ? 1 : 2,
                CreatedDtTm: moment(Date.now()).format("MM/DD/YYYY"),
                ModifiedDtTm: moment(Date.now()).format("MM/DD/YYYY")
            },
            InsuCard: {
                Id: insuModel.InsucardId,
                PolicyNum: insuModel.PolicyNum,
                FirstName: insuModel.FirstName,
                LastName: insuModel.LastName,
                ExpiryDt: insuModel.CardExpiry ? moment.utc(insuModel.CardExpiry).local().format("MM/DD/YYYY") : "",
                IsActive: true
            },
            InsuCarrier: {
                InsurerCode: insuModel.InsurerCode
            },
            Insgroup: {
                Id: insuModel.InsGroupId,
                Name: insuModel.GroupName
            }
        });
        this.isM3PIns = insuModel.ParentInsuId;
        this.originalData = this.insuranceFG.value;
        // const insurance = _.filter(this.insuranceData, {
        //     Id: insuModel.InsuranceId
        // });
        this.insuName = insuModel.InsurerName;
        this.inputValue = insuModel.InsurerCode ? insuModel.InsurerCode.trim() : insuModel.InsurerCode;
        if (this.insuranceData && this.insuranceData.length > 0) {
            const insurance = this.insuranceData.find(d => d["InsuCarrierId"] === +this.insuranceFG.value["Insurance"]["InsurerId"]);
            if (insurance) {
                this.checkIsPolicyRequired(insurance);
            }
        }
        this.filterGroupsBasedOnInsurerId(insuModel.InsurerId);
    }

    deleteInsuranceformList(content, s: any, e: any) {
        this.rowId = null;
        const hit = s.hitTest(e);
        this.rowDetails = s.selectedRows[0].dataItem;
        this.rowId = this.rowDetails["Row Id"];
        if (this.rowDetails.Priority === "Primary") {
            this.modelRef = this.modalService.open(this.PrimaryInsuranceDelete, {centered: true, backdrop: false, keyboard: false,
                 windowClass: "large--content"});
        } else {
            // tslint:disable-next-line:max-line-length
            this._additionalInsu.getPatAddInsuranceDeleteResp(this.patientInsurances[this.rowId].InsuranceId).pipe(takeUntil(this.unsubscribe$)).subscribe((deleteresp: any) => {
                if (deleteresp) {
                    if (deleteresp === -2) {
                        this._alertService.error("Selected Insurance is already associated with the Patient Rx and cannot be deleted");
                    } else if (deleteresp === -3) {
                        this._alertService.error("You cannot delete Cash Insurance");
                    } else {
                        this.modelRef = this.modalService.open(content, { centered: true, backdrop: false, keyboard: false,
                            windowClass: "large--content" });
                    }
                }
            });
        }
    }

    getRowDetails(content, s: any, e: any) {
        this.rowId = null;
        const hit = s.hitTest(e);
        this.rowDetails = s.selectedRows[0].dataItem;
        this.rowId = this.rowDetails["Row Id"];
        this.modelRef = this.modalService.open(content, { centered: true, backdrop: false, keyboard: false,
            windowClass: "large--content" });
    }

    deleteInsurance() {
        if(this.modelRef) {
            this.modelRef.close();
        }
        this._additionalInsu
            .deletePatientInsurance(
                this.patientInsurances[this.rowId].PatInsId,
                this.patientInsurances[this.rowId].InsuranceId,
                this.patId
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    if (resp !== -2 || resp !== -3) {
                        const orgData = [{name: "Active Status", value: this.patientInsurances[this.rowId].IsActive === 1 ?
                        this.patientInsurances[this.rowId].InsurerCode + " - 1" : this.patientInsurances[this.rowId].InsurerCode + " - 2"}];
                        const chngData = [{ name: "Active Status", value: this.patientInsurances[this.rowId].InsurerCode + " - 3" }];
                        this._newAuditUtil.SaveAuditOnDelete(
                            orgData,
                            chngData,
                            "AdditionalInsurance",
                            "Patient",
                            this.patId);
                        this.getpatientInsuList();
                        this._alertService.success("Insurance deleted successfully.");
                    }
            }
            });
    }

    updatePatientInsurance() {
       // this.checkForInsuranceDuplication("update");
        this.callSaveOrUpdate("update");
    }

    updateInsurance() {
        if (this.insuranceFG.valid) {
            this._additionalInsu
            .updatePatientInsurance(this.insuranceFG.value).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if(this.modelRef) {
                    this.modelRef.close();
                      this.modelRef = null;
                }
                if (resp === 1) {
                    const additionalModel = new PatAuditLog().additionalModel;
                    const dropDownsData = {"billingOptions": this.billingOptions};
                    this._newAuditUtil.saveAuditChange(
                        this.originalData,
                        this.insuranceFG.value,
                        "AdditionalInsurance",
                        "Patient",
                        this.patId,
                        additionalModel,
                        false,
                        dropDownsData,
                        "Edit Patient Insurance"
                    );
                    this.getpatientInsuList();
                    this._alertService.success(
                        "Insurance updated successfully."
                    );
                } else if (resp === 2) {
                    this._alertService.error(
                        "Selected Insurance is already associated with the Patient Rx and Insurance type cannot be changed"
                    );
                } else if (resp === 0) {
                    this._alertService.error(
                        "Insurance update failed"
                    );
                } else if (resp === 3) {
                    this._alertService.error(
                        "Insurance update failed, atleast one insurance should be active  for the patient."
                    );
                }
            });
        } else {
            this._validUtils.validateAllFormFields(this.insuranceFG);
            // this._alertService.error("Please enter required fields.");
        }
    }

    async setPatientPrimaryInsurance() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        const originalPatInfo = Object.assign({}, this.patientInfoFG.getRawValue());
        await this._additionalInsu.setPrimaryInsurance(this.patId,
            this.patientInsurances[this.rowId].InsuranceId).toPromise();
        const patInfo = await this._patientService.getEditPatientInformation(this.patId).toPromise();
        this.patientInfoFG.patchValue({
            Insurances: (patInfo && patInfo.Insurances) ? patInfo.Insurances : this.patientInfoFG.value["Insurances"],
            InsuCarrier: (patInfo && patInfo.InsuCarrier) ? patInfo.InsuCarrier : this.patientInfoFG.value["InsuCarrier"],
            InsuCards: (patInfo && patInfo.InsuCards) ? patInfo.InsuCards : this.patientInfoFG.value["InsuCards"]
        });
        if (patInfo && patInfo.InsuGroups) {
            this.patientInfoFG.patchValue({InsuGroups: patInfo.InsuGroups});
        } else {
            this.patientInfoFG.controls["InsuGroups"].reset();
        }
        this.patientInfoFG.controls["InsuCards"].patchValue({ExpiryDt:
            moment(this.patientInfoFG.value["InsuCards"]["ExpiryDt"]).format("MM/DD/YYYY")});
        if(this.insuranceData && this.insuranceData.length){
            const insurance = this.insuranceData.find(d => d["InsuCarrierId"] === +this.patientInfoFG.value["Insurances"]["InsurerId"]);
            if (insurance) {
                this.checkIsPolicyRequired(insurance);
            }
        }
        this.getpatientInsuList(patInfo);
        this._alertService.success("Primary Insurance has been set.");
        const prescDataModel = new PatAuditLog().patAuditModel;
            this._newAuditUtil.saveAuditChange(
                originalPatInfo,
                this.patientInfoFG.getRawValue(),
                "EditPatient",
                "Patient",
                this.patId,
                prescDataModel
        );
    }

    checkIsPolicyRequired(val) {
        const insuCardsFG: any = this.patientInfoFG.controls["InsuCards"];
        if (insuCardsFG.value["PolicyNum"] === "" || insuCardsFG.value["PolicyNum"] === " ") {
            insuCardsFG.controls["PolicyNum"].setValue(null);
        }
        if (val.SubmissionType === "9" || !val.SubmissionType) {
            this.policyRequired = true;
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.required]);
            insuCardsFG.controls["PolicyNum"].markAsUntouched();
        } else {
            this.policyRequired = false;
            insuCardsFG.controls["PolicyNum"].setValidators([Validators.nullValidator]);
            insuCardsFG.controls["PolicyNum"].setErrors(null);
            setTimeout(() => {
                insuCardsFG.controls["PolicyNum"].markAsUntouched();
            }, 10);
        }
    }


    async setInsuranceStatus(status: number) {
        // this.modelRefSM.close();
        const resp = await this._additionalInsu.insuranceStatusSet(
                this.patientInsurances[this.rowId].InsuranceId, status).toPromise();
        if(resp == 2){
            this._alertService.error("Insurance Status Change Failed, atleast one insurance should be active  for the patient.");
        }
        else if(resp == 1){
            const orgData = [{name: "Active Status", value: this.patientInsurances[this.rowId].IsActive === 1 ?
            this.patientInsurances[this.rowId].InsurerCode + " - 1" : this.patientInsurances[this.rowId].InsurerCode + " - 2"}];
            const chngData = [{name: "Active Status", value: this.patientInsurances[this.rowId].InsurerCode + " - " + status}];
            this._newAuditUtil.SaveAuditOnDelete(
                orgData,
                chngData,
                "AdditionalInsurance",
                "Patient",
                this.patId);
            this._alertService.success(status === 1 ? "Insurance is set to ACTIVE." : "Insurance is set to INACTIVE.");
            this.getpatientInsuList();
        } else {
            this._alertService.error("Insurance Status Change Failed.");
        }
    }

    getPatientInsurancesWJ(insuList: AdditionalInsurance["PatientInsuList"]) {
        if(insuList.length){
        this.isDataExists = true;
        this.patientInsuWJ = new CollectionView(
            insuList.map((insuModel : any, i) => {
                // tslint:disable-next-line:prefer-const
                let k = {};
                k["Row Id"] = i;
                k["Ins Code"] = insuModel.InsurerCode ? insuModel.InsurerCode.toUpperCase() : "";
                k["Insurer Name"] = insuModel.InsurerName ? insuModel.InsurerName.toUpperCase() : "";
                k["Ins Id"] = k["Ins ID"] = insuModel.PolicyNum;
                k["PC"] = insuModel.PerNum;
                k["Priority"] = insuModel.InsurancePriority;
                k["Group#"] = insuModel.GroupName;
                k["C.H.Last Name"] = insuModel.LastName;
                k["C.H.First Name"] = insuModel.FirstName;
                k["Active for Patient"] = insuModel.IsActive === 1 ? "YES" : "NO";
                k["Bin#"] =  k["BIN"] =insuModel.BinNum;
                k["Comments"] = insuModel.Remarks;
                k["Assig"] = insuModel.IsPatAssigned;
                k["Rel."] = insuModel.RelationName;
                k["Is 340B?"] = (this.InsuIdFor340b === insuModel.InsurerId) ? true : false;
                k["Action"] = "";
                k["InsurerActiveStatus"] = insuModel.InsurerActiveStatus;
                k["ParentInsuId"] = insuModel.ParentInsuId
                return k;
            })
        );
        }
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
             if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Ins Code" && +row.dataItem['ParentInsuId']) {
                cell.style.backgroundColor = "#51a351";
                cell.style.color = "#fff";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => (term.length < 1 ? [] : this.filterByPcnBin(term)))
        )

    filterByPcnBin(term) {
        // const searchTerms = term.split(",");
        const insuranceList = Object.assign([], this.insuranceData);
        let filteredData = this._rxUtils.filterInsurancesForSuggest(insuranceList, term);
        if (!filteredData || (filteredData && filteredData.length === 0)) {
            this.modalRef = this.modalService.open(this.InsuranceNotFound, {centered: true, backdrop: false,
                windowClass: "large--content"});
            this.inputValue = "";
            this._cdr.detectChanges();
            this.inputValue = null;
        }
        return filteredData.splice(0, 20);
    }

    closeInsuNotFound() {
        if (this.modalRef) {
            this.modalRef.close();
        }
        this.inputValue = "";
        this._cdr.detectChanges();
        this.inputValue = null;
    }

    routeToAddInsurance() {
        const modalRefInsu = this.modalService.open(InsuranceRefComponent, {
            backdrop: false,
            size: "lg",
            windowClass: "md-x-lg erx-to-add-modal",
            keyboard: false,
        });
        modalRefInsu.componentInstance.SkipUpdateBS = true;
        modalRefInsu.componentInstance.CloseInsuModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async res => {
            if (res && res["Type"] !== "Cancel") {
                const search = new SearchInsurance();
                const data = await this._commonServ.getInsuData(search).toPromise();
                this._commonServ._insuranceData$.next(data["Records"]);
                if(this.insuranceData && this.insuranceData.length){
                    setTimeout(() => {
                        const insurance = this.insuranceData.find(d => d["Id"] === +res["insuId"]);
                        if (insurance) {
                            this.insuranceFG.controls["Insurance"].patchValue({InsurerId: insurance.InsuCarrierId});
                            this.selectedInsType(insurance, "FrmAdd");
                        }
                    }, 1000);
                }
        }
            modalRefInsu.close();
        });
    }
    onInputKeydown(event: KeyboardEvent) {
        const inputValue = (event.target as HTMLInputElement).value;
        if (event.key === 'Tab' && inputValue.trim() === '') {
            event.preventDefault();
            this.isInputTouched = false;
        }
    }
    onInputFocus() {
        this.isInputTouched = true;
    }
    onInputBlur() {
        this.isInputTouched = false;
    }

    selectedInsType(val, type?: any, insuranceData?: any) {
        const data = type ? val : val.item;
        this.insuName = type ? data.InsuranceName : data.InsurerName;
        if (!(this.inputValue && data && this.inputValue === data.InsurerCode)) {
            this.InsuCard.controls["PolicyNum"].patchValue(null);
            this.InsuCard.controls["ExpiryDt"].setValue("12/31/2099");
        }
        this.insuranceFG.controls["IsDef340BInsu"].patchValue(
            (type && type === "Frm340B") ?  true: false
        );
        if((type && type === "Frm340B"))
                this.insuranceFG.controls["patientid"].patchValue(this.patId);
        this.inputValue = data.InsurerCode ? data.InsurerCode.trim() : data.InsurerCode;
        const newFG: any = this.insuranceFG.controls["Insurance"];
        const newIC: any = this.insuranceFG.controls["InsuCarrier"];
        newFG.controls["InsurerId"].setValue(data.InsuCarrierId);
        newFG.controls["InsuGrpId"].setValue(null);
        newIC.controls["InsurerCode"].setValue(data.InsurerCode);
        this.isInsuranceExist(this.patId, data.InsuCarrierId, data.Id, newFG.controls["Id"].value, "", insuranceData);
    }

    async isInsuranceExist(patientId, insurerId, insuId, insuranceId, type?: any, insuranceData?: any) {
        let isInsuExs = null;
        let isInsuActv = null;
        if (!type) {
            isInsuExs = await this._additionalInsu
            .isInsuranceExistForPatient(patientId, insurerId, insuranceId).toPromise();
        }
        isInsuActv = await this._additionalInsu.isInsuranceActiveFrmInsFilePatient(insurerId).toPromise();
        if (isInsuExs || this.inputValue == this.patientInfoFG.value['InsuCarrier']['InsurerCode']) {
            this._alertService.error("Patient already has the selected Insurance.");
            this.insuName = " ";
            this.inputValue = " ";
            this.inputValue = null;
            const newFG: any = this.insuranceFG.controls["Insurance"];
            const newIC: any = this.insuranceFG.controls["InsuCarrier"];
            newIC.controls["InsurerCode"].setValue(null);
            newFG.controls["InsurerId"].setValue(null);
        } else if (!isInsuActv) {
            this._alertService.error("The selected Insurance is inactive, hence cannot be added.");
            this.insuName = " ";
            this.inputValue = " ";
            this.inputValue = null;
            const newFG: any = this.insuranceFG.controls["Insurance"];
            const newIC: any = this.insuranceFG.controls["InsuCarrier"];
            newIC.controls["InsurerCode"].setValue(null);
            newFG.controls["InsurerId"].setValue(null);
        } else {
                const insurance = this.insuranceData && this.insuranceData.length ? this.insuranceData.find(d => d["InsuCarrierId"] === +this.insuranceFG.value["Insurance"]["InsurerId"]) : null;
                if (insuId) {
                    const insuStng = await this._insuServ.getInsSettingsInfo(insuId).toPromise();
                    if (insuStng && insuStng["InsuPlanPrice"]) {
                        this.Insurance.controls["IsPatAssigned"].setValue(insuStng["InsuPlanPrice"]["IsAssigntAccepted"]);
                    }
                }
            if (insurance) {
                this.checkIsPolicyRequired(insurance);
            }
            this.filterGroupsBasedOnInsurerId(insurerId);
        }
    }

    changeInsu() {
        this.updateInsu = !this.updateInsu;
    }

    isTypedValue(Value) {
        if (Value && Value.value && Value.value !== "") {
            this.InsuranceGroup.controls["Name"].patchValue(Value.value["Name"]);
            this.InsuranceGroup.controls["InsurerId"].patchValue(this.insuranceFG.value.Insurance.InsurerId);
            this.InsuranceGroup.controls["IsActive"].patchValue(true);
            this.InsuranceGroup.controls["Id"].patchValue(Value.value["Id"]);
        } else {
            this.InsuranceGroup.controls["Name"].patchValue(null);
            this.InsuranceGroup.controls["InsurerId"].patchValue(null);
            this.InsuranceGroup.controls["IsActive"].patchValue(false);
        }
    }

    focusOutFromGroup() {
        if (document.getElementById("primInsRela")) {
        const data: any = document.getElementById("primInsRela").getElementsByTagName("input")[0];
        setTimeout(() => {
            data.focus();
        }, 100);
        }
    }

    filterGroupsBasedOnInsurerId(insuCarrierId) {
        this._additionalInsu.getInsuGroups(insuCarrierId).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp && resp["length"] > 0) {
                this.filteredGroups = resp;
                if (this.InsuranceGroup.value["Name"] && !this.Insurance.value["InsuGrpId"] &&
                this.filteredGroups.find(val => val["Name"] === this.InsuranceGroup.value["Name"])) {
                    const matchedGroup = this.filteredGroups.find(val => val["Name"] === this.InsuranceGroup.value["Name"]);
                    this.Insurance.controls["InsuGrpId"].patchValue(matchedGroup["Id"]);
                    this.InsuranceGroup.controls["Id"].patchValue(matchedGroup["Id"]);
                } else if (!this.Insurance.value["InsuGrpId"]) {
                    this.InsuranceGroup.controls["Name"].patchValue(null);
                    this.Insurance.controls["InsuGrpId"].patchValue(null);
                    this.InsuranceGroup.controls["Id"].patchValue(null);
                }
            } else {
                this.Insurance.controls["InsuGrpId"].patchValue(null);
                this.InsuranceGroup.controls["Id"].patchValue(null);
            }
        });
    }

    init(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
    }

    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openPopUpModal(content);
            }
        }
    }

    openPopUpModal(content) {
        this.editMode = false;
        this.policyRequired = false;
        this.insuranceFG.reset();
        this.inputValue = "";
        this.insuName = "";
        this.setDefaultValues();
        this.modelRef = this.modalService.open(content, { size: "lg" , centered: true, backdrop: false, keyboard: false });
        this.oldInsuModal = null;
    }

    getBillingOptions() {
        return [
            // {Id: 0, value: "None"},
            {Id: null, value: "None"},
            // { Id: 1, value: "Primary" },
            { Id: 2, value: "Secondary" },
            { Id: 3, value: "Tertiary" },
        ];
    }

    Cancel() {
        // TODO: Need to implement
    }

    formatter = (x: any) => {
        this.insuName = x.InsuranceName ? x.InsuranceName.trim() : "";
        return x.InsurerCode ? x.InsurerCode.trim() : null;
    }

    checkForInsuranceDuplication(type) {
        const insuCardsFG: any = this.insuranceFG.controls["InsuCard"];
        const newFG: any = this.insuranceFG.controls["Insurance"];
        if (insuCardsFG.value.PolicyNum && insuCardsFG.value.PolicyNum.trim()) {
            this._commonServ.checkIfPolicyNoExists(insuCardsFG.value.PolicyNum, newFG.value.InsurerId, insuCardsFG.value.Id).pipe(takeUntil(this.unsubscribe$))
            .subscribe( resp => {
                if (this.oldInsuModal !== null) {
                    if (resp) {
                        if (this.oldInsuModal.PolicyNum != insuCardsFG.value.PolicyNum) {
                            this.resetPolicyNum();
                            return true;
                        }
                    }
                    this.callSaveOrUpdate(type);
                } else {
                    if (resp) {
                        this.resetPolicyNum();
                    } else {
                        this.callSaveOrUpdate(type);
                    }
                }
            });
        } else {
            this.callSaveOrUpdate(type);
        }
    }

    resetPolicyNum() {
        const insuCardsFG: any = this.insuranceFG.controls["InsuCard"];
        insuCardsFG.controls["PolicyNum"].setErrors({invalid: true});
        insuCardsFG.controls["PolicyNum"].markAsTouched();
        this._alertService.error("Insurance ID already exists.");
        document.getElementById("InsuId").focus();
    }

    callSaveOrUpdate(type) {
        const insuCardsFG: any = this.insuranceFG.controls["InsuCard"];
        insuCardsFG.controls["PolicyNum"].setErrors(null);
        if (type === "update") {
            this.updateInsurance();
        } else {
            this.addPatientInsurance();
        }
    }

    focusOutFromAdd(event) {
        if (!this.patientInsurances || this.patientInsurances.length === 0) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.wijimoId.focus();
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }
}
