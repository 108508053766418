<app-erx-filter
    (FormModel)="receiveForm($event)"
    [AwaitingCount]="AwaitingCount"
    [ErrorCount]="ErrorCount"
    [ReadyCount]="ReadyCount"
    [ProcessCount]="ProcessedCount"
    [RxFGInfo]="rxFG"
    [IsSelectedRecords]="isSelectedRecords"
    [PatientId]="PatientId"
    [StoreFilters]="storeFilterss"
    [IsFromRxHistoryDisplay]="IsFromRxHistoryDisplay"
    [isPopErxModel]="isPopErxModel"
    [FromIntakeQueue]="fromIntakeQueue"
    [FromRph]="fromRph"
    (processBenfitCheck)="processMultiRecords($event)"
    (TransferInRequest)="openTransferInRequest($event)"
    (CloseErxMessageLinkModal)="closeErxMessageLinkModal($event)"
    (processMultiRecords)="processMultiRecords($event)"
    [ReviewXDays]="reviewXDays"
    [RxInfo]="rxInfo"
></app-erx-filter>
<app-erx-listing
    (AwaitingCount)="receiveAwaiting($event)"
    (ErrorCount)="receiveError($event)"
    (ReadyCount)="receiveReady($event)"
    [rxFG]="rxFG"
    [isPopErxModel]="isPopErxModel"
    [FromRph]="fromRph"
    [FromIntakeQueue]="fromIntakeQueue"
    [RxType]="rxType"
    [TransferInReq]="transferInReq"
    (SelectedRecords)="selectedRecords($event)"
    [CurrentDetails]="CurrentDetails"
    [IsFromRxHistoryDisplay]="IsFromRxHistoryDisplay"
    (GetErxCount)="getErxCount($event)"
    [RxInfo]="rxInfo"
    (ProcessedCount)="receiveProcess($event)"
    [erxFilterData]="erxFilterData"
    [RxExtraInfoObj]="rxExtraInfoObj"
    (CloseErxMessageLinkModal)="closeErxMessageLinkModal($event)"
    *ngIf="erxFilterData"
    (StoreFilters)="storeFilters()"
    (CloseErxModal)="closeErxModal($event)"
    (emitEnableDisableEvnt)="enableDisableBtn($event)"
></app-erx-listing>
<ng-template #ErxwarningMessage let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close')">
        <h4 header>Warning!!</h4>
        <span body>
            <div>Are you sure you want to save this Electronic Rx without associating with eRx prescription?</div>
        </span>
        <div footer class="row position-relative bottom-0 w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button ngbAutofocus class="hotkey_primary"  (keydown.enter)="c('close');closeErxModal()"   (click)="c('close');closeErxModal()" appShortcutKey
                    InputKey="y"><b>Y</b> YES</button>
                <button class="hotkey_primary" (click)="c('close')" appShortcutKey InputKey="n">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>
