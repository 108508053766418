<div class="editpatient--body newrx--body edit--drug">
    <div class="row content--heading">
        <div class="col-md-12 padding-0">
            <div class="row d-flex flex-row">
                <div class="col-12">
                    <div class="row">
                        <div class="icon">
                            <i class="far fa-pills"></i>
                        </div>
                        <div class="col-10 margin_left_n1rem">
                            <div class="content--heading__title padding-0">
                                REVIEW DRUG PICK VERIFICATION HISTORY
                            </div>
                        </div>
                        <div class="col-1 padding_left_14rem padding_top_0_7rem">
                            <app-user-guide-icon [ActiveTabId]= "'DPVHistory'"></app-user-guide-icon>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="row">
                <div class="col">
                    <span class="eprx--header__heading">Review Drug Pick Verification History</span>
                </div>
            </div>
        </div>
        <div class="eprx--block__content">
            <form [formGroup]="filterFG" (ngSubmit)="getRecords()">
                <div class="col-sm-12 padding-0">
                    <div class="row">
                        <div class="col-6">
                                <div class="row">
                                    <div class="col-3">
                                        <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'DphFromDate'"
                                            [LabelText]="'Verification From Date'" [PlaceHolder]="''" [ControlName]="'fromDate'"
                                            [FormGroupName]="filterFG" [MinDate]="" [MaxDate]="maxDate"
                                            [ErrorDefs]="{required: 'Required', inValid:'Invalid Date', pattern: 'Invalid Date', maxDate:'Invalid Date'}"
                                            [MarkAsTouched]="filterFG.controls.fromDate?.touched">
                                        </eprx-date-picker>
                                    </div>
                                    <div class="col-3">
                                        <eprx-date-picker [RxSelectId]="'ToDate'"   [LabelText]="'Verification To Date'" [PlaceHolder]="''"
                                            [ControlName]="'toDate'" [FormGroupName]="filterFG" [MinDate]="" [MaxDate]="maxDate"
                                            [ErrorDefs]="{required: 'Required', inValid:'Invalid Date', pattern: 'Invalid Date', maxDate:'Invalid Date'}"
                                             [MarkAsTouched]="filterFG.controls.toDate?.touched">
                                        </eprx-date-picker>
                                    </div>
                                    <div class="col-3">
                                        <app-rx-select [LabelText]="'Status'" [PlaceHolder]="''" [ControlName]="'verifyStatus'" [FormGroupName]="filterFG"
                                            [HasMultiple]="false" [HasBindLabel2]="false" [LabelForId]="'Name'" [List]="[{Name:'All',Value:null},{Name:'Verified/Re-Verified',Value:'p'},{Name:'Re-Verify',Value:'rv'},{Name:'Not Yet Verified',Value:'n'}]"
                                            [BindLabel]="'Name'" [BindValue]="'Value'" [LabelForId]="'Name'" (TriggerSelectValue)="getStatusSelected()" (TriggerOnEnterValue)="getStatusSelected()" [nextElement]="'currentUser'">
                                        </app-rx-select>
                                    </div>
                                    <div class="col-3  pt-4 height_2_5rem" [formGroup]="filterFG">
                                        <input class="form-check-input" type="checkbox" [id]="'currentUser'" value=true formControlName="isCurrentUser"/>
                                        <label type="label" class="form-check-label" [for]="'currentUser'">
                                            Is Current User
                                        </label>
                                    </div>
                                </div>
                        </div>
                        <div class="col-6">
                                <div class="row">

                                    <div class="col-6">
                                        <div class="d-flex flex-column"><label>Smart Search</label>
                                            <wj-flex-grid-search [grid]="flex" class="smart-filter max-width_35rem" autofocus placeholder="Type any keyword to search from the below grid"
                                            title="'Rx#'" (click)="searchGridData($event)" (keyup)="searchGridData($event)" [text]="clearSearchData">
                                            </wj-flex-grid-search>
                                        </div>
                                    </div>
                                    <div class="col-6 align-button text-right pt-3">
                                        <button title="Search / Refresh" type="submit" class="hotkey_success" appShortcutKey [AltKey]="'f'"><span>F</span> Search</button>
                                        <button type="button" class="hotkey_success" appShortcutKey [AltKey]="'t'" (click)="getReport(false)"><span>T</span>
                                            Report
                                        </button>
                                        <!-- <button type= "button" class="hotkey_success" (click)="getReport(true)" appShortcutKey
                                        [AltKey]="'e'"><span>E</span>
                                       Export
                                    </button> -->
                                        <button type="button" class="hotkey_success" (keydown.tab)="focusToFirstIfEmpty($event)" appShortcutKey
                                            [AltKey]="'l'" (click)="clearSearchInputs()"><span>L</span> Clear
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="eprx--block__content erx--body" id="grpTransHistGrid">
            <span>
                <div class="row mt-0" *ngIf="totalVerifRecs?.length">
                    <div class="col-12 text-right">
                        <button class="submit print-med-guide background_orange"
                            (click)="toggleColorLegend()">Color Legend</button>
                    </div>
                </div>
                <wj-flex-grid #flex [headersVisibility]="'Column'" [itemsSource]="dphVerifWJ" [isReadOnly]="true"
                (drop)="this._storageService.storeWijmoGridDataInSession(flex)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(flex)"
                    [columnPicker]="'reviewdrugVerfWJ'" (dblclick)="OnDoubleClickOnWijimo(flex, $event)" (initialized)="init(flex)" [selectionMode]="'Row'"
                    [itemFormatter]="itemFormatter" [ngClass]="[!totalVerifRecs?.length ? 'no-data wjm-grd-custom-height-56' : 'wjm-grd-custom-height-56']">
                    <wj-flex-grid-column [width]="'0.6*'" [header]="'Edit Rx'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div  class="text-center padding_left_10px">
                                <i class="far fa-edit actions" title="Edit Rx" (click)="routeToEditRx(item)"></i>
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [width]="'0.6*'" [header]="'History'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div class="text-center padding_left_10px">
                                <i class="far fa-history actions" title="Verification History" (click)="openVerifHistory(item,true)"></i>
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column
                        [header]="rxheader.hName" [binding]="rxheader.hName" [aggregate]="(rxheader.hName === 'Billed' || rxheader.hName === 'Paid') ? 'Sum' : 'None'"
                          [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders"
                        [width]="rxheader['width']">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div *ngIf="rxheader.hName === 'Filled' || rxheader.hName === 'Verified'">
                                {{ item[rxheader.hName] | date: "MM/dd/yyyy" }}
                            </div>
                            <div *ngIf="!(rxheader.hName === 'Filled' || rxheader.hName === 'Verified' || rxheader.hName === 'Drug Name' || rxheader['hName'] === 'Rx#')"
                                [title]="item[rxheader.hName]+'' |  uppercase">
                                {{item[rxheader.hName]}}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#'"  [ngbTooltip]=" rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : item['RxHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 3) ? 'right' : (cell.col.index>=3 && cell.col.index <10)?'top':'left'" container="body" tooltipClass="tooltip-title" [openDelay]="500">
                                {{ item[rxheader['hName']]}}
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div [title]="(rxheader?.hName  | StringSepWithSpaceOnCamCase )">
                                {{ rxheader?.hName  | StringSepWithSpaceOnCamCase }}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                </wj-flex-grid>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="totalVerifRecs?.length">
                <app-eprx-pagination [TotalCount]="totalCountData" [WijmoName]="flex" [GridName]="'ReviewDrugPickVerfGrid'" (OnPageSelected)="setPage($event)"
                    (OnSizeSelected)="setSize($event)" (keydown.tab)="focusToFirst($event)" [PageNumber]="pageNumber"></app-eprx-pagination>
            </div>
        </span>
        </div>
    </div>
</div>

<app-override-log *ngIf="openOverride" [PrivMask]="privType" [CatId]="categoryId" [OverrideEndpoint]="overrideEndpoint"
    (OverrideLoginDet)="OverrideRes($event)"></app-override-log>


<ng-template #dphVerifHistTmp let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close')">
        <h4 header>Verification History</h4>
        <span body>
            <div class="row">
                <div class="col-12" *ngIf="dphVerifHistory && dphVerifHistory.length>0 else noRecords">
                    <div>
                    <wj-flex-grid #previewControl [headersVisibility]="'Column'" class="min-height_2rem" [itemsSource]="dphVerifModif"
                        [isReadOnly]="true" [selectionMode]="'None'" [columnPicker]="'revHistoryListWj'" >
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                        [width]="rxheader['width']"   *ngFor="let rxheader of wjHeaders2; let i = index">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div *ngIf="(rxheader['hName'] === 'Date Time Verified')">
                                {{
                                    item[rxheader['hName']]
                                    | localTime | date: "MM/dd/yyyy hh:mm:ss a"
                                }}
                                <!-- {{ item[rxheader['hName']] | localTime | date: "MM/dd/yyyy" }} -->
                            </div>

                            <div *ngIf="(rxheader['hName'] === 'Result')">
                                {{item[rxheader['hName']] ? ( item[rxheader['hName']].trim() === 'p' ? 'PASS' :
                                item[rxheader['hName']].trim()
                                === 'f' ? 'FAIL' : (item[rxheader['hName']].trim()=== 'RV') ? 'NEED REVERIFICATION': '' ) : ''}}
                            </div>
                            <div *ngIf="!(rxheader['hName'] === 'Date Time Verified'  || rxheader['hName'] === 'Result')"
                                title={{item[rxheader.hName]}}>
                                {{ item[rxheader['hName']] }}
                            </div>

                        </ng-template>

                      </wj-flex-grid-column>
                    </wj-flex-grid>
                    </div>
                </div>
                <ng-template #noRecords>
                    <div class="col-12 eprx--block__footer">
                        <div>
                            <span></span>
                            <i class="fa fa-file"></i><br/>
                            No Data Found!
                        </div>
                    </div>
                </ng-template>
            </div>
        </span>
        <button footer class="hotkey_primary" (click)="c('close')" appShortcutKey [InputKey]="'c'">
            <b>C</b> Close
        </button>
    </app-custom-modal>
</ng-template>


