<div>
    <div *ngIf="isPopUp" class="modal-header">
        <div class="row">
            <div class="col"><span class="eprx--header__heading font_weight_600 pt-1">Patient Search</span></div>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="closePateintModal()">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
    </div>
    <div class="row erx--drug-body">
        <div class="col-md-12" [ngClass]="searchFrmPage === 'Dash' && !isPopUp ? 'dash--drug--grid' : searchFrmPage === 'header' && !isPopUp ? 'notdash--drug--grid' : ''">
            <div class="exprx--common-block">
                <div class="eprx--block__header block__header" *ngIf="!isPopUp">
                    <div class="row">
                        <div class="col"><span class="eprx--header__heading pt-1">Patient Search</span></div>
                        <button type="button" [tabindex]="-1" class="close pr-3 pt-1" aria-label="Close" (click)="ClosePatientDrugPage.emit(true); clearFilters()">
                            <span aria-hidden="true" class="close-button">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="eprx--block__content block__content erx--body p-4">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <div class="d-flex flex-column pt-1">
                                <eprx-input [AutoFocus]="true" [LabelText]="'Search'" [ControlName]="'PatientName'" [FormGroupName]="PatientSuggestFilterFG" [PlaceHolder]="'Enter Keywords, Patient names, or Address to search through the list below and globally'" (TriggerValWhileEnter)="getPatientBySearch()" [RxSelectId]="'PatientName'" (TriggerOnEnterValue)="getGlobalSearchPatientSuggest()">
                                </eprx-input>
                                <span class="help-block" *ngIf="required"> Enter min 3 Chars </span>
                                <div class="search--icon" (click)="clearedBySearchIcon()">
                                    <i class="far fa-search"></i>
                                </div>                            
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding_top_27px">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="form-check-label padding_top_0_1rem">Show Active Patients Only</label>
                                </div>
                                <div class="col-md-4">
                                    <eprx-toggle [LabelText]="''" [ControlName]="'ActivePatient'" [FormGroupName]="PatientSuggestFilterFG" [IsChecked]="PatientSuggestFilterFG?.get('ActivePatient')?.value" 
                                    [RxselectId]="'IsActivePatient'" (TriggerSelectValue)="getActivePatientList($event.target.checked)" [AutoFocus]="true"></eprx-toggle>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-button text-right allign-bottom">
                            <button type="submit" #searchButton title="Search" class="hotkey_success pull-right" appShortcutKey appShortcutKey [AltKey]="'f'" (click)="getGlobalSearchPatientSuggest()">
                                <span>F</span> Search
                            </button>
                            <button type="button" class="hotkey_success pull-right" appShortcutKey [AltKey]="'l'" (click)="clearFilters()">
                                <span>L</span> clear
                            </button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div id="loader" class="progress-bar-container mb-2" *ngIf="loading">
                            <div class="progress-bar--common" [style.width]="progressValue + '%'"></div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 drugwj-grid">
                            <wj-flex-grid #patientflex [columnPicker]="'patientSuggestListWj'" [itemsSource]="patientSuggestWJ" [isReadOnly]="true"  [allowSorting]="true" (initialized)="Init(patientflex)" [ngClass]="!patientSugList?.length ? 'no-data':''"  [itemFormatter]="itemFormatter" 
                            [headersVisibility]="'Column'" [selectionMode]="'Row'" (click)="onRowSingleClick(patientflex, $event)" class="text_transform" (drop)="_storageService.storeWijmoGridDataInSession(patientflex)" (resizedColumn)="_storageService.storeWijmoGridDataInSession(patientflex)" (keydown.tab)="focusToFirst($event)">
                            <wj-flex-grid-column [header]="''" [width]="40">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-item="item" let-cell="cell">
                                    <span class="text-center">
                                        <input class="form-check-input drug-check-input ml-0" type="checkbox" [(ngModel)]="item.isSelected" [checked]="item.isSelected" [id]="item.RowId" name="" (click)="getselectedPatientByCheck(item)"/>
                                        <label class="form-check-label" [for]="item.RowId"></label>
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                    *ngFor="let rxheader of patientListwjHeaders; let i = index" [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                        <div [ngbTooltip]="rxheader['hName'] === 'Patient Name' || rxheader['hName'] === 'Address' ? patientcellToolTip : ''" [placement]="cell.col.index>8? 'left': 'right'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                            {{item[rxheader['hName']]}}                                        
                                        </div>
                                        <ng-template #patientcellToolTip>
                                            <span>{{item[rxheader['hName']] | uppercase}}</span>
                                    </ng-template>
                                    </ng-template>
                                    <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                        <div [title]="rxheader['hName']">
                                            {{ rxheader['hName'] }}
                                        </div>
                                    </ng-template>                                
                            </wj-flex-grid-column>
                            <app-no-data-found *ngIf="!patientSugList?.length"></app-no-data-found>
                            </wj-flex-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
