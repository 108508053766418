<ng-template #ChangeReq let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="mainPopUpClosed()">
        <h4 header>Rx Change Request</h4>
        <span body>
            <div class="exprx--common-block erx-change-request">
                <div class="eprx--block__content erx__doc--body">
                    <div class="row ">
                        <div class="col-md-4">
                            <div class="exprx--common-block">
                                <h5 class="erx--label--primary">Change Type</h5>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <eprx-select [RxSelectId]="'ChangeType'" [LabelText]="
                                                    'Select Change Type'
                                                " (keydown)="
                                                    focusOnTab(
                                                        $event,
                                                        enableMessageSubCode ? 'ChangeSubType' : 'RxSig',
                                                        'select'
                                                    )
                                                "
                                                [IsDisabled]="(requestCategoryCode && requestCategoryCode?.length && requestCategoryCode[0]?.RequestCategoryCode === 'P' && selectedBenefitCheckType === 'paReq')"
                                                (TriggerSelectValue)="
                                                    enableDrug($event)
                                                " [IsFirstElem]="true" [ControlName]="'RxChangeReason'"
                                                [FormGroupName]="ErxChangeReqFG" [IsRequired]="true"
                                                [HasMultiple]="false" [HasBindLabel2]="false"
                                                [BindLabel]="'RequestName'" [BindValue]="
                                                    'RequestDescription'
                                                " [LabelForId]="'RequestName'" [List]="
                                                    erxDetails?.RxChangeRequestTypesList
                                                " [MarkAsTouched]="ErxChangeReqFG?.controls?.RxChangeReason?.touched"
                                                [InputErrors]="ErxChangeReqFG?.controls?.RxChangeReason?.errors"
                                                [ErrorDefs]="{required: 'Required'}"
                                                >
                                            </eprx-select>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"
                                        *ngIf="(selectedBenefitCheckType !== 'paReq')">
                                            <label>Patient Name</label>
                                            <div class="label--data">
                                                {{
                                                    erxDetails?.Patient
                                                        ?.lastname
                                                        ? erxDetails?.Patient
                                                              ?.lastname +
                                                              (erxDetails?.Patient
                                                              ?.firstname
                                                              ? ", " + erxDetails?.Patient
                                                                    ?.firstname
                                                              : "")
                                                        : "--"
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 seconday-search--grid margin_top_6" *ngIf="(selectedBenefitCheckType === 'paReq')">
                                            <label>Patient</label>
                                            <app-searchbox-global
                                            class="common_global_search_max_content_display_for_changeRequest"  id="Patient" [RxSelectId]="'Patient'" [SearchFor]="'Patient'"
                                            [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true"
                                            (SelectedPatient)="selectPatient($event)" [ClearInputValue]="patientSearchValue">
                                        </app-searchbox-global>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="enableMessageSubCode">
                                            <eprx-select [RxSelectId]="'ChangeSubType'"
                                             [LabelText]="
                                                    'Select Reason Type'
                                                " (keydown)="
                                                    focusOnTab(
                                                        $event,
                                                        'RxSig',
                                                        'select'
                                                    )
                                                "
                                                [IsFirstElem]="true" [ControlName]="'RxChangeSubReason'"
                                                [FormGroupName]="ErxChangeReqFG" [IsRequired]="true"
                                                [HasMultiple]="false" [HasBindLabel2]="false"
                                                [BindLabel]="'Description'" [BindValue]="
                                                    'Code'
                                                " [LabelForId]="'Description'" [List]="
                                                    erxDetails?.messageRequestSubCodes
                                                " [MarkAsTouched]="ErxChangeReqFG?.controls?.RxChangeSubReason?.touched"
                                                [InputErrors]="ErxChangeReqFG?.controls?.RxChangeSubReason?.errors"
                                                [ErrorDefs]="{required: 'Required'}"
                                                >
                                            </eprx-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="exprx--common-block">
                                <h5 class="erx--label--primary">
                                    Prescribed Drug
                                </h5>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Drug Name</label>
                                            <div class="label--data">
                                                {{
                                                    erxDetails?.Drug?.drugname
                                                        ? erxDetails?.Drug
                                                              ?.drugname
                                                        : "--"
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Drug Form</label>
                                            <div class="label--data">
                                                {{
                                                    erxDetails?.Drug
                                                        ?.drugformname
                                                        ? erxDetails?.Drug
                                                              ?.drugformname
                                                        : "--"
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>AWP</label>
                                            <div class="label--data">
                                                {{
                                                    erxDetails?.Drug?.awppack
                                                        ? ("$" + erxDetails?.Drug
                                                              ?.awppack.toFixed(2))
                                                        : "--"
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Drug NDC</label>
                                            <div class="label--data">
                                                {{this._formatsUtil.getNDCFormat(erxDetails?.Drug?.drugcode)}}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Strength</label>
                                            <div class="label--data">
                                                {{
                                                    erxDetails?.Drug?.strength
                                                        ? erxDetails?.Drug
                                                              ?.strength
                                                        : "--"
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label>Sig</label>
                                            <div class="label--data">
                                                {{
                                                    erxDetails?.Drug
                                                        ?.defaultSigCode
                                                        ? erxDetails?.Drug
                                                              ?.defaultSigCode
                                                        : "--"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-12">
                                    <div class="exprx--common-block">
                                        <div class="row">
                                            <div class="col-9">
                                                <div class="exprx--common-block">
                                                    <h5 class="erx--label--primary">
                                                        Change Prescriber
                                                    </h5>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                                <label>Prescriber
                                                                    Name</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        erxDetails
                                                                            ?.Prescriber
                                                                            ?.prescriberlastname +
                                                                            ", " +
                                                                            erxDetails
                                                                                ?.Prescriber
                                                                                ?.prescriberfirstname
                                                                            | uppercase
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 px-0">
                                                                <label>DEA#</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        erxDetails
                                                                            ?.Prescriber
                                                                            ?.prescriberdeanum
                                                                            ? _formatsUtil.getDeaFormat(erxDetails?.Prescriber?.prescriberdeanum)
                                                                            : "--"
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                                <label>NPI#</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        erxDetails
                                                                            ?.Prescriber
                                                                            ?.prescribernpinum
                                                                            ? erxDetails
                                                                                  ?.Prescriber
                                                                                  ?.prescribernpinum
                                                                            : "--"
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                                <label>Phone#</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        erxDetails?.Prescriber
                                                                            ? (erxDetails
                                                                                  ?.Prescriber
                                                                                  ?.telephone
                                                                              | mask
                                                                                  : "(000) 000-0000")
                                                                            : "--"
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                                <label>Address</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        PrescAddress?.AddressLine1
                                                                            ? PrescAddress?.AddressLine1
                                                                            : PrescAddress2?.AddressLine1
                                                                            ? PrescAddress2?.AddressLine1
                                                                            : "--"
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 px-0">
                                                                <label>Zip</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        PrescAddress?.ZipCode
                                                                            ? PrescAddress?.ZipCode
                                                                            : PrescAddress2?.ZipCode
                                                                            ? PrescAddress2?.ZipCode
                                                                            : "--"
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                                <label>State</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        PrescAddress?.StateName
                                                                            ? PrescAddress?.StateName
                                                                            : PrescAddress2?.StateName
                                                                            ? PrescAddress2?.StateName
                                                                            : "--"
                                                                    }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                                <label>City</label>
                                                                <div class="label--data">
                                                                    {{
                                                                        PrescAddress?.CityName
                                                                            ? PrescAddress?.CityName
                                                                            : PrescAddress2?.CityName
                                                                            ? PrescAddress2?.CityName
                                                                            : "--"
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-11 col-md-11 col-sm-11 col-xs-11">
                                                                <eprx-select class="change-select"
                                                                    [AddItem]="
                                                                        true
                                                                    " [RxSelectId]="
                                                                        'RxSig'
                                                                    " [LabelText]="
                                                                        'SPI'
                                                                    " [HasControl]="
                                                                        false
                                                                    " [InputValue]="
                                                                        PrescAddress
                                                                            ? PrescAddress?.SPINo
                                                                            : ''
                                                                    " [HasMultiple]="
                                                                        false
                                                                    " [HasBindLabel2]="
                                                                        false
                                                                    " [BindValueML]="
                                                                        SPIDetails
                                                                    " [HasBindLabelM]="
                                                                        true
                                                                    " [BindLabel]="
                                                                        'SPINo'
                                                                    " [BindValue]="
                                                                        'SPINo'
                                                                    " [LabelForId]="
                                                                        'SPINo'
                                                                    " [List]="
                                                                        SPIDetails
                                                                            ? SPIDetails
                                                                            : ''
                                                                    " (keydown)="
                                                                    focusOnTab(
                                                                        $event,
                                                                        (enableDrugSearch ? 'DrugCov' : 'erxReNotes'),
                                                                        (enableDrugSearch ? 'select' : 'textArea')
                                                                    )
                                                                    "  (TriggerSelectValue)="
                                                                        changeSPIADD(
                                                                            $event
                                                                        );
                                                                    " [CheckSpiServiceLevel]="checkServiceLevel(PrescAddress ? PrescAddress:'')" 
                                                                    [Title]="checkServiceLevel(PrescAddress ? PrescAddress:'') === true ? 'Service Level found' : 'Service Level Not found'">
                                                                </eprx-select>
                                                            </div>
                                                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 top_2rem position-relative">
                                                                <a (click)="refreshSpi()" [ngStyle]="{color: checkServiceLevel(PrescAddress ? PrescAddress:'') === true ? '#0fa20f' : '#f6251a'}">
                                                                        <i class="far fa-redo-alt fa-md font-weight-bold" title="Refresh SPI"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="exprx--common-block">
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 change-select">
                                                                <eprx-select [RxSelectId]="
                                                                        'DrugCov'
                                                                    " [LabelText]="
                                                                        'Drug Coverage Code'
                                                                    " [ControlName]="
                                                                        'DrugCoverageCodes'
                                                                    " [FormGroupName]="
                                                                        ErxChangeReqFG
                                                                    " [HasMultiple]="
                                                                        true
                                                                    " [HasBindLabel2]="
                                                                        true
                                                                    " [BindLabel2]="
                                                                        'Decription'
                                                                    " [BindLabel]="
                                                                        'Code'
                                                                    " [BindValue]="
                                                                        'Code'
                                                                    " (keydown)="
                                                                      focusOnTab(
                                                                            $event,
                                                                            'AuthRef',
                                                                            'drug'
                                                                        )
                                                                    " [LabelForId]="
                                                                        'pr1'
                                                                    " [List]="
                                                                        erxDetails.DrugCoverageCodes
                                                                            ? erxDetails.DrugCoverageCodes
                                                                            : ''
                                                                    "
                                                                    [IsDisabled]="!enableDrugSearch">
                                                                </eprx-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="exprx--common-block">
                                                    <h5 class="erx--label--primary">
                                                        Pharmacist Notes
                                                    </h5>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <eprx-text-area [RxSelectId]="
                                                                        'erxReNotes'
                                                                    " [AutoFocus]="
                                                                        textareaFocus
                                                                    " (keydown)="
                                                                       focusOnTab(
                                                                            $event,
                                                                            '',
                                                                            'OutText'
                                                                        )
                                                                    " [PlaceHolder]="
                                                                        'Enter text'
                                                                    " [Cols]="50" [Rows]="11" [MaxLength]="
                                                                        140
                                                                    " [FormGroupName]="
                                                                        ErxChangeReqFG
                                                                    " [ControlName]="
                                                                        'PharmacistNotes'
                                                                    ">
                                                                </eprx-text-area>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-6">
                            <div class="exprx--common-block erx--body insurance--body">
                                <h5 class="erx--label--primary">
                                    Requested Drugs
                                </h5>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 seconday-search--grid"
                                            *ngIf="SearchDrug">
                                            <div class="row">
                                                <div class="col-4 label-search color_cyan pt-0"
                                                  >
                                                    Drug
                                                </div>
                                                <div class="col-8">
                                                    <div class="text-right">
                                                        <!-- <div>
                                                            <i class="far fa-lg fa-long-arrow-alt-right color_orange"
                                                                ></i><span>
                                                                for Equv ; </span><b class="color_orange">Shift +
                                                                <i class="far fa-lg fa-long-arrow-alt-right color_orange"
                                                                    ></i></b><span>
                                                                for Gen
                                                                Equv</span>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <app-searchbox-global [SearchFor]="'Drug'" [TabIndex]="
                                                    enableDrugSearch
                                                        ? null
                                                        : '-1'
                                                "
                                                 (SelectedDrug)="
                                                    selectedDrugInfo($event)
                                                " [DisableSerchFil]="true" [inputdisabled]="
                                                    !enableDrugSearch
                                                " [DisableRadioButtons]="true" class="erxChange"
                                                [IsFocused]="isInputFocused" [DisableRadioButtons]="true"
                                                [IsAddNew]="false"
                                                ></app-searchbox-global>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="
                                                !SearchDrug && enableDrugSearch
                                            ">
                                            <div class="row">
                                                <div class="col-11">
                                                    <label>Selected Drug</label>
                                                    <div class="label--data">
                                                        {{
                                                            selectedDrug?.DrugFullName &&
                                                            selectedDrug?.DrugFullName !==
                                                                " "
                                                                ? selectedDrug?.DrugFullName
                                                                : selectedDrug?.drugname +
                                                                  " " +
                                                                  selectedDrug?.drugformname +
                                                                  " " +
                                                                  selectedDrug?.strength
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="col-1 padding_19px_1px_1px_1px">
                                                    <a>
                                                        <i class="far fa-redo-alt fa-md actions" (click)="
                                                                resetDrug()
                                                            " title="Reset Drug"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="
                                                !SearchDrug && enableDrugSearch
                                            ">
                                            <label class="col-12 padding-0">
                                                NDC
                                            </label>
                                            <div class="label--data">
                                                {{
                                                    selectedDrug?.drugcode
                                                        ? (selectedDrug?.drugcode
                                                          | mask
                                                              : "AAAA0-0000-00")
                                                        : "--"
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding_top_7px">
                                            <eprx-input [LabelText]="'Auth Refills'" [AutoFocus]="DrugSelect"
                                                [PlaceHolder]="'Auth Refills'" [ControlName]="'Refills'"
                                                [RxSelectId]="'AuthRef'" [FormGroupName]="erxReq"
                                                [InputType]="'NUMBER'" [MaxLength]="3" [DecimalValues]="0">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding_top_7px">
                                            <eprx-input [RxSelectId]="'erxReqQty'" [LabelText]="'Qty'"
                                                [DecimalValues]="3" [ControlName]="'Qty'" [FormGroupName]="erxReq"
                                                (TriggerSearchValue)="
                                                    setNumberFormat('Qty')
                                                " [PlaceHolder]="'Qty'" [MaxLength]="11">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding_top_7px">
                                            <eprx-input [RxSelectId]="'erxReqDays'" [LabelText]="'Days'"
                                                [PlaceHolder]="'Days'" [ControlName]="'Days'" [FormGroupName]="erxReq"
                                                [InputType]="'NUMBER'" [MaxLength]="3"
                                                [DecimalValues]="0" (keydown.tab)="
                                                focusOnTab($event, 'DAW', 'select')
                                                ">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 padding_top_7px">
                                            <eprx-select [RxSelectId]="'DAW'" [LabelText]="'DAW'" [PlaceHolder]="''"
                                                [ControlName]="'Daw'" [FormGroupName]="erxReq" [List]="DAWList"
                                                [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Code'"
                                                [LabelForId]="'Name'">
                                            </eprx-select>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <eprx-input [RxSelectId]="'rxSigCode'"
                                                [LabelText]="'Sig Code List'" [PlaceHolder]="''" (keydown)="
                                                focusOnTab($event, '', 'OutSig')
                                                " (TriggerChangeValue)="
                                                    getSigCodeDescOnChange(
                                                        $event
                                                    )
                                                " (TriggerOnEnterValue)="
                                                    getSigCodeDescOnEnter(
                                                        $event
                                                    )
                                                " [IsRequired]="selectedDrug" [DebounceTime]="'75'"
                                                [ControlName]="'SigCode'" [FormGroupName]="erxReq" [FormGroupInvalid]="
                                                    drugformGroupInvalid
                                                " [InputErrors]="
                                                    (erxReq?.controls)[
                                                        'SigCode'
                                                    ]?.errors
                                                " [ErrorDefs]="{
                                                    required: 'Required'
                                                }" (TriggerOnTabbing)="
                                                    getSigCodeDescOnEnter(
                                                        $event
                                                    )
                                                " (TriggerSearchValue)="
                                                    trimSigCodeOnBlur($event)
                                                ">
                                            </eprx-input>
                                        </div>
                                        <div [ngClass]="
                                                selectedDrug
                                                    ? 'col-lg-4 col-md-4 col-sm-4 col-xs-4'
                                                    : 'col-lg-8 col-md-8 col-sm-8 col-xs-8'
                                            ">
                                            <eprx-input [LabelText]="'Sig Description'"
                                                [PlaceHolder]="'Sig'" [ControlName]="'Sig'" [FormGroupName]="erxReq"
                                                (keydown)="
                                                focusOnTab($event, '', 'OutSig')
                                                ">
                                            </eprx-input>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 text-right pull-right padding_top_15px"
                                             *ngIf="
                                                selectedDrug && enableDrugSearch
                                            ">
                                            <button class="hotkey_success hotkey_primary--Override height_2_5rem" id="addtogrid"
                                                 (click)="AddDrugToGrid()" [tabIndex]="
                                                    selectedDrug &&
                                                    enableDrugSearch
                                                        ? null
                                                        : '-1'
                                                " (keydown)="
                                                focusOnTab($event, 'priCode1', 'select')
                                                " appShortcutKey [AltKey]="'g'">
                                                <span>G</span> Add To Grid
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="exprx--common-block erx--body insurance--body">
                                <div class="row">
                                    <div class="col-9">
                                        <h5 class="erx--label--primary margin_top_1_5rem">
                                            DUR
                                        </h5>
                                    </div>
                                    <div class="col-3 text-right pull-right">
                                        <button aria-hidden="true" class="hotkey_success hotkey_primary--Override"
                                            id="durAdd" title="Add" (click)="addPopUp()" appShortcutKey (keydown.tab)="
                                            focusOnTab($event, 'priCode1', 'select')
                                            " [AltKey]="'a'">
                                            <span>A</span>ADD
                                        </button>
                                    </div>
                                </div>

                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-12 overflow_scroll">
                                            <wj-flex-grid #DURlist class="max-height_6rem" [headersVisibility]="'Column'" [itemsSource]="DURWJ"
                                                [isReadOnly]="true" [columnPicker]="'erxChangeReqDURListWJ'" [selectionMode]="'None'"
                                                (updatedView)="init1(DURlist)">
                                                <wj-flex-grid-column [header]="'Action'" [width]="68">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                        <div class="text=center">
                                                            <i class="far fa-edit actions" title="edit" (click)="
                                                                    editNewRxPopUpModal(
                                                                        DURADD,
                                                                        item,
                                                                        item.value
                                                                    )
                                                                "></i>
                                                            <i class="far fa-trash-alt  actions danger-actve" title="delete" (click)="
                                                                    openNewRxDeleteModal(
                                                                        delete,
                                                                        item.value
                                                                    )
                                                                "></i>
                                                        </div>
                                                    </ng-template>
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                                    [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeadersDUR;let i = index"
                                                    [width]="rxheader['width']">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                            {{item[rxheader['hName']]}}
                                                    </ng-template>
                                                </wj-flex-grid-column>
                                            </wj-flex-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="exprx--common-block erx--body insurance--body" *ngIf="enableDrugSearch">
                                <h5 class="erx--label--primary">
                                    Selected Drugs
                                </h5>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-12">
                                            <wj-flex-grid class="max-height_6rem" #DrugSelected [headersVisibility]="'Column'"
                                                [itemsSource]="drugListWJ" [isReadOnly]="true" [selectionMode]="'None'"
                                                [allowSorting]="true" [allowDragging]="'None'" [columnPicker]="'erxChangeReqSelectDrugListWJ'">
                                                <wj-flex-grid-column [header]="'Actions'" [width]="68">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                        <i class="far fa-trash-alt fa-sm actions danger-actve" title="Delete"  (click)="deleteSelectedId(item)"></i>
                                                    </ng-template>
                                                </wj-flex-grid-column>
                                                <wj-flex-grid-column (initialized)="init(DrugSelected)" [header]="rxheader['hName']" [binding]="rxheader['hName']"
                                                [visible]="rxheader['isVisible']"
                                                    *ngFor="
                                                        let rxheader of wjHeadersSelectDrug;
                                                        let i = index"
                                                    title="{{ rxheader['hName'] }}" [width]="rxheader['width']">
                                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                            {{item[rxheader['hName']]}}
                                                    </ng-template>
                                                </wj-flex-grid-column>
                                            </wj-flex-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="exprx--common-block">
                                <h5 class="erx--label--primary">
                                    Diagnosis Code
                                </h5>
                                <div class="eprx--block__content">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <eprx-select [RxSelectId]="
                                                            'priCode1'
                                                        " (keydown)="
                                                        focusOnTab($event, 'priCode2', 'select')
                                                        " [LabelText]="
                                                            'Primary Code 1'
                                                        " [ControlName]="
                                                            'DiagnosisCode'
                                                        " [FormGroupName]="
                                                            PrimaryDiagnosis1
                                                        " [InputValue]="''" [HasMultiple]="false"
                                                        [HasBindLabel2]="true" [BindLabel2]="
                                                            'DIAGDESCR'
                                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                                        [LabelForId]="'pr1'" [List]="
                                                            (patDiagnis
                                                                ?.TempDiagnosis)[
                                                                'length'
                                                            ] > 0
                                                                ? patDiagnis?.TempDiagnosis
                                                                : ''
                                                        ">
                                                    </eprx-select>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                                    [InputValue]="PrimaryDiagnosis1?.value['DiagnosisCode'] ? 'ICD10' : null"
                                                    [ReadOnly]="true"></eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                                    >
                                                    <i class="far fa-search fa-lg actions" (click)="
                                                            openDiagList('1')
                                                        "></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <eprx-select [RxSelectId]="
                                                            'priCode2'
                                                        " [LabelText]="
                                                            'Primary Code 2'
                                                        " [ControlName]="
                                                            'DiagnosisCode'
                                                        " [FormGroupName]="
                                                            PrimaryDiagnosis2
                                                        " [InputValue]="''" [HasBindLabel2]="true" [BindLabel2]="
                                                            'DIAGDESCR'
                                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                                        [LabelForId]="'pr2'" [List]="
                                                            (patDiagnis
                                                                ?.TempDiagnosis)[
                                                                'length'
                                                            ] > 0
                                                                ? patDiagnis?.TempDiagnosis
                                                                : ''
                                                        ">
                                                    </eprx-select>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                                    [InputValue]="PrimaryDiagnosis2?.value['DiagnosisCode'] ? 'ICD10' : null"
                                                    [ReadOnly]="true"></eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                                    >
                                                    <i class="far fa-search fa-lg actions" (click)="
                                                            openDiagList('2')
                                                        "></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <eprx-select [RxSelectId]="
                                                            'secPrime1'
                                                        " [LabelText]="
                                                            'Secondary Code 1'
                                                        " [ControlName]="
                                                            'DiagnosisCode'
                                                        " [FormGroupName]="
                                                            SecondaryDiagnosis1
                                                        " [InputValue]="''" [HasMultiple]="false"
                                                        [HasBindLabel2]="true" [BindLabel2]="
                                                            'DIAGDESCR'
                                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                                        [LabelForId]="'sc1'" [List]="
                                                            (patDiagnis
                                                                ?.TempDiagnosis)[
                                                                'length'
                                                            ] > 0
                                                                ? patDiagnis?.TempDiagnosis
                                                                : ''
                                                        ">
                                                    </eprx-select>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                                    [InputValue]="SecondaryDiagnosis1?.value['DiagnosisCode'] ? 'ICD10' : null"
                                                    [ReadOnly]="true"></eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                                    >
                                                    <i class="far fa-search fa-lg actions" (click)="
                                                            openDiagList('3')
                                                        "></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                    <eprx-select (keydown.tab)="
                                                    focusOnTab($event, '', 'textArea')
                                                        " [RxSelectId]="
                                                            'secPrime1'
                                                        " [LabelText]="
                                                            'Secondary Code 2'
                                                        " [ControlName]="
                                                            'DiagnosisCode'
                                                        " [FormGroupName]="
                                                            SecondaryDiagnosis2
                                                        " [InputValue]="''" [HasMultiple]="false"
                                                        [HasBindLabel2]="false" [BindLabel2]="
                                                            'DIAGDESCR'
                                                        " [BindLabel]="'DIAGCODE'" [BindValue]="'DIAGCODE'"
                                                        [LabelForId]="'sc2'" [List]="
                                                            (patDiagnis
                                                                ?.TempDiagnosis)[
                                                                'length'
                                                            ] > 0
                                                                ? patDiagnis?.TempDiagnosis
                                                                : ''
                                                        ">
                                                    </eprx-select>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                                    [InputValue]="SecondaryDiagnosis2?.value['DiagnosisCode'] ? 'ICD10' : null"
                                                    [ReadOnly]="true"></eprx-input>
                                                </div>
                                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding_top_25px edit--drug pl-0"
                                                    >
                                                    <i class="far fa-search fa-lg actions" (click)="
                                                            openDiagList('4')
                                                        "></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button class="hotkey_success" footer id="sendReq" tabindex="18" (click)="checkSPIServiceLevel()" appShortcutKey
            [AltKey]="'s'">
            <span>S</span> {{ sendButtonText }}
        </button>
        <button class="hotkey_success" footer (click)="mainPopUpClosed()" tabindex="19" appShortcutKey [AltKey]="'c'">
            <span>C</span> Cancel Request
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #DURADD let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">DUR Entry</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body box-body">
        <div class="box box-default box-solid">
            <div class="eprx--block__header">
                <span class="eprx--header__heading"></span>
            </div>
            <div class="box-body">
                <!-- <div class="row"> -->
                    <div class="col-12">
                        <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [IsFirstElem]="true" [LabelText]="'Reason For Service'" [PlaceHolder]="''"
                            [ControlName]="'ServiceReasonCode'" [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'"
                            [BindLabel2]="'Remarks'" [BindValue]="'Name'" [LabelForId]="'Name'" [List]="defaultval?.servReason"
                            [IsRequired]="false" [InputErrors]="
                                                (DurFG?.controls)['ServiceReasonCode']?.errors"
                            [MarkAsTouched]="DurFG.get('ServiceReasonCode').touched" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7">
                        <eprx-select [LabelText]="'Professional Service'" [PlaceHolder]="''"
                            [MarkAsTouched]="DurFG.get('ProfessionalServiceCode').touched" [ControlName]="'ProfessionalServiceCode'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                            [BindValue]="'Name'" [LabelForId]="'Name'" [List]="defaultval?.profServs" [IsRequired]="false" [InputErrors]="
                                                (DurFG?.controls)['ProfessionalServiceCode'] 
                                                    ?.errors
                                            " [Show2Values]="true" [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid">
                        </eprx-select>
                    </div>
                    </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Result Of Service'" [PlaceHolder]="''"
                            [MarkAsTouched]="DurFG.get('ServiceResultCode').touched" [ControlName]="'ServiceResultCode'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                            [BindValue]="'Name'" [LabelForId]="'Name'" [List]="defaultval?.servResult" [IsRequired]="false"
                            [InputErrors]="
                                                (DurFG?.controls)['ServiceResultCode']?.errors
                                            " [ErrorDefs]="{ required: 'Required' }" [Show2Values]="true" [FormGroupInvalid]="formGroupInvalid">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7">
                        <eprx-select [LabelText]="'Level Of Effort'" [PlaceHolder]="''" [ControlName]="'LevelOfEffort'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                            [BindValue]="'Name'" [LabelForId]="'Name'" [List]="defaultval.effortLvl" [IsRequired]="false"
                            [InputErrors]="(DurFG?.controls)['LevelOfEffort']?.errors"
                            [MarkAsTouched]="DurFG.get('LevelOfEffort').touched" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-input [LabelText]="'Co-Agent ID'" [PlaceHolder]="''" [ControlName]="'CoAgentID'" [MaxLength]="19"
                            [InputType]="'ALPHANUMERIC'" [FormGroupName]="DurFG" [MarkAsTouched]="DurFG.get('CoAgentID').touched">
                        </eprx-input>
                    </div>
                
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7">
                        <!--<eprx-input [LabelText]="'Co-Agent ID Type'" [PlaceHolder]="''" [ControlName]="'CoAgentIdType'" [MaxLength]="2"
                            [InputType]="'ALPHANUMERIC'" [FormGroupName]="DurFG" [IsRequired]="true"
                            [MarkAsTouched]="DurFG.get('CoAgentIdType').touched"
                            [InputErrors]="(DurFG?.controls)['CoAgentIdType']?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid">
                        </eprx-input>-->
                        <eprx-select [LabelText]="'Co-Agent ID Type'" id="CoAgentId" [PlaceHolder]="''"
                            [ControlName]="'CoAgentIdType'" [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'"
                            [BindLabel2]="'Remarks'" [BindValue]="'Name'" [LabelForId]="'Id'" [List]="systemData?.coAgentIdCat"
                            [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid"
                            [IsRequired]="DurFG?.value['CoAgentID']"
                            [MarkAsTouched]="DurFG?.controls['CoAgentIdType']?.touched" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 padding--right__7">
                        <eprx-select [LabelText]="'Clinical Significance'" [PlaceHolder]="''"
                            [MarkAsTouched]="DurFG.get('ClinicalSignificanceCode').touched" [ControlName]="'ClinicalSignificanceCode'"
                            [FormGroupName]="DurFG" [HasMultiple]="false" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                            [BindValue]="'Name'" [LabelForId]="'Name'" [List]="defaultval?.clinicalSignf" [IsRequired]="false"
                            [InputErrors]="(DurFG?.controls)['ClinicalSignificanceCode']?.errors" [ErrorDefs]="{ required: 'Required' }"
                            [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true">
                        </eprx-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--left__7">
                        <eprx-text-area [FormGroupName]="DurFG" [ControlName]="'AcknowledgementReason'"
                            [LabelText]="'Pharmacist Comment'" [Rows]="1" [PlaceHolder]="'Comments'">
                        </eprx-text-area>
                    </div>
                    </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" *ngIf="!editMode" (click)="addNewDUR()" appShortcutKey [AltKey]="'s'">
                    <span>S</span> Save
                </button>
                <button class="hotkey_success" *ngIf="editMode" (click)="editNewRxDUR()" appShortcutKey [AltKey]="'u'">
                    <span>U</span> Update
                </button>
                <button class="hotkey_success" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'">
                    <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>{{warningText?.deleteConfrmMsg}}</span>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="deleteNewRxDur()" appShortcutKey
                    InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="n">
                    <b>N</b> NO
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #diagList let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Diagnosis List</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-diag-list [FmChangeReq]="true" [isPopup]="true" (emitOnDbClick)="closeDiagList($event)"></app-diag-list>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="c">
                    <b>C</b> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #BILLTO let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeBillModal()">
        <h4 header>Select Insurance To Bill</h4>
        <span body>
            <div class="eprx--block__content">
                <div *ngIf="!insureList || insureList?.length === 0" class="text-center">
                    <h1>There are no Insurances for this patient.</h1>
                </div>
                <span *ngIf="insureList?.length > 0">
                    <wj-flex-grid #insurances [headersVisibility]="'Column'" [selectionMode]="'None'"
                        [itemsSource]="insurncListModified" [isReadOnly]="true" [columnPicker]="''">
                        <wj-flex-grid-column header="Select">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                <input type="radio" name="SelectedIns" [disabled]="item.parentInsuId" [title]="item.parentInsuId ? getTitle(insureList) : ''" (change)="
                                        selectedInsurence(
                                            $event?.target?.checked,
                                            item?.insuranceid,
                                            true
                                        )
                                    " [value]="item.insuranceid" [checked]="
                                        patInsurList?.length > 1
                                            ? item?.insuranceid ==
                                              insureList[0]?.insuranceid
                                            : 'true'
                                    " />
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'Ins Code'" [width]="" [binding]="'insuType'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'Insurance Name'" [width]="400" [binding]="'insurancename'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'Insurance ID#'" [width]="" [binding]="'policynum'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'Bill Order'" [width]="" [binding]="'InsuPriority'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'BIN'" [width]="" [binding]="'binnum'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="'PCN'" [width]="160" [binding]="'ProcessorNum'">
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                </span>
            </div>
        </span>
        <button footer ngbAutofocus class="hotkey_primary" (click)="closeBillModal(true)" appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
        <button footer class="hotkey_primary mr-0" (click)="closeBillModal()" appShortcutKey
            InputKey="c">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #MissingFields let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 header>Some values are missing {{DrugMissingForm.value.DrugName ? 'for ' + DrugMissingForm.value.DrugName : ''}}</h4>
        <button header type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="showDrugDescription">
                <eprx-select [LabelText]="'Dosage Form Description'" [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmDescId'" [AutoFocus]="true" [FormGroupName]="DrugMissingForm"
                    [List]="dropdownData?.DoseFrmDesc"  [ShowLabel2]="true"  [BindLabel2]="'Name'" [BindLabel]="'Code'"
                    [BindValue]="'Id'" [LabelForId]="'Id'" [InputErrors]="DrugMissingForm.controls['DoseFrmDescId']?.errors"
                    [IsRequired]="false" [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formSubmitted">
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select [LabelText]="'Dosage Form Code'" [PlaceHolder]="'Dosage Form Code'"
                    [ControlName]="'DoseFrmCodeId'" [AutoFocus]="true" [FormGroupName]="DrugMissingForm"
                    [List]="dropdownData?.DoseFrmCode" [ShowLabel2]="true"  [BindLabel2]="'Name'" [BindLabel]="'Code'"
                    [BindValue]="'Id'" [LabelForId]="'Id'" [InputErrors]="
                        DrugMissingForm.controls['DoseFrmCodeId']?.errors
                    " [IsRequired]="showDrugDescription" [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formSubmitted">
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select [LabelText]="'Drug Strength Qualifier'" [PlaceHolder]="'Drug Strength Qualifier'"
                    [ControlName]="'DrugStrQualId'" [FormGroupName]="DrugMissingForm" [List]="dropdownData?.DrugStrQual"
                    [ShowLabel2]="true"  [BindLabel2]="'Name'" [BindLabel]="'Code'"  [BindValue]="'Id'"
                    [LabelForId]="'Id'" [InputErrors]=" DrugMissingForm.controls['DrugStrQualId']?.errors
                    " [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formSubmitted">
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select [LabelText]="'Potency Unit Code'" [PlaceHolder]="'Potency Unit Code'"
                    [ControlName]="'PotencyUnitCodeId'" [FormGroupName]="DrugMissingForm"
                    [List]="dropdownData?.PotencyUnitCode" [ShowLabel2]="true"  [BindLabel2]="'Name'" [BindLabel]="'Code'"
                    [BindValue]="'Id'" [LabelForId]="'Id'" [InputErrors]="
                        DrugMissingForm.controls['PotencyUnitCodeId']?.errors
                    " [IsRequired]="true" [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formSubmitted">
                </eprx-select>
            </div>
            <div class="col-md-12">
                <eprx-select [LabelText]="'Quantity Qualifier'" [PlaceHolder]="'Quantity Qualifier'"
                    [ControlName]="'QtyQualId'" [FormGroupName]="DrugMissingForm" [List]="dropdownData?.QtyQual"
                    [ShowLabel2]="true"  [BindLabel2]="'Name'" [BindLabel]="'Code'"  [BindValue]="'Id'"
                    [LabelForId]="'Id'" [InputErrors]="DrugMissingForm.controls['QtyQualId']?.errors
                    " [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formSubmitted">
                </eprx-select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="hotkey_success" appShortcutKey [AltKey]="'s'" (click)="saveDrugFields()">
            <span>S</span> Save
        </button>
        <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="modal.close()">
            <span>C</span> Cancel
        </button>
    </div>
</ng-template>

<ng-template #canDoRxChange let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modal.close()">
        <h4 header>SPI Does Not Support Rx Change Request</h4>
        <span body>
            <p>
                The Selected SPI for this Prescriber may not have valid service level to process 'Rx Change Request' and may get
                rejected, you can try with another SPI with rx change service level if available.
            </p>
            <p>
                Are you sure you want to send it anyway?
            </p>
        </span>
        <button footer ngbAutofocus type="button" class="hotkey_primary" (click)="sendErxChangeReq(); modal.close()"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer type="button" class="hotkey_primary" (click)="modal.close()" appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>
