import { Injectable } from "@angular/core";
import { PrivMaskGuard } from "../guards";
import { AccCategoryE, RxE, RxModel, RxTransmisParams } from "../models";
import { CommonService } from "../services";
import { NRxUtils } from "./nRx.util";
import { CommonUtil } from "./common.util";

@Injectable({
    providedIn: "root"
})
export class TransmissionUtils {
    prevRxPays: any[];
    accessPrivCate: typeof AccCategoryE;
    rxPrivs: typeof RxE;
    confirmModel: any;
    _modalService: any;
    confirmModalName: string;
    payrecs: any;

    constructor(private _privMaskGuard: PrivMaskGuard, private _nrxUtls: NRxUtils,  private _commonServ: CommonService, private _commUtil: CommonUtil) {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;
    }

    createTransmissionParams(rxInfo, transmType: string, revIns?: any, revInsPrio?: any, payRecords?: any) {
        const transType = (transmType === "TransReverse" || transmType === "ReversReTrans") ? "B2" :
                transmType === "TransAdjst" ? "B3" : "B1";

        const insurence = (rxInfo && rxInfo.Patient && rxInfo.Patient.insurance) ? rxInfo.Patient.insurance.filter(
            x => x.insuranceid === +rxInfo.RefillInsu.InsuId
        )[0] : null;
        this.payrecs = payRecords && payRecords.length ? payRecords.filter((item: any) => item.RecType === "P") : [];
        const billAs = rxInfo && rxInfo.RefillInsu && rxInfo.RefillInsu.InsuPriId ? rxInfo.RefillInsu.InsuPriId : null;
        revInsPrio = (transmType === "ReTransmission" && this.payrecs) ? this.payrecs.length : revInsPrio;
        const transmParms = new RxTransmisParams();
        transmParms.rxId = rxInfo?.Prescription?.Id;
        transmParms.PrescRefillId = rxInfo?.PrescReFill.Id;
        transmParms.patientId = rxInfo?.Prescription?.PatientId;
        transmParms.TransType = transType;
        transmParms.Ins_Code = insurence ? insurence.insuType : null;
        transmParms.BillAs =
                billAs === 2 ? "S" : billAs === 3 ? "T" : billAs === 4 ? "Q" : "P";
        transmParms.PrescNum = rxInfo?.Prescription?.PrescNum;

        if (transmType === "TransReverse") {
            transmParms.Ins_Code = revIns;
            transmParms.BillAs = (revInsPrio === 1 ? "S" : revInsPrio === 2 ? "T" : revInsPrio === 3 ? "Q" : "P");
        }

        if (transmType === "ReversReTrans" || transmType === "TransAdjst" || transmType === "ReTransmission") {
            transmParms.Ins_Code = revIns;
            transmParms.BillAs = (revInsPrio === 1 ? "S" : revInsPrio === 2 ? "T" : revInsPrio === 3 ? "Q" : "P");
        }
        transmParms.BillStatusId = rxInfo?.RxBill.StatusId;
        transmParms.DrugTypeId = rxInfo?.Drug.drugtypeid;
        transmParms.InsuCarrierId = rxInfo?.insuranceInfo.insuCarrier.Id;
        transmParms.InsuPlanId = rxInfo?.insuranceInfo.insuPlan.Id;
        transmParms.InsuranceId = rxInfo?.insuranceInfo.insurance.Id;
        transmParms.RepriceSetting = this._commonServ.getSetttingValue("RxSettings", "REPRICE_UNBILLEDRX_BEFORE_TRANSMITTING");
        return transmParms;
    }

    getTransmissionResponse(responseStatus: any) {
        let transcResp = null;
        if (responseStatus === "P") {
            transcResp = "Paid";
        } else if (responseStatus === "R") {
            transcResp = "Rejected";
        } else if (responseStatus === "A") {
            transcResp = "Approved";
        } else if (responseStatus === "C") {
            transcResp = "Captured";
        } else if (responseStatus === "D") {
            transcResp = "Duplicate Paid";
        } else if (responseStatus === "S") {
            transcResp = "Duplicate of Approved";
        } else {
            transcResp = responseStatus;
        }
        return transcResp;
    }

    getTransmissionBillAs(responseStatus: any, billAsString: string) {
        let billAs = null;
        if (responseStatus === "P") {
            billAs = billAsString === "P" ? 1 : billAsString === "S" ? 2 : 3;
        } else if (responseStatus === "A") {
            billAs = billAsString === "P" ? 1 : billAsString === "S" ? 2 : 3;
        } else if (responseStatus === "C") {
            billAs = billAsString === "P" ? 1 : billAsString === "S" ? 2 : 3;
        } else if (responseStatus === "D") {
            billAs = billAsString === "P" ? 1 : billAsString === "S" ? 2 : 3;
        } else {
            billAs = billAsString === "P" ? 1 : billAsString === "S" ? 2 : 3;
        }
        return billAs;
    }

    calculateTotalTransData(trasData: any, type?: any) {
        let totalTransData = 0;
        if (trasData.ClaimProcess &&
            trasData.ClaimProcess.lstRxPay &&
            trasData.ClaimProcess.lstRxPay.length > 0
        ) {
            // this.prevRxPays = trasData.ClaimProcess.lstRxPay.filter(
            //     pay => pay.RECTYPE !== "R"
            // );
            this.prevRxPays = trasData.ClaimProcess.lstRxPay.filter(
                pay => {
                    return trasData.ClaimProcess.BILLAS === "S" ? pay.RECTYPE !== "R" && pay.COVERAGECODE !== 2
                    : pay.RECTYPE !== "R";
                }
            );
            if (this.prevRxPays && this.prevRxPays.length) {
                this.prevRxPays.map(
                    p => (totalTransData += +p.TOTAMTPAID)
                );
            }
            if (type === "TransMessage") {
                totalTransData =
                totalTransData + (+trasData.transmissionDetails.Deductible);
            } else {
                totalTransData =
                totalTransData + (+trasData.transmissionDetails.Deductible) + (+trasData.transmissionDetails.TotalAmount);
            }
        } else {
            totalTransData =
                +trasData.transmissionDetails.TotalAmount +
                +trasData.transmissionDetails.Deductible;
        }
        totalTransData += +trasData.transmissionDetails.VoucherAmnt;
        return +totalTransData;
    }

    calculateRxCost(trasData: any) {
        if (trasData && trasData.transmissionDetails && trasData.transmissionDetails.InsAmountList
            && trasData.transmissionDetails.InsAmountList.length) {
                const cost = trasData.transmissionDetails.InsAmountList.find(v => v["Ins"] === "Cost (Rx)");
            return cost ? +cost.Amount : 0;
        } else {
            return 0;
        }
    }
    calculateProfitLoss(trasData: any) {
        if (trasData && trasData.transmissionDetails && trasData.transmissionDetails.InsAmountList
            && trasData.transmissionDetails.InsAmountList.length) {
                const cost = trasData.transmissionDetails.InsAmountList.find(v => v["Ins"] === "Profit");
            return cost ? +cost.Amount : 0;
        } else {
            return 0;
        }
    }

    checkIsAmountPaidLess(_rxInfo, trasData: any) {
        const responsStatus = trasData.transmissionDetails.ResponseStatus;
        const isAmountLess = this.calculateRxCost(trasData) > this.calculateTotalTransData(trasData) ? true : false;
        if (
            responsStatus !== "R" &&
            isAmountLess &&
            this._privMaskGuard.canActivate(
                this.accessPrivCate.Rx,
                this.rxPrivs.CanOverrideClaimsPaidLessThenCost
            ) && +this._nrxUtls.getSetngKeyVal("RxSettings", "AUTH_REQ_CLAIM_PAID_LESS_COST")
        ) {
            return true;
        }
        return false;
    }
    
    isTrnsRjctActnEnbld(transmResp, transmissionType) {
        return ((transmResp.transmissionDetails.ResponseStatus === "R" ||
                transmResp.transmissionDetails.ResponseStatus === "TRANSMISSION ERROR") &&
                transmissionType !== "TransReverse" &&
                transmissionType !== "TransMessage");
    }

    isTransmsnPaid(transmResp) {
        return (transmResp.transmissionDetails.ResponseStatus === "P" ||
        transmResp.transmissionDetails.ResponseStatus === "A" ||
        transmResp.transmissionDetails.ResponseStatus === "C" ||
        transmResp.transmissionDetails.ResponseStatus === "D" ||
        transmResp.transmissionDetails.ResponseStatus === "S");
    }

    isTransmsnPaidRej (transmResp) {
        return  transmResp.transmissionDetails.ResponseStatus === "R";
    }

    checkHasNextInsurncs(rxInfo: RxModel, payRcrds: any[], insuList?) {
        let insuLst = [];
        const M3PInsu = this._commUtil.isPatientHasM3P(insuList);
        var InsuId1,InsuId2,InsuId3;
        if(rxInfo?.RefillInsuList?.length && rxInfo?.PrescReFill?.CarryFwdBillSequence === true) {
            InsuId1 =  rxInfo.RefillInsuList.find(val => val.InsuPriId === 1);
            InsuId2 =  rxInfo.RefillInsuList.find(val => val.InsuPriId === 2);
            InsuId3 =  rxInfo.RefillInsuList.find(val => val.InsuPriId === 3);
        }
        const  sTrans = insuList.filter(z => InsuId2 ? +z.insuranceid === InsuId2.InsuId : +z.insuPriId === 2)[0];
        const  terTrans = insuList.filter(z => InsuId3 ? +z.insuranceid === InsuId3.InsuId : +z.insuPriId === 3)[0];
        const  primaryTrans = insuList.filter(z => InsuId1 ? +z.insuranceid === InsuId1.InsuId : +z.insuPriId === 1)[0];
        if (M3PInsu && !this._commUtil.isParentInsuBilled(M3PInsu)) {
            insuLst = insuLst.filter(item => !item.parentInsuId);
        }
        if (insuList && insuList.length > 0) {
            if (rxInfo.RefillInsu.InsuPriId === 1) {
                insuLst = (payRcrds && payRcrds.length > 0) ? insuList.filter(x =>
                    !payRcrds.some(y => (x.insuType + "").trim() === (y.Ins_Code + "").trim() &&  (((y.RecType + "").trim() !== "R")))) :
                    insuList.filter(x => +x.insuPriId !== 1);
             
                if(( !payRcrds.some(y => (sTrans?.insuType + "").trim() === (y.Ins_Code + "").trim()) && sTrans) ) {
                    insuLst  = sTrans;
                } else if(!payRcrds.some(y => (primaryTrans?.insuType + "").trim() === (y.Ins_Code + "").trim()) && primaryTrans) {
                    insuLst  = primaryTrans;
                } else if ((!payRcrds.some(y => (terTrans?.insuType + "").trim() === (y.Ins_Code + "").trim()) && terTrans)) {
                    insuLst  = terTrans;
                } else {
                    insuLst = null;
                }
            } else if (rxInfo.RefillInsu.InsuPriId === 2) {
                insuLst = (payRcrds && payRcrds.length > 0) ? insuList.filter(x =>
                    !payRcrds.some(y => (x.insuType + "").trim() === (y.Ins_Code + "").trim() &&  (((y.RecType + "").trim() !== "R")))) :
                   insuList.filter(x => +x.insuPriId !== 1 && +x.insuPriId !== 2);
                   if(( !payRcrds.some(y => (sTrans?.insuType + "").trim() === (y.Ins_Code + "").trim()) && sTrans) ) {
                        insuLst  = sTrans;
                    } else if ((!payRcrds.some(y => (terTrans?.insuType + "").trim() === (y.Ins_Code + "").trim()) && terTrans)) {
                        insuLst  = terTrans;
                    } else if(!payRcrds.some(y => (primaryTrans?.insuType + "").trim() === (y.Ins_Code + "").trim()) && primaryTrans) {
                        insuLst  = primaryTrans;
                    }  else {
                        insuLst = null;
                    }
            } else if (rxInfo.RefillInsu.InsuPriId === 3 && M3PInsu && !this._commUtil.isM3PBilled(M3PInsu) && this._commUtil.isParentInsuBilled(M3PInsu)) {
                insuLst = insuList.filter(z => +z.parentInsuId)[0];
            }
        }
        return insuLst;
    }

    getRemainingInsuranceToBill(rxInfo: RxModel, _payRcrds: any[], systemData, isFromcheck: boolean = false) {
        let insuLst = [];
        if (rxInfo && rxInfo.Patient && rxInfo.Patient.insurance) {
            insuLst = rxInfo.Patient.insurance;
            if(!isFromcheck) {
                insuLst = insuLst.filter(x => 
                    x.insurerid !== +systemData["DefaultValuese"][0]["CashInsurerId"] &&
                     (x.ActiveStatus === 1 && x.insurerActiveStatus) 
                 );
                 
            } else {
                insuLst = insuLst.filter(x => {
                    const transList = (rxInfo?.RxPay?.find((item: any) =>(item?.Ins_Code == x?.InsurerCode) ));
                    return  (x.insurerid !== +systemData["DefaultValuese"][0]["CashInsurerId"]  &&
                    (x.ActiveStatus === 1 && x.insurerActiveStatus) && !transList);
                }
                 );
            }
            insuLst = insuLst.filter(item => !item.IsCash);
            return  insuLst;
        }
    }

    checkHasNextInsurncsFmEditRx(rxInfo: RxModel, payRcrds: any[], insuList?, FrmNxtBill?: boolean) {
        let insuLst = [];
        let insurance = null;
        const M3PInsu = this._commUtil.isPatientHasM3P(insuList);
        if (insuList && insuList.length > 0) {
            if (rxInfo.RefillInsu.InsuPriId === 1 || (FrmNxtBill && rxInfo.RefillInsu.InsuPriId === 2)) {
                insuLst = insuList.filter(x =>
                    !payRcrds.some(y => (x.insuType + "").trim() === (y.Ins_Code + "").trim() &&  (((y.RecType + "").trim() !== "R"))));

                insurance = insuLst.filter(z => +z.insuPriId === 2)[0];
            } else if (rxInfo.RefillInsu.InsuPriId === 2 || (FrmNxtBill && rxInfo.RefillInsu.InsuPriId === 3)) {
                insuLst = insuList.filter(x =>
                    payRcrds.some(y => x.insuType !== y.Ins_Code));

                insurance = insuLst.filter(z => +z.insuPriId === 3)[0];
            } else if (M3PInsu && !this._commUtil.isM3PBilled(M3PInsu) && this._commUtil.isParentInsuBilled(M3PInsu)) {
                insurance = insuList.filter(z => +z.parentInsuId)[0];
            }
        }
        if (M3PInsu && !this._commUtil.isParentInsuBilled(M3PInsu)) {
            insuLst = insuLst.filter(item => !item.parentInsuId);
        }
        if  (insurance) {
            return insurance;
        } else {
            if (insuLst.length > 0) {
                return insuLst[0];
            } else {
                return insurance;
            }
        }
    }

    getRemainingInsuranceToBillFmEdit(rxInfo: RxModel, payRcrds: any[], systemData) {
        let insuLst = [];
        insuLst = (rxInfo && rxInfo.Patient && rxInfo.Patient.insurance) ? rxInfo.Patient.insurance.filter(x =>
            !payRcrds.some(y => (x.insuType + "").trim() === (y.Ins_Code + "").trim() &&  (((y.RecType + "").trim() !== "R")))) : null;
        insuLst = insuLst.filter(x =>
            x.insurerid !== +systemData["DefaultValuese"][0]["CashInsurerId"] && (x.ActiveStatus === 1 && x.insurerActiveStatus));
        insuLst = insuLst.filter(item => !item.IsCash);
        return insuLst;
    }
}
