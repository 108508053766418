import { Component, OnInit, Output, ViewChild, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RefillDue, SystemData } from 'src/app/models';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ReportsService, AlertService, CommonService, PreviousRouteService } from 'src/app/services';
import { PrintService } from 'src/app/services/print.service';
import { BaseUtils, ValidateFormFieldsUtils, CommonUtil } from 'src/app/utils';
import { CollectionView } from  '@grapecity/wijmo';
import { MsgConfig } from 'src/app/app.messages';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-refill-filters',
  templateUrl: './refill-filters.component.html',
})
export class RefillFiltersComponent implements OnInit {
  openPopup: any;
  refillDueFG: FormGroup;
  refilDue: any;
  pdfContent: any;
  dailylogDrops: any;
  today: any;
  minDate: { year: number; month: number; day: number };
  insuParams: {
    PlanCodeSearchText: any;
    InsNameSearchText: any;
    PageNumber: number;
    PageSize: number;
  };
  insuranceData: any;
  unsubscribe$: Subject<void> = new Subject();
  systemData: SystemData;
  maxDate: { year: number; month: number; day: number; };
  mindate: any;
  showPreview = false;
  StatesList: any;
  patientCatData: any[];
  ZipcodesList: any[] = [];
  ZipHeaders = [
    "Zip Code",
    "State",
    "City"
  ];
  ZipCodesWJ: any;
  selectedRecords: any[] = [];
  fromDueDateList: any[] = [];
  StName: any;
  ZipCodeFG: FormGroup;

  @ViewChild("ZIPCODESPOPUP", { static: true })
  ZIPCODESPOPUP: any;
  @ViewChild("flexx", { static: true })
  presInputValue: any = null;
  fileorAll: string;
  optedInsList: any;
  prevSearchValue: any;
  modalRef: any;
  activeHeadersIns: string[];
  insuCodes: any;
  openForInsCode = false;
  insucodeList: any;

  @ViewChild("REFILLDUE", { static: true })
  REFILLDUE: any;

  @ViewChild("insuPopUpToLink",{static:true})
  insuPopUpToLink: any;
  inputValues: any;
  formGroupInvalidDate = false;
  PharmStateId: any;
  pharmacistDet: any;

  @Input()
  set PatchInputValues(data: any) {
    if (data) {
      this.inputValues = data;
    }
  }
  @Input() editTaskData : any;
  @Input() editMode : any;
  @Output()
  IspopUpclosed = new EventEmitter<any>();
  flexx: any;
  insuWJ: CollectionView;

  constructor(private _fb: FormBuilder,
    private _reportsServ: ReportsService,
    private _alertSer: AlertService,
    private _sanitizer: DomSanitizer,
    private _printServ: PrintService,
    private _router: Router,
    private _baseUtils: BaseUtils,
    private _commonServ: CommonService,
    private _prevSer: PreviousRouteService,
    private _modalService: NgbModal,
    private _formUtils: ValidateFormFieldsUtils,
    private _commonUtils: CommonUtil,
    private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
    const appUsrData = this._commonServ.appUserData$["source"]["value"];
    this.pharmacistDet = appUsrData.filter(val => !(val.Persona.includes("0")));
    this.today = moment.utc().local().format("MM/DD/YYYY");
    this.openPopup = this._modalService.open(this.REFILLDUE, { keyboard: false, windowClass: "model_widht_51", centered: true, backdrop: false });
    this.maxDate = {
      year: moment(this.today).year(),
      month: moment(this.today).month() + 1,
      day: moment(this.today).date()
    };
    this.createFG();
    this._commonServ.systemData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      this.systemData = resp;
    });
    this.insuranceData = this._commonServ.insuranceData$["source"]["value"];
    this.mindate = moment()
      .subtract(6, "month")
      .format("MM/DD/YYYY");
    this._commonUtils.setDefaultValues(this.refillDueFG,"refillDueFG");
    this.setDefaultValues();
    this.fileorAll = "all";
   // this.setDefaultValuesForInit();
   this.presInputValue = "";
    if (this.inputValues) {
      this.fileorAll = this.inputValues.FiledRxs;
      this.refillDueFG.patchValue(this.inputValues);
      if (this.refillDueFG.controls["AllForSinceDate"].value === "d") {
        this.refillDueFG.controls["RefillFillDate"].patchValue(this.today);
      } else {
        this.refillDueFG.controls["RefillFillDate"].patchValue(moment(this.refillDueFG.value.RefillFillDate).format("MM/DD/YYYY"));
      }
      if (this.refillDueFG.controls["AllForFromDueDate"].value === "d") {
      this.refillDueFG.controls["refillsDueFromDate"].patchValue(this.today);
      } else {
        this.refillDueFG.controls["refillsDueFromDate"].patchValue(moment(this.refillDueFG.value.refillsDueFromDate).format("MM/DD/YYYY"));
      }
      if (this.refillDueFG.controls["AllForToDueDate"].value === "d") {
      this.refillDueFG.controls["refillsDueToDate"].patchValue(this.today);
      } else {
        this.refillDueFG.controls["refillsDueToDate"].patchValue(moment(this.refillDueFG.value.refillsDueToDate).format("MM/DD/YYYY"));
      }
      this.presInputValue = this.inputValues.PrescriberName ? this.inputValues.PrescriberName : "";
      this.patchDrugClassValues();

    }

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}

  patchDrugClassValues() {
    if (this.inputValues.drugClass === 1 ) {
      this.refillDueFG.controls["drugClass"].patchValue("All");
    } else if (this.inputValues.drugClass === 2) {
      this.refillDueFG.controls["drugClass"].patchValue("Class 2");
    } else if (this.inputValues.drugClass === 3) {
      this.refillDueFG.controls["drugClass"].patchValue("Class 3");
    } else if (this.inputValues.drugClass === 4) {
      this.refillDueFG.controls["drugClass"].patchValue("Class 4");
    } else if (this.inputValues.drugClass === 5) {
      this.refillDueFG.controls["drugClass"].patchValue("Class 5");
    } else if (this.inputValues.drugClass === 7) {
      this.refillDueFG.controls["drugClass"].patchValue("Exclude Controls");
    } else if (this.refillDueFG.value["drugClass"] = 6) {
      this.refillDueFG.controls["drugClass"].patchValue("All Control Drugs(2-5)");
    } else if (this.refillDueFG.value["drugClass"] = 8) {
      this.refillDueFG.controls["drugClass"].patchValue("Class 1");
    }

    if (this.refillDueFG.value["billSearch"] === null) {
      this.refillDueFG.controls["billSearch"].patchValue([0]);
    } else if (this.refillDueFG.value["BillType"] === "1") {
        this.refillDueFG.controls["billSearch"].patchValue([1]);
    } else if (this.refillDueFG.value["BillType"] === "2") {
        this.refillDueFG.controls["billSearch"].patchValue([2]);
    }  else if ((this.refillDueFG.value["BillType"] === "12") && (this.refillDueFG.value["BillStatusAllSelected"])) {
        this.refillDueFG.controls["billSearch"].patchValue([0]);
    } else if (this.refillDueFG.value["BillType"] === "12") {
        this.refillDueFG.controls["billSearch"].patchValue([1, 2]);
    }
  }

  async setDefaultValues() {
  const response = await this._reportsServ.getZipcodes().toPromise();
   if (response) {
        this.StatesList = response.State;
    }
    this.patientCatData = [{ Code: "All", Id: 0, IsActive: true, Name: "Patient Category" }];
    this.fromDueDateList = [
      { Name: "Today 's Date", Value: "d" },
      { Name: "Today 's Date +", Value: "d+" },
      { Name: "Today 's Date -", Value: "d-" },
      { Name: "Fixed Date", Value: "f" }
    ];
    this._commonServ.getCommonDropDownValues({IsFacility : false , IsPatientCategory: true , IsPayor: false , IsInsuranceList: false})
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if (resp) {
        const dailylogDrops = resp;
        if (dailylogDrops["PatientCategories"] && dailylogDrops["PatientCategories"].length) {
          this.patientCatData = this.patientCatData.concat(dailylogDrops["PatientCategories"]);
        }
      }
    });
    const pharmacyState = this._commonServ.getSetttingValue("TenantSettings", "TNT_STATE");
    this.PharmStateId = this.StatesList.find((item: any) => {
            return (item.Name === pharmacyState);
    });
  }


  // setDefaultValuesForInit() {

  // }

  setForRefillDate(eve) {
    if (this.refillDueFG.controls["AllForSinceDate"].value === "d") {
      this.refillDueFG.controls["RefillFillDate"].patchValue(this.today);
    }
  }

  setForFromDate(eve) {
    if (this.refillDueFG.controls["AllForFromDueDate"].value === "d") {
        this.refillDueFG.controls["refillsDueFromDate"].patchValue(this.today);
      }
  }

  setForDueDate(eve) {
    if (this.refillDueFG.controls["AllForToDueDate"].value === "d") {
        this.refillDueFG.controls["refillsDueToDate"].patchValue(this.today);
      }
  }

  navUpForSinceDate() {
    // tslint:disable-next-line: radix
    let sinceDays = parseInt(this.refillDueFG.value.SinceDateDays);
    if (this.refillDueFG.controls["AllForSinceDate"].value === 'd+' &&  parseInt(this.refillDueFG.controls["SinceDateDays"].value) >=0) {
      if (sinceDays <= 999) {
        this.refillDueFG.controls["SinceDateDays"].patchValue(++sinceDays);
      }
    }

    if (this.refillDueFG.controls["AllForSinceDate"].value === 'd-' &&  parseInt(this.refillDueFG.controls["SinceDateDays"].value) <0) {
      if (sinceDays <= 999) {
        this.refillDueFG.controls["SinceDateDays"].patchValue(++sinceDays);
    }
    }
  }

  navDownForSinceDate() {
    let sinceDays = parseInt(this.refillDueFG.value.SinceDateDays);
    if (this.refillDueFG.controls["AllForSinceDate"].value === 'd+' && parseInt(this.refillDueFG.controls["SinceDateDays"].value) >0) {
      if (sinceDays > 0) {
        this.refillDueFG.controls["SinceDateDays"].patchValue(--sinceDays);
    }
    }

    if (this.refillDueFG.controls["AllForSinceDate"].value === 'd-' &&  parseInt(this.refillDueFG.controls["SinceDateDays"].value) <=0) {
      if (sinceDays > 0 ) {
        this.refillDueFG.controls["SinceDateDays"].patchValue(--sinceDays);
    }
    }
}

  navUpForFromDays() {
    let fromDueDays = parseInt(this.refillDueFG.value.FromDueDateDays)
    if (this.refillDueFG.controls["AllForFromDueDate"].value === 'd+' && parseInt(this.refillDueFG.controls["FromDueDateDays"].value) >=0) {
      if (fromDueDays <= 999) {
        this.refillDueFG.controls["FromDueDateDays"].patchValue(++fromDueDays);
      }
     }

     if (this.refillDueFG.controls["AllForFromDueDate"].value === 'd-' && parseInt(this.refillDueFG.controls["FromDueDateDays"].value) <0) {
      if (fromDueDays <= 999) {
        this.refillDueFG.controls["FromDueDateDays"].patchValue(++fromDueDays);
      }
     }
  }

  navDownForFromDays() {
    let fromDueDays = parseInt(this.refillDueFG.value.FromDueDateDays)
    if (this.refillDueFG.controls["AllForFromDueDate"].value === 'd+' && parseInt(this.refillDueFG.controls["FromDueDateDays"].value) >0) {
      if (fromDueDays > 0) {
        this.refillDueFG.controls["FromDueDateDays"].patchValue(--fromDueDays);
      }
     }

     if (this.refillDueFG.controls["AllForFromDueDate"].value === 'd-' && parseInt(this.refillDueFG.controls["FromDueDateDays"].value) <=0) {
      if (fromDueDays > 0) {
        this.refillDueFG.controls["FromDueDateDays"].patchValue(--fromDueDays);
      }
     }
  }

  navUpForToDueDays() {
    let toDueDays = parseInt(this.refillDueFG.value.ToDueDateDays);
    if (this.refillDueFG.controls["AllForToDueDate"].value === 'd+' &&  parseInt(this.refillDueFG.controls["ToDueDateDays"].value) >=0) {
      if (toDueDays <= 999) {
        this.refillDueFG.controls["ToDueDateDays"].patchValue(++toDueDays);
      }
    }

    if (this.refillDueFG.controls["AllForToDueDate"].value === 'd-' &&  parseInt(this.refillDueFG.controls["ToDueDateDays"].value) <0) {
      if (toDueDays <=999) {
        this.refillDueFG.controls["ToDueDateDays"].patchValue(++toDueDays);
      }
    }
  }

  navDownForToDueDays() {
    let toDueDays = parseInt(this.refillDueFG.value.ToDueDateDays);
    if (this.refillDueFG.controls["AllForToDueDate"].value === 'd+' &&  parseInt(this.refillDueFG.controls["ToDueDateDays"].value) >0) {
      if (toDueDays > 0) {
        this.refillDueFG.controls["ToDueDateDays"].patchValue(--toDueDays);
      }
     }
    if (this.refillDueFG.controls["AllForToDueDate"].value === 'd-' && parseInt(this.refillDueFG.controls["ToDueDateDays"].value) <=0) {
      if (toDueDays > 0) {
        this.refillDueFG.controls["ToDueDateDays"].patchValue(--toDueDays);
      }
    }
  }

  filedRxs(type: string) {
    this.fileorAll = type;
    this.refillDueFG.controls["FiledRxs"].patchValue(type);
  }

  createFG() {
    this.refillDueFG = this._fb.group(new RefillDue());
  }


  selectedPresInfo(prescInfo: any) {
      this.presInputValue = true;
    if (prescInfo) {
      this.refillDueFG.controls["PrescriberId"].patchValue(prescInfo.prescriberid);
      this._cdr.detectChanges();
      this.presInputValue = prescInfo.prescriberlastname +
      (prescInfo.prescriberfirstname ? "," + prescInfo.prescriberfirstname : "");
    } else {
      this.refillDueFG.controls["PrescriberId"].patchValue(null);
      this.presInputValue = "";
    }
  }

  triggerChange(val) {
    if (!val) {
      this.refillDueFG.patchValue({
        ShowIns: false,
        ShowProfit: false
      });
    }
  }

  openInsSelectPopUp() {
      this.optedInsList = Object.assign([], this.refillDueFG.value.SelectedInsList) || [];
      this.insucodeList = Object.assign([], this.refillDueFG.value.ExcludeInsuranceType) || [];
      this.openForInsCode = true;
}

  closeInsModalPopup(eve) {
    if (eve) {
      this.refillDueFG.patchValue({
        ExcludeInsuranceType: eve.inscodes && eve.inscodes.length ?
        eve.inscodes.map((x: any) => x.toUpperCase()) : "",
        SelectedInsList: eve.optedList && eve.optedList.length ? eve.optedList : []
      });
    }
    this.openForInsCode = false;
  }

  async setParamters() {
    this.formGroupInvalidDate = false;
    if (this.refillDueFG.valid) {
      await this._formUtils.blurAndDelay();

      if (this.refillDueFG.controls["AllForSinceDate"].value === "d+") {
        this.refillDueFG.controls["RefillFillDate"].patchValue(moment().add(this.refillDueFG.value.SinceDateDays, "days").format("MM/DD/YYYY"));
      }
      if (this.refillDueFG.controls['AllForSinceDate'].value === 'd-') {
        this.refillDueFG.controls["RefillFillDate"].patchValue(moment().subtract(this.refillDueFG.value.SinceDateDays, "days").format("MM/DD/YYYY"));
      }

      if (this.refillDueFG.controls['AllForFromDueDate'].value === 'd+') {
        this.refillDueFG.controls["refillsDueFromDate"].patchValue(moment().add(this.refillDueFG.value.FromDueDateDays, "days").format("MM/DD/YYYY"));
      }

      if (this.refillDueFG.controls['AllForFromDueDate'].value === 'd-') {
        this.refillDueFG.controls["refillsDueFromDate"].patchValue(moment().subtract(this.refillDueFG.value.FromDueDateDays, "days").format("MM/DD/YYYY"));
      }
      if (this.refillDueFG.controls['AllForToDueDate'].value === 'd+') {
        this.refillDueFG.controls["refillsDueToDate"].patchValue(moment().add(this.refillDueFG.value.ToDueDateDays, "days").format("MM/DD/YYYY"));
      }

      if (this.refillDueFG.controls['AllForToDueDate'].value === 'd-') {
        this.refillDueFG.controls["refillsDueToDate"].patchValue(moment().subtract(this.refillDueFG.value.ToDueDateDays, "days").format("MM/DD/YYYY"));
      }
      if (this.refillDueFG.controls["RefillFillDate"].valid) {
        if (!this.refillDueFG.controls["RefillFillDate"].value) {
          this.refillDueFG.patchValue({
            RefillFillDate: moment().subtract(6, "months").format("MM/DD/YYYY")
          });
        }
      } else {
        this._alertSer.error("Please enter valid Rx filled date");
        return;
      }
      const resp = this._formUtils.checkDateErrors2(this.refillDueFG, "refillsDueFromDate", "refillsDueToDate", 7);
      if (resp === "DATEISSUE") {
        this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
      } else if (resp === "INVALID") {
        this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
      } else if (resp === "VALID") {
        if (!this.refillDueFG.value["billSearch"] || this.refillDueFG.value["billSearch"].length === 0) {
            this.refillDueFG.controls["BillType"].patchValue("12");
        } else if (this.refillDueFG.value["billSearch"].length === 1) {
            this.refillDueFG.controls["BillType"].patchValue(this.refillDueFG.value["billSearch"][0] === 0 ? "12" :
              this.refillDueFG.value["billSearch"].toString());
        } else if (this.refillDueFG.value["billSearch"].length === 2) {
          this.refillDueFG.value["billSearch"].sort();
          this.refillDueFG.controls["BillType"].patchValue((this.refillDueFG.value["billSearch"].toString()).replace(",", ""));
        }
        this.refillDueFG.controls["BillStatusAllSelected"].patchValue((this.refillDueFG.value["billSearch"] ?
        (this.refillDueFG.value["billSearch"][0] === 0 ? true : false) : false));
          this.refillDueFG.value["billSearch"] = null;
        this.refillDueFG.controls["BillType"].patchValue(this.refillDueFG.value["IncludeUnbilledRxs"] ? "12" : "2")
        if (this.refillDueFG.value["IncludeFiledRx"] && this.refillDueFG.value["BillType"] !== "123") {
            const billtypev = this.refillDueFG.value["BillType"];
            this.refillDueFG.controls["BillType"].patchValue(billtypev ? billtypev + "3" : "3");
        }
        if (!this.refillDueFG.value["IncludeFiledRx"] && this.refillDueFG.value["ShowExpired"] &&
         this.refillDueFG.value["BillType"] !== "123") {
           const billtypev = this.refillDueFG.value["BillType"];
           this.refillDueFG.controls["BillType"].patchValue(billtypev ? billtypev + "3" : "3");
       }
        this.refillDueFG.value["PrescriberName"] = this.presInputValue;
        let drugType = [];
        if (this.refillDueFG.value["drugType"] && this.refillDueFG.value["drugType"].length > 0) {
          if (this.refillDueFG.value["drugType"].length === 5) {
            drugType = [1, 2, 3, 4, 5];
          } else {
            drugType = this.refillDueFG.value["drugType"];
          }
        } else {
          drugType = [1, 2, 3, 4, 5];
        }
        if (this.refillDueFG.value["ShowExpiredRxOnly"]) {
            this.refillDueFG.controls["ShowExpired"].patchValue(false);
            this.refillDueFG.controls["ShowExpiring"].patchValue(false);
        } else {
            this.refillDueFG.controls["ShowExpired"].patchValue(this.refillDueFG.value["ShowExpired"]);
            this.refillDueFG.controls["ShowExpiring"].patchValue(this.refillDueFG.value["ShowExpiring"]);
            // this.refillDueFG.controls["ExcludeExpiredByTimeLimit"].patchValue(
            //     (!(this.refillDueFG.controls["ShowExpired"].value &&
            //      this.refillDueFG.controls["ShowExpiredRxOnly"].value)) ? false : true);
        }
        // if (this.refillDueFG.controls["ExcludeExpiredByTimeLimit"].value) {
        //         this.refillDueFG.controls["ExcludeExpiredByTimeLimit"].patchValue(
        //             (!(this.refillDueFG.controls["ShowExpired"].value &&
        //              this.refillDueFG.controls["ShowExpiredRxOnly"].value)) ? true : false);
        // }
        if (this.refillDueFG.value["drugClass"] === "All") {
          this.refillDueFG.value["drugClass"] = 1;
        } else if (this.refillDueFG.value["drugClass"] === "Class 2") {
          this.refillDueFG.value["drugClass"] = 2;
        } else if (this.refillDueFG.value["drugClass"] === "Class 3") {
          this.refillDueFG.value["drugClass"] = 3;
        } else if (this.refillDueFG.value["drugClass"] === "Class 4") {
          this.refillDueFG.value["drugClass"] = 4;
        } else if (this.refillDueFG.value["drugClass"] === "Class 5") {
          this.refillDueFG.value["drugClass"] = 5;
        } else if (this.refillDueFG.value["drugClass"] === "Exclude Controls") {
          this.refillDueFG.value["drugClass"] = 7;
        } else if (this.refillDueFG.value["drugClass"] === "All Control Drugs(2-5)") {
          this.refillDueFG.value["drugClass"] = 6;
        } else if (this.refillDueFG.value["drugClass"] === "Class 1") {
          this.refillDueFG.value["drugClass"] = 8;
        }
        this.closePopupForRefillDue("saveParameters");
      }
    } else {
      this.formGroupInvalidDate = true;
      this._alertSer.error("Please enter required fields.")
    }
  }

  createZipFG() {
    this.ZipCodeFG = this._fb.group({
      State: [this.PharmStateId.Name],
      FilterBy: true,
      FilterSearch: []
    });
  }

  init(flex: any) {
    flex.columnHeaders.rows[0].wordWrap = true;
    flex.autoSizeRow(0, true);
    this.flexx = flex;
}

  filterGrid(val?: string) {
    const filter = val ? val.toLowerCase() : "";
    if (this.flexx) {
      this.flexx.collectionView.filter = (item: any) => {
        return filter.length === 0 || this.ZipCodeFG.value.FilterBy ? item["Zip Code"].toLowerCase().startsWith(filter) :
          item.City.toLowerCase().startsWith(filter);
      };
    }
  }
  openZipPOPup() {
    this.createZipFG();
    this._modalService.open(this.ZIPCODESPOPUP, { keyboard: false, windowClass: "medium-modal-50 medium-modal-50-margin", centered: true, backdrop: false });
    this.loadStateZipCodes();
  }

  async loadStateZipCodes(val?: { Id: any; Name: any; }) {
    this.ZipCodeFG.controls.FilterSearch.patchValue("");
    const resp = await this._reportsServ.getZipcodes(val ? val.Id : this.PharmStateId.Id).toPromise();
    if (resp) {
      this.StatesList = resp.State;
      this.ZipcodesList = resp.ZipMaster;
      this.StName = val ? val.Name : this.PharmStateId.Name;
      this.generateWJ(resp.ZipMaster ? resp.ZipMaster : []);
    }
  }

  selectedZips() {
    if (this.selectedRecords && this.selectedRecords.length) {
      let zips = this.refillDueFG.value.zipCodes ? this.refillDueFG.value.zipCodes : "";
      this.selectedRecords.forEach((List, i) => {
        zips += zips === "" ? List["Zip Code"] : "," + List["Zip Code"];
      });
      zips = zips.split(",").filter((elem, index, self) => {
        return index === self.indexOf(elem);
      }).join();
      this.refillDueFG.patchValue({
        zipCodes: zips
      });
    }
    this.closePopUp();
  }

  clearFilter(e: { preventDefault: () => void; }) {
    this.ZipCodeFG.controls.FilterSearch.patchValue("");
    this.filterGrid("");
    e.preventDefault();
    if (document.getElementById("filterSearch")) {
      document.getElementById("filterSearch").focus();
    }
  }

  clearSelection() {
    if (this.selectedRecords.length) {
      this.selectedRecords = [];
      this.ZipCodeFG.controls.FilterSearch.patchValue("");
      this.filterGrid("");
      this.generateWJ(this.ZipcodesList ? this.ZipcodesList : []);
    }
  }
  generateWJ(ZipcodesList: any[]) {
    this.ZipCodesWJ = new CollectionView(
      ZipcodesList.map((List: { ZipCode: any; CityName: any; }, i: any) => {
        const j = {};
        j["RowId"] = i;
        j["Zip Code"] = List.ZipCode;
        j["State"] = this.StName;
        j["City"] = List.CityName;
        if (this.selectedRecords.length) {
          if (this.selectedRecords.some(r => r["Zip Code"] === j["Zip Code"])) {
            j["IsSelected"] = true;
          }
        }
        return j;
      }
      )
    );
  }
  changeSelcted(value: { [x: string]: boolean; }, event: { target: { checked: any; }; }) {
    if (event && event.target.checked) {
      value["IsSelected"] = true;
      this.selectedRecords.push(value);
    } else {
      const index = this.selectedRecords && this.selectedRecords.length && this.selectedRecords.
        findIndex(v => v["RowId"] === value["RowId"]);
      this.selectedRecords.splice(index, 1);
      value["IsSelected"] = false;
    }
  }

  closePopUp() {
    this.selectedRecords = [];
    this.StatesList = null;
    this.ZipcodesList = null;
    this.StName = null;
    this.loadStateZipCodes();
  }

  getRxStatus(selectedArray?) {
    this.refillDueFG.controls["billSearch"].patchValue(
      this._commonUtils.selectedRxStatus(this.refillDueFG.controls["billSearch"].value));
  }

  closePopupForRefillDue(type?: string) {
    if (this.openPopup) {
      this.openPopup.close();
      this.openPopup = null;
    }
    if (type === "saveParameters") {
      if (this.editMode) {
        const dataToPost = {
          Id : this.editMode ? this.editTaskData.Id : 0,
          taskId: this.editTaskData?.TaskId,
          taskName: this.editTaskData?.TaskName,
          taskDescription : this.editTaskData?.TaskDescription,
          IsEnabled : this.editTaskData?.IsEnabled,
          startTime : this.editTaskData?.StartTime,
          HangFireJobId : this.editMode ? this.editTaskData?.HangFireJobId : "",
          cronExpression: this.editTaskData?.CronExpression,
          ParametersString : JSON.stringify(this.refillDueFG.value),
          LastExecutedDtTm: this.editMode ? this.editTaskData?.LastExecutedDtTm : null,
          EmailAddress: null,
          IsFileGenerated: null,
          RecurIn : this.editTaskData?.RecurIn,
          isEmailEnabled: false,
          emailAddress: null,
          TimeZone: null,
          IsActive: true,
          IsDeleted: false,
          IsDefault: this.editMode ? this.editTaskData?.IsDefault : false,
          Action: this.editMode ? "u": "a",
          CreatedDtTm: this.editMode ? this.editTaskData?.CreatedDtTm :null,
          ModifiedDtTm: this.editMode ? this.editTaskData?.ModifiedDtTm: null,
          taskTypeId : this.editTaskData?.TaskTypeId
        }
        this._reportsServ.addTaskToScheduler(dataToPost).pipe(takeUntil(this.unsubscribe$)) .subscribe(resp => {
          this.IspopUpclosed.emit(this.refillDueFG.value);
        });
      } else {
        this.IspopUpclosed.emit(this.refillDueFG.value);
      }
    } else {
      this.IspopUpclosed.emit(null);
    }
  }

  changeConsumptionThresholdSelection() {
    if (this.refillDueFG.controls["ConsumptionThreshold"].value) {
        this.refillDueFG.controls["ConsumptionThreshold"].patchValue(false);
    }
    document.getElementById("consumptionThreshold24")["disabled"] = this.refillDueFG.controls["PickupDate"].value ? true : false;
 }

 changePickupDateSelection() {
    if (this.refillDueFG.controls["PickupDate"].value) {
        this.refillDueFG.controls["PickupDate"].patchValue(false);
    }
    document.getElementById("PickupDate8")["disabled"] = this.refillDueFG.controls["ConsumptionThreshold"].value ? true : false;
 }
 patchUserInitials(event) {
  this.refillDueFG.controls["PharmacistUserName"].patchValue(event?.value ? event.value.UserId : null);
 }

}
