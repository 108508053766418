import { Component, Input, OnInit, Output, ViewChild,EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services';
import { CollectionView } from  '@grapecity/wijmo';

@Component({
  selector: 'app-ins-codes',
  templateUrl: './ins-codes.component.html',
})
export class InsCodesComponent implements OnInit {
  insuWJ: CollectionView;
  prevSearchValue: any;
  optedInsList: any[];
  activeHeadersIns: string[];
  insuranceData: any;
  modalRef: any;

  @ViewChild("insuPopUpToLink" ,{static: true})
  insuPopUpToLink: any;
  insuCodes: any[];

  @Input()
  set OptedInsList(data: any) {
    this.optedInsList = data;
  }
 @Input()
 set OptedInsCodeList(data: any) {
    this.insuCodes = data;
  }
  @Input() frmScrn: any;
  @Output()
  emitInscodes = new EventEmitter<any>();

  constructor(private _commonServ: CommonService, private _modalService: NgbModal) { }

  ngOnInit() {
    this.prevSearchValue = null;
    this.insuranceData = this._commonServ.insuranceData$["source"]["value"];
    this.insuranceSort();
    this.generateInsuWJ(this.insuranceData);
    this.modalRef = this._modalService.open(this.insuPopUpToLink, { centered: true, size: !this.frmScrn ? "lg" : "", windowClass: this.frmScrn ? "medium-modal-50 medium-modal-50-margin" : '', backdrop: false, keyboard: false });
  }

  insuranceSort() {
    if (this.insuranceData && this.insuranceData.length) {
        this.insuranceData = this.insuranceData.sort((a: any , b: any) => {
            if (a["InsuranceName"] && b["InsuranceName"] ) {
                const insNameA = a["InsuranceName"].trim();
                const insNameB = b["InsuranceName"].trim();
                if (insNameA < insNameB) {
                    return -1;
                } else if (insNameA > insNameB) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        });
    }
  }

  generateInsuWJ(data) {
    this.insuWJ = new CollectionView(
      data.map((ins, i) => {
        const j = {};
        j["Ins.Code"] = ins["PlanCode"];
        j["Ins.Name"] = ins["InsuranceName"];
        j["Bin#"] = j["BIN"] = ins.BinNum;
        j["Processor Ctrl#"] = ins.ProcessorNum;
        j["Pharmacy Id"] = ins.PharmacyNum;
        j["Address"] = ins.Address;
        j["Phone No"] = j["Phone#"] = ins.PhoneNumber;
        j["RowId"] = ins["PlanCode"] + ins["Id"];
        j["Id"] = ins["Id"];
        if (this.optedInsList && this.optedInsList.length) {
          j["IsSelected"] = this.optedInsList.indexOf(j["Id"]) > -1 ? true : false;
        } else {
            j["IsSelected"] = false;
        }
        return j;
      }));
    this.activeHeadersIns = [
      "Ins.Code",
      "Ins.Name",
      "BIN",
      "Processor Ctrl#",
      "Pharmacy Id",
      "Address",
      "Phone#"
    ];
  }


  fiterInsu(value) {
    if (value === "") {
      value = null;
    }
    if (value !== this.prevSearchValue) {
      if (value && value.trim() !== "") {
        this.prevSearchValue = value;
        const insuranceList = Object.assign([], this.insuranceData);
        const filteredData = insuranceList.filter(v => {
          let valExists = false;
          ["PlanCode", "InsuranceName", "BinNum"].forEach(function (key) {
            const val = v[key] ? v[key] : "";
            valExists =
              valExists ||
              val
                .toString()
                .toLowerCase()
                .startsWith(value.toLocaleLowerCase());
          });
          return valExists;
        });
        this.generateInsuWJ(filteredData);
      } else {
        this.prevSearchValue = null;
        this.generateInsuWJ(this.insuranceData);
      }
    }
  }

  selectCode(item, event) {
    if ((!item["IsSelected"] || (event && event.target.checked))) {
      item["IsSelected"] = !item["IsSelected"];
      this.optedInsList = this.optedInsList || [];
      this.insuCodes = this.insuCodes || [];
      this.optedInsList.push(item["Id"]);
      this.insuCodes.push(item["Ins.Code"]);
    } else {
      item["IsSelected"] = !item["IsSelected"];
      if (this.optedInsList && this.optedInsList.length) {
        const index = this.optedInsList.findIndex(val => val === item["Id"]);
        if (index > -1) {
          this.optedInsList.splice(index, 1);
          if (this.insuCodes && this.insuCodes.length) {
            this.insuCodes.splice(index, 1);
          }
        }
      }
    }
  }

  addInsCodes(type?: string) {
    // this.refillDueFG.patchValue({
    //   ExcludeInsuranceType: this.insuCodes && this.insuCodes.length ?
    //     this.insuCodes.toString().toUpperCase().split(",").join(", ") : "",
    //   SelectedInsList: this.optedInsList && this.optedInsList.length ? this.optedInsList : []
    // });
    if (type === "addcodes") {
      this.emitInscodes.emit({inscodes: this.insuCodes , optedList: this.optedInsList});
    } else {
      this.emitInscodes.emit(null);
    }
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }
  }

}
