import { CommonService } from "./../../../services/common.service";

import {
    Component,
    OnInit,
    ViewChild,
    Output,
    EventEmitter,
    HostListener,
    Input,
    ChangeDetectorRef
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    RxService,
    AlertService,
    PatientService,
    ErxService,
    EditDrugService
} from "../../../services";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import {
    DiagCode,
    ProcModifier,
    RxExtra,
    PrescInfo,
    CouponInfo,
    RxModel,
    RxAuditLog,
    ErxVersions,
    ErxDetailsData,
    SystemData,
    AccCategoryE,
    eRxE
} from "../../../models";

import { forkJoin, Subject } from "rxjs";
import { InsuranceStore, RxStore } from "src/app/store";
import { CommonUtil, NewAuditUtils, NRxUtils, PatchRXFGUtil, RxUtils } from "src/app/utils";
import * as moment from "moment";
import { ErxComponent } from "../../erx";
import { DateCheckUtil } from "src/app/utils/date-checks.util";
import { takeUntil } from "rxjs/operators";
import { PrivMaskGuard } from "src/app/guards";
import { MsgConfig } from "src/app/app.messages";

@Component({
    selector: "app-other-values",
    templateUrl: "./other-values.component.html",
    styles: []
})
export class OtherValuesComponent implements OnInit {
    modelRef: any;
    otherValFG: FormGroup;
    extraValueInfo: any;
    minDate: any;
    rxType: any;
    patientInfo: any;
    patDiagnis: any;
    diagCodepopindex: any;
    isDisabled: boolean = false;
    isTransferIn: boolean = false;
    rxExpiredDateWarMessage = "";
    isElectornic: any;
    @Input() rxFG: FormGroup;
    @Input() miscInfo: any;
    @Input() IsFromNewRx: boolean;
    @Input() FocusFieldId: any;
    isErxMessageWarning: any = {};
    eRxVersion: any;
    erxDetailsInRx: any;
    isErxResultShow: boolean = false;
    @ViewChild("OTHERVALUES")
    OTHERVALUES: any;

    @ViewChild("diagList", { static: true })
    diagList: any;

    @ViewChild("ErxwarningMessage", { static: true })
    ErxwarningMessage: any;

    @Output()
    IsPopUpClosed = new EventEmitter<any>();

    @Output()
    IsMiscInfoClosedFromNewRx = new EventEmitter<any>();

    @Output()
    SaveOtherValueInfo = new EventEmitter<any>();

    @Output()
    CanclOthrValueInfo = new EventEmitter<any>();
    drugInfoByNDC: any;
    fgInvaild: boolean;
    originalData: any;
    compDesOnSave: boolean;
    erxDetails: any;
    dataFromBackend: any;
    systemData: SystemData;
    cntrlSettings: any;
    isReqState: boolean;
    unsubscribe$: Subject<void> = new Subject();
    rxOriginList: any;
    oldRxExtra: any;

    constructor(
        private _modalService: NgbModal,
        private _rxService: RxService,
        private _fb: FormBuilder,
        private _alertServ: AlertService,
        private _commonSvc: CommonService,
        private _rxStore: RxStore,
        private _patientSVC: PatientService,
        private _ptchRxFgUtl: PatchRXFGUtil,
        private _nwAudtUtl: NewAuditUtils,
        private _erxService: ErxService,
        private _cdr: ChangeDetectorRef,
        private _nrxUtls: NRxUtils,
        private _commonUtil: CommonUtil,
        private _dateChecks: DateCheckUtil,
        private _prvMskGrd: PrivMaskGuard,
        private _insuStore: InsuranceStore,
        private _rxUtils: RxUtils,
        private edtDrgServ: EditDrugService
    ) {
        this.createFileFG();
    }

    ngOnInit() {
        this.rxType = this.rxFG.get("rxType").value;
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate() + 1
        };
        this._commonSvc.systemData$.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            this.systemData = resp;
            this.rxOriginList = this.systemData.RxOrigin.map(item => 
                item.Name === "Electronic" ? { ...item, disabled: true } : item
              );
        });
        if ((!this.miscInfo || this.rxType === "er")) {
            this.getOtherValCmnData(this.rxFG.getRawValue());
        } else {
            this.getExtraValues();
            if (this.rxType == 'rf') {
                this.defaultPatchinggFromRefill();
                this.setProcModifierAndDiagnosisCode(this.rxFG.value["rxExtraInfo"]);
                this.dataFromBackend = this.otherValFG.value["rxExtra"]["RxExpDt"];
                this.otherValFG.controls["rxExtra"].patchValue({
                    RxExpDt: this.otherValFG.value["rxExtra"]["RxExpDt"] ?
                        moment.utc(this.otherValFG.value["rxExtra"]["RxExpDt"]).local().format("MM/DD/YYYY") : null
                });
                if (this.rxFG.value.isPatIndicator !== null) {
                    this.RXEXTRA.controls["IsPatAssignInd"].setValue(this.rxFG.value.isPatIndicator);
                }
            }
            this.otherValFG = this._ptchRxFgUtl.createAndPatchFGInfo(this.miscInfo);
            if (this.rxFG.value.isPatIndicator !== null) {
                this.RXEXTRA.controls["IsPatAssignInd"].setValue(this.rxFG.value.isPatIndicator);
            }
            const rxRefillRemainder = this._commonSvc.getSetttingValue("RxSettings", "DEF_RX_REF_REM");
            if (rxRefillRemainder === "1") {
                this.RXEXTRA.controls["IdRefillReminded"].patchValue(true);
            } else if (rxRefillRemainder === "0") {
                this.RXEXTRA.controls["IdRefillReminded"].patchValue(this.rxFG.controls["Patient"].value["isRefillReminderSet"]);
            }
            this.RXEXTRA.controls["MaxDailyDose"].patchValue(this.rxFG.value["MaxDailyDose"] ? this.rxFG.value["MaxDailyDose"] : 0);
            this.RXEXTRA.controls["BillDaysSup"].patchValue(this.RXEXTRA.value["BillDaysSup"] ? this.RXEXTRA.value["BillDaysSup"] : 0);
            this.otherValFG.markAsPristine();
        }
        this.isDisabled = (this.rxType === "rf") ? true : false;
        this.eRxVersion = this._commonUtil.getErxVersion();
        if (this.rxFG && this.rxFG.value["erxDetailsInRx"]) {
            this.erxDetailsInRx = this.rxFG.value["erxDetailsInRx"];
            this.erxDetails = this.rxFG.value["eRxDetailsViewModel"];
            console.log(this.erxDetails, "erxDetails");
        }
        const stateVal = this._commonSvc.getSetttingValue("TenantSettings", "TNT_STATE");
        const resp = this._commonSvc.controlFileSettings$["source"]["value"];
        if ((this.rxFG.value["Drug"]["drugclass"] >= 2 && this.rxFG.value["Drug"]["drugclass"] <= 5) &&
            resp && resp["ControlSetting"] && resp["ControlSetting"].length > 0) {
            this.cntrlSettings = resp["ControlSetting"];
            this.cntrlSettings.filter((item: any) => {
                if (item.IsTreatmentTypeRequired && ((item.State === this.rxFG.controls["Patient"].value["state"])
                    || (item.State === stateVal))) {
                    this.isReqState = true;
                }
            });
        }
        if (this.isReqState && !this.RXEXTRA.controls["TreatmentTypeId"].value) {
            this.RXEXTRA.get("TreatmentTypeId").setErrors({ required: true });
            this.RXEXTRA.get("TreatmentTypeId").markAsTouched();
            setTimeout(() => {
                this.RXEXTRA.get("TreatmentTypeId").markAsUntouched();
            }, 0);
        }
        this.patchSubmClarf();
    }
    async patchSubmClarf() {
        if (this.IsFromNewRx) {
            if (this.rxFG.value.rxType === "rf") {
                const drugInsPref = await this.edtDrgServ.getInsuranceRestrictionWithoutBS(this.rxFG.value["Drug"]["id"], 1, 30).toPromise();
                this._rxStore.storeDrugInsuRstrctn(drugInsPref);
                const INS = await this._rxUtils.getfindAndInsuDet(this.rxFG.value["Patient"], null,
                    this.rxFG.value.RefillInsu.InsuId);
                this._insuStore.storeInsuData(INS);
            }
            const drugInsRestr = this._rxStore.drugInsRestr$["source"]["value"];
            const refInsuData =this.rxFG.value["Patient"]["insurance"] && this.rxFG.value["Patient"]["insurance"]?.length ? this.rxFG.value["Patient"]["insurance"].find((item: any) =>
            item.insuranceid === this.rxFG.value["RefillInsu"]["InsuId"]) : null;
            let insPrefData = drugInsRestr?.length ? drugInsRestr.find((item: any) => item.InsurId === refInsuData?.Id) : null;
            if (!insPrefData) {
                insPrefData = drugInsRestr?.length ? drugInsRestr.find((item: any) => item.IsAllInsu) : null;
            }
            const INS = this._insuStore.insuInfo$["source"]["value"];
            this.RXEXTRA.controls["SubClarifId1"].patchValue(insPrefData ? insPrefData.SubClarifId : ((INS &&  INS?.InsuTransmission?.SubClarifId) ? INS.InsuTransmission.SubClarifId :
                null));
        }
    }

    undokeytab(event: any, fg?: any, fc?: string) {
        this._nrxUtls.rxBilngUndokeytab(event, fg, fc);
    }


    validateOrderRxDate() {
        if (this.RXEXTRA.value["RxExpDt"]) {
            const rxExpDt = moment().isAfter(this.RXEXTRA.value["RxExpDt"]);
            this.rxExpiredDateWarMessage = "";
            if (rxExpDt) {
                this.rxExpiredDateWarMessage = "Expiry date cannot be current date or previous date.";
            }
        }
    }
    ngOnDestroy() {
        if (!this.compDesOnSave) {
            if (this.rxType !== "er" || !this.otherValFG.dirty) {
                this.saveOtherValueInfo("destroy");
            }
        }
        this.otherValFG.reset();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async getExtraValues() {
        if (this.IsFromNewRx) {
            const resp = await this._patientSVC.getPatientDiagnosis(this.rxFG.value["Patient"]["patientid"], true).toPromise();
            this.patDiagnis = resp;
        } else {
            const resp = await forkJoin([
                this._rxService.getRxExtraDefaultValues(this.rxFG.value["PrescReFill"]["Id"] ? this.rxFG.value["PrescReFill"]["Id"] : 0, true),
                this._patientSVC.getPatientDiagnosis(this.rxFG.value["Patient"]["patientid"], true)
            ]).toPromise();

            this.extraValueInfo = resp[0];
            this.isElectornic = this.systemData.rxOriginsData.find(val => val["Id"] === this.RXEXTRA.value["rxOriginId"] &&
                val["Name"] === "Electronic");

            this.patDiagnis = resp[1];
        }
    }

    createFileFG() {
        this.otherValFG = this._fb.group({
            rxExtra: this._fb.group(new RxExtra()),
            prescInfo: this._fb.group(new PrescInfo()),
            couponInfo: this._fb.group(new CouponInfo()),
            diagCode: [],
            procModifier: [],
            PrescriptionId: null,
            oldRxExtra: null
        });
        this.createDiagCodeFA();
        this.createProcModfFA();
    }
    openErxActionList() {
        if (!this._prvMskGrd.canActivate(AccCategoryE.eRx, eRxE.eRxActionList)) {
            this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS);
        } else {
            if (this.rxFG && this.erxDetailsInRx && this.erxDetailsInRx["erxId"]) {
                this.isErxMessageWarning = {
                    header: "Already Linked!",
                    body: " This Rx Already Linked with an eRx Prescription.", body1: " Are you sure you want to change it?"
                };
                this.openErxwarningMessage();
            } else {
                this.openERXProcess();
            }
        }
    }
    openERXProcess() {
        const rxInfo = this.rxFG.getRawValue();
        const rxExtra = this.otherValFG.controls["rxExtra"] as FormGroup;
        const prescInfo = this.otherValFG.controls["prescInfo"] as FormGroup;
        const couponInfo = this.otherValFG.controls["couponInfo"] as FormGroup;
        const diagCode = this.otherValFG.controls["diagCode"] as any;
        const procModifier = this.otherValFG.controls["procModifier"] as any;
        if (rxInfo && rxInfo["PrescReFill"] && rxInfo["PrescReFill"]["Id"]) {
            rxExtra.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
            prescInfo.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
            couponInfo.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);

            diagCode.controls.map((dcode: any) => {
                dcode.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
            });

            procModifier.controls.map((dcode: any) => {
                dcode.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
            });

            this.otherValFG.controls["PrescriptionId"].patchValue(rxInfo["Prescription"]["Id"]);

        }
        const modelRef = this._modalService.open(ErxComponent,
            { size: "lg", keyboard: false, backdrop: "static", windowClass: "md-x1-lg" });
        modelRef.componentInstance.IsPopErxModel = true;
        modelRef.componentInstance.rxFG = this.rxFG;
        modelRef.componentInstance.RxType = this.rxType;
        modelRef.componentInstance.RxExtraInfoObj = { prescReFill: this.rxFG.value["PrescReFill"]["Id"], otherValFG: this.otherValFG.getRawValue() };
        modelRef.componentInstance.CloseErxMessageLinkModal.pipe(takeUntil(this.unsubscribe$)).subscribe(async resp => {
            if (resp) {
                this.erxDetailsInRx = this.rxFG.value["erxDetailsInRx"];
                modelRef.close();
            }
        });
    }
    goToUnlinkErxMessageLink() {
        this.erxDetailsInRx = this.rxFG.value["erxDetailsInRx"];
        if (this.erxDetailsInRx && this.erxDetailsInRx["erxId"]) {
            const erxObject = {
                "ErxId": this.erxDetailsInRx["erxId"],
                "ErxOpTransactionId": this.erxDetailsInRx["erxOpTransactionId"],
                "RxNum": this.rxFG.value["Prescription"]["PrescNum"],
                "ErxOperationId": this.erxDetailsInRx["erxOperationId"]
            };
            this._erxService.getProcessERxMessageUnLink(erxObject, this.eRxVersion).pipe(takeUntil(this.unsubscribe$)).subscribe(async (res: any) => {
                if (res) {
                    if (this.isErxMessageWarning && this.isErxMessageWarning?.header && this.isErxMessageWarning.header == "eRx Association Warning") {
                        if (this.rxType === "er") {
                            let rxInfo: any = await this._nrxUtls.getandStoreRxInfo(+this.rxFG.value["Prescription"]["PrescNum"],
                                +this.rxFG.value["PrescReFill"]["Id"], false, null, null, null, true);
                            if (rxInfo) {
                                rxInfo = this._nrxUtls.editRxPatchValues(rxInfo);
                                this._nrxUtls.createPatchStoreRxFG(rxInfo);
                            }
                        }
                        this.rxFG.controls["erxDetailsInRx"].reset();
                        this.erxDetailsInRx = this.rxFG.value["erxDetailsInRx"];
                        this.patchRxOrigin(this.RXEXTRA.value["rxOriginId"]);
                    }
                    if ((this.isErxMessageWarning) && (this.isErxMessageWarning.body1)) {
                        this.openERXProcess();
                    }
                    if (this.modelRef) {
                        this.modelRef.close();
                    }
                }
            });
        }
    }
    saveOriginInRxFG() {
        if (this.rxFG && this.erxDetailsInRx && this.erxDetailsInRx["erxId"]) {
            this.isErxMessageWarning = {
                header: "eRx Association Warning",
                body: "Are you sure want to remove eRx association and make eRx as ready to process?"
            };
            this.openErxwarningMessage();
        } else {
            this.patchRxOrigin(this.RXEXTRA.value["rxOriginId"]);
        }
    }
    saveOriginInMiscFG() {
        this.RXEXTRA.controls["rxOriginId"].patchValue(this.rxFG.value["PrescReFill"]["RxOriginId"]);
        this.isElectornic = this.systemData.rxOriginsData.find(val => val["Id"] === this.RXEXTRA.value["rxOriginId"] &&
            val["Name"] === "Electronic");
    }
    patchRxOrigin(rxOriginId) {
        this.isElectornic = this.systemData.rxOriginsData.find(val => val["Id"] === this.RXEXTRA.value["rxOriginId"] &&
            val["Name"] === "Electronic");
        this.rxFG.controls["PrescReFill"].patchValue({ RxOriginId: rxOriginId });
    }
    openErxwarningMessage() {
        this.modelRef = this._modalService.open(this.ErxwarningMessage, {
            centered: true,
            backdrop: false,
            windowClass: "medium-sm-modal-30 large--content",
            keyboard: false
        });
    }
    closeErxModal() {
        if (this.isElectornic && this.isElectornic.Id) {
            this.RXEXTRA.controls["rxOriginId"].patchValue(this.isElectornic["Id"]);
            this.patchRxOrigin(this.isElectornic["Id"]);
        }
        if (this.modelRef) {
            this.modelRef.close();
        }
    }
    openErxDetails() {
        if (!this._prvMskGrd.canActivate(AccCategoryE.eRx, eRxE.eRxActionList)) {
            this._alertServ.error(MsgConfig.NO_PRIVS_ACCESS);
        } else {
            this.isErxResultShow = true;
        }
    }

    saveMaxDoseVal() {
        this.rxFG.controls["MaxDailyDose"].patchValue(this.RXEXTRA.value["MaxDailyDose"] ? this.RXEXTRA.value["MaxDailyDose"] : 0);
    }

    saveBillDaysSupplyVal() {
        this.RXEXTRA.controls["BillDaysSup"].patchValue(this.RXEXTRA.value["BillDaysSup"] ? this.RXEXTRA.value["BillDaysSup"] : 0);
    }
    createDiagCodeFA() {
        const diagCodeData = [
            new DiagCode(),
            new DiagCode(),
            new DiagCode(),
            new DiagCode(),
            new DiagCode()
        ];
        this.generateFA("diagCode", diagCodeData);
    }

    createProcModfFA() {
        const procModifierData = [
            new ProcModifier(),
            new ProcModifier(),
            new ProcModifier(),
            new ProcModifier()
        ];

        this.generateFA("procModifier", procModifierData);
    }

    get RXEXTRA(): FormGroup {
        return this.otherValFG.get("rxExtra") as FormGroup;
    }

    async getDropDownValues(rxInfo) {
        const resp = await forkJoin([
            this._commonSvc.getEditPatientInformation(rxInfo.Patient.patientid, true),
            this._rxService.getRxExtraDefaultValues(rxInfo.PrescReFill.Id ? rxInfo.PrescReFill.Id : 0, true),
            this._patientSVC.getPatientDiagnosis(rxInfo.Patient.patientid, true)
        ]).toPromise();
        return resp;
    }

    async getOtherValCmnData(rxInfo: RxModel) {
        const resp = await this.getDropDownValues(rxInfo);
        if (resp) {
            this.oldRxExtra = resp[1].rxExtra;
            await this.patchPatientInfo(resp[0]);
            await this.patchDefaultMiscValue(resp[1]);
            this.extraValueInfo = resp[1];
            this.isElectornic = this.systemData.rxOriginsData.find(val => val["Id"] === this.RXEXTRA.value["rxOriginId"] &&
                val["Name"] === "Electronic");
            this.patDiagnis = resp[2];
            this.originalData = Object.assign({}, this.otherValFG.getRawValue());
            this.otherValFG.markAsPristine();
        }
    }

    patchPatientInfo(resp) {
        // this.RXEXTRA.controls["IsPatAssignInd"].setValue(
        //     resp.Insurances.IsPatAssigned);
        this.RXEXTRA.controls["IsIncludedInMar"].setValue(false);
        this.RXEXTRA.controls["IsPAUsedForAllRefills"].setValue(false);
    }

    async patchDefaultMiscValue(resp) {
        if (this.rxType === "er") {
            if (resp) {
                this.extraValueInfo = resp;
                ["rxExtra", "prescInfo", "couponInfo"].map(
                    key => {
                        if (resp[key]) {
                            this.otherValFG.controls[
                                key
                            ].patchValue(resp[key]);
                        }
                    }
                );
                this.setProcModifierAndDiagnosisCode(resp);
                if (
                    document.getElementById("miscServLevel")
                    && !this.FocusFieldId) {
                    const element: any = document
                        .getElementById("miscServLevel")
                        .getElementsByTagName("input")[0];
                    element.focus();
                } else {
                    if (this.FocusFieldId === "treatmenttype") {
                        const element: any = document
                            .getElementById(this.FocusFieldId)
                            .getElementsByTagName("input")[0];
                        if (element)
                            element.focus();
                    }
                }
                this.dataFromBackend = this.otherValFG.value["rxExtra"]["RxExpDt"];
                this.otherValFG.controls["rxExtra"].patchValue({
                    RxExpDt: this.otherValFG.value["rxExtra"]["RxExpDt"] ?
                        moment.utc(this.otherValFG.value["rxExtra"]["RxExpDt"]).local().format("MM/DD/YYYY") : null
                });
            }
            if (this.rxFG.value.isPatIndicator !== null) {
                this.RXEXTRA.controls["IsPatAssignInd"].setValue(this.rxFG.value.isPatIndicator);
            }
        } else {
            this.RXEXTRA.controls["IsPatAssignInd"].setValue(this.rxFG.value.isPatIndicator);
            this.RXEXTRA.controls["rxOriginId"].setValue(this.rxFG.value.PrescReFill.RxOriginId);
            ["rxExtra", "prescInfo", "couponInfo"].map(key => {
                if (resp[key]) {
                    this.otherValFG.controls[key].patchValue(resp[key]);
                }
            }
            );
            this.foucsOnmiscServLevel();
            this.setProcModifierAndDiagnosisCode(resp);
            // const excludeVals = ["OtherCoverageName", "OtherCovrgId", "SubClarifId1", "SubClarification1Name"];
           if(!this.IsFromNewRx) {
            this.RXEXTRA.controls["OtherCoverageName"].reset(); // patchValue("");
            this.RXEXTRA.controls["SubClarification1Name"].reset(); // patchValue("");
            this.RXEXTRA.controls["SubClarifId1"].reset();// patchValue(0);
            this.RXEXTRA.controls["OtherCovrgId"].reset();// patchValue(0);
           }
           else if(this.rxType === 'rf') {
               this.RXEXTRA.controls["OtherCovrgId"].reset();// patchValue(0);
            }
        }

        if (this.rxType === "er" || (this.IsFromNewRx && this.rxType === 'rf')) {
            this.RXEXTRA.controls["IdRefillReminded"].patchValue(this.RXEXTRA.value["IdRefillReminded"]);
        } else {
            const rxRefillRemainder = this._commonSvc.getSetttingValue("RxSettings", "DEF_RX_REF_REM");
            if (rxRefillRemainder === "1") {
                this.RXEXTRA.controls["IdRefillReminded"].patchValue(true);
            } else if (rxRefillRemainder === "0") {
                if ((this.rxFG && this.rxFG.controls["Patient"].value["isRefillReminderSet"] !== null)) {
                    this.RXEXTRA.controls["IdRefillReminded"].patchValue(
                        this.rxFG.controls["Patient"].value["isRefillReminderSet"]);
                } else {
                    this.RXEXTRA.controls["IdRefillReminded"].patchValue(false);
                }
            }
        }

        // this.RXEXTRA.controls["MaxDailyDose"].patchValue(this.rxFG.value["MaxDailyDose"] ? this.rxFG.value["MaxDailyDose"] : 0);
        this.RXEXTRA.controls["MaxDailyDose"].patchValue((resp.rxExtra && resp.rxExtra.MaxDailyDose) ? resp.rxExtra.MaxDailyDose : 0);
        this.RXEXTRA.controls["BillDaysSup"].patchValue(this.RXEXTRA.value["BillDaysSup"] ? this.RXEXTRA.value["BillDaysSup"] : 0);
        // this.RXEXTRA.controls["BillDaysSup"].patchValue(0);
    }

    setProcModifierAndDiagnosisCode(resp) {
        if (resp["diagCode"] && resp["diagCode"].length === 5) {
            this.generateFA("diagCode", resp["diagCode"]);
        } else if (resp["diagCode"] && resp["diagCode"].length < 5) {
            const diagCodeData: any[] = Object.assign([], resp["diagCode"]);
            for (let i = 5; i > resp["diagCode"].length; i--) {
                diagCodeData.push(new DiagCode());
            }
            this.generateFA("diagCode", diagCodeData);
        } else {
            const diagCodeData = [new DiagCode(), new DiagCode(), new DiagCode(), new DiagCode(), new DiagCode()];
            this.generateFA("diagCode", diagCodeData);
        }
        if (resp["procModifier"] && resp["procModifier"].length === 5) {
            this.generateFA("procModifier", resp["procModifier"]);
        } else if (resp["procModifier"] && resp["procModifier"].length < 5) {
            const procModifierData: any[] = Object.assign([], resp["procModifier"]);
            const len = (this.rxType === "er") ? 5 : 4;
            for (let i = len; i > resp["procModifier"].length; i--) {
                procModifierData.push(new ProcModifier());
            }
            this.generateFA("procModifier", procModifierData);
        } else {
            const procModifierData = [new ProcModifier(), new ProcModifier(), new ProcModifier(), new ProcModifier()];
            this.generateFA("procModifier", procModifierData);
        }
    }

    updateRxPatAssignVal() {
        this.rxFG.controls["isPatIndicator"].patchValue(this.RXEXTRA.value["IsPatAssignInd"]);
    }

    updateRefillRemin() {
        this.rxFG.controls["Patient"].patchValue(
            {
                isRefillReminderSet: this.RXEXTRA.value["IdRefillReminded"]
            });
    }

    foucsOnmiscServLevel(e?: any) {
        if (e) {
            e.preventDefault();
        }
        const element: any = document
            .getElementById("miscServLevel")
            .getElementsByTagName("input")[0];
        element.focus();
    }

    generateFA(key: string, values: any[]) {
        const addressFGs = values.map(val => this._fb.group(val));
        const addressFormArray = this._fb.array(addressFGs);
        this.otherValFG.setControl(key, addressFormArray);
    }

    get PRESCINFO(): FormGroup {
        return this.otherValFG.get("prescInfo") as FormGroup;
    }

    get COUPONINFO(): FormGroup {
        return this.otherValFG.get("couponInfo") as FormGroup;
    }

    get DIAGCODE(): FormArray {
        return this.otherValFG.get("diagCode") as FormArray;
    }

    get PROCMODIFIER(): FormArray {
        return this.otherValFG.get("procModifier") as FormArray;
    }

    closeModal() {
        this.IsPopUpClosed.emit();
    }

    cancelOnEdit() {
        this.CanclOthrValueInfo.emit("Patient1");
    }

    saveOtherValueInfo(type?: any) {
        const rxExtraExpDtFG = this._fb.group({ RxExpDt: this.RXEXTRA.controls["RxExpDt"].value });
        var dateChecks = [{
            control: "RxExpDt",
            minDate: this.minDate,
            maxDate: ""
        }];
        if (this._dateChecks.checkDateValidationAtTimeofSave(rxExtraExpDtFG, dateChecks)) {
            return;
        } else {
            if (this.otherValFG.valid) {
                this.fgInvaild = false;
                this._rxStore.storeRxMiscInfo(this.otherValFG.getRawValue());
                this.compDesOnSave = true;
                if (!type) {
                    this._alertServ.success("Misc Info Added. Please click on Rx save");
                    this.closeModal();
                }
            } else {
                this.fgInvaild = true;
            }
        }

    }
    defaultPatchinggFromRefill() {
        this.fgInvaild = false;
        const rxInfo = this.rxFG.value;
        const rxExtraInfo = rxInfo['rxExtraInfo'];
        console.log(rxExtraInfo);
        this.otherValFG.controls["rxExtra"].patchValue(rxExtraInfo.rxExtra);
        this.otherValFG.controls["prescInfo"].patchValue(rxExtraInfo.prescInfo);
        this.otherValFG.controls["couponInfo"].patchValue(rxExtraInfo.couponInfo);
        this.otherValFG.controls["diagCode"].patchValue(rxExtraInfo.diagCode);
        this.otherValFG.controls["procModifier"].patchValue(rxExtraInfo.procModifier);

        const rxExtra = this.otherValFG.controls["rxExtra"] as FormGroup;
        const prescInfo = this.otherValFG.controls["prescInfo"] as FormGroup;
        const couponInfo = this.otherValFG.controls["couponInfo"] as FormGroup;
        const diagCode = this.otherValFG.controls["diagCode"] as any;
        const procModifier = this.otherValFG.controls["procModifier"] as any;

        rxExtra.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
        prescInfo.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
        couponInfo.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);

        diagCode.controls.map((dcode: any) => {
            dcode.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
        });

        procModifier.controls.map((dcode: any) => {
            dcode.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
        });
        this.otherValFG.controls["PrescriptionId"].patchValue(rxInfo["Prescription"]["Id"]);
    }
    async saveOtherValueInfoFmEdit(type?: any) {
        const rxExtraExpDtEditFG = this._fb.group({ RxExpDt: this.RXEXTRA.controls["RxExpDt"].value });
        var dateChecks = [{
            control: "RxExpDt",
            minDate: this.minDate,
            maxDate: ""
        }];
        if (this._dateChecks.checkDateValidationAtTimeofSave(rxExtraExpDtEditFG, dateChecks)) {
            return;
        } else {
            if(!this.otherValFG.valid && !this.RXEXTRA.controls["TreatmentTypeId"].value) {
                const element: any = document.getElementById('treatmenttype').getElementsByTagName("input")[0];
            if (element)
                element.focus();
            }
            
            if (this.otherValFG.valid) {
                this.fgInvaild = false;
                const rxInfo = this.rxFG.getRawValue();
                const rxExtra = this.otherValFG.controls["rxExtra"] as FormGroup;
                const prescInfo = this.otherValFG.controls["prescInfo"] as FormGroup;
                const couponInfo = this.otherValFG.controls["couponInfo"] as FormGroup;
                const diagCode = this.otherValFG.controls["diagCode"] as any;
                const procModifier = this.otherValFG.controls["procModifier"] as any;


                rxExtra.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
                prescInfo.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
                couponInfo.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);

                diagCode.controls.map((dcode: any) => {
                    dcode.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
                });

                procModifier.controls.map((dcode: any) => {
                    dcode.controls["PrescRefillId"].setValue(rxInfo["PrescReFill"]["Id"]);
                });

                this.otherValFG.controls["PrescriptionId"].patchValue(rxInfo["Prescription"]["Id"]);
                this.otherValFG.controls["oldRxExtra"].patchValue(this.oldRxExtra);
                if (this.IsFromNewRx) {
                    rxExtra.controls["TreatmentTypeId"].patchValue(this.rxFG.controls["TreatmentTypeId"].value);
                    this.IsMiscInfoClosedFromNewRx.emit(this.otherValFG.value);
                    return;
                }
                const resp = await this._rxService.SaveRxExtraValues(
                    rxInfo["PrescReFill"]["Id"], this.otherValFG.getRawValue()).toPromise();
                this.rxFG.controls["TreatmentTypeId"].patchValue(rxExtra.controls["TreatmentTypeId"].value);
                if (this.rxFG.controls["rxExtraInfo"] && this.rxFG.controls["rxExtraInfo"].value["rxExtra"])
                    this.rxFG.controls["rxExtraInfo"].value["rxExtra"]["TreatmentTypeId"] = rxExtra.controls["TreatmentTypeId"].value
                if (resp && resp["Response"]) {
                    this.rxFG.controls["PrescReFill"].patchValue({
                        ModifiedDtTm: resp["modifiedDate"]
                    });
                    this.originalData.rxExtra.RxExpDt = this.dataFromBackend;
                    const miscRxAudtMdl = new RxAuditLog().rxExtraAuditModel;
                    this._nwAudtUtl.saveAuditChange(
                        this.originalData,
                        this.otherValFG.getRawValue(),
                        "MiscRxInfo",
                        "Rx",
                        rxInfo["PrescReFill"]["Id"],
                        miscRxAudtMdl
                    );
                    this.originalData = Object.assign({}, this.otherValFG.getRawValue());
                    if (!type && !this.IsFromNewRx) {
                        this._alertServ.success("Misc Info updated successfully");
                    }
                }
                this.compDesOnSave = true;
                if (!type && !this.IsFromNewRx) {
                    this.cancelOnEdit();
                }
            } else {
                this.fgInvaild = true;
            }
        }

    }


    checkIndexandProceed(e, index?) {
        // if (e.target.id === "Procedure Modifiers5") {
        //     e.preventDefault();
        //     if (document.getElementById("DIAGCODE1")) {
        //         document.getElementById("DIAGCODE1").focus();
        //     }
        // } else if (e.target.id === "DIAGCODE5") {
        //     e.preventDefault();
        //     if (document.getElementById("PrescOrgPresc1")) {
        //         document.getElementById("PrescOrgPresc1").focus();
        //     }
        // } else if (e.target.id === "CoupVal1") {
        //     e.preventDefault();
        //     if (document.getElementById("saveMiscInfo")) {
        //         document.getElementById("saveMiscInfo").focus();
        //     }
        // }
        if (e.target.id === "Procedure Modifiers5" || e.target.id === "DIAGCODE1" || e.target.id === "DIAGCODE2" || e.target.id === "DIAGCODE3" || e.target.id === "DIAGCODE4" || e.target.id === "DIAGCODE5" || e.target.id === "PrescOrgPresc1" || e.target.id === "CoupVal1") {
            e.preventDefault();
            document.getElementById(e.target.id === "Procedure Modifiers5" ? "DIAGCODE1" : (e.target.id === "DIAGCODE1" ? "DIAGCODE2" : (e.target.id === "DIAGCODE2" ? "DIAGCODE3" :
                (e.target.id === "DIAGCODE3" ? "DIAGCODE4" : (e.target.id === "DIAGCODE4" ? "DIAGCODE5" :
                    (e.target.id === "DIAGCODE5" ? "PrescOrgPresc1" : (e.target.id === "CoupVal1" ? "saveMiscInfo" : ''))))))).focus();
        }
    }

    checkProcDuplicate(index) {
        if (this.PROCMODIFIER && this.PROCMODIFIER.value[index]["CPTCode"]) {
            this.PROCMODIFIER.controls[index].patchValue({ CPTCode: this.PROCMODIFIER.value[index]["CPTCode"].trim() });
            const ProcData = [this.PROCMODIFIER.value[0]["CPTCode"], this.PROCMODIFIER.value[1]["CPTCode"],
            this.PROCMODIFIER.value[2]["CPTCode"], this.PROCMODIFIER.value[3]["CPTCode"]];
            ProcData.splice(index, 1);
            if (ProcData.indexOf(this.PROCMODIFIER.value[index]["CPTCode"]) > -1) {
                this.PROCMODIFIER.controls[index].patchValue({ CPTCode: null });
                document.getElementById("Procedure Modifiers" + index).focus();
                this._alertServ.warning("Procedure Modifiers exist.");
            }
        }
    }

    checkIsSubClarfExist(e, cntrl) {
        if (e.value && e.value.Id) {
            const cntrlName = ["SubClarifId1", "SubClarifId2", "SubClarifId3"];
            const checkCntrlName = cntrlName.filter(c => c !== cntrl);
            const checkCntrlData = [];
            checkCntrlName.map(val => {
                checkCntrlData.push(this.RXEXTRA.value[val]);
            });
            if (checkCntrlData.indexOf(e.value.Id) > -1) {
                this.RXEXTRA.controls[cntrl].setValue(null);
                this._alertServ.warning("Subm. Clarification exist.");
            }
        }
    }

    checkIsExist(e, index) {
        let isExits = false;
        if (e && e.DIAGCODE) {
            isExits = this.otherValFG.value.diagCode.some(
                (d, i) => index !== i && d.DiagnosisId === e.DIAGCODE
            );
        } else if (e && e.Code) {
            isExits = this.otherValFG.value.diagCode.some(
                (d, i) => index !== i && d.DiagnosisId === e.Code
            );
        }
        if (isExits) {
            const diagFG = this.otherValFG.controls.diagCode as FormArray;
            const diagCntrl = diagFG.controls[index] as FormGroup;
            diagCntrl.controls["DiagnosisId"].setValue(null);
            this._alertServ.warning("Diagnosis code exist.");
        } else {
            if (e && e.DIAGCODE) {
                this._patientSVC.getDiagnosisDetails(e.DIAGCODE)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(resp => {
                        if (resp) {
                            if (!this.patDiagnis || !this.patDiagnis.TempDiagnosis) {
                                this.patDiagnis = [];
                                this.patDiagnis.TempDiagnosis = [];
                            }
                            this.patDiagnis.TempDiagnosis.push(resp);
                            const diagData = this.patDiagnis.TempDiagnosis;
                            this.patDiagnis.TempDiagnosis = [];
                            this._cdr.detectChanges();
                            this.patDiagnis.TempDiagnosis = diagData;
                        }
                    })
            }
        }
    }

    openDiagList(index) {
        this.diagCodepopindex = index;
        this.modelRef = this._modalService.open(this.diagList, {
            backdrop: false,
            size: "lg",
            keyboard: false,
            windowClass: "modal-center-to-page"
        });
    }

    saveandCloseDiagList(value) {
        if (this.modelRef) {
            this.modelRef.close();
        }
        const diagFG = this.otherValFG.controls.diagCode as FormArray;
        const diagCntrl = diagFG.controls[this.diagCodepopindex] as FormGroup;
        diagCntrl.controls["DiagnosisId"].patchValue(null);
        setTimeout(() => {
            diagCntrl.controls["DiagnosisId"].setValue(value.Code);
            diagFG.controls[this.diagCodepopindex].markAsDirty();
            this.checkIsExist(value, this.diagCodepopindex);
        }, 100);
        setTimeout(() => {
            const id = "diagCode" + (this.diagCodepopindex + 1);
            if (document.getElementById(id)) {
                const data: any = document.getElementById(id).getElementsByTagName("input")[0];
                data.focus();
            }
        }, 300);
    }

    selectDrug(drugSelected) {
        if (drugSelected) {
            this.drugInfoByNDC = drugSelected;
            this.PRESCINFO.patchValue({
                OrgPrescProd: drugSelected.ndc,
                OrgPrescDrugName: drugSelected.drugname + ' ' + drugSelected.strength + ' ' + drugSelected.drugformname
            });
        } else {
            this.PRESCINFO.patchValue({
                OrgPrescProd: null,
                OrgPrescDrugName: null
            });
        }
        this.PRESCINFO.markAsDirty();
    }

    // getDrugData(drugNdc) {
    //     let re = /\-/gi;
    //     drugNdc = drugNdc.replace(re, "");
    //     if (drugNdc.length === 11) {
    //         this._commonSvc.getDrugSuggest(drugNdc).subscribe(resp => {
    //             if (resp && resp.length === 1) {
    //                 let drug = resp[0];
    //                 this.drugInfoByNDC = resp[0];
    //             } else {
    //                 this.drugInfoByNDC = null;
    //             }
    //         });
    //     } else {
    //         this.drugInfoByNDC = null;
    //     }
    // }
}
