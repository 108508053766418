import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AuditLogUtils } from 'src/app/utils/audit-log.util';
import { CommonService, PatPreDrugModalService, RphVerificationService, } from '../../../services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VerificationTabsListComponent } from '../../verification-tabs/verification-tabs-list/verification-tabs-list.component';

@Component({
  selector: "app-common-rph-verify",
  templateUrl: "./common-rph-verify.component.html"
})
export class CommonRphVerifyComponent implements OnInit {
    openRphPopup: boolean;
    modalRef: any;
    verificationType: any;
    openPopUp: boolean;
    selectedRxInfo: any = [];
    warningInfo: string;
    totalInvalidRxForRpv: any = [];
    unBilled: boolean;
    dpvCheck: any;
    verfiRe: any;
    verifiedAlready: any = [];
    totalValidRxForRph: any = [];
    warningInfoForDisplay: string;
    rphTodaysFG: FormGroup;
    verifiedData: any = [];
    verifiedDetails: any;
    rxInfo: any;
    batchRpv: any = [];
    verifiedArray: any = [];
    orginal: number;
    unsubscribe$: Subject<void> = new Subject();
    beforeRph: number;
    reqRxVerfStng: number;
    isFrRxVerfication: boolean;
    // workFlwPatStng: number;
    @Input()
    set OpenrphPopup(val: boolean) {
        this.openRphPopup = val;
    }
    @Input()
    set SelectedRxInfo(rxs: any) {
        this.selectedRxInfo = rxs;
    }
    @Input()
    set RphFormGroup(fg: any) {
        this.rphTodaysFG = fg;
    }
    @Input()
    set isRxVerification(frRxVerf: boolean) {
        this.isFrRxVerfication = frRxVerf;
    }
    @Output()
    emitGetData = new EventEmitter();
    @ViewChild("invalidRx", { static: true })
    invalidRx: any;

    @ViewChild("Confirmation", { static: true })
    Confirmation: any;
    @ViewChild("AlreadyVerif", { static: true })
    AlreadyVerif: any;

    @ViewChild("VerifiedRxs", { static: true })
    VerifiedRxs: any;

  constructor(private _modalService: NgbModal,
    private _commonServ:CommonService,
    private _rphServ: RphVerificationService,
    private _router: Router,
    private _auditUtils: AuditLogUtils,
    private _patPreDrugModalServ: PatPreDrugModalService) { }

  ngOnInit() {
      this.openPopupModal();
  }
  openPopupModal() {
    if (this.openRphPopup) {
        this.modalRef = this._modalService.open(this.Confirmation, {
            backdrop: false,
            keyboard: false,
            centered: true,
            windowClass: "large--content_medium"
        });
    }
}

verifyInfo(type) {
    this.verificationType = type;
    if (type === "individually") {
        if ((!this.isFrRxVerfication && sessionStorage.getItem("rph-verify")) || (this.isFrRxVerfication && sessionStorage.getItem("rx-verify"))) {
            this.processedRxsRphVerification(type);
        }else{ 
            this.openPopUp = true;
        }
    } else {
         this.processedRxsRphVerification(type);
    }
}
ngOnDestroy() {
	this.unsubscribe$.next();
	this.unsubscribe$.complete();
}
getRxStatusForDisplay(data, warningMessage) {
    return  ("<br><label>" +
    data["Prescription"]["PrescNum"] + "-" + data["PrescReFill"]["ReFillNum"] +
    (this._commonServ.checkIsPartialFill(data["PrescReFill"], data["Prescription"]) ? "-" + data["PrescReFill"]["PartialFillNo"] : " ") +
    " </label> :  <b>" + warningMessage + "</b>");
}
processedRxsRphVerification(type?) {
    if (this.selectedRxInfo && this.selectedRxInfo.length > 0) {
        const rxInfo = this.selectedRxInfo.shift();
        this.otherVerificationChecks(rxInfo, type);
    } else {
        this.selectedRxInfo = [];
    }
}
    async otherVerificationChecks(rxInfo, type?) {
        // this.orginal = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification_for_Original_Rx_Only"), 0);
        this.unBilled =   this._commonServ.getSetttingValue("WorkFlowSettings","ALLOW_RPH_VERF_UNB_RX" ) === "0";
        this.dpvCheck = this._commonServ.getSetttingValue("WorkFlowSettings","MNDT_DRUG_PICK_VERF_BEF_PHARM_VERF") === "1";
        // this.beforeRph = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings",
        //                  "Rx_Verification_Required_Before_RPh_Verification"), 0);
        this.reqRxVerfStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings", "Require_Rx_Verification"), 0);
        // this.workFlwPatStng = parseInt(this._commonServ.getSetttingValue("WorkFlowSettings","Require_Rx_Verification_for_workflow_patient_only"), 0);
        this.verfiRe = await this._rphServ.IsRphVerifiedAlready(rxInfo.Prescription.Id, rxInfo.PrescReFill.Id, this.isFrRxVerfication).toPromise();
        const rxRefillFillDisplay = rxInfo["Prescription"]["PrescNum"] + " - " + rxInfo["PrescReFill"]["ReFillNum"] +
        (this._commonServ.checkIsPartialFill(rxInfo["PrescReFill"], rxInfo["Prescription"]) ? " - " + rxInfo["PrescReFill"]["PartialFillNo"] : " ");

        if (rxInfo.PrescReFill.StatusId === 2 || rxInfo.Prescription.RxStatusId === 2) {
            this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" + " is a Deferred Rx, it cannot be verified.";
            this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo, "Deferred Rx"));
        } else if (rxInfo.PrescReFill.StatusId === 4 || rxInfo.Prescription.RxStatusId === 4) {
        this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" + " is a Transferred Rx, it cannot be verified.";
        this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo, "Transferred Rx"));
    } else if (!this.isFrRxVerfication && rxInfo.RxBill.StatusId === 1 && this.unBilled) {
        this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" + " is a Unbilled Rx, it cannot be verified.";
        this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo, "Unbilled Rx"));

    } else if (!this.isFrRxVerfication && (!rxInfo["drugPickVerificationStatus"] || (rxInfo["drugPickVerificationStatus"] && (rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "rv" ||rxInfo["drugPickVerificationStatus"].trim().toLowerCase() === "nv"))) && this.dpvCheck) {
        this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
        " Drug pick verification is required before pharmacist verification.";
        this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo,
            "Drug pick verification is required before pharmacist verification."));
    } else if (!this.isFrRxVerfication && this.reqRxVerfStng) {
        /*if (this.workFlwPatStng) {
            // if (rxInfo && rxInfo.Patient && rxInfo.Patient.IsWorkflowPatient && this.orginal && this.beforeRph &&
            //     !(rxInfo.rph1VerificationStatus && rxInfo.rph1VerificationStatus.toLowerCase() === "r")
            //      && (rxInfo && (rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
            //         rxInfo["Prescription"]["IsDiscontinued"] = false;
            //         this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
            //         " Rx  verification is required before pharmacist verification.";
            //         this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo,
            //         " Rx  verification is required before pharmacist verification."));
            // } else if (rxInfo && rxInfo.Patient && rxInfo.Patient.IsWorkflowPatient && !(this.orginal) && this.beforeRph && !(rxInfo["rph1VerificationStatus"] &&
            // rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
            //     rxInfo["Prescription"]["IsDiscontinued"] = false;
            //     this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
            //     " Rx  verification is required before pharmacist verification.";
            //     this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo,
            //         " Rx  verification is required before pharmacist verification."));
            // } else
            if (this.verfiRe && this.verfiRe.Status === "V ") {
                this.verifiedAlready.push(rxInfo);
                this.verifiedData.push(this.verfiRe);
            } else {
                this.totalValidRxForRph.push(rxInfo);
            }
        // } else if (this.orginal && this.beforeRph &&
        //     !(rxInfo.rph1VerificationStatus && rxInfo.rph1VerificationStatus.toLowerCase() === "r")
        //      && (rxInfo && (rxInfo["PrescReFill"]["ReFillNum"]) === 0)) {
        //         rxInfo["Prescription"]["IsDiscontinued"] = false;
        //         this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
        //         " Rx  verification is required before pharmacist verification.";
        //         this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo,
        //         " Rx  verification is required before pharmacist verification."));
        // } else if (!(this.orginal) && this.beforeRph && !(rxInfo["rph1VerificationStatus"] &&
        //     rxInfo.rph1VerificationStatus.toLowerCase() === "r")) {
        //         rxInfo["Prescription"]["IsDiscontinued"] = false;
        //         this.warningInfo = "<label>" + rxRefillFillDisplay + " " + "</label>" +
        //         " Rx  verification is required before pharmacist verification.";
        //         this.totalInvalidRxForRpv.push(this.getRxStatusForDisplay(rxInfo,
        //             " Rx  verification is required before pharmacist verification."));
        }  else*/
         if (this.verfiRe?.Status?.trim() === "V") {
            this.verifiedAlready.push(rxInfo);
            this.verifiedData.push(this.verfiRe);
        } else {
            this.totalValidRxForRph.push(rxInfo);
        }
    } else if (this.verfiRe?.Status?.trim() === "V" || this.verfiRe?.["Status"]?.trim() === "R") {
        this.verifiedAlready.push(rxInfo);
        this.verifiedData.push(this.verfiRe);
    } else {
        this.totalValidRxForRph.push(rxInfo);
    }
    if (this.selectedRxInfo && this.selectedRxInfo.length > 0) {
        this.processedRxsRphVerification();
    } else {
         if (
             this.totalValidRxForRph &&
             this.totalValidRxForRph.length &&
             this.totalValidRxForRph.length === 1 &&
             this.totalInvalidRxForRpv &&
             !this.totalInvalidRxForRpv.length
         ) {
             if (this.verificationType === "individually") {
                 this.routeToRphVerf(this.totalValidRxForRph.shift());
             } else {
                 const data = this.totalValidRxForRph;
                 this.totalValidRxForRph = [];
                 this.batchRphVerification(data);
             }
         } else if (
             this.totalInvalidRxForRpv &&
             this.totalInvalidRxForRpv.length
         ) {
             this.warningInfoForDisplay =
                 this.totalInvalidRxForRpv &&
                 this.totalInvalidRxForRpv.length &&
                 this.totalInvalidRxForRpv.length > 1
                     ? "Following selected Rx's cannot be verified:" +
                       this.totalInvalidRxForRpv.join("")
                     : this.warningInfo;
             this.modalRef = this._modalService.open(this.invalidRx, {
                 centered: true,
                 keyboard: false,
                 backdrop: false,
                 windowClass: "large--content",
             });
         } else if (
             this.totalValidRxForRph &&
             this.totalValidRxForRph.length &&
             this.totalValidRxForRph.length > 1 &&
             this.totalInvalidRxForRpv &&
             !this.totalInvalidRxForRpv.length
         ) {
             if (this.verificationType === "individually") {
                 this.routeToRphVerf(this.totalValidRxForRph.shift());
             } else {
                 const data = this.totalValidRxForRph;
                 this.totalValidRxForRph = [];
                 this.batchRphVerification(data);
             }
         } else if (
             this.verifiedAlready &&
             this.verifiedAlready.length &&
             this.verifiedAlready.length === 1 &&
             this.totalInvalidRxForRpv &&
             !this.totalInvalidRxForRpv.length &&
             this.totalValidRxForRph &&
             !this.totalValidRxForRph.length
         ) {
             if (this.verificationType === "individually") {
                 this.openReVerifyModal();
             } else {
                 const data = this.verifiedAlready;
                 this.verifiedAlready = [];
                 this.batchRphVerification(data);
             }
         } else if (
             this.verifiedAlready &&
             this.verifiedAlready.length &&
             this.verifiedAlready.length > 1 &&
             this.totalInvalidRxForRpv &&
             !this.totalInvalidRxForRpv.length &&
             this.totalValidRxForRph &&
             !this.totalValidRxForRph.length
         ) {
             if (this.verificationType === "individually") {
                 this.openReVerifyModal();
             } else {
                 const data = this.verifiedAlready;
                 this.verifiedAlready = [];
                 this.batchRphVerification(data);
             }
         }

    }
}
openReVerifyModal() {
    this.rxInfo =   this.verifiedAlready[0];
    this.verifiedDetails = this.verifiedData.shift();
    this.modalRef = this._modalService.open(this.AlreadyVerif, {centered: true,
            backdrop: false,
            keyboard: false,
            windowClass: "large--content"});
}
    processRhvForAlreadyVerified(type) {
        if (type === "yes") {
            if (this.verifiedAlready && this.verifiedAlready.length) {
                if (this.verificationType === "individually") {
                this.routeToRphVerf(this.verifiedAlready.shift());
                } else {
                     this.batchRphVerification(this.verifiedAlready);
                     this.verifiedAlready = [];
                }
            }
        } else {
            this.verifiedAlready.shift();
            if (this.verifiedAlready && this.verifiedAlready.length) {
                 this.openReVerifyModal();
        }
    }
    }
    processRphVerificationfromInvalidRx(frmInvalid?) {
        if(frmInvalid) {
            this.totalInvalidRxForRpv = [];
            if (this.modalRef) {
                this.modalRef.close();
                this.modalRef = null;
            }
        }
        if (this.totalValidRxForRph && this.totalValidRxForRph.length) {
                if (this.verificationType === "individually") {
                    this.routeToRphVerf(this.totalValidRxForRph.shift());
                } else {
                    const data = this.totalValidRxForRph;
                    this.totalValidRxForRph = [];
                    this.batchRphVerification(data);
                }
        } else if  (this.verifiedAlready && this.verifiedAlready.length) {
            if (this.verificationType === "individually") {
            this.openReVerifyModal();
            } else {
                const data = this.verifiedAlready;
                this.verifiedAlready = [];
                this.batchRphVerification(data);
            }
        } else {
            if (this.verificationType === "mark" && this.batchRpv && this.batchRpv.length) {
                this._rphServ.SendBatchRphVerificationDetails(this.batchRpv)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.getChangedValues();
                        this.modalRef = this._modalService.open(this.VerifiedRxs, {
                            centered: true,
                            backdrop: false,
                            keyboard: false,
                            windowClass: "large--content"
                    });
                    }

            });
            } else {
                this.closeModal();
            }
        }
    }
    routeToRphVerf(rxInfo?) {
        /*this._patPreDrugModalServ._patPreDrugModal$.next("RphVerification");
        const modalRef = this._patPreDrugModalServ.modalInstanceRef;
        modalRef.componentInstance.IsPatientHistory = true;
        modalRef.componentInstance.RxID = rxInfo.Prescription.PrescNum ;
        modalRef.componentInstance.RefilID = rxInfo.PrescReFill.ReFillNum ;
        modalRef.componentInstance.FillId = rxInfo.PrescReFill.PartialFillNo;
        modalRef.componentInstance.CloseRphModal
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                if (modalRef) {
                    modalRef.close();
                    this.emitGetData.emit(null);
                }
            }
                this.processRphVerificationfromInvalidRx();
        });*/
        const modalRefVerfTab = this._modalService.open(VerificationTabsListComponent, {size: "lg",  windowClass: "md-xx-screenWidth comp--modal modal-large modal-center-to-page", backdrop: false, keyboard: false});
        modalRefVerfTab.componentInstance.IsPatientHistory = true;
        modalRefVerfTab.componentInstance.RxInfo = rxInfo;
        modalRefVerfTab.componentInstance.RxID = rxInfo.Prescription.PrescNum ;
        modalRefVerfTab.componentInstance.RefilID = rxInfo.PrescReFill.ReFillNum ;
        modalRefVerfTab.componentInstance.FillID = rxInfo.PrescReFill.PartialFillNo;
        modalRefVerfTab.componentInstance.RefillNumId = rxInfo.PrescReFill.Id;
        modalRefVerfTab.componentInstance.IsRxVerification = this.isFrRxVerfication;
        modalRefVerfTab.componentInstance.activeTabId = !this.isFrRxVerfication ? "rphVerfTab" : "rxVerfTab";
        modalRefVerfTab.componentInstance.closeTabs.subscribe((resp: any) => {
            if (resp) {
                if (modalRefVerfTab) {
                    modalRefVerfTab.close();
                    this.emitGetData.emit(null);
                }
            }
            this.processRphVerificationfromInvalidRx();
        });
    }

    batchRphVerification(rxInfo) {
        rxInfo.map((item: any) => {
            this.batchRpv.push(
                {
                    PartialfillNum: item.PrescReFill.PartialFillNo,
                    PrescId: item.Prescription.Id,
                    PrescNum: item.Prescription.PrescNum,
                    PrescRefillId:  item.PrescReFill.Id,
                    RefillNum: item.PrescReFill.ReFillNum,
                    Status: !this.isFrRxVerfication ? "V" : "R",
                    VerifDtTm: moment(Date.now()).format("MM-DD-YYYY"),
                    SendReadyForPickupAlerts:  this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts"),
                    SendReadyForPickupAlertsWhen: this._commonServ.getSetttingValue("MessageSettings", "Send_Ready_For_Pickup_Alerts_When")
                }
            );
            const rxNo =
            // tslint:disable-next-line:max-line-length
            item.Prescription.PrescNum + "-" + item.PrescReFill.ReFillNum + (this._commonServ.checkIsPartialFill(item.PrescReFill, item.Prescription) ? "-" + item.PrescReFill.PartialFillNo  : " ");
           this.verifiedArray.push(rxNo);
        });
        this.processRphVerificationfromInvalidRx();
    }

    afterVerifyRphVerification(event) {
        this.openPopUp = event;
        if ((!this.isFrRxVerfication && sessionStorage.getItem("rph-verify")) || (this.isFrRxVerfication && sessionStorage.getItem("rx-verify"))) {
            this.processedRxsRphVerification();
        }else{ 
            this.emitGetData.emit(null);
        }
    }
    closeModal(type?: any) {
        if (this.modalRef) {
            this.modalRef.close();
            this.modalRef = null;
        }
        this.emitGetData.emit(null);
    }
    getChangedValues() {
        if(this.batchRpv && this.batchRpv.length) {
            this.batchRpv.map((item: any) => {
                this._auditUtils.getChangedValues(
                    null,
                    this.isFrRxVerfication ? {"Rx Verified": "Verified"} : {"Rph Verified": "Verified"},
                    (this.isFrRxVerfication) ? "Rx" : "Rph" + " Verification",
                    "Rx",
                      item['PrescRefillId']
                );
                this._auditUtils.getChangedValues(
                    null,
                    this.isFrRxVerfication ? {"Rx Verification Status": "Pass"} : {"Rph Verification Status": "Pass"},
                    (this.isFrRxVerfication) ? "Rx" : "Rph" + " Verification",
                    "Rx",
                      item['PrescRefillId']
                );
            });
        }
        this.batchRpv = [];
    }
}
