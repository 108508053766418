import { constant } from "./../app.constants";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable ,  BehaviorSubject } from "rxjs";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class RxTransferService {

    payRecords$: Observable<any>;
    _payRecords$: BehaviorSubject<any>;


    constructor(private _http: HttpClient) {
        this._payRecords$ = new BehaviorSubject({}) as BehaviorSubject<any>;
        this.payRecords$ = this._payRecords$.asObservable();
    }

    getRxTransferDetails(Id: any, type: any, PrescRefillId) {
        return this._http.get(
            constant.GET_TransferRx_Details.replace("{PrescNum}", "" + Id).replace("{filter}", "" + type)
            .replace("{PrescRefillId}", "" + PrescRefillId)
        );
    }

    printReport(payload: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_PRINT_REPORT , payload , { headers: headers, responseType: "blob" });
    }

    sendFax(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_SEND_FAX , payload);
    }

    updateFaxToPharm(pharmacyId: any, faxNum: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_UPDATE_FAXNUM_TO_PHARMACY.replace(
                "{pharmacyNum}",
                "" + pharmacyId
                ).replace("{faxNum}", "" + faxNum),
                null);
    }

    getRxTransferPopups(Id: any, page: boolean, RefillNo: any) {
        return this._http.get(
            constant.GET_TransferRx_Popups.replace(
                "{PrescNum}",
                "" + Id
            ).replace("{page}", "" + page).replace("{RefillNo}", "" + RefillNo)
        );
    }

    getPharmacyDetails(Id: any) {
        return this._http.get(
            constant.GET_Pharmacy_Details.replace("{PharmId}", "" + Id)
        );
    }
    getPharmacyNames(noLoader?) {
        if(noLoader) {
            return this._http.get(constant.GET_Pharmacy_Names,reqOptns);
        } else {
            return this._http.get(constant.GET_Pharmacy_Names);
        }
    }
    postRxTransferInOut(data: any) {
        return this._http.post(constant.POST_TransferRxInOut, data);
    }
    addPharmacy(data: any) {
        return this._http.post(constant.POST_Add_Pharmacy, data);
    }
    editPharmacy(data: any) {
        return this._http.put(constant.PUT_Edit_Pharmacy, data);
    }
    deletePharmacy(Id: any) {
        return this._http.delete(constant.DELETE_Delete_Pharmacy.replace("{PharmId}", "" + Id));
    }
    checkDuplicatePharmacy(data: any) {
        return this._http.post<any>(constant.POST_Duplicate_Pharmacy, data);
    }
    RxTransferpopUp(id: any) {
        return this._http.get(
            constant.GET_Pharmacy_Pop.replace("{PrescNum}", "" + id)
        );
    }

    getRXTransmissionLog(prescRefillId): Observable<any> {
        return this._http.get<any>(constant.GET_Rx_TransmissionLog.replace("{prescRefillId}", "" + prescRefillId));
    }

    getRXTransmissionLogCount(PrescID) {
        return this._http.get<any>(constant.GET_Rx_TransmissionLogCount.replace("{prescRefillId}", "" + PrescID));
    }


    getTransferInDetails(PresNum, RefillNum, type) {
        return this._http.get<any>(constant.GET_TransferIn_Details.replace("{rxnum}", "" + PresNum).replace("{filter}", "" + type).replace("{refillNum}", "" + RefillNum));
    }

    deletePayRecord(payId: number) {
        return this._http.delete<boolean>(constant.DELETE_Rx_RxPay.replace("{payId}", "" + payId));
    }

    updatePayRecord(data) {
        return this._http.put(constant.UPDATE_Rx_RxPay, data);
    }

    updateXferInfo(data) {
        return this._http.put(constant.PUT_Transfer_Details, data);
    }

    getDeleteConfirmation(PharmId) {
        return this._http.get<any>(constant.delete_Transfer_Pharmacy.replace("{PharmId}", "" + PharmId));
    }

    getTransferRxOutCheckList(payload: any) {
        return this._http.post(constant.POST_TransferRxOut_ChckList, payload);
    }
}
