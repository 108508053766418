import {
    Component,
    OnInit,
    Input
} from "@angular/core";
import { City, DeliveryAddress, State, PatAuditLog, PaginationModel } from "src/app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
    PatientService,
    AlertService
} from "../../../services";
import { RegEx } from "src/app/app.regex";
import { CollectionView } from "@grapecity/wijmo";
import * as _ from "lodash";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { MsgConfig } from "src/app/app.messages";
import { ValidateFormFieldsUtils, NewAuditUtils, WijimoUtil } from "src/app/utils";
import { PatientInfoUtil } from "src/app/utils/patient-info.util";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { StorageService } from "src/app/services/storage.service";

@Component({
    selector: "app-delivery-address",
    templateUrl: "./delivery-address.component.html"
})
export class PDeliveryAddressComponent implements OnInit {
    @Input() patientId: number;
    @Input() patientInfoFG: FormGroup;
    regex = RegEx;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    search = { SearchText: null, SearchBy: null, OrderByColumn: null, SortDirection: null, PageNumber: this.pageNumber, PageSize: this.pageSize };
    citySelected = null;
    stateSelected = null;
    deliveryAddressFG: FormGroup;
    deliveryInfo: any;
    modelRef: any;
    modelRefSM: any;
    addressId: any;
    deliveryAddWJ: CollectionView;
    activeHeaders: any;
    wjHeaders: any;
    totalCount: number;
    addEditInfo: any;
    wijimoId: any;
    modalType: string;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    constructor(
        private modalService: NgbModal,
        private _fb: FormBuilder,
        private _editPatient: PatientService,
        private _alertService: AlertService,
        private _newAudit: NewAuditUtils,
        private _formUtil: ValidateFormFieldsUtils,
        private _patUtils: PatientInfoUtil,
        private _wijimoUtils: WijimoUtil,
        public _storageService: StorageService
    ) {
    }

    ngOnInit() {
        this.getPatientDeliveryAddress();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "patDeliveryAddListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async getPatientDeliveryAddress() {
        this.deliveryInfo = await this._editPatient.getPatientDeliveryAddress(this.patientId, this.search).toPromise();
        this.generateAddressWJ(this.deliveryInfo);
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("patDeliveryAddListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("patDeliveryAddListWJ", storedWJ);
    }

    openPopUpModalAdd(content) {
        this.createFG();
        this.stateSelected = null;
        this.citySelected = null;
        this.modalType = "Add";
        this.modelRef = this.modalService.open(content, { keyboard: false, centered: true, backdrop: false });
    }

    Init(content,grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                if (e.which !== 13) {
                    grid.hitTest(e);
                }
                const selectedData = this.deliveryInfo.find(val => val["Id"] === grid.collectionView["_src"][grid.selection.row]["Id"]);
                this.createFG();
                this.deliveryAddressFG.patchValue(selectedData);
                this.addEditInfo = this.deliveryAddressFG.value;
                if (this.addEditInfo) {
                    this.citySelected = new City();
                    this.stateSelected = new State();
                    this.citySelected.Id = this.addEditInfo.CityId;
                    this.citySelected.StateId = this.addEditInfo.StateId;
                    this.citySelected.Name = this.addEditInfo.CityName;
                    this.stateSelected.Id = this.addEditInfo.StateId;
                    this.stateSelected.Name = this.addEditInfo.StateName;
                }
                this.modalType = "Update";
                this.modelRef = this.modalService.open(content, { keyboard: false, centered: true, backdrop: false });
            }
        });
    }

    openEditAddress(content, s: any, e: any) {
        if (e.which !== 13) {
            s.hitTest(e);
        }
        const selectedData = this.deliveryInfo.find(val => val["Id"] === s.selectedItems[0]["Id"]);
        this.createFG();
        this.deliveryAddressFG.patchValue(selectedData);
        this.addEditInfo = this.deliveryAddressFG.value;
        if (this.addEditInfo) {
            this.citySelected = new City();
            this.stateSelected = new State();
            this.citySelected.Id = this.addEditInfo.CityId;
            this.citySelected.StateId = this.addEditInfo.StateId;
            this.citySelected.Name = this.addEditInfo.CityName;
            this.stateSelected.Id = this.addEditInfo.StateId;
            this.stateSelected.Name = this.addEditInfo.StateName;
        }
        this.modalType = "Update";
        this.modelRef = this.modalService.open(content, { keyboard: false, centered: true, backdrop: false });
    }

    createFG() {
        this.deliveryAddressFG = this._fb.group(new DeliveryAddress());
    }

    generateAddressWJ(data) {
        if (data && data["length"] > 0) {
            this.isDataExists = true;
            this.totalCount = data[0].TotalCount;
            this.deliveryAddWJ = new CollectionView(
                data.map((deliveryAddress, i) => {
                    const k = {};
                    k["Id"] = deliveryAddress.Id;
                    k["Address1"] = deliveryAddress.AddressLine1;
                    k["Address2"] = deliveryAddress.AddressLine2;
                    k["City"] = deliveryAddress.CityName;
                    k["State"] = deliveryAddress.StateName;
                    k["Zip"] = deliveryAddress.ZipCode;
                    return k;
                })
            );
        } else {
            this.totalCount = 0;
            this.isDataExists = false;
            this.deliveryAddWJ = new CollectionView(null)
        }
        this.activeHeaders = [
            "Action",
            "Address1",
            "Address2",
            "City",
            "State",
            "Zip"
        ];
    }

    setPage(page: number) {
        this.search.PageNumber = page;
        this.getPatientDeliveryAddress();
    }
    setPageSize(size: number) {
        this.search.PageSize = size;
        this.getPatientDeliveryAddress();
    }

    setDefaultValues() {
        this.deliveryAddressFG.controls["CountryId"].setValue(1);
        this.deliveryAddressFG.controls["IsActive"].setValue(true);
    }

    async addDeliveryAddress() {
        if (this.deliveryAddressFG.invalid) {
            this._formUtil.validateAllFormFields(this.deliveryAddressFG);
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            return;
        }
        this.setDefaultValues();
        const resp = await this._editPatient.addPatientDeliveryAddress(this.patientId, this.deliveryAddressFG.value).toPromise();
        if(this.modelRef) {
            this.modelRef.close();
              this.modelRef = null;
        }
        if (resp) {
            const deliveryModel = new PatAuditLog().deliveryAddModel;
            await this._newAudit.saveAuditOnAdd(
                this.deliveryAddressFG.value,
                "DeliveryAddress",
                "Patient",
                this.patientId,
                deliveryModel
            );
            this._alertService.success("Delivery Address info saved successfully.");
            this.getPatientDeliveryAddress();
        } else {
            this._alertService.error(MsgConfig.SAVE_FAIL);
        }
    }

    async updateDeliveryAddress() {
        if (this.deliveryAddressFG.invalid) {
            this._formUtil.validateAllFormFields(this.deliveryAddressFG);
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            return;
        }
        if(this.modelRef) {
            this.modelRef.close();
              this.modelRef = null;
        }
        const resp = await this._editPatient.updatePatientDeliveryAddress(this.deliveryAddressFG.value).toPromise();
        if (resp) {
            const deliveryModel = new PatAuditLog().deliveryAddModel;
            this._newAudit.saveAuditChange(
                this.addEditInfo,
                this.deliveryAddressFG.value,
                "DeliveryAddress",
                "Patient",
                this.patientId,
                deliveryModel
            );
            this._alertService.success("Delivery Address updated successfully.");
            this.getPatientDeliveryAddress();
        } else {
            this._alertService.error(MsgConfig.UPDATE_FAIL);
        }
    }

    getRowDetails(content, s: any, e: any) {
        s.hitTest(e);
        this.addressId = s.selectedItems[0];
        this.modelRefSM = this.modalService.open(content, { keyboard: false, backdrop: false, centered: true,
             windowClass: "large--content" });
    }

    async deletePatientAddress() {
        const resp = await this._editPatient.deletePatientDeliveryAddress(this.addressId["Id"]).toPromise();
        if (resp) {
            const old = [{ name: "Is Active", value: true }];
            const chngData = [{ name: "Is Active", value: false }, { name: "Id", value: this.addressId["Id"] }];
            this._newAudit.SaveAuditOnDelete(
                old,
                chngData,
                "DeliveryAddress",
                "Patient",
                this.patientId);
            this._alertService.success("Patient delivery address deleted successfully.");
            this.getPatientDeliveryAddress();
        } else {
            this._alertService.error(MsgConfig.DELETE_FAIL);
        }
    }

    async setPrimary() {
        const resp = await this._editPatient.setPatientPrimaryAddress(this.patientId, this.addressId["Id"]).toPromise();
        if (resp) {
            const originalPatInfo = Object.assign({}, this.patientInfoFG.getRawValue());
            const patInfo = await this._editPatient.getEditPatientInformation(this.patientId).toPromise();
            this.patientInfoFG.patchValue({
                Addresses: patInfo.Addresses ? patInfo.Addresses : this.patientInfoFG.value["Addresses"],
            });
            (this.patientInfoFG.value["Addresses"]) ?
            this._patUtils.patchCityAndStateValue(this.patientInfoFG.value["Addresses"], this.patientInfoFG)
                    : this._patUtils.makeCityAndStateNull();
            this.getPatientDeliveryAddress();
            this._alertService.success("Primary address set to patient successfully.");
            const prescDataModel = new PatAuditLog().patAuditModel;
            this._newAudit.saveAuditChange(
                originalPatInfo,
                this.patientInfoFG.getRawValue(),
                "EditPatient",
                "Patient",
                this.patientId,
                prescDataModel
        );
        }
    }

    getCityStateFromZipCode(value) {
        if (value) {
            this.citySelected = new City();
            this.citySelected.Id = value.CityId;
            this.citySelected.StateId = value.StateId;
            this.citySelected.Name = value.CityName;
            this.stateSelected = new State();
            this.stateSelected.Id = value.StateId;
            this.stateSelected.Name = value.StateName;

            this.deliveryAddressFG.controls["StateId"].setValue(value.StateId);
            this.deliveryAddressFG.controls["CityId"].setValue(value.CityId);
            this.deliveryAddressFG.controls["StateName"].setValue(value.StateName);
            this.deliveryAddressFG.controls["CityName"].setValue(value.CityName);
        }
    }

    optedCitySelected(val: any) {
        if (val) {
            this.deliveryAddressFG.controls["CityId"].setValue(val.Id);
            this.deliveryAddressFG.controls["CityName"].setValue(val.Name);
        }
     }

     optedStateValue(val: any) {
         if (val) {
            this.deliveryAddressFG.controls["StateId"].setValue(val.Id);
            this.deliveryAddressFG.controls["StateName"].setValue(val.Name);
            this.citySelected = new City();
            this.deliveryAddressFG.controls["CityName"].setValue(null);
        }
     }

    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openPopUpModalAdd(content);
            }
        }
    }

    focusOutFromAdd(event) {
        if (!this.totalCount) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.wijimoId.focus();
        }
    }

    focusToFirst(event) {
        if (document.getElementById("SaveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("SaveButton").focus();
            }, 10);

        }
    }

    init(flex: wjcGrid.FlexGrid) {
        this.wijimoId = flex;
    }

    onAddressChange(address,type){
        const result = address.replace(/^\s+/g, '');
        if(type === "Address1"){
            this.deliveryAddressFG.controls["AddressLine1"].patchValue(result);
        }else{
            this.deliveryAddressFG.controls["AddressLine2"].patchValue(result);
        }

}
    ngOnDestroy(){
        this._storageService.getWijmoGridDataInSession(this.wjHeaders,"patDeliveryAddListWJ")
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
