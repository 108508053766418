<div class="">
    <div class="col-12 p-0 exprx--common-block erx--body insurance--body patient-edit--transfer-in my-0">
        <div class="eprx--block__header text-white pl-3 font-weight-bold" *ngIf="!IsFullscreenMode">
            <div class="row">
                <div class="col-4">
                    Transfer Request Details
                </div>
                <div class="col-8 text-right">
                    <button   class="hotkey_success show_originalReq" (click)="getDetails()">Transfer request Info
                </button>
                </div>
            </div>
        </div>
        <div class="px-0 pt-0" [ngClass] = "IsFullscreenMode? '' : 'eprx--block__content'">
            <div class="col-12">
                <div class="row">
                    <div class="col-2 pr-0 transferable_information">
                        <span class="transfer_type_display"></span>
                        Transferable Rx(s) <span class="font-weight-bold color_green">{{rxList?.TransferableRx?.length ? rxList?.TransferableRx?.length : 0}}</span>
                    </div>
                    <div class="col-10 text-right">
                        <span class="rx--filter_text">
                            <button type="button"
                                class="submit print-med-guide background_orange_RGB border_1px_solid_black"
                                (click)="toggleColorLegend()">Color Legend</button>

                        </span>
                        <i title="Full Screen" id="fullScreen" (click)="showFullScreen()" *ngIf="!IsFullscreenMode"
                            class="fa fa-window-maximize font_icon  icon_postions text-primary font-size_2_2"></i>
                    </div>
                </div>
            </div>
            <div>
                <wj-flex-grid #TransferrableWJ [headersVisibility]="'Column'" [itemsSource]="transferrableWJ"
                    [isReadOnly]="true"  [itemFormatter]="itemFormatter" 
                     (initialized)="init(TransferrableWJ)"
                    id="transferrableNonRxs" [selectionMode]="'Row'" [allowSorting]="true"
                    [ngClass]="[!(rxList?.TransferableRx?.length )? 'no-data': (!IsFullscreenMode? 'wijmo_transfer_in' : 'wijmo_transfer_Fullscreen')]">
                    <wj-flex-grid-column header="" [width]="34">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <span class="text-center">
                                <input class="form-check-input" type="checkbox" [id]="'check'+ item.row" name="" [(ngModel)]="item.IsSelected"
                                    [checked]="item.IsSelected" (click)="selectedItems(item)">
                                <label class="form-check-label" [for]="'check'+ item.row">
                                </label>
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> 
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                        *ngFor="let rxheader of wjHeaders; let i = index">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                            <div *ngIf="rxheader['hName']==='Prescriber Name'" [ngbTooltip]=preToolTipInfo placement="left" triggers="mouseenter:mouseleave" container="body"
                            container="body" [openDelay]="500" tooltipClass="erx-prescriber-tooltip">
                            {{ item[rxheader['hName']] }}
                                <ng-template #preToolTipInfo>
                                    <div>
                                        <div class="row text-left">
                                            <div class="col-md-12">
                                                <label class="text-decoration-underline margin_bottom_1rem">Prescriber Info</label>
                                            </div>
                                            <div class="col-md-6">
                                                <label>NPI# : </label>
                                                <span> {{item["NPI#"]}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label>DEA# : </label>
                                                <span> {{item["DEA#"] | uppercase}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label>Prescriber Telephone# : </label>
                                                <span> {{item["PrescriberInfo"]['Telephone'] ? (["PrescriberInfo"]['Telephone'] | mask : "(000)000-0000") : ''}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label>Prescriber Fax# : </label>
                                                <span> {{item["PrescriberInfo"]['Fax'] ? (["PrescriberInfo"]['Fax'] | mask : "(000)000-0000") : ''}}</span>
                                            </div>
                                            <div class="col-md-12">
                                                <label>Address : </label>
                                                <span> {{item["PrescriberAddress"] | uppercase}}</span>
                                            </div>
                                        </div>
                                    </div>
                            </ng-template>
                            </div>
                            <div *ngIf="rxheader['hName'] !=='Prescriber Name'">
                                {{ item[rxheader['hName']] }}
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div title={{rxheader.hName}}>
                                {{ rxheader.hName }}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists1"></app-no-data-found>
                </wj-flex-grid>
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-2 pr-0 transferable_information">
                        <span class="transfer_type_display"></span>
                        Informational Rx(s) <span class="font-weight-bold unavailable">{{rxList?.NonTransferableRx?.length ? rxList?.NonTransferableRx?.length : 0}}</span>
                        <i class="fas fa-info-circle" style="line-height: 1.5" [ngbTooltip]="'This option only sends the record to the designated pharmacy for informational purposes.'" triggers="mouseover:mouseleave"
                        [autoClose]="true" placement="top" tooltipClass="search-tooltip title-tooltip"></i>
                    </div>
                </div>
            </div>
            <div>
                <wj-flex-grid #NonTransferrableWJ  [itemsSource]="nonTransferrableWJ"  [itemFormatter]="itemFormatter"
                    [isReadOnly]="true" [headersVisibility]="'Column'"  (initialized)="init(NonTransferrableWJ)"
                    id="transferrableNonRxs" [selectionMode]="'Row'" [allowSorting]="true"
                    [ngClass]="[!(rxList?.NonTransferableRx?.length) ? 'no-data': (!IsFullscreenMode? 'wijmo_transfer_in' : 'wijmo_transfer_Fullscreen')]">
                    <wj-flex-grid-column header="" [width]="34">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <span class="text-center">
                                <input class="form-check-input" type="checkbox" [id]="'check'+ item.row" name="" [(ngModel)]="item.IsSelected"
                                    [checked]="item.IsSelected" (click)="selectedItems(item)">
                                <label class="form-check-label" [for]="'check'+ item.row">
                                </label>
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> 
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                        *ngFor="let rxheader of wjHeaders; let i = index">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                            <div *ngIf="rxheader['hName']==='Prescriber Name'" [ngbTooltip]=preToolTipCont placement="left" container="body" triggers="mouseover:mouseleave"
                             container="body" [openDelay]="500" tooltipClass="erx-prescriber-tooltip">
                            {{item[rxheader['hName']] }}
                                <ng-template #preToolTipCont>
                                    <div>
                                        <div class="row text-left">
                                            <div class="col-md-12">
                                                <label class="text-decoration-underline margin_bottom_1rem">Prescriber Info</label>
                                            </div>
                                            <div class="col-md-6">
                                                <label>NPI# : </label>
                                                <span> {{item["NPI#"]}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label>DEA# : </label>
                                                <span> {{item["DEA#"] | uppercase}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label>Prescriber Telephone# : </label>
                                               <span> {{item["PrescriberInfo"]['Telephone'] ? (["PrescriberInfo"]['Telephone'] | mask : "(000)000-0000") : ''}}</span>
                                            </div>
                                            <div class="col-md-6">
                                                <label>Prescriber Fax# : </label>
                                                <span> {{item["PrescriberInfo"]['Fax'] ? (["PrescriberInfo"]['Fax'] | mask : "(000)000-0000") : ''}}</span>
                                            </div>
                                            <div class="col-md-12">
                                                <label>Address : </label>
                                                <span> {{item["PrescriberAddress"] | uppercase}}</span>
                                            </div>
                                        </div>
                                    </div>
                            </ng-template>
                            </div>
                            <div *ngIf=" (rxheader['hName'] !== 'Actions') && rxheader['hName'] !== 'Prescriber Name'">
                                {{item[rxheader['hName']] }}
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div title={{rxheader.hName}}>
                                {{ rxheader.hName }}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExists2"></app-no-data-found>
                </wj-flex-grid>
            </div>
        </div>
    </div>
</div>
<app-transfer-in *ngIf="IseRxTRxREQ"  (ClosePopUpModal)="IseRxTRxREQ=false" [AwaitInfo]="awaitInfo" [currentDetails]="currentDetails"> </app-transfer-in>