export class DrugInteraction {
    EquivalentDrug: EquivalDrug[] = [];
    Counselling: DrugCounselling = null;
    Warnings: string = null;
    Allergies: Allergis = null;
    DiseaseContradication: DiseaseContradication = null;
    DuplicateDrugs: DuplicateDrugs[] = [];
    DrugToDrugInteraction: DrugToDrugIntractn[] = [];
    PregnancyLactationInfo: PregnancyLactationInfo = null;
    LifeStyle: any[] = [];
    AdverseReaction: AdverseReaction[] = null;
    AcbScore: number = null;
    AllergiesCount: boolean = null;
    DiseaseContradicationCount:boolean = null;
    DuplicateDrugsCount:boolean = null;
    AdverseReactionCount:boolean = null;
    DrugToDrugInteractionCount:boolean = null;
    PregnancyLactationInfoCount:boolean = null;
    LifeStyleCount:boolean = null;
    HasAcbScore:boolean = null;
    HasDosecheck:boolean = null
}

export class DrugInteractionData {
    drugId: number = null;
    drugTypeId: number = null;
    patientId: number = null;
    gender: string = null;
    islong: boolean = null;
    languageCulture: string = null;
    calledFrom: string = null;
    patLanguage: string = null;
    isFromEditRx: boolean = null;
    isForCount: boolean = null;
    ageInDays: number = null;
    therapeuticCode: number = null;
    drugClass: number = null;
    CHECK_DRU_AND_DELIVERY: boolean = null;
    CHECK_DRU_BEARING_AGE: boolean = null;
    USE_ACB_SCORING: boolean = null;
    drugNdc: string = null;
    diagnosisList: any = null;
    ddiScanDays: string = null;
    INCIDENCE: string = null;
    ON_SET: string = null;
    SEVERITY_LEVEL: string = null;
    USE_ADV_DRUG_REACTION: string = null;
    DRUGS_WIT_WARN_ONLY:string = null;
    PrescNum: string = null;
}

export class EquivalDrug {
    awppack: number = null;
    costpack: number = null;
    directunitprice: number = null;
    discontdt: Date = null;
    drugbandname: string = null;
    drugcategory: string = null;
    drugclass: number = null;
    drugcode: string = null;
    drugcreateddate: Date = null;
    drugformid: number = null;
    drugmodifieddate: Date = null;
    drugname: string = null;
    drugtypeid: number = null;
    drugtype: string = null;
    drugunitid: number = null;
    drugunit: string = null;
    isdiscontdate: string = null;
    genericname: string = null;
    id: number = null;
    isactive: boolean = null;
    IsPreferred: boolean = null;
    isdeleted: boolean = null;
    manufactid: number = null;
    ndc: string = null;
    qtypack: number = null;
    quickcode: string = null;
    strength: string = null;
    tenantid: number = null;
    unitpricecost: number = null;
    manufacturername: string = null;
    DirectPrice: number = null;
    QtyInHand: number = null;
    LastDispensed: Date = null;
    Drgform: string = null;
    TotalAmount: number = null;
    DisDate: Date = null;
    Size: number = null;
    AWP: number = null;
}

export class DrugCounselling {
    DrugCounsellingDescription: DurgDescription = null;
    Language: boolean = null;
    IsLong: boolean = null;
    DrugName: string = null;
    DrugDescription: string = null;
    DrugId: number = null;
    IsRightAlligned: boolean = null;
}

export class DurgDescription {
    HEADER: string = null;
    DESCRIPTION: string = null;
    FOOTER: string = null;
}

export class Allergis {
    AllergyInfo: string = null;
    IsAllergy: boolean = null;
}

export class DiseaseContradication {
    dtDrugWarnings: string = null;
    DrillDownData: any = null;
    MonographText: string = null;
    IsShowDDC: boolean = null;
}

export class DuplicateDrugs {
    DrugName: string = null;
    rxNumber: string = null;
    DrugNDC: string = null;
    DrugNDC2: string = null;
    Days: number = null;
    Quantity: number = null;
    Filldate1: Date = null;
    Filldate: string = null;
    RefillNum: boolean = null;
    CurrentRefill: boolean = null;
    Description: string = null;
}

export class DrugToDrugIntractn {
    Drug1NDC: string = null;
    Drug2NDC: string = null;
    EFFECTS: string = null;
    MECHANISM: string = null;
    ONSET: string = null;
    SEVERITY: string = null;
    DOCUM: string = null;
    MANAGEMENT: string = null;
    SEVERITYDESCRIPTION: string = null;
    DOCUMENTATIONDESCRIPTION: string = null;
    CONSUMERNOTES: string = null;
    REFERENCE: string = null;
    DRUGINTERACTCLASSNAME1: string = null;
    DRUGINTERACTCLASSNAME2: string = null;
    DRUGNAME: string = null;
    RXNO: string = null;
    DrugToDrugInteraction: DrugToDrugIntractn;
    length: number;
    DuplicateDrugs: any;
}

export class PregnancyLactationInfo {
    dtPregnancyInformation: any[] = [];
    dtLactationInformation: string = null;
    AlternateDrugsForLactation: string[] = [];
    lstpregnancyName: string[] = [];
    lstLactionName: string[] = [];
    IssfeforLabourandDelivery: string = null;
    IssageforChildBeingAge: string = null;
    LactationRating: string = null;
}

export class AdverseReaction {
    IsBlackboxWarning: boolean = null;
    GSTERMID: string = null;
    DISEASENAME: string = null;
    Severity: number = null;
    Onset: number = null;
    INCIDENCEFROM: Date = null;
    INCIDENCETO: Date = null;
    INCIDENCE: string = null;
    ADVERSEREACTIONS: string = null;
    SEVERITYORDER: string = null;
    ONSETORDER: string = null;
    ISBLACKBOX: string = null;
}

export class AlertTypes{
    isAllergy = false;
    isDDT = false;
    isDOSE = false;
    isACB = false;
    isLife = false;
    isPRG = false;
    isADV = false;
    isDDC = false;
    isDDI = false;
    isHighRisk = false
    
}
