
export class PharmacyAddress {
    AddressLine1: string = null;
    AddressLine2: string = null;
    City: string = null;
    State: string = null;
    ZipCode: any = null;
    CountryCode: any = null;
}
export class OrganizationRequest
{
    VendorType: string = null;
    RequestingPharmacyNABP: string = null;
    OrganizationNPI: string = null;
    OrganizationName: string = null;
    OrganizationAddress: string = null;
    OrganizationCity: string = null;
    OrganizationStateProvince: string = null;
    OrganizationPrimaryPhoneNumber: string = null;
    OrganizationPostalCode: string = null;
    OrganizationToken: string = null;
}
export class PatRxHistoryInputParams
{
    patientId : string = null;
    Billstatus: string = null;
    DateTime: string = null;
    StartDate: string = null;
    EndDate: string = null;
    PageNumber: number = null;
    PageSize: number = null;
    OrderByColumn: number = null;
    IsShowExpRefills: number = null;
    GroupView: number = null;
    showCurrentRx: number = null;
    AllowRefillingTransferRx: number = null;
    IsNotFromPatientHistory: number = null;
    fromRph: number = null;
    drug: string = null;
}

export class RxTransferConfirm {
    ConfirmResponse: string = null;
    ResponseReferenceNo: string = null;
    ResponseReasonCode: string = null;
    ResponseReason: string = null;
}
export class Notes {
    Id: number = null;
    TenantId: number = null;
    AppUserId: number = null;
    xNote: string = null;
    NoteCatId: number = null;
    IsShownAsPopup: boolean = null;
    IsActive: boolean = null;
    IsDeleted: boolean = null;
    CreateDtTm: string = null;
    ModifiedDtTm: string = null;
    Name: string = null;
    PopupScreenCodes: string = null;
    IsAddItem: boolean = false;
}