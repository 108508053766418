<!-- Delete Rx Confirmation-->
<ng-template #DeleteRxConfirmation let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation for Delete Rx</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col inline-flex">
                <label>Rx#</label>:
                <div class="label--data">
                    &nbsp;{{ rxInfo?.Prescription?.PrescNum }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Ref#</label>:
                <div class="label--data">
                    &nbsp;{{ rxInfo?.PrescReFill?.ReFillNum }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Fill#</label>:
                <div class="label--data">
                    &nbsp;{{ (_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription) ? rxInfo?.PrescReFill?.PartialFillNo : " -- ") }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Patient</label>:
                <div class="label--data">
                    &nbsp;{{ rxInfo?.Patient?.lastname }},&nbsp;
                    {{ rxInfo?.Patient?.firstname }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Insurance</label>:
                <div class="label--data">
                    &nbsp;{{
                        rxInfo?.insuranceInfo
                            ? rxInfo?.insuranceInfo?.insuCarrier
                                ? rxInfo?.insuranceInfo?.insuCarrier
                                      ?.InsurerCode
                                : " "
                            : " "
                    }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Prescriber</label>:
                <div class="label--data">
                    &nbsp;{{ rxInfo?.Prescriber?.prescriberlastname }},&nbsp;
                    {{ rxInfo?.Prescriber?.prescriberfirstname }}
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col inline-flex">
                <label>Drug</label>:
                <div class="label--data">
                    &nbsp;{{ rxInfo?.Drug?.drugname }}
                    {{ rxInfo?.Drug?.strength}}
                    {{ rxInfo?.Drug?.drugformname}}
                </div>
            </div>
        </div>
        <label>Are you sure you want to delete?</label>
        <!-- <p *ngIf="controlRx">Once deleted Control Rx cannot be recalled , Are you sure?</p> -->
        <!-- <p *ngIf="!controlRx">
      Are you sure you want to delete Rx?
    </p> -->
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="y"
            (click)="deleteRx()"
        >
            <b>Y</b> YES
        </button>
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="n"
            (click)="closeModalSM()"
        >
            <b>N</b> NO
        </button>
    </div>
</ng-template>

<!-- Unbilled Rx Error-->
<ng-template #UnbilledRxError let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div >Rx: 
            <label class="label--data">{{ rxInfo?.Prescription?.PrescNum}}-{{rxInfo?.PrescReFill?.ReFillNum }}<span *ngIf="_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)">-{{rxInfo?.PrescReFill?.PartialFillNo }}
            </span>
           </label>
        </div>
         <p>
            {{(isTransferType && isTransferType === 'O') ? 'This prescription cannot be deleted because it has been transferred out.' : (checkIsCashRx ? 'Billed Rx(s) for CASH cannot be deleted. If you want to delete it, change the Rx to Unbilled.' : 'Billed Rx(s) or Rx(s) with paid pay records cannot be deleted.')}}
            </p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="o"
            (click)="closeModalSM()"
            (keydown.enter)="closeModalSM()"
        >
            <b>O</b> OK
        </button>
    </div>
</ng-template>

<!-- Delete eRx warning-->
<ng-template #eRxDelete let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">
            Warning!!
        </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="deleteErx(false)">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span> Rx: <label>{{ rxInfo?.Prescription?.PrescNum}}-{{rxInfo?.PrescReFill?.ReFillNum}}<span *ngIf="_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)">-{{rxInfo?.PrescReFill?.PartialFillNo }}
            </span>
            </label> is an Electronic Prescription(eRx).</span>
        <p>
            If you delete this Rx, would you like to restore the eRx Message
            back to "Ready To Process" Queue in the Action List?
        </p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            (click)="checkForOtherCntns(true)"
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="n"
            (click)="checkForOtherCntns(false)"
        >
            <b>N</b> NO
        </button>
    </div>
</ng-template>

<!-- Control Rx delete-->
<ng-template #controlRxDelete let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{controlRxMessgae}}</p>
        <p>Do you wish to Delete?</p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            autofocus
            (click)="checkForERx()"
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="n"
            (click)="closeModalSM()"
        >
            <b>N</b> NO
        </button>
    </div>
</ng-template>

<!-- Control Rx delete-->
<!-- <ng-template #submittedDEA let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Rx: <label>{{rxInfo?.Prescription?.PrescNum}}-{{rxInfo?.PrescReFill?.ReFillNum}}<span *ngIf=_commonServ.checkIsPartialFill(rxInfo?.PrescReFill?.PartialFillNo,rxInfo?.PrescReFill?.PartialIndStatus)>-{{rxInfo?.PrescReFill?.PartialFillNo}}
                </span></label>
            has been already submitted to the state. Do you wish to add to the Re-Submission list with status marked as VOID?
        </p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            (click)="deleteErx(false)"
            InputKey="y"><b>Y</b> YES
        </button>
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey
            InputKey="n"
            (click)="deleteErx(true)"
        >
            <b>N</b> NO
        </button>
    </div>
</ng-template> -->

<!-- Rx Delete Warning-->
<ng-template #RxDeleteWarning let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">
            <div>Warning!!</div>
        </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Rx: <label>{{rxNum}}-{{refillNum}}<span *ngIf=_commonServ.checkIsPartialFill(fillNum)>-{{fillNum}}</span>
            </label> {{deletemsg}}</p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="hotkey_primary"
            appShortcutKey InputKey="o"
            (click)="closeModalSM()"
        >
        <b>O</b> Ok
        </button>
    </div>
</ng-template>
