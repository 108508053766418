<ng-template #TRANSRESULT let-modal>
    <app-custom-modal [hideCross]="true" [hideFooter]="true">
        <h4 header>Transmission Result
        </h4>
        <span body id="contentToPrint">
            <div class="row transmit--block">
                <div class="col-md-10">
                    <app-tr-rx-header-info [transmissionType]="transmissionType" [transmResp]="transmissionData$ | async" [rxInfo]="rxInfo$ | async"></app-tr-rx-header-info>
                </div>
                <div class="col-md-2 padding-0 transmit--btns">
                    <app-tr-rx-ppdi-route [transmResp]="transmissionData$ | async" [rxInfo]="rxInfo$ | async"
                        (PatPrescDrugInsModalType)="patprescDrugInsModalType($event)" [transmissionType]="transmissionType"></app-tr-rx-ppdi-route>
                </div>
            </div>
            <hr class="margin_top_0_5rem margin_bottom_0_5rem"/>
            <div class="col-xs-12 col-md-12 padding-0 transmit--block">
                <div class="row">
                    <div class="col-md-12 col-lg-12 trasmit--rx">
                        <div class="">
                            <label> Transaction Response </label>
                            <div class="label--data">
                                <span
                                    *ngIf="
                                        transmResp?.transmissionDetails?.ResponseStatus === 'P' ||
                                        transmResp?.transmissionDetails?.ResponseStatus === 'C' ||
                                        transmResp?.transmissionDetails?.ResponseStatus === 'D' ||
                                        transmResp?.transmissionDetails?.ResponseStatus === 'S' ||
                                        transmResp?.transmissionDetails?.ResponseStatus === 'A'
                                    "
                                    [ngClass]="
                                        transcRespStatus
                                            ? 'bg--green txt--white'
                                            : null
                                    "
                                    >{{ transcRespStatus }}</span
                                >
                                <span
                                    *ngIf="
                                        transmResp
                                            ?.transmissionDetails
                                            ?.ResponseStatus ===
                                            'R' ||
                                        transmResp
                                            ?.transmissionDetails
                                            ?.ResponseStatus ===
                                            'TRANSMISSION ERROR'
                                    "
                                    [ngClass]="
                                        transcRespStatus
                                            ? 'bg--red txt--white'
                                            : null
                                    "
                                    >{{ transcRespStatus }}</span
                                >
                            </div>
                        </div>
                        <div class="">
                            <label> Authorization </label>
                            <div class="label--data">
                                {{
                                    transmResp?.transmissionDetails
                                        ?.AuthNo
                                }}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label> {{
                                   ( transmResp?.transmissionDetails
                                        ?.VersionInfo  && transmResp?.transmissionDetails
                                        ?.VersionInfo.length ) ? transmResp?.transmissionDetails?.VersionInfo[0] : ""
                                }}
                            </label>:
                            {{
                                ( transmResp?.transmissionDetails
                                     ?.VersionInfo  && transmResp?.transmissionDetails
                                     ?.VersionInfo.length ) ? transmResp?.transmissionDetails?.VersionInfo[1] : ""
                             }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <app-tr-rx-pay-detls [transmResp]="transmissionData$ | async" [transmissionType]="transmissionType"
                        [transmitParams]="transmitParams" [transcRespStatus]="transcRespStatus" [rxCost]="rxCost"></app-tr-rx-pay-detls>
                    </div>
                    <div class="col-md-2 padding-0 transmit--btns">
                        <app-tr-rx-actions [transmResp]="transmissionData$ | async" [transmissionType]="transmissionType"
                        [transmitParams]="transmitParams" [rxInfo]="rxInfo$ | async" (IsTransmsnCmpltd)="splitEmittedEvent($event)" [systemData]="systemData"
                            [isRjctActnEnbld]="isRjctActnEnbld" [isTransmsnPaid]="isTransmsnPaid" [isTransmsnPaidRej]="isTransmsnPaidRej" [rxFG]="(rxFG ? rxFG : rxFG$ | async)" (rejectedReTransmission)="retranmitRejectedScreen($event)"
                            (InitiateNextTrans)="initiateNextTrans($event)"  (InitiateNextTransForSelectedIns)="initiateNextTransforSelectedInsu($event)"  (IsRxDeleted)="isRxDeleted($event)"
                            [transReverseInfo]="transreverseInfo"></app-tr-rx-actions>
                    </div>
                </div>
            </div>
            <app-print-acpt-rej [transmResp]="transmissionData$ | async"  [transcRespStatus]="transcRespStatus"
            [rxInfo]="rxInfo$ | async"  [transmitParams]="transmitParams" [transmissionType]="transmissionType"></app-print-acpt-rej>

            <span *ngIf="transmissionType === 'GroupTransmission' || transmissionType === 'TrnsmtPatent'">
                <div class="eprx--block__content batchrefill grpTransm-inprogress" *ngIf="grpTransRxs?.length > 1">
                    <b>In progress Rxs</b>
                    <ul class="refill-inProgress">
                        <li
                            *ngFor="let trnsRx of grpTransRxs; let i = index"
                            [ngClass]="trnsRx.trim() === (transmResp?.transmissionDetails?.RXNo + '-' + transmResp?.transmissionDetails?.NRefill +
                            ( transmResp?.transmissionDetails?.FillNo ? '-' + transmResp?.transmissionDetails?.FillNo : ''))
                                    ? 'refill-prog--li inProgress--refill'
                                    : 'refill-prog--li notinProgress--refill'"
                        >
                            <i
                                class="far fa-chevron-circle-right"
                                *ngIf="trnsRx === transmResp?.transmissionDetails?.RXNo"
                            ></i>
                            {{ trnsRx }}
                        </li>
                    </ul>
                </div>
            </span>
        </span>
    </app-custom-modal>
</ng-template>
<!--
<app-print-rx-label
    [SkipAutoPrint]="true"
    [RxInfo]="rxInfo"
    [SkipTemplSelct]="'true'"
    [RouteFrom]="routeFrom"
></app-print-rx-label>

<ng-template #TRANSMISSIONCONF let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeConfirmModal()">
        <h4 header>Confirmation</h4>
        <span body>
            Amount paid is LESS than the DRUG COST!, Do you want to continue?
        </span>
        <button
            footer
            ngbAutofocus
            type="submit"
            class="hotkey_primary"
            (click)="saveTransmitClaims()"
            appShortcutKey InputKey="o"
        >
           <b>O</b> OK
        </button>
        <button
            footer
            type="submit"
            class="hotkey_primary"
            (click)="closeConfirmModal()"
            appShortcutKey InputKey="c"
        >
          <b>C</b>  Cancel
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #WARNINGMSGS let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeConfirmModal()">
        <h4 header>Confirmation</h4>
        <span body> {{ warningMsg }} </span>
        <button
            footer
            ngbAutofocus
            type="submit"
            class="hotkey_primary"
            (click)="closeConfirmModal()"
        >
            Ok
        </button>
    </app-custom-modal>
</ng-template>








<ng-template #SWITCHERROR let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header" style="background: red">
        <h4 class="modal-title" id="modal-basic-title">
            Error Message
        </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeSwitchError()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="color: red">
        {{errorMessage}}
    </div>
    <div class="modal-footer">
        <button footer class="hotkey_primary" (click)="closeSwitchError()" appShortcutKey InputKey="o"><b>O</b> OK </button>
    </div>
</ng-template>

<app-override-log
    *ngIf="openOverride"
    [PrivMask]="privType"
    [CatId]="categoryId"
    [OverrideEndpoint]="overrideEndpoint"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>

<app-delete-rx
    *ngIf="transModelName === 'delete'"
    [RxInfo]="rxInfo"
    (IsPopUpClosed)="closeModal($event); closeAndReviewModal('R')"
></app-delete-rx>

<ng-template #transDataStrng let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Transmission Data
        </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="modal.close('Save click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="word-break: break-all; padding: 20px 50px;">
        {{transmResp?.TransStrObject?.TrString}}
    </div>
    <div class="modal-footer">
        <button footer class="hotkey_primary" (click)="modal.close('Save click')" appShortcutKey InputKey="o"><b>O</b> OK </button>
    </div>
</ng-template> -->


