import { MiscInfo, DrugData } from "./../models/edit-drug.model";
import { Drug } from "./../models/patient-Info.model";
import { constant } from "./../app.constants";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable ,  BehaviorSubject } from "rxjs";
import { Vendordruginfo } from "../models";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: "root"
})
export class EditDrugService {
    _insRescData$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    insRescData$: Observable<any> = this._insRescData$.asObservable();

    constructor(private _http: HttpClient, private _commServ: CommonService) {
    }

    getDrugInfo(id: any, isVaccinePopupOpen = false) {
        return this._http.get<DrugData>(
            constant.GET_Drug.replace("{drugId}", "" + id).replace("{isVaccinePopupOpen}", "" + isVaccinePopupOpen)
        );
    }
    updateDrugInfo(drugInfo: any) {
        return this._http.put(constant.PUT_Drug, drugInfo);
    }

    getDispnsHistory(data: any) {
        return this._http.post(constant.GET_Drug_DispenseHistory, data);
    }

    getMiscInfo(drugId: number, buckId?: number) {
        if (!buckId) {
            buckId = +this._commServ.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET");
        }
        return this._http.get<MiscInfo>(
            constant.GET_Drug_MiscInfo.replace("{drugId}/{bucketId}", "" + drugId + "/" + buckId)
        );
    }

    updateMisc(miscInfo: any) {
        return this._http.put(constant.PUT_Drug_MiscInfo, miscInfo);
    }

    addvendorItems(data) {
        return this._http.post(constant.POST_Drug_VendorPrice, data);
    }

    updatevendorItems(data) {
        return this._http.put(constant.PUT_Drug_VendorPrice, data);
    }

    checkVendorExists(data) {
        return this._http.post(constant.POST_Drug_VendorCodeExist, data);
    }
    checkLotExists(data) {
        return this._http.post(constant.POST_Drug_LotExist, data);
    }

    vendorInfoDelete(venodrId: any) {
        return this._http.delete(
            constant.DELETE_Drug_VendorPrice.replace(
                "{vendorPriceId}",
                "" + venodrId
            )
        );
    }

    updateVendorStatus(vendroId: number, status: boolean): Observable<any> {
        return this._http.put<any>(
            constant.PUT_Drug_DiscontinueVendor.replace(
                "{vendorId}",
                "" + vendroId
            ).replace(
                "{status}",
                "" + status
        ), null);
    }

    getVendorInfobyDrugId(id: number): Observable<Vendordruginfo[]> {
        return this._http.get<Vendordruginfo[]>(
            constant.GET_Drug_VendorPrice.replace("{drugId}", "" + id)
        );
    }

    getDrugCodes(drugId: number, lang: boolean) {
        return this._http.get<any>(
            constant.GET_Drug_CounsellingInfo.replace(
                "{drugId}",
                "" + drugId
            ).replace("{lang}", "" + lang)
        );
    }

    updateDrugCoun(counInfo: any) {
        return this._http.put(constant.PUT_Drug_CounsellingInfo, counInfo);
    }

    getInsuranceRestriction(drugId: number, pageNo: number, pageSize: number) {
        this._http
            .get(
                constant.GET_Insurance_Restriction.replace(
                    "{drugId}",
                    "" + drugId
                )
                    .replace("{pagenumber}", "" + pageNo)
                    .replace("{pagesize}", "" + pageSize)
            )
            .subscribe(resp => {
                this._insRescData$.next(resp);
            });
    }

    storeInsrestData(data) {
        this._insRescData$.next(data);
    }

    getInsuranceRestrictionWithoutBS(
        drugId: number,
        pageNo: number,
        pageSize: number
    ) {
        return this._http.get(
            constant.GET_Insurance_Restriction.replace("{drugId}", "" + drugId)
                .replace("{pagenumber}", "" + pageNo)
                .replace("{pagesize}", "" + pageSize)
        );
    }

    getDrugInsurancePopups(
        insuId: number
    ) {
        return this._http.get(
            constant.GET_DrugInsurance_Popups.replace("{insuId}", "" + insuId)
        );
    }

    getBucketPricing(drugId: number, pageNo: number, pageSize: number) {
        return this._http.get(
            constant.GET_Drug_Bucket_Pricing.replace("{drugid}", "" + drugId)
                .replace("{pagenumber}", "" + pageNo)
                .replace("{pagesize}", "" + pageSize)
        );
    }

    addBucketPricing(data: any) {
        return this._http.post(constant.POST_Add_Bucket_Pricing, data);
    }

    deleteBucketPricing(data: any) {
        return this._http.put(constant.DELETE_Drug_Bucket_Pricing, data);
    }

    editBucketPricing(data: any) {
        return this._http.put(constant.PUT_Drug_Bucket_Pricing, data);
    }

    getInsuranceDropDown() {
        return this._http.get(constant.GET_Insurance_Dropdown);
    }

    addInsuRest(val: any) {
        return this._http.post(constant.POST_Insurance_Restriction, val);
    }

    editInsuRest(val: any) {
        return this._http.put(constant.PUT_Insurance_Restriction, val);
    }

    deleteInsuRest(Id: any) {
        return this._http.delete(
            constant.DELETE_Insurance_Restriction.replace(
                "{drugInsuId}",
                "" + Id
            )
        );
    }

    getDrugPriceUpdate(val: any) {
        return this._http.post(constant.POST_Drug_Priceupdate, val);
    }

    getAllDrugCategory() {
        return this._http.get(constant.GET_All_Drug_Category);
    }

    getDrugCategory(drugId: any) {
        return this._http.get(
            constant.GET_Drug_Category.replace("{drugId}", "" + drugId)
        );
    }
    getDrugCategoriesList(drugId: any) {
        return this._http.get(
            constant.GET_Drug_CategoriesList.replace("{drugId}", "" + drugId)
        );
    }
    editDrugCategory(val: any) {
        return this._http.put(constant.PUT_Drug_Category, val);
    }
    addDrugCategory(val: any) {
        return this._http.post(constant.PUT_Drug_Category, val);
    }
    addOrUpdateDrugCategory(val: any) {
        return this._http.post(constant.AddOrUpdate_DrugCategories, val);
    }
    getDrugMmsData(drugInfo: any) {
        return this._http.post(constant.POST_Drug_Mms_Data, drugInfo);
    }

    copyDrug(DrugId: any, copiedId: any) {
        return this._http.get(
            constant.GET_Drug_Copy.replace(
                "{copyFromId}",
                "" + copiedId
            ).replace("{copyToId}", "" + DrugId)
        );
    }

    getNDCOCC(drugType: boolean, drugCode: any, pageNo: any, pageSize: any) {
        return this._http.get(
            constant.GET_Drug_NDC_OCC.replace("{drugtype}", "" + drugType)
                .replace("{drugcode}", "" + drugCode)
                .replace("{pagenumber}", "" + pageNo)
                .replace("{pagesize}", "" + pageSize)
        );
    }

    addNewDrug(val: any) {
        return this._http.post(constant.POST_Drug_New, val);
    }

    getDrugDefaultval() {
        return this._http.get(constant.GET_Drug_Default_values);
    }

    getDrugDefaultvalbyPost(data) {
        return this._http.post(constant.POST_Drug_Default_values,data);
    }


    getSalesSummary(drugId, showEquivalents = false) {
        return this._http.get<any>(
            constant.GET_Drug_Sales_Summary.replace("{drugId}/{ShowEquivalent}", "" + drugId + "/" + showEquivalents)
        );
    }

    checkDuplicateQuickCode(drugId, quickCode) {
        return this._http.get(constant.GET_Check_DuplicateQuickCode.replace("{drugId}", "" + drugId)
                                .replace("{quickcode}", "" + quickCode));
    }

    getDrugCompoundNDC(): Observable<any> {
        return this._http.get<any>(constant.GET_DRUG_GenerateCompNDC);
    }

    AddIngredientDrug(IngData: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_Drug_DrugIngredients,
            IngData
        );
    }

    getInventoryhistory(data) {
        return this._http.post(constant.POST_Inventory_Audit,data);
    }

    getDDIReport(payload: any) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_DDI_REPORT, payload, {
            headers: headers,
            responseType: "blob"
        });
    }

    saveDrugSuggestFiltersAsDefault(data) {
        return this._http.post(constant.POST_DrugSuggestFilterSaving,data);
    }
}
