<div class="exprx--common-block insurance--body erx--body">
    <div class="eprx--block__header large_header">
        <div class="eprx--header__heading">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-md-10">
                        Clinical Info
                    </div>
                    <div class="col-md-2 text-right">
                        <button class="hotkey_success hotkey_primary--Override" title="Add Clinical Info" (keydown.tab)="focusOutFromAdd($event)"
                            autofocus (click)="openClinicalInfo(addclinicalInfo)" appShortcutKey
                            [AltKey]="'a'"><span>A</span>ADD CLINICAL INFO</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="eprx--block__content">
        <wj-flex-grid #Clinical (updatedView)="init(Clinical)" (initialized)="Init(addclinicalInfo,Clinical)"
        (drop)="this._storageService.storeWijmoGridDataInSession(Clinical)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(Clinical)"
            (keydown)="openPopOnWijimo(addclinicalInfo, $event)" [headersVisibility]="'Column'"
            [itemsSource]="patClinicalList" [isReadOnly]="true" [columnPicker]="'patClinicalInfoListWJ'" [selectionMode]="'Row'"
            [allowSorting]="true" [ngClass]="!totalCount ? 'no-data' : ''">
            <wj-flex-grid-column [header]="'Actions'" [width]="'1.2*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                        <i class="far  fa-edit fa-lg actions actions--Highlightfocus--icon" title="Edit" (click)="
                                openeditClinicalInfo(addclinicalInfo, item)"></i>
                        <i class="far fa-trash-alt fa-lg actions danger-actve" title="Delete"
                            (click)="openDeletePopUp(content, item)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                *ngFor="let rxheader of wjHeaders; let i = index" title="{{ rxheader['hName'] }}"
                [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div>
                        {{ item[rxheader['hName']] }}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
        <app-eprx-pagination *ngIf="totalCount > 0" [TotalCount]="totalCount" (OnPageSelected)="setPage($event)"
            (OnSizeSelected)="setSize($event)" [GridName]="'PatClinicalInfoGrid'" [WijmoName]="Clinical"
            (OnFocusOut)="focusToFirst($event)"></app-eprx-pagination>
    </div>
    <ng-template #addclinicalInfo let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title">Clinical Info Measurement Details</h4>
            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true" class="close-button">&times;</span>
            </button>
        </div>
        <div class="modal-body edit-patient">
            <div class="row">
                <div class="col-md-4">
                    <eprx-date-picker [LabelText]="'Measure Date'" [PlaceHolder]="'Measure Date'"
                        [ControlName]="'MeasureDt'" [FormGroupName]="clinicalMeasureFG" [MinDate]="" [MaxDate]="minDate"  [RxSelectId]="'MeasureDate'"
                        [IsDisabled]="false" [AutoFocus]="true" [IsRequired]="true">
                    </eprx-date-picker>

                    <eprx-input [LabelText]="'Measure Time'" [PlaceHolder]="'Measure Time'" [MaxLength]="15"
                        [ControlName]="'MeasureTm'" [Title]="'Measure Time'" [FormGroupName]="clinicalMeasureFG">
                    </eprx-input>
                    <!-- <eprx-input [LabelText]="'Rx Number'" [PlaceHolder]="'Rx Number'" [ControlName]="'PrescId'"
                        [Title]="'Rx Number'" [FormGroupName]="clinicalMeasureFG" [InputType]="'NUMBER'">
                    </eprx-input>
                    <eprx-input [LabelText]="'Refill Number'" [PlaceHolder]="'Refill Number'"
                        [ControlName]="'RefillNum'" [Title]="'Refill Number'" [FormGroupName]="clinicalMeasureFG"
                        [InputType]="'NUMBER'">
                    </eprx-input> -->
                </div>
                <div class="col-md-8">
                    <eprx-select [LabelText]="'Select Dimension'" [PlaceHolder]="'select dimension'"
                    [secondCntrlN]="'Dimension'" [ControlName]="'MedTestId'" [FormGroupName]="clinicalMeasureFG" [HasMultiple]="false"
                    [bindScnCntrlV]="'Remarks'"   [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Id'" [BindLabel2]="'Remarks'"
                        [List]="systemData?.MedTest" [ShowLabel2]="true" [IsTitle]="true"  [IsRequired]="true" (TriggerOnClear)="getUnit()"
                        [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" (TriggerSelectValue)="getUnit()" [Show2Values]="true" [show2ValInInputhidden]="true">
                    </eprx-select>
                    <eprx-select [LabelText]="'Select Unit'" [PlaceHolder]="'select unit'" [ControlName]="'TestUnitId'"
                    [secondCntrlN]="'MeasurementUnit'"   [FormGroupName]="clinicalMeasureFG" [HasMultiple]="false" [BindLabel]="'Id'" [BindLabel2]="'Name'"
                    [bindScnCntrlV]="'Name'"   [BindValue]="'Id'" [LabelForId]="'Name'" [List]="unitValues" [IsRequired]="true"
                        [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [Show2Values]="true" [show2ValInInputhidden]="true">
                    </eprx-select>

                    <eprx-input [LabelText]="'Value'" [PlaceHolder]="'Value'" [MaxLength]="15" [ControlName]="'ObsVal'"
                        [Title]="'Measure Time'" [FormGroupName]="clinicalMeasureFG" [RxSelectId]="'ObsVal'" [InputType]="'NUMBER'"
                        [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid"
                        [ErrorDefs]="{ required: 'Required' }">
                    </eprx-input>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="hotkey_success" (click)="saveclinicalData()" *ngIf="!editMode" appShortcutKey
                [AltKey]="'s'">
                <span>S</span> SAVE
            </button>
            <button type="button" class="hotkey_success" (click)="updateclinicalData()"
                *ngIf="editMode" appShortcutKey [AltKey]="'u'">
                <span>U</span> Update
            </button>
            <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'">
                <span>C</span> CANCEL
            </button>
        </div>
    </ng-template>
</div>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{warningText?.deleteHeaderTxt}}</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label> {{warningText?.deleteConfrmMsg}}</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')" (click)="deleteClinicalInfo()"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" class="hotkey_primary" appShortcutKey InputKey="n" (click)="c('Close click')">
            <b>N</b> NO
        </button>
    </div>
</ng-template>
