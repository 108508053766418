import { Injectable } from "@angular/core";


@Injectable({
    providedIn: "root"
})
export class StatusCommonLoigc {
    constructor() {
    }
    getDeferStatus(rxFG: any) {
        if ((rxFG?.PrescReFill?.Status === 2 || rxFG?.Prescription?.RxStatusId === 2 || rxFG?.RxBill.StatusId === 6)) {
            return true;
        } else
            return false;
    }
    getTransferredInStatus(rxFG) {
        if (rxFG?.PrescReFill?.StatusId === 3 || rxFG?.Prescription?.RxStatusId === 3)
            return true;
        else
            return false;
    }
    getTransferType(rxFG) {
        if (rxFG?.PrescReFill?.StatusId === 3 || rxFG?.Prescription?.RxStatusId === 3)
            return "I";
        else if (rxFG?.PrescReFill?.StatusId === 4 || rxFG?.Prescription?.RxStatusId === 4)
            return "O";
        else
            return null;
    }
    getBilledStatus(rxInfo){
        if(rxInfo?.RxBill?.StatusId === 2 && rxInfo?.PrescReFill?.StatusId !== 4 && rxInfo?.Prescription?.RxStatusId !== 2 && rxInfo?.Prescription?.RxStatusId !== 4 && rxInfo?.PrescReFill?.StatusId !== 2){
            return true;
        }
        else{
            return false;
        }
    }


}
