

        <div class="col-sm-12 padding-0">
            <div class="row">
                <div class="col-md-12">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                {{
                                    prescriberInfo?.Person?.LastName
                                        | uppercase
                                }},&nbsp;
                                {{
                                    prescriberInfo?.Person?.FirstName
                                        | uppercase
                                }}
                            </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row">
                                <div
                                    class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                >
                                    <label> Phone# </label>
                                    <div class="label--data">
                                        {{
                                            prescriberInfo?.Mobil?.xPhone
                                                ? (prescriberInfo?.Mobil.xPhone | mask : "(000)000-0000")
                                                : "--"
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                >
                                    <label> License# </label>
                                    <div class="label--data">
                                        {{
                                            prescriberInfo?.Prescriber
                                                ?.PrescbNum
                                                ? prescriberInfo?.Prescriber
                                                      ?.PrescbNum
                                                : "--"
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                >
                                    <label> DEA# </label>
                                    <div class="label--data">
                                        {{
                                            prescriberInfo?.Prescriber?.DEASuffix
                                                ? (prescriberInfo?.Prescriber?.DEANum+'-'+prescriberInfo?.Prescriber?.DEASuffix) : prescriberInfo?.Prescriber?.DEANum ? prescriberInfo?.Prescriber?.DEANum :"--"
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-lg-4 col-md-4 col-sm-4 col-xs-4 "
                                >
                                    <label> NPI# </label>
                                    <div class="label--data">
                                        {{
                                            prescriberInfo?.Person?.NPINum
                                                ? prescriberInfo?.Person?.NPINum
                                                : "--"
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                >
                                    <label> Address </label>
                                    <div class="label--data">
                                        {{
                                            prescriberInfo?.Address
                                                ?.AddressLine1
                                        }}
                                        <span
                                            *ngIf="
                                                prescriberInfo?.Address
                                                    ?.AddressLine2
                                            "
                                        >
                                            -
                                            {{
                                                prescriberInfo?.Address
                                                    ?.AddressLine2
                                            }}
                                        </span>
                                        <span
                                            *ngIf="
                                                prescriberInfo?.Address?.CityId
                                            "
                                        >
                                            -
                                            {{
                                                prescriberInfo?.Address
                                                    ?.CityName
                                            }}
                                        </span>
                                        <span
                                            *ngIf="
                                                prescriberInfo?.Address?.StateId
                                            "
                                        >
                                            -
                                            {{
                                                prescriberInfo?.Address
                                                    ?.StateName
                                            }}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="col-lg-4 col-md-4 col-sm-4 col-xs-4"
                                >
                                    <label> FAX# </label>
                                    <div class="label--data">
                                        {{
                                            prescriberInfo?.Fax?.xPhone
                                                ? (prescriberInfo?.Fax?.xPhone | mask : "(000)000-0000")
                                                : "--"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="exprx--common-block erx--body">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">Rx History</div>
                        </div>

                        <div class="eprx--block__content">
                            <div class="col-12 pr-0 text-right" *ngIf="totalCount">
                                <button  class="submit print-med-guide background_orange" (click)="toggleColorLegend()">Color Legend</button>
                            </div>
                            <wj-flex-grid
                                #PrescriberHistory
                                [headersVisibility]="'Column'"
                                [itemsSource]="prescHistoryList"
                                [isReadOnly]="true"
                                [selectionMode]="'Row'"
                                [allowSorting]="true"
                                [columnPicker]="'prescHistoryListWJ'"
                                (updatedView)="init(PrescriberHistory)"
                                (keydown.enter)="editEventOnWijimo($event, PrescriberHistory)"
                                [ngClass]="[!totalCount ? 'presc-rx-history-wijmo no-data':'presc-rx-history-wijmo']"
                                [itemFormatter]="itemFormatter"
                                (drop)="this._storageService.storeWijmoGridDataInSession(PrescriberHistory)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(PrescriberHistory)"
                            >
                                <wj-flex-grid-column [header]="'Actions'" [width]="'0.9*'">
                                    <ng-template
                                        wjFlexGridCellTemplate
                                        [cellType]="'Cell'"
                                        let-item="item"
                                    >
                                        <i
                                            class="far  fa-edit  actions "
                                            title="Edit"
                                            (click)="routeToEditRx(item)"
                                        ></i>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column
                                    (initialized)="init(PrescriberHistory)"
                                    [header]="rxheader['hName']"
                                    [binding]="rxheader['hName']"
                                    [visible]="rxheader['isVisible']"
                                    *ngFor="let rxheader of wjHeaders; let i = index"
                                    title="{{ rxheader['hName'] }}"
                                    [width]="rxheader['width']"
                                >
                                    <ng-template
                                        wjFlexGridCellTemplate
                                        [cellType]="'Cell'"
                                        let-item="item"
                                    >
                                        <div *ngIf="rxheader['hName'] === 'Tot. Amt'">
                                                {{ item[rxheader['hName']] | currency
                                                    : "USD"
                                                    : "symbol-narrow"
                                                    : "1.2-2"}}
                                        </div>
                                        <div *ngIf="!(rxheader['hName'] === 'Tot. Amt'  || rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#')">
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                        <div *ngIf = "(rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Rx#')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver']  : item['RxHoverOver']" placement = "top" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"  container="body">
                                            {{ item[rxheader['hName']] }}
                                    </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                            </wj-flex-grid>
                            <app-eprx-pagination *ngIf="totalCount"
                                [TotalCount]="totalCount"
                                (OnPageSelected)="setPage($event)"
                                (OnSizeSelected)="setSize($event)"
                                [GridName]="'PrescriberHistory'"
                                [WijmoName]="flex"
                                (OnFocusOut)="focusToFirst($event)"
                            ></app-eprx-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<app-override-log
    *ngIf="openOverride"
    [PrivMask]="privType"
    [CatId]="categoryId"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>

<ng-template #Redirect let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close')">
        <h4 header>Warning!!</h4>
        <span body>
            The current action will discard the {{routeFrom === "er" ? "Edit Rx" : "Refill Rx"}} transaction.Do you wish
            to continue?
        </span>
        <button footer autofocus class="hotkey_primary" appShortcutKey
            (click)="routeToRx(true);c('close')" InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" appShortcutKey InputKey="n" (click)="c('close')"><b>N</b> NO
        </button>
    </app-custom-modal>
</ng-template>
