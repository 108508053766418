<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Patient')"
    appShortcutKey
    [AltKey]="'x'"
>
    <span>X</span> Pat. Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Prescriber')"
    appShortcutKey
    [AltKey]="'y'"
>
    <span>Y</span> Doc Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Drug')"
    appShortcutKey
    [AltKey]="'z'"
>
    <span>Z</span> Drug Info
</button>
<button
    class="hotkey_success"
    (click)="editPatPrescDrgIns('Insurance')"
    appShortcutKey
    [AltKey]="'i'"
>
    <span class = "padding_left_10px"> I </span> Ins. Info
</button>
<span *ngIf="transmResp?.transmissionDetails?.ResponseStatus === 'R' && showPA && isProrAuth">
    <button type="button" class="pull-right pa-{{paStatusId}}-hotkey_success hotkey_success" [autoClose]="true" placement="bottom" [ngbTooltip]="paStatusHistory?.length ? paStatusToolTip : ''" triggers="mouseover:mouseleave" container="body" tooltipClass="paStatus--tooltip--{{paStatusId}}" appShortcutKey [AltKey]="'o'" (click)="PAVendorId && (paStatusId === 0 || paStatusId === 11) ? sendPARequest(PAVendorId, false) : (PriorApp || cmmResponse || validationErrors) && paStatusId != 11 ? naviagteToPaInfo() : startPADrop.toggle()">
        <span>O</span> 
        <div class="paStatus_name" [ngClass] = "PAVendorName && (paStatusId === 0 || paStatusId === 11) ? 'paStatus_bottom': ''">{{paStatusName}}</div>
        <div class="paVendor_name" *ngIf="PAVendorName && (paStatusId === 0 || paStatusId === 11)">({{PAVendorName}})</div>
</button>
<ng-template #paStatusToolTip>
        <div class="paheading">
            <p class="mb-0">PA Status History</p>
        </div>
        <div class="patooltip-inner_height-scroll">
            <div *ngFor="let status of paStatusHistory;let i = index">
                <h4 class="main-title mb-0 mt-2" *ngIf="status?.paVendor">{{status?.paVendor === 1 ? 'Cover My Meds (CMM)' : status?.paVendor === 2 ? 'SureScripts' : status?.paVendor === 3 ? 'Manual' : '--'}}</h4>
                <span *ngIf="status?.history?.length">
                    <div *ngFor="let history of status?.history;let j = index">
                        <div class="{{i === 0 && j === 0 ? 'status-item-bg-'+history?.paStatusId : ''}}" >
                            <div class="status-item">
                                <div class="status-text paStatus-text-{{history?.paStatusId}}">
                                    <div class="status-title"><span class="status-icon"><i class="{{(history?.paStatusId === 12 || history?.paStatusId === 16)  ? 'fal' : 'fa-kit'}} {{history?.paStatusId === 1 ? 'fa-hourglass-clock' : (history?.paStatusId === 2 || history?.paStatusId === 14) ? 'fa-circle-check' : (history?.paStatusId === 3 ||  history?.paStatusId === 13) ? 'fa-envelope' : (history?.paStatusId === 8 || history?.paStatusId === 15) ? 'fa-circle-xmark' : history?.paStatusId === 6 ? 'fa-ban-sharp' : history?.paStatusId === 7 ? 'fa-circle-exclamation' : history?.paStatusId === 16 ? 'fa-trash' : history?.paStatusId === 9 ? 'fa-regular-cloud-slash' : history?.paStatusId === 11 ? 'fa-regular-list-slash' : history?.paStatusId === 12 ? 'fa-archive' : '--'}}"></i></span>
                                        <span class="status-sub"> {{history?.paStatus}} </span> 
                                    </div>
                                    <div class="status-time">{{history?.dateTime | localTime | date:Date_Time}} <span class="user-action" *ngIf="history?.changedBy && (history?.paStatus === 'Deleted' || history?.paStatus === 'Archived')"> By : {{history?.changedBy}}</span></div>
                                    <div class="status-time" *ngIf="history?.paStatusId === 16 && history?.description">Reason : {{history?.description}}</div>
                                    <div class="divider-line"></div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </span>                
            </div>
        </div> 
</ng-template>
<span ngbDropdown class="view--Trans__icon padding_bottom_6px padding_top_7px background-White view--Trans-{{paStatusId}}" #startPADrop="ngbDropdown" *ngIf="paStatusId === 0 || paStatusId === 11 || paStatusId === 7">
    <button class="btn view-trans-arrow view-Trans-arrow-{{paStatusId}}" id="dropdownBasic3" ngbDropdownToggle container="body"> </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="margin_top_0_3rem">
        <span *ngIf="paStatusId === 0 || paStatusId === 11">
            <span *ngFor="let list of PAVendorsList">
                <a class="pa-{{paStatusId}}-hotkey__buttons enable--hotkey__buttons" appShortcutKey placement="top" ngbTooltip="{{list?.vendorName}}" (click)="sendPARequest(list?.vendorId, false)" container="body">
                    <span class="save--buttons">{{list?.vendorName}} </span>
                </a>
            </span> 
        </span>  
        <span *ngIf="paStatusId === 7">
            <a ngbTooltip="Retry" class="mr-1 pa-{{paStatusId}}-hotkey__buttons enable--hotkey__buttons small_HotKeybuttons padding_bottom_3px" (click)="sendPARequest(PAVendorId, false)" container="body">
                <span class="save--buttons">Retry</span>
            </a>         
        </span>
    </div>
</span>
</span>

