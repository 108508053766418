import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { SystemData } from 'src/app/models';
import { CommonService, PatPreDrugModalService } from 'src/app/services';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';
import { CommonWarnorconfirmPopupComponent } from '../common-warnorconfirm-popup/common-warnorconfirm-popup.component';
import * as _ from "lodash";
import { PatientLabels } from 'src/app/models/labels.models';
import { PatientComponent } from '../../patient/patient.component';
@Component({
  selector: 'app-patient-selection',
  templateUrl: './patient-selection.component.html',
  styleUrls: ['./patient-selection.component.scss']
})
export class PatientSelectionComponent implements OnInit {
  selectedPatientDetails: any;
  patientSearchValue: any = "";
  IsFocusField: string = "";
  systemData: SystemData;
  SearchPatient: boolean = false;
  unsubscribe$: Subject<void> = new Subject();
  primaryInsu: any;
  msgConfig = MsgConfig;
  isfromPatientHistory: boolean = false;
  @Input() IsfrmPatHis;
  @Input() IsTransferType;
  requestedInfor: any;
  @Input()
  set RequestedInfo(requestedInfo : any) {
      if(requestedInfo) {
        this.requestedInfor = requestedInfo;
        this.checkIsActivePatient(requestedInfo, true);
      } else {
        this.requestedInfor = null;
      }
  }
  
  @Input()
  set SelectedPatientDetailsfrmRx(patient : any){
      if(patient) {
        this.isfromPatientHistory = true;
        this.checkIsActivePatient(patient);
      }
  }
  @Input()
  set PatientDetails(patient : any){
      if(patient) {
        this.isfromPatientHistory = true;
        this.checkIsActivePatient(patient);
      }
  }
  @Output() SelectedPatientID = new EventEmitter();
patientLabels = new PatientLabels();
  constructor(private _modalService:NgbModal,
              private _cdr: ChangeDetectorRef,
              private _commonServ:CommonService,
              private _ppdiModalSer: PatPreDrugModalService,
              private _patientUtil: PatientInfoUtil) { 
             
                this._commonServ.systemData$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp) {
                        this.systemData = resp;
                    }
                });
              }

  ngOnInit(): void {
  
    
  }
  checkIsActivePatient(patient, requestedInfo: boolean = false) {
    this.selectedPatientDetails = null;
    if (patient) {
      const patientName = patient.fullname ? ((patient.fullname).toUpperCase()).replace("_", ", ") : "";
      if (!requestedInfo && patient && patient["fullname"] && (patient["actstatus"] !== 1)) {
        const warnMsg = "Selected Patient <label><span class='font-weight-bold'>" + patientName  +"</span></label> is not an Active Patient.";
        this.commonWarnorconfirmPopup({warnMsg: warnMsg, IsHeaderText : MsgConfig.Inactive_Patient, IsFocusField : "patient"});
      } else {
        this._cdr.detectChanges();
        this.selectedPatientDetails = patient;  
        if(requestedInfo)
           this.selectedPatientDetails.requestedInfo = true;
        patient.fullname = patient.fullname ? this._patientUtil.getPatientFullName(patient) : "";
        patient.address = patient ? this._patientUtil.getPatientAddress(patient) : "";
        patient.AllergiesList = (patient?.patallergies?.length) ? this._patientUtil.getPatientAllergies(patient) : "";
        this.IsFocusField = "";
        this._cdr.detectChanges();
        this.IsFocusField = "drug";
        this.primaryInsu = _.filter(this.selectedPatientDetails?.insurance, {
          insuranceid: +this.selectedPatientDetails.insuranceid
        })[0];
          if (!this.primaryInsu) {
            this.primaryInsu = _.filter(this.selectedPatientDetails.insurance, {
                IsCash: true
            })[0];
        }
        if (!this.primaryInsu) {
            this.primaryInsu = _.filter(this.selectedPatientDetails.insurance, {
                insurerid: this.systemData["DefaultValuese"][0]["CashInsurerId"]
            })[0];
        }
        if (!this.primaryInsu) {
            this.primaryInsu = _.filter(this.selectedPatientDetails.insurance, {
                insuranceid: 10189
            })[0];
          } 
      }
      this.patientSearchValue = null;
      this._cdr.detectChanges();
      this.patientSearchValue = true;
      this.SelectedPatientID.emit(patient.patientid);
      this._cdr.detectChanges();
    this.SearchPatient = false;
    this._cdr.detectChanges();
    this.SearchPatient = true;
  
} else {
  this.SearchPatient = true;
    this._cdr.detectChanges();
    this.SearchPatient = false;
    this.primaryInsu = null;
    this.IsFocusField = "patient";
    this.SelectedPatientID.emit();
}
  }
  editPatient(patientid) {
  this._ppdiModalSer._patPreDrugModal$.next("patient");
     const modalRef = this._ppdiModalSer.modalInstanceRef;
     modalRef.componentInstance.patientID  = +patientid;
     modalRef.componentInstance.ClosePatientModal
     .pipe(takeUntil(this.unsubscribe$))
     .subscribe(async resp => {
         modalRef.close();
         if(resp.PatientId)
         {
          const patInfo = await this._commonServ.getRxPatientDetailsById(resp["PatientId"]).toPromise();
          this.checkIsActivePatient(patInfo);
         }
         this._ppdiModalSer._patPreDrugModal$.next(null);
     });
}
  commonWarnorconfirmPopup(object) {
    const modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {
      backdrop: false,
      keyboard: false, windowClass: "large--content"
    });
    modelRef.componentInstance.warnMsg = object.warnMsg;
    modelRef.componentInstance.okButton = true;
    modelRef.componentInstance.IsHeaderText = object.IsHeaderText;
    modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
      if(resp)
      {
        modelRef.close();
        this.IsFocusField = "";
        this._cdr.detectChanges();
        this.IsFocusField = object.IsFocusField;
      }
    });
  }
  ngOnDestroy() {
  }
}
