<app-custom-modal (oncrossBtnClick)="closeBillModal()">
    <h4 header>Select Insurance</h4>
    <span body>
        <div class="eprx--block__content">
            <div *ngIf="!insureList || insureList?.length === 0" class="text-center">
                <h1>There are no Insurances for this patient.</h1>
            </div>
            <span *ngIf="insureList?.length > 0">
                <wj-flex-grid #insurances [headersVisibility]="'Column'" [selectionMode]="'None'"
                    [itemsSource]="insurncListModified" [isReadOnly]="true" [columnPicker]="'insuranceListWJ'">
                    <wj-flex-grid-column [width]="110" [header]="'Select'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <span *ngIf="!inActInsu">
                            <input type="radio" name="SelectedIns" (change)="
                                    selectedInsurence(
                                        $event?.target?.checked,
                                        item?.Insuranceid,
                                        item?.Insuranceid,
                                        item
                                    )
                                " [value]="item.Insuranceid" [checked]="
                                    patInsurList?.length > 1
                                        ? item?.Insuranceid ==
                                          insureList[0]?.insuranceid
                                        : 'true'
                                " [disabled]="isDisabled(item)" [title]="!item['Active Status'] ? 'Insurance is marked as Inactive' : (item['ParentInsuId'] ? getTitle(insureList) : '')"/>
                            </span>
                                <span *ngIf="inActInsu">
                                    <input [id]="item?.Insuranceid" type="radio" name="SelectedIns" (change)="selectedInsurence($event?.target?.checked, item?.Insuranceid, 
                                    item?.Insuranceid,
                                    item)" [title]="item.ParentInsuId ? getTitle(insureList) : ''" 
                                    [value]="item?.Insuranceid" [checked]="isCheckedIns(item)" [disabled]="disabled || item.ParentInsuId" autofocus/>
                                </span>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders;"
                    [width]="rxheader['width']">
                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div>
                                {{ item[rxheader['hName']] }}
                            </div>
                        </ng-template>
                </wj-flex-grid-column>
                </wj-flex-grid>
            </span>
        </div>
    </span>
    <span footer *ngIf="!inActInsu">
    <button ngbAutofocus class="hotkey_primary" (keydown.enter)="closeBillModal(true)"  (click)="closeBillModal(true)" appShortcutKey InputKey="o">
        <b>O</b> OK
    </button>
    <button class="hotkey_primary mr-0" (click)="closeBillModal()" appShortcutKey
        InputKey="c">
        <b>C</b> Cancel
    </button>
    </span>
    <span footer *ngIf="inActInsu">
        <button index="3" ngbAutofocus id="BillToDeflActnOK1" #BillToDeflActnOK1 class="hotkey_primary" appShortcutKey InputKey="o" (click)="closeBillModal(true)"> <b>O</b> Ok</button>
        <button index="4" class="hotkey_primary mr-0" id="BillToDeflActnCancl2" #BillToDeflActnCancl2 appShortcutKey InputKey="c" (click)="closeBillModal()"><b>C</b> Cancel
        </button>
    </span>
</app-custom-modal>

<ng-template #payorPopUp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Please Select Payors</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');closeInsPopUp()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [AutoFocus]="true" [LabelText]="'Filter'" [PlaceHolder]="'Search Payor'"
                    (TriggerChangeValue)="fiterPayor($event)" [HasControl]="false">
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid #InsuList [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="payorWJ"
            [isReadOnly]="true">
            <wj-flex-grid-column header="Select" [width]="57">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span class="text-center">
                        <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                            (click)="selectCode(item,$event)" />
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                *ngFor="let actvHeader of activeHeadersIns; let i = index" [width]="
            actvHeader === 'Notes' ? 300 :
            actvHeader === 'State' ? 65 :
            actvHeader === 'Zip' ? 95 :
            actvHeader === 'Fax' || actvHeader === 'Phone' ? 160 :
             '*'">>
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <span *ngIf="!(actvHeader === 'Phone' || actvHeader ==='Fax')"
                        [title]="(item[actvHeader] | uppercase)">
                        {{ item[actvHeader] }}
                    </span>
                    <span *ngIf="(actvHeader === 'Phone' || actvHeader ==='Fax')">
                        <span *ngIf="actvHeader === 'Phone'">
                            {{item[actvHeader] ? (item[actvHeader] | mask : "(000)000-0000") : ""}}
                        </span>
                        <span *ngIf="actvHeader === 'Fax'">
                            {{item[actvHeader] ? (item[actvHeader] | mask : "(000)000-0000") : ""}}
                        </span>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
        <div class="ml-2 mt-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 row">
            <label>Selected Payors : </label>
            <div class="label--data">
                {{optedPayorList?.length}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="custmAdd" class="hotkey_success" (click)="c('Close click');selectedPayor()"
            appShortcutKey [AltKey]="'s'"><span>S</span>Select/unselect Payors</button>
        <button type="button" class="hotkey_success" (click)="c('Close click');closeInsPopUp()" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>
