import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    ViewChild,
    Renderer2,
    ElementRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EditPrescriberService, CommonService, AlertService, ControlFileService, ReportsService, SecurityManagerService, NotesLogService } from "../../services";
import { PrescTabEnum, PrescHeaderTitles, PrescTabTitles } from "./prescriber.constants";
import { PrescriberUtil, NewAuditUtils, NRxUtils } from "src/app/utils";
import { FormGroup } from "@angular/forms";
import { PrescriberStore } from "src/app/store";
import { Observable, Subject } from "rxjs";
import { MsgConfig } from "src/app";
import { ErxVersions, PrescAuditModel } from "src/app/models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrintErxService } from "src/app/services/print-erx.service";
import { CntrlFileResubComponent } from "../shared";
import { takeUntil } from "rxjs/operators";
import { ControlFileUpdateModel } from "src/app/models/control-file.model";

@Component({
    selector: "app-prescriber",
    templateUrl: "./prescriber.component.html"
})
export class PrescriberComponent implements OnInit {
    tabEnum: any;
    currTab: string = "PRESCRIBERINFO";
    headerData: any;

    @Input() prescriberID: any;
    @Input() addPrescriberFG: FormGroup;
    @Input() IsFromGlobalSearch:any
    @Input() openMMS: boolean;
    popUpType: string;
    prescSelFrmRef: boolean;
    resetToFirst = false;
    warnId: any;
    isNPI: boolean;
    oldPrescriberNPI: void;
    isValueChanged: boolean = false;
    ControlFileUpdatedValues: ControlFileUpdateModel = new ControlFileUpdateModel();
    @Input()
    set DefPrescrName(dpn: any) {
        if (dpn) {
         this._prescUtils.patchErxValues(dpn, this.prescriberFG);
        }
    }

    @Input() IsFromErx: boolean;

    @Input() FocusOnDea: boolean;

    @Output()
    ClosePrescriberModal = new EventEmitter<any>();

    @Output()
    IsAddNewPrescriberCompleted = new EventEmitter<any>();

    @ViewChild("warningInfo", { static: true })
    warningInfo: ElementRef;

    TabIds: any;
    systemData$: any;
    prescriberFG: any;
    prescbInfo$: Observable<any>;
    originalData: any;
    fGInvalid: boolean;
    warningMsg: string;
    isFromEditRx: boolean;
    editedRxPrescReFillId: any;
    prescdropValues: any;
    facilitiesList: any[] = [];
    PrescNPIRequired : boolean = true;
    unsubscribe$ : Subject<void> = new Subject();
    constructor(private _commonService: CommonService,
        private _alertService: AlertService,
        private _securityService: SecurityManagerService,
        private _prescUtils: PrescriberUtil,
        private _prescStore: PrescriberStore,
        private _newAuditUtil: NewAuditUtils,
        private _renderer: Renderer2,
        private _erxServ: PrintErxService,
        private _modalSvc: NgbModal,
        private _nrxUtls: NRxUtils, private _controlFileSvc: ControlFileService,
        private _prescService: EditPrescriberService,
        private _notesLogSvc: NotesLogService,
        private _reportsServ: ReportsService) {
        this.tabEnum = PrescTabEnum;
        this.headerData = PrescHeaderTitles;
        this.TabIds = PrescTabTitles;
        this.systemData$ = this._commonService.systemData$;
        this._prescService.getPrescriberDropDown().pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp) {
                this.prescdropValues = resp;
            }
        });
        if (!this._commonService.faciltityDropdownList$["source"]["value"]?.length) {
            this._commonService.getCommonDropDownValues({IsFacility : true , IsPatientCategory: false , IsPayor: false , IsInsuranceList: false}, true).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                if (resp && resp["Facilities"] && resp["Facilities"].length) {
                    this.facilitiesList = resp["Facilities"];
                }
              });
        } else {
            const resp1 = this._commonService.faciltityDropdownList$["source"]["value"];
            if (resp1?.length) {
                this.facilitiesList = resp1;
            }
        }
    }

    ngOnInit() {
        this.isNPI = this.openMMS && this.addPrescriberFG && this.addPrescriberFG.value.Person &&
                         this.addPrescriberFG.value.Person.NPINum ? true : false;
        this.prescriberFG = (this.addPrescriberFG && !this.prescriberID) ? this.addPrescriberFG : this._prescUtils.generatePrescriberFG() ;
        if (this.prescriberID) {
            this.getPrescriberInfo();
        } else {
            this._prescUtils.patchNewPrescInfo(this.prescriberFG, this.IsFromErx);
            this._prescStore.storePrescriberData(Object.assign({}, this.prescriberFG.value));
            this.prescbInfo$ = this._prescStore.prescriberInfo$;
            this.currTab = "PRESCRIBERINFO";
            this.prescriberFG.markAsPristine();
            this.isValueChanged = false;
            this.prescriberFG.valueChanges.subscribe(value => {
            if (this.prescriberFG.dirty) {
                this.isValueChanged = true;
            } else {
                this.isValueChanged = false;
            }
        });
        }
    }

    selectedFrmRef() {
        this.prescSelFrmRef = true;
    }

    async getPrescriberInfo(type?: any, selectTab?: boolean) {
        this.originalData = await this._prescUtils.getPrescriberInfo(this.prescriberID);
        this.originalData.Prescriber['DEASuffix'] = (this.originalData.Prescriber.DEANum && this.originalData.Prescriber.DEANum.length > 10) ? (this.originalData.Prescriber.DEANum.substr(9, 5)) : '';
        this._prescStore.storePrescriberData(Object.assign({}, this.originalData));
        this.prescbInfo$ = this._prescStore.prescriberInfo$;
        this.oldPrescriberNPI = this.originalData.Person['NPINum'];
        this._prescUtils.patchPrescInfo(this.prescbInfo$["source"]["value"], this.prescriberFG);
        if (!type) {
            this.currTab = "PRESCRIBERINFO";
        } else if (!selectTab) {
            this.currTab = this.tabEnum[type];
        }
        this.prescriberFG.markAsPristine();
        this.isValueChanged = false;
        this.prescriberFG.valueChanges.subscribe(value => {
            if (this.prescriberFG.dirty) {
                this.isValueChanged = true;
            } else {
                this.isValueChanged = false;
            }
        });
    }

    selectedPrescrTab(tabName: string) {
        this.currTab = this.tabEnum[tabName];
    }

    closeModal(type, Id?: any) {
        const result = {Type: type, prescriberId: Id ? Id : this.prescriberID};
        this.ClosePrescriberModal.emit(result);
    }

    resetPrescTabs(warnId?: any) {
        if (warnId === "PrescNPI") {
            this.savePrescriber();
        } else {
            this.resetToFirst = false;
            setTimeout(() => {
                this.resetToFirst = true;
            }, 50);
            setTimeout(() => {
                if (warnId === 2 && document.getElementById("PrescDeano")) {
                    document.getElementById("PrescDeano").focus();
                } else if (document.getElementById("PrescNPINUM")) {
                    document.getElementById("PrescNPINUM").focus();
                }
            }, 1000);
        }
    }

    CheckPrescTypeNPI(){
        if(this.prescdropValues && this.prescdropValues.PrescriberCat && this.prescdropValues.PrescriberCat.length && this.prescriberFG && this.prescriberFG.value.Prescriber && this.prescriberFG.value.Prescriber.PrescCatId){
           const prescdata=  this.prescdropValues.PrescriberCat.find(ele =>{
                return ele.Id === this.prescriberFG.value.Prescriber.PrescCatId ? this.prescriberFG.value.Prescriber.PrescCatId : false;
            });
            if (prescdata) {
                this.PrescNPIRequired = prescdata.IsDefault ? true : false;
            }
        }
        if(!this.PrescNPIRequired && !(this.prescriberFG?.get('Person.NPINum').value)){
            this.warnId = "PrescNPI";
            this._modalSvc.open(this.warningInfo, {
                    centered: true,
                    keyboard: false, backdrop: false,
                    windowClass: "large--content"
            });
            this.warningMsg  = "Claim cannot be submitted to the insurance for drugs filled by the selected prescriber due to missing NPI#."
        } else {
                this.savePrescriber();
            }
    }

    async savePrescriber(type?: any) {
        if (this.prescriberFG.valid) {
            const prescriberNumber = this.prescriberFG.controls['Prescriber'].get('PrescbNum')?.value; 
            if(prescriberNumber){
                let trimmedValue = prescriberNumber.replace(/^[/-]+|[/-]+$/g, '');  
                this.prescriberFG.controls["Prescriber"].patchValue({PrescbNum : trimmedValue});
            }
            this.fGInvalid = false;
            
            const NPINUM = this.prescriberFG.controls["Person"].value.NPINum;
            const isDuplicate = NPINUM && NPINUM !== " " ? await this._prescUtils.
                checkDuplicatePrescb(this.prescriberFG.value["Person"]["NPINum"], this.prescriberID ? this.prescriberID : 0,
                (this.prescriberFG.value["Prescriber"]["DEANum"] ? this.prescriberFG.value["Prescriber"]["DEANum"] : null)) : 1;
            const deaNum = (this.prescriberFG?.value["Prescriber"]["DEASuffix"] ? this.prescriberFG.value["Prescriber"]["DEANum"] + this.prescriberFG?.value["Prescriber"]["DEASuffix"] : this.prescriberFG.value["Prescriber"]["DEANum"]);
            this.prescriberFG.value["Prescriber"]["DEANum"] = deaNum;
            if (isDuplicate === 1) {
                if (this.prescriberID) {
                    const response = await this._prescUtils.updatePrescriber(this.prescriberFG.value);
                    if (NPINUM != this.oldPrescriberNPI || this.originalData.Prescriber.DEANum != this.prescriberFG.value["Prescriber"]["DEANum"]) {
                        this.ControlFileUpdatedValues.PrescriberId = this.prescriberID;
                        this.ControlFileUpdatedValues.IsForPrescriberDEA = (this.originalData.Prescriber.DEANum != this.prescriberFG.value["Prescriber"]["DEANum"])
                        this.ControlFileUpdatedValues.IsForPrescriberNPI = (this.originalData?.Person?.['NPINum'] != NPINUM);
                        this._securityService.ControlFileUpdateUnresolvedRxsForUser(this.ControlFileUpdatedValues).toPromise();
                    }
                        this.afterUpdatePresc(response);
                } else if (!this.prescriberID) { 
                    const response = await this._prescUtils.addPrescriber(this.prescriberFG.value);
                    if (this._commonService.overridePrivNotes$["source"]["value"] && this._commonService.overridePrivNotes$["source"]["value"].frm == 'Prescriber') {
                        this._notesLogSvc.addNotesLog('Prescriber',response, {Note: [this._commonService.overridePrivNotes$["source"]["value"].Note], NoteCat : null}).subscribe(resp => {
                            if (resp) 
                                this._commonService.storeOverridePrivNotes(null);
                        })
                    }
                    this.afterAddPresb(response, type);
                }
            } else if (isDuplicate === -1 || isDuplicate === 2) {
                this._modalSvc.open(this.warningInfo, {
                    centered: true,
                    keyboard: false, backdrop: false,
                    windowClass: "large--content"
                });
                this.warnId = isDuplicate;
                this.warningMsg = (isDuplicate === 2) ? "Prescriber DEA number is invalid." :
                    "Other Prescriber exists with the same NPI number."; 
            }
        } else {
            this.resetPrescTabs();
           // this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            setTimeout(() => {
                if (document.querySelector("input.ng-invalid")) {
                    const element: any = document.querySelector("input.ng-invalid");
                    (<HTMLInputElement>element).focus();
                }
            }, 1000);
            this.fGInvalid = true;
        }
    }
   
    afterUpdatePresc(response) {
        if (response === 1) {
            const prescDataModel = new PrescAuditModel().prescInfo;
            const dropdownsData = {"Speciality": this.prescdropValues?.Speciality, "prescType": this.prescdropValues?.PrescriberCat, "prescIdType": this.prescdropValues?.PrescIdCat, "facilitiesList": this.facilitiesList};
            this._newAuditUtil.saveAuditChange(
                this.originalData,
                this.prescriberFG.value,
                "Edit Prescriber",
                "Prescriber",
                this.prescriberID,
                prescDataModel,
                false,
                dropdownsData
            );
            this._alertService.success(MsgConfig.UPDATE_SUCCESS);
            this.closeModal("Update");
        } else if (response === -2) {
            this.ifInsResInvalid();
        } else {
            this._alertService.error(MsgConfig.UPDATE_FAIL);
        }
    }

    async afterAddPresb(response, type?: any) {
        if (response > 0) {
            const prescDataModel = new PrescAuditModel().prescInfo;
            const dropdownsData = {"Speciality": this.prescdropValues?.Speciality, "prescType": this.prescdropValues?.PrescriberCat, "prescIdType": this.prescdropValues?.PrescIdCat, "facilitiesList": this.facilitiesList};
            this._newAuditUtil.saveAuditOnAdd(
                this.prescriberFG.value,
                "Add Prescriber",
                "Prescriber",
                response,
                prescDataModel,
                dropdownsData
            );
            this._alertService.success(MsgConfig.SAVE_SUCCESS);
            if (this.prescSelFrmRef) {
                this.prescSelFrmRef = false;
                let eRxVersion = null;
                const versionsetting = await this._commonService.getSetttingValue("ERxSettings", "E-PRESCRIPTION_SCRIPT_VERSION");
                if (versionsetting == 2) {
                    eRxVersion = ErxVersions.NewerVersion;
                } else {
                    eRxVersion = ErxVersions.OldVersion;
                }
                this._erxServ.refreshSpi(this.prescriberFG.value["Person"]["NPINum"], response, eRxVersion).toPromise();
            }
            if (!type) {
                this.closeModal("Add", response);
            } else {
                this.prescriberID = response;
                this.getPrescriberInfo(type);
            }
            if (this.IsFromErx) {
                this.IsAddNewPrescriberCompleted.emit(response);
            }
        } else if (response === -2) {
            this.ifInsResInvalid();
        } else {
            this._alertService.error(MsgConfig.SAVE_FAIL);
        }
    }

    ifInsResInvalid() {
        this._alertService.error("Invalid Insurance Restriction Value. Please enter valid one.", false, "long");
        if (this._renderer.selectRootElement("#InsRest")) {
            this._renderer.selectRootElement("#InsRest").focus();
        }
    }

    onCancel(value) {
        this._commonService.storeOverridePrivNotes(null);
        if(this.isValueChanged){
            this.popUpType = value;
            if (value && value !== "cancel") {
                this.closeModal("Cancel");
            }
        } else {
            this.closeModal("Cancel");
        }
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
