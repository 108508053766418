export enum MessageSettingEnum {
    General = 0,
    RefillDue = 1,
    BirthdayGreeting = 4,
    RxReadyPickup = 2,
    NewRxReceived = 6,
    Subscription = 7,
    MobilePayment = 14
}

export enum QuestionTypeAssessEnum {
    Text = 1,
    DropDown = 2,
    Date = 3,
    YesOrNo = 4
}

export enum IdentifierTypeEnum {
    NPI = 1,
    NABP = 2,
    PATIENT = 3,
    PRESCRIBER = 4
}

export enum FiltersEnum {
    ALLHISTORY = 'All History',
    TODAY = 'Today',
    LASTONEWEEK = 'Last 1 Week',
    LASTONEMONTH = 'Last 1 Month',
    LASTSIXMONTHS = 'Last 6 Months',
    LASTONEYEAR = 'Last 1 Year'
}

export enum ApplicationTypeEnum {
    NotSpecified = 0,
    Client = 1,
    Pharmacy = 2,
    FMRWebsite = 3,
    FMRApp = 4,
    PharmacyOwnerApp = 5,
    MMSGateway = 6,
    PrimeRxCloud = 7,
    MyPrimeRx = 8,
    PrimeRxPay = 9,
}

export enum ParticipantTypeEnum {
    None = 0,
    PHARMACY = 1,
    PATIENT = 2,
}

export interface Message {
    message: string;
    date: string;
    IsRead: Boolean;
        sender: {
            userName: string;
            identifierType: number | string;
        },
        recipient: {
            userName: string;
            identifierType: number | string;
        }
}