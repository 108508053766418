import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { NotesHeaders } from 'src/app/models/labels.models';
import { NotesLogService } from 'src/app/services';

@Component({
  selector: 'app-notes-details',
  templateUrl: './notes-details.component.html',
  styleUrls: ['./notes-details.component.scss']
})
export class NotesDetailsComponent implements OnInit {
msgConfig;
notesLabels: any;
requestedNotesInfo: any;
@Input() MainCategory;
unsubscribe$: Subject<void> = new Subject();
@Input()
set RequestedInfo(infoData: any) {
   if(infoData?.Note){
      this.requestedNotesInfo = infoData.Note;
   }
}
  constructor(private _notesLogSvc: NotesLogService) { 
   this.msgConfig = MsgConfig;
      this.notesLabels = new NotesHeaders();

  }

  ngOnInit(): void {
   if(this.requestedNotesInfo?.NoteCatId)
         this.getNotesSubacatList();
  }
  getNotesSubacatList() {
      const searchParams = {
      SearchText: null,
      SearchBy: null,
      OrderByColumn: null,
      SortDirection: null,
      PageNumber: 1,
      PageSize: 30,
      PrescrefillId: null
  };
  this._notesLogSvc
    .getNotesLog(
        this.MainCategory ? this.MainCategory : 'RxAnnotation',
          0,
      "1973-01-01",
      moment(Date.now()).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN),
      searchParams
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp: any) => {
            if(resp?.NoteCat?.length) {
            const selectedSubCat =   resp?.NoteCat.find((item: any) => {
               return item.Id === this.requestedNotesInfo?.NoteCatId
               });
               this.requestedNotesInfo.NoteCatName = selectedSubCat.Name? selectedSubCat.Name: "";
            }
    });
  }
    

         

}
