import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { MsgConfig } from 'src/app/app.messages';
@Component({
  selector: 'app-common-dscsa-info',
  templateUrl: './common-dscsa-info.component.html'
})
export class CommonDscsaInfoComponent implements OnInit {
  @Output() DrugRunDateAndTime = new EventEmitter<any>();
  @Input() DrugSlctd;
  @Input() FromScreen;
  @Input() PrescrefillId;
  @Input() isFromErx;
  @Input() IsDrugFormBarcode;
  @Input() DSCSAId;
  @Input() RxType;
  @Input() set Onchange(val) {
    this.changes = val;
  }  
  SerializedOn: string;
  barcodeValidation: number;
  unsubscribe$: Subject<void> = new Subject();
  dscsaInfo: any;
  @Output() isDSCSAExist = new EventEmitter<any>();
  DscsaList: any = [];
  changes:boolean;
  constructor( private _commonSvc: CommonService) { }

  ngOnInit(): void {
    if(this.PrescrefillId === 0) {
      this.DSCSAId = this._commonSvc.DecryptData(sessionStorage.getItem('DSCSAId'))
    }
    if(this.IsDrugFormBarcode) this.getBarcodeTokenValidation();
    else if(this.PrescrefillId && this.PrescrefillId!=0) this.getDscsaPrescrefillData();
    else if(this.DSCSAId) this.getDscsaData();
  }

  ngOnChanges() {
    if(this.IsDrugFormBarcode === true && (this.RxType === "er" || this.RxType === "rf" || this.RxType === "erx") && this.changes) this.getBarcodeTokenValidation(); 
    if(this.IsDrugFormBarcode === false && this.DrugSlctd?.IsManualDrugEnter) {
      this.dscsaInfo = null; 
      this.DscsaList = [];
    }
  }  

  getBarcodeTokenValidation() {  
    if(this.DrugSlctd && this.DrugSlctd.ExpirationDate && this.DrugSlctd.GTIN && this.DrugSlctd.drugcode && this.DrugSlctd.LotNum) {
      let request = { LotExpDate : this.DrugSlctd.ExpirationDate, Gtin : this.DrugSlctd.GTIN, NDCNumber : this.DrugSlctd.drugcode, LotNumber :this.DrugSlctd.LotNum,
      SerialNumber :this.DrugSlctd.SerialNumber, DrugId : this.DrugSlctd.id, ndc10: this.DrugSlctd.ndc10 }
      this.changes = false;
      this._commonSvc.getBarcodeTokenValidation(request).pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
          if(result) {
              this.SerializedOn = result.SerializedOn ? moment.utc(result.SerializedOn).local().format(MsgConfig.DATE_FORMAT + ' hh:mm:ss a') : "";
              this.DrugRunDateAndTime.emit(result.SerializedOn)
              this.barcodeValidation = (result.Found && result.Found == true) ? 1 : 2;
              this.dscsaInfo = request;
              sessionStorage.setItem('DSCSAId',this._commonSvc.encryptData(result.DSCSAId));
              if(result.DSCSAId && (this.PrescrefillId || this.PrescrefillId === 0)) this.saveDscsaRx(result.DSCSAId);
          }
      }); 
    }
  }

  saveDscsaRx(DSCSAId) {
    if(this.RxType === "er") this.FromScreen = "Captured from Edit Rx";
    let request = { DSCSAId : DSCSAId, UIScreen:this.FromScreen, PrescrefillId:this.PrescrefillId, CreatedDtTm:moment(new Date()).format() }
    this._commonSvc.saveDscsaRx(request).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
      if(resp) {
        sessionStorage.removeItem('DSCSAId');
        // if(DSCSAId && this.PrescrefillId) this.getDscsaPrescrefillData();
      }
    }); 
  }

  getDscsaPrescrefillData() {
    this._commonSvc.getDscsaPrescrefillData(this.PrescrefillId).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
      if(resp && resp?.DSCSAList?.length) {
        this.DscsaList = resp.DSCSAList;
        this.barcodeValidation = (resp.Found && resp.Found == true) ? 1 : 2;
      }
      this.isDSCSAExist.emit((this.DscsaList[0]?.LotNumber && this.DscsaList[0]?.LotExpDate && this.DscsaList[0]?.Gtin && this.DscsaList[0]?.SerialNumber && this.DscsaList[0]?.NDCNumber) ? true : false)
    });
  }

  getDscsaData() {
    let request = { DSCSAId : this.DSCSAId, UIScreen:this.FromScreen }
    this._commonSvc.getDscsaData(request).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
      if(resp) {
        this.dscsaInfo = resp;
        this.barcodeValidation = (resp.Found && resp.Found == true) ? 1 : 2;
        this.SerializedOn = resp.SerializedOn ? moment.utc(resp.SerializedOn).local().format(MsgConfig.DATE_FORMAT + ' hh:mm:ss a') : "";
        sessionStorage.removeItem('DSCSAId')
      }
    });  
}

getSerializedOnDate(date) {
  return date ? moment.utc(date).local().format(MsgConfig.DATE_FORMAT + ' hh:mm:ss a') : "";
}

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
