import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";

import * as Sentry from "@sentry/browser";
import { environment } from "src/environments/environment";
import { AlertService } from "./alert.service";

Sentry.init({
    dsn: environment.SentryURL,
    environment: environment.SentryEnvironment,
    beforeSend(event) {
        if (event.message && event.message.includes("qztraynetworkissue")) {
            return null; // Ignore error
        }
        return event;
    }
});

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private _alrtServ: AlertService, private zone: NgZone) {}

    handleError(error: any) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/; // error: any
        if (error?.message?.includes("qztraynetworkissue")) {
            return; // Stop further processing
        }
        if (error instanceof HttpErrorResponse) {
            let message;
            if (error instanceof HttpErrorResponse) {
                // Server error
                message = navigator.onLine? error.message
                            : "No Internet Connection";
            } else {
                // Client Error
                message = error["message"] ? error["message"] : error;
            }

            this.zone.run(() => {
                this._alrtServ.error(message);
            });
            // if (environment.SentryURL !== "") {
            //     Sentry.captureException(
            //         Sentry.captureException(error["originalError"] || error)
            //     );
            // }
        } else if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        } else {
            if (environment.SentryURL !== "") {
                Sentry.captureException(
                    Sentry.captureException(error["originalError"] || error)
                );
            } else {
                console.error(error);
            }
        }
    }

    setErrorInSentry(value) {
        if (value?.message?.includes("qztraynetworkissue")) {
            console.warn("Ignored qztraynetworkissue in Sentry:", value);
            return; // Do not send to Sentry
        }
        if (environment.SentryURL !== "") {
            Sentry.captureException(
                Sentry.captureException(value)
            );
        } else {
            console.error(value);
        }
    }
}
