import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AlertService, CommonService } from 'src/app/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrimeEscService } from 'src/app/services/prime-esc.service';
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as wjcCore from "@grapecity/wijmo";
import { ValidateFormFieldsUtils, WijimoUtil } from 'src/app/utils';
import { MsgConfig } from 'src/app';
import { CollectionView } from "@grapecity/wijmo";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaginationModel } from 'src/app/models/elastc-rx.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
    selector: 'app-sign-by-date',
    templateUrl: './sign-by-date.component.html'
})
export class SignByDateComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    today: any;
    InFiltersFrmGrp: FormGroup;
    minDate: any;
    wjHeaders: any;
    transactionHistory: any;
    tarsnHistWJ: wjcCore.CollectionView;
    signatureData: any;
    sigType: any;
    signId: any;
    showPatientSign: boolean;
    rxPatientModel: any;
    RPH: any;
    pageNu: number;
    Size: any;
    totalCount: any;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    @ViewChild("transHistGrid")
    transHistGrid: wjcGrid.FlexGrid;
    constructor(private _alertSrv: AlertService,
        private _modalService: NgbModal,
        private _fb: FormBuilder,
        private _ngZone: NgZone,
        private _primeEscSer: PrimeEscService,
        private _formUtils: ValidateFormFieldsUtils,
        private _commonServ: CommonService, private _wijimoUtils: WijimoUtil,
        public _storageService: StorageService) {
    }

    ngOnInit() {
        this.today = moment().format("MM/DD/YYYY");
        this.createFg();
        this.minDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.searchFilters();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "primeescSignByDateListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }
    createFg() {
        this.InFiltersFrmGrp = this._fb.group({
            fromDate: [this.today],
            toDate: [this.today],
            pageSize: this.paginationModel.pageSize,
            pageNumber: this.paginationModel.pageNumber
        });
    }

    async searchFilters() {
        await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.InFiltersFrmGrp, "fromDate", "toDate", 0);
        if (resp === "DATEISSUE") {
            this._alertSrv.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSrv.error(MsgConfig.INVALID_DATES_ERROR);
        } else if (resp === "VALID") {
            this._primeEscSer.getSignByPatient(this.InFiltersFrmGrp.value).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp && resp.length) {
                    this.transactionHistory = resp;
                    this.totalCount = resp[0].TotalCount;
                    this.generateTransHistWJ();
                    this.isDataExists = true;
                } else {
                    this.totalCount = null;
                    this.isDataExists = false;
                    this.tarsnHistWJ = new wjcCore.CollectionView([]);
                }
            });
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("primeescSignByDateListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("primeescSignByDateListWJ", storedWJ);
    }

    generateTransHistWJ() {
        if (this.transactionHistory && this.transactionHistory.length > 0) {
           const mapDataSet=[];
           this.transactionHistory.map((transaction, i) => {
                    const j = {};
                    j["TRANS NO"] = transaction.Id;
                    j["PATIENT ID"] = transaction.PatientId;
                    j["PATIENT NAME"] = transaction.PatientName;
                    j["Patient Name"] = transaction.PatientName;
                    j["PICKUP DATE"] = transaction.CreatedDtTm ? moment.utc(transaction.CreatedDtTm).local().format("MM/DD/YYYY") : "";
                    j["Pickup Date"] = transaction.CreatedDtTm ? moment.utc(transaction.CreatedDtTm).local().format("MM/DD/YYYY") : "";
                    j["Rxs SIGNED FOR"] = transaction.TranSignatureData;
                    j["Rxs Signed For"] = transaction.TranSignatureData;
                    j["COUNS?"] = transaction.CounselingReq;
                    j["Couns?"] = transaction.CounselingReq;
                    j["RPH"] = transaction["RPH"];
                    // return j;
                    mapDataSet.push(j);
                });
                    this.tarsnHistWJ = new CollectionView(mapDataSet,
                        {
                           sortComparer: (a: any, b: any) => {
                                return moment(a).isValid() && moment(b).isValid()
                                    ? moment(a).diff(moment(b)) : null;
                            }
                        });
        } else {
            this.tarsnHistWJ = new wjcCore.CollectionView([]);
        }
    }

    clearData() {
        this.transactionHistory = [];
        this.InFiltersFrmGrp.reset();
        this.InFiltersFrmGrp.markAsUntouched();
        this.InFiltersFrmGrp.patchValue({
            fromDate: this.today,
            toDate: this.today
        });
        this.setSize(this.paginationModel.pageSize);
        // this.patchDefaultValHeaders();
    }

    openSignModal(item) {
        this._primeEscSer.getPatAckSign(item["TRANS NO"], false).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._commonServ.getRxPatientDetailsById(item["PATIENT ID"]).pipe(takeUntil(this.unsubscribe$)).subscribe(patientResp => {
                        if (resp) {
                            this.rxPatientModel = patientResp;
                            this.signatureData = resp;
                            this.sigType = false;
                            this.RPH = item["RPH"];
                            this.signId = item["TRANS NO"];
                            this._ngZone.run(() => {
                                this.showPatientSign = true;
                            });
                        }
                    });
                }
            });
    }

    focusToFirst(event) {
        if (document.getElementById("clearButton")) {
            event.preventDefault();
            // setTimeout(() => {
                document.getElementById("clearButton").focus();
            // }, 10);
        }
        }

        setPage(page: number) {
            if(page){
              this.pageNu = page;
              this.InFiltersFrmGrp.controls["pageNumber"].patchValue(this.pageNu);
              this.searchFilters();
            }
        }
        
        setSize(size: any) {
          if(size){
            this.Size = size;
            this.InFiltersFrmGrp.controls["pageSize"].patchValue(this.Size);
            this.InFiltersFrmGrp.controls["pageNumber"].patchValue(1);
             this.searchFilters();
          }
        }

        ngOnDestroy() {
            this._storageService.getWijmoGridDataInSession(this.wjHeaders,"primeescSignByDateListWJ")
            this.unsubscribe$.next();
            this.unsubscribe$.complete();
        }
}
