import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PrivMaskGuard } from 'src/app/guards';
import { AccCategoryE, Rx1E } from 'src/app/models';
import { CommonService } from 'src/app/services';
import { CommonStore } from 'src/app/store';
import { CommonUtil } from 'src/app/utils';
import { InterfaceUtils } from 'src/app/utils/interface.util';

@Component({
  selector: 'app-verification-tabs-list',
  templateUrl: './verification-tabs-list.component.html',
  styleUrls: ['./verification-tabs-list.component.scss']
})
export class VerificationTabsListComponent implements OnInit, OnChanges{
  rxInfo: any;
  isPatientHistory: any;
  RxId: any;
  RefillId: any;
  FillId: any;
  rphActv: boolean;
  disableRphTab: boolean;
  PrescId: any;
  dpvActv: boolean;
  rxInfoList: any;
  rxActv: boolean;
  disableDpvTab: boolean;
  RRRChk: any;
  disableRxTab: any;
  activeModal: string;
  openPopUp: boolean;
  eventRph: any;
  frmDPVToRph: boolean;
  noPrivsRph: boolean;
  noPrivsRx: boolean;
  noPrivsDPV: boolean;
  isSysImmediateMode: boolean;
  systemSetting: any;
  @Input()
  set RxID(rxid: any) {
      if (rxid) {
          this.RxId = rxid;
      }
  }
  @Input()
  set RefilID(refid: any) {
          this.RefillId = refid;
  }
  @Input()
  set FillID(fillid: any) {
      if (fillid) {
          this.FillId = fillid;
      }
  }
  @Input()
  set RefillNumId(prescid: any) {
    if (prescid) {
      this.PrescId = prescid;
    }
  }
  @Input()
  set RxInfoList(rxinfolist: any) {
      if (rxinfolist) {
          this.rxInfoList = rxinfolist;
      }
  }
  @Input()
  set RxInfo(rxinfo: any) {
      if (rxinfo) {
          this.rxInfo = rxinfo;
      }
  }
  @Input()
  set IsPatientHistory(patient: any) {
      this.isPatientHistory = patient;
  }
  @Input() IsRxVerification: boolean = false;
  @Input() activeTabId: any;
  @Input() totalRecordsCount: any;
  @Output() closeTabs = new EventEmitter();
  constructor(public _commonServ: CommonService, private _privMaskGuard: PrivMaskGuard, private _cdr: ChangeDetectorRef, private _cmnStore: CommonStore, private _interfaceUtil: InterfaceUtils) { 
    this.systemSetting = this._commonServ.systemSettings$["source"]["value"]
  }

  ngOnInit(): void {
    if(localStorage.getItem("ImmediateMode") && (this._commonServ.getDecryptLocalStorage("ImmediateMode") === "true")) {
      const tenantId = this.systemSetting["TenantSettings"]?.length ? this.systemSetting["TenantSettings"][0].TenantId : 0;
     this._interfaceUtil.setInterfaceMode(tenantId, null);
    }
    if (this.rxInfoList?.length)
          this.rxInfo = this.rxInfoList.find((info: any) => this.PrescId === info.PrescReFill.Id);
    this.conditionsCheck();
    this.isSysImmediateMode = localStorage.getItem("ImmediateMode") && (this._commonServ.getDecryptLocalStorage("ImmediateMode") === "true") ? true : false;
    if (this.isSysImmediateMode && this.activeTabId === "dpvVerfTab") {
      const signlrData = {
        Mode: "I",
        ActiveTab: this.activeTabId === "dpvVerfTab" ? "DPV": null,
        Rx: this.rxInfo ? this.rxInfo?.Prescription?.PrescNum : null,
        Refill: this.rxInfo ? this.rxInfo?.PrescReFill.ReFillNum : null,
        Fill: this.rxInfo ? this.rxInfo.PrescReFill.PartialFillNo : null
      }
      this._cmnStore.storeSignlRDPVInfo(signlrData)
    }
    
  }

  conditionsCheck() {
    this.noPrivsRx = !this._privMaskGuard.canActivate(AccCategoryE["Rx1"], Rx1E["RxVerification"]);
    this.noPrivsRph = !this._privMaskGuard.canActivate(AccCategoryE["Rx1"], Rx1E["PharmacistVerification"]);
    this.noPrivsDPV = !this._privMaskGuard.canActivate(AccCategoryE["Rx1"], Rx1E["DrugPickVerification"]);
    this.disableRxTab = this.noPrivsRx ? true : (!+(this._commonServ.getSetttingValue('WorkFlowSettings', 'Require_Rx_Verification')) && (this.rxInfo?.rph1VerificationStatus ? !((this.rxInfo?.rph1VerificationStatus.trim().toLowerCase()) === 'r') : true));
    this.disableRphTab = (this.activeTabId === "dpvVerfTab" || this.activeTabId === "rxVerfTab") ? (this.noPrivsRph ? true : (!this.rxInfo?.drugPickVerificationStatus || (this.rxInfo?.drugPickVerificationStatus && ((this.rxInfo?.drugPickVerificationStatus.trim().toLowerCase() === 'rv') || (this.rxInfo?.drugPickVerificationStatus.trim().toLowerCase()) === 'nv')))) : false;
    this.disableDpvTab = (this.activeTabId === "dpvVerfTab" || (this.rxInfo?.drugPickVerificationStatus && this.rxInfo?.drugPickVerificationStatus.trim().toLowerCase() === 'p')) ? false : (this.noPrivsDPV  ? true : (+(this._commonServ.getSetttingValue('WorkFlowSettings', 'Require_Rx_Verification')) ? (!this.rxInfo?.rph1VerificationStatus || (this.rxInfo?.rph1VerificationStatus && ((this.rxInfo?.rph1VerificationStatus.trim().toLowerCase() === "nv") || (this.rxInfo?.rph1VerificationStatus.trim().toLowerCase() === "rv")))) : false));
    this.rxActv = (this.activeTabId === "rxVerfTab" && !this.disableRxTab) ? true : false;
    this.rphActv = (this.activeTabId === "rphVerfTab") ? true : false;
    this.dpvActv = (this.activeTabId === "dpvVerfTab") ? true : false;
  }

  ngOnChanges(): void {
    this.conditionsCheck();
  }

  closeModal(_val?) {
    this.closeTabs.emit(true);
    /*if (val) {
      this.disableDpvTab = this.noPrivsDPV ? true : false;
    } else {
      this.closeTabs.emit(true);
    }*/
    
  }
  changeTab(event) {
    if (event) {
      if (typeof event === "object") {
        if (!this._privMaskGuard.canActivate(AccCategoryE["Rx1"], Rx1E["PharmacistVerification"])) {
          this.disableRphTab = this.noPrivsRph ? true : false;
        } else {
          this.eventRph = event;
          this.isPatientHistory = event.IsPatientHistory;
          this.RxId = event.RxID;
          this.RefillId = event.RefilID;
          this.FillId = event.FillID;
          this.IsRxVerification = false;
          if (!sessionStorage.getItem("rph-verify")) {
            this.activeTabId = "dpvVerfTab";
            this.activeModal = null;
            this._cdr.detectChanges();
            this.frmDPVToRph = true;
            this.activeModal = "RphVerification";
            this.openPopUp = true;
          } else {
            this.openRphScreen();
          }
        }
      } else {
        this.closeTabs.emit(true);
        // this.disableRphTab = false;
      }
    }
  }

  routeToScreen(id, event?) {
    if (id === "dpvVerfTab") {
      if (sessionStorage.getItem("drug-pick")) {
         this.openDPvScreen();
      } else {
        this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
      }
    } else if (id === "rphVerfTab") {
      if (event) {
        this.eventRph = event;
        this.isPatientHistory = event.IsPatientHistory;
        this.RxId = event.RxID;
        this.RefillId = event.RefilID;
        this.FillId = event.FillID;
        this.IsRxVerification = false;
        if (sessionStorage.getItem("rph-verify")) {
          this.openRphScreen();
        } else {
          this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
        }
      } else {
        if (sessionStorage.getItem("rph-verify")) {
          this.RxId = this.rxInfo?.Prescription?.PrescNum;
          this.RefillId = this.rxInfo?.PrescReFill?.ReFillNum;
          this.FillId = this.rxInfo?.PrescReFill?.PartialFillNo;
          this.isPatientHistory =this.isPatientHistory;
          this.IsRxVerification =  false;
          this.openRphScreen();
        } else {
          this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
        }
      }
    } else {
      if (sessionStorage.getItem("rx-verify")) {
        this.openRxScreen();
      } else {
        this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
      }
    }
  }

  openDPvScreen() {
    this.openPopUp = false;
    this.activeModal = null;
    if (this.rxInfoList?.length)
        this.rxInfo = this.rxInfoList.find((info: any) => this.PrescId === info.PrescReFill.Id);
    this.dpvActv = true;
    this.rphActv = this.rxActv = false;
    this.activeTabId = "dpvVerfTab";
    this.disableRphTab = (this.activeTabId === "dpvVerfTab") ? (!this.rxInfo?.drugPickVerificationStatus || (this.rxInfo?.drugPickVerificationStatus && ((this.rxInfo?.drugPickVerificationStatus.trim().toLowerCase() === 'rv') || (this.rxInfo?.drugPickVerificationStatus.trim().toLowerCase()) === 'nv'))) : false;
  }

  openRphScreen() {
    this.openPopUp = false;
    this.activeModal = null;
    this.dpvActv = this.rxActv = false;
    this.activeTabId = "rphVerfTab";
    this.rphActv =  true;
    this.disableRphTab = false;
  }

  openRxScreen() {
    this.openPopUp = false;
    this.activeModal = null;
    this.RxId = this.rxInfo?.Prescription?.PrescNum;
    this.RefillId = this.rxInfo?.PrescReFill?.ReFillNum;
    this.FillId = this.rxInfo?.PrescReFill?.PartialFillNo;
    this.isPatientHistory =this.isPatientHistory;
    this.IsRxVerification =  true;
    this.dpvActv = this.rphActv = false;
    this.rxActv =  true;
    this.activeTabId = "rxVerfTab";
  }

  beforeChangetab(nextId: string) {
    if (nextId === "dpvVerfTab") {
        if (!sessionStorage.getItem("drug-pick")) {
          this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
          this.activeModal = null;
          this._cdr.detectChanges();
          this.activeModal = "DrugPickVerification";
          this.openPopUp = true;
        } else {
          this.openDPvScreen();
        }
    } else {
      if (nextId === "rphVerfTab" || nextId === "rxVerfTab") {
        if (nextId === "rphVerfTab") {
            if (!sessionStorage.getItem("rph-verify")) {
              this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
              this.frmDPVToRph = false;
              this.activeModal = null;
              this._cdr.detectChanges();
              this.activeModal = "RphVerification";
              this.openPopUp = true;
            } else {
              this.RxId = this.rxInfo?.Prescription?.PrescNum;
              this.RefillId = this.rxInfo?.PrescReFill?.ReFillNum;
              this.FillId = this.rxInfo?.PrescReFill?.PartialFillNo;
              this.isPatientHistory =this.isPatientHistory;
              this.IsRxVerification =  false;
              this.openRphScreen();
            }
        } else {
            if (!sessionStorage.getItem("rx-verify")) {
              this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
              this.activeModal = null;
              this._cdr.detectChanges();
              this.activeModal = "RxVerification";
              this.openPopUp = true;
            } else {
              this.openRxScreen();
            }
        }
      }
    }
}

storeRxInfo(event) {
  if (typeof event === "object") {
    this.rxInfo = event.rxInfo ? event.rxInfo : event;
    this.RRRChk = event.isRRR ? event.isRRR : "";
    if (this.RRRChk && this.rxInfo) {
      this.commonLogic(event);
    } else {
      this.rxInfo = event.rxInfo ? event.rxInfo : event;
      if (this.rxInfo) {
        this.commonLogic(event);
      }
    }
  } else {
    this.rxInfo = event;
  }
}

commonLogic(event) {
  if (!this.noPrivsRx && (!this.rxInfo.rph1VerificationStatus  || (this.rxInfo.rph1VerificationStatus && this.rxInfo.rph1VerificationStatus.trim().toLowerCase() === "rv")) && (+(this._commonServ.getSetttingValue('WorkFlowSettings', 'Require_Rx_Verification')))) {
    if (!sessionStorage.getItem("rx-verify")) {
      this.activeTabId = (this.dpvActv ? "dpvVerfTab" : (this.rphActv ? "rphVerfTab" : "rxVerfTab"));
      this.activeModal = null;
      this._cdr.detectChanges();
      this.activeModal = "RxVerification";
      this.openPopUp = true;
    } else {
      this.RxId = this.rxInfo?.Prescription?.PrescNum;
      this.RefillId = this.rxInfo?.PrescReFill?.ReFillNum;
      this.FillId = this.rxInfo?.PrescReFill?.PartialFillNo;
      this.isPatientHistory =this.isPatientHistory;
      this.IsRxVerification = true;
      this.activeTabId = "rxVerfTab";
      this.rxActv = true;
      this.disableDpvTab = this.disableRphTab = true;
    }
  } else if (!this.noPrivsDPV && (!this.rxInfo.drugPickVerificationStatus || (this.rxInfo.drugPickVerificationStatus && this.rxInfo.drugPickVerificationStatus.trim().toLowerCase() === "rv"))) {
    this.activeTabId = "dpvVerfTab";
    this.dpvActv = true;
    this.disableRphTab = true;
  } else {
    this.rxInfo = event.rxInfo ? event.rxInfo : event;
  }
}

storeRxDetals(event) {
  this.rxInfo = event;
}
}
