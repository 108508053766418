<div class="exprx--common-block" *ngIf="!OpenDiagPopUp">
    <div class="eprx--block__header large_header">
        <div class="row">
            <div class="col">
                <span class="eprx--header__heading">Diagnosis Details</span>
            </div>
            <div class="col pull-right text-right">
                <button class="hotkey_success hotkey_primary--Override" id="AddDiagButton1" autofocus *ngIf="!frmBasicInfo"
                    (keydown.tab)="focusOutFromAdd($event)" title="Alt+D" (click)="openPopUpModal()" appShortcutKey
                    [AltKey]="'a'"><span>A</span>ADD DIAGNOSIS</button>
                    <button class="hotkey_success hotkey_primary--Override" *ngIf="frmBasicInfo"
                    (keydown.tab)="focusOutFromAdd($event)" id="AddDiagButton2" title="Alt+D" (click)="openPopUpModal()" appShortcutKey
                    [AltKey]="'D'" tabIndex="-1"><span>D</span>ADD DIAGNOSIS</button>
            </div>
        </div>
    </div>
    <div class="eprx--block__content erx--body" *ngIf="frmBasicInfo">
        <div class="box-body table-responsive overflow_y_auto max-height_7rem">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>Code</th>
                        <th>Diagnosis Description</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let diag of this.patientFG.value['PatientDiagnosis']; let i=index">
                       <td>
                            <a (click)="selectItemForDelete(deleteDiagnosisContent, diag.DiagnosisId)">
                                <!-- <i class="far fa-trash-alt danger-actve" aria-hidden="true"></i> -->
                                <i class="far fa-trash-alt fa-lg actions danger-actve" aria-hidden="true"></i>
                            </a>
                        </td>
                        <td>
                            {{diag?.DiagnosisId}}
                        </td>
                        <td>
                            {{diag?.DiagDesc}}
                        </td>
                        <td>
                            ICD10
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="eprx--block__content erx--body" *ngIf="!frmBasicInfo">
        <wj-flex-grid #DiagnosisList [headersVisibility]="'Column'" [selectionMode]="'Row'"
        (drop)="this._storageService.storeWijmoGridDataInSession(DiagnosisList)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(DiagnosisList)"
            [itemsSource]="patientDiagnosisWJ" [isReadOnly]="true" [columnPicker]="'patDiagnosisListWJ'"
            (updatedView)="init1(DiagnosisList)" (keydown.tab)="focusToCancel($event)"
            (keydown.alt.d)="getRowDetails(deleteDiagnosisContent, DiagnosisList, $event)"
            (keydown)="openPopOnWijimo($event)">
            <wj-flex-grid-column [header]="'Action'" [width]="frmBasicInfo ? '0.3*' : '0.4*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <i class="far fa-trash-alt actions danger-actve" title="delete"
                        (click)="getRowDetails(deleteDiagnosisContent, DiagnosisList, $event)"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [width]="rxheader['width']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
            *ngFor="let rxheader of wjHeaders; let i = index">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    {{ item[rxheader['hName']] }}
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
        </wj-flex-grid>
    </div>
</div>

<!-- display diagnosis list -->
<ng-template #diagnosisList let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Diagnosis Details</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeDiagonisModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit--drug">
        <div class="row">
            <div class="col-md-4">
                <eprx-input [LabelText]="'Code'" [RxSelectId]="'Code'" [AutoFocus]="true" [PlaceHolder]="'Search Code'"
                    (TriggerChangeValue)="getCodeFilteredData($event, 'Code')" [HasControl]="false">
                </eprx-input>
            </div>
            <div class="col-md-8">
                <eprx-input [LabelText]="'Diagnosis Description'" [PlaceHolder]="'Search Description'"
                    (TriggerChangeValue)="getCodeFilteredData($event, 'Description')" [HasControl]="false"
                >
                </eprx-input>
            </div>
        </div>
        <wj-flex-grid *ngIf="totalCount > 0" #DiagnosList [headersVisibility]="'Column'" [itemsSource]="diagnosisWJ"
            [isReadOnly]="true" [columnPicker]="'patDiagnosisListWJ'" [selectionMode]="'Row'"
            (initialized)="Init(DiagnosList)" (keydown)="onEnterDiag($event)" (updatedView)="init(DiagnosList)"
            (dblclick)="onRowDblclicked(DiagnosList, $event)" (keydown.ctrl.c)="$event.preventDefault()"
            (keydown.space)="preventSpaceEvent($event);changeDiognasis(DiagnosList.selectedItems[0],null)">
            <wj-flex-grid-column header="Select" [width]="100">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                    <span  class="text-center" *ngIf="!item['AlreadySelected']">
                        <input (focus)="$event.target.blur()" class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                            [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                            (click)="changeDiognasis(item,$event)" />
                        <label class="form-check-label" [for]="item.RowId">
                        </label>
                    </span>
                    <span  class="text-center" *ngIf="item['AlreadySelected']">
                        <i class="far fa-check-circle text-success actions"  aria-hidden="true" title="Already Selected"></i>
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [width]="((rxheader['hName'] === 'Diagnosis Description') ? 744 : rxheader['width'])" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
             *ngFor="let rxheader of wjHeaders; let i = index">
                 <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                     {{ item[rxheader['hName']] }}
                 </ng-template>
             </wj-flex-grid-column>
        </wj-flex-grid>
        <app-eprx-pagination [rxId]="'diagPage'" *ngIf="totalCount > 0" [TotalCount]="totalCount" [PageSize]="pageSize"
            [WijmoName]="diagnosisListWJ" [GridName]="'DiagnosisList'" (OnPageSelected)="setPage($event)"
            (OnSizeSelected)="setPageSize($event)" [FromModal]="true"></app-eprx-pagination>
        <div *ngIf="totalCount === 0" class="eprx--block__footer">
            <div>
                <span></span>
                <i class="fa fa-file"></i><br/>
                No Data Found!
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="Addonpop" class="hotkey_success" title = "Add Diagnosis" (click)="addSelectdItems()" appShortcutKey
            [AltKey]="'d'"><span title="-D">D</span> Add Diagnosis</button>
        <button type="button" (keydown.tab)="focusToFirst($event)" id="buttononpop" title = "Close" class="hotkey_success"
            (click)="closeDiagonisModal()" appShortcutKey [AltKey]="'c'"><span title="-C">C</span> Close</button>
    </div>
</ng-template>

<!-- delete diagnosis -->
<ng-template #deleteDiagnosisContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Are you sure you want to delete the selected diagnosis?</p>
    </div>
    <div class="modal-footer">
        <button autofocus type="button" class="hotkey_primary" (click)="deleteDiagnosis();c('Close click')" appShortcutKey
            InputKey="y"><b>Y</b> YES</button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey
            InputKey="n"><b>N</b>NO</button>
    </div>
</ng-template>

<!-- Diagnosis Exist warning modal -->
<ng-template #DiagnosisExist let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Diagnosis already exists. Please select another one.
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="d('Cross click')" appShortcutKey InputKey="o"><b>O</b>
            OK</button>
    </div>
</ng-template>
