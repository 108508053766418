import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MsgConfig } from 'src/app/app.messages';
import { PharmacyLabels } from 'src/app/models/labels.models';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';

@Component({
  selector: 'app-pharmacy-selection',
  templateUrl: './pharmacy-selection.component.html',
  styleUrls: ['./pharmacy-selection.component.scss']
})
export class PharmacySelectionComponent implements OnInit {
@Input() isfromPatientHistory:boolean;
@Input() HeaderTitle:string;
sourcepharmacyInfo: any;
isRequestedInfo: boolean = false;
resetThePhramacy = false;
@Input()
set RequestedInfo(pharmacy : any){
  if(pharmacy?.PrescriptionData?.PrescriptionData?.PharmacyDetail?.SourcePharmacy)
   {
    this.isRequestedInfo = true;
    this.sourcepharmacyInfo = pharmacy.PrescriptionData.PrescriptionData.PharmacyDetail.SourcePharmacy;
     this.selectedpharmDetails(true);
   } else {
    this.isRequestedInfo = false;
   }
}
selectedpharmacyDetails: any;
IsFocusField: any;
rxTransferFG: FormGroup;
pharmacyabels = new PharmacyLabels();
public citySelected = null;
public stateSelected = null;
modalRef: any;
@Output() selectedPharm = new EventEmitter();
pharmacySearchValue: any = "";
addPharFG: FormGroup;
msgConfig = MsgConfig;
addPopUp = false;
isedit = false;
newPharId: any;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _patientUtil: PatientInfoUtil
  ) {
   }

  ngOnInit(): void {
    
  }
  selectedPharmDetails(pharmacy) {
    if(pharmacy)
    {
      this.selectedpharmacyDetails = pharmacy;
      this.selectedpharmacyDetails.addresses  = this._patientUtil.getPatientAddress({
        address1: this.selectedpharmacyDetails?.AddressLine1,
        address2:this.selectedpharmacyDetails?.AddressLine2,
        city: this.selectedpharmacyDetails?.CityName,
        state: this.selectedpharmacyDetails?.StateName,
        zipcode: this.selectedpharmacyDetails?.ZipCode,
      });
    } else  {
      this.selectedpharmacyDetails = null;
    }
      this.selectedPharm.emit(pharmacy);
  }

  openPharmacyEdit() {
      this.isedit = false;
      this._cdr.detectChanges();
      this.isedit = true;
  }
  selectedpharmDetails(serviceLevels?) {
    if(this.sourcepharmacyInfo)
    {
      let phFax: any = "", phPone: any = "";
      if(this.sourcepharmacyInfo.PhoneNumbers?.length) {
        // to find the Fax number
        phFax =  this.sourcepharmacyInfo.PhoneNumbers.find((item: any) => { return item.Type === 'Fax' });
           // to find the Phone 1 number
        phPone =  this.sourcepharmacyInfo.PhoneNumbers.find((item: any) => { return item.Type === 'Primary' });
      }
      this.selectedpharmacyDetails = {
        ServiceLevels : serviceLevels,
        PharmName : this.sourcepharmacyInfo.BusinessName,
        NPINum  : this.sourcepharmacyInfo.Npi, 
        Fax  : phFax? phFax.Number :  null, 
        Phone  :  phPone ? phPone.Number : null, 
        addresses : this._patientUtil.getPatientAddress({
          address1: this.sourcepharmacyInfo?.Address?.AddressLine1,
          address2:this.sourcepharmacyInfo?.Address?.AddressLine2,
          city: this.sourcepharmacyInfo?.Address?.City,
          state: this.sourcepharmacyInfo?.Address?.State,
          zipcode: this.sourcepharmacyInfo?.Address?.ZipCode,
        }),
      }
    }
  }

  reselectPharmacy() {
    this.resetThePhramacy = true;
  }


}
