

<div class="exprx--common-block">
    <div class="eprx--block__header back-dark-color">
        <div class="eprx--header__heading">
            {{ isPartialFillRx ? "Fill Details For Rf#" : "Previous Refills for Rx#"}} {{ PRESCRIPTION?.value["PrescNum"] }}
        </div>
    </div>
    <div class="eprx--block__content" *ngIf="rxType === 'er' ? !isPartialFillRx : true">
        <wj-flex-grid #prevRef [headersVisibility]="'Column'"    [itemsSource]="PrevRefWJ" [isReadOnly]="true"    [columnPicker]="'prevoiusRefillsWJ'" [visible]="true"  [ngClass]="[!hasPrevRef ? 'no-data':'']"
            [selectionMode]="'None'" class="max-height_8rem mt-1">
            <wj-flex-grid-column
                *ngFor="let rxheader of actvHeaders1; let i = index"  [header]="rxheader['hName']"  [width]="rxheader['width']"  [visible]="true"
               >
               <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                <div title = {{item[rxheader.hName]}}>
                    {{ item[rxheader['hName']] }}
                </div>
            </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!hasPrevRef" [IsPageFrm]="'prevoiusRefills'"></app-no-data-found>
        </wj-flex-grid>
    </div>
    <div class="eprx--block__content edit--drug erx--body" *ngIf="rxType === 'er' && isPartialFillRx">
        <wj-flex-grid #prevFills [headersVisibility]="'Column'" [itemsSource]="fillsListWJ" [isReadOnly]="true"
            [selectionMode]="'None'" class="max-height_8rem mt-1"       [columnPicker]="'prevoiusFillsWJ'">
            <wj-flex-grid-column [header]="'Action'" [width]="'1*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <i class="far fa-edit actions" title="Edit" (click)="closeAndReopenEditRx(item)" *ngIf="item?.PrescRfId !== rxFG?.value?.PrescReFill?.Id"></i>
                    <i class="far fa-edit actions in-active" title="Edit" *ngIf="item?.PrescRfId === rxFG?.value?.PrescReFill?.Id"></i>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column [header]="rxheader['hName']" [visible]="true"
                *ngFor="let rxheader of actvHeaders2; let i = index" [width]="rxheader['width']">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                    <div title = {{item[rxheader.hName]}}>
                        {{ item[rxheader['hName']] }}
                    </div>
                </ng-template>
            </wj-flex-grid-column>
            <app-no-data-found *ngIf="!hasPrevFills" [IsPageFrm]="'prevoiusRefills'"></app-no-data-found>
        </wj-flex-grid>
    </div>
    </div>
