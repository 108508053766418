import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { constant } from "../app.constants";
import { CommonStore } from "../store";
import { CommonService } from "./common.service";
import { StorageService } from "./storage.service";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
};

@Injectable()
export class LabelDesignService {

    constructor(private _http: HttpClient, private _cmnstr: CommonStore, private _cmnSvc: CommonService, private _storageSvc: StorageService) { }

    getLabels(): Observable<any> {
        return this._http.get<any>(constant.GET_Labels);
    }

    getLabelsData(data) {
        return this._http.post<any>(constant.GET_Labels,data);
    }
    getTemplateAndRxNumber(addHdrs?:boolean) {
        if (addHdrs) {
            return this._http.get<any>(constant.GET_TempAndRx, reqOptns);
        } else {
            return this._http.get<any>(constant.GET_TempAndRx);
        }
        
    }

    postDefaultReturnToStock(data) {
        return this._http.post<any>(constant.POST_LabelDesigner_DefaultReturnToStock.replace(
                "{TemplateId}",
                data
            ),null);
    }

    getLabelTemplateById(templateId) {
        return this._http.get<any>(constant.GET_TemplateById
            .replace("{templateId}", templateId + "")
        );
    }
    postTemplet(addTemplate: any): Observable<any> {
        return this._http.post<any>(constant.POST_Template, addTemplate);
    }
    updateTemplet(updateTemp: any) {
        return this._http.put(constant.UPDATE_Template, updateTemp);
    }

    templateDelete(template: any) {
        return this._http.delete(
            constant.DELETE_Template.replace(
                "{TemplateId}",
                "" + template
            )
        );
    }

    preview(prescId: any, PrescNum: any, templateId: any, prescrefillId?: any, printCoun?: any, isShrtCoun?: any, 
        printCummlative?: any, isFrmDpvVerf?: any, DispQty?: any, DrugId?: any, OweQty? : any,iouOrder?: any, dpvForUnbilledSetting? :any) {
        const InterfaceSet = this._cmnstr.interfacePharmConfigSetting$["source"]["value"];
        const configDet = InterfaceSet && InterfaceSet.length ? InterfaceSet[0].configurationDetail : null;
        const actvList = configDet && configDet.sendData && configDet.sendData.length ? configDet.sendData.filter((item: any) => item.active) : null;
        // const ipAddress = this.getIPAddress();
        // console.log(ipAddress, "ipAddress");
        if (this._storageSvc.getLocalStorage("GUIDEyecon") && this._cmnSvc.getDecryptLocalStorage("GUIDEyecon") && actvList?.length) {
            const eyconDet = actvList.find((machnDet: any) => ((machnDet.guid === (this._cmnSvc.getDecryptLocalStorage("GUIDEyecon"))) && machnDet.receiveMode === "I"))
            this._cmnSvc.setEncryptLocalStorage("ImmediateMode", (eyconDet ? "true" : "false"))
        }
        const obj = {id: prescId, prescNum: PrescNum, templateId: templateId,
             isShortMonogramSelected: isShrtCoun ? isShrtCoun : false, printCounsellingsheet: printCoun ? printCoun : false,
             prescrefillId: prescrefillId, printCumlative: printCummlative ? printCummlative : false, IsEyeConEnabled: (actvList && actvList.length ? true : false), MACAddress: null, ReceiveMode: localStorage.getItem("ImmediateMode") && (this._cmnSvc.getDecryptLocalStorage("ImmediateMode")  === "true") ? "I" : "B",
             isFrmDpvVerf : isFrmDpvVerf ? isFrmDpvVerf : false, DispQty : DispQty ? DispQty : null, DrugId : DrugId ? DrugId : null, OweQty : OweQty ? OweQty : null, iouOrder : iouOrder ? iouOrder : null,
             dpvForUnbilledSetting: dpvForUnbilledSetting ? dpvForUnbilledSetting : null}
        return this._http.post<any>(constant.GET_PreviewLabels, obj);
    }
    // getIPAddress() {
    //     let result: any;
    //     fetch(environment.ipAddressUrl)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       result = data.ip;
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching IP address:', error);
    //     });
    //     return result;
    // }
    previewForLabelDocuemnts(prescId: any, PrescNum: any, templateId: any, prescrefillId?: any, printCoun?: any, isShrtCoun?: any, printCummlative?: any) {
        const obj = {id: prescId, prescNum: PrescNum, templateId: templateId,
             isShortMonogramSelected: isShrtCoun ? isShrtCoun : false, printCounsellingsheet: printCoun ? printCoun : false,
             prescrefillId: prescrefillId, printCumlative: printCummlative ? printCummlative : false};
        return this._http.post<any>(constant.GET_Reports_LabelDocuments, obj);
    }
    getCompoundFormPrint(drugId: any, prescRefillId: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        headers = headers.set("isLoadNotReq", "true");
        return this._http.get(constant.GET_Compound_Formulation
            .replace("{drugId}", "" + drugId)
            .replace("{prescRefillId}", "" + prescRefillId),
            { headers: headers, responseType: "blob" }
        );
    }

    getIsCounsellingPrinted(payload): Observable<any> {
        return this._http.post<any>(constant.POST_IsMonogram_Printed, payload);
    }

    getCounsellingReport(payload) {
        let headers = new HttpHeaders();
        headers = headers.set("content-type", "application/json");
        headers = headers.set("Accept", "application/pdf");
        headers = headers.set("isLoadNotReq", "true");
        return this._http.post(constant.POST_Print_Drug_Counselling, payload,{ headers: headers, responseType: "blob" });
    }

    postCounsellingAudit(payload) {
        return this._http.post(constant.POST_Print_Counselling_Audit, payload);
    }

    postPCAutomatLabelCheck(payload) {
        return this._http.post(constant.POST_Automated_PrintCheck, payload);
    }

}
