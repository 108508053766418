import {
    PrescAdds,
    PrescClinic,
    PresClinicalAddress
} from "./../../../models/prescriber-info.model";
import { CommonService, AlertService } from "src/app/services";
import { Address, City, State } from "src/app/models/patient-Info.model";
import { FormGroup, FormControl } from "@angular/forms";
import { FormBuilder } from "@angular/forms";

import {
    Component,
    OnInit,
    ViewChild,
    OnChanges,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef,
    Input,
    EventEmitter,
    Output,
    HostListener
} from "@angular/core";
import { EditPrescriberService } from "../../../services";
import { ActivatedRoute } from "@angular/router";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { AuditLogUtils } from "../../../utils/audit-log.util";
import { MsgConfig } from "../../..";
import { CommonUtil, NRxUtils, PrescriberUtil, WijimoUtil } from "src/app/utils";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { RegEx } from "../../../app.regex";
import { StorageService } from "src/app/services/storage.service";

@Component({
    selector: "app-clinic-address",
    templateUrl: "./clinic-address.component.html"
})
export class ClinicAddressComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    clinicalData: PresClinicalAddress = new PresClinicalAddress();
    prescriberId: number;
    clinicAddressList: CollectionView;
    totalCount: any;
    pageCount: number;
    pageSize = 15;
    pageNumber = 1;
    clinicalFG: FormGroup;
    citiesData: City;
    regex: any;
    citiesList: City[] = [];
    currentItem: any;
    modalRef: any;
    clinicalInfo: any;
    currentAddress: any;
    prescriberInfo: any;
    prescAddress: string;
    currentAddress1: any;
    currentAddress2: any;
    currentState: any;
    currentCity: any;
    currentZip: any;
    zipRegex: RegExp;
    stateID: any;
    clinicObj: PrescClinic = new PrescClinic();
    formGroupInvalid: boolean;
    spiRegex: RegExp;
    citySelected: City;
    stateSelected: State;
    editMode: boolean;
    isPopUp = false;
    hasClinicalInfoData: any;

    @ViewChild("clinicalAddress")
    flex: wjcGrid.FlexGrid;

    @ViewChild("warningData", { static: true })
    warningData: ElementRef;
    route: string;
    showPopUp = true;
    clinicalInfoData: any;
    warningPOPUP: boolean;
    clinicalEdit: boolean;
    exchangePopUP: boolean;
    deletePopUP: boolean;
    wjHeaders: any;
    checkedAddress: any;
    @Input() selectedAddressId :any
    IsPrimaryAddress: any;
    addNewAddress: boolean;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();
    prescriberAddrs: any;
    @Input()
    set PrescriberID(pbi: number) {
        if (pbi) {
            this.prescriberId = pbi;
        }
    }
    @Input()
    rxNumber:any;
    @Input() PrescFG: FormGroup;

    @Input()
    set IsPopUp(ip: boolean) {
        this.isPopUp = ip;
    }
    @Input()
    rxType:any;

    @Input() 
    ClinicalAddresses:any;
    @Output()
    CloseClinicalAddressPopup = new EventEmitter<{}>();

    @Output()
    emitChangedAddress = new EventEmitter<{}>();

    @Output()
    ChangePrescAddress = new EventEmitter<{}>();

    @Output()
    focustoFirst = new EventEmitter<any>();

    @Output() 
    PrescriberPopUpsClosed = new EventEmitter<any>();

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event && event.which === 27 && this.modalRef) { // esc
            this.modalRef.close();
            this.modalRef = null;
            event.preventDefault();
        }
    }

    constructor(
        private _prescService: EditPrescriberService,
        private modalService: NgbModal,
        private _fb: FormBuilder,
        private _cdr: ChangeDetectorRef,
        private _commonServ: CommonService,
        private _auditUtils: AuditLogUtils,
        private _alertService: AlertService,
        private _prescUtil: PrescriberUtil,
        private _wijimoUtils: WijimoUtil,
        private _nrxUtls: NRxUtils,
        private _commonUtils: CommonUtil,
        public _storageService: StorageService
    ) {
        this.regex = RegEx;
        this.spiRegex = /^\d{13}$/;
        this.createFG();
    }

    ngOnInit() {
        this.route = window.location.href;
        this.prescriberInfo = this.PrescFG && this.PrescFG.value  ? this.PrescFG.value : null;
        this.getInfo();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "prescClinicAddListWJ" || w["WijmoKey"] === "prescClinicAddListPopupWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        this.checkedAddress = this.selectedAddressId ? this.selectedAddressId : this.prescriberInfo.primeAddressId;        
    }

    ngOnChanges(changes: any) {
    }

    getPrescInfo() {
        this._commonServ
            .getPrescriberData(this.prescriberId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                this.PrescFG.patchValue({
                    Address: resp.Address,
                    PrescAdds: resp.PrescAdds
                });
                (resp.Address) ? this._prescUtil.patchCityAndStateValue(resp.Address, this.PrescFG)
                    : this._prescUtil.makeStateCityNull();
                this.prescriberInfo = this.PrescFG.value;
            });
    }

    ngAfterViewInit() {
        this._cdr.detectChanges();
    }

    ngOnDestroy() {
        if (this.isPopUp) {
            this._storageService.getWijmoGridDataInSession(this.wjHeaders,"prescClinicAddListPopupWJ")
        } else {
            this._storageService.getWijmoGridDataInSession(this.wjHeaders,"prescClinicAddListWJ")
        }
        
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    init(flex: wjcGrid.FlexGrid) {
        flex.columns[0].align = "center";
    }

    createFG() {
        this.clinicalFG = this._fb.group({
            PrescriberId: [],
            Address: this._fb.group(new PresClinicalAddress()),
            PrescAdds: this._fb.group(new PrescAdds())
        });
    }

    getInfo() {
        if (this.ClinicalAddresses && this.ClinicalAddresses.length > 0 && !this.addNewAddress){
            this.clinicalInfoData = this.ClinicalAddresses;
            this.addressDisplay(this.clinicalInfoData)
        }else{
            this._prescService
            .getClinicAddressList(this.prescriberId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
            if(resp && resp.length){
                this.clinicalInfoData = resp;
                this.addressDisplay(this.clinicalInfoData)
            }else {
                this.hasClinicalInfoData = false;
            }
            });
        }
    }

    addressDisplay(clinicalInfoData){
        if (clinicalInfoData && clinicalInfoData.length > 0) {
            this.hasClinicalInfoData = true;
            const primeAddId = this.prescriberInfo ? (this.prescriberInfo.primeAddressId ? this.prescriberInfo.primeAddressId : (this.prescriberInfo.Address && this.prescriberInfo.Address.Id ? this.prescriberInfo.Address.Id : null )) : null;
            clinicalInfoData = this._nrxUtls.getPrimaryAddress(clinicalInfoData,primeAddId);
            this.generateList(clinicalInfoData);
        } else {
                this.hasClinicalInfoData = false;
        }
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData(this.isPopUp ? "prescClinicAddListPopupWJ" : "prescClinicAddListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader((this.isPopUp ? "prescClinicAddListPopupWJ" : "prescClinicAddListWJ"), storedWJ);
        
    }

    generateList(data: any) {
        if (data.length) {
            this.isDataExists = true;
            this.clinicAddressList = new CollectionView(
                data.map((prescriber, i) => {
                    const j = {};
                    j["Id"] = prescriber.Id,
                    j["SPI No."] = j["SPI#"] = prescriber.SPINum;
                    j["Address1"] = prescriber.AddressLine1;
                    j["Address2"] = prescriber.AddressLine2;
                    j["State"] = prescriber.StateName;
                    j["City"] = prescriber.CityName;
                    j["ZipCode"] = prescriber.ZipCode;
                    j["CreatedDtTm"] = prescriber.CreatedDtTm;
                    j["CityId"] = prescriber.CityId;
                    j["StateId"] = prescriber.StateId;
                    j["Active"] = prescriber.IsActive;
                    j["Telephone#"] = (prescriber.Telephone && prescriber.Telephone.trim() && prescriber.Telephone !== "###") ? this._commonUtils.getPhoneFormat(prescriber.Telephone) : "";
                    j["Fax#"] = (prescriber.Fax && prescriber.Fax.trim() && prescriber.Fax !== "###") ? this._commonUtils.getPhoneFormat(prescriber.Fax) : "";
                    return j;
                })
            );
        } else {
            this.totalCount = 0;
            this.isDataExists = false;
            this.clinicAddressList = new CollectionView(null)
        }

    }

    openAddClinicPopUp(clinicData) {
        this.addNewAddress = true;
        this.formGroupInvalid = false;
        this.editMode = false;
        this.clinicalFG.reset();
        this.citySelected = null;
        this.stateSelected = null;
        this.clinicalFG.controls["Address"].patchValue({
            IsActive: true
        });
        this.clinicalInfo = null;
        this.clinicalEdit = true;
        this.modalRef = this.modalService.open(clinicData, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
    }

    saveClinicAdd() {
        if (this.clinicalFG.valid) {
            this.clinicalFG.value.PrescriberId = this.prescriberId;
            this.clinicalFG.value.Address.Id = null;
            this.clinicalFG.value.Address.SPINum = this.clinicalFG.value.PrescAdds.SPINum;
            this._prescService
                .saveClinicAdress(this.clinicalFG.value)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    if (resp && resp !== 2 && resp !== 3) {
                        this._alertService.success(MsgConfig.SAVE_SUCCESS);
                        this._auditUtils.getChangedValues(
                            null,
                            this.clinicalFG.value,
                            "Add Clinical Address",
                            "Prescriber",
                            this.prescriberId
                        );
                        if (this.modalRef) {
                            this.modalRef.close();
                        }
                        this.getInfo();
                        this.clinicalEdit = false;
                    } else if (resp && resp === 2) {
                        this._alertService.error("SPI must be unique");
                        if (document.getElementById("ClinicalSPI")) {
                            document.getElementById("ClinicalSPI").focus();
                        }
                    }else if (resp && resp === 3) {
                            this._alertService.error("Address must be unique");
                            if (document.getElementById("AddressLine1")) {
                                document.getElementById("AddressLine1").focus();
                            }
                    } else {
                        this._alertService.error(MsgConfig.SAVE_FAIL);
                    }
                });
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }

    editClinicPopUp(clinicData, val: any) {
        this.formGroupInvalid = false;
        this.editMode = true;
        this.clinicalInfo = this.clinicalFG.value;
        this.clinicalInfo.PrescriberId = this.prescriberId;
        this.clinicalInfo.Address.AddressLine1 = val.Address1;
        this.clinicalInfo.Address.AddressLine2 = val.Address2;
        this.clinicalInfo.Address.ZipCode = val["ZipCode"];
        this.clinicalInfo.PrescAdds.SPINum = val["SPI No."];
        this.clinicalInfo.PrescAdds.Id = val.Id;
        this.clinicalInfo.Address.Id = val.Id;
        this.clinicalInfo.Address.CreatedDtTm = val.CreatedDtTm;
        this.clinicalInfo.Address.IsActive = val.Active;
        this.clinicalInfo.Address.StateId = val.StateId;
        this.clinicalInfo.Address.CityId = val.CityId;
        this.clinicalInfo.Address.StateName = val.State;
        this.clinicalInfo.Address.CityName = val.City;
        this.clinicalInfo.Address.Fax = val["Fax#"];
        this.clinicalInfo.Address.Telephone = val["Telephone#"];
        this.citySelected = new City();
        this.citySelected.Id = val.CityId;
        this.citySelected.Name = val.City;
        this.citySelected.StateId = val.StateId;
        this.stateSelected = new State();
        this.stateSelected.Id = val.StateId;
        this.stateSelected.Name = val.State;
        this.clinicalEdit = true;
        this.modalRef = this.modalService.open(clinicData, {
            centered: true,
            keyboard: false,
            backdrop: false
        });
        this.patchValue();
    }

    patchValue() {
        // tslint:disable-next-line:prefer-const
        for (let key in this.clinicalInfo) {
            if (key) {
                this.clinicalFG.controls[key].patchValue(
                    this.clinicalInfo[key]
                );
            }
        }
    }

    updateClinicAdd() {
        if (this.clinicalFG.valid) {
            this.clinicalFG.value.PrescriberId = this.prescriberId;
            this.clinicalFG.value.Address.SPINum = this.clinicalFG.value.PrescAdds.SPINum;
            //Removed Fax format before update
            if (this.clinicalFG?.value?.Address?.["Fax"]?.includes(")") && this.clinicalFG?.value?.Address?.Fax.trim() && this.clinicalFG.value?.Address?.Fax !== "###") {
                this.clinicalFG.value.Address['Fax'] = this.clinicalFG.value.Address["Fax"].replace("(", "").replace(")", "").replace("-", "");
            }
            //Removed Telephone format before update
            if (this.clinicalFG?.value?.Address?.["Telephone"]?.includes(")")  && this.clinicalFG?.value?.Address?.Telephone.trim() && this.clinicalFG.value?.Address?.Telephone !== "###") {
                this.clinicalFG.value.Address['Telephone'] = this.clinicalFG.value.Address["Telephone"].replace("(", "").replace(")", "").replace("-", "");
            }

            this.ClinicalAddresses = this.ClinicalAddresses?.map(address => {
                if (address.Id === this.clinicalFG.value.Address.Id) {
                  return { ...address, ...this.clinicalFG.value.Address };
                }
                return address;
              });
            this._prescService.updateClincAddress(this.clinicalFG.value).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                    if (resp && resp !== 2) {
                        if(this.prescriberInfo?.Address && this.clinicalFG?.value?.Address && ((this.prescriberInfo?.Address?.Telephone !== this.clinicalFG?.value?.Address?.Telephone) ||(this.prescriberInfo?.Address?.Id == this.clinicalFG?.value?.Address?.Id))) {
                                this.PrescFG.controls.Address.patchValue(this.clinicalFG?.value?.Address);
                        }                      
                        this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                        this._auditUtils.getChangedValues(
                            this.clinicalInfo,
                            this.clinicalFG.value,
                            "Edit Clinical Address",
                            "Prescriber",
                            this.prescriberId
                        );
                        this.getInfo();
                        if (this.modalRef) {
                            this.modalRef.close();
                        }
                    } else if (resp && resp === 2) {
                        this._alertService.error("SPI must be unique");
                        if (document.getElementById("ClinicalSPI")) {
                            document.getElementById("ClinicalSPI").focus();
                        }
                    } else {
                        this._alertService.error(MsgConfig.UPDATE_FAIL);
                    }
                });
        } else {
            this._alertService.error(MsgConfig.MANDATORY_FIELDS);
            this.formGroupInvalid = true;
        }
    }

    exchangeAddressPopup(exchangeInfo, val: any) {
        if (val.Active) {
            this.currentAddress = val;
            this.exchangePopUP = true;
            if(this.PrescFG && this.PrescFG.value && this.PrescFG.value.PrescriberAddresses && this.PrescFG.value.PrescriberAddresses.length){
                this.PrescFG.value.PrescriberAddresses.map(resp=>{
                    if(resp.Id == this.PrescFG.value.primeAddressId){
                        this.prescriberAddrs = resp;
                    }
                    })
            } else {
                this.prescriberAddrs = this.PrescFG.value.Address
            }
            this.modalRef = this.modalService.open(exchangeInfo, {
                centered: true,
                keyboard: false,
                backdrop: false,
                windowClass: "large--content"
            });
            this.currentItem = val["Id"];
        } else {
            this.warningPOPUP = true;
            this.modalRef = this.modalService.open(this.warningData, {
                centered: true,
                keyboard: false,
                backdrop: false,
                windowClass: "large--content"
            });
        }
    }

    setPrimaryClinicAddress() {
        this._prescService
            .setPrimaryClinicAddress(this.prescriberId, this.currentItem)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                    this.getPrescInfo();
                    if (this.isPopUp) {
                        this.CloseClinicalAddressPopup.emit(this.currentItem);
                    }
                } else {
                    this._alertService.error(MsgConfig.UPDATE_FAIL);
                }
            });
    }

    deleteCofirmation(content, item) {
        this.deletePopUP = true;
        this.modalRef = this.modalService.open(content, {
            centered: true,
            keyboard: false,
            backdrop: false,
            windowClass: "large--content"
        });
        this.currentItem = item["Id"];
    }

    deleteClinicalAdd() {
        this._prescService
            .DeleteClinical(this.prescriberId, this.currentItem)
            .subscribe(resp => {
                if (resp) {
                    this._alertService.success(MsgConfig.UPDATE_SUCCESS);
                    this.getInfo();
                } else {
                    this._alertService.error(MsgConfig.DELETE_FAIL);
                }
            });
    }

    clinicStateValue(val: any) {
        this.AddressForm.controls.StateId.patchValue(val.Id);
        this.AddressForm.controls.StateName.patchValue(val.Name);
        this.citySelected = new City();
    }

    optedCityValue(val: any) {
        this.AddressForm.controls.CityId.patchValue(val.Id);
        this.AddressForm.controls.CityName.patchValue(val.Name);
    }

    clinicZipSelected(zipMaster: any) {
        this.citySelected = new City();
        if (zipMaster) {
            this.citySelected.Id = (zipMaster.CityId) ? zipMaster.CityId : "";
            this.citySelected.StateId = zipMaster.StateId ? zipMaster.StateId : "";
            this.citySelected.Name = zipMaster.CityName ? zipMaster.CityName : "";
            this.AddressForm.controls.CityId.patchValue(zipMaster.CityId ? zipMaster.CityId : "");
            this.AddressForm.controls.CityName.patchValue(zipMaster.CityName ? zipMaster.CityName : "");
            this.stateSelected = new State();
            this.stateSelected.Id = zipMaster.StateId ? zipMaster.StateId : "";
            this.stateSelected.Name = zipMaster.StateName ? zipMaster.StateName : "";
            this.AddressForm.controls.StateId.patchValue(zipMaster.StateId ? zipMaster.StateId : "");
            this.AddressForm.controls.StateName.patchValue(zipMaster.StateName ? zipMaster.StateName : "");
        }
    }

    closeClinicalAddressModal() {
        this.CloseClinicalAddressPopup.emit();
        this.PrescriberPopUpsClosed.emit(0);
        const event = {
            addressId: this.checkedAddress,
            prescriberInfo: this.clinicalInfoData
        }
        this.emitChangedAddress.emit(event);
        if(this.rxType == "er" || this.rxType == "rf"){
            this._prescService.changePrescriberAddress(this.rxNumber,this.checkedAddress).toPromise();
        }
      
    }

    focusOutFromAdd(event) {
        if (!this.hasClinicalInfoData) {
            this.focusToFirst(event);
        } else {
            event.preventDefault();
            this.flex.focus();
        }
    }

    focusToFirst(event) {
        if (event) {
            event.preventDefault();
            this.focustoFirst.emit();
        }
    }

    Init(content,grid) {
        grid?.cells.hostElement.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                e.preventDefault()
                this.editClinicPopUp(content, grid.collectionView["_src"][grid.selection.row]);
            }
        });
    }

    // keyEventOnWijimo(content, grid) {
    //     if (this.hasClinicalInfoData) {
    //         event.preventDefault();
    //         this.editClinicPopUp(content, grid.selectedItems[0]);
    //     }
    // }

    openPopOnWijimo(content, event) {
        if (event.ctrlKey) {
            if (event.which === 65) {
                event.preventDefault();
                this.openAddClinicPopUp(content);
            }
        }
    }



    get Address(): FormGroup {
        return this.clinicalFG.get("Address") as FormGroup;
    }

    get PrescAdds(): FormGroup {
        return this.clinicalFG.get("PrescAdds") as FormGroup;
    }

    get SPINum(): FormControl {
        return this.clinicalFG.controls["PrescAdds"].get(
            "SPINum"
        ) as FormControl;
    }

    get ZipCode(): FormControl {
        return this.clinicalFG.controls["Address"].get(
            "ZipCode"
        ) as FormControl;
    }
    get StateId(): FormControl {
        return this.clinicalFG.controls["Address"].get(
            "StateId"
        ) as FormControl;
    }
    get AddressLine1(): FormControl {
        return this.clinicalFG.controls["Address"].get(
            "AddressLine1"
        ) as FormControl;
    }

    get AddressForm(): FormGroup {
        return this.clinicalFG.get("Address") as FormGroup;
    }
    onAddressChange(address,type){
        const result = address.replace(/^\s+/g, '');
        if(type === "Address1"){
            this.Address.controls["AddressLine1"].patchValue(result);
        }else{
            this.Address.controls["AddressLine2"].patchValue(result);
        }

}
changeAddress(isChecked,checkedVal){
if(isChecked){
    this.checkedAddress = checkedVal && checkedVal["Id"] ? checkedVal["Id"] : null;
}
}
selectRadioOnKeyEvents(grid){
    this.changeAddress(
        true,
        grid.selectedItems[0]
    );
}
}
