<ng-template #REFILLDUE let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Refill Scheduler</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"
            (click)="d('Cross click');closePopupForRefillDue()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body px-4 pt-2">
        <div class="col-md-12 padding-0">
            <div class="row exprx--common-block">
                <div class="col-md-12 p-0">
                <div class="row col-md-12 insurance--body pr-0">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 refill-due-report-height--top">
                        <div class="row">
                            <div class="col-md-7">
                                <app-rx-select [LabelText]="'From Due Date'" [PlaceHolder]="''" [ControlName]="'AllForFromDueDate'"
                                    [FormGroupName]="refillDueFG" [HasMultiple]="false" [HasBindLabel2]="false" [LabelForId]="'Name'"
                                    [List]="fromDueDateList" [BindLabel]="'Name'" [BindValue]="'Value'" [LabelForId]="'Name'" (TriggerSelectValue)="setForFromDate($event)">
                                </app-rx-select>
                            </div>
                            <div class="col-md-5" *ngIf="(refillDueFG.controls['AllForFromDueDate'].value=== 'd' ||
                                                        refillDueFG.controls['AllForFromDueDate'].value=== 'f')">
                                <eprx-date-picker [RxSelectId]="'FromDate'" [AutoFocus]="true" [LabelText]="''" [PlaceHolder]="''"
                                    [ControlName]="'refillsDueFromDate'" [FormGroupName]="refillDueFG" [MinDate]="" [MaxDate]=""
                                    [IsDisabled]="refillDueFG.controls['AllForFromDueDate'].value=== 'd'?true:false">
                                </eprx-date-picker>
                            </div>
                            <div class="col-md-5"
                                *ngIf="(refillDueFG.controls['AllForFromDueDate'].value=== 'd+' ||
                                        refillDueFG.controls['AllForFromDueDate'].value=== 'd-')">
                                <div class="row">
                                    <div class="col-md-10">
                                        <eprx-input class="position-relative top_n19px" [LabelText]="'Days'" [PlaceHolder]="'Days'"
                                            [ControlName]="'FromDueDateDays'" [FormGroupName]="refillDueFG" [HasControl]="true"
                                            [RxSelectId]="'FromDueDateDays'" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalidDate"
                                            [ErrorDefs]="{required: 'Required'}"  [MaxLength]="4" [InputErrors]="refillDueFG?.controls?.FromDueDateDays?.errors"
                                             [MarkAsTouched]="refillDueFG?.controls?.FromDueDateDays?.touched">
                                        </eprx-input>
                                    </div>
                                    <div class="col-md-2 pl-0 pt-4">
                                        <i class="far fa-angle-up text-primary fa-2x refillduefar" (click)="navUpForFromDays()"></i>
                                        <i class="far fa-angle-down text-primary fa-2x refillduefar" (click)="navDownForFromDays()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 refill-due-report-height--top">
                        <div class="row">
                            <div class="col-md-7">
                                <app-rx-select [LabelText]="'To Due Date'" [PlaceHolder]="''" [ControlName]="'AllForToDueDate'"
                                    [FormGroupName]="refillDueFG" [HasMultiple]="false" [HasBindLabel2]="false" [LabelForId]="'Name'"
                                    [List]="fromDueDateList" [BindLabel]="'Name'" [BindValue]="'Value'" [LabelForId]="'Name'"
                                    (TriggerSelectValue)="setForDueDate($event)">
                                </app-rx-select>
                            </div>
                            <div class="col-md-5" *ngIf="(refillDueFG.controls['AllForToDueDate'].value=== 'd' ||
                                                        refillDueFG.controls['AllForToDueDate'].value=== 'f')">
                                <eprx-date-picker [RxSelectId]="'refillsDueToDate'" [AutoFocus]="true" [LabelText]="''" [PlaceHolder]="''"
                                    [ControlName]="'refillsDueToDate'" [FormGroupName]="refillDueFG" [MinDate]="" [MaxDate]=""
                                    [IsDisabled]="refillDueFG.controls['AllForToDueDate'].value=== 'd'? true: false">
                                </eprx-date-picker>
                            </div>
                            <div class="col-md-5"
                                *ngIf="(refillDueFG.controls['AllForToDueDate'].value=== 'd+' ||
                                                                                            refillDueFG.controls['AllForToDueDate'].value=== 'd-')">
                                <div class="row">
                                    <div class="col-md-10">
                                        <eprx-input class="position-relative top_n19px" [LabelText]="'Days'" [PlaceHolder]="'Days'"
                                            [ControlName]="'ToDueDateDays'" [FormGroupName]="refillDueFG" [HasControl]="true"
                                            [RxSelectId]="'ToDueDateDays'" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalidDate"
                                            [ErrorDefs]="{required: 'Required'}"  [MaxLength]="4" [InputErrors]="refillDueFG?.controls?.ToDueDateDays?.errors"
                                            [MarkAsTouched]="refillDueFG?.controls?.ToDueDateDays?.touched"
                                            >
                                        </eprx-input>
                                    </div>
                                    <div class="col-md-2 pl-0 pt-4">
                                        <i class="far fa-angle-up text-primary fa-2x refillduefar" (click)="navUpForToDueDays()"></i>
                                        <i class="far fa-angle-down text-primary fa-2x refillduefar" (click)="navDownForToDueDays()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-md-12 insurance--body pr-0">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 refill-due-report-height--top">
                        <div class="row">
                            <div class="col-md-7">
                                <app-rx-select [AutoFocus]="true" [LabelText]="'Rx filled date since'" [PlaceHolder]="''"
                                    [ControlName]="'AllForSinceDate'" [FormGroupName]="refillDueFG" [HasMultiple]="false"
                                    [HasBindLabel2]="false" [LabelForId]="'Name'" [List]="fromDueDateList" [BindLabel]="'Name'"
                                    [BindValue]="'Value'" [LabelForId]="'Name'" (TriggerSelectValue)="setForRefillDate($event)">
                                </app-rx-select>
                            </div>
                            <div class="col-md-5" *ngIf="(refillDueFG.controls['AllForSinceDate'].value=== 'd' ||
                                                                           refillDueFG.controls['AllForSinceDate'].value=== 'f')">
                                <eprx-date-picker [RxSelectId]="'SinceDueDate'" [AutoFocus]="true" [LabelText]="''" [PlaceHolder]="''"
                                    [ControlName]="'RefillFillDate'" [FormGroupName]="refillDueFG" [MinDate]="" [MaxDate]=""
                                    [IsDisabled]="refillDueFG.controls['AllForSinceDate'].value=== 'd'? true : false">
                                </eprx-date-picker>
                            </div>
                            <div class="col-md-5" *ngIf="(refillDueFG.controls['AllForSinceDate'].value=== 'd+' ||
                                                          refillDueFG.controls['AllForSinceDate'].value=== 'd-')">
                                <div class="row">
                                    <div class="col-md-10" >
                                        <eprx-input class="position-relative top_n19px" [LabelText]="'Days'" [PlaceHolder]="'Days'"
                                            [ControlName]="'SinceDateDays'" [FormGroupName]="refillDueFG" [HasControl]="true"
                                            [RxSelectId]="'SinceDateDays'" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalidDate"
                                            [MarkAsTouched]="refillDueFG?.controls?.SinceDateDays?.touched"
                                            [ErrorDefs]="{required: 'Required'}"  [MaxLength]="4" [InputErrors]="refillDueFG?.controls?.SinceDateDays?.errors" >
                                        </eprx-input>
                                    </div>
                                    <div class="col-md-2 pl-0 pt-4">
                                        <i class="far fa-angle-up text-primary fa-2x refillduefar" (click)="navUpForSinceDate()"></i>
                                        <i class="far fa-angle-down text-primary fa-2x refillduefar" (click)="navDownForSinceDate()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                        <eprx-select [RxSelectId]="'Patientcat'" [LabelText]="'Patient Category'"
                            [PlaceHolder]="'Patient Category'" [Title]="'Patient Category'"
                            [ControlName]="'PatientCategory'" [FormGroupName]="refillDueFG" [HasMultiple]="false"
                            [HasBindLabel2]="true" [Show2Values]="true" [BindLabel2]="'Name'" [BindLabel]="'Code'"
                            [BindValue]="'Name'" [LabelForId]="''" [List]="patientCatData">
                        </eprx-select>
                    </div>
                </div>
                    
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                        <eprx-input [RxSelectId]="'Profitfrom'" [LabelText]="'Profit Range From'"
                            [PlaceHolder]="'Profit Range From'" [ControlName]="'Profitfrom'" [FormGroupName]="refillDueFG"
                            [MaskPattern]="'09999.00'" [DropSpclChar]="false" [InputType]="'CURRENCY'">
                        </eprx-input>
                    </div> -->
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                        <eprx-input [RxSelectId]="'Profitto'" [LabelText]="'Profit Range To'"
                            [PlaceHolder]="'Profit Range To'" [ControlName]="'Profitto'" [FormGroupName]="refillDueFG"
                            [MaskPattern]="'09999.00'" [DropSpclChar]="false" [InputType]="'CURRENCY'">
                        </eprx-input>
                    </div> -->
                    
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                        <eprx-select [RxSelectId]="'Drug Type'" [LabelText]="'Drug Type(Blank=ALL)'"
                            [PlaceHolder]="'Drug Type'" [Title]="'Drug Type'" [ControlName]="'drugType'"
                            [FormGroupName]="refillDueFG" [HasMultiple]="true" [HasBindLabel2]="true" [Show2Values]="true"
                            [BindLabel2]="'Remarks'" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="''"
                            [List]="systemData?.DrugType">
                        </eprx-select>
                    </div> -->
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                        <eprx-select [RxSelectId]="'DrugClass'" [LabelText]="'Drug Class'" [PlaceHolder]="'Drug CLass'"
                            [Title]="'Drug Class'" [ControlName]="'drugClass'" [FormGroupName]="refillDueFG"
                            [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Name'" [LabelForId]="''"
                            [List]="[{Name:'All'},{Name:'Class 2'},{Name:'Class 3'},{Name:'Class 4'},{Name:'Class 5'},{Name:'Exclude Controls'},{Name:'All Control Drugs(2-5)'}]">
                        </eprx-select>
                    </div> -->
                    <!-- <div class="'col-lg-8 col-md-8 col-sm-8 col-xs-8'">
                        <label>Prescriber</label>
                        <div class="seconday-search--grid">
                            <app-searchbox-global [SearchFor]="'Prescriber'" [DisableRadioButtons]="true" [IsAddNew]="false"
                                (SelectedPrescriber)="selectedPresInfo($event)" [ClearInputValue]="presInputValue" class="common_global_search_max_content_display_for_reports"
                                >
                            </app-searchbox-global>
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                        <eprx-select [RxSelectId]="'Rx Status'" [LabelText]="'Rx Status'" [PlaceHolder]="'Rx Status'"
                            [Title]="'Status'" [ControlName]="'billSearch'" [FormGroupName]="refillDueFG"
                            [HasMultiple]="true" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="''"
                            (TriggerSelectValue)="getRxStatus($event)"
                            [List]="[{Id: 0, Name: 'ALL'},{Id: 1, Name: 'Unbilled'}, {Id: 2, Name: 'Billed'}]">
                        </eprx-select>
                    </div> -->
                    <div class="row col-md-12 insurance--body pr-0">
                        <div class="col-lg-6 col-md-7 col-sm-7 col-xs-7 height_2_5rem" [formGroup]="refillDueFG">
                            <input class="form-check-input" type="checkbox" [id]="1" value="1"
                                formControlName="IncludeFacilityPatients" />
                            <label type="label" class="form-check-label" id="'IncludeFacilityPatients'" [for]="1">
                                For Facility Patients only
                            </label>
                        </div>
                        <div class="col-lg-6 col-md-7 co l-sm-7 col-xs-7 height_2_5rem" [formGroup]="refillDueFG">
                            <input class="form-check-input" type="checkbox" [id]="'rfdueunbld'" value="1" formControlName="IncludeUnbilledRxs" />
                            <label type="label" class="form-check-label" id="'IncludeUnbilledRxs'" [for]="'rfdueunbld'">
                                Include Unbilled Rx(s)
                            </label>
                        </div>
                    </div>
                    
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 height_2_5rem" [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="2" value="2"
                            formControlName="ShowExpiredRxOnly" />
                        <label type="label" class="form-check-label" [for]="2" id="'expired Rx only'">
                            Show Expired Rx's only
                        </label>
                    </div> -->
                    <!-- <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 height_2_5rem" [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="3" value="3"
                            formControlName="ShowMaintenanceDrugsOnly" />
                        <label type="label" class="form-check-label" [for]="3" id="'Drugs Only'">
                            Show Maintenance Drugs Only
                        </label>
                    </div> -->
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 height_2_5rem" [formGroup]="refillDueFG">
                        <fieldset [disabled]= "refillDueFG?.value?.ShowExpiredRxOnly">
                        <input class="form-check-input" type="checkbox" [id]="6" value="6" formControlName="ShowExpiring"
                        [checked]= "refillDueFG?.value?.ShowExpiring && !refillDueFG?.value?.ShowExpiredRxOnly" />
                        <label type="label" class="form-check-label" [for]="6" id="'Show Expiring Rxs'">
                            Show Expiring Rx's
                        </label>
                        </fieldset>
                    </div> -->
                    <div class="row col-md-12 insurance--body pr-0">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 height_2_5rem" [formGroup]="refillDueFG">
                            <input class="form-check-input" type="checkbox" [id]="'PickupDate8'" value="8" formControlName="PickupDate"/>
                            <label type="label" class="form-check-label" [for]="'PickupDate8'" id="'PickupDate'">
                                Calculate Due Date based on Picked Up Date
                            </label>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 height_2_5rem" [formGroup]="refillDueFG">
                            <input class="form-check-input" type="checkbox" [id]="9" value="9"
                                formControlName="ExcludeRefillRemainder" />
                            <label type="label" class="form-check-label" [for]="9" id="'ExcludeRefillRemainder'">
                                Exclude Drugs Set To No Refill Reminder
                            </label>
                        </div>
                    </div>
                    
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 height_2_5rem" [formGroup]="refillDueFG">
                        <fieldset [disabled]= "refillDueFG?.value?.ShowExpiredRxOnly">
                        <input class="form-check-input" type="checkbox" [id]="5" value="5" formControlName="ShowExpired"
                        [checked]= "refillDueFG?.value?.ShowExpired && !refillDueFG?.value?.ShowExpiredRxOnly" />
                        <label type="label" class="form-check-label" [for]="5" id="'Show Expired Rxs'">
                            Show Expired Rx's
                        </label>
                        </fieldset>
                    </div> -->
                    <!-- <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 mt-2 height_2_5rem"
                        [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="7" value="7"
                            formControlName="BasedOnEquiDrugs" />
                        <label type="label" class="form-check-label" [for]="7" id="'BasedOnEquiDrugs'">
                            Refills Due Based On Equivalent Drugs
                        </label>
                    </div> -->
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 height_2_5rem" [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="12" value="12"
                            formControlName="ExcludeEarlyForRefill" />
                        <label type="label" class="form-check-label" [for]="12" id="'excludeearlyforrefil'">
                            Exclude Early for Refill
                        </label>
                    </div> -->
                    
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 height_2_5rem" [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="14" value="14"
                            formControlName="ExcludeTaggedRxs" />
                        <label type="label" class="form-check-label" [for]="14" id="'Excludetaggedrxs'">
                            Exclude Tagged Rx(s)
                        </label>
                    </div> -->
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 height_2_5rem" [formGroup]="refillDueFG">
                        <fieldset [disabled]= "!(refillDueFG?.value?.ShowExpired || refillDueFG?.value?.ShowExpiredRxOnly)">
                        <input class="form-check-input" type="checkbox" [id]="13" value="13"
                            formControlName="ExcludeExpiredByTimeLimit" [checked]= "refillDueFG?.value?.ExcludeExpiredByTimeLimit &&
                             (refillDueFG?.value?.ShowExpired || refillDueFG?.value?.ShowExpiredRxOnly)" />
                        <label type="label" class="form-check-label" [for]="13" id="'Excludeexpiredbytimelimit'">
                            Exclude Expired by time limit
                        </label>
                        </fieldset>
                    </div> -->
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 height_2_5rem" [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="17" value="17"
                            formControlName="PrintRxPrice" />
                        <label type="label" class="form-check-label" [for]="17" id="'printrxprice'">
                            Print Rx Price
                        </label>
                    </div> -->
                    <!-- <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 height_2_5rem" [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="'partialFillOnly'" value="'partialFillOnly'"
                            formControlName="IsPartialFill" />
                        <label type="label" class="form-check-label" [for]="'partialFillOnly'" id="'partialFillOnly'">
                            Show Partial Fill
                        </label>
                    </div> -->
                    <!-- <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 height_2_5rem"
                        [formGroup]="refillDueFG">
                        <input class="form-check-input" type="checkbox" [id]="'consumptionThreshold24'" value="consumptionThreshold"
                        formControlName="ConsumptionThreshold" (change)="changePickupDateSelection()"/>
                        <label type="label" class="form-check-label" [for]="'consumptionThreshold24'" id="'consumptionThreshold'">
                            Calculate Due Date By consumption Threshold
                        </label>
                    </div> -->
                    <div class="row col-md-12 insurance--body pr-0">
                        <div class="col-lg-6 col-md-5 col-sm-5 col-xs-5 height_3_5rem" [formGroup]="refillDueFG">
                            <input class="form-check-input" type="checkbox" [id]="'rfdue18'" value="18" formControlName="IncludeFiledRx" />
                            <label type="label" class="form-check-label" [for]="'rfdue18'" id="'Includefiledrxs'">
                                Include Deferred Rx(s)
                            </label>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin_top_0_5rem padding_left_30px">
                                <eprx-radio [List]="['All Deferred Rxs', 'Due within Date Range']" [ValueList]="['all', 'due']"
                                    [IDForList]="['rfdues15', 'rfdues16']" [LabelForId]="" [FormGroupName]="refillDueFG" [ControlName]="'FiledRxs'"
                                    [IsDisabled]="!refillDueFG.get('IncludeFiledRx').value">
                                </eprx-radio>
                            </div>
                        </div>
                        <div class="col-md-6 height_2_5rem">
                            <label class="col-md-12 padding-0">Rx Setting</label>
                            <eprx-radio [ControlName]="'RxRefillReminder'" [FormGroupName]="refillDueFG"
                                [List]="['Refill Reminder Yes', 'Refill Reminder No','All']" [ValueList]="['Yes', 'No','All']"
                                [IDForList]="['100', '101','102','103']" [LabelForId]="">
                            </eprx-radio>
                        </div>
                    </div>
                    <div class="row col-md-12 insurance--body pr-0">
                        <div class="col-md-6 messsage-height margin_top_1rem height_2_5rem">
                            <label class="col-md-12 padding-0">Patient Setting</label>
                            <eprx-radio [ControlName]="'PatientRefillReminder'" [FormGroupName]="refillDueFG"
                                [List]="['Refill Reminder Yes', 'Refill Reminder No','All']" [ValueList]="['Yes', 'No','All']"
                                [IDForList]="['200', '201','202','03']" [LabelForId]="">
                            </eprx-radio>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 margin_top_1rem">
                            <eprx-select [RxSelectId]="'Insurance'" [LabelText]="'Insurance(Blank=ALL)'"
                                [PlaceHolder]="'Insurance'" [Title]="'Insurance'" [ControlName]="'InsuranceType'"
                                [FormGroupName]="refillDueFG" [HasMultiple]="true" [HasBindLabel2]="true" [Show2Values]="true"
                                [BindLabel2]="'InsuranceName'" [BindLabel]="'PlanCode'" [BindValue]="'PlanCode'"
                                [LabelForId]="''" [List]="insuranceData" [LabelTitle1]="'Plan Code'">
                            </eprx-select>
                        </div>        
                    </div>                    
                    <div class="row col-md-12 insurance--body pr-0">
                        <div class="col-lg-10 col-md-8 col-sm-8 col-xs-8">
                            <eprx-text-area [LabelText]="'Exclude Insurance Codes'" [ControlName]="'ExcludeInsuranceType'" [FormGroupName]="refillDueFG"
                            [PlaceHolder]="'Exclude Insurance Codes'" [Rows]="2" [ReadOnly]="true" [isChips]="true">
                            </eprx-text-area>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-4 text-right margin_top_1_8rem pl-0">
                            <button  type="button" class="hotkey_success" (click)="openInsSelectPopUp()" (keydown.enter)="openInsSelectPopUp()" appShortcutKey [AltKey]="'i'">
                                <span>I</span>Select Ins
                            </button>
                        </div>
                    </div>
                    <div class="row col-md-12 insurance--body pr-0">
                        <div class="col-lg-10 col-md-8 col-sm-8 col-xs-8">
                            <eprx-text-area [LabelText]="'Zip Code(s) (Comma Separated)'" [Rows]="2"
                                [FormGroupName]="refillDueFG" [ControlName]="'zipCodes'"
                                [PlaceHolder]="'Zip Code(s) (Comma Separated)'" [RxSelectId]="'zipcodess'">
                            </eprx-text-area>
                        </div>
                        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-4 text-right margin_top_1_8rem pl-0">
                            <button type="submit" class="hotkey_success pull-right" id="selectZipC" appShortcutKey
                                [AltKey]="'z'" (click)="openZipPOPup()" (keydown.enter)="openZipPOPup()">
                                <span>Z</span>Select Zip
                            </button>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12 col-md-8 col-sm-8 col-xs-8 ">
                        <div class="row">
                            <div class="col-lg-10 col-md-8 col-sm-8 col-xs-8">
                                <eprx-text-area [LabelText]="'Exclude Insurance Codes'"
                                    [ControlName]="'ExcludeInsuranceType'" [FormGroupName]="refillDueFG"
                                    [PlaceHolder]="'Exclude Insurance Codes'" [Rows]="2" [ReadOnly]="true">
                                </eprx-text-area>
                            </div>
                            <div class="mt-4 col-lg-2 col-md-4 col-sm-4 col-xs-4">
                                <button type="button" class="hotkey_success" (click)="openInsSelectPopUp()"
                                    (keydown.enter)="openInsSelectPopUp()" appShortcutKey [AltKey]="'i'">
                                    <span>I</span>Select Ins
                                </button>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-12 messsage-height--for-refill_sort">
                        <label class="col-md-12 padding-0"> Sort By </label>
                        <eprx-radio [ControlName]="'sortBy'" [FormGroupName]="refillDueFG" [List]="['Rx', 'Patient']"
                            [ValueList]="['Rx', 'Patient']" [IDForList]="['10', '11']" [LabelForId]="">
                        </eprx-radio>
                    </div> -->
                </div>
                <div class="col-md-4" *ngIf="false">
                    <div class="eprx--block__content border_AllRadius">
                        <div class="row col-md-12 insurance--body pr-0">
                            <div class="col-lg-12 col-md-4 col-sm-4 col-xs-4 height_2_5rem pr-0 mt-2">
                                <div class="row">
                                    <div class="col-9">
                                        <label id="'IsAutoBill'">
                                            Auto Bill
                                            &nbsp;

                                            <span  class="d-inline info_display" [ngbTooltip]="instructToolTip" container="body" tooltipClass="bottom--toolTip hover--width"  triggers="mouseover:mouseleave" [autoClose]="true" placement="bottom">𝒊</span>
                                        <ng-template #instructToolTip>
                                            <div class="text-left">
                                                Refill scheduler will process and transmit refills, however, all clinical checks (Ex: Drug to Drug Interactions, Allergy Warnings etc.) need to be handled during the workflow through Verifications (Rx/DPV/Rph verifications).
                                            </div>
                                        </ng-template>
                                        </label>
                                    </div>
                                    <div class="col-3  pl-4 pr-0">
                                        <eprx-toggle [ControlName]="'IsAutoBillActive'" [FormGroupName]="refillDueFG"  [IsChecked] = "refillDueFG?.get('IsAutoBillActive').value" >
                                        </eprx-toggle>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-12 insurance--body pr-0 mt-2" [ngClass]="{'disabled--selection': !refillDueFG?.value['IsAutoBillActive']}">
                            <div class="col-lg-12 col-md-4 col-sm-4 col-xs-4 pr-0">
                                <eprx-select [RxSelectId]="'pharmInitSchlr'" [LabelText]="'Pharmacist Initials'" [ControlName]="'PharmacistUserId'"
                                    [FormGroupName]="refillDueFG" [PlaceHolder]="'Pharmacist Initials'" [BindLabel]="'UserId'" [BindLabel2]="'Name'"
                                    [BindLabel3]="'Role'" [LabelTitle1]="'USER ID'" [LabelTitle2]="'NAME'"
                                    [LabelTitle3]="'ROLE'" [BindValue]="'AppUserId'" [LabelForId]="'AppUserId'" [IsRequired]="refillDueFG?.value['IsAutoBillActive']" [ErrorDefs]="{required: 'Required'}" [FormGroupInvalid]="formGroupInvalidDate"
                                     [List]="pharmacistDet" [hasBindLabel3]="true" [updateDropDownPos]="true" [ReadOnly]="!refillDueFG?.value['IsAutoBillActive']"
                                     (TriggerSelectValue)="patchUserInitials($event)" [MarkAsTouched]="refillDueFG?.value['IsAutoBillActive'] && refillDueFG.controls?.PharmacistUserId?.touched"></eprx-select>
                            </div>
                        </div>
                        <div class="row col-md-12 insurance--body pr-0 mt-2" [ngClass]="{'disabled--selection': !refillDueFG?.value['IsAutoBillActive']}">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 height_2_5rem" [formGroup]="refillDueFG">
                                <input class="form-check-input" type="checkbox" [id]="'rfdueUnPickd'" value="6" formControlName="ExcludeUnpickedPrevFillRx" [attr.disabled]="!refillDueFG?.value['IsAutoBillActive'] ? true :  null"/>
                                <label type="label" class="form-check-label label-weight" id="'ExcludeUnpickedPrevFillRx'" [for]="'rfdueUnPickd'">
                                    Do Not Auto Bill If Last Refill Not Picked Up
                                </label>
                            </div>
                        </div>
                        <div class="row col-md-12 insurance--body pr-0 mt-2">
                            <div class="col-12 pr-0">
                                <div class="eprx--block__content border_AllRadius border-bg--color_InfoBlock">
                                <span> <i class="fas fa-question-circle usrGuidFrBlueBg userGuideIconPosition pt-8 font_size_15px" container="body"></i></span>
                                    <span class="font_weight_500 pl-2">Auto Bill Help Info</span>
                                    <div class="listInfo">
                                        <ul>
                                            <li>
                                                Automatically replaces NDC if a replacement is available.
                                            </li>
                                            <li>
                                                Cash prescription refills are auto-billed.
                                            </li>
                                            <li>
                                                Refills are billed even if inventory is low or unavailable.
                                            </li>
                                            <li>
                                                Refills are auto-billed only for eligible (Dark Green) prescriptions based on due date.
                                            </li>
                                            <li>
                                                Uses the previous Refill's billing sequence.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer pt-0">
        <div class="text-right pull-right col-md-10 padding-0">
            <button  class="hotkey_success" (click)="setParamters()" appShortcutKey [AltKey]="'s'"><span>S</span>Save
            </button>
            <button  autofocus class="hotkey_success" (click)="closePopupForRefillDue()" appShortcutKey
                [AltKey]="'c'"><span>C</span>Cancel
            </button>
        </div>
    </div>
</ng-template>

<app-ins-codes *ngIf="openForInsCode" [OptedInsCodeList]="insucodeList" [OptedInsList]="optedInsList" [frmScrn]="'schedulerRef'" (emitInscodes)="closeInsModalPopup($event)"></app-ins-codes>



<ng-template #ZIPCODESPOPUP let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('Close click');closePopUp()">
        <h4 header>Please Select Zip Code(s)</h4>
        <span body>
            <div class="col-md-12 height_2_5rem">
                <eprx-radio [ControlName]="'FilterBy'" [FormGroupName]="ZipCodeFG"
                    [List]="['FilterBy ZipCode', 'FilterBy City']" [ValueList]="[true , false]"
                    [IDForList]="['21', '22']" (TriggerSelectValue)="clearFilter($event)">
                </eprx-radio>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <eprx-input [LabelText]="'Filter'" [ControlName]="'FilterSearch'" [FormGroupName]="ZipCodeFG"
                        [RxSelectId]="'filterSearch'"
                        [PlaceHolder]="ZipCodeFG.value.FilterBy ? 'Search Zip Code' : 'Search City'"
                        (TriggerChangeValue)="filterGrid($event)">
                    </eprx-input>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <eprx-select [LabelText]="'State'" [ControlName]="'State'" [PlaceHolder]="'Select State'"
                        [FormGroupName]="ZipCodeFG" [List]="StatesList" [BindLabel]="'Name'" [LabelForId]="'Name'"
                        [BindValue]="'Name'" (TriggerSelectValue)="loadStateZipCodes($event.value)">
                    </eprx-select>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5  mt-3 text-right pull-right">
                    <button footer class="hotkey_success" (click)="clearFilter($event)"
                        (keydown.enter)="clearFilter($event)" appShortcutKey [AltKey]="'f'"><span>F</span>
                        Clear Filter
                    </button>
                    <button footer class="hotkey_success" (click)="clearSelection()" (keydown.enter)="clearSelection()"
                        appShortcutKey [AltKey]="'s'"><span>S</span> Clear
                        Selection
                    </button>
                </div>
            </div>
            <div class="col-md-12">
                <wj-flex-grid #flexx [headersVisibility]="'Column'" [itemsSource]="ZipCodesWJ" [isReadOnly]="true"
                    [columnPicker]="''" [selectionMode]="'None'" class="max-height_21rem" (initialized)="init(flexx)">
                    <wj-flex-grid-column [width]="65" [header]="'Select'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div class="text-center padding_left_10px">
                                <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                                    [(ngModel)]="item.IsSelected" [checked]="item.IsSelected"
                                    (click)="changeSelcted(item, $event)" />
                                <label class="form-check-label" [for]="item.RowId"></label>
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="actvHeader" [binding]="actvHeader" [visible]="true" *ngFor="
                                                                    let actvHeader of ZipHeaders;
                                                                    let i = index" [width]="
                                                                    actvHeader === 'State' ? 150 : '*'">
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
            <div class="col-md-12 row">
                <span class="margin_left_2rem">
                    <label class="margin_right_1rem">Total Records: </label> {{ZipCodesWJ ? ZipCodesWJ?.items?.length :
                    0}}
                </span>
                <span class="margin_left_2rem">
                    <label class="margin_right_1rem">Selected Records: </label> {{selectedRecords ?
                    selectedRecords?.length : 0}}
                </span>
            </div>
        </span>
        <button footer class="hotkey_success" (click)="c('Close click');selectedZips()" appShortcutKey
            [AltKey]="'o'"><span>O</span> Ok
        </button>
        <button footer autofocus class="hotkey_success" (click)="c('Close click');closePopUp()" appShortcutKey
            [AltKey]="'c'"><span>C</span>Cancel
        </button>
    </app-custom-modal>

</ng-template>
