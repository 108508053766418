<ng-template #WarnPopup let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal [hideCross]="hideCross" (oncrossBtnClick)="c('close');okButton ? closeEvent(true): (okayFinalButton || closeButton) ? closeEvent(false) : patchQtyDisp(null)">
        <h4 header>
            {{IsHeaderText ? IsHeaderText : 'Warning!!'}} </h4>
        <div body>
            <span [innerHTML]="warnMsg"></span></div>
            <button ngbAutofocus  *ngIf="okButton" id="focuschange" footer   type="submit" class="hotkey_primary" appShortcutKey (click)="c('close')" 
            (click)="closeEvent(true)" (keydown.enter)="closeEvent(true)" InputKey="o"><b>O</b> OK
        </button>
        <button *ngIf="okayFinalButton" id="focuschange" footer   type="submit" class="hotkey_primary" appShortcutKey (click)="c('close')"
        (click)="closeEvent(false)" (keydown.enter)="closeEvent(false)" InputKey="o"><b>O</b> OK
        </button>
        <!-- <button *ngIf="okButton" footer autofocus  type="submit" class="hotkey_primary" appShortcutKey (click)="c('close')"
            (click)="closeEvent(true)" (keydown.enter)="closeEvent(true)" InputKey="o"><b>o</b> Ok
        </button> -->
        <button class="hotkey_primary" type="submit"  footer *ngIf="yesButton"  ngbAutofocus appShortcutKey InputKey="y" (click)="patchQtyDisp(true)">
            <b>Y</b>YES
        </button>
        <button class="hotkey_primary"   type="submit" footer *ngIf="noButton"  appShortcutKey InputKey="n" (click)="patchQtyDisp(false)">
            <b>N</b>NO
        </button>
        <button class="hotkey_primary" type="submit"  footer *ngIf="recallButton"  ngbAutofocus appShortcutKey InputKey="r" (click)="closeEvent(true)">
            <b>R</b>RECALL DELETED RX
        </button>
        <button class="hotkey_primary"   type="submit" footer *ngIf="closeButton"  appShortcutKey InputKey="c" (click)="closeEvent(false)">
            <b>C</b>CLOSE
        </button>
        <button class="hotkey_primary" title="Defer with future effective date as due date"  type="submit" footer *ngIf="Defer"  appShortcutKey InputKey="d" (click)="closeEvent('defer')">
            <b>D</b>DEFER
        </button>
        <button class="inactive" title="Defer with future effective date as due date"  type="submit" footer *ngIf="Defer === false"  appShortcutKey InputKey="d" > <b>D</b>
           DEFER
        </button>
        <button class="hotkey_primary"   type="submit" footer title="Ignore effective date and Process Today" *ngIf="IgnoreAndContinue"  appShortcutKey InputKey="i" (click)="closeEvent('ignoreAndContinue')">
            <b>I</b>IGNORE AND PROCESS
        </button>
        <button class="hotkey_primary"  id="CancelButton"  type="submit" footer *ngIf="CancelButton"  appShortcutKey InputKey="c" (click)="closeEvent('cancel')">
            <b>C</b>CANCEL
        </button>
        <button class="hotkey_primary" type="submit"  footer *ngIf="buttonType"  ngbAutofocus appShortcutKey InputKey="{{shortcutKey}}" (click)="closeEvent(true)">
            <b>{{shortcutKey | uppercase}}</b> {{buttonType}}
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #PAErrorMessage let-c="close" let-d="dismiss" class="el--popup">
    <div class="main_error_block">
        <i class="far fa-times float-right" aria-hidden="true" (click)="c('Close click');closeEvent(true)"></i>
        <div class="pt-2">The following fields are required to initiate PA request for Rx# : {{RxNum}}</div>
        <div *ngFor="let error of warnMsg">
            <span class="error_msg_text">{{error}}</span>
        </div>
        <div>Please enter those fields and try again.</div>
    </div>
</ng-template>
