import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RxStore } from 'src/app/store';
import { CollectionView } from "@grapecity/wijmo";
import * as moment from 'moment';
import { CommonService, RxService } from 'src/app/services';
import { CommonUtil, WijimoUtil } from 'src/app/utils';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RxChecksForCommonLoigc } from 'src/app/utils/rxCheck.util';
import { MsgConfig } from 'src/app/app.messages';
@Component({
    selector: 'app-prevoius-refills-fills',
    templateUrl: './prevoius-refills-fills.component.html',
    styleUrls: ['./prevoius-refills-fills.component.scss']
})
export class PrevoiusRefillsFillsComponent implements OnInit {
    @Input() rxFG: FormGroup;
    rFData: any;
    refRxDetails: Response;
    @Output() reOpenEditRx = new EventEmitter<any>();
    totalCount: number = 0;
    hasPrevRef: boolean = false;
    rxType: any;
    prevoiusRefills: any = [];
    PrevRefWJ: CollectionView;
    hasPrevFills: boolean = false;
    modelReferance: any;
    actvHeaders1: any;
    actvHeaders2: any;
    fillsListWJ: CollectionView;
    isPartialFillRx: number = 0;
    unsubscribe$: Subject<void> = new Subject<void>();
    get PRESCRIBER(): FormGroup {
        return this.rxFG.get("Prescriber") as FormGroup;
    }

    get PRESCRIPTION(): FormGroup {
        return this.rxFG.get("Prescription") as FormGroup;
    }

    get RxStatusId(): FormControl {
        return this.rxFG.controls["Prescription"].get(
            "RxStatusId"
        ) as FormControl;
    }
    constructor(private _rxStore: RxStore, 
        public _commonServ: CommonService, 
        private _rxServc: RxService, 
        public _cmnUtils: CommonUtil,
        private _wijimoUtils: WijimoUtil,
        public _rxCheckUtil: RxChecksForCommonLoigc
    ) { }

    ngOnInit(): void {
        this.rxType = this.rxFG.get('rxType')?.value || '';
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && (w["WijmoKey"] === "prevoiusRefillsWJ" || w["WijmoKey"] === "prevoiusFillsWJ")) {
                this.patchDefaultValHeaders();
            }
        });
        if (this.rxType == "er") {
            this.getFillCummulativeDetails();
        }
        this.isPartialFillRx  = this._commonServ.checkIsPartialFill(this.rxFG.value.PrescReFill, this.rxFG.value.Prescription) ? this.rxFG.value.PrescReFill.PartialFillNo : "";
        this.getPrevRef();
    }
    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("prevoiusRefillsWJ");
        this.actvHeaders1 = this._wijimoUtils.patchDefHeader("prevoiusRefillsWJ", storedWJ);
        const storedWJ1 = await this._wijimoUtils.getWJSavedData("prevoiusFillsWJ");
        this.actvHeaders2 = this._wijimoUtils.patchDefHeader("prevoiusFillsWJ", storedWJ1);
    }
    generatePrevrefWJ(info: any) {
        var totDispQty = 0;
        this.totalCount = info.length;
        if (info && info.length > 0) {
            this.PrevRefWJ = new CollectionView(
                info.map((Rx, i) => {
                    const j = {};
                    j["Rx#"] = Rx.PrescNum ? Rx.PrescNum.toString() : null;
                    j["Fill Date"] = (moment.utc(Rx.FillDtTm).local()).format(
                        MsgConfig.DATE_FORMAT
                    );
                    j["Order Qty"] = Rx.OrdQty ? (parseFloat(Rx.OrdQty)).toFixed(3) : "0.000";
                    j["Disp Qty"] = Rx.DispQty ? (parseFloat(Rx.DispQty)).toFixed(3) : "0.000";
                    totDispQty = totDispQty + (Rx.DispQty);
                    j["Days"] = Rx.SupplyDays;
                    j["Ref#"] = Rx.ReFillNum;
                    const prescRefObj = {
                        NumberOfFills: Rx.NumberOfFills,
                        PartialFillNo: Rx.PartialFillNo,
                        PartialIndStatus: Rx.PartialIndStatus
                    }
                    j["Fill#"] = this._commonServ.checkIsPartialFill(prescRefObj, null) ? Rx.PartialFillNo : " ";
                    j["Total Amt"] = Rx.TotalAmt ? "$" + (parseFloat(Rx.TotalAmt)).toFixed(2) : "$0.00";
                    return j;
                })
            );
        } else {
            this.PrevRefWJ = new CollectionView();
        }
    }
    async getPrevRef() {
        let rxNo: any;
        if (this.rFData && this.rFData.queueData && this.rFData.queueData.length) {
            this.rFData.queueData.map(item => {
                if (this.rxFG.value && this.rxFG.value.PrescReFill && this.rxFG.value.PrescReFill.Id
                    && item.PrescrefillId === this.rxFG.value.PrescReFill.Id) {
                    rxNo = item.Prescnum;
                }
            });
        }
        if (this.rxFG.value["Patient"]["patientid"] && this.rxFG.value["Drug"]["id"]) {
            const rxDetlsID = {
                PatientId: this.rxFG.value["Patient"]["patientid"],
                PrescriberId: this.PRESCRIBER.value["prescriberid"],
                DrugId: this.rxFG.value["Drug"]["id"],
                PrescNum: rxNo ? rxNo : this.PRESCRIPTION.value["PrescNum"]
            };
            const resp = await this._rxServc.refillRxDetailLessData(rxDetlsID).toPromise();
            if(resp)
            {
                this.refRxDetails = resp;
                this._rxStore.storeMiniRefRxDetails(this.refRxDetails);
                if (resp?.RefillRxData?.length > 0) {
                    const refilldetails = this.rxType != "rf" ? (resp.RefillRxData.filter((item: any) => {
                        return item.Id !== this.rxFG.value["PrescReFill"]["Id"];
                    })) : resp.RefillRxData;
                    this.prevoiusRefills = refilldetails;
                    if(this.prevoiusRefills?.length)
                        this.hasPrevRef = true;
                    this.generatePrevrefWJ(this.prevoiusRefills);
                } else {
                    this.hasPrevRef = false;
                }
            }
        }
    }
    closeAndReopenEditRx(Value) {
        if (this.modelReferance) {
            this.modelReferance.close();
            this.modelReferance = null;
        }
        if (Value && Value["PrescRfId"] !== this.rxFG.value["PrescReFill"]["Id"]) {
            const data = { rxNo: this.rxFG.value["Prescription"]["PrescNum"], rfId: Value["PrescRfId"] };
            this.reOpenEditRx.emit(data);
        }
    }
    async getFillCummulativeDetails() {
        const data = {PrescriptionId: this.rxFG.value["Prescription"]["Id"],
        RefillNum: this.rxFG.value["PrescReFill"]["ReFillNum"]};
        const fillDetails: any = await this._rxServc.getCummulativeFillDetails(data).toPromise();

        this.generatefillsWJ(fillDetails);
        this.rxFG.controls["isPreviousRefillPartialCreatedOrNot"].patchValue((fillDetails && fillDetails.length > 1) ? true : false);
    }

    generatefillsWJ(info: any) {
        var totDispQty = 0;
        if (info && info.length > 1) {
            this.hasPrevFills = true;
            this.fillsListWJ = new CollectionView(
                info.map((Rx, i) => {
                    const j = {};
                    j["PrescRfId"] = Rx.RefillId;
                    j["Fill#"] = Rx.TotFills ? Rx.TotFills.toString() : 0;
                    j["Disp Qty"] = Rx.TotDispQty ? (parseFloat(Rx.TotDispQty)).toFixed(3) : "0.000";
                    totDispQty = totDispQty + (Rx.TotDispQty) ;
                    j["Days"] = Rx.TotSupplyDays;
                    j["Ing. Cost"] = Rx.TotIngCost ? "$" + (parseFloat(Rx.TotIngCost)).toFixed(2) : "$0.00";
                    j["U&C"] = Rx.TotUNC ? "$" + (parseFloat(Rx.TotUNC)).toFixed(2) : "$0.00";
                    j["Copay"] = Rx.TotCoPay ? "$" + (parseFloat(Rx.TotCoPay)).toFixed(2) : "$0.00";
                    return j;
                })
            );
        } else {
            this.hasPrevFills = false;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
