import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { SystemData, DrugData } from "src/app/models";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { DrugUtils, CommonUtil } from "src/app/utils";
import { DrugStore, RxStore } from "src/app/store";
import { AlertService, BucketService, CommonService, DrugPriceCheckService, DrugService, InventoryService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddDrugToBucketComponent } from "../../shared/add-drug-to-bucket/add-drug-to-bucket.component";
import { DrugBucketUtil } from "src/app/utils/drug-bucket.util";
import * as _ from "lodash";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";


@Component({
    selector: "app-drug-info",
    templateUrl: "./drug-info.component.html"
})
export class DrugInfoComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() drugId: any;
    @Input() systemData: SystemData;
    @Input() drugInfo: DrugData;
    @Input() drugInfoFG: FormGroup;
    @Input() formGroupInvalid: boolean;
    @Input() focusOn: any;
    @Input() dropDownValues: any;
    @Input() doAddDrugToBucket: boolean = false;
    @Input() drugFlow: any;
    @Input() manuFactDrug : any;
    @Input() rxBillStatusId: any;
    @Input() checkPayRec: any;
    @Input() isOriginalRx: any;
    @Input() islatestrefill: any;
    @Input() isPartialRefStatus: any;
    @Input() frmAction: boolean = true;
    @Input() InsuRestrictInfo: any;
    @Input() enableDrugClassFromEDI : boolean;
    @Input() isDrugPreferred: any;
    @Input() defaultSig: any;
    @Output() EmitOpenCompndDrug = new EventEmitter();
    @Output() EmitaddDrugToBuckComplete = new EventEmitter();

    modalRef: any;
    imgURL: any;
    minDate: any;
    focusOnDU: any;
    isDrugSearchFocused: boolean;
    copyNDCTo: any;
    modelRef: any;
    BillingUnitDropDowns: any[] = [];
    compNDC: any;
    drugBucketList: any[];
    DrugBucketFG: FormGroup;
    bucketIdsTracker: any[] = [];
    prevDrgClass:any;
    unsubscribe$:Subject<void> = new Subject();
    @ViewChild("AddDrugToBucketConfirm", {static: true})
    AddDrugToBucketConfirm: any;
    @ViewChild("sigCode", {static: true})
    sigCode: any;
    @ViewChild("drugclasschange", {static: true})
    drugclasschange: any;
    Is340BPharm$: any;
    manfactList: any;
    countFG: FormGroup;
    manFactDataFrmSearchMMS: {Name: any; };
    drugInfo$: Observable<any>;
    DrugList: any[];
    selectedDrugItem: string;
    @ViewChild("MarkAsPrefDrug", {static: true})
    MarkAsPrefDrug: any;
    modalRefIsPref: any;
    compoundDrugIds = [];
    compoundIng: FormGroup;
    compDrugList = [];
    isOriginalCompDrug: boolean;
    isOriginalDrugAddToBucket: string = null;
    modelRefDiag: any;
    get ThreeFourtyBSettings(): FormArray {
        return this.drugInfoFG.get("ThreeFourtyBSettings") as FormArray;
      }
    @ViewChild("diagList", { static: true })
    diagList: any;


    constructor(private _drugUtils: DrugUtils, private _modalService: NgbModal, private _drugBuckUtil: DrugBucketUtil,
        private _comnUtil: CommonUtil, private _drugStore: DrugStore, public _cmnSvc: CommonService,  private _cdr: ChangeDetectorRef,
        private _altSvc: AlertService, private _invService: InventoryService, private _fb: FormBuilder,private _rxStore: RxStore,
        private _drugpriceChk: DrugPriceCheckService, private _drugSvc: DrugService, private _formatsUtil: FormatsUtil, private _bucketService: BucketService, private _commonServ: CommonService,) {
            this.countFG = this._fb.group({
                count: 0
            });
         }

    ngOnInit() {
        this.Is340BPharm$ = this._cmnSvc.Is340BVal$;
            if (!this.drugInfoFG.value["Drug"]["IsFDAApprovedNULL"]) {
                this.drugInfoFG.controls["Drug"].patchValue({ IsFDAApprovedNULL: 2 });
            }
        const today = new Date();
        this.minDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        if (!this.drugId && this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2) {
            if (!this.drugInfoFG.value["Drug"]["DrugCode"]) {
                this.getNdcForCompound();
            }
            this.openCompound();
        }
        this.BillingUnitDropDowns = this.systemData.DrugUnit.filter(f => ["EA", "GM", "ML"].includes(f.Remarks));
        if (this.BillingUnitDropDowns && this.BillingUnitDropDowns.length) {
            this.BillingUnitDropDowns.map(v => {
                if (v["Remarks"] === "EA") {
                    v["Name"] = "EACH";
                } else if (v["Remarks"] === "GM") {
                    v["Name"] = "GRAMS";
                } else if (v["Remarks"] === "ML") {
                    v["Name"] = "Milliliters";
                }
            });
        }
        this.drugInfoFG.markAsPristine();
        this.DrugBucketFG = this._drugBuckUtil.createDrugBucketFG();
        this.focusOn = (this.focusOn && this.focusOn === "DiscontDt" || this.focusOn === "LastUpdateDt") ? this.focusOn : (this.focusOn && this.focusOn === "drugCost") ? "drugCost" : "QuickCode";
            const element: any = document.getElementById(this.focusOn);
            if (element) {
                element.focus();
            }
        this.setRefRemainderValue();
        this.drugInfo$ = this._drugStore.drugInfo$;
        if(this.drugId && !this.drugInfoFG.value["Drug"]?.["DrugCode"]?.includes('-')) {
            this.getNdcFormatInEditMode();
        }
    }
    async getNdcFormatInEditMode() {
        this.drugInfoFG.controls["Drug"].patchValue({
            DrugCode:  this._formatsUtil.getNDCFormat( this.drugInfoFG.value["Drug"]["DrugCode"])
        });
    }
    ngOnChanges(changes?: any) {
        if (changes.doAddDrugToBucket && changes.doAddDrugToBucket.currentValue &&
            !changes.doAddDrugToBucket.previousValue && this.drugId) {
            this.getDrugBucketListNPatch(this.DrugBucketFG && this.DrugBucketFG.value && this.DrugBucketFG.value.Id ? this.DrugBucketFG.value.Id : null, true, false);
        }
        if (this.focusOn && (this.focusOn === "drugCost")) {
            const element = document.getElementById("UnitPriceCost") as HTMLInputElement;
            if (element) {
                element.focus();
                element.select();
            }
        }
        if (changes.isDrugPreferred && changes.isDrugPreferred.currentValue) {
            this.drugInfoFG.controls["Drug"].patchValue({IsPreferred : (changes.isDrugPreferred.currentValue === "true" ? true : (changes.isDrugPreferred.currentValue === "false" ? false : this.drugInfoFG.controls["Drug"].value))});
        }
        this.focusOn = this.focusOn && (this.focusOn === "DiscontDt" || this.focusOn === "LastUpdateDt")
        && !(this.focusOn === "drugCost") ? this.focusOn : (this.focusOn && (this.focusOn === "drugCost")) ? this.focusOn : "QuickCode";
            const elem: any = document.getElementById(this.focusOn);
            if (elem) {
                elem.focus();
            }
            if(this.manuFactDrug){
                this.manFactDataFrmSearchMMS = {
                    Name : this.manuFactDrug && this.manuFactDrug.Madeby ? this.manuFactDrug.Madeby :null
                }
                this.isTypedValue({value : this.manFactDataFrmSearchMMS});
            } else{
                this.manfactList = this.dropDownValues && this.dropDownValues.Organization ? this.dropDownValues.Organization : [];
            }
    }
    ngAfterViewInit() {
       // this.prevDrgClass = oldRxInfo.Drug.drugclass;
        this.focusOn = (this.focusOn && this.focusOn === "DiscontDt" || this.focusOn === "LastUpdateDt" || this.focusOn === "drugCost") ? this.focusOn : "QuickCode";
        if (this.focusOn && (this.focusOn === "drugCost")) {
            const element = document.getElementById("UnitPriceCost") as HTMLInputElement;
            if (element) {
                element.focus();
                element.select();
            }
        } else {
            const element: any = document.getElementById(this.focusOn);
            if (element) {
                element.focus();
            }
        }
    }
    focusToSave(event) {
        this._comnUtil.focusById("saveButton", event);
    }


    async checkIsBucketPriceExistsAndOpenPopUp(BuckID?: any, checkBuckList = true) {
        const buckID = BuckID || this.DrugBucketFG.value.Id;
        if (checkBuckList) {
            this._drugBuckUtil.patchDrugBucketInfo(this.drugBucketList, this.DrugBucketFG, buckID);
            this.patchDrugBuckPricing(this.DrugBucketFG.value.Id);
        }
        this._drugBuckUtil.patchSaveDrugBucketModal(this.DrugBucketFG.value, this.drugInfoFG.get("Drug"));
        if (!this.drugId) {
            return;
        }
        if (this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2) {
            this.compoundDrugIds = [];
            let compBucketcheck: any;
            this.compDrugList = []
            const payload = {
                PrescReFillId: 0,
                BucketId: this.DrugBucketFG.value.Id,
                DrugId: this.drugId
            }
            compBucketcheck = await this._bucketService.CompoundDrugBucketsInfo(payload).toPromise();
            compBucketcheck.find((i)=>{
                if(!i.IsPriceExist) {
                    this.compDrugList.push(i.DrugName)
                    this.compoundDrugIds.push(i.DrugId)
                }
            })
            this.isOriginalCompDrug = (this.compoundDrugIds.includes(this.drugId));
            if (this.compoundDrugIds && this.compoundDrugIds.length && this.isAdvancedMode())
                this._modalService.open(this.AddDrugToBucketConfirm, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
            else if (this.compoundDrugIds && this.compoundDrugIds.length) 
                this.openAddDrugToBuckOrNotForCompound(null,true)
        } else {
            if (!this._drugBuckUtil.checkIsDrugBuckPriceExist(this.DrugBucketFG.value) && this.isAdvancedMode()) {
                this._modalService.open(this.AddDrugToBucketConfirm, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
            } else if (!this._drugBuckUtil.checkIsDrugBuckPriceExist(this.DrugBucketFG.value)) { // make manage Inventory call ?
                this.drugPoManageInv();
            }
        }
    }

    openAddDrugToBuckOrNot(isOpen: boolean) {
        if (this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2) {
            this.openAddDrugToBuckOrNotForCompound(isOpen)
        } else {
            if(this.doAddDrugToBucket) {
                this.DrugBucketFG.controls.CostPack.patchValue(this.drugInfoFG.value["Drug"]["CostPack"]);
                this.DrugBucketFG.controls.UnitPriceCost.patchValue(this.drugInfoFG.value["Drug"]["UnitPriceCost"]);
            }
            if (!isOpen && this.doAddDrugToBucket) { // add drug flow do manage inv.
                this.drugPoManageInv();
                return;
            }
            isOpen ? this.checkDrugBucket(this.DrugBucketFG.value.Id) : this.getDrugBucketListNPatch(
                this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null , false, true); // else patch prev bucketInfo
        }
    }

    openAddDrugToBuckOrNotForCompound(isOpen?,isBasicMode?,close?) {
        this.compoundIng = this._drugUtils.createDrugDataModal();
        if (this.compoundDrugIds && this.compoundDrugIds.length) {
            this._commonServ.getDrugInfo(this.compoundDrugIds[0]).subscribe(async resp => {
                if (resp && !isBasicMode) {
                    await this._drugUtils.patchDrugInfo(resp, this.compoundIng, this.compoundDrugIds[0]);
                    if(this.doAddDrugToBucket) {
                        this.DrugBucketFG.controls.CostPack.patchValue(this.compoundIng?.value["Drug"]["CostPack"]);
                        this.DrugBucketFG.controls.UnitPriceCost.patchValue(this.compoundIng?.value["Drug"]["UnitPriceCost"]);
                    }
                    if (!isOpen && this.doAddDrugToBucket) {
                        this.drugPoManageInv();
                        return;
                    }
                    isOpen ? this.checkDrugBucket(this.DrugBucketFG.value.Id) : this.getDrugBucketListNPatch(
                        this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null , false, true);
                } if (resp && isBasicMode)
                    this.drugPoManageInv();
            })
        } 
        if (this.isOriginalCompDrug && this.compoundDrugIds && !this.compoundDrugIds.length) {
            if (!isBasicMode) {
                let bucketId = null;
                if (close === "NOTADDED")
                    bucketId = this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null;
                else if (close === "ADDED")
                    bucketId = this.DrugBucketFG.value.Id;
                this.getDrugBucketListNPatch(bucketId, false, true);
            } else {
                this.getDrugBucketListNPatch(this.DrugBucketFG && this.DrugBucketFG.value && this.DrugBucketFG.value.Id ? this.DrugBucketFG.value.Id : null, false, true);
            }
        } 
    }

    async drugPoManageInv(patchPrev?: boolean) {
        if (!this.drugId) {
            return;
        }
        let DrugPoInfo: any;
        let payload: any;
        const DrugPO: FormGroup = this._drugBuckUtil.createDrugPoFG(); // api call to get drugPO for the bucket.
        if (this.doAddDrugToBucket) { // ADD Drug flow
            payload = this._drugBuckUtil.patchDrugInvINFO(this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.DrugBucketFG.value, DrugPO.value, this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundIng ? this.compoundIng.value : this.drugInfoFG.value);
        } else { // Edit Drug Flow.
            DrugPoInfo = await this._invService.getDrugPoForBucket(this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.DrugBucketFG.value.Id).toPromise();
            if (DrugPoInfo) {
                this._drugBuckUtil.patchDrugBuckPO(DrugPO, DrugPoInfo);
                payload = this._drugBuckUtil.patchDrugInvINFO(this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.DrugBucketFG.value, DrugPO.value, this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundIng ? this.compoundIng.value : this.drugInfoFG.value);
            } else {
                payload = this._drugBuckUtil.patchDrugInvINFO(this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.DrugBucketFG.value, DrugPO.value, this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundIng ? this.compoundIng.value : this.drugInfoFG.value);
            }
        }
        const priceInfo: any = await this._drugpriceChk.getDrugBuckPrice(this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId, this.DrugBucketFG.value.Id).toPromise();
        if (priceInfo) {
            // patch into packcost, unitcost.
            payload.ManageInventory.CostPack = priceInfo.PackCost ? (priceInfo.PackCost).toFixed(2) : payload.ManageInventory.CostPack;
            payload.ManageInventory.UnitPriceCost = priceInfo.UnitCost ? (priceInfo.UnitCost).toFixed(3) : payload.ManageInventory.UnitPriceCost;
        }
        const resp = await this._invService.drugBuckManagInv(payload).toPromise();
        if (this.drugInfoFG.value["Drug"]["DrugTypeId"] !== 2) {
            if (resp) {
                this.getDrugBucketListNPatch(this.DrugBucketFG && this.DrugBucketFG.value && this.DrugBucketFG.value.Id ? this.DrugBucketFG.value.Id : null, false, true);
            } else {
                this.getDrugBucketListNPatch(this.DrugBucketFG && this.DrugBucketFG.value && this.DrugBucketFG.value.Id ? this.DrugBucketFG.value.Id : null, false, true);
            }
        }
        if (this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2) {
            this.compoundDrugIds.shift()
            this.openAddDrugToBuckOrNotForCompound(null,true)
        }
    }

    async getDrugBucketListNPatch(buckId?: any, checkAddDrugTOBuck = false, emitComplete = false) {
        const resp = await this._drugBuckUtil.getDrugBucketList(this.drugId || null);
        if (resp) {
            this._cdr.detectChanges();
            this.drugBucketList = resp;
            await this._drugBuckUtil.patchDrugBucketInfo(this.drugBucketList, this.DrugBucketFG, buckId);
            this.patchDrugBuckPricing(this.DrugBucketFG.value.Id);
            if (checkAddDrugTOBuck) { // check for add drug to bucket
                this.checkIsBucketPriceExistsAndOpenPopUp(this.DrugBucketFG.value.Id, false);
            } else { // patch drugbucket model in drug.
                this._drugBuckUtil.patchSaveDrugBucketModal(this.DrugBucketFG.value, this.drugInfoFG.get("Drug"));
            }
            if (this.bucketIdsTracker.length < 2) { // tracking bucket changed.
                this.bucketIdsTracker.push(this.DrugBucketFG.value.Id);
            } else {
                this.bucketIdsTracker.shift();
                this.bucketIdsTracker.push(this.DrugBucketFG.value.Id);
            }
            if (!checkAddDrugTOBuck && emitComplete && this.doAddDrugToBucket) { // add new drug logic
                this.EmitaddDrugToBuckComplete.emit("COMPLETE");
            }
        }
    }

    async getNdcForCompound() {
        this.compNDC = await this._drugUtils.getCompoundNDC();
        this.drugInfoFG.controls["Drug"].patchValue({
            DrugCode: this.compNDC
        });
    }

    async selectDrugType(inputVal) {
        if (inputVal && inputVal.Id === 2) {
            if (!this.drugId) {
                await this.getNdcForCompound();
                this.openCompound();
            }
        } else if (!this.drugId && this.compNDC && this.compNDC === this.drugInfoFG.value["Drug"]["DrugCode"]) {
            this.drugInfoFG.controls["Drug"].patchValue({DrugCode: null});
        }
    }

    openCompound() {
        this.EmitOpenCompndDrug.emit();
    }

    convertToFixedDecimals(control) {
        this._drugUtils.convertByMouseDown(control, this.drugInfoFG);
    }

    saveUnitValues(type) {
        const dataInfo: any = this.drugInfoFG.controls["Drug"];
        if (type === "awp") {
            if (((Number(dataInfo.controls["UnitPriceAWP3Digits"].value)).toFixed(3) !==
            (Number(dataInfo.controls["UnitPriceAWP"].value)).toFixed(3))) {
                this.drugInfoFG.controls["Drug"].patchValue({UnitPriceAWP: dataInfo.controls["UnitPriceAWP3Digits"].value
                ? (parseFloat(dataInfo.controls["UnitPriceAWP3Digits"].value)).toFixed(4) : "0.0000"});
            }
        } else {
            if (((Number(dataInfo.controls["UnitPriceCost3Digits"].value)).toFixed(4) !==
            (Number(dataInfo.controls["UnitPriceCost"].value)).toFixed(4))) {
                this.drugInfoFG.controls["Drug"].patchValue({UnitPriceCost: dataInfo.controls["UnitPriceCost3Digits"].value
                ? (parseFloat(dataInfo.controls["UnitPriceCost3Digits"].value)).toFixed(4) : "0.0000"});
            }
        }
    }

    setNumber(ControlName, number) {
        this._drugUtils.setNumberFormat(ControlName, number, this.drugInfoFG);
    }

    openSigCodeConfrim(content) {
        const fg: any = this.drugInfoFG.controls["Drug"];
        if (fg.controls["DefaultSigCode"].dirty && fg.value["DefaultSigCode"]) {
            fg.controls["DefaultSigCode"].markAsPristine();
            this._modalService.open(content, {centered: true, backdrop: false, windowClass: "large--content"});
        }
    }
    OnSigCodePopupClose(type) {
        const fg: any = this.drugInfoFG.controls["Drug"];
        if (type === "DefaultSigCode") {
            fg.controls["DefaultSigCode"].markAsDirty();
        }
        setTimeout(() => {
            if (document.getElementById(type)) {
                document.getElementById(type).focus();
            }
        }, 0);
    }
    shortcutKeyClicked(e){
            if (this.drugInfoFG.valid && e?.target?.value !== this.defaultSig && e.altKey) {
                this.openSigCodeConfrim(this.sigCode)
                this.defaultSig=e?.target?.value
            }   
    }

    openCopyNDCPopUp(content, type) {
        this.copyNDCTo = type;
        this.modelRef = this._modalService.open(content, {centered: true, keyboard: false, backdrop: false, size: "lg"});
        this.isDrugSearchFocused = false;
        this._cdr.detectChanges();
        this.isDrugSearchFocused = true;
    }

    isFocusedOutofSearch() {
        this.isDrugSearchFocused = false;
    }

    copyNDC(event) {
        if (this.copyNDCTo && event && event !== "") {
                const fg: any = this.drugInfoFG.controls["Drug"];
                if (this.copyNDCTo !== "ReplaceNDC" || (this.copyNDCTo === "ReplaceNDC" &&
                 this.drugInfoFG.value["Drug"]["Therapeutic"] === event.therapeutic &&
                 this.drugInfoFG.value["Drug"]["TherapeuticCode"] === event.therapeuticcode)) {
                fg.controls[this.copyNDCTo].patchValue(event.drugcode);
                } else {
                    this._altSvc.warning("Only those drugs can be selected , which belongs to same category.");
                }
                if (this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                }
        }
    }

    patchDrugBuckPricing (buckId?: any) {
        this._drugBuckUtil.patchDrugBucketPricing(this.drugInfoFG, this.drugBucketList, buckId, this.doAddDrugToBucket);
    }

    isAdvancedMode(): boolean {
        return +this._drugBuckUtil.isAdvancedMode() ? true : false;
    }

    checkDrugBucket(BuckID?: any) {
            if (BuckID) {
                this.openAddDrugToBuck();
            } else { // patch bucket info and  pricing info.
                this.getDrugBucketListNPatch(this.DrugBucketFG && this.DrugBucketFG.value && this.DrugBucketFG.value.Id ? this.DrugBucketFG.value.Id : null, false, true);
            }
    }

    openAddDrugToBuck() {
        if (!this.drugId) {
            return;
        }
        const modelRef = this._modalService.open( AddDrugToBucketComponent,
            {backdrop: false, size: "lg", windowClass: "modal-md modal-center-to-page", keyboard: false});
        modelRef.componentInstance.DrugFG = this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundIng ? this.compoundIng : this.drugInfoFG;
        modelRef.componentInstance.DrugBuckFG = this.DrugBucketFG;
        modelRef.componentInstance.DrugId = this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.compoundDrugIds && this.compoundDrugIds.length ? this.compoundDrugIds[0] : this.drugId;
        modelRef.componentInstance.prefVendorList = this.dropDownValues && this.dropDownValues.Vendor ?
        this.dropDownValues.Vendor : [];
        // modelRef.componentInstance.DrugInfo = this.drugInfo || null;
        modelRef.componentInstance.DrugBucketInfo = this.DrugBucketFG.value;
        modelRef.componentInstance.BucketId = this.DrugBucketFG.value.Id || null;
        modelRef.componentInstance.IsFromAddDrugScreen = this.doAddDrugToBucket;
        modelRef.componentInstance.emitAddDrugToBuckClose.pipe(takeUntil(this.unsubscribe$)).subscribe(close => {
            modelRef.close();
            let bucketId = null;
            if ( this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2 && this.drugId == this.compoundDrugIds[0]) 
                this.isOriginalDrugAddToBucket = close
            if (close === "NOTADDED") {
                if (this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2) {
                    this.compoundDrugIds.shift()
                    this.openAddDrugToBuckOrNotForCompound(true, false, this.isOriginalDrugAddToBucket)
                } else {
                    bucketId = this.bucketIdsTracker.length ? this.bucketIdsTracker[0] : null;
                }
                if (this.doAddDrugToBucket) { // add drug flow do manage inv.
                    this.drugPoManageInv();
                    return;
                }
            } else if (close === "ADDED") {
                if (this.drugInfoFG.value["Drug"]["DrugTypeId"] === 2) {
                    this.compoundDrugIds.shift()
                    this.openAddDrugToBuckOrNotForCompound(true, false, this.isOriginalDrugAddToBucket)
                } else {
                    bucketId = this.DrugBucketFG.value.Id;
                }
            } 
            if (this.drugInfoFG.value["Drug"]["DrugTypeId"] !== 2) {
                this._cdr.detectChanges();
                this.getDrugBucketListNPatch(bucketId, false, true);
                // after closing the add drug to bucket popup update bucketlist info and patch drugbucketinfo - pricing
            }
        });
    }
    ngOnDestroy(): void {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    isTypedValue(Value) {
        let data: any;
        let cnt = this.countFG.value["count"];
        const fg: any = this.drugInfoFG.controls["Drug"];
        if (Value && Value.value && Value.value !== "") {
            this.manfactList = [];
            if (!Value.value["Id"]) {
                data = {
                    ContPerson: null,
                    Id: "manf"+""+cnt,
                    CreatedDtTm: null,
                    InsurerCode: null,
                    Name: Value.value["Name"],
                    IsActive: false,
                    IsDeleted: false,
                    ModifiedDtTm: null,
                    PrimeAddsId: null,
                    StateName: null,
                    SwitchCD: null,
                    TenantId: null,
                    WebURL: null,
                };
                const idExist = this.dropDownValues?.Organization?.filter((item: any) => {
                    return item.Name === data.Name;
                });
                if (idExist && !idExist.length) {
                    this.dropDownValues?.Organization?.push(data);
                }
                cnt++;
                this.countFG.controls["count"].patchValue(cnt);
            }
            this._cdr.detectChanges();
                this.manfactList = this.dropDownValues?.Organization;
                fg.controls["ManufactId"].patchValue(
                    Value["value"]["Id"] ? Value["value"]["Id"] : data.Id);
                fg.controls["ManufactName"].patchValue(Value.value["Name"]);
        }
    }

    focusOutFromGroup() {
        const fg: any = this.drugInfoFG.controls["Drug"];
        if (document.getElementById("DrugTypeId") && fg && fg.controls && fg.controls["ManufactId"].value) {
            const DrugTypeFocus = document.getElementById("DrugTypeId").getElementsByTagName("input")[0];
            DrugTypeFocus.focus()
        }
    }
    displayMsg(){
        const shift = this._cmnSvc.getCtrlorNonctrlChange(this.prevDrgClass, this.drugInfoFG.value["Drug"]["DrugClass"]);
        if(shift){
            this._modalService.open(this.drugclasschange, {centered: true, backdrop: false, keyboard: false, windowClass: "large--content"});
        }
    }
    setRefRemainderValue() {
        if (this.drugFlow && this.drugFlow === "Add") {
            this.drugInfoFG.controls["Drug"].patchValue({
                IsIncInRefilRem: this.drugInfoFG.value["Drug"]["DrugClass"] !== 2 ?  true : false
            });
        }
    }
    async checkPrefDrug() {
        if (this.drugInfoFG.value["Drug"]["IsPreferred"]) {
            let DrugFromName;
            this.DrugList = [];
            this.dropDownValues.FDA_Form.map((id: any) => {
            if (id.FDAFormID === this.drugInfoFG.value["Drug"]["DrugFormId"]) {
                DrugFromName = id.FormName;
            }
            });
            const PrefDrugStng = this._cmnSvc.getSetttingValue(
            "DrugSettings", "ONE_PREF_DRUG_PER_CLASS");
            if (PrefDrugStng === "1" && this.drugInfoFG.controls["Drug"].value && this.drugInfoFG.value["Drug"]["IsPreferred"]) {
                const EquList = await this._drugSvc.getEquivalentDrug(this.drugId, this.drugInfoFG.value["Drug"]["drugcode"], false, null, false,
                     null, false, this.drugInfoFG.value["Drug"]["Therapeutic"], this.drugInfoFG.value["Drug"]["TherapeuticCode"]).toPromise();
                const PrefList = EquList?.length ? EquList.filter((item: any) => {
                    return item.ispreferred;
                }) : [];
                if (PrefList && PrefList.length > 0) {
                    PrefList.map((item) => {
                        if (item && item.ispreferred) {
                            const name: any =
                                (item.drugname
                                    ? item.drugname.trim().toUpperCase()
                                    : "") +
                                " " +
                                (item.strength
                                    ? item.strength.trim().toUpperCase()
                                    : "") +
                                " " +
                                (item.drugformname
                                    ? item.drugformname.trim().toUpperCase()
                                    : "") +
                                " - " +
                                (item.drugcode ? this._formatsUtil.getNDCFormat(item.drugcode).toUpperCase(): null);
                            if (name) {
                                this.DrugList.push(name);
                            }
                        }
                    });
                    this.selectedDrugItem =
                        (this.drugInfoFG.value["Drug"] && this.drugInfoFG.value["Drug"]["Name"]
                        ? this.drugInfoFG.value["Drug"]["Name"].trim().toUpperCase()
                        : "") +
                     " " +
                    (this.drugInfoFG.value["Drug"] && this.drugInfoFG.value["Drug"]["Strength"]
                    ? this.drugInfoFG.value["Drug"]["Strength"].trim().toUpperCase()
                    : "") +
                    " " +
                    (DrugFromName
                    ? DrugFromName.trim().toUpperCase()
                    : "") +
                    " - " +
                    (this.drugInfoFG.value["Drug"] && this.drugInfoFG.value["Drug"]["DrugCode"]
                    ? this._formatsUtil.getNDCFormat(this.drugInfoFG.value["Drug"]["DrugCode"], !((this.drugInfoFG.value["Drug"]["DrugCode"]).includes("-"))).toUpperCase(): null);
                    this.modalRefIsPref = this._modalService.open(this.MarkAsPrefDrug, {
                    centered: true,
                    backdrop: false,
                    keyboard: false,
                    windowClass: "large--content",
                    });
                }
            }
        }    
    }

    async continueMarkPref() {
        if (this.modalRefIsPref) {
            this.modalRefIsPref.close();
            this.modalRefIsPref = null;
        }
        await this._drugSvc.getUpdatePrefDrug(this.drugInfoFG.controls["Drug"].value).pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if (resp && resp.length > 0) {
                    resp.map((data: any) => {
                        if (this.drugId === data.DrugId) {
                            this.drugInfoFG.controls["Drug"].patchValue({IsPreferred : data.IsPreferred});
                        }
                    });
                this.selectedDrugItem = null;
            }
        });
    }
    cancelMarkPref() {
        if (this.modalRefIsPref) {
            this.modalRefIsPref.close();
            this.modalRefIsPref = null;
        }
        
        this.drugInfoFG.controls["Drug"].patchValue({IsPreferred : false});
        this._cdr.detectChanges();
            const element = document.getElementById("IsPreferredf2") as HTMLInputElement;
            if (element) {
                element.focus();
            }
        
    }

    closeDrugsearchPopup(event) {
        if(event) {
            if (this.modelRef) {
                this.modelRef.close();
                this.modelRef = null;
            }
        }
    }
    openDiagList() {
        this.modelRefDiag = this._modalService.open(this.diagList, {
            backdrop: false,
            size: "lg",
            keyboard: false,
            windowClass: "modal-center-to-page"
        });
    }
    saveandCloseDiagList(value) {
        if (this.modelRefDiag) {
            this.modelRefDiag.close();
        }
        this.drugInfoFG.controls["diagnosisInfo"].patchValue({ DIAGCODE: value.Code });
    }
}
