import { DateTime } from "@grapecity/wijmo";

export class RxTransferData {
    Rxtransfer: RxTransfer;
    Phone: RxPhone[] = [];
}

export class RxTransfer {
    PatientName: string = null;
    PatientAddress: string = null;
    InsurerCode: string = null;
    AWP: number = null;
    Aref: number = null;
    PacketSize: number = null;
    TotalQty: number = null;
    Manifacturer: string =null;
    Gender: string = null;
    DrugDetail: string = null;
    DateOfBirth: DateTime;
    NDC: string = null;
    Sig: string = null;
    Refillno: number = null;
    RxStatus: number = null;
    PrescriberName: string = null;
    Licencenumber: string = null;
    DEA: string = null;
    Fax: string = null;
    NPINum: string = null;
    Qty: number = null;
    DispQty: number = null;
    Days: number = null;
    Refillsleft: number = null;
    QtyRem: number = null;
    OriginalRxDate: Date = null;
    RxFillDate: Date = null;
    function: boolean = null;
    SigCode: string = null;
    SigDescription: string = null;
    RxStatusId: number = null;
    StatusId: number = null;
    FillRemainingQty: number = null;
    FillNo: number = null;
    FillDispQty: number = null;
    PrescId: any;
    PartialFillQty: any = null;
    TotalRefillsQty: any = null;
    PartialFillsList: any = [];
    RefillsList: any = [];
    RefillsTransferredList: any = null;
    RefillsCount: any;
    RxNum: any;
}


export class RxPhone {
    Phone: string = null;
}
export class Pharmacy {
    AddressLine1: string = null;
    CityName: string = null;
    DEANum: string = null;
    OrgID: number = null;
    PharmName: string = null;
    PharmId: number = null;
    StateName: string = null;
    ZipCode: string = null;
    NPINum: string = null;
    Remarks: string = null;
    CityId: number = null;
    StateId: number = null;
    OrgAddId: number = null;
    CountryId: number = null;
    Extension: string = null;
    FullAddress: string = null;
    NCPDPID:number = null;
}
export class PharmacyData {
    TransferPharmacy: Pharmacy;
    Phone: Pharmphone[] = [];
}
export class Pharmphone {
    PhoneId: number = null;
    PhoneCatId: number = null;
    AreaCode: string = null;
    Phone: string = null;
    OPHId: number = null;
    OPHOrgID: number = null;
}
export class PharmacyDatas {
pharmNames: PharmacyNames[] = null;
    pharmacistNames: PharmacistNames[] = null;
}

export class PharmacyNames {
    PharmacyId: number = null;
    PharmacyName: string = null;
}

export class PharmacistNames {
    PharmacistId: number = null;
    PharmacistName: string = null;
    PharmacistFullName: string = null;
}

export class TransmitSearch {
    rxId: number = null;
    PrescRefillId: number = null;
    patientId: number = null;
    TransType: string = null;
    Ins_Code: string = null;
    BillAs: string = null;
    PrescNum: any = null;
    Trans_Result: any = null;
}

export class XferInfoEdit {
    rxTransferOut: XferOutInfo = null;
    rxTransferIn: XferInInfo = null;
    CheckInOrOut: boolean = null;
}

export class XferOutInfo {
    Id: number = null;
    RxId: number = null;
    XferPharmId: number = null;
    OtherPharmacistNm: string = null;
    PharmacistId: number = null;
    TransferDtTm: Date = null;
    Remarks: string = null;
    Refills: number = null;
    RefillsTransferred: number = null;
    IsFiledRx: boolean = null;
    ReFillNum: number = null;
    IsRefillTransferred: boolean = null;
    OrgLastRxFillDtTm: Date = null;
    OrgRxDtTm: Date = null;
    OrdQty: any = null;
    TotalQty: any = null;
}

export class XferInInfo {
    Id: number = null;
    RxId: number = null;
    XferPharmId: number = null;
    PharmacistId: number = null;
    TransferDtTm: Date = null;
    Remarks: string = null;
    Refills: number = null;
    IsFiledRx: boolean = null;
    ReFillNum: number = null;
    OrgLastRxFillDtTm: Date = null;
    OrgRxDtTm: Date = null;
    OrdQty: any = null;
    OrgRxNum: any = null;
    OrgPharmacistNm: string = null;
    RefillsReceived: number = null;
}

export class XferInfoDet {
    Id: number = null;
    RxId: number = null;
    XferPharmId: number = null;
    PharmacistId: number = null;
    TransferDtTm: Date = null;
    Remarks: string = null;
    Refills: number = null;
    RefillsTransferred: number = null;
    IsFiledRx: boolean = null;
    ReFillNum: number = null;
    IsRefillTransferred: boolean = null;
    OrgLastRxFillDtTm: Date = null;
    OrgRxDtTm: Date = null;
    OrdQty: any = null;
    OrgRxNum: any = null;
    OrgPharmacistNm: string = null;
    RefillsReceived: number = null;
    OtherPharmacistNm: string = null;
    prescnumid: any = null;
    PartialFillNo: number = null;
    PartialFillQty: number = null;
    RefillsTransferredList: any = null;
    PharmacistName: string = null;
    PrescRefillId: any = null;
    TotalQty: any = null;
    transferType: any = null;
    Notes: any = null;
}
