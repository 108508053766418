import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { City, OrganizationRequest, PaginationModel, State } from 'src/app/models';
import { CommonService, ErxService, FilesService, RxTransferService, UserService } from 'src/app/services';
import {
  PharmacyData,
  Pharmacy,
  PharmacyDatas,
  RxTransferData,
  Pharmphone
} from "./../../../models/rxTransfer.model";
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonUtil } from 'src/app/utils';
import { MsgConfig } from 'src/app/app.messages';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferPharmacyComponent } from '../transfer-pharmacy/transfer-pharmacy.component';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';
@Component({
  selector: 'app-pharmacy-suggest',
  templateUrl: './pharmacy-suggest.component.html',
  styleUrls: ['./pharmacy-suggest.component.scss']
})
export class PharmacySuggestComponent implements OnInit {
  unsubscribe$: Subject<void> = new Subject();
  @Output() selectedPharm = new EventEmitter();
  @Output() CloseEditPharmacy = new EventEmitter();
  msgConfig = MsgConfig;
  paginationModel: PaginationModel = new PaginationModel();
  PharmacyNames: PharmacyDatas = new PharmacyDatas();
  logPhaId: any;
  PharmacyNamesList: any;
  getPharmNanes: any;
  addPharFG: FormGroup;
  pharmcyDet: PharmacyData = new PharmacyData();
  public citySelected = null;
  rxTransferFG: FormGroup;
  public stateSelected = null;
  SuggestData: any = [];
  public searchResults: any[] = [];
  searchTerm : any = "";
  selectedPharmDeatails : any = "";
  debounceTime = 1000;
  searching = false;
  DataResult: any;
  isedit : boolean;
  newPharId: any;
  addPopUp = false;
  popupSearchTerm: any;
  phamrcySearchModel: any;
  @Input() set ResetThePhramacy(resetThePha: boolean) {
    if(resetThePha)
      this.inputClearedBySearchIcon();
  }
  hideFilterForm: boolean;
  faxFG: FormGroup;
  @Input() frmPatHis: any;
  @Input() isFrmPatExternHist : boolean;
  @ViewChild("searchBoxPharmacyType", { static: true }) searchBoxInsType: ElementRef;
  @ViewChild("patExternPhamracySuggest", { static: true }) patExternPhamracySuggest: ElementRef;
   pharmacies = [
    { PharmName: 'Pharmacy One', Fax: '123-456-7890', NPINum: '1111', Phone: '987-654-3210', AddressLine1: '123 Pharmacy St.' },
    { PharmName: 'Pharmacy Two', Fax: '234-567-8901', NPINum: '2222', Phone: '876-543-2109', AddressLine1: '456 Health Ave.' },
    { PharmName: 'Pharmacy Three', Fax: '345-678-9012', NPINum: '3333', Phone: '765-432-1098', AddressLine1: '789 Wellness Rd.' }
  ];
  constructor(public _commonUtil:CommonUtil,
             private _rxSer: RxTransferService,
             private _eRxService: ErxService,
             private _userService: UserService,
             private _modalService: NgbModal,
             private _fb: FormBuilder,
            public  _patientUtil: PatientInfoUtil,
             private _commonServ: CommonService) {
              this.addPharFG = this._fb.group({
                TransferPharmacies: this._fb.group(new Pharmacy()),
                phone1: null,
                phone2: null,
                Fax: null,
                Phone: this._fb.array([new Pharmphone()])
            });

              }

  ngOnInit(): void {
    if (localStorage.getItem("RestoreAddrData") && this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData")) && this.frmPatHis && this.frmPatHis["Type"] === "Out") {
      this.DataResult = JSON.parse(this._commonServ.DecryptData(localStorage.getItem("RestoreAddrData")));
      this.hideFilterForm = true;
      if(this.DataResult?.TransferPharmacy?.Fax)
          this.faxFG.patchValue({
              fax: this.DataResult?.TransferPharmacy?.Fax
          });
  }
  }

  inputFormatter = (result: any) => result?.PharmName & result.ServiceLevels ? result.PharmName : "";  // Display Pharmacy name


  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((term: any) => {
        const modelData = new OrganizationRequest();
        const microSvcUrlToken = sessionStorage.getItem("CmnMicroSvcToken") ? 
          JSON.parse(this._commonServ.DecryptData(sessionStorage.getItem("CmnMicroSvcToken"))) : '';
        if(term && term.startsWith('#')) {
          modelData.OrganizationAddress = term.replace(/#/g, "");;
        } else if(term && !isNaN(term) && term.trim() !== "") {
          modelData.OrganizationPrimaryPhoneNumber = term;
        } else  {
          modelData.OrganizationName = term;
        }
        modelData.OrganizationToken = microSvcUrlToken;
        return this._eRxService.toGetTranferPharListWithServiceAvail(modelData).pipe(
          tap(pharmacies => this.PharmacyNamesList = pharmacies)
        );
      }),
      map((pharmacies: any[]) => pharmacies.length ? pharmacies : [])
    );
  };
  reselectPharmacy() {
    this.inputClearedBySearchIcon();
    const pharmacyEle = document.getElementById("pharmacy-search");
    if(pharmacyEle) 
      pharmacyEle.focus();
  }

  selectedPharmacyDetails(pharmacy) {
    if(this.phamrcySearchModel){
      this.phamrcySearchModel.close();
      this.phamrcySearchModel = null;
    }
    this.searchTerm = pharmacy?.PharmName;
    this.selectedPharmDeatails = pharmacy;
      this.selectedPharm.emit(pharmacy);
  }
  inputClearedBySearchIcon() {
    this.searchTerm = "";
    this.selectedPharmDeatails = null;
    this.selectedPharm.emit(this.selectedPharmDeatails);
  }

  triggerPharmacySearch(term: string) {
    if (this.isFrmPatExternHist && term?.trim().length >= 3) {
      this.popupSearchTerm = term?.trim();
      this.searchPhamracy(() => {
      this.phamrcySearchModel = this._modalService.open(this.patExternPhamracySuggest, {
          backdrop: false,keyboard: false,size: "lg",windowClass: "medium-modal",centered : true });
      });
    }
  }
  
  searchPhamracy(callback?: Function) {
    const term = this.popupSearchTerm?.trim();
    if (term?.length >= 3) {
      const modelData = new OrganizationRequest();
      const microSvcUrlToken = sessionStorage.getItem("CmnMicroSvcToken")
        ? JSON.parse(this._commonServ.DecryptData(sessionStorage.getItem("CmnMicroSvcToken")))
        : '';
      if (term.startsWith('#')) modelData.OrganizationAddress = term.replace(/#/g, "");
      else if (!isNaN(term)) modelData.OrganizationPrimaryPhoneNumber = term;
      else modelData.OrganizationName = term;
      modelData.OrganizationToken = microSvcUrlToken;
      this._eRxService.toGetTranferPharListWithServiceAvail(modelData).subscribe((pharmacies) => {
        this.PharmacyNamesList = pharmacies;
        if (callback) callback();
      });
    }
  }
  
  clearInputFiled(){
    this.searchTerm = "";
    this.popupSearchTerm = "";
    this.PharmacyNamesList = null;
  }
    
  getPatientAddress(x) {
    if(x)
    {
      const fullAdrress = this._patientUtil.getPatientAddress({
        address1 :  x?.AddressLine1,
        address2 :   x?.AddressLine2,
        city : x?.CityName,
        state : x?.StateName,
        zipcode : x?.ZipCode,
      });
    return fullAdrress;
    }
  }
  openAddPopUp() {
    this.addPharFG.controls["TransferPharmacies"].reset();
    this.addPharFG.controls["TransferPharmacies"].markAsUntouched();
    this.addPharFG.controls["phone1"].reset();
    this.addPharFG.controls["phone1"].markAsUntouched();
    this.addPharFG.controls["phone2"].reset();
    this.addPharFG.controls["phone2"].markAsUntouched();
    this.addPharFG.controls["Fax"].reset();
    this.addPharFG.controls["Fax"].markAsUntouched();
    this.citySelected = null;
    this.stateSelected = null;
    this.isedit = false;
    this.addPopUp = true;
    this.openTransferPharmacy();
}
openTransferPharmacy() {
  const modalRef = this._modalService.open(TransferPharmacyComponent, {
      centered: true
  });
  modalRef.componentInstance.PharmacyFG = this.addPharFG;
  modalRef.componentInstance.IsEditMode = this.isedit;
  modalRef.result
      .then(res => {
          if (res.AddValue) {
              this.addNewPharResult(res.AddValue);
          }
          if (res.Editvalue) {
              this.editPharResult(res.Editvalue);
              this.CloseEditPharmacy.emit(false);
          }
          this.closeCommonPopUp(res.IsPopUpClosed);
      })
      .catch(error => error);
}
closeCommonPopUp(content) {
  this.addPopUp = content;
}

editPharResult(data) {
  if (data) {
      this.getPharmcyDetails();
  }
}

addNewPharResult(data) {
  this.newPharId = data;
  this.rxTransferFG.controls["XferPharmIdxId"].patchValue(this.newPharId);
  this.rxTransferFG.controls.XferPharmIdxId.markAsTouched();
  this.getPharmcyDetails();
}

getPharmcyDetails() {

}
  editPopUp() {
    this.citySelected = new City();
    this.pharmcyDet = this.DataResult ? this.DataResult : this.pharmcyDet;
    if (this.pharmcyDet.TransferPharmacy) {
        if (this.pharmcyDet.TransferPharmacy.CityId) {
            this.citySelected.Id = this.pharmcyDet.TransferPharmacy.CityId;
            this.citySelected.Name =  this.pharmcyDet.TransferPharmacy.CityName;
            this.citySelected.StateId = this.pharmcyDet.TransferPharmacy.StateId;
        }
        this.stateSelected = new State();
        if (this.pharmcyDet.TransferPharmacy.StateId) {
            this.stateSelected.Id = this.pharmcyDet.TransferPharmacy.StateId;
            this.stateSelected.Name =  this.pharmcyDet.TransferPharmacy.StateName;
        }
        if (this.pharmcyDet.TransferPharmacy.NPINum) {
            this.pharmcyDet.TransferPharmacy.NPINum = this.pharmcyDet.TransferPharmacy.NPINum;
            //  this.pharmcyDet.TransferPharmacy.NPINum = this.pharmcyDet.TransferPharmacy.NPINum.toString();
        }
    }
    for (const key in this.pharmcyDet) {
        if (key) {
            if (key === "TransferPharmacy" && this.pharmcyDet[key]) {
                this.addPharFG.controls["TransferPharmacies"].patchValue(
                    this.pharmcyDet[key]
                );
            }
            if (key === "Phone") {
                for (const keys in this.pharmcyDet[key] &&
                    this.pharmcyDet[key]) {
                    if (keys) {
                        if (keys === "0") {
                            this.addPharFG.controls["phone1"].patchValue(
                                this.pharmcyDet[key][keys]["Phone"]
                            );
                        } else if (keys === "1") {
                            this.addPharFG.controls["phone2"].patchValue(
                                this.pharmcyDet[key][keys]["Phone"]
                            );
                        } else if (keys === "2") {
                            this.addPharFG.controls["Fax"].patchValue(
                                this.pharmcyDet[key][keys]["Phone"]
                            );
                        }
                    }
                }
            }
        }
    }
    this.addPopUp = true;
    this.openTransferPharmacy();
}
  
}  