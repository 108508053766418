import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpcChatHistoryComponent } from './spc-chat-history/spc-chat-history.component';
import { spcCloudRoutingModule } from './spc-cloud-routing.module';
import { spcCloudComponent } from './spc-cloud/spc-cloud.component';
import { SpcUsersListComponent } from './spc-users-list/spc-users-list.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    spcCloudRoutingModule,
    FormsModule,
    SharedModule
  ],
  declarations: [spcCloudComponent, SpcUsersListComponent, SpcChatHistoryComponent],
  exports: [spcCloudComponent, SpcChatHistoryComponent]
})
export class spcCloudComponentModule { }
