<div class="exprx--common-block" *ngIf="!(transmissionType === 'TransReverse' || (transmitParams && transmitParams['TransType'] === 'B2')|| restrictRejectTrans)">
    <span *ngIf="transcRespStatus !== 'Rejected'">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                Payment Details
            </div>
        </div>
        <div class="eprx--block__content">
            <div class="row trasmit--rx">
                <div
                    class="col-lg-5 col-md-5 col-sm-5 col-xs-12 inline-flex border_right_1px_solid_white"
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" class="text-right">
                                    Paid
                                </th>
                                <th scope="col" class="text-right">
                                    Billed
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    Deductible/Copay
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.Deductible
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        0 | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="line_height_1">
                                    Ingredient Cost ({{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.PercDiff
                                    }})
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.IngredientCost
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.BillIngredient
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Prof.Fee Paid
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.DispensingFee
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.BillDispensingFee
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Other Fee Paid
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.OtherFeePaid
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.BilledOtherFeePaid
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Tax Paid
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.SalesTax
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.BilledSalesTaxPaid
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Other Amount
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.otherAmount
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.BillOtherAmount
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <!-- <tr>
                                <th scope="row">
                                    DIR Fee
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.nCPDPRecv
                                            ?.DIRFee
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        0
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr> -->
                            <tr *ngIf="transmResp?.nCPDPRecv?.OtherPayorAmountRecognized > 0">
                                <th scope="row" class="bagrnd-profit__Heading display_inline-flex">
                                    Voucher Amount Applied
                                </th>
                                <td class="text-right">
                                <span [ngClass]="transmResp?.nCPDPRecv?.OtherPayorAmountRecognized > 0 ? 'bagrnd-profit' : ''">
                                    {{
                                        transmResp?.nCPDPRecv?.OtherPayorAmountRecognized
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </span>
                                </td>
                                <td class="text-right">
                                    {{
                                        0 | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Total Price ({{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.BasisInfo ? transmResp
                                            ?.transmissionDetails
                                            ?.BasisInfo : 0.00
                                    }})
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.TotalAmount
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.BillTotalAmount
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="col-lg-2 col-md-2 col-sm-2 col-xs-12 inline-flex border_right_1px_solid_white"
                >
                    <div class="row">
                        <div class="col-xs-12 col-md-12 col-lg-12 min_height_50px">
                        </div>
                        <div class="col-xs-12 col-md-12 col-lg-12 min_height_50px">
                            <div *ngIf="transmResp?.transmissionDetails?.IngredientCost && transmResp?.transmissionDetails?.BillIngredient">
                                <span *ngIf="transmResp?.transmissionDetails?.IngredientCost > transmResp?.transmissionDetails?.BillIngredient">
                                    -
                                </span>
                                {{
                                    (transmResp?.transmissionDetails?.IngredientCost/
                                    transmResp?.transmissionDetails?.BillIngredient)| percent:'0.0-2'
                                }} OFF
                            </div>
                            <div *ngIf="!(transmResp?.transmissionDetails?.IngredientCost && transmResp?.transmissionDetails?.BillIngredient)">
                                {{
                                    transmResp
                                        ?.transmissionDetails
                                        ?.PercDiff
                                }}
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-12 col-lg-12 min_height_50px">
                        </div>
                        <div class="col-xs-12 col-md-12 col-lg-12 min_height_50px">
                            <div>
                                {{
                                    transmResp
                                        ?.transmissionDetails
                                        ?.BasisInfo
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-lg-3 col-md-3 col-sm-3 col-xs-12 inline-flex border_right_1px_solid_white"
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Ins</th>
                                <th scope="col" class="text-right">
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pay of prevRxPays">
                                <th scope="row">
                                    {{
                                        pay?.INS_CODE | uppercase
                                    }}
                                </th>
                                <td class="text-right">
                                    {{
                                        pay?.TOTAMTPAID
                                        | currency
                                        : "USD"
                                        : "symbol-narrow"
                                        : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr *ngIf="!(prevRxPays && prevRxPays.length) && (transmissionType === 'TransMessage')">
                                <th scope="row">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.InsType | uppercase
                                    }}
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.TotalAmount
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr *ngIf="!InsRecExistInPrevPay && (transmissionType !== 'TransMessage') &&
                                (transmissionType !== 'TransReverse') && (transmissionType !== 'transEditPrev')">
                                <th scope="row">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.InsType | uppercase
                                    }}
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.TotalAmount
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Voucher Amount<br><br>
                                    Applied
                                </th>
                                <td class="text-right">
                                <span [ngClass]="transmResp?.nCPDPRecv?.OtherPayorAmountRecognized > 0 ? 'bagrnd-profit' : ''">
                                    {{transmResp
                                            ?.transmissionDetails
                                            ?.VoucherAmnt
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Copay
                                </th>
                                <td class="text-right">
                                    {{
                                        transmResp
                                            ?.transmissionDetails
                                            ?.Deductible
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    Total
                                </th>
                                <td class="text-right">
                                    {{
                                        totalTransData | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row line_height_1">
                                    Cost (Rx)
                                </th>
                                <td class="text-right">
                                    {{
                                        rxCost | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <tr *ngIf="ProfitLossPriv">
                                <th scope="row line_height_1" *ngIf="profitLoss > 0" class="bagrnd-profit__Heading display_inline-flex">
                                    Profit
                                </th>
                                <th scope="row line_height_1" *ngIf="profitLoss < 0" class="bagrnd-loss display_inline-flex">
                                    Loss
                                </th>
                                <th scope="row line_height_1" *ngIf="profitLoss == 0">
                                    Profit/Loss
                                </th>
                                <td class="text-right">
                                    {{
                                        profitLoss
                                            | currency
                                                : "USD"
                                                : "symbol-narrow"
                                                : "1.2-2"
                                    }}
                                </td>
                            </tr>
                            <!-- <tr *ngIf="hasViewProfPrivs">
                                <th scope="row">
                                    Profit
                                </th>
                                <td
                                    class="text-right"
                                >
                                <span *ngIf="(totalTransData - rxCost) > 0" class="bagrnd-profit">
                                        {{
                                            totalTransData - rxCost
                                                | currency
                                                    : "USD"
                                                    : "symbol-narrow"
                                                    : "1.2-2"
                                        }}
                                </span>
                                <span *ngIf="(totalTransData - rxCost) <= 0" class="bagrnd-loss">
                                        {{
                                            totalTransData - rxCost
                                                | currency
                                                    : "USD"
                                                    : "symbol-narrow"
                                                    : "1.2-2"
                                        }}
                                </span>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 inline-flex">
                    <div class="row">
                        <div class="col-xs-12 col-md-12 col-lg-12 min_height_50px">
                        </div>
                        <div class="col-xs-12 col-md-12 col-lg-12 min_height_130px">
                            <label> Other Info </label>
                            <div>
                                {{
                                    transmResp
                                        ?.transmissionDetails
                                        ?.OtherInfotTxt
                                }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </span>
    <span *ngIf="
        (transmResp?.transmissionDetails
            ?.ResponseStatus === 'R' ||
        transmResp?.transmissionDetails
            ?.ResponseStatus ===
            'TRANSMISSION ERROR')">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                Rejected Details
            </div>
        </div>
        <div
            class="eprx--block__content"
        >
            <div class="row trasmit--rx">
                <div
                    class="col-md-12 col-xs-12 color-red"
                >
                    <div *ngFor="let data of transmResp?.transmissionDetails?.RejectInfo
                        | StringSepWithEnterOnCamCase">
                        {{data}}
                    </div>
                </div>
            </div>
        </div>
    </span>
</div>
<span *ngIf="!(transmissionType === 'TransReverse' || (transmitParams && transmitParams['TransType'] === 'B2') || restrictRejectTrans)">
    <app-trans-dur [transDetails]="transmResp"></app-trans-dur>
</span>
<div class="exprx--common-block">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Messages
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="row min-height_4rem max-height_7rem overflow_scroll">
            <div class="col-12">
                <div class="row margin_left_auto">
                    <div>
                        {{
                            (transmResp && transmResp?.nCPDPRecv && transmResp?.nCPDPRecv?.MessageFQ) ? transmResp?.nCPDPRecv?.MessageFQ.toString()
                            : !(transmResp?.transmissionDetails?.ResponseStatus === 'R' ||
                        transmResp?.transmissionDetails?.ResponseStatus ==='TRANSMISSION ERROR') ? transmResp?.ClaimProcess?.objncpdRecv?.MessageFQ ?
                        transmResp?.ClaimProcess?.objncpdRecv?.MessageFQ.toString() : "" : ""
                        }}
                        <span *ngIf="(transmResp?.nCPDPRecv?.MessageFQ || transmResp?.ClaimProcess?.objncpdRecv?.MessageFQ)">
                            <br></span>
                        <span *ngIf="!(transmissionType === 'TransReverse' || (transmitParams && transmitParams['TransType'] === 'B2')); else elseblock">
                        {{
                            (transmResp && transmResp?.nCPDPRecv && transmResp?.nCPDPRecv?.MessageF4) ? transmResp?.nCPDPRecv?.MessageF4.toString()
                            : !(transmResp?.transmissionDetails?.ResponseStatus === 'R' ||
                        transmResp?.transmissionDetails?.ResponseStatus ==='TRANSMISSION ERROR') ? (transmResp?.ClaimProcess?.objncpdRecv?.MessageF4 ?
                        transmResp?.ClaimProcess?.objncpdRecv?.MessageF4.toString() : "") : ""
                        }}
                        </span><br/>
                        <ng-template #elseblock>
                            {{
                                (transmResp && transmResp?.nCPDPRecv && transmResp?.nCPDPRecv?.MessageF4) ? transmResp?.nCPDPRecv?.MessageF4.toString()
                                : !(transmResp?.transmissionDetails?.ResponseStatus === 'R' ||
                            transmResp?.transmissionDetails?.ResponseStatus ==='TRANSMISSION ERROR') ? transmResp?.ClaimProcess?.objncpdRecv?.MessageF4 ?
                            transmResp?.ClaimProcess?.objncpdRecv?.MessageF4.toString() : "" : ""
                            }}
                        </ng-template>
                    </div>
                    <div *ngIf="transmResp?.transmissionDetails?.M3PStatus == 1" class="width_100per">
                        <span class="label--data"><b>{{transmResp?.transmissionDetails?.PatientName}}</b></span> may be eligible for the Medicare Prescription Payment Plan (M3P) due to high drug costs. <label class="ltb-print"><a href="javascript:void(0)" class="min-width-0" (click)="printM3P()"><i title="Print Label" class="far fa-print actions"></i> PRINT LIKELY TO BENEFIT</a></label>
                    </div>
                </div>
                <div class="row margin_top_1_5rem" *ngIf="transmResp?.nYMCDResponse?.nYMCDF4ResponseModel">
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 trasmit--rx ">
                        <div>
                            <label class="min_width_13rem"> Recipient Medicaid# </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.RecipientMedicaid }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Recipient Anniv.Month</label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.RecipientAnnivMonth }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Recipient Assist.Catg </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.RecipientAssistCatg }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Office Number</label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.OfficeNumber }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 trasmit--rx">
                        <div>
                            <label class="min_width_13rem"> Recipient County Code </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.RecipientCountyCode }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Recipient YOB & Sex</label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.RecipientYOBSex }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Recertification Month </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.RecertificationMonth }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Date Of Service </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDF4ResponseModel?.DateOfService }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row margin_top_1_5rem" *ngIf="transmResp?.nYMCDResponse?.nYMCDResonseModel">
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 trasmit--rx">
                        <div>
                            <label class="min_width_13rem"> MEVS Response Code </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.MEVSResponseCode }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> MEVS UT/P&C Code</label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.MEVSUTPCCode }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> DVS Reason Code </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.DVSReasonCode }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Copay Code</label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.CopayCode }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Medicare Coverage </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.MedicareCoverage }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> 1st Ins.Code & Cov. </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.FstInsCodeCov }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> 2nd Ins.Code & Cov. </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.SecndInsCodeCov}}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> DVS Number </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.DVSNumber }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 trasmit--rx">
                        <div>
                            <label class="min_width_13rem"> Copay Met Date </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.CopayMetDate }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Additional Cov. Ind.</label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.AdditionalCovInd }}
                            </div>
                        </div>
                        <div>
                            <label class="min_width_13rem"> Restriction Info. </label>
                            <div class="label--data">
                                {{ transmResp?.nYMCDResponse?.nYMCDResonseModel?.RestrictionInfo }}
                            </div>
                        </div>
        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="exprx--common-block" *ngIf="patInsuInfoWJ">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Patient Insurance Information
        </div>
    </div>
    <div class="eprx--block__content">
        <wj-flex-grid [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="patInsuInfoWJ"
        [isReadOnly]="true" [columnPicker]="true">
        <wj-flex-grid-column [header]="'Order'" [width]="70" [binding]="'INSORD'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'PATIDQ'" [width]="70" [binding]="'PAYIDQ'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'BIN'" [width]="75" [binding]="'BN'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Process Control'" [width]="150" [binding]="'PN'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'PatientID'" [width]="150" [binding]="'ID'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Group#'" [width]="140" [binding]="'GP'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Person Code'" [width]="100" [binding]="'PC'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Phone#'" [width]="140" [binding]="'PH'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'RelationCD'" [width]="150" [binding]="'RELATIONCD'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'EffectiveDate'" [width]="100" [binding]="'EFFECTIVEDATE'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'TerminationDate'" [width]="150" [binding]="'TERMINATIONDATE'">
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'INSU CODE'" [width]="140" [binding]="'INSUCODE'">
        </wj-flex-grid-column>
    </wj-flex-grid>
    </div>
</div>
<div class="exprx--common-block" *ngIf="prefDrugInfoWJ">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Preferred Drug Information
        </div>
    </div>
    <div class="eprx--block__content">
        <wj-flex-grid [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="prefDrugInfoWJ"
            [isReadOnly]="true" [columnPicker]="true" class="max-height_8rem">
            <wj-flex-grid-column [binding]="actvHeader" [visible]="true" *ngFor="let actvHeader of prefDrugHeaders" [width]="
            (actvHeader === 'Cost' || actvHeader === 'AWP') ? 120 :
            (actvHeader === 'DrugName') ? 450 : (actvHeader === 'Manufacturer') ? 350 : '*'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                    <div>
                        <span *ngIf="!(actvHeader ==='Cost' || actvHeader ==='AWP' || actvHeader ==='NDC')">
                            {{item[actvHeader]}}
                        </span>
                        <span *ngIf="(actvHeader ==='Cost' || actvHeader ==='AWP')">
                            {{item[actvHeader] ? (item[actvHeader] | currency: "$":"symbol":"1.2-2") : "" }}
                        </span>
                        <span *ngIf="actvHeader ==='NDC'">
                            {{item[actvHeader] ? (item[actvHeader] | mask:"AAAA0-0000-00") : "" }}
                        </span>
                    </div>
                </ng-template>
            </wj-flex-grid-column>
        </wj-flex-grid>
    </div>
</div>
