import { ErxFilter, ErxProcess, ErxVersions } from "./../models/erx-filter.model";
import { constant } from "../app.constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class ErxService {
    constructor(private _http: HttpClient) {}

    getErxActionList(filteredData: any): Observable<ErxFilter> {
        return this._http.post<ErxFilter>(
            constant.POST_eRx_Search,
            filteredData,reqOptns
        );
    }

    deleteErx(erxopTransactionId: any, erxId1: any): Observable<any> {
        return this._http.delete<boolean>(
            constant.DELETE_eRx.replace(
                "{eropTransactionId}", "" + erxopTransactionId
            ).replace("{erxId}", "" + erxId1)
        );
    }

    getReadyErx(Id: any, Version: any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.GET_eRx_ReadyToProcess.replace("{eRxId}", "" + Id);
        } else {
            url =  constant.GET_eRx_ReadyToProcess.replace("{eRxId}", "" + Id);
        }
        return this._http.get<any>(url);
    }
    getReadyBenfitCheck(obj: any, Version: any): Observable<any> {
        return this._http.post(
            constant.POST_eRx_BenefitCheck.replace("{eRxVersion}", "" + Version), obj);
    }
    getAwaitErx(Id: any, Version: any, eRxOpId, patientID?): Observable<any> {
        let url;
        if (Version == ErxVersions.LatestNewerVersion) 
            url = ErxVersions.LatestNewerVersion + "/" + constant.GET_eRx_AwaitingResponse.replace("{eRxId}", "" + Id).replace("{ERxOpId}", "" + eRxOpId).replace("{patientID}", "" + patientID);
        else if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.GET_eRx_AwaitingResponse.replace("{eRxId}", "" + Id);
        } else {
            url =  constant.GET_eRx_AwaitingResponse.replace("{eRxId}", "" + Id);
        }
        return this._http.get<any>(url);
    }

    getProcesErx(Id: any, Version: any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion + "/" + constant.GET_eRx_Process.replace("{eRxId}", "" + Id);
        } else {
            url =  constant.GET_eRx_Process.replace("{eRxId}", "" + Id);
        }
        return this._http.get<any>(url);
    }

    geteRxProcessDetails(eRxProcessInfo: ErxProcess, Version: any): Observable<ErxProcess> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion+"/" + constant.POST_eRx_ProcessDetails;
        } else {
            url =  constant.POST_eRx_ProcessDetails;
        }
        return this._http.post<ErxProcess>(url, eRxProcessInfo );
    }

    getProcessedRxIDsByeRxID(erxID: number) {
        return this._http.get<any>(
            constant.GET_eRx_ProcessIds.replace("{eRxId}", "" + erxID)
        );
    }

    getProcessERxMessageLink(erxData: any, eRxVersion: any) {
        return this._http.post(
            constant.POST_eRx_ProcessLink.replace("{eRxVersion}", "" + eRxVersion), erxData);
    }
    getProcessERxMessageUnLink(erxData: any, eRxVersion: any) {
        return this._http.post(
            constant.POST_eRx_ProcessUNLink.replace("{eRxVersion}", "" + eRxVersion), erxData);
    }
    getProcessRecallERX(erxData: any) {
        return this._http.put<any>( constant.PUT_eRx_Recall, erxData);
    }

    generateRxToProcess(erxData: any, Version: any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion+"/" + constant.POST_ERX_RxGeneration;
        } else {
            url =  constant.POST_ERX_RxGeneration;
        }
        return this._http.post<any>(url, erxData);
    }
    postErxRequest(_version, erxData) {
          return this._http.post(constant.POST_NewRxRequest, erxData);
    }
    getSpiList(erxData) {
        return this._http.get(
            constant.GET_SPI.replace("{version}", "" + erxData.version).replace("{prescriberId}", "" + erxData.prescriberId));
    }
    UpdateErx(_erxId: any, _erxOptransId: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_eRx_UpdateRx,{}
        );
    }

    getRxRefillsByPrescnum(prescNum: any) {
        return this._http.get<any>(
            constant.GET_eRx_RefillsByPrescnum.replace("{prescNum}", "" + prescNum)
        );
    }

    acceptOrRejectCancelRx(dataToPost: any, Version: any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion+"/" + constant.PUT_eRx_AcceptorRejectCancelRx;
        } else {
            url =  constant.PUT_eRx_AcceptorRejectCancelRx;
        }
        return this._http.put<any>(url, dataToPost);
    }

    reverseCancelRx(dataToPost: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_eRx_ReverseCancelRxs, dataToPost
        );
    }

    deleteCancelRx(dataToPost: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_eRx_DeleteCancelRxs, dataToPost
        );
    }

    updatePatientDetails(dataToPost: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_eRx_UpdatePatientDetails, dataToPost
        );
    }

    updatePrescriberDetails(dataToPost: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_eRx_UpdatePrescriberDetails, dataToPost
        );
    }

    updateErxProcessStatus(Id: any, status: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_eRx_Process.replace("{eRxOpId}", "" + Id).replace("{status}", "" + status), {}
        );
    }


	getErxStatus(Id: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_eRx_Status.replace("{eRxOpId}", "" + Id)
        );
    }

    getErxChangeRespStatus(Id: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_changeResponseStatus.replace("{eRxOpId}", "" + Id)
        );
    }


    getCompoundDrugs(payload: any,Version:any): Observable<any> {
        let url;
        if (Version == ErxVersions.NewerVersion) {
            url = ErxVersions.NewerVersion+"/" + constant.POST_eRx_CompoundDrugList;
        } else {
            url =  constant.POST_eRx_CompoundDrugList;
        }
        return this._http.post<any>(url,payload );
    }

    getErxCompoundIngredients(erxId: number): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.get(
            constant.GET_ErxCompoundIngredientsReport.replace("{erxId}", "" + erxId),
            { headers: headers, responseType: "blob" }
        );
    }
    getSPIInformation(prescriberId){
        return this._http.get(
            constant.GET_PrescriberSpiData.replace("{prescriberId}", "" + prescriberId),
        );
    }

    getErxMultiReport(payload: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_Reports_MultiErxReport,payload,
            { headers: headers, responseType: "blob" }
        );
    }
    getAnnoationReport(payload: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_AnnotationPrint,payload,
            { headers: headers, responseType: "blob" }
        );
    }
   //Transfer in req
    sendTransferInReq(payload, version){
        return this._http.post(
            constant.Transfer_In_Req_Send.replace("{eRxVersion}", "" + version), payload
        );
    }
    //Resend the Transfer in req
    reSendTransferInReq(payload){
        return this._http.post(
            constant.Resend_Transfer_In_Req_Send, payload);
    }

    //Resend the Transfer in req
    pharmacySuggest(payload){
        return this._http.post(constant.Get_Transfer_Pharmacy_Info, payload);
    }

     //To Check ERx Service Level
     checkERxServiceLevel(payload){
        return this._http.post(constant.Check_ERxService_Level, payload);
    }


     //To Check ERx Service Level
     toGetTranferPharListWithServiceAvail(payload){
        return this._http.post(constant.Get_Transfer_Pharmacy_Info, payload);
    }

    // To get the Transferable and informational Rx's
    getRxsForTransferInReq(payload){
        return this._http.post(constant.GetRxs_ForTransfer_In_Req, payload);
    }

    // To process the Transfer In request
    setRxsForTransferInReqProcess(payload){
        return this._http.post(constant.Transfer_In_Req_Process, payload);
    }

}
