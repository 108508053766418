
import {of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';
import { constant } from "./../app.constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    SearchInsurance,
    InsuranceInfo,
    InsuranceSettings,
    InsuTransOther,
    InsuBillFileSetting
} from "../models";
const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class InsuranceService {
    dropdownData$: Observable<any>;
    _dropdownData$: BehaviorSubject<any>;

    _editData$: BehaviorSubject<any>;
    editData$: Observable<any>;

    _insuSettings$: BehaviorSubject<any>;
    insuSettings$: Observable<any>;
    _insuSettingsOld$: BehaviorSubject<any>;
    insuSettingsOld$: Observable<any>;
    _insReqChngStat$: BehaviorSubject<any>;
    insReqChngStat$: Observable<any>;
    _insPlanId$: BehaviorSubject<any>;
    constructor(private _http: HttpClient) {
        this._editData$ = new BehaviorSubject({}) as BehaviorSubject<any>;
        this._dropdownData$ = new BehaviorSubject({}) as BehaviorSubject<any>;
        this.dropdownData$ = this._dropdownData$.asObservable();

        this._insuSettings$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.insuSettings$ = this._insuSettings$.asObservable();
        this._insuSettingsOld$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.insuSettingsOld$ = this._insuSettingsOld$.asObservable();
        this._insReqChngStat$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.insReqChngStat$ = this._insReqChngStat$.asObservable();
    }

    getInsInfogroup(data: any) {
        this._editData$.next(data);
    }

    getEditableInfo(): Observable<any> {
        return this._editData$.asObservable();
    }

    getInsuranceValues(addHdrs?:boolean) {
        if (addHdrs) {
            return this._http
            .get<any>(constant.GET_Insurance_DropDownData, reqOptns)
            .subscribe(resp => this._dropdownData$.next(resp));
        } else {
            return this._http
            .get<any>(constant.GET_Insurance_DropDownData)
            .subscribe(resp => this._dropdownData$.next(resp));
        }
    }

    getInsuranceDropdowns() {
        return this._http.get<any>(constant.GET_Insurance_DropDownData);
    }

    getInsuranceData(data: any): Observable<SearchInsurance> {
        return this._http.post<SearchInsurance>(
            constant.POST_Insurance_Search,
            data, reqOptns
        );
    }

    addInsuranceInfo(insuranceInfo: InsuranceInfo): Observable<any> {
        return this._http.post<any>(constant.POST_Insurance, insuranceInfo);
    }

    deleteInsurance(Id: any): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Insurance.replace("{insuranceId}", "" + Id)
        );
    }
    checkInsuranceAttach(Id: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_Attach.replace("{insuranceId}", "" + Id)
        );
    }

    getEditInsuranceInformation(Id: any): Observable<InsuranceInfo> {
        return this._http.get<InsuranceInfo>(
            constant.GET_Insurance.replace("{insuranceId}", "" + Id)
        );
    }

    updateInsurance(data: any): Observable<number> {
        return this._http.put<number>(constant.PUT_Insurnace, data);
    }

    getEmptyObservable(): Observable<any> {
        return observableOf([]);
    }

    getInsSettingsInfo(Id: any): Observable<InsuranceSettings> {
        return this._http.get<InsuranceSettings>(
            constant.GET_Insurnace_Settings_byId.replace(
                "{insuPlanId}",
                "" + Id
            )
        );
    }

    async getInsSettingsInfoForRx(Id: any) {

        const resp = await this._http.get<InsuranceSettings>(
            constant.GET_Insurnace_Settings_byId.replace("{insuPlanId}", "" + Id)).toPromise();
        this._insuSettings$.next(resp);
        return resp;
    }

    saveInsuSettings(data) {
        this._insuSettings$.next(data);
    }

    getInsSettingsByCopyId(copyFromId: any, copyToId: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_Settings_Copy.replace(
                "{copyFromId}",
                "" + copyFromId
            ).replace("{copyToId}", "" + copyToId)
        );
    }

    updateInsSettings(data: any) {
        return this._http.put(constant.PUT_Insurance_Settings, data);
    }

    getCopyValues(): Observable<any> {
        return this._http.get<any>(constant.GET_Insurance_Settings);
    }

    binIdSuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_BinSuggest.replace("{searchBy}", "" + value)
        );
    }

    processorIdSuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_ProcessorSuggest.replace(
                "{searchBy}",
                "" + value
            )
        );
    }

    getMMSData(binNum: any, processorNum: any) {
        return this._http.get(
            constant.GET_Insurance_MMSData.replace(
                "{binNum}",
                "" + binNum
            ).replace("{processorNum}", "" + processorNum)
        );
    }

    getTransSettingsInfo(Id: any): Observable<InsuTransOther> {
        return this._http.get<InsuTransOther>(
            constant.GET_Insurance_TransSettings_byId.replace(
                "{insuPlanId}",
                "" + Id
            )
        );
    }

    updateTransInsSettings(data: any) {
        return this._http.put(constant.PUT_Insurance_TransSettings, data);
    }

    getNcpdpInfo(Id: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_NCPDP.replace("{ncpdpId}", "" + Id)
        );
    }

    updateNcpdpInfo(ncpdpdata: any) {
        return this._http.put(constant.PUT_Insurance_NCPDP, ncpdpdata);
    }

    updateInsNotes(ncpdpupdatedata: any) {
        return this._http.put(constant.PUT_Insurance_Notes, ncpdpupdatedata);
    }

    getOtherInsInfo(otherInsdata: any) {
        return this._http.post(
            constant.POST_Insurance_OtherInsPayer,
            otherInsdata
        );
    }

    deleteOtherInsInfo(otherPayorId: any): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Insurance_OtherInsPayer.replace(
                "{otherPayorId}",
                "" + otherPayorId
            )
        );
    }

    OtherInsuSuggest(value: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_OtherInsuSuggest.replace(
                "{searchBy}",
                "" + value
            )
        );
    }

    addOtherInsuPayer(data: any) {
        return this._http.post(constant.POST_Insurance_AddOtherInsu, data);
    }

    editOtherInsuPayer(data: any) {
        return this._http.put(constant.PUT_Insurance_EditOtherInsu, data);
    }

    getInsBillingInfo(Id: any): Observable<any> {
        return this._http.get<any>(
            constant.GET_Insurance_InsBilling_byId.replace(
                "{insuPlanId}",
                "" + Id
            )
        );
    }

    saveInsBilling(billingInfo: InsuBillFileSetting, Id: any) {
        return this._http.put(
            constant.PUT_Insurance_InsBilling.replace("{insuPlanId}", "" + Id),
            billingInfo
        );
    }

    getInsuranceSearch(insuSearch: SearchInsurance) {
        return this._http.post<any>(constant.POST_Insurance_Search, insuSearch);
    }

    sendNCPDPFilters(data: any) {
        return this._http.post<any>(constant.POST_Insurance_NCPDP_Filters, data, reqOptns);
    }

    addNCPDPInfo(ncpdpInfo: any) {
        return this._http.post(constant.POST_Insurance_NCPDP, ncpdpInfo);
    }

    deleteNCPDPInfo(ncpdpId: any): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_Insurane_NCPDP.replace(
                "{NcpdpId}",
                "" + ncpdpId
            )
        );
    }

    getNCPDPDeleteResponse(ncpdpId: any): Observable<any> {
        return this._http.get<any>(constant.GET_NCPDP_DeleteResponse.replace("{NcpdpId}",ncpdpId));
    }

    getInsuranceExceptionList(payload: any): Observable<any>  {
        return this._http.post<any>(constant.POST_INSURANCE_EXCEPTION_LIST, payload, reqOptns);
    }

    getInsurnaceExceptionFilters(): Observable<any>  {
        return this._http.get<any>(constant.GET_INSURANCE_EXCEPTION_FILTERS, reqOptns);
    }

    saveInsurnaceExceptionFilters(payload): Observable<any>  {
        return this._http.post<any>(constant.POST_INSURANCE_EXCEPTION_FILTERS,payload, reqOptns);
    }


    checkIfInsuCodeExists(insCode): Observable<any>  {
        return this._http.post<any>(constant.POST_INSURANCE_EXISTS.replace("{insCode}",insCode),{})
    }
    // Payor file related end points.
    addPayorFile(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_ADD_PAYOR_FILE, payload);
    }
    getPayorFileList(payload: any, frmFile?:boolean): Observable<any> {
        if (frmFile) {
            return this._http.post<any>(constant.GET_PAYOR_FILE_LIST, payload, reqOptns);
        } else {
            return this._http.post<any>(constant.GET_PAYOR_FILE_LIST, payload);
        }
        
    }
    updatePayorFile(payload: any): Observable<any> {
        return this._http.put<any>(constant.PUT_PAYOR_FILE, payload);
    }
    deletePayorFile(payorInsId: any): Observable<any> {
        return this._http.delete<any>(constant.DELETE_PAYOR_FILE.replace("{PayorId}", payorInsId));
    }
    checkInsLinkToPayor(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_CHECK_PAYOR_INSURANCE_LINK, payload);
    }
    getPayorLinkedIns(payorId: any): Observable<any> {
        return this._http.get<any>(constant.GET_PAYOR_LINKED_INS.replace("{payorId}", payorId));
    }
    insuranceLinkToPayor(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_INS_LINK_TO_PAYOR, payload);
    }
    getInsLinkedToMultiPayors(payload: any): Observable<any> {
        return this._http.post<any>(constant.POST_MULTI_PAYOR_LINKED_INS, payload);
    }
    getInsuranceDeleteResponse(insuranceId: any) {
        return this._http.get<any>(
            constant.GET_InsuranceDeleteResponse.replace("{insuPlanId}", "" + insuranceId));
    }

    getPayorFileDeleteResponse(payorId: any): Observable<any> {
        return this._http.get(constant.GET_PayorFileDeleteResponse.replace("{PayorId}", "" + payorId));
    }

    saveOldInsuSettings(data) {
        this._insuSettingsOld$.next(data);
    }

    insReqChngStatus(data) {
        this._insReqChngStat$.next(data);
    }

}
