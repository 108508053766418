import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ErxListingComponent } from "./erx-listing/erx-listing.component";
import { ErxFilterComponent } from "./erx-filter/erx-filter.component";

@Component({
    selector: "app-erx",
    templateUrl: "./erx.component.html"
})
export class ErxComponent implements OnInit {
    AwaitingCount: number;
    ErrorCount: number;
    ReadyCount: number;
    ProcessedCount: number;
    fromRph: boolean = false;
    SelectedPatient: any;
    erxFilterData: FormGroup;
    storeFilterss = false;
    rxInfo: any;
    isPopErxModel = false;
    rxExtraInfoObj: any;
    activeModal:string = "";
    rxType: any;
    reviewXDays : any;
    @Input() rxFG: FormGroup;
    @Input() PatientId: FormGroup;
    @Input() IsFromRxHistoryDisplay: any;
    @Input() CurrentDetails: any;
    
    fromIntakeQueue: boolean;
    @Input()
    set IsPopErxModel(lt: boolean) {
        this.isPopErxModel = lt;
    }
    @Input()
    set RxType(lt: any) {
        this.rxType = lt;
    }

    @Input()
    set RxInfo(data: any) {
        if (data) {
            this.rxInfo = data;
        }
    }

    @Input()
    set FromRph(bl: boolean) {
        this.fromRph = bl;
    }
    @Input()
    set FromIntakeQueue(bl: boolean) {
        this.fromIntakeQueue = bl;
    }

    @Input()
    set RxExtraInfoObj(rxExtraInfoObj: any) {
        this.rxExtraInfoObj = rxExtraInfoObj;
    }

    @Input()
    set ReviewXDays(data : any){
        this.reviewXDays = data;
    }
    @Output()
    CloseErxMessageLinkModal = new EventEmitter<any>();

    @Output()
    CloseErxModal = new EventEmitter<any>();


    @Output()  GetErxCounts = new EventEmitter<any>();
    @ViewChild(ErxListingComponent)
    erxlistingComp: ErxListingComponent;
    @ViewChild("ErxwarningMessage", {static:true})
    ErxwarningMessage: any;
    isPopClosedType: string = "";
    isSelectedRecords: boolean = false;
    transferInReq: any;
    @ViewChild(ErxFilterComponent)
    erxFilterComponent: ErxFilterComponent;
    constructor(private _modalService: NgbModal, private _cdr: ChangeDetectorRef) { }

    ngOnInit() {
     }
    receiveForm(event) {
        this.erxFilterData = event;
        if (this.IsFromRxHistoryDisplay && this.rxInfo && this.rxInfo.Patient) {
            this.erxFilterData["FirstName"] = this.rxInfo.Patient.firstname;
            this.erxFilterData["LastName"] = this.rxInfo.Patient.lastname;
            this.erxFilterData["DOB"] = this.rxInfo.Patient.dob;
        }
        this._cdr.detectChanges();
    }

    receiveAwaiting($event) {
        if ($event) {
            this.AwaitingCount = $event;
        } else {
            this.AwaitingCount = $event;
        }
    }

    receiveError($event) {
        this.ErrorCount = $event;
    }

    receiveReady($event) {
        this.ReadyCount = $event;
    }

    receiveProcess($event) {
        if ($event) {
            this.ProcessedCount = $event;
        } else {
            this.ProcessedCount = 0;
        }
    }

    storeFilters() {
        this.storeFilterss = true;
    }
    closeErxMessageLinkModal(type) {
        this.isPopClosedType = type;
        if (type === "cancelWithoutSelect") {
            this.openWarningModal();
        } else {
            this.CloseErxMessageLinkModal.emit("Cancel");
        }
    }
    openWarningModal() {
        this._modalService.open(this.ErxwarningMessage, {
            backdrop: "static",
            size: "sm",
            windowClass: "medium-sm-modal-30 large--content",
            keyboard: false,
            centered : true
        });
    }
    closeErxModal(type) {
        if(type){
            this.CloseErxModal.emit(true)
        }
        this.CloseErxMessageLinkModal.emit(this.isPopClosedType ? this.isPopClosedType : "Cancel");
    }
    processMultiRecords(type) {
        if (type ==='fax') {
            this.erxlistingComp.sendFax();
        } else if(type ==='report') {
            this.erxlistingComp.getMultiReport();
        } else if(type === "erxMessageLink") {
            this.erxlistingComp.getErxMessageLink();
        } else if(type === "benfitCheck") {
            this.erxlistingComp.navigateToBenfitCheck();
        } else if(type === "Process") {
            this.erxlistingComp.processSelectedRecords();
        }
    }
    selectedRecords(event) {
        this.isSelectedRecords = !event;
        this._cdr.detectChanges();
        this.isSelectedRecords = event;
    }
    getErxCount(count) {
        this.GetErxCounts.emit(count);
    }
    enableDisableBtn(event) {
        if (this.erxFilterComponent) {
            this.erxFilterComponent.enableOrDisableSearch(event);
        }
    }
    openTransferInRequest(event) {
        this.transferInReq = false;
        this._cdr.detectChanges();
        this.transferInReq = true;
    }
}
