<div class="exprx--common-block  insurance--body">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        <div class="row">
            <div class="col-11">
                Patient Details
            </div>
            <div class="col-1 pl-0 patient-edit--transfer-in" *ngIf="selectedPatientDetails?.patientid  && !selectedPatientDetails?.requestedInfo" 
            >
                <i   [ngClass]="IsfrmPatHis? 'color_light_gray ' : ''" (click)="IsfrmPatHis ? '' : editPatient(selectedPatientDetails.patientid)"
                class="far  fa-edit" title="Patient Edit"></i>
            </div>
        </div>
    </div>
    <div
    class="eprx--block__content  pt-0" [ngClass]="requestedInfor? 'height_230' :(IsTransferType ? 'cards_height_mark_transferIn' : 'cards_height_transferIn')">
        <div class="seconday-search--grid dashboard--search lg-secondary-search--grid row-space-transfer-in mb-0" *ngIf="!selectedPatientDetails?.requestedInfo">
    <app-searchbox-global class="w-100" #PatSear [SearchFor]="'Patient'" [DisableSerchFil]="true"
    class="eRx_transfer-request" [DisableRadioButtons]="true" [IsHide]="true"
    (SelectedPatient)="checkIsActivePatient($event)"  [isFrmAllergies]="true"
    [inputdisabled]="isfromPatientHistory" [IsClearIConDisable]="isfromPatientHistory ? true: false"
     id="NewRxPatSearforeRx" [IsFocused]="true"  [ClearInputValue]="patientSearchValue"
        ></app-searchbox-global>
  </div> 
    <div class="row row-space-transfer-in" >
        <div class="col-6">
            <label>{{patientLabels.Name}}</label>
            <div class="label--data ellpise" [ngbTooltip]="(selectedPatientDetails?.fullname)? (selectedPatientDetails?.fullname) :''" triggers="mouseenter:mouseleave"
             placement="top-start" tooltipClass="search-tooltip title-tooltip" ><span>{{selectedPatientDetails ?
                    (selectedPatientDetails?.fullname) : msgConfig.EMPTY_Data}}</span></div>
        </div>
        <div class="col-3">
            <label>{{patientLabels.DOB}}</label>
            <div class="label--data"><span>{{selectedPatientDetails ?
                    (selectedPatientDetails?.dob | date: "MM/dd/yyyy" ) : msgConfig.EMPTY_Data}}</span></div>
        </div>
        <div class="col-3">
            <label>{{patientLabels.Gender}}</label>
            <div class="label--data"><span>{{selectedPatientDetails ?
                    (selectedPatientDetails?.gender) : msgConfig.EMPTY_Data}}</span></div>
        </div>
    </div>
    <div class="row row-space-transfer-in">
        <div class="col-6">
            <label>{{patientLabels.Insurance_Type_Code}}</label>
            <div class="label--data"><span>{{(primaryInsu?.InsurerCode) ? (primaryInsu?.InsurerCode
                    | uppercase) : msgConfig.EMPTY_Data}}({{(primaryInsu?.groupname) ? (primaryInsu?.groupname
                        | uppercase) : msgConfig.EMPTY_Data}}/{{(primaryInsu?.binnum) ? (primaryInsu?.binnum
                        | uppercase) : msgConfig.EMPTY_Data}}/{{(primaryInsu?.ProcessorNum) ? (primaryInsu?.ProcessorNum
                            | uppercase) : msgConfig.EMPTY_Data}})</span></div>
        </div>
        <div class="col-6">
            <label>{{patientLabels.Patient_notes}}</label>
            <div class="label--data ellpise text_transform"  [ngbTooltip]="selectedPatientDetails?.notes ? selectedPatientDetails?.notes : ''" triggers="mouseenter:mouseleave"  placement="top-start" tooltipClass="search-tooltip title-tooltip"><span>{{(selectedPatientDetails?.notes) ?
                    selectedPatientDetails?.notes : msgConfig.EMPTY_Data}}</span></div>
        </div>
    </div>
    <div class="row row-space-transfer-in">
        <div class="col-6">
            <label>{{patientLabels.Phone}}</label>
            <div class="label--data ellpise">
                <span>{{selectedPatientDetails?.mobile ? (selectedPatientDetails?.mobile | mask : "(000)000-0000"):"--"}}</span>,
                <span>{{selectedPatientDetails?.telephone ? (selectedPatientDetails?.telephone | mask : "(000)000-0000"):"--"}}</span>
            </div>
        </div>
        <div class="col-6">
            <label>{{patientLabels.Address}}</label>
            <div class="label--data ellpise" 
            [ngbTooltip]="(selectedPatientDetails?.address)? (selectedPatientDetails?.address | uppercase) :''" triggers="mouseenter:mouseleave"
             placement="top-start" tooltipClass="search-tooltip title-tooltip"
            > <span>{{(selectedPatientDetails?.address) ?
                    selectedPatientDetails?.address : msgConfig.EMPTY_Data}}</span></div>
        </div>
    </div>
    <div class="row row-space-transfer-in">
        <div class="col-12">
            <label>{{patientLabels.Allergies}}</label>
            <div class="label--data tranfer-in-sig-allergies-height"
            [ngClass]="selectedPatientDetails?.AllergiesList?.includes(msgConfig.No_Known_Allergies) ? '' : 
                                    (selectedPatientDetails?.AllergiesList?.length ? 'allergy-color' : '')">
                <span>
                    {{selectedPatientDetails?.AllergiesList ?
                    selectedPatientDetails?.AllergiesList.join(", ") : msgConfig.EMPTY_Data}}
                </span>
            </div>
        </div>
    </div>
</div>
</div>
