<ng-template #RXINMODAL let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            <h4>Rx Transfer In</h4>
        </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-dropdown-overflow">
        <div class="eprx--block__content">
            <app-rx-transfer-in [frmNewRx]="true" [RxTransferType]="rxTransferType"  [RxNumber]="Prescription?.get('PrescNum')?.value" [RxTransferInfo]="rxTransferInfo" [RxTransferPhone]="Phone" [PatientDetails]="this.rxFG?.value['Patient']" [RxType]="rxType"
                (IsNewRxSaved)="setSaveButton($event)" [PrevSavedData]="prevSaved" [saveNewRxTXIN]="saveRXIN" [rxInfoNew]="rxFG?.value"
                [RxType]="this.rxFG?.value['rxType']"
                [PrescRefillId] = "PrescReFill?.get('Id')?.value"  [ReFillNo] = "PrescReFill?.get('ReFillNum')?.value">
            </app-rx-transfer-in>
        </div>
    </div>
    <div class="modal-footer">
        <button footer class="hotkey_success" (click)="saveNewRxTXIN(true)" appShortcutKey [AltKey]="'i'">
            <span>I</span> Transfer-In
        </button>
        <button footer class="hotkey_success" (click)="saveNewRxTXIN('print')" appShortcutKey [AltKey]="'p'">
            <span>P</span> Transfer-In & Print
        </button>
        <button footer class="hotkey_success" (click)="closeModal()" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
    </div>
</ng-template>

<ng-template #RXDETAILMODAL let-c="close" let-d="dismiss" class="el--popup">
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        <h4>Rx Transfer</h4>
    </h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');closeDetailModal(true)">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="eprx--block__content erx--body">
        <div class="row">
            <div class="col">
                <wj-flex-grid [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="transferDWJ"
                    [isReadOnly]="true">
                    <wj-flex-grid-column *ngFor="let actvHeader of activeHeaders;
                                                    let i = index" [header]="actvHeader" [binding]="actvHeader" [visible]="true"
                        [width]="(actvHeader === 'TRANSDATE' || actvHeader === 'LFILLDATE' || actvHeader === 'ORGRXDATE') ? 98 :
                        (actvHeader === 'Actions' || actvHeader === 'NRefills Transfer (In/Out)' || actvHeader === 'TransferDirection') ? 37 :
                        (actvHeader === 'Rx#') ? 100 : '*'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div *ngIf="actvHeader === 'Actions'">
                                <i class="far fa-edit actions" title="edit" (click)="
                                                                openEditView(item['TransferDirection'])
                                                                                    "></i>
                            </div>
                            <div *ngIf="(actvHeader === 'TRPHARMAC' || actvHeader === 'PHCISTFRO' || actvHeader === 'PHCISTTO' || actvHeader === 'COMMENTS')" title="{{item[actvHeader] | uppercase}}">
                                {{ item[actvHeader] }}
                            </div>
                            <div *ngIf="!(actvHeader === 'Actions' || actvHeader === 'TRPHARMAC' || actvHeader === 'PHCISTFRO' || actvHeader === 'PHCISTTO' || actvHeader === 'COMMENTS')">
                                {{ item[actvHeader] }}
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div title={{actvHeader}}>
                                {{ actvHeader }}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                </wj-flex-grid>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button footer class="hotkey_success" (click)="d('Cross click');closeDetailModal(true)" appShortcutKey [AltKey]="'c'"><span>C</span> Cancel</button>
</div>
</ng-template>

<ng-template #NOTRX let-c="close" let-d="dismiss" class="el--popup">
<app-custom-modal (oncrossBtnClick)="closeModal()">
    <h4 header>Warning!!</h4>
    <span body>
        <p>This is not a transfer prescription. </p>
    </span>
    <button footer autofocus class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="o"><b>O</b> Ok</button>
</app-custom-modal>
</ng-template>

<ng-template #XEREDIT let-c="close" let-d="dismiss" class="el--popup">
<app-custom-modal (oncrossBtnClick)="closeModal()">
    <h4 header>Rx <span *ngIf="transferType"> Transfer-Out </span> <span *ngIf="!transferType"> Transfer-In </span>
        Information</h4>
    <span body>
        <div class="editpatient--body newrx--body edit--drug erx--body">
            <div class="row content--heading">
                <div class="col-md-12 text-right pull-right padding-0">
                    <button class="inactive" *ngIf="!this.rxTransferFG.valid">
                        <span>R</span>
                        Save and Print
                    </button>
                    <!-- <button class="inactive" *ngIf="!this.rxTransferFG.valid">
                        Save and Fax
                    </button> -->
                    <button class="inactive" *ngIf="!this.rxTransferFG.valid">
                        <span>S</span>
                        Save
                    </button>
                    <button class="hotkey_success" autofocus  appShortcutKey [AltKey]="'r'" (click)="saveEditRxXfer('print')"
                        *ngIf="this.rxTransferFG.valid" [id]="'PrintINOUT'"><span>R</span>
                        Save and Print
                    </button>
                    <!-- <button class="hotkey_primary" appShortcutKey [CtrlKey]="'o'" (click)="saveEditRxXfer()"
                        *ngIf="this.rxTransferFG.valid" [id]="'TransferOutFax'"><span>O</span>
                        Save and Fax
                    </button> -->
                    <button class="hotkey_success" appShortcutKey [AltKey]="'s'" (click)="saveEditRxXfer()"
                        *ngIf="this.rxTransferFG.valid" [id]="'Transfer'"><span>S</span>
                        Save
                    </button>
                    <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="closeModal()"
                        id="cancel"><span>C</span> Cancel</button>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 exprx--common-block flex--one">
                    <div class="box box-default box-solid">
                        <div class="eprx--block__header">
                            <div class="col">
                                <span class="eprx--header__heading">
                                    Rx Transfer
                                </span>
                            </div>
                        </div>
                        <div class="box-body insurance--body">
                            <div class="col-sm-12  erx--body">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="box box-default box-solid">
                                            <div class="eprx--block__header">
                                                <span class="eprx--header__heading">
                                                    Rx Info
                                                </span>
                                            </div>
                                            <!-- <h5 class="erx--label--primary"> <b>Rx Info</b></h5> -->
                                            <div class="box-body insurance--body">
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                        <label class="align-label"> RX# </label>
                                                        <div class="label--data">
                                                            {{rxFG.value["Prescription"]["PrescNum"]}}</div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label class="align-label">Auth#</label>
                                                        <div class="label--data">{{rxTransferInfo?.Aref ?
                                                            rxTransferInfo?.Aref : "0" }}</div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label class="align-label">Refill# </label>
                                                        <div class="label--data">{{rxTransferInfo?.Refillno ?
                                                            rxTransferInfo?.Refillno : 0 }}</div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                                        <label class="align-label width_unset">Ord.Qty / Ord.Days </label>
                                                        <div class="label--data">{{(rxTransferInfo?.Qty ?
                                                            (rxTransferInfo?.Qty)
                                                            : 0)| number : '1.3-3' }} / {{rxTransferInfo?.Days ?
                                                            rxTransferInfo?.Days
                                                            : "0" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                        <label *ngIf="transferType"> Refills# Transferred </label>
                                                        <label *ngIf="!transferType"> Refills Transferred In
                                                        </label>
                                                        <!-- <div class="label--data" *ngIf="transferType">{{ rxTransferFG?.value?.IsRefillTransferred ? 1 : (rxTransferFG?.value?.RefillsTransferred ? (rxTransferFG?.value?.RefillsTransferred)
                                                            : 0) }}</div> -->
                                                            <div class="label--data" *ngIf="transferType">



                                                                <span 
                                                                *ngIf="!rxTransferFG?.value?.IsRefillTransferred">
                                                                    {{rxTransferDetails?.rxTransferOut?.RefillsTransferredList}}
                                                            </span>

                                                            <span  *ngIf="rxTransferFG?.value?.IsRefillTransferred">
                                                                {{rxTransferFG?.value?.IsRefillTransferred  || !rxTransferFG?.value?.RefillsTransferred ? rxTransferInfo?.Refillno :
                                                                    (rxTransferFG?.value?.RefillsTransferred ? refillNos : 0)}}

                                                            </span>
                                                                <span *ngIf="(rxTransferFG?.value?.IsRefillTransferred
                                                                || !rxTransferFG?.value?.RefillsTransferred) && rxTransferFG?.value?.PartialFillNo > 1"> - Remaining Fill Qty </span></div>



                                                            <div class="label--data" *ngIf="!transferType">{{(rxTransferFG?.value?.RefillsReceived ? (rxTransferFG?.value?.RefillsReceived + 1)
                                                                : 1) }}</div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="transferType">
                                                        <label> Total Qty Transferred Out </label>
                                                        <div class="label--data" *ngIf="rxTransferFG?.value?.TotalQty > 0" title="">
                                                           {{rxTransferFG?.value?.TotalQty | number : '1.3-3'}} 
                                                        </div>
                                                       
                                                        <div class="label--data" *ngIf="(!rxTransferFG?.value?.TotalQty && rxTransferFG?.value?.PartialFillNo && rxTransferFG?.value?.RefillsTransferred)">
                                                             {{&nbsp; (rxTransferFG?.value?.PartialFillQty && rxTransferFG?.value?.OrdQty && rxTransferFG?.value?.RefillsTransferred) ? 
                                                                      (rxTransferFG?.value?.PartialFillQty + (rxTransferFG?.value?.OrdQty * rxTransferFG?.value?.RefillsTransferred))  : 0 | number : '1.3-3'}}
                                                         </div>

                                                        <div class="label--data" *ngIf="!rxTransferFG?.value?.TotalQty && !rxTransferFG?.value?.PartialFillNo">{{
                                                            (rxTransferFG?.value?.OrdQty ? (rxTransferFG?.value?.OrdQty * (rxTransferFG?.value?.IsRefillTransferred ? 1 : rxTransferFG?.value?.RefillsTransferred))
                                                                : 0)| number : '1.3-3'}}</div>
                                                        <div class="label--data" *ngIf="!rxTransferFG?.value?.TotalQty && rxTransferFG?.value?.PartialFillNo && !rxTransferFG?.value?.RefillsTransferred">{{(rxTransferFG?.value?.PartialFillQty ?
                                                                (rxTransferFG?.value?.PartialFillQty)
                                                                : 0)| number : '1.3-3' }}</div>

                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="!transferType">
                                                        <label> Total Qty Transferred In
                                                        </label>
                                                        <div class="label--data">{{(rxTransferFG?.value?.OrdQty ? (rxTransferFG?.value?.OrdQty *
                                                            (rxTransferFG?.value?.RefillsReceived + 1))
                                                            : 0)| number : '1.3-3' }}</div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <label>Sig</label>
                                                        <div class="label--data">
                                                            {{rxTransferInfo?.SigDescription | uppercase : "--" }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="margin_top_35px">

                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-3" *ngIf="!transferType">
                                                                <eprx-input [LabelText]="'Original Rx#'"
                                                                    [PlaceHolder]="''" [ControlName]="'OrgRxNum'"
                                                                    [FormGroupName]="rxTransferFG" [MaxLength]="15"
                                                                    [AutoFocus]="!transferType" [IsRequired]="true"
                                                                    [FormGroupInvalid]="formGroupInvalid"
                                                                    [RxSelectId]="'OriginalRxN'"
                                                                    [RxSelectId]="'OrgRxNum'" [MaskPattern]="'0*'"
                                                                    [ErrorDefs]="{required: 'Required'}">
                                                                </eprx-input>
                                                            </div>

                                                            <div [ngClass]="!transferType ? 'col-3' : 'col-4'">
                                                                <eprx-date-picker [LabelText]="'Original Rx Date'"
                                                                    [RxSelectId]="'OriginalRxDate'"
                                                                    [AutoFocus]="transferType"
                                                                    [ControlName]="'OrgRxDtTm'" [PlaceHolder]="''"
                                                                    [IsRequired]="true"
                                                                    [FormGroupName]="rxTransferFG"
                                                                    [IsRequired]="true"
                                                                    [ErrorDefs]="{required: 'Required'}"
                                                                    [FormGroupInvalid]="formGroupInvalid">
                                                                </eprx-date-picker>
                                                            </div>
                                                            <div [ngClass]="!transferType ? 'col-3' : 'col-4'">
                                                                <eprx-date-picker [LabelText]="'Last Rx Fill Date'"
                                                                    [RxSelectId]="'LastRxDate'" [IsRequired]="true"
                                                                    [ControlName]="'OrgLastRxFillDtTm'"
                                                                    [PlaceHolder]="''"
                                                                    [FormGroupName]="rxTransferFG"
                                                                    [ErrorDefs]="{required: 'Required'}"
                                                                    [FormGroupInvalid]="formGroupInvalid">
                                                                </eprx-date-picker>
                                                            </div>
                                                            <div [ngClass]="!transferType ? 'col-3' : 'col-4'">
                                                                <eprx-date-picker [LabelText]="'Transfer Date'"
                                                                    [RxSelectId]="'TransOutDate'"
                                                                    [IsRequired]="true"
                                                                    [ControlName]="'TransferDtTm'"
                                                                    [PlaceHolder]="''"
                                                                    [FormGroupName]="rxTransferFG"
                                                                    [ErrorDefs]="{required: 'Required'}"
                                                                    [FormGroupInvalid]="formGroupInvalid">
                                                                </eprx-date-picker>
                                                            </div>
                                                            <div class="col-4">
                                                                <eprx-input [LabelText]="'Other Pharmacist Name'"
                                                                    [RxSelectId]="'OthPharName'" [PlaceHolder]="''"
                                                                    [ControlName]="'OrgPharmacistNm'"
                                                                    [FormGroupName]="rxTransferFG"
                                                                    [IsRequired]="true"
                                                                    [ErrorDefs]="{required: 'Required'}"
                                                                    [FormGroupInvalid]="formGroupInvalid"
                                                                    [MaxLength]="30"></eprx-input>
                                                            </div>
                                                            <div class="col-4">
                                                                <eprx-select [LabelText]="'Pharmacist\'s Name(Your)'"
                                                                    [ControlName]="'PharmacistId'"
                                                                    [secondCntrlN]="'PharmacistName'"
                                                                    [IsRequired]="true"
                                                                    [FormGroupName]="rxTransferFG"
                                                                    [PlaceHolder]="''"
                                                                    [BindLabel2]="'PharmacistName'"
                                                                    [BindLabel]="'PharmacistFullName'"
                                                                    [BindValue]="'PharmacistId'"
                                                                    [bindScnCntrlV]="'PharmacistName'"
                                                                    [LabelForId]="'PharmacistId'"
                                                                    [LabelTitle2]="'Initials'"
                                                                    [LabelTitle1]="'NAME'"
                                                                    [List]="PharmacyNames?.pharmacistNames"
                                                                    (TriggerSelectValue)="selectedRphValue($event)"
                                                                    [ErrorDefs]="{required: 'Required'}"
                                                                    [FormGroupInvalid]="formGroupInvalid">
                                                                </eprx-select>
                                                            </div>
                                                            <div class="col-4" [formGroup]="rxTransferFG">
                                                                <label class="align-label padding_bottom_1px"
                                                                    >
                                                                    Remarks </label>
                                                                <textarea class="text-area h-76 w-100"
                                                                    [rows]="2" [cols]="15"
                                                                    formControlName="Remarks"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="box box-default box-solid">
                                            <div class="eprx--block__header">
                                                <span class="eprx--header__heading">
                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <span class="eprx--header__heading">
                                                                Pharmacy Information <span *ngIf="!transferType">
                                                                    (Xfer From) </span>
                                                                <span *ngIf="transferType"> (Xfer To) </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>


                                            <div class="box-body insurance--body">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <eprx-select [LabelText]="'Select Pharmacy'" [ControlName]="'XferPharmId'" [FormGroupName]="rxTransferFG"
                                                                    [PlaceHolder]="'Search Pharmacy Name'" [BindLabel]="'PharmacyName'" [BindLabel2]="'Phone1'"
                                                                    [BindLabel3]="'Phone2'" [BindLabel4]="'Fax'" [hasBindLabel4]=true [LabelTitle1]="'Pharmacy Name'"
                                                                    [LabelTitle2]="'Phone# 1'" [LabelTitle3]="'Phone# 2'" [LabelTitle4]="'Fax#'" [IsTitle]="true"
                                                                    [BindValue]="'Id'" [LabelForId]="'Id'" [List]="PharmacyNames?.pharmNames"
                                                                    (TriggerSelectValue)="getPharmcyDetails()" [IsRequired]="true"
                                                                    [ErrorDefs]="{required: 'Required'}"
                                                                    [FormGroupInvalid]="formGroupInvalid"></eprx-select>
                                                            </div>

                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                                <hr>
                                                                <div class="col-sm-12">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            <label class="align-label"> Address
                                                                            </label>
                                                                            <div class="label--data">
                                                                                {{pharmcyDet?.TransferPharmacy?.AddressLine1
                                                                                ?
                                                                                pharmcyDet?.TransferPharmacy?.AddressLine1
                                                                                : "--"}}</div>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <label class="align-label"> State
                                                                            </label>
                                                                            <div class="label--data">
                                                                                {{pharmcyDet?.TransferPharmacy?.StateName
                                                                                ?
                                                                                pharmcyDet?.TransferPharmacy?.StateName
                                                                                : "--"}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <label class="align-label"> City
                                                                            </label>
                                                                            <div class="label--data">
                                                                                {{pharmcyDet?.TransferPharmacy?.CityName
                                                                                ?
                                                                                pharmcyDet?.TransferPharmacy?.CityName
                                                                                : "--"}}</div>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <label class="align-label"> Zip </label>
                                                                            <div class="label--data">
                                                                                {{pharmcyDet?.TransferPharmacy?.ZipCode
                                                                                ?
                                                                                pharmcyDet?.TransferPharmacy?.ZipCode
                                                                                : "--"}}</div>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <label class="align-label"> Phone# 1
                                                                            </label>
                                                                            <div class="label--data">
                                                                                {{Phone1 ? (Phone1 | mask : "(000)000-0000"): "--"}}</div>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <label class="align-label"> Phone# 2
                                                                            </label>
                                                                            <div class="label--data">
                                                                                {{Phone2 ? (Phone2 | mask : "(000)000-0000") : "--"}}</div>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <label class="align-label"> Fax# </label>
                                                                            <div class="label--data">{{Fax ? (Fax | mask : "(000)000-0000") :
                                                                                "--"}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-3">
                                                                            <label class="align-label"> Pharmacy
                                                                                DEA#
                                                                            </label>
                                                                            <div class="label--data">
                                                                                {{pharmcyDet?.TransferPharmacy?.DEANum
                                                                                ?
                                                                                pharmcyDet?.TransferPharmacy?.DEANum
                                                                                : "--"}}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="box box-default box-solid">
                                            <div class="eprx--block__header">
                                                <span class="eprx--header__heading">
                                                    Patient
                                                </span>
                                            </div>
                                            <div class="box-body insurance--body">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Name</label>
                                                        <div class="label--data">{{rxTransferInfo?.PatientName ?
                                                            (rxTransferInfo?.PatientName | uppercase)
                                                            : "--" }}</div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                        <label>DOB</label>
                                                        <div class="label--data"> {{rxTransferInfo.DateOfBirth ? (
                                                            rxTransferInfo.DateOfBirth|
                                                            date:'MM/dd/yyyy') : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label>Gender</label>
                                                        <div class="label--data">{{rxTransferInfo?.Gender ?
                                                            rxTransferInfo?.Gender
                                                            : "--" }}</div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Address</label>
                                                        <div class="label--data"> {{rxTransferInfo?.PatientAddress ?
                                                            rxTransferInfo?.PatientAddress
                                                            : "--" }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="box box-default box-solid">
                                            <div class="eprx--block__header">
                                                <span class="eprx--header__heading">
                                                    Prescriber
                                                </span>
                                            </div>
                                            <div class="box-body insurance--body">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label>Name</label>
                                                        <div class="label--data">{{rxTransferInfo?.PrescriberName ?
                                                            (rxTransferInfo?.PrescriberName | uppercase)
                                                            : "--" }}</div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                        <label>DEA#</label>
                                                        <div class="label--data">{{rxTransferInfo?.DEA ?
                                                            _formatsUtil.getDeaFormat(rxTransferInfo?.DEA) : "--"
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label>NPI#</label>
                                                        <div class="label--data">{{rxTransferInfo?.NPINum ?
                                                            rxTransferInfo?.NPINum
                                                            : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label>License#</label>
                                                        <div class="label--data">{{rxTransferInfo?.Licencenumber ?
                                                            rxTransferInfo?.Licencenumber
                                                            : "--"}}</div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label class="align-label"> Phone# </label>
                                                        <div class="label--data">{{Phone ? (Phone | mask : "(000)000-0000"): "--" }}</div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label>Fax#</label>
                                                        <div class="label--data"> {{rxTransferInfo?.Fax ?
                                                            (rxTransferInfo?.Fax | mask : "(000)000-0000") : "--"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="box box-default box-solid">
                                            <div class="eprx--block__header">
                                                <span class="eprx--header__heading">
                                                    Drug
                                                </span>
                                            </div>
                                            <div class="box-body insurance--body">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label>Name</label>
                                                        <div class="label--data">{{rxTransferInfo?.DrugDetail ?
                                                            (rxTransferInfo?.DrugDetail | uppercase)
                                                            : "--" }}</div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                        <label>NDC</label>
                                                        <div class="label--data"> {{rxTransferInfo?.NDC ?
                                                            rxTransferInfo?.NDC : "--"
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label>Packet Size</label>
                                                        <div class="label--data">{{rxTransferInfo?.PacketSize ?
                                                            (rxTransferInfo?.PacketSize | number : '1.3-3')
                                                            : "0.000" }}</div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <label class="align-label"> AWP </label>
                                                        <div class="label--data">{{(rxTransferInfo?.AWP ?
                                                            (rxTransferInfo?.AWP) : 0)| currency:
                                                            "$":"symbol":"1.2-2"
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </span>
</app-custom-modal>
</ng-template>

<ng-template #TRANSFERINFO let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Rx <span *ngIf="transferType"> Transfer-Out </span> <span *ngIf="!transferType"> Transfer-In </span>
            Information</h4>
        <span body class="eprx-transfer-req patient-edit--transfer-in exprx--common-block">
             <!--Steppers-->
            <div class="row" >
                <div class="col-12 row m-0">
                    <div class="col-5 row m-0 pt-0" >
                        <div class="col-12 text-center">
                            <div> <i  class="fas fa-check-circle"></i> Select Patient</div>
                            <div  class="stepper1 eprx-eRx_transferReq-border-select">
                        </div>
                        </div>
                    </div>
                    <div class="col-2 text-center row pl-0 pt-0" >
                        <div class="col-12 tex-center">
                            <div>  <i  class="fas fa-check-circle"></i> Select Transfer Type</div>
                            <div 
                            [ngClass]=" (rxTransferFG.value['XferPharmId']) ? 'stepper2 eprx-eRx_transferReq-border-select' : 'stepper2 eprx-eRx_transferReq-border-unselect'">
                        </div>
                        </div>
                    </div>
                    <div class="col-5 text-center row" style="padding-top: 0px !important;">
                        <div class="col-12 tex-center">
                            <div> <i  [ngClass]="rxTransferFG.value['XferPharmId'] ? 'fas fa-check-circle' : 'fa-kit fa-circle-3-solid'"></i> Select Pharmacy</div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Patient & Transfer Type & Pharmacy selection-->
            <div class="row mt-1">
                <div class="col-5">
                    <app-patient-selection  [IsfrmPatHis]="true" [IsTransferType]="'markasTransferIn'"  [SelectedPatientDetailsfrmRx]="rxFG?.value['Patient']" ></app-patient-selection>
                </div>
                <div class="col-2 px-0">
                    <app-transfer-type  [IsfrmPatHis]="true"  [IsTransferType]="'markasTransferIn'"  [RxType]="'er'" [eRxModelFG]="rxTransferFG"></app-transfer-type>
                </div>
                <!--Pharmacy Selection-->
                <div class="col-5 pharmacy_dropdown exprx--common-block insurance--body">
                    <div class="eprx--block__header row col m-0">
                        <div class="col-11 text-white  font-weight-bold px-1">
                            Pharmacy Information <span *ngIf="!transferType">
                                (Xfer From) </span>
                            <span *ngIf="transferType"> (Xfer To) </span>
                        </div>
                        <div class="col-1 pl-0 patient-edit--transfer-in text-right">
                                <i 
                            class="far fa-edit text-right color_light_gray "  title="Edit"></i>
                        </div>
                    </div>
                    <div class="eprx--block__content pt-0 cards_height_mark_transferIn">
                        <div class="row row-space-transfer-in">
                            <div class="col-9 pr-0">
                                <eprx-select [LabelText]="''"
                                [ControlName]="'XferPharmId'"
                                [FormGroupName]="rxTransferFG"
                                [PlaceHolder]="'Search Pharmacy Name'"
                                [BindLabel]="'PharmacyName'"
                                [BindLabel2] = "'Phone1'"
                                [BindLabel3] = "'Phone2'"
                                [BindLabel4] = "'Fax'"
                                [BindLabel5]="'addresses'"
                                [BindLabel6]="'NPI'"
                                [IsCardView]="true"
                                [hasBindLabel5] = true
                                [LabelTitle1]="'Pharmacy Name'"
                                [LabelTitle2]="'Phone1#'"
                                [LabelTitle3]="'Phone2#'"
                                [LabelTitle4]="'Fax#'"
                                [LabelTitle5]="'Address'"
                                [LabelTitle6]="'NPI'"
                                [IsTitle]="true"
                                [BindValue]="'Id'"
                                [LabelForId]="'Id'"
                                [List]="PharmacyNames?.pharmNames"
                                (TriggerSelectValue)="getPharmcyDetails($event)"
                                [IsRequired]="true"
                                (keydown.tab)="focusOutFmPharm($event)"
                                [InputErrors]="XferPharmIdxId?.errors"
                                [ErrorDefs]="{required: 'Required'}"
                                [FormGroupInvalid]="formGroupInvalid"
                                [phoneLabel]="true"
                                [faxLabel]="true"
                                [addressLabel]="true"
                                [DropdownPosition]="'bottom'"
                                [MarkAsTouched]="rxTransferFG?.controls?.XferPharmIdxId?.touched"
                                ></eprx-select>
                            </div>
                            <div class="col-2 pl-0">
                                <button class="inactive"  title="Add" appShortcutKey
                                [AltKey]="'a'"><span>A</span>ADD</button>
                            </div>
                            <div class="col-1 pl-0 patient-edit--transfer-in">
                                <i class="fa-kit fa-pharmacy-icon"  [ngClass]="rxTransferFG?.value['XferPharmId'] ? 'color_green' : ''" 
                                title="Pharmacy"></i> 
                              </div>
                        </div>
                        <div class="row row-space-transfer-in">
                            <div class="col-12">
                                <label>{{pharmacyabels.pName}}</label>
                                <div class="label--data"><span>{{pharmcyDet?.TransferPharmacy ?
                                        (pharmcyDet?.TransferPharmacy?.PharmName) : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space-transfer-in">
                            <div class="col-4">
                                <label>{{pharmacyabels.Phone}}</label>
                                <div class="label--data"><span>{{this.Phone1 ?
                                        (this.Phone1) : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-4">
                                <label>{{pharmacyabels.Fax}}</label>
                                <div class="label--data"><span>{{Fax ?
                                        (Fax) : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                            <div class="col-4">
                                <label>{{pharmacyabels.NPI}}</label>
                                <div class="label--data"><span>{{pharmcyDet?.TransferPharmacy?.NPINum ?
                                        pharmcyDet?.TransferPharmacy?.NPINum : msgConfig.EMPTY_Data}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row row-space-transfer-in">
                            <div class="col-12">
                                <label>{{pharmacyabels.Address}}</label>
                                <div class="label--data"> <span>{{(pharmcyDet?.TransferPharmacy?.FullAddress) ?
                                        pharmcyDet?.TransferPharmacy?.FullAddress : msgConfig.EMPTY_Data}}</span></div>
                            </div>
                        </div>
                        <div class="row row-space-transfer-in">
                            <div class="col-6">
                                <eprx-input [LabelText]="'Rph Name'"
                                [RxSelectId]="'OthPharName'" [PlaceHolder]="''"
                                [ControlName]="'OrgPharmacistNm'"
                                [FormGroupName]="rxTransferFG"
                                [IsRequired]="true"
                                [ErrorDefs]="{required: 'Required'}"
                                [FormGroupInvalid]="formGroupInvalid"
                                [MaxLength]="30"></eprx-input>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
              <!--Rx Details-->
            <div class="eprx--block__header text-white pl-3 font-weight-bold">
                <div class="row">
                    <div class="col-12">
                        Rx  Info
                    </div>
                </div>
            </div>
            <div
            class="eprx--block__content mt-0" style="border-radius: 0px 0px 10px 10px;    height: 135px;">
                <div class="row">
                    <div class="col-4">
                    <div class="row">                       
                        <div class="col-2">
                            <label class="align-label"> Rx# </label>
                            <div class="label--data">
                                {{rxFG.value["Prescription"]["PrescNum"]}}</div>
                        </div>
                        <div class="col-3">
                            <label class="align-label">Auth#</label>
                            <div class="label--data">{{rxTransferInfo?.Aref ?
                                rxTransferInfo?.Aref : "0" }}</div>
                        </div>
                        <div class="col-7 pl-0">
                            <label>Drug Name</label>
                            <div class="label--data">{{rxTransferInfo?.DrugDetail ?
                                (rxTransferInfo?.DrugDetail | uppercase)
                                : "--" }}</div>
                        </div>
                        <div class="col-5 row-space-transfer-in">
                            <label>Prescriber Name</label>
                            <div class="label--data">{{rxTransferInfo?.PrescriberName ?
                                (rxTransferInfo?.PrescriberName | uppercase)
                                : "--" }}</div>
                        </div>
                        <div class="col-3 pl-0 pr-0 row-space-transfer-in">
                            <label>NPI#</label>
                            <div class="label--data">{{rxTransferInfo?.NPINum ?
                                rxTransferInfo?.NPINum
                                : "--" }}
                            </div>
                        </div>
                        <div class="col-4 pl-0 row-space-transfer-in" >
                            <label>DEA#</label>
                            <div class="label--data">{{rxTransferInfo?.DEA ?
                                _formatsUtil.getDeaFormat(rxTransferInfo?.DEA) : "--"
                                }}
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-2">
                        <div class="row">
                        <div class="col-6">
                            <label>NDC</label>
                            <div class="label--data"> {{rxTransferInfo?.NDC ?
                                rxTransferInfo?.NDC : "--"
                                }}
                            </div>
                        </div>
                        <div class="col-6">
                            <label class="align-label width_unset">Ord.Qty</label>
                            <div class="label--data">{{(rxTransferInfo?.Qty ?
                                (rxTransferInfo?.Qty)
                                : 0)| number : '1.3-3' }}
                            </div>
                        </div>
                        <div class="col-6 row-space-transfer-in">
                            <label>License#</label>
                            <div class="label--data">{{rxTransferInfo?.Licencenumber ?
                                rxTransferInfo?.Licencenumber
                                : "--"}}</div>
                        </div>
                        <div class="col-6 row-space-transfer-in">
                            <label class="align-label"> Phone# </label>
                            <div class="label--data">{{Phone ? (Phone | mask : "(000)000-0000"): "--" }}</div>
                        </div>
                    </div>
                </div>
                    <div class="col-6">
                       <div class="row">
                        <div class="col-12">
                           <div class="row">
                            <div class="col-2 pr-0">
                                <label class="align-label"> Days Supply </label>
                                <div class="label--data">
                                    {{rxTransferInfo?.Days ? rxTransferInfo?.Days : "--"
                                    }}
                                </div>
                            </div>
                            <div class="col-3 px-0">
                                <label *ngIf="transferType"> Refills# Transferred </label>
                                <label *ngIf="!transferType"> Refills Transferred In
                                </label>
                                <!-- <div class="label--data" *ngIf="transferType">{{ rxTransferFG?.value?.IsRefillTransferred ? 1 : (rxTransferFG?.value?.RefillsTransferred ? (rxTransferFG?.value?.RefillsTransferred)
                                    : 0) }}</div> -->
                                    <div class="label--data" *ngIf="transferType">
    
    
    
                                        <span 
                                        *ngIf="!rxTransferFG?.value?.IsRefillTransferred">
                                            {{rxTransferDetails?.rxTransferOut?.RefillsTransferredList}}
                                    </span>
    
                                    <span  *ngIf="rxTransferFG?.value?.IsRefillTransferred">
                                        {{rxTransferFG?.value?.IsRefillTransferred  || !rxTransferFG?.value?.RefillsTransferred ? rxTransferInfo?.Refillno :
                                            (rxTransferFG?.value?.RefillsTransferred ? refillNos : 0)}}
    
                                    </span>
                                        <span *ngIf="(rxTransferFG?.value?.IsRefillTransferred
                                        || !rxTransferFG?.value?.RefillsTransferred) && rxTransferFG?.value?.PartialFillNo > 1"> - Remaining Fill Qty </span></div>
    
    
    
                                    <div class="label--data" *ngIf="!transferType">{{(rxTransferFG?.value?.RefillsReceived ? (rxTransferFG?.value?.RefillsReceived + 1)
                                        : 1) }}</div>
                            </div>
                            <div class="col-3" *ngIf="transferType">
                                <label> Total Qty Transferred Out </label>
                                <div class="label--data" *ngIf="rxTransferFG?.value?.TotalQty > 0" title="">
                                   {{rxTransferFG?.value?.TotalQty | number : '1.3-3'}} 
                                </div>
                               
                                <div class="label--data" *ngIf="(!rxTransferFG?.value?.TotalQty && rxTransferFG?.value?.PartialFillNo && rxTransferFG?.value?.RefillsTransferred)">
                                     {{&nbsp; (rxTransferFG?.value?.PartialFillQty && rxTransferFG?.value?.OrdQty && rxTransferFG?.value?.RefillsTransferred) ? 
                                              (rxTransferFG?.value?.PartialFillQty + (rxTransferFG?.value?.OrdQty * rxTransferFG?.value?.RefillsTransferred))  : 0 | number : '1.3-3'}}
                                 </div>
    
                                <div class="label--data" *ngIf="!rxTransferFG?.value?.TotalQty && !rxTransferFG?.value?.PartialFillNo">{{
                                    (rxTransferFG?.value?.OrdQty ? (rxTransferFG?.value?.OrdQty * (rxTransferFG?.value?.IsRefillTransferred ? 1 : rxTransferFG?.value?.RefillsTransferred))
                                        : 0)| number : '1.3-3'}}</div>
                                <div class="label--data" *ngIf="!rxTransferFG?.value?.TotalQty && rxTransferFG?.value?.PartialFillNo && !rxTransferFG?.value?.RefillsTransferred">{{(rxTransferFG?.value?.PartialFillQty ?
                                        (rxTransferFG?.value?.PartialFillQty)
                                        : 0)| number : '1.3-3' }}</div>
    
                            </div>
                            <div class="col-3" *ngIf="!transferType">
                                <label> Total Qty Transferred In
                                </label>
                                <div class="label--data">{{(rxTransferFG?.value?.OrdQty ? (rxTransferFG?.value?.OrdQty *
                                    (rxTransferFG?.value?.RefillsReceived + 1))
                                    : 0)| number : '1.3-3' }}</div>
                            </div>
                            <div class="col-4">
                                <label>Sig</label>
                                <div class="label--data ellpise" [ngbTooltip]="(rxTransferInfo?.SigDescription) ? (rxTransferInfo?.SigDescription | uppercase):''" triggers="mouseover:mouseleave"
                                [autoClose]="true" placement="top-start" tooltipClass="search-tooltip title-tooltip">
                                    {{rxTransferInfo?.SigDescription | uppercase : "--" }}
                                </div>
                            </div>
                           </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-2 pr-0 row-space-transfer-in">
                                    <label>Fax#</label>
                                    <div class="label--data"> {{rxTransferInfo?.Fax ?
                                        (rxTransferInfo?.Fax | mask : "(000)000-0000") : "--"
                                        }}
                                    </div>
                                </div>
                                <div class="col pl-0 row-space-transfer-in" *ngIf="!transferType"> 
                                    <eprx-input [LabelText]="'Original Rx#'"
                                    [PlaceHolder]="''" [ControlName]="'OrgRxNum'"
                                    [FormGroupName]="rxTransferFG" [MaxLength]="15"
                                    [AutoFocus]="!transferType" [IsRequired]="true"
                                    [FormGroupInvalid]="formGroupInvalid"
                                    [RxSelectId]="'OriginalRxN'"
                                    [RxSelectId]="'OrgRxNum'" [MaskPattern]="'0*'"
                                    [ErrorDefs]="{required: 'Required'}">
                                </eprx-input>
                                </div>
                                
                                <div class="col pl-0 row-space-transfer-in">
                                    <eprx-date-picker [LabelText]="'Original Rx Date'"
                                        [RxSelectId]="'OriginalRxDate'"
                                        [AutoFocus]="transferType"
                                        [ControlName]="'OrgRxDtTm'" [PlaceHolder]="''"
                                        [IsRequired]="true"
                                        [FormGroupName]="rxTransferFG"
                                        [IsRequired]="true"
                                        [ErrorDefs]="{required: 'Required'}"
                                        [FormGroupInvalid]="formGroupInvalid">
                                    </eprx-date-picker>
                                </div>
                                <div class="col pl-0 row-space-transfer-in" >
                                    <eprx-date-picker [LabelText]="'Last Rx Fill Date'"
                                        [RxSelectId]="'LastRxDate'" [IsRequired]="true"
                                        [ControlName]="'OrgLastRxFillDtTm'"
                                        [PlaceHolder]="''"
                                        [FormGroupName]="rxTransferFG"
                                        [ErrorDefs]="{required: 'Required'}"
                                        [FormGroupInvalid]="formGroupInvalid">
                                    </eprx-date-picker>
                                </div>
                                <div class="col row-space-transfer-in">
                                    <eprx-date-picker [LabelText]="'Transfer Date'"
                                        [RxSelectId]="'TransOutDate'"
                                        [IsRequired]="true"
                                        [ControlName]="'TransferDtTm'"
                                        [PlaceHolder]="''"
                                        [FormGroupName]="rxTransferFG"
                                        [ErrorDefs]="{required: 'Required'}"
                                        [FormGroupInvalid]="formGroupInvalid">
                                    </eprx-date-picker>
                                </div>
                            </div>
                        </div>
                       
                       </div>
                    </div>
                </div>
            </div>
            <!---Your Pharmacy details & Notes-->
            <div class="row mt-1">
                <div class="col-5">
                    <app-current-pharmacy  [LabelName]="'Your Pharmacy'"></app-current-pharmacy>
                </div>
                <div class="col-4 px-0">
                    <app-single-notes [TransferIn]="'markasTransfer'" [RxType]="rxType"   [PrescriptionId]="this.rxFG?.value?.Prescription?.Id"  [IsNotPopup]="true" [MainCategory]="'RxAnnotation'" [SavedNotes]="this.rxFG?.value?.RxNotes" (TransferNotes)="transferSingleNotes($event)" (IsPopUpClosed)="transferSingleNotes($event)" ></app-single-notes>
                </div>
                <div class="col-3 text-center transfer-in_icon">
                    <img [src]="transferType? '/assets/Transfer Out.svg': '/assets/Mark Transfer In.svg'" [ngbTooltip]="transferType ? 'Transfer Out': 'Transfer In'" triggers="mouseover:mouseleave"
                    [autoClose]="true" placement="top" tooltipClass="search-tooltip title-tooltip">
                </div>
            </div>
        </span>
        <span footer>
            <div class="row content--heading">
                <div class="col-md-12 text-right pull-right padding-0">
                    <button class="inactive" *ngIf="!this.rxTransferFG.valid">
                        <span>R</span>
                        Save and Print
                    </button>
                    <!-- <button class="inactive" *ngIf="!this.rxTransferFG.valid">
                        Save and Fax
                    </button> -->
                    <button class="inactive" *ngIf="!this.rxTransferFG.valid">
                        <span>S</span>
                        Save
                    </button>
                    <button class="hotkey_success" autofocus appShortcutKey [AltKey]="'r'" (click)="saveEditRxXfer('print')"
                        *ngIf="this.rxTransferFG.valid" [id]="'PrintINOUT'"><span>R</span>
                        Save and Print
                    </button>
                    <!-- <button class="hotkey_primary" appShortcutKey [CtrlKey]="'o'" (click)="saveEditRxXfer()"
                        *ngIf="this.rxTransferFG.valid" [id]="'TransferOutFax'"><span>O</span>
                        Save and Fax
                    </button> -->
                    <button class="hotkey_success" appShortcutKey [AltKey]="'s'" (click)="saveEditRxXfer()"
                        *ngIf="this.rxTransferFG.valid" [id]="'Transfer'"><span>S</span>
                        Save
                    </button>
                    <button class="hotkey_success" appShortcutKey [AltKey]="'c'" (click)="closeModal()"
                        id="cancel"><span>C</span> Cancel</button>
                </div>
            </div>
        </span>
    </app-custom-modal>
    </ng-template>
    

<ng-template #INDETAILS let-c="close" let-d="dismiss" class="el--popup">
<app-custom-modal (oncrossBtnClick)="closeModal()">
    <h4 header>Rx <span *ngIf="transferType"> Transfer-Out </span> <span *ngIf="!transferType"> Transfer-In </span> Information</h4>
    <span body>
        <div class="box box-default box-solid exprx--common-block">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    <div class="col-sm-12 padding-0">
                        <div class="row">
                            <div class="col-md-9">
                                Rx Information
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-body">
                <div class="col-sm-12  erx--body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row">
                                <div class="col-6">
                                    <label class="align-label"> RX# </label>
                                    <div class="label--data">{{rxFG.value["Prescription"]["PrescNum"]}}</div>
                                </div>
                                <div class="col-6" *ngIf="rxTransferInfo.RxStatusId === 1">
                                    <label class="align-label"> Rx Status</label>
                                    <div class="label--data color_red">UNBILLED</div>
                                </div>
                                <div class="col-6" *ngIf="rxTransferInfo.RxStatusId === 2">
                                    <label class="align-label"> Rx Status</label>
                                    <div class="label--data color_green" >BILLED</div>
                                </div>
                                <div class="col-6" *ngIf="rxTransferInfo.RxStatusId === 6">
                                    <label class="align-label"> Rx Status</label>
                                    <div class="label--data color_dark_blue">FILED</div>
                                </div>
                                <div class="col-6" *ngIf="rxTransferInfo.RxStatusId === 4">
                                    <label class="align-label"> Rx Status</label>
                                    <div class="label--data color_dark_blue">TRASFERED</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Patient </label>
                                    <div class="label--data">{{rxTransferInfo?.PatientName ? rxTransferInfo?.PatientName
                                        : "--" }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Drug </label>
                                    <div class="label--data">{{rxTransferInfo?.DrugDetail ? rxTransferInfo?.DrugDetail
                                        : "--" }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> DOB </label>
                                    <div class="label--data">{{rxTransferInfo?.DateOfBirth | date:'MM/dd/yyyy' : "--" }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> NDC </label>
                                    <div class="label--data">{{rxTransferInfo?.NDC ? rxTransferInfo?.NDC : "--" }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Sig </label>
                                    <div class="label--data">{{rxTransferInfo?.SigDescription | uppercase : "--" }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row">
                                <div class="col-6">
                                    <label class="align-label"> Prescriber </label>
                                    <div class="label--data">{{rxTransferInfo?.PrescriberName ? rxTransferInfo?.PrescriberName
                                        : "--" }}</div>
                                </div>
                                <div class="col-6">
                                        <label class="align-label"> DEA# </label>
                                        <div class="label--data">{{rxTransferInfo?.DEA ? _formatsUtil.getDeaFormat(rxTransferInfo?.DEA) : "--" }}</div>
                                    </div>
                                <div class="col-6">
                                    <label class="align-label">Refill# </label>
                                    <div class="label--data">{{rxTransferInfo?.Refillno ? rxTransferInfo?.Refillno :
                                        "0" }}</div>
                                </div>
                                <div class="col-6">
                                        <label class="align-label">
                                            Refill(s) Left </label>
                                        <div class="label--data">{{refillDetails?.Refills}}</div>
                                    </div>

                                <div class="col-6">
                                    <label class="align-label"> Phone# </label>
                                    <div class="label--data">{{Phone ? Phone : "--" }}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> NPI# </label>
                                    <div class="label--data">{{rxTransferInfo?.NPINum ? rxTransferInfo?.NPINum : "--"
                                        }}
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> License# </label>
                                    <div class="label--data">{{rxTransferInfo?.Licencenumber ? rxTransferInfo?.Licencenumber
                                        : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Qty / Days </label>
                                    <div class="label--data">{{rxTransferInfo?.Qty ? rxTransferInfo?.Qty : "--" }} /
                                        {{rxTransferInfo?.Days ? rxTransferInfo?.Days : "--"
                                        }}
                                    </div>
                                </div>
                                <!-- <div class="col-6">
                                    <label class="align-label">
                                        Qty Remaining </label>
                                    <div class="label--data">{{rxTransferInfo?.QtyRem ? rxTransferInfo?.QtyRem : "--"
                                        }}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box box-default box-solid exprx--common-block">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    <div class="col-sm-12 padding-0">
                        <div class="row">
                            <div class="col-md-9">
                                Pharmacy Information
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-body">
                <div class="col-sm-12  erx--body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row">
                                <div class="col-6" *ngIf="!transferType">
                                    <label class="align-label"> Original Rx# </label>
                                    <div class="label--data">{{refillDetails.OrgRxNum}}</div>
                                </div>
                                <div class="col-6">
                                        <label class="align-label"> Pharmacy Name </label>
                                        <div class="label--data">{{pharmcyDet?.TransferPharmacy?.PharmName ? pharmcyDet?.TransferPharmacy?.PharmName : "--"}}</div>
                                    </div>
                                <div class="col-6">
                                    <label > Pharmacist Name </label>
                                    <div class="label--data">{{selectedPharmacist}}</div>
                                </div>
                                <div class="col-6">
                                    <label> Other Pharmacist Name  </label>
                                    <div class="label--data">{{refillDetails.OrgPharmacistNm}}</div>
                                </div>
                                <div class="col-6" *ngIf="!transferType">
                                    <label class="align-label"> Rx LastFillDate </label>
                                    <div class="label--data">{{refillDetails.OrgLastRxFillDtTm | date:'MM/dd/yyyy'}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Rx TransferDate </label>
                                    <div class="label--data">{{refillDetails.TransferDtTm | localTime | date:'MM/dd/yyyy'}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Remarks </label>
                                    <div class="label--data">{{refillDetails.Remarks}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div class="row">
                                <div class="col-6">
                                    <label class="align-label"> Address </label>
                                    <div class="label--data">{{pharmcyDet?.TransferPharmacy?.AddressLine1 ? pharmcyDet?.TransferPharmacy?.AddressLine1 : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> State </label>
                                    <div class="label--data">{{pharmcyDet?.TransferPharmacy?.StateName ? pharmcyDet?.TransferPharmacy?.StateName : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> City </label>
                                    <div class="label--data">{{pharmcyDet?.TransferPharmacy?.CityName ? pharmcyDet?.TransferPharmacy?.CityName : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Zip </label>
                                    <div class="label--data">{{pharmcyDet?.TransferPharmacy?.ZipCode ? pharmcyDet?.TransferPharmacy?.ZipCode : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Phone1 </label>
                                    <div class="label--data">{{pharmcyDet?.Phone ? pharmcyDet?.Phone[0] ?
                                                    pharmcyDet?.Phone[0].Phone ? pharmcyDet?.Phone[0].Phone : "--" : "--" : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Phone2 </label>
                                    <div class="label--data">{{pharmcyDet?.Phone ? pharmcyDet?.Phone[1] ?
                                        pharmcyDet?.Phone[1].Phone ? pharmcyDet?.Phone[1].Phone : "--" : "--" : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Fax </label>
                                    <div class="label--data">{{pharmcyDet?.Phone ? pharmcyDet?.Phone[2] ?
                                        pharmcyDet?.Phone[2].Phone ? pharmcyDet?.Phone[2].Phone : "--" : "--" : "--"}}</div>
                                </div>
                                <div class="col-6">
                                    <label class="align-label"> Pharmacy DEA# </label>
                                    <div class="label--data">{{pharmcyDet?.TransferPharmacy?.DEANum ? _formatsUtil.getDeaFormat(pharmcyDet?.TransferPharmacy?.DEANum) : "--"}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
    <button footer class="hotkey_primary" (click)="printInfo()" appShortcutKey InputKey="p"><b>P</b> Print </button>
    <button footer class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="o"><b>O</b> Ok</button>
    </app-custom-modal>
</ng-template>
