<!-- <ng-template
    #VIEWTRANSMSG
    let-c="close"
    let-d="dismiss"
    class="el--popup"
> -->
    <app-custom-modal (oncrossBtnClick)="closeModal()" *ngIf="viewTransmData">
            <h4 header *ngIf="viewTransmData && !viewTransmData?.ErrorMessage"> View Transmission</h4>
        <span body *ngIf="viewTransmData && !viewTransmData?.ErrorMessage">
            <div class="row">
                <div class="exprx--common-block col-12">
                    <div class="eprx--block__content border_radius_10px">
                        <div class="row trasmit--rx">
                            <div class="col-lg-12">
                                <label class="header-title">Header Information</label>
                                <div class="row mt-2">
                                    <div class="col" *ngFor="let lstSegValue of headerSeg[0]">
                                        <label class="field-label font_weight_300">{{ lstSegValue?.FieldID }}</label>
                                        <div class="field-value">{{ lstSegValue?.FieldValue }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6" *ngFor="let item of viewTransList; let i = index">
                    <div class="exprx--common-block max-height_38rem overflow_x_hidden">
                        <div class="eprx--block__header">
                        <div class="eprx--header__heading"> {{ i === 0 ? 'Transmission String' : 'Received String' }} </div>
                        </div>
                        <div class="eprx--block__content">
                            <div class="row trasmit--rx">
                                <div class="col-lg-12 col-md-12 col-xs-12 word_break">
                                    {{item?.TrString}}
                                </div>
                            </div>
                            <div class="trans-Header light_gray mt-2">
                                <div class="eprx--header__heading color_495057">
                                    <div class="row">
                                        <div class="col-1 ml-3">SegID</div>
                                        <div class="col-4">SegName</div>
                                    </div>
                                </div>
                            </div>
                    <div class="row trans_message">
                                <div class="col-12">
                                    <ngb-accordion [closeOthers]="true" activeIds="ngb-panel-0">
                                        <ngb-panel [id]="'list0'">
                                            <ng-template ngbPanelTitle>
                                                <div class="row" (click)="toggledata('list0')">
                                                    <div class="col-1">0</div>
                                                    <div class="col-4">Header</div>
                                                    <div class="col-7 text-right">
                                                        <i *ngIf="isExpand('list0')" class="fas fa-chevron-down"></i>
                                                        <i *ngIf="!isExpand('list0')" class="fas fa-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        <ng-template ngbPanelContent>
                                                    <table class="table table-bordered">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th scope="col">Seg ID</th>
                                                                <th scope="col">Field ID</th>
                                                                <th scope="col">Field Value</th>
                                                            </tr>
                                                        </thead>
                                                <tbody *ngFor="let lstSegValue of headerSeg[i]">
                                                            <tr>
                                                                <th scope="row"> 0 </th>
                                                                <td> {{lstSegValue?.FieldID}} </td>
                                                                <td> {{lstSegValue?.FieldValue}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            </ng-template>
                                        </ngb-panel>
                                <ngb-panel [id]="'list'+lstsegName?.SegID" 
                                    *ngFor="let lstsegName of item?.lstSegmentName; let i = index">
                                            <ng-template ngbPanelTitle>
                                                <div class="row" (click)="toggledata(i)">
                                                    <div class="col-1">{{lstsegName?.SegID}}</div>
                                                    <div class="col-4" >{{lstsegName?.SegName}}</div>
                                                    <div class="col-7 text-right">
                                                        <i  *ngIf="isExpand(i)" class="fas fa-chevron-down"></i>
                                                        <i *ngIf="!isExpand(i)" class="fas fa-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                    <table class="table table-bordered">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th scope="col">Seg ID</th>
                                                                <th scope="col">Field ID</th>
                                                                <th scope="col">Field Value</th>
                                                            </tr>
                                                        </thead>
                                                <tbody *ngFor="let lstSegValue of item?.lstSegmentValue; let i = index">
                                                            <tr *ngIf="lstsegName?.SegID === lstSegValue?.SegID">
                                                                <th scope="row"> {{lstSegValue?.SegID}} </th>
                                                                <td> {{lstSegValue?.FieldID}} </td>
                                                                <td> {{lstSegValue?.FieldValue}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
        <button
            ngbAutofocus
            footer
            class="hotkey_primary"
            (click)="initiateTransmitRxOnly()"
            appShortcutKey InputKey="t"
            *ngIf="isNotFromTrans === 'true' && viewTransmData && !viewTransmData?.ErrorMessage"
        >
            <b>T</b> Transmit
        </button>
        <button footer *ngIf="viewTransmData && !viewTransmData?.ErrorMessage" class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="c"><b>C</b> Close</button>


            <h4 header *ngIf="viewTransmData?.ErrorMessage"> Error Message </h4>
            <span body *ngIf="viewTransmData?.ErrorMessage color_red">
                {{viewTransmData?.ErrorMessage}}
            </span>
            <button *ngIf="viewTransmData?.ErrorMessage" footer class="hotkey_primary" (click)="closeModal()" appShortcutKey InputKey="c"><b>C</b> Close</button>


    </app-custom-modal>

<!-- </ng-template> -->
