<ng-template #Confirmation let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Confirmation</h4>
        <span body>
            Do you want to perform Drug Pick Verification Individually or mark all the selected Rxs as Verified?
        </span>
        <button footer class="hotkey_primary" ngbAutofocus (keydown.enter)="c('Close click');verifyInfo('individually')"
            (click)="c('Close click')" (click)="verifyInfo('individually')" appShortcutKey InputKey="v"><b>V</b>
            Verify Individually
        </button>
        <button footer class="hotkey_primary" (click)="c('Close click')" (click)="verifyInfo('mark')"
            (keydown.enter)="c('Close click');verifyInfo('mark')" appShortcutKey InputKey="m"><b>M</b>
            Mark all selected Rxs as Verified
        </button>
        <button footer class="hotkey_primary" (click)="closeModal()" (keydown.enter)="closeModal()" appShortcutKey
            InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>

<ng-template #VerifiedRxs let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Confirmation</h4>
        <span body>
            The following marked Rx(s) <label>{{&nbsp; verifiedArray.join(", ")}}</label> are successfully verified.
        </span>
        <button footer autofocus class="hotkey_primary" (click)="closeModal()" (keydown.enter)="closeModal()"
            appShortcutKey InputKey="o"><b>O</b> OK</button>
            <!-- <button footer class="hotkey_primary" (click)="closeModal()" (keydown.enter)="closeModal()" appShortcutKey
            InputKey="n"><b>N</b>
            NO</button> -->
    </app-custom-modal>
</ng-template>

<ng-template #invalidRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Warning!!</h4>
        <span body>
            <span [innerHTML]="warningInfoForDisplay"></span>
        </span>
        <button footer autofocus class="hotkey_primary"
            (click)="closeModal();processDrugPickVerificationfromInvalidRx()" appShortcutKey InputKey="o"><b>O</b>
            OK</button>
    </app-custom-modal>
</ng-template>


<ng-template #RxDiscontinued let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <span>Warning!!</span>
    </div>
    <div class="modal-body">
        <span><b>{{discontinuedData?.Prescription?.PrescNum}}-{{discontinuedData?.PrescReFill?.ReFillNum}}{{(_commonServ.checkIsPartialFill(discontinuedData?.PrescReFill, discontinuedData?.Prescription) ?'-'+discontinuedData?.PrescReFill?.PartialFillNo :
                '')}}</b></span>
        is Discontinued Rx.
        <br /> If you wish to continue, selected Rx will no more be marked as discontinued.
        <br /> Would you like to proceed?
    </div>
    <div class="modal-footer">
        <button class="hotkey_primary" ngbAutofocus appShortcutKey [InputKey]="'y'"
            (click)="setOrderStatus(); c('Close click')"><b>Y</b>YES</button>
        <button class="hotkey_primary" appShortcutKey [InputKey]="'n'"
            (click)="closeModal();processDrugPickVerificationfromInvalidRx('no')"><b>N</b>NO</button>
    </div>
</ng-template>


<ng-template #alreadyVerified let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="processDrugPickVerification()">
        <h4 header>Confirmation</h4>
        <span body>
            <div class="row">
                <div class="col">
                    <table class="table table-hover">
                        <tbody>
                            <div class="eprx--block__content">
                                <div class="row mt-2 mb-2">
                                    <div class="col-6">
                                        <label>Patient Name: </label> {{ rxInfo?.Patient?.firstname | uppercase &nbsp;}}
                                        {{rxInfo?.Patient?.lastname | uppercase}}
                                    </div>
                                    <div class="col-6">
                                        <label>Drug Name: </label>
                                        <span title="{{ (rxInfo?.Drug?.drugname | uppercase) + ' ' +
                                        rxInfo?.Drug?.strength + ' ' + rxInfo?.Drug?.drugformname}}"> {{
                                            (rxInfo?.Drug?.drugname | uppercase) + ' ' +
                            rxInfo?.Drug?.strength + ' ' + rxInfo?.Drug?.drugformname }}</span>
                                    </div>
                                    <div class="col-6">
                                        <label>Rx#: </label> {{ rxInfo?.Prescription?.PrescNum &nbsp;}}
                                    </div>
                                    <div class="col-3">
                                        <label>Rf#: </label> {{ rxInfo?.PrescReFill?.ReFillNum }}
                                    </div>
                                    <div class="col-3">
                                        <label>Fill#: </label>
                                        {{_commonServ.checkIsPartialFill(rxInfo?.PrescReFill, rxInfo?.Prescription)
                                        ? rxInfo?.PrescReFill?.PartialFillNo : "-"}}
                                    </div>
                                    <div class="col-12">
                                        <label>
                                            was previously verified successfully.
                                        </label>
                                    </div>
                                </div>

                                <div class="row mt-2 mb-2">
                                    <div class="col">
                                        <label> Date Verified: </label>
                                        <div class="label-data">
                                            {{ (rxInfo?.drugPickVerif)[0] ?.VerifDtTm | localTime | date : "MM/dd/yyyy
                                            hh:mm:ss a" }}
                                        </div>
                                    </div>
                                    <div class="col">
                                        <label> Verified By: </label>
                                        <div class="label-data">
                                            {{ (rxInfo?.drugPickVerif)[0] ?.UserName ? ((rxInfo?.drugPickVerif)[0]
                                            ?.UserName | uppercase) : " " }}
                                        </div>
                                    </div>
                                </div>
                                <label>Do you wish to verify this Rx again?</label>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </span>
        <button footer autofocus class="hotkey_primary" (click)="processDpvForAlreadyVerified('yes')"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button footer class="hotkey_primary" (click)="closeModal()" (click)="processDpvForAlreadyVerified('no')"
            appShortcutKey InputKey="n"><b>N</b>
            NO</button>
    </app-custom-modal>
</ng-template>

<app-login-verify *ngIf="activeModal == 'DrugPickVerification'" [HeaderName]="'DrugPickVerification'" [LoginTab]="'PatientDrugPickVerification'"
    [FromPage]="'PatientDrugPickVerification'" (setPopUpValue)="afterVerifyDrugPickVerificationp($event)">
</app-login-verify>
