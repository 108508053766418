<div class="row drug--price__info exprx--common-block">
    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 padding-sm-15 exprx--common-block flex--one">
        <div class=" box-default">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Drug Price Information
                </div>
            </div>
            <div class="eprx--block__content">
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                            <eprx-input [LabelText]="'AWP Pack'" [PlaceHolder]="'AWP Pack'" [ControlName]="'AWPPack'"
                                [FormGroupName]="drugInfoFG?.get('Drug')" [AutoFocus]="true" [MaxLength]="10" [InputType]="'CURRENCY'"
                                [DecimalValues]="2" [MaskPattern]="'099999.00'" [DropSpclChar]="false" [RxSelectId]="'AWPPack'"
                                (TriggerSearchValue)="convertToFixedDecimals('AWPPack')">
                            </eprx-input>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                            <eprx-input [LabelText]="'Cost Pack'" [DropSpclChar]="false" [PlaceHolder]="'Cost Pack'"
                                [ControlName]="'CostPack'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                (TriggerSearchValue)="convertToFixedDecimals('CostPack')" [MaskPattern]="'099999.00'"
                                [InputType]="'CURRENCY'" [DecimalValues]="2" [RxSelectId]="'CostPack'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                            <eprx-input [LabelText]="'Unit Price_DIR'" [DropSpclChar]="false" [PlaceHolder]="'Unit Price_DIR'"
                                [ControlName]="'DirectUnitPrice'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="10"
                                (TriggerSearchValue)="setNumber('DirectUnitPrice', 3)" [MaskPattern]="'09999.000'" [InputType]="'CURRENCY'"
                                [DecimalValues]="3" [RxSelectId]="'DirectUnitPrice'">
                            </eprx-input>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                            <eprx-input [LabelText]="'Unit AWP Price'" [DropSpclChar]="false" [PlaceHolder]="'Unit AWP Price'"
                                [ControlName]="'UnitPriceAWP3Digits'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="10"
                                (TriggerSearchValue)="convertToFixedDecimals('UnitPriceAWP')" [MaskPattern]="'09999.000'"
                                [InputType]="'CURRENCY'" [DecimalValues]="3" [RxSelectId]="'UnitPriceAWP'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                            <eprx-input [LabelText]="'Unit Price Cost'" [PlaceHolder]="'Unit Price Cost'"
                                [ControlName]="'UnitPriceCost3Digits'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="10"
                                [DropSpclChar]="false" [MaskPattern]="'09999.000'"
                                (TriggerSearchValue)="convertToFixedDecimals('UnitPriceCost')" [InputType]="'CURRENCY'" [DecimalValues]="3"
                                [RxSelectId]="'UnitPriceCost'">
                            </eprx-input>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0" *ngIf="!(Is340BPharm$ | async)">
                            <eprx-input [LabelText]="'Alternate Cost'" [PlaceHolder]="'Alternate Cost'"
                                [ControlName]="'AlternateCost'" [FormGroupName]="drugInfoFG?.get('Drug')" [MaxLength]="10"
                                [DropSpclChar]="false" [MaskPattern]="'09999.00'"
                                (TriggerSearchValue)="setNumber('AlternateCost', 2)" [InputType]="'CURRENCY'" [DecimalValues]="2"
                                [RxSelectId]="'AlternateCost'">
                            </eprx-input>
                        </div>
                    </div>

                     <!-- Below code is for alternate cost with different types -->

                    <!-- <div class="col-md-7 dinamic--fields" [formGroup]="drugInfoFG">
                        <div formArrayName="DrugMiscPrice">
                            <div class="row" *ngFor="let drugMisc of drugInfoFG?.get('DrugMiscPrice')?.controls; let i=index" >
                                <div class="col-lg-5  col-md-6 col-sm-12 col-xs-12 padding-0" *ngIf="drugInfoFG?.get('DrugMiscPrice')?.value[i].IsActive">
                                    <eprx-select [LabelText]="'Type Of Cost'" [PlaceHolder]="''" [ControlName]="'DrugMiscPriceCatId'" [FormGroupName]="drugMisc"
                                    [IsDisabled]="true" [HasMultiple]="false" [BindLabel]="'Name'" [BindValue]="'Id'" [LabelForId]="'Name'"
                                        [List]="systemData?.DrugMiscPriceCat">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-12 padding-0" style="margin-left: 20px;" *ngIf="drugInfoFG?.get('DrugMiscPrice')?.value[i].IsActive">
                                    <eprx-input [LabelText]="'Cost Amount'" [PlaceHolder]="''"
                                    [ControlName]="'Price'" [FormGroupName]="drugMisc" [MaskPattern]="'09999.099'" [DropSpclChar]="false"
                                     (TriggerSearchValue)="updateAltCost($event, i)" [InputType]="'CURRENCY'">
                                    </eprx-input>
                                </div>
                                <div class="col-lg-1 text-center">

                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-lg-4 exprx--common-block flex--one">
        <div class="row">
            <div class="col-md-12 padding-sm-15">
                <div class=" box-default">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            Cash Pricing
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0">
                            <eprx-select [LabelText]="'Cash Price Scheduled'" [PlaceHolder]="'Cash Price Scheduled'" [ControlName]="'PriceSchId'" [FormGroupName]="drugInfoFG?.get('Drug')"
                                [HasMultiple]="false" [BindLabel2]="'Name'" [BindLabel]="'PriceCode'" [BindValue]="'Id'"
                                [LabelForId]="'Name'" [List]="priceSchedule?.PriceScheduleList" [LabelTitle1]="'Code'" [LabelTitle2]="'Name'" [Show2Values]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                            <eprx-input [LabelText]="'Surcharge'" [PlaceHolder]="'Surcharge'" [ControlName]="'Surcharge'" [MaskPattern]="'099.00'" [DropSpclChar]="false"
                            [InputType]="'NUMBER'" [FormGroupName]="drugInfoFG?.get('Drug')" (TriggerSearchValue)="convertToFixedDecimals('Surcharge')" [DecimalValues]="2">
                            </eprx-input>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                            <eprx-input [LabelText]="'%Off Brand'" [PlaceHolder]="'%Off Brand'" [ControlName]="'PerOfBrand'"[MaskPattern]="'099.00'" [DropSpclChar]="false"
                            [InputType]="'NUMBER'" [FormGroupName]="drugInfoFG?.get('Drug')" (TriggerSearchValue)="convertToFixedDecimals('PerOfBrand')" [DecimalValues]="2">
                            </eprx-input>
                        </div>
                        <div class="col-md-6 padding-0">
                            <label class="col-md-12 padding-0"> Discountable </label>
                            <eprx-radio [ControlName]="'IsDiscountable'" [FormGroupName]="drugInfoFG?.get('Drug')" [IsRequired]="true" [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                [IDForList]="['yes', 'no']" [LabelForId]=""
                            (keydown.tab)="focusToSave($event)">
                            </eprx-radio>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class=" exprx--common-block flex--two">
    <div class="row">
        <div class="col-8 padding-sm-15 top_n65px left_n16px">
            <div class=" box-default">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Drug Other Prices
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 padding-0">
                    <wj-flex-grid #BucketListGrid [headersVisibility]="'Column'" [isReadOnly]="true"
                    (drop)="this._storageService.storeWijmoGridDataInSession(BucketListGrid)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(BucketListGrid)"
                        [columnPicker]="'drugOtherPriceswj'" [itemsSource]="DrugOtherPriceswj" [selectionMode]="'Row'">
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                            [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders;"
                            [width]="rxheader['width']">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div>
                                    {{ (rxheader['hName'] === 'Unit Price') ? (item[rxheader['hName']] |currency : "$") : (item[rxheader['hName']]) }}
                                </div>
                                <div *ngIf="rxheader['hName']  === 'Unit Price'">
                                    <div class="pr-2">
                                        <!-- <i title="UnitPrice" [ngClass]="((item?.UnitPrice) ?  'fa fa-pencil  float-right margin_15' : 'fa fa-pencil float-right margin_15')"  (click)="openNewMacPrice(item)"></i> -->
                                        <i title="UnitPrice" [ngClass]="((item?.UnitPrice) ?  'far fa-pencil  actions  float-right margin_15 padding_bottom_8px padding_right_3rem' : 'far fa-pencil  actions float-right margin_15 padding_bottom_8px padding_right_3rem')"  (click)="openNewMacPrice(item)"></i>
                                    </div>
                                </div>
                            </ng-template>
                        </wj-flex-grid-column>
                    </wj-flex-grid>
                    <div class="modal-footer pr-0">
                        <button footer ngbAutofocus id="addOk"appShortcutKey [AltKey]="'m'" type="button" class="hotkey_success"
                        (click)="getMacWacUpdateMmsData()">
                           <span>M</span> Update From MMS
                        </button>
                    </div>
                    <ng-template #MacWac let-c="close" let-d="dismiss">
                        <div class="modal-header">
                            <h4 class="modal-title">
                                <div>Other Price</div>
                            </h4>
                            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
                                <span aria-hidden="true" class="close-button">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="hotkey_primary"
                                appShortcutKey InputKey="o"
                                (click)="c('Close click'); closeModalSM()"
                            >
                            <b>O</b> Ok
                            </button>
                        </div>
                    </ng-template>
                </div>
                <ng-template #MacNew let-c="close" let-d="dismiss">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <span *ngIf="IsMac">
                                <div>New MAC Price</div>
                            </span>
                            <span *ngIf="!IsMac">
                                <div>New WAC Price</div>
                            </span>
                        </h4>
                        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModalSM()">
                            <span aria-hidden="true" class="close-button">&times;</span>
                        </button>
                    </div>
                    <span *ngIf="IsMac">
                        <div class="modal-body">
                        <eprx-input [LabelText]="'MAC'" [MaxLength]="15" [DropSpclChar]="false" [PlaceHolder]="'MAC'"
                        [ControlName]="'MAC'" [FormGroupName]="macWacFG"
                        (TriggerSearchValue)="convertToFixedDecimals('MAC')" [MaskPattern]="'099999.000'"
                        [InputType]="'CURRENCY'" [DecimalValues]="3" >
                    </eprx-input>
                        </div>
                    </span>
                    <span *ngIf="!IsMac">
                        <div class="modal-body">
                            <eprx-input [LabelText]="'WAC'" [MaxLength]="15" [DropSpclChar]="false" [PlaceHolder]="'WAC'"
                        [ControlName]="'WAC'" [FormGroupName]="macWacFG"
                        (TriggerSearchValue)="convertToFixedDecimals('WAC')" [MaskPattern]="'099999.000'"
                        [InputType]="'CURRENCY'" [DecimalValues]="3">
                    </eprx-input>
                        </div>
                    </span>
                    <div class="modal-footer">
                            <button  type="button"
                            class="hotkey_primary"  appShortcutKey InputKey="s"
                             (click)="onSaveMacWacValue();c('Close click'); closeModalSM()">
                                <b>s</b> SAVE
                            </button>
                            <button
                            type="button"
                            class="hotkey_primary"
                            appShortcutKey InputKey="c"
                            (click)="c('Close click'); closeModalSM()"
                        >
                        <b>c</b> CANCEL
                    </button>
                    </div>
                   </ng-template>
            </div>
        </div>
    </div>
