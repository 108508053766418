export class QuickRefillStatus {
    PrescRefillId: number = null;
    RxId: number = null;
    Ref: number = null;
    ARef: number = null;
    InsuranceName: string = null;
    QtyDisp: number = null;
    FillDtTm: Date = null;
    Price: number = null;
    CoPay: number = null;
    SupplyDays: number = null;
    DeliveryMode: string = null;
    IsPickedUp: boolean = null;
    PrecName: string = null;
    DrugName: string = null;
    PatientName: string = null;
    IsDrugActive: boolean = null;
    DrugStatus: boolean = null;
    InsurerCode: string = null;
    IsDiscontinued: boolean = null;
    IsFacility: boolean = null;
    PatientId: number = null;
    PrescNum: string = null;
    RxStatus: string = null;
}

// export class RxDelivery {
//     Id: number = null;
//     TenantId: number = null;
//     RxId: number = null;
//     IsShipped: boolean = null;
//     IsFacility: boolean = null;
//     FacilityId: number = null;
//     AddressId: number = null;
//     PriorityId: number = null;
//     ReqdDtTm: Date = null;
//     DeliveryInstru: string = null;
//     Remarks: string = null;
//     RefillNum: number = null;
//     DeliveryMode: number = null;
// }

export class RefillReport {
    Reportype: string =  null;
    ReportDueFromDate: Date =  null;
    ReportDueToDate: Date =  null;
    ReportFromFillDate: Date =  null;
    ReportToFillDate: Date =  null;
    IncludeDueDate: boolean =  null;
    IncludeFillDate: boolean =  null;
    drugType: any = null;
    drugClass: number = null;
    ExcludeInsuranceType: any = null;
    SelectedInsList: any = null;
    InsuranceType: any = null;
    IncludeFacilityPatients: boolean =  null;
    ShowMaintenanceDrugsOnly: boolean =  null;
    sortBy: string =  null;
    ShowExpiredRxOnly: boolean =  null;
    ShowExpiredRx: boolean =  null;
    ShowExpiring: boolean =  null;
    BasedOnEquiDrugs: boolean =  null;
    IncludeFiledRxs: boolean =  null;
    PickupDate: boolean =  null;
    Profitfrom: number = null;
    Profitto: number = null;
    RxRefillReminder: any = null;
    PatientRefillReminder: any = null;
    ExcludeRefillRemainder: boolean =  null;
    FacilityId: number = null;
    PrescriberId: number = null;
    InsuranceId: number = null;
    PatientCategory: any = null;
    PatCategory: string =  null;
    OnlyFacPatients: boolean =  null;
    ExcludeTaggedRxs: boolean =  null;
    ExcludeExpiredRx: boolean = null;
    ExcludeFacilityPatients: boolean =  null;
    ShowExpiringRxs: boolean =  null;
    billSearch: any  = null;
    BillType: string = null;
    ExcludeEarlyForRefill: boolean = null;
    ExcludeExpiredByTimeLimit: boolean = null;
    IncludeFiledRx: boolean = null;
    PrintRxPrice: boolean = null;
    FiledRxs: boolean = null;
    ShowExpired: boolean = null;
    IsPartialFill: boolean = null;
    ConsumptionThreshold: any = null;
    IncludeUnbilledRxs: boolean = null;
}
