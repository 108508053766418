import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { MsgConfig } from "../app.messages";

@Injectable({
    providedIn: "root"
})
export class DateCheckUtil {
    constructor() {

    }
    checkExpiryDate(expDate: any) {
        if (expDate) {
            let today = moment().format("MM/DD/YYYY"); // let today = moment().utc().local().format("MM/DD/YYYY");
            let RxExpDate = moment.utc(expDate).local().format("MM/DD/YYYY");
            return moment(today, "MM/DD/YYYY").isAfter(RxExpDate);
        } else {
            return false;
        }
    }
    formatDate(date) {
        if (date) {
            return  moment(date).format(MsgConfig.DATE_FORMAT);
        } else {
            return "";
        }
    }
    checkDateValidationAtTimeofSaveAllFormGroups(dateCheckForControls) {
        let invalidFormGroups: any  = [];
        if(dateCheckForControls && dateCheckForControls.length) {
            dateCheckForControls.map((item: any) => {
                const formGroup = item["formGroup"];
                const orginalDate = formGroup.value[item["control"]];
                const controlName = item["control"];
                const minDate = item["minDate"];
                const maxDate  =  item["maxDate"];
                const isRequired  =  item["isRequired"];

                let date : any ;
                const isCheck = (moment(orginalDate, "YYYY-MM-DDTHH:mm:ss", true).isValid()) || (moment(orginalDate, "YYYY-MM-DDTHH:mm:ss.SSSSSSS", true).isValid());
                if(orginalDate &&  !isCheck && !(this.invalidDateCheck(orginalDate))) {
                    date = orginalDate;
                } else {
                     date = (controlName && ((orginalDate && orginalDate.length >=10) || (controlName === 'FillDtTm' || controlName === 'OrderDtTm'))) ?  moment(formGroup.value[item["control"]]).format("MM/DD/YYYY") : formGroup.value[item["control"]];

                }

                if (moment(date, "MM/DD/YYYY", true).isValid() || moment(date, "M/DD/YYYY", true).isValid() || moment(date, "MM/D/YYYY", true).isValid() || moment(date, "M/D/YYYY", true).isValid() || moment(date, "MM/D/YYYY", true).isValid()) {
                    if(date && minDate) {
                        if (moment(minDate.month + "/" + minDate.day + "/" + minDate.year, "MM/DD/YYYY").isAfter(moment(date, "MM/DD/YYYY"))) {
                            formGroup.controls[item["control"]].setErrors({ "minDate": true });
                        }
                    }
                    if(date && maxDate) {
                        if (moment(maxDate.month + "/" + maxDate.day + "/" + maxDate.year, "MM/DD/YYYY")
                            .isBefore(moment(date, "MM/DD/YYYY"))) {
                                formGroup.controls[item["control"]].setErrors({ "maxDate": true });
                        }
                    }
                } else {
                    if(!date && isRequired) {
                        formGroup.controls[item["control"]].setErrors({ required: true });
                    } else if((date && date !== "Invalid date") && !(moment(date, "MM/DD/YYYY", true).isValid())) {
                        formGroup.controls[item["control"]].setErrors({ inValid: true });
                    }
                }
                if(formGroup.invalid) {
                            invalidFormGroups.push(item);
                }
            });
        }
        if(invalidFormGroups && invalidFormGroups.length)
            return invalidFormGroups[0];
        else
            return null;
    }

    checkDateValidationAtTimeofSave(formGroup: FormGroup, dateCheckForControls) {
        let isInvalidControl : any;
        if(dateCheckForControls && dateCheckForControls.length) {
            dateCheckForControls.map((item: any) => {
                const minDate = item["minDate"];
                const maxDate  =  item["maxDate"];
                const controlName = item["control"];
                const isRequired  =  item["isRequired"];
                const isDateWithTime = item["Id"]
                const orginalDate = formGroup.value[item["control"]];
                let date : any ;
                const isDateFormatCheck = this.invalidDateCheckForIsoFormat(orginalDate);
                if(orginalDate && !isDateFormatCheck && !isDateWithTime && !(this.invalidDateCheck(orginalDate))) {
                    date = orginalDate;
                } else {
                     date = (controlName && ((orginalDate && orginalDate.length >=10) || (controlName === 'FillDtTm' || controlName === 'OrderDtTm'))) ?  moment(formGroup.value[item["control"]]).format("MM/DD/YYYY") : formGroup.value[item["control"]];

                }

                if (moment(date, "MM/DD/YYYY", true).isValid() || moment(date, "M/DD/YYYY", true).isValid() || moment(date, "MM/D/YYYY", true).isValid() || moment(date, "M/D/YYYY", true).isValid() || moment(date, "MM/D/YYYY", true).isValid()) {
                    if(date && minDate) {
                        if (moment(minDate.month + "/" + minDate.day + "/" + minDate.year, "MM/DD/YYYY").isAfter(moment(date, "MM/DD/YYYY"))) {
                            formGroup.controls[item["control"]].setErrors({ "minDate": true });
                            isInvalidControl  = item["control"];
                        }
                    }
                    if(date && maxDate) {
                        if (moment(maxDate.month + "/" + maxDate.day + "/" + maxDate.year, "MM/DD/YYYY")
                            .isBefore(moment(date, "MM/DD/YYYY"))) {
                                formGroup.controls[item["control"]].setErrors({ "maxDate": true });
                                isInvalidControl  = item["control"];
                        }
                    }
                } else {
                    if(!date && isRequired) {
                        formGroup.controls[item["control"]].setErrors({ required: true });
                        isInvalidControl  = item["control"];
                    } else if((date && date !== "Invalid date") && !(moment(date, "MM/DD/YYYY", true).isValid())) {
                        isInvalidControl  = item["control"];
                        formGroup.controls[item["control"]].setErrors({ inValid: true });
                    }
                }
            });
        }
        if(formGroup.valid)
            return false;
        else
            return true;
    }
    invalidDateCheck(date) {
        let  isInvalidDate : boolean = false;
        if(date) {
            isInvalidDate  =    moment(date, "MM/DD/YYYY", true).isValid() || moment(date, "M/DD/YYYY", true).isValid() || moment(date, "MM/D/YYYY", true).isValid() || moment(date, "M/D/YYYY", true).isValid() || moment(date, "MM/D/YYYY", true).isValid();

        }
        return isInvalidDate;
    }
    invalidDateCheckForIsoFormat(value) {
        let  isInvalidDate : boolean = false;
        if(value) {
            isInvalidDate =   (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSSSS", true).isValid())
            || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ", true).isValid()) || (moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()) || (moment(value, "YYYY-MM-DDTHH:mm:ssZ", true).isValid());
        }
        return isInvalidDate;
    }
    getCurrentDate() {
        return moment().format(MsgConfig.DATE_FORMAT);
    }
    getCurrentWithDate() {
        return moment(Date.now()).format(MsgConfig.DATE_FORMAT);
    }

    getBeforeOneMonthDate() {
        return moment().subtract(30, "day").format(MsgConfig.DATE_FORMAT)
    }
}
