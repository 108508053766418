<div class="modal-header">
    <h4 class="modal-title" *ngIf="!isEdit">Add New Pharmacy</h4>
    <h4 class="modal-title" *ngIf="isEdit">Edit Pharmacy</h4>
    <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closeModal('cancel', null)">
        <span aria-hidden="true" class="close-button">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <eprx-input [LabelText]="'Pharmacy Name'" [AutoFocus]="true" [PlaceHolder]="''" [ControlName]="'PharmName'" [FormGroupName]="TransferPharmacies"
                [MaxLength]="30" [InputErrors]="TransferPharmacies?.controls?.PharmName?.errors" [ErrorDefs]="{required: 'Required'}" [IsRequired]="true" [FormGroupInvalid]="pharmFGInvalid">
            </eprx-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <eprx-input [LabelText]="'NPI#'" [PlaceHolder]="''" [ControlName]="'NPINum'" [FormGroupName]="TransferPharmacies" [MaxLength]="10"
             [RxSelectId]="'NPINumber'"   [MaskPattern]="'0000000000'" [MaxLength]="10" [MinLength]="10">
            </eprx-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <eprx-input [LabelText]="'Pharmacy DEA#'" [PlaceHolder]="''" [ControlName]="'DEANum'" [FormGroupName]="TransferPharmacies"
            [RxSelectId]="'DEANumber'"    [MaskPattern]="'SS0000000'" [MaxLength]="9" [MinLength]="9">
            </eprx-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <eprx-input [LabelText]="'Address'" [PlaceHolder]="'Address'" [ControlName]="'AddressLine1'" [FormGroupName]="TransferPharmacies"
                [MaxLength]="30" [InputErrors]="TransferPharmacies?.controls?.AddressLine1?.errors" (TriggerOnChange)="onAddressChange($event.target.value)" [FormGroupInvalid]="pharmFGInvalid" [ErrorDefs]="{required: 'Required'}"
                [IsRequired]="true">
            </eprx-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <app-search-zip [FormGroupName]="TransferPharmacies" [ControlName]="'ZipCode'" [InputErrors]="TransferPharmacies?.controls?.ZipCode?.errors" (OnZipSelected)="prescZipSelected($event)"
                [zipExtnCntrl]="'Extension'" [IsRequired]="true" [FormGroupInvalid]="pharmFGInvalid"></app-search-zip>
            </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 messsage-height">
            <app-search-state [FormGroupName]="TransferPharmacies" [ControlName]="'StateId'" [CityControlName]="'CityId'" [StateSelected]="stateSelected"
                [InputErrors]="TransferPharmacies?.controls?.StateId?.errors" [ErrorDefs]="{required: 'Required' }" [FormGroupInvalid]="pharmFGInvalid" [IsRequired]="true"
                (OnStateSelected)="optedStateValue($event)"></app-search-state>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 messsage-height">
            <app-search-city [FormGroupName]="TransferPharmacies" [ControlName]="'CityId'" [StateControlName]="'StateId'" [CitySelected]="citySelected"
             [zipControl]="'ZipCode'"   [InputErrors]="TransferPharmacies?.controls?.CityId?.errors" [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="pharmFGInvalid" [IsRequired]="true"
                (OnCitySelected)="optedCityValue($event)"></app-search-city>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <eprx-input [LabelText]="'Phone# 1'" [PlaceHolder]="''" [ControlName]="'phone1'"
            [FormGroupName]="addPharFG" [MaskPattern]="'(000)000-0000'" [DropSpclChar]="true"
            [ShowMask]="true">
            </eprx-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <eprx-input [LabelText]="'Phone# 2'" [PlaceHolder]="''" [ControlName]="'phone2'"
            [FormGroupName]="addPharFG" [MaskPattern]="'(000)000-0000'"
            [DropSpclChar]="true"
            [ShowMask]="true">
            </eprx-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <eprx-input [LabelText]="'Fax#'" [PlaceHolder]="''" [ControlName]="'Fax'"
            [FormGroupName]="addPharFG" [MaskPattern]="'(000)000-0000'" [DropSpclChar]="true"
            [ShowMask]="true">
            </eprx-input>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <eprx-input [LabelText]="'NCPDP ID'" [PlaceHolder]="''" [ControlName]="'NCPDPID'" [EnteredMaxLength]="10"
            [FormGroupName]="TransferPharmacies"  [DropSpclChar]="true" [InputType]="'NUMBER'"  [MaxLength]="10" [MinLength]="6">
            </eprx-input>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <eprx-input [LabelText]="'Comments'" [PlaceHolder]="''" [ControlName]="'Remarks'" [FormGroupName]="TransferPharmacies">
            </eprx-input>
        </div>
    </div>
</div>
<div class="modal-footer ">
    <div class="text-right pull-right col-md-10 padding-0">
        <div>
            <button class="hotkey_success" (click)="checkDuplicatePharm(warning)" *ngIf="!isEdit" appShortcutKey [AltKey]="'s'"><span>S</span>Save</button>
            <button class="hotkey_success" (click)="checkDuplicatePharm(warning)" *ngIf="isEdit" appShortcutKey [AltKey]="'u'"><span>U</span>Update</button>
            <button class="hotkey_success" (click)="closeModal('cancel', null)" appShortcutKey [AltKey]="'c'"><span>C</span>Cancel</button>
        </div>
    </div>
</div>


<ng-template #warning let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">Similar record already exists; would you like to create another?</div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="editPharmacy()" appShortcutKey
                    *ngIf="isEdit" InputKey="y"><b>Y</b> YES
                </button>
                <button class="hotkey_primary" (click)="d('Cross click')" (click)="addNewPhar()" appShortcutKey
                    *ngIf="!isEdit" InputKey="y"><b>Y</b> YES
            </button>
                <button class="hotkey_primary" (click)="d('Cross click'); warningpopup = false;" appShortcutKey
                    InputKey="n"><b>N</b>NO</button>
            </div>
        </div>
    </div>
</ng-template>
