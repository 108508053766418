<div class="editpatient" *ngIf="otherValFG?.value">
    <div class="row content--heading" *ngIf="!IsFromNewRx">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png" />
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0">
                        Miscellaneous Info
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
         
            <button id="saveMiscInfo" class="hotkey_success" *ngIf="rxType === 'er'"
                (click)="saveOtherValueInfoFmEdit()" tabindex="20" appShortcutKey [AltKey]="'s'">
                <span>S</span> Save
            </button>
            <button class="hotkey_success" id="saveMiscInfo" *ngIf="
                    rxType === 'rf' ||
                    rxType === 'nrf' ||
                    rxType === 'nr'
                " (click)="saveOtherValueInfo()" tabindex="20" appShortcutKey [AltKey]="'s'">
                <span>S</span> Save
            </button>
            <button class="hotkey_success" *ngIf="rxType === 'er'" (click)="cancelOnEdit()"
                (blur)="foucsOnmiscServLevel($event)" tabindex="21" appShortcutKey [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
            <button class="hotkey_success" *ngIf="
                    rxType === 'rf' ||
                    rxType === 'nrf' ||
                    rxType === 'nr'
                " (click)="closeModal()" (blur)="foucsOnmiscServLevel($event)" tabindex="21" appShortcutKey
                [AltKey]="'c'">
                <span>C</span> Cancel
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-9">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Miscellaneous
                    </div>
                </div>
                <div class="eprx--block__content" >
                    <div class="row">
                        <div class="col-md-4">
                            <eprx-select [RxSelectId]="'miscServLevel'" [LabelText]="'Level Of Service'"
                                [PlaceHolder]="''" [ControlName]="'ServiceLvlId'" [secondCntrlN]="'ServiceLvlName'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.serviceLvlData" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [bindScnCntrlV]="'Remarks'"
                                [LabelTitle1]="'Code'" [LabelTitle2]="'Description'" [LabelForId]="'Id'"
                                [Show2Values]="true" [AutoFocus]="!FocusFieldId ? true : false">
                            </eprx-select>
                            <div class="row">
                                <div class="col-md-5">
                                    <eprx-input [LabelText]="'Interm. Authorize. ID'" [PlaceHolder]="'Intermediary Authorization ID'" [MaxLength]="'11'"
                                        [ControlName]="'InterimAuthCode'" [FormGroupName]="RXEXTRA">
                                    </eprx-input>
                                </div>
                                <div class="col-md-7">
                                    <eprx-select [RxSelectId]="'IntermAuthorizeType'" [LabelText]="'Intermediary Authorization Type'"
                                    [PlaceHolder]="''" [ControlName]="'InterimAuthCatId'"
                                    [secondCntrlN]="'IntermAuthCatName'" [FormGroupName]="RXEXTRA"
                                    [List]="systemData?.IntermAuthCatData" [BindLabel]="'Code'" [BindLabel2]="'Remarks'"
                                    [BindValue]="'Id'" [bindScnCntrlV]="'Remarks'" [LabelTitle1]="'Code'"
                                    [LabelTitle2]="'Description'" [LabelForId]="'Id'" [Show2Values]="true">
                                    </eprx-select>
                                </div>
                            </div>
                            <eprx-input [LabelText]="'Alternate ID'" [PlaceHolder]="''" [ControlName]="'AlternateCode'"
                                [FormGroupName]="RXEXTRA" [MaxLength]="20">
                            </eprx-input>
                            <div class="row">
                                <!-- <div class="col-2 m-17"
                                    *ngIf="(erxDetailsInRx && erxDetailsInRx['erxId'] && (rxType == 'er') && isElectornic)">
                                    <button class="btn btn-primary" (click)="openErxDetails()">eRx</button>
                                </div> -->
                                <div *ngIf="!IsFromNewRx" class="col-12">
                                    <eprx-select *ngIf="rxType !== 'er'" [LabelText]="'Rx Origin'" [PlaceHolder]="''"
                                        [ControlName]="'RxOriginId'" [FormGroupName]="this?.rxFG?.controls?.PrescReFill"
                                        [List]="systemData?.rxOriginsData" [BindLabel2]="'Name'" [BindLabel]="'Code'"
                                        [BindValue]="'Id'" [LabelForId]="'Id'" [LabelTitle1]="'Code'"
                                        [LabelTitle2]="'Description'" [ShowLabel2]="true" [IsDisabled]="isDisabled"
                                        (TriggerSelectValue)="saveOriginInMiscFG()" [Show2Values]="true">
                                    </eprx-select>
                                    <eprx-select *ngIf="rxType === 'er'" [LabelText]="'Rx Origin'" [PlaceHolder]="''"
                                        [ControlName]="'rxOriginId'" [secondCntrlN]="'RxOriginName'"
                                        [FormGroupName]="RXEXTRA" [List]="rxOriginList"
                                        [BindLabel2]="'Name'" [BindLabel]="'Code'" [BindValue]="'Id'"
                                        [bindScnCntrlV]="'Name'" [LabelForId]="'Id'" [LabelTitle1]="'Code'"
                                        [LabelTitle2]="'Description'" [ShowLabel2]="true" [IsDisabled]="isElectornic || (this.rxFG?.value?.isTransferIn)"
                                        (TriggerSelectValue)="saveOriginInRxFG()" [Show2Values]="true">
                                    </eprx-select>
                                </div>
                                <!--
                                Based EP-6581 on this card code commented.
                                <div class="col-2 pl-2" *ngIf=" ((rxType === 'er') && isElectornic)">
                                    <button class="btn btn-primary mt-4" (click)="openErxActionList()">
                                        Link
                                    </button>
                                </div> -->
                            </div>
                            <eprx-date-picker [LabelText]="'Rx Expiry Date'" [PlaceHolder]="''"
                                [ControlName]="'RxExpDt'" [FormGroupName]="RXEXTRA" [MinDate]="minDate"
                                (TriggerOnShiftTabbing)="undokeytab($event, RXEXTRA, 'RxExpDt')" [MaxDate]=""
                                (TriggerOnEnterValue)="validateOrderRxDate()" (TriggerOnTabbing)="validateOrderRxDate()"
                                (TriggerOnDateSelect)="validateOrderRxDate()"
                                (EmitAltClickValue)="validateOrderRxDate()" [ErrorDefs]="{
                                    required: 'Required',
                                    ngbDate: 'Required',
                                    minDate: rxExpiredDateWarMessage,
                                    maxDate: '',
                                    inValid: 'Invalid Date'
                                }">
                            </eprx-date-picker>
                            <div *ngIf="(rxType === 'er')">
                                <eprx-select [RxSelectId]="'treatmenttype'" [LabelText]="'Treatment Type'" [PlaceHolder]="'Treatment Type'"
                                    [ControlName]="'TreatmentTypeId'" [FormGroupName]="RXEXTRA" [secondCntrlN]="'TreatmentTypeName'"
                                    [List]="systemData?.treatmentTypes" [BindLabel]="'Code'" [BindLabel2]="'Description'"
                                    [bindScnCntrlV]="'Description'" [BindValue]="'Id'" [HasMultiple]="false"
                                    [Show2Values]="true" [show2ValInInputhidden]="true" [ErrorDefs]="{ required: 'Required'}" [IsRequired]="isReqState">
                                </eprx-select>
                            </div>
                            <div class="col-md-6 px-0" *ngIf="IsFromNewRx"> 
                                <eprx-radio [LabelText]="'Patient Assign Indicator'" [ControlName]="'IsPatAssignInd'"
                                    [FormGroupName]="RXEXTRA" [List]="['Yes', 'No']" [Name]="'IsPatAssignInd'"
                                    [ValueList]="[true, false]" [IDForList]="[
                                        'IsPatAssignInd1',
                                        'IsPatAssignInd2'
                                    ]" (TriggerSelectValue)="updateRxPatAssignVal()">
                                </eprx-radio>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6" *ngIf="!IsFromNewRx"> 
                                    <eprx-radio [LabelText]="'Patient Assign Indicator'" [ControlName]="'IsPatAssignInd'"
                                        [FormGroupName]="RXEXTRA" [List]="['Yes', 'No']" [Name]="'IsPatAssignInd'"
                                        [ValueList]="[true, false]" [IDForList]="[
                                            'IsPatAssignInd1',
                                            'IsPatAssignInd2'
                                        ]" (TriggerSelectValue)="updateRxPatAssignVal()">
                                    </eprx-radio>
                                </div>
                                <div class="col-md-6">
                                    <eprx-radio [LabelText]="'Include In MAR'" [ControlName]="'IsIncludedInMar'"
                                        [FormGroupName]="RXEXTRA" [List]="['Yes', 'No']" [Name]="'IsIncludedInMar'"
                                        [ValueList]="[true, false]" [IDForList]="[
                                            'IsIncludedInMar1',
                                            'IsIncludedInMar2'
                                        ]">
                                    </eprx-radio>
                                </div>
                            </div>
                            <eprx-input [LabelText]="'Insurance Specific Value'" [PlaceHolder]="'Insurance Specific Value'"
                                [ControlName]="'InsSpVal'" [FormGroupName]="RXEXTRA" [MaxLength]="20">
                            </eprx-input>

                            <eprx-select [LabelText]="'Batch File Billing Code'" [PlaceHolder]="''"
                                [ControlName]="'BatchFileBillCodeId'" [secondCntrlN]="'BatchFileBillName'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.batchFileBillCodesData"
                                [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'"
                                [bindScnCntrlV]="'Remarks'" [LabelForId]="'Id'" [Show2Values]="true">
                            </eprx-select>
                            <eprx-input [LabelText]="'Batch File Billing Ref'" [PlaceHolder]="''" [MaxLength]="15"
                                [ControlName]="'BatchFileBillRef'" [FormGroupName]="RXEXTRA">
                            </eprx-input>
                            <div class="row">
                                <div class="col-md-6">
                                    <eprx-radio [LabelText]="'Refill Reminder'" [ControlName]="'IdRefillReminded'"
                                        [FormGroupName]="RXEXTRA" [List]="['Yes', 'No']" [Name]="'IdRefillReminded'"
                                        [ValueList]="[true, false]" [IDForList]="[
                                            'IdRefillReminded1',
                                            'IdRefillReminded2'
                                        ]" (TriggerSelectValue)="updateRefillRemin()">
                                    </eprx-radio>
                                </div>
                                <div class="col-md-6 padding-0">
                                    <eprx-radio [LabelText]="'Use PA For Refills'"
                                        [ControlName]="'IsPAUsedForAllRefills'" [FormGroupName]="RXEXTRA"
                                        [List]="['Yes', 'No']" [Name]="'IsPAUsedForAllRefills'"
                                        [ValueList]="[true, false]" [IDForList]="[
                                            'IsPAUsedForAllRefills1',
                                            'IsPAUsedForAllRefills2'
                                        ]">
                                    </eprx-radio>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <eprx-input [LabelText]="'Max Daily Dose'" [PlaceHolder]="''"
                                        [ControlName]="'MaxDailyDose'" [FormGroupName]="RXEXTRA"
                                        (TriggerOnBlur)="saveMaxDoseVal()">
                                    </eprx-input>
                                </div>
                                <div class="col-md-6">
                                    <eprx-input [LabelText]="'Billing Days Supply'" [PlaceHolder]="''"
                                        [ControlName]="'BillDaysSup'" [FormGroupName]="RXEXTRA"
                                        (TriggerOnBlur)="saveBillDaysSupplyVal()">
                                    </eprx-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 padding--left__7">
                            <eprx-select [LabelText]="'Subm. Clarification 1'" [PlaceHolder]="''"
                                [ControlName]="'SubClarifId1'" [secondCntrlN]="'SubClarification1Name'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.subClarificationData" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [bindScnCntrlV]="'Remarks'" [IsTitle]="true" [BindValue]="'Id'"
                                [LabelForId]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                [Show2Values]="true"
                                (TriggerSelectValue)="checkIsSubClarfExist($event, 'SubClarifId1')">
                            </eprx-select>
                            <eprx-select [LabelText]="'Subm. Clarification 2'" [PlaceHolder]="''"
                                [ControlName]="'SubClarifId2'" [secondCntrlN]="'SubClarification2Name'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.subClarificationData" [BindLabel]="'Name'"
                                [IsTitle]="true" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [bindScnCntrlV]="'Remarks'"
                                [LabelForId]="'Id'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                [Show2Values]="true"
                                (TriggerSelectValue)="checkIsSubClarfExist($event, 'SubClarifId2')">
                            </eprx-select>
                            <eprx-select [LabelText]="'Subm. Clarification 3'" [PlaceHolder]="''"
                                [ControlName]="'SubClarifId3'" [secondCntrlN]="'SubClarification3Name'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.subClarificationData" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [bindScnCntrlV]="'Remarks'"
                                [LabelForId]="'Id'"  [IsTitle]="true" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                [Show2Values]="true"
                                (TriggerSelectValue)="checkIsSubClarfExist($event, 'SubClarifId3')">
                            </eprx-select>
                            <eprx-select [LabelText]="'Other Coverage Cd'" [PlaceHolder]="''"
                                [ControlName]="'OtherCovrgId'" [secondCntrlN]="'OtherCoverageName'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.otherCoverageData" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [IsTitle]="true" [bindScnCntrlV]="'Remarks'"
                                [LabelForId]="'Id'"[LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                [Show2Values]="true">
                            </eprx-select>
                            <eprx-select [LabelText]="'Delay Reason Cd'" [PlaceHolder]="''"
                                [ControlName]="'DelayReasonId'" [secondCntrlN]="'DelayReasonName'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.delayReasonsData" [BindLabel]="'Name'"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [IsTitle]="true" [LabelForId]="'Id'"
                                [LabelTitle1]="'Code'" [LabelTitle2]="'Description'" [Show2Values]="true">
                            </eprx-select>
                            <eprx-select [LabelText]="'Ph. Service Type'" [PlaceHolder]="''"
                                [ControlName]="'PhServiceCatId'" [secondCntrlN]="'PharmServiceCatName'"
                                [FormGroupName]="RXEXTRA" [List]="systemData?.pharmServiceCatData" [BindLabel]="'Code'"
                                [BindLabel2]="'Name'" [BindValue]="'Id'" [bindScnCntrlV]="'Name'" [LabelForId]="'Id'"
                                [IsTitle]="true" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'" [Show2Values]="true">
                            </eprx-select>
                        </div>
                    </div>

                    <div class="row"></div>
                </div>
            </div>
            <div class="row ">
                <div class="col-8 pr-0">
                    <div class="exprx--common-block">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Proc Modifier
                            </div>
                        </div>
                        <div class="eprx--block__content" [formGroup]="otherValFG">
                            <div class="row" formArrayName="procModifier">
                                <div class="col-md-4" *ngFor="
                                        let procInfo of PROCMODIFIER?.controls;
                                        let i = index
                                    ">
                                    <eprx-input [LabelText]="'Procedure Modifiers '+ (i+1)" [PlaceHolder]="''" [MaxLength]="'2'"
                                        [ControlName]="'CPTCode'" [FormGroupName]="procInfo" [InputType]="'ALPHANUMERIC'"
                                        (TriggerOnTabbing)="checkIndexandProceed($event, i+1)"
                                        [RxSelectId]="'Procedure Modifiers'+ (i+1)" (TriggerOnBlur)="checkProcDuplicate(i)">
                                    </eprx-input>
        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4 pl-0">
                    <div class="exprx--common-block   pl-1" >
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                      
                            </div>
                        </div>
                        <div class="eprx--block__content "> 
                            <div class="col-12 px-0">
                                <eprx-input
                                    [RxSelectId]="'rxPriorAuthNum'"
                                    [LabelText]="'Prior Auth'"
                                    [PlaceHolder]="''"
                                    [ControlName]="'PriorAuthNum'"
                                    [FormGroupName]="this?.rxFG?.controls?.PrescReFill"
                                    [TabIndex]="0"
                                    [IsDisabled]="isDisabled"
                                    (TriggerOnShiftTabbing)="
                                        undokeytab(
                                            $event,
                                            this?.rxFG?.controls?.PrescReFill,
                                            'PriorAuthNum'
                                        )
                                    "
                                >
                                </eprx-input>
                            </div>
                            <div class="col-12 px-0">
                                <eprx-input
                                    [RxSelectId]="'rxPriorType'"
                                    [LabelText]="'Auth. Type'"
                                    [PlaceHolder]="''"
                                    [ControlName]="'PAType'"
                                    [FormGroupName]="this?.rxFG?.controls?.Prescription"
                                    [TabIndex]="0"
                                    [MaxLength]="2"
                                    [InputType]="'ALPHANUMERIC'"
                                    [IsDisabled]="isDisabled"
                                    (TriggerOnShiftTabbing)="
                                        undokeytab(
                                            $event,
                                            this?.rxFG?.controls?.Prescription,
                                            'PAType'
                                        )
                                    "
                                >
                                </eprx-input>
                            </div>      
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Prescribed Information
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <eprx-select [RxSelectId]="'PrescOrgPresc'" [LabelText]="'Originally Prescribed Product Type'"
                                [updateDropDownPos]="true" [PlaceHolder]="''" [ControlName]="'OrgPrescProdCatId'"
                                [secondCntrlN]="'OrgPrescProdCatName'" [FormGroupName]="PRESCINFO"
                                [List]="systemData?.orgPrescProdCatsData" [BindLabel]="'Name'" [IsTitle]="true"
                                [BindLabel2]="'Remarks'" [BindValue]="'Id'" [bindScnCntrlV]="'Remarks'"
                                [LabelForId]="'PrescOrgPresc1'" [LabelTitle1]="'Code'" [LabelTitle2]="'Description'"
                                [Show2Values]="true">
                            </eprx-select>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 erx--body">
                            <div class="seconday-search--grid">
                                <div class="row">
                                    <div class="col-12 color_cyan">Originally Prescribed Product</div>
                                    <!-- <div class="col-8">
                                        <div class="text-right">
                                            <div><i class="far fa-lg fa-long-arrow-alt-right"
                                                    style="color: rgb(247, 148,29);"></i><span> for Equv ;
                                                </span><b style="color: rgb(247, 148,29);">Shift + <i
                                                        class="far fa-lg fa-long-arrow-alt-right"
                                                        style="color: rgb(247, 148,29);"></i></b><span> for Gen Equv</span></div>
                                        </div>
                                    </div> -->
                                </div>
                                <app-searchbox-global id="DrugRx" [RxSelectId]="'DrugRx'" [SearchFor]="'Drug'"
                                    [IsAddNew]="false" [DisableSerchFil]="true" [DisableRadioButtons]="true"
                                    (SelectedDrug)="selectDrug($event)"
                                    [InputValue]="PRESCINFO?.value?.OrgPrescDrugName" [ShowOnlyDrugName]="true" [SearchFrm]="'commonDrugSearch'">
                                </app-searchbox-global>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <eprx-input [LabelText]="'Originally Prescribed Product NDC'" [PlaceHolder]="'Originally Prescribed Product NDC'"
                                [ControlName]="'OrgPrescProd'" [FormGroupName]="PRESCINFO" [IsDisabled]="true"
                                [MaskPattern]="'AAAA0-0000-00'" [MinLength]="10" [ErrorDefs]="{minlength: 'invalid'}">
                            </eprx-input>
                        </div>
                        <div class="col-md-12">
                            <eprx-input [LabelText]="'Originally Prescribed Quantity'" [PlaceHolder]="'Originally Prescribed Quantity'"
                                [ControlName]="'OrgPrescQty'" [FormGroupName]="PRESCINFO" [MaxLength]="5">
                            </eprx-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Coupon Information
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-md-12">
                            <eprx-select [LabelText]="'Coupon Type'" [PlaceHolder]="''" [ControlName]="'CouponCatId'"
                                [secondCntrlN]="'CouponCatName'" [FormGroupName]="COUPONINFO"
                                [List]="systemData?.couponCatsData" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"
                                [BindValue]="'Id'" [IsTitle]="true" [bindScnCntrlV]="'Remarks'" [LabelForId]="'Id'"
                                [LabelTitle1]="'Code'" [LabelTitle2]="'Description'" [Show2Values]="true">
                            </eprx-select>
                        </div>
                        <div class="col-md-12">
                            <eprx-input [LabelText]="'Coupon Number'" [PlaceHolder]="''" [ControlName]="'CouponNum'"
                                [FormGroupName]="COUPONINFO" [MaxLength]="15">
                            </eprx-input>
                        </div>
                        <div class="col-md-12">
                            <eprx-input [RxSelectId]="'CoupVal1'" [LabelText]="'Coupon Value'" [PlaceHolder]="''"
                                [ControlName]="'CouponVal'" [FormGroupName]="COUPONINFO" [InputType]="'CURRENCY'"
                                [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerOnTabbing)="checkIndexandProceed($event)">
                            </eprx-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Diagnosis Code
                    </div>
                </div>
                <div class="eprx--block__content" [formGroup]="otherValFG">
                    <div class="row" formArrayName="diagCode">
                        <div class="col-md-3" *ngFor="
                                let diagInfo of DIAGCODE?.controls;
                                let i = index
                            ">
                            <div class="row">
                                <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                                    <eprx-select [RxSelectId]="'diagCode' + (i+1)"
                                        [LabelText]="'Diagnosis Code ' + (i+1)" [PlaceHolder]="''"
                                        [ControlName]="'DiagnosisId'" [FormGroupName]="diagInfo" [IsTitle]="true"
                                        [DropdownPosition]="'top'" [BindLabel]="'DIAGCODE'" [BindLabel2]="'DIAGDESCR'"
                                        [BindValue]="'DIAGCODE'" [LabelForId]="'DIAGCODE' + (i+1)"
                                        (TriggerSelectValue)="checkIsExist($event?.value, i)" 
                                        [List]="patDiagnis?.TempDiagnosis?.length > 0 ? patDiagnis?.TempDiagnosis: ''"
                                        [InputValue]="''" [HasMultiple]="false" [HasBindLabel2]="true" 
                                        [outsideVal]="true" (TriggerOnTabbing)="checkIndexandProceed($event, i+1)"  [show2ValInInputhidden]="true">
                                    </eprx-select>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 pl-0 padding_right_0_5rem" 
                                    >
                                    <eprx-input [LabelText]="'Qualifier'" [HasControl]="false"
                                        [InputValue]="diagInfo?.value['DiagnosisId'] ? 'ICD10' : null"
                                        [ReadOnly]="true"></eprx-input>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding-0 padding_top_25px edit--drug">
                                    <i class="far fa-search fa-lg actions" (click)="openDiagList(i)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="eprx--header__heading">
                        Proc Modifier
                    </div>
                </div>
                <div class="eprx--block__content" [formGroup]="otherValFG">
                    <div class="row" formArrayName="procModifier">
                        <div
                            class="col-md-6"
                            *ngFor="
                                let procInfo of PROCMODIFIER?.controls;
                                let i = index
                            "
                        >
                        <eprx-input [LabelText]="'Procedure Modifiers '+ (i+1)" [PlaceHolder]="''" [MaxLength]="'2'"
                            [ControlName]="'CPTCode'" [FormGroupName]="procInfo" [InputType]="'ALPHANUMERIC'"
                            (TriggerOnTabbing)="checkIndexandProceed($event, i)"
                            [RxSelectId]="'Procedure Modifiers'+ i" (TriggerOnBlur)="checkProcDuplicate(i)">
                        </eprx-input>

                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="row mt-4">
        <div class="text-right col-6 offset-6">
            <button id="saveMiscInfo" class="hotkey_primary" *ngIf="IsFromNewRx"
            (click)="saveOtherValueInfoFmEdit()" tabindex="20" appShortcutKey [CtrlKey]="'o'">
            <span>o</span> OK
        </button>

        </div>
    </div>
</div>
<app-erx-result *ngIf="isErxResultShow" (IsPopUpClosed)="isErxResultShow=false"
    [detailsInfoObject]="{'eRxVersion' : eRxVersion , 'ID':erxDetailsInRx['erxId'], 'RxNo' : erxDetails['RxNum']}">
</app-erx-result>
<ng-template #diagList let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Diagnosis List</h4>
    </div>
    <div class="modal-body">
        <app-diag-list [FmChangeReq]="true" [isPopup]="true" (emitOnDbClick)="saveandCloseDiagList($event)">
        </app-diag-list>
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_success" (click)="d('Cross click')" appShortcutKey [AltKey]="'c'">
                    <span>C</span> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #ErxwarningMessage let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeErxModal()">
        <h4 header>{{isErxMessageWarning?.header}}</h4>
        <span body>
            <div>{{isErxMessageWarning?.body}}</div>
            <div>{{isErxMessageWarning?.body1}}</div>
        </span>
        <div footer class="row position-relative w-100 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button ngbAutofocus class="hotkey_primary" (click)="goToUnlinkErxMessageLink()" appShortcutKey
                    InputKey="y"><b>Y</b> YES</button>
                <button class="hotkey_primary" (click)="closeErxModal()" appShortcutKey InputKey="n">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>
