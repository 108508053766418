import { Injectable } from "@angular/core";


@Injectable({
    providedIn: "root"
})

export class FormatsUtil {

    constructor() { }
    
    getNDCFormat(drugNdc, IsformatNeeded: boolean = true) {
        if(IsformatNeeded && drugNdc)
        {
            if(drugNdc?.length  == 11 || drugNdc?.length  == 10) {
                return drugNdc ? (drugNdc.substr(0, 5)) + "-" + (drugNdc.substr(5, 4)) + "-" + (drugNdc.substr(9, 11)) : null;
            } else if (drugNdc.length  <= 5) {
                return  drugNdc.substr(0, 5) + "-  -";
            }  else if(drugNdc.length  > 5 && drugNdc.length  <= 9) {
                return   (drugNdc.substr(0, 5)) + "-" + (drugNdc.substr(5, 4)) + "-"; 
            } 
        }
        else 
            return  drugNdc ? drugNdc : null;
     }
     getPhoneFormat(number) {
         if (number) {
         return ( ((number && number[0] === '(') ?  "" : "(") + (number.substr(0, 3)) +  ((number && number[4] === ')') ?  "" : ")") + (number.substr(3, 3)) + "-" + (number.substr(6, 4)));
         } else {
             return "";
         }
     }
     getPhoneFormatForWrongFax(number) {
         if (number) {
         return ( ((number && number[0] === '(') ?  "" : "(") + (number.substr(0, 3)) +  ((number && number[4] === ')') ?  "" : ")") + (number.substr(3, 3)) + "-" + (number.substr(6, 5)));
         } else {
             return "";
         }
     }
     checkRefill(rx: string, aref: number, ref: number) {
        if (ref !== 0) {
            return rx + " - R";
        } else {
            return rx;
        }
    }
    
    getDeaFormat(dea) {
        return  (dea && dea.length > 10 && !dea.includes("-")) ? (dea.substr(0, 9)) + "-" + (dea.substr(9, 5)) : dea;
    }

    twoFixed(value) {
         return value ? value.toFixed(2) : "0.00";
    }
    
    threeFixed(value) {
        return value ? value.toFixed(3) : "0.000";
    }

}