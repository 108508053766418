import { Component, OnInit,Input, ChangeDetectorRef } from '@angular/core';
import { WijimoUtil } from 'src/app/utils';
import { CollectionView } from "@grapecity/wijmo";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { FormatsUtil } from 'src/app/utils/formats.util';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-transfer-request-details',
  templateUrl: './transfer-request-details.component.html',
  styleUrls: ['./transfer-request-details.component.scss']
})
export class TransferRequestDetailsComponent implements OnInit {
  totalCount: number;
  searchRequestedList: CollectionView;
  unsubscribe$ : Subject<void> = new Subject();
  wjHeaders: any;
  isDataExists = true;
  transferTypedeatails: string;
  @Input()
    set SelectedTransferTypeDetails(data: any) {
      this.transferTypedeatails = null;
      this._cdr.detectChanges();
      this.transferTypedeatails = data;
      if(data && data !== 'All')
        this.generateList(data);        
    } 

    
  constructor( private _wijimoUtils: WijimoUtil, 
               private _formatsUtil:FormatsUtil,
               private _decimalPipe: DecimalPipe,
               private _cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.patchDefaultValHeaders();
    this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
      if (w && w["WijmoKey"] && w["WijmoKey"] === "drugRequesteDetails") {
          this.patchDefaultValHeaders();
      }
  });

  }
  async patchDefaultValHeaders() {
    const storedWJ = await this._wijimoUtils.getWJSavedData("drugRequesteDetails");
    this.wjHeaders = this._wijimoUtils.patchDefHeader("drugRequesteDetails", storedWJ);
    
}
  generateList(drug: any) {
    const mapDataSet = [];
    if(drug)
    {
      let j = {};
      const fulldrugName = drug.drugname + (drug?.strength ? drug?.strength: '') + (drug?.drugformname ? drug?.drugformname: '');
      j["Drug Name"] = fulldrugName.toUpperCase();
      j["Drug Class"] = drug?.drugclass;
      j["NDC"] =  this._formatsUtil.getNDCFormat(drug.ndc); 
      j["Brand"] = drug?.isbranded === true ? "Yes" : "No";
      j["Drug "] = drug?.qtypack;
      j["Size"] = drug?.qtypack ? drug?.qtypack.toFixed(3) : "0.000"
      j["Manufacturer"] = drug?.manufacturername;
      j["AWP"] = drug?.unitpriceawp ? "$" + this._decimalPipe.transform(drug.unitpriceawp, "1.3-3") : "$0.000";
      j["Cost"] = drug?.unitpricecost ? "$" + this._decimalPipe.transform(drug.unitpricecost, "1.4-4") : "$0.0000";
      mapDataSet.push(j);
      this.searchRequestedList = new CollectionView(mapDataSet,
        {
          sortComparer: (a: any, b: any) => {
            return moment(a).isValid() && moment(b).isValid()
              ? moment(a).diff(moment(b)) : null;
          }
        });
    }
  }


}
