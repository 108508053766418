import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from "@angular/core"; 
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { CommonUtil, WijimoUtil } from "src/app/utils";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { StorageService } from "src/app/services/storage.service";
import { FormGroup } from "@angular/forms";
import { CommonService } from "src/app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonWarnorconfirmPopupComponent } from "../common-warnorconfirm-popup/common-warnorconfirm-popup.component";
import { MsgConfig } from 'src/app/app.messages';
import { PatientInfoUtil } from "src/app/utils/patient-info.util";

@Component({
    selector: "app-patient-card",
    templateUrl: "./result-card.component.html"
})
export class ResultCardComponent implements OnInit {
    unsubscribe$: Subject<void> = new Subject();
    @ViewChild("patientflex") patientflex: wjcGrid.FlexGrid;
    @Input() index: any;
    @Input() searchTerm: any;
    modelRefWarn: any;
    @Input()
    set ResultData(rd: any) {
        if (rd) {
            this.inputData = rd;
            if (this.inputData && this.inputData.insurance && this.inputData.insurance.length > 0) {
                this.primaryInsu = this.inputData.insurance.filter(x =>
                    x.insuranceid === +this.inputData.insuranceid)[0];
            }
        }
    }
    @Input() PatientResultData;
    @Input() searchFrmPage;
    @Input() PatientSuggestFilterFG: FormGroup;
    patientSugList: any;
    patientSuggestWJ:CollectionView;
    patientListwjHeaders: any[];
    isPopUp: boolean;
    patientSugListCopy: any;
    required: boolean;
    patientResp: boolean;
    progressValue: number;
    isDataExists: boolean;
    modelRef: any;
    patientNameBackup: any;
    @Input()
    set RouterURL(ru: string) {
        this.routerURL = ru;
    }

    @Input()
    set IsAllToShow(all: boolean) {
        this.isAllToShow = all;
    }

    @Input()
    set ShowOnlyPatName(patName: boolean) {
        this.showPatName = patName;
    }

    @Input()
    set IsPopUp(ip: boolean) {
        this.isPopUp = ip;
    }

    @Output() SelectedPatient = new EventEmitter();
    @Output() ClosePatientDrugPage = new EventEmitter();
    @Output() GetSelectedPatient = new EventEmitter();    
    @Output() ClosePatientResultModal = new EventEmitter();
    @Output() AddNewPatient = new EventEmitter();
    
    inputData: any;
    routerURL: string;
    isAllToShow: boolean;
    showPatName: boolean;
    primaryInsu: any;
    loading: boolean;
    constructor(
        private _wijimoUtils: WijimoUtil, public _storageService: StorageService, private _commonUtil: CommonUtil, private _cdr: ChangeDetectorRef,
        private _commonServ: CommonService, private _modalService: NgbModal,private _patientUtils: PatientInfoUtil
    ) {}

    ngOnInit() {
        sessionStorage.setItem("fromDrugGrid","true");
                this.patchDefaultValHeaders();
                this._wijimoUtils.wjSavedData$
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(w => {
                    if (w && w["WijmoKey"] && w["WijmoKey"] === "patientSuggestListWj") {
                        this.patchDefaultValHeaders();
                    }
                });
        if(this.PatientResultData?.length) {
            this.patientSugList = this.patientSugListCopy = this.PatientResultData;
            this.generatePatientWijGrid(this.patientSugList);
            this.focusFirstGrid();
        }
        this.patientNameBackup = this.PatientSuggestFilterFG.value['PatientName']
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("patientSuggestListWj");
        this.patientListwjHeaders = this._wijimoUtils.patchDefHeader("patientSuggestListWj", storedWJ);
    }

    onRowSingleClick(grid,e){
        var hti = grid.hitTest(e);
        if (hti && hti.row >=0 && grid.selectedRows && grid.selectedRows.length && grid.selectedRows[0].dataItem && hti.cellType === 1) {
            let pat = grid.selectedRows[0].dataItem;
            this.getselectedPatientByCheck(pat);
        }
    }

    getselectedPatientByCheck(pat) {
        if(this.patientSugListCopy?.length) {
            let patient = this.patientSugListCopy.find(val => (val?.patientid === pat?.patientid));
            if(patient && patient["fullname"] && (patient["actstatus"] !== 1)) {
                this.getCommonWarnMsg(patient)
            }
            else if(patient) {                       
                this.GetSelectedPatient.emit(patient);
                sessionStorage.removeItem("fromDrugGrid");
            }
            this.generatePatientWijGrid(this.patientSugListCopy);
            this._cdr.detectChanges();
            if (this.patientflex) {
                this.patientflex.select(-1, -1);
            } 
        }
    }

    getCommonWarnMsg(item) {
        const patientName = item.fullname ? ((item.fullname).toUpperCase()).replace("_", ", ") : "";
        this.modelRefWarn = this._modalService.open(CommonWarnorconfirmPopupComponent, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
        this.modelRefWarn.componentInstance.IsHeaderText = MsgConfig.Inactive_Patient;                    
        this.modelRefWarn.componentInstance.warnMsg = "Selected Patient <label><span class='font-weight-bold'>" + patientName  +"</span></label> is not an Active Patient.";
        this.modelRefWarn.componentInstance.okButton = true;
        this.modelRefWarn.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if (this.modelRefWarn) {
                this.modelRefWarn.close();
                this.modelRefWarn = null;
            }
            if (resp) { 
                this.modelRefWarn.close();
                this.modelRefWarn = null;
            }
        });
    }

    selectedPatient(patient: any): any {
        this.SelectedPatient.emit({patient});
    }

    onEnterClick(grid,e) {
        this.getselectedPatientByCheck(grid);
        e.preventDefault();
    }

    generatePatientWijGrid(SendData) {
        this.patientSuggestWJ = new CollectionView(SendData?.map((pat: any, i) => {
            const k = {};
            k["RowId"] = i;
            k["Patient Name"] = pat?.lastname +', '+ pat?.firstname
            k["DOB"] = pat?.dob ? pat?.dob : '--';
            k["Gender"] = pat?.gender ? pat?.gender : '--';
            k["Ins. ID"] = pat?.insurance?.length ? this.getInsuId(pat) : '--';
            k["Ins. Code"] = pat?.insurance?.length ? this.getInsuType(pat) : '--';
            k["Chart#"] = pat?.chartnum ? pat?.chartnum : '--';
            k["Facility Code"] = pat?.PatientFacilityInfo?.Organization?.Name ? pat?.PatientFacilityInfo?.Organization?.Name : '--';
            k["Phone#"] = pat?.telephone && pat?.telephone !== "###" ?  this._commonUtil.getPhoneFormat(pat?.telephone.trim()) : "--";
            k["Address"] = this._patientUtils.getPatientAddress(pat)
            k["Email"] = pat?.email ? pat?.email : '';
            k["patientid"] = pat?.patientid;
            k["actstatus"] = pat?.actstatus;
            k["fullname"]= pat?.fullname;     
            k["isSelected"] = false;
            return k;
        }))
    }

    getInsuId(inputData) {
        let insId = null;
        if (inputData && inputData.insurance && inputData.insurance.length > 0) {
            insId = inputData.insurance.filter(x => x.insuranceid === +inputData.insuranceid)[0];
            return insId?.policynum;
        }
        
    }

    getInsuType(inputData) {
        let insType = null;
        if (inputData && inputData.insurance && inputData.insurance.length > 0) {
            insType = inputData.insurance.filter(x => x.insuranceid === +inputData.insuranceid)[0];
            return insType?.insuType;
        }
        
    }

    Init(flex: wjcGrid.FlexGrid) {
            flex.columnHeaders.rows[0].wordWrap = true;
            flex.autoSizeRow(0, true);
            flex.columnHeaders.rows[0].height = 30;    
            flex?.cells.hostElement.addEventListener('keydown', (e) => {
                if (e.key == 'Enter') {
                    this.onEnterClick(flex.collectionView["_src"][flex.selection.row],e);
                }
            });
        }
    
        itemFormatter = (panel, r, c, cell) => {
            const row = panel.rows[r];
            if (row.dataItem) {
                cell.style.color = "#000";
                cell.style.background = "#FFF";
                cell.style.fontWeight = "400";
            }
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Patient Name") {
                if(row.dataItem && row.dataItem?.actstatus !=1 ) {
                    cell.style.color = "#000";
                    cell.style.background = "linear-gradient(to left, transparent 98%, #cc0033 2%)";
                    cell.style.paddingLeft = "12px"
                    cell.style.fontWeight = "700";
                } else {
                    cell.style.color = "#000";
                    cell.style.paddingLeft = "12px"
                    cell.style.background = "#FFF";
                    cell.style.fontWeight = "700";
                }
            } else {
                cell.style.color = "#000";
                cell.style.paddingLeft = "10px"
                cell.style.background = "#FFF";
                cell.style.fontWeight = "400";
            }
            if (panel.cellType == wjcGrid.CellType.ColumnHeader && r == 0) {
                cell.style.backgroundColor = "#EAEAEA";
                cell.style.color = "#000";
                cell.style.lineHeight = "24px";
                cell.style.fontWeight = "700"
            }
        }
    
    getGlobalSearchPatientSuggest() {
        if(this.PatientSuggestFilterFG.value['PatientName']?.length > 2) {
            let patientName = this.PatientSuggestFilterFG.get('PatientName').value.trim();
            if (document.getElementById('loader'))
                document.getElementById('loader').style.display = 'block';
                this.patientResp = false;
                this.startProgress();
                this.PatientSuggestFilterFG.get('PatientName').setValue(patientName);
            let suggData = this._commonServ.getPatientsuggest(this.PatientSuggestFilterFG.value['PatientName']);
            this.getPatientSugestData(suggData)
            this.required = false;
        } else {
            this.loaderDisplay();
            this.required = true;
        }
    }

    getPatientSugestData(patObserv: Observable<any>) {
        patObserv.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
                if (resp && resp?.length) {
                    this.loaderDisplay();
                    this.patientSugList = resp;
                    this.patientSugListCopy = resp;
                    this.focusFirstGrid();
                    this.generatePatientWijGrid(this.patientSugList);               
                } else {
                    this.modelRef = this._modalService.open(CommonWarnorconfirmPopupComponent, {centered: true, keyboard: false, backdrop: false, windowClass: "large--content"});
                    this.modelRef.componentInstance.IsHeaderText = 'Search Patient';                    
                    this.modelRef.componentInstance.warnMsg = "<div><b>Patient: </b> <span class='font-bigger text-primary word_break'>"+this.PatientSuggestFilterFG.value['PatientName']+"</span>. <span>Would you like to add?</span></div>";
                    this.modelRef.componentInstance.yesButton = true;
                    this.modelRef.componentInstance.noButton = true;
                    this.modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
                        if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        }
                        if (resp) { 
                            this.AddNewPatient.emit(this.PatientSuggestFilterFG.value["PatientName"])
                        }
                        else if (!resp) { 
                            if (this.modelRef) {
                            this.modelRef.close();
                            this.modelRef = null;
                        } }
                    });

                    this.loaderDisplay();
                    this.patientSugList = [];
                    this.patientSugListCopy = [];
                    this.isDataExists = false;
                    this.generatePatientWijGrid(this.patientSugList);
                }
        })
    }

    startProgress() {
        this.loading = true;
        this.progressValue = 0;
        const interval = setInterval(() => {
            if (this.patientResp) {
                this.progressValue = 100;
                clearInterval(interval);
            } else {
                this.progressValue += 25;
            }
        }, 100);  
    }

    loaderDisplay() {
        this.patientResp = true;
        if (this.patientResp) {
            setTimeout(()=>{
                this.loading = false;
                if (document.getElementById('loader'))
                    document.getElementById('loader').style.display = 'none';
            }, 200)
        }
    }
    
    clearFilters() {
        this.PatientSuggestFilterFG.controls['PatientName'].patchValue(null);
        this.PatientSuggestFilterFG.controls['ActivePatient'].patchValue(false);
        this.getActivePatientList(false)
    }

    closePateintModal() {
        this.ClosePatientResultModal.emit(true);
        this.clearFilters();
    }

    clearedBySearchIcon() {
        this.required = false;
        this.PatientSuggestFilterFG.controls['PatientName'].patchValue(null);
        this.generatePatientWijGrid(this.patientSugListCopy);
        this.focusToFirst();
    }

    async getPatientBySearch() {
        let patientName = this.PatientSuggestFilterFG.value['PatientName'];
        if(patientName?.length > 2) this.required = false;
        if (patientName) {
            patientName = patientName ? (patientName + '').toLowerCase().trim() : "";                    
                this.patientSugList = this.patientSugListCopy?.filter((item: any) => {
                    item["firstname"] = item["firstname"] ? ((item["firstname"] + '').toLowerCase()) : "";
                    item["lastname"] = item["lastname"] ? ((item["lastname"] + '').toLowerCase()) : "";
                    item["dob"] = item["dob"] ? ((item["dob"] + '').toLowerCase()) : "";
                    item["gender"] = item["gender"] ? ((item["gender"] + '').toLowerCase()) : "";
                    item["chartnum"] = item["chartnum"] ? ((item["chartnum"] + '').toLowerCase()) : "";
                    item["email"] = item["email"] ? ((item["email"] + '').toLowerCase()) : "";
                    const address =  this._patientUtils.getPatientAddress(item).toLowerCase();
                    const orginalPatientName = item["lastname"] + ", " + item["firstname"];
                    const phone =  this._commonUtil.getPhoneFormat(item?.telephone.trim())
                    const insuId = this.getInsuId(item);
                    const insuType = this.getInsuType(item);
                    const facilityCode = item?.PatientFacilityInfo?.Organization?.Name? item["PatientFacilityInfo"]["Organization"]["Name"] : '';                    
                    return (orginalPatientName.includes(patientName) || item["dob"].includes(patientName) || item["gender"].includes(patientName) || item["chartnum"].includes(patientName) || item["email"].includes(patientName) || address.includes(patientName) || phone.includes(patientName) || facilityCode.includes(patientName) || insuId.includes(patientName) || insuType.includes(patientName));
                });
                this.generatePatientWijGrid(this.patientSugList);
                } else {  this.generatePatientWijGrid(this.patientSugListCopy); }
    }

    getActivePatientList(e) {
        if(e) {
            this.patientSugList = this.patientSugListCopy?.filter((item: any) => {                  
                return item["actstatus"] === 1;
            });
            this.generatePatientWijGrid(this.patientSugList);
        } else {
            this.generatePatientWijGrid(this.patientSugListCopy);
        }
    }

    focusToFirst(e?) {
        if (document.getElementById("PatientName")) {
            this._cdr.detectChanges();
            document.getElementById("PatientName").focus();
            if(e) e.preventDefault();
        }
    }

    focusFirstGrid(): void {
            // Need SetTimeout
            setTimeout(() => {
                if (this.patientflex && this.patientflex.rows.length > 0) {
                this.patientflex.selection = new wjcGrid.CellRange(this.patientflex.selection._row, 0);
                this.patientflex.focus();
                }
            },100);
        }

    ngOnDestroy() {
        sessionStorage.removeItem("fromDrugGrid");
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this._storageService.getWijmoGridDataInSession(this.patientListwjHeaders,"patientSuggestListWj")
    }
}
