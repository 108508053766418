<!-- <div class="row mt-1" *ngIf="hasPatientHistory">
    <div class="col-12 text-right">
        <button  class="submit print-med-guide" style="background: rgb(247, 148,29)" (click)="toggleColorLegend()">Color Legend</button>
    </div>
</div> -->
<div class="exprx--common-block" *ngIf="
        ((activeScreen === 'rx') ||
            activeScreen !== 'rx') || !showExpiredRx || isFromModal
    " id="patientHistoryGrid">
    <div class="eprx--block__header">
        <div class="eprx--header__heading">
            Rx History
            <div class="pat-hist--header" *ngIf="activeScreen !== 'DocQueue'">
                <span class="rx--filter_text" *ngIf="!fromRph">
                    DrugName/NDC: &nbsp;
                </span>
                <form autocomplete="off" class="margin_top_n5px">
                    <eprx-input class="height_10px margin_top_n5px" *ngIf="!fromRph" [FormGroupName]="FilterFG" [ControlName]="'drugName_NDC'"
                     [DebounceTime]="1000" [RxSelectId]="'DrugName_NDC#Fil'"
                    [PlaceHolder]="'Enter DrugName/NDC'" (TriggerOnChange)="drugFilter($event.target.value)">
                </eprx-input>
                </form>
                <span class="rx--filter_text" *ngIf="activeScreen !== 'DocQueue' && !fromRph && !(isSelectedType === 'Current')">
                    <button *ngIf="showExpiredRx" type="button" class="secondary" (click)="showExpiredRefills()">
                        Show Exp. Ref
                    </button>
                    <button *ngIf="!showExpiredRx" type="button" class="secondary" (click)="showAllRefills()">
                        Show All
                    </button>
                </span>
                <span class="rx--filter_text" *ngIf="activeScreen !== 'DocQueue' && !fromRph">
                    <button *ngIf="showRefills" type="button" class="secondary header__btn" (click)="showRefill()">
                        Show Refill
                    </button>
                    <button *ngIf="!showRefills" type="button" class="secondary header__btn" (click)="showAllRefills()">
                        Show All
                    </button>
                </span>

                <span class="rx--filter_text" *ngIf="activeScreen !== 'DocQueue' && !fromRph">
                    <button *ngIf="showDue" type="button" class="secondary header__btn" (click)="showDueRefills()">
                        Select Due
                    </button>
                </span>
                <span class="rx--filter_text" *ngIf="isLogicoyReg && SearchParams">
                    <button type="button" class="secondary header__btn" (click)="PMPNow()">PMP Now</button>
                </span>
                <span *ngIf="showDateRange && !fromRph" class="rx--filter_text">&nbsp; &nbsp; Date Range: &nbsp;</span>
                <app-rx-select *ngIf="activeScreen !== 'DocQueue' && !fromRph" [LabelText]="''" [PlaceHolder]="''"
                    [ControlName]="'dateRng'" [FormGroupName]="FilterFG" [HasBindLabel2]="false" [HasMultiple]="false"
                    [List]="dateFilter" [BindLabel]="'name'"
                    (TriggerSelectValue)="setDate($event?.value?.target?.value)" [BindValue]="'name'"
                    [LabelForId]="'name'">
                </app-rx-select>
                <span class="rx--filter_text" *ngIf="!fromRph">&nbsp; &nbsp; Bill Status: &nbsp;
                </span>
                <app-rx-select *ngIf="!fromRph" [LabelText]="''" [PlaceHolder]="''" [ControlName]="'dateRng'"
                    [HasControl]="false" [List]="filterList" [BindLabel]="'name'" (TriggerSelectValue)="
                        getFilter($event?.value?.target?.value)
                    " [BindValue]="'name'" [LabelForId]="'name'">
                </app-rx-select>
                <span class="rx--filter_text"
                    *ngIf="!(isSelectedType === 'Current') && !fromRph && !showGroupView && showRefills && !(rxHistoryParams?.Billstatus === 'refdue' || rxHistoryParams?.Billstatus === 'f')">
                    <button type="button" class="secondary header__btn" (click)="getHistoryAndSetView(true)">
                        Group View
                    </button>
                </span>
                
                <span class="rx--filter_text"
                    *ngIf="showGroupView && showRefills && !(rxHistoryParams?.Billstatus === 'refdue' || rxHistoryParams?.Billstatus === 'f') && !fromRph">
                    <button type="button" class="secondary header__btn" (click)="getHistoryAndSetView(false)">
                        Individual View
                    </button>
                </span>
                <span class="rx--filter_text">
                    <button type="button" class="secondary header__btn" (click)="getCurrentViewForSelectedRx()">
                        Current View
                    </button>
                </span>
                <span class="rx--filter_text">
                    <!-- <button type="button" class="secondary header__btn color_orange color_white"
                        (click)="toggleColorLegend()">Color Legend</button> -->
                        <button type="button" class="submit print-med-guide background_orange_RGB border_1px_solid_black"
                        (click)="toggleColorLegend()">Color Legend</button>
                        
                </span>
            </div>
        </div>
    </div>
    <div class="eprx--block__content pb-2 erx--body rx--his">
        <span *ngIf="!showGroupView">
            <div class="mb-1">
                <wj-flex-grid #patientHistory [headersVisibility]="'Column'" [itemsSource]="patientRxHistoryWJ" [ngClass]="!hasPatientHistory ? 'no-data':''"
                    [itemFormatter]="itemFormatter" [isReadOnly]="true" [columnPicker]="'patientRxHistWJ'" id="patientHistId"
                    (drop)="this._storageService.storeWijmoGridDataInSession(patientHistory)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(patientHistory)"
                    (initialized)="init(patientHistory)"  [style.max-height]="_wijHeight" [selectionMode]="'Row'"
                     (keydown.tab)="focusOnCancel($event)" (dblclick)="!isfromEditRx ? onRowDblclicked(patientHistory, $event) : ''"
                    (click)="optedRx2($event, patientHistory)"
                    (keydown.space)="preventSpaceEvent($event);optedRx(!patientHistory.selectedItems[0].IsSelected, patientHistory.selectedItems[0], true)"
                    (updatedView)="updateColumnSize(patientHistory)" (keydown.control.l)="triggerCancel()">

                    <!--Double click action is avoided as this action opens again Edit Rx and this getting the already opened Edit Rx data is getting changed when opened patient history from Edit Rx EP-2241 -->

                     <!--  <wj-flex-grid-column header="" [width]="31" *ngIf="multiCheck">
                        
                    </wj-flex-grid-column>
                  <wj-flex-grid-column [header]="'Action'" [width]="58"
                        *ngIf="isFromModal && activeScreen !== 'DocQueue'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                        </ng-template>
                    </wj-flex-grid-column> -->
                    <wj-flex-grid-column [header]="'Action'" [width]="58"
                        *ngIf="isFromModal && activeScreen === 'DocQueue'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                            <input type="radio" [id]="row.index" [name]="'PrescriptionSelect'" (change)="
                                    prescriptionSelected( $event?.target?.checked, item, row.index ) "
                                value="{{ row.index }}" />
                        </ng-template>
                    </wj-flex-grid-column>
                    <!-- Action column is made to hide as this action opens again Edit Rx and this getting the already opened Edit Rx data is getting changed when opened patient history from Edit Rx EP-2241 -->
                    <wj-flex-grid-column [header]="'Action'" [width]="108" *ngIf="!isfromEditRx && activeScreen !== 'DocQueue'">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <span class="text-center">
                                <div class="row">
                                    <div>
                                        <span class="text-center">
                                            <input class="form-check-input left_0_37rem" type="checkbox" [id]="item.RowId"
                                                name="" [(ngModel)]="item.IsSelected" (change)=" optedRx($event?.target?.checked, item)" [checked]="item.IsSelected" />
                                                <label class="form-check-label" [for]="item.RowId"></label>
                                        </span>
                                    </div>
                                    <div class="text-center"
                                        [ngbTooltip]="((item?.reasonForService?.length || item?.professionalService?.length || item?.resultOfService?.length || item?.levelOfEffort?.length || item?.coAgentId?.length || item?.coAgentIdType?.length || item?.clinicalSig?.length || item?.pharmacistComment?.length)) ? DurTooltip : ''"
                                        placement="right" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip_notes_dur">
                                        <span *ngIf="!((item?.reasonForService?.length || item?.professionalService?.length || item?.resultOfService?.length || item?.levelOfEffort?.length || item?.coAgentId?.length || item?.coAgentIdType?.length || item?.clinicalSig?.length || item?.pharmacistComment?.length))">
                                            <img src="assets/dist/Icons/DUR.svg" [ngClass]="'width_1_2rem ml-4'" (click)="openDurOrNotesTab(item,'dur')">
                                        </span>
                                        <span *ngIf="((item?.reasonForService?.length || item?.professionalService?.length || item?.resultOfService?.length || item?.levelOfEffort?.length || item?.coAgentId?.length || item?.coAgentIdType?.length || item?.clinicalSig?.length || item?.pharmacistComment?.length))">
                                            <img src="assets/dist/Icons/DURRED.svg" [ngClass]="'width_1_2rem ml-4'" (click)="openDurOrNotesTab(item,'dur')">
                                        </span>
                                               <ng-template #DurTooltip>
                                                <div class="text-left">
                                                <div *ngIf="item?.reasonForService?.length"><b>Reason For Service: </b>{{item?.reasonForService}}</div>
                                                <div *ngIf="item?.professionalService?.length"><b>Professional Service: </b>{{item?.professionalService}}</div>
                                                <div *ngIf="item?.resultOfService?.length"><b>Result Of Service: </b>{{item?.resultOfService}}</div>
                                                <div *ngIf="item?.levelOfEffort?.length"><b>Level Of Effort: </b>{{item?.levelOfEffort}}</div>
                                                <div *ngIf="item?.coAgentId?.length"><b>Co-Agent ID: </b>{{item?.coAgentId}}</div>
                                                <div *ngIf="item?.coAgentIdType?.length"><b>Co-Agent ID Type: </b>{{item?.coAgentIdType}}</div>
                                                <div *ngIf="item?.clinicalSig?.length"><b>Clinical Significance: </b>{{item?.clinicalSig}}</div>
                                                <div *ngIf="item?.pharmacistComment?.length"><b>Pharmacist Comment: </b>{{item?.pharmacistComment}}</div>
                                                </div>
                                                
                                               </ng-template>
                                    </div>
                                    <div class="text-center">
                                        <span *ngIf="item['RxNotes']?.length" triggers="mouseover:mouseleave"
                                        [autoClose]="true" placement="right" tooltipClass="auto-title-tooltip" container="body" 
                                        [ngbTooltip]="htmlContent">
                                                <i class="fa-kit fa-notes-filled" (click)="openDurOrNotesTab(item)"></i>
                                            </span>
                                        <span *ngIf="!(item['RxNotes']?.length)"  (click)="openDurOrNotesTab(item,'rxnotes')">
                                            <i class="fa-kit fa-notes-line"></i>
                                        </span>   
                                        <ng-template #htmlContent>
                                            <span [innerHTML]="item['RxNotes']"></span>
                                            </ng-template> 
                                    </div>
                                    <div class="text-center" container="body">
                                        <span class="benefitCheck ml-1" title="Benefit Check" (click)="openModal(item)">
                                            B
                                        </span>                                   
                                    </div>
                                </div>
                            </span>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                        *ngFor="let rxheader of wjHeaders; let i = index">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <div *ngIf="(rxheader['hName'] === 'Total Amount')  || (rxheader['hName'] === 'Patient Pay') ||  (rxheader['hName'] === 'Profit Amount') || (rxheader['hName'] === 'Price')" title={{item[rxheader]}}>
                                {{ item[rxheader['hName']] | currency: "$":"symbol":"1.2-2"}}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Ref#'">
                                <span class="iou_hist_traingle_icon refill" *ngIf="item?.refLatest"   [ngbTooltip]="refrequest_tooltip" triggers="mouseover:mouseleave" [autoClose]="true"
                                container="body" tooltipClass="tooltip-title" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <15)?'top':'left'">
                                </span>
                                <ng-template #refrequest_tooltip>
                                    <div class="font-weight-bold ">
                                        Refill request was already sent 
                                    </div>
                                </ng-template>
                                <!-- <ng-template >
                                    <div class="row text-left">
                                        <span class="font-weight-bold "> Refill request was already sent </span>                                               
                                    </div>
                                </ng-template> -->
                            </div>
                            <div *ngIf="!(rxheader['hName'] === 'Total Amount' || rxheader['hName'] === 'Copay' || (rxheader['hName'] === 'Patient Pay') || rxheader === 'Profit Amount' || rxheader['hName'] === 'Price' || rxheader['hName'] === 'Profit Amount' ||  rxheader['hName'] === 'T.Rx Qty Rem' || rxheader['hName'] ==='Rx#' || rxheader['hName'] === 'Status' || rxheader['hName'] === 'Drug Name' || rxheader['hName'] == 'Notes' || rxheader['hName'] ==='Fill#' || rxheader['hName'] ==='Copay' || rxheader['hName'] ==='Insurance Paid' || rxheader['hName'] === 'Qty Dis' || rxheader['hName'] === 'Rx Origin')"
                            [title]="((item[rxheader.hName] + '') | uppercase)">
                                {{ item[rxheader['hName']]}}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Rx Origin'" >
                                {{ item[rxheader['hName']]}}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Qty Dis'">
                                {{item[rxheader['hName']]}}
                                <span *ngIf="(item?.iouInfo?.length)" [ngClass]="+item.OweQty ? 'total_remaining_traingle_icon IOU-partialfilled' : 'total_remaining_traingle_icon IOU-filled'" [ngbTooltip]="instructToolTip" triggers="mouseover:mouseleave" [autoClose]="true"
                                container="body" tooltipClass="iou--toolTip" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <15)?'top':'left'">
                                <!-- <img src="assets/IOU_info.png" class="image_placement" > -->
                                </span>
                                <ng-template #instructToolTip>
                                    <div>
                                        <div class="row-text-left">
                                            <div class="col-md-12">
                                                <label class="margin_bottom_1rem text-decoration_underline">IOU Information</label>
                                            </div>
                                        </div>
                                        <div class="row text-left" *ngFor="let iou of item?.iouInfo; let i = index">
                                            <div class="col-md-12 pr-0">
                                                
                                                <div class="row">
                                                    <i *ngIf="i !== (item?.iouInfo?.length)" class="fa fa-caret-right" style="padding-top:0.3rem"></i>
                                                    <div class="col-md-5">
                                                        <label>NDC#: </label>
                                                        <span> {{this._formatsUtil.getNDCFormat(iou?.BottleNDC)}}</span>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Actual Qty Disp: </label>
                                                        <span> {{iou?.DispQty| number : '1.3-3'}}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-5 margin_left_0_4rem">
                                                        <label>Qty. Owed: </label>
                                                         <span> {{iou?.OweQty| number : '1.3-3'}} </span>
                                                    </div>
                                                    <div class="col-md-6 pr-0">
                                                        <label>Date & Time: </label>
                                                        <span> {{iou?.DispDtTm ? (iou?.DispDtTm
                                                            | localTime | date: 'MM/dd/yyyy   hh:mm:ss a') : '--'}}</span>
                                                    </div>
                                                </div>
                                                <br>
                                                
                                                <!-- <span [innerHTML]="reasons"><span *ngIf="i === (rejectedReasons?.length - 2)"><br></span></span> -->
                                            </div>
                                        </div>
                                        <!-- <div class="row text-left">
                                            <div class="col-md-12">
                                                <label class="text-decoration_underline margin_bottom_1rem text-dark font-weight-bold">IOU Information</label>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <label>Actual Dispensed Quantity :</label>
                                                        <span> {{item['ActDispQty']}}</span>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <label>Owed Quantity :</label>
                                                        <span>{{item['OweQty']}} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </ng-template>
                            </div>
                            
                            <div *ngIf="rxheader['hName'] === 'T.Rx Qty Rem'">
                                {{ item[rxheader['hName']]}}
                                <!--  As per EP-4459 Handling Overfill Scenarios   <span (click)="openRxTotalQtyComponent(item)"
                                    [ngClass]="item[rxheader['hName']] && (item[rxheader['hName']] !== '0.000') && (item['PartialQtyRemaining'] > 0) ? 'total_remaining_traingle_icon' : ''">
                                </span> -->
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Fill#'"  (click)="isNoteType='RxAnnotation';openAnnotationHistoryModal(item)" >
                                {{ item[rxheader['hName']]}}
                                <span  class="attachment" *ngIf="item.IsNotesIconDisplay " 
                                triggers="mouseover:mouseleave"  container="body"  
                                [ngbTooltip]="'Class 2 filled partially, No further quantity may be supplied beyond 72hrs without a new prescription.'"  tooltipClass="search-tooltip_warn" >
                                <img src="assets/Notes_Pin.png" class="image_placement" >
                                </span>
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Insurance Paid'"   
                            [ngbTooltip]="item['partiallyoRCompRecords'] && item['partiallyoRCompRecords']['Records'].length > 0 ? PatialoRCompletePayment : null" placement="top" container="body" tooltipClass="payment-tooltip" triggers="mouseover:mouseleave" 
                            container="body" [openDelay]="500">
                                {{ item[rxheader['hName']]}} 
                            </div> 
                            <div *ngIf="rxheader['hName'] === 'Copay'"  
                            [ngbTooltip]="item['IsCopayPaid'] === 'YES' ? CompleteCopayPayment : null" placement="top" container="body" tooltipClass="payment-tooltip" triggers="mouseover:mouseleave" 
                            container="body" [openDelay]="500">
                                {{ item[rxheader['hName']] | currency: "$":"symbol":"1.2-2"}}
                            </div>
                            <ng-template #PatialoRCompletePayment>
                                <div>
                                    <div class="row text-left">
                                        <div class="col-12">
                                            <label>Paid Amount:</label>
                                            <span>  {{item['partiallyoRCompRecords'].TotalAmountPaid ? (item['partiallyoRCompRecords'].TotalAmountPaid | currency: "$":"symbol":"1.2-2") : "-"
                                                }} </span>
                                        </div>
                                        <div class="col-12">
                                            <label>Date Paid : </label>
                                            <span> {{item['partiallyoRCompRecords'].Records?.length ? (item['partiallyoRCompRecords']?.Records[0]?.PaidDate) : "-"
                                                }} </span>
                                        </div>
                                        <div class="col-12">
                                            <label>Cheque#: </label>
                                            <span> {{item['partiallyoRCompRecords'].Records?.length ? item['partiallyoRCompRecords'].Records[0]?.ChequeNum : "-"
                                                }}  </span>
                                        </div>
                                        <div class="col-12" *ngIf="item['partiallyoRCompRecords'].IsPartiallyPaidComp !== null && !item['partiallyoRCompRecords'].IsPartiallyPaidComp">
                                            <label>Pending Amount: </label>
                                            <span> {{item['partiallyoRCompRecords'].Records?.length ? (item['partiallyoRCompRecords']?.PendingAmount | currency: "$":"symbol":"1.2-2") : "-"
                                                }}  </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template #CompleteCopayPayment>
                                <div>
                                    <div class="row text-left">
                                        <div class="col-12">
                                            <label>Paid Amount:</label>
                                            <span>  {{item['Copay'] ? (item['Copay']   | currency: "$":"symbol":"1.2-2") : '-'}} </span>
                                        </div>
                                        <div class="col-12">
                                            <label>Date Paid : </label>
                                            <span> {{item['PickedDate'] ? item['PickedDate'] : '-' }} </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="d-inline" *ngIf="(rxheader['hName'] === 'Rx#'  || rxheader['hName'] === 'Drug Name')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : item['StatusHoverOver']" 
                            [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <15)?'top':'left'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                  {{item[rxheader['hName']] }}
                                  
                            </div>
                           <!-- <div *ngIf="(rxheader['hName'] === 'Rx#') && item['isWarn']">
                                <span [ngbTooltip]="item['RxHoverOver']" 
                                placement = "top" triggers="mouseover:mouseleave" container="body"  tooltipClass="tooltip-title">
                                    {{item[rxheader['hName']] }}
                                </span>
                                <span  triggers="mouseover:mouseleave"  container="body"  [ngbTooltip]="'No further quantity may be supplied beyond 72 hours without a new Prescription.'"  tooltipClass="search-tooltip_warn">
                                    <i class='fas fa-hourglass-start hourglass' ></i>
                                </span>
                                
                            </div> -->
                            <div *ngIf="rxheader['hName'] === 'Insurance Code'">
                                {{item[rxheader['hName']] }}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Status'">
                                <div  *ngIf="!(item['RejectReasons']) || item['Status'] === 'D'" 
                                 [ngbTooltip]="statusToolTip"  [placement]="cell.col.index>18? 'left': 'top'" triggers="mouseover:mouseleave" container="body" tooltipClass="auto-title-tooltip">
                                    {{item[rxheader['hName']] }}
                            </div>
                            <ng-template #statusToolTip>
                                <div *ngIf="item['Status'] === 'D'" class="text-left">
                                    <span [innerHTML] = "item['File Due Date'] ? item['FileDueDateHover'] : item['StatusHoverOver']"></span> <br/>
                                    <span [innerHTML] = "item['Defer Reason'] ? item['DeferReasonHover']:''"></span>
                                </div>
                                <div *ngIf="item['Status'] !== 'D'">
                                    {{ item['StatusHoverOver'] }}
                                </div>
                            </ng-template>
                            <div  *ngIf="item['RejectReasons'] && (item['Status'] !== 'D')" 
                            [ngbTooltip]="instructToolTip" triggers="mouseover:mouseleave" [autoClose]="true"
                    [placement]="cell.col.index>10? 'left': 'right'" container="body" tooltipClass="reject_codeForStatus">
                    {{item[rxheader['hName']]}} </div>  
    
    
                                    <ng-template #instructToolTip>
                                        <div>
                                            <div class="row-text-left">
                                                <div class="col-md-12">
                                                    <label class="margin_bottom_1rem text-decoration-underline  text-dark font-weight-bold">Rejected Reason(s)</label>
                                                </div>
                                            </div>
                                            <div class="row text-left" *ngFor="let reasons of item['hoverForStatus']; let i = index">
                                                <div class="col-md-12">
                                                    <i  class="fa fa-caret-right"></i>&nbsp;
                                                    <span [innerHTML]="reasons"><span *ngIf="i === (item['hoverForStatus']?.length - 2)"><br></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
    

                            </div>

                            <!-- <div *ngIf="item['Notes']">
                                <div class="text-center" *ngIf="rxheader['hName'] == 'Notes'" [ngbTooltip]="instructToolTip" placement = "top" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip_notes">
                                    <img src="assets/dist/Icons/RxNotes2.svg"/>
                                    <ng-template #instructToolTip>
                                        <div>
                                            <p>
                                            <b>Rx Notes:</b><br/>
                                            {{item && item['Notes'] ? item['Notes'] : ''}}   
                                            </p>
                                        </div>
                                    </ng-template>
                                </div>
                            </div> -->
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div [title]="((rxheader['hName'] && rxheader['hName'] === 'T.Rx Qty Rem')) ? 'Total Remaining Qty on Rx' : (rxheader['hName'] && rxheader['hName'] === 'Qty Rem P.fill') ? 'Quantity remaining on Refill' : rxheader['hName'] ">
                                {{ rxheader['hName'] }}
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!hasPatientHistory"></app-no-data-found>
                </wj-flex-grid>
            </div>
            <app-eprx-pagination *ngIf="!showRefillPagination && hasPatientHistory" [HasExport]="true" [TotalCount]="totalCount" [BillToStatus]="billToStatus" [TotalCopayForSelectedRxs]="totalCopayForSelectedRxs"
                (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'RxHistory'" [IsSelectedType]="isSelectedType"
                [WijmoName]="patientHistory" [PageSize]="pageSize" [PageNumber]="pageNumber" [FromModal]="isFromModal"
                [TotalFiledRxs]="totalFiled" [FromRphModal]="true">
            </app-eprx-pagination>
            <app-eprx-pagination *ngIf="showRefillPagination && hasPatientHistory" [HasExport]="true" [TotalCount]="totalRefCount" [BillToStatus]="billToStatus" [TotalCopayForSelectedRxs]="totalCopayForSelectedRxs"
                (OnPageSelected)="setRefPage($event)" (OnSizeSelected)="setRefSize($event)" [GridName]="'RxHistory'" [IsSelectedType]="isSelectedType"
                [WijmoName]="patientHistory" [PageSize]="pageSize" [FromModal]="isFromModal"
                [TotalFiledRxs]="totalFiled"></app-eprx-pagination>
        </span>
        <span *ngIf="showGroupView">
            <div >
                <wj-flex-grid #patientHistory [autoGenerateColumns]="false"  [headersVisibility]="'Column'" [itemsSource]="patientRxHistoryWJ"
                [itemFormatter]="itemFormatter" [isReadOnly]="true" [columnPicker]="'rxGroupViewWJ'" (initialized)="init(patientHistory)"
                    [style.max-height]="_wijHeight" [selectionMode]="'Row'" (keydown.tab)="focusOnCancel($event)"
                    (dblclick)="!isfromEditRx ? onRowDblclicked(patientHistory, $event, 'parent') : ''" (keydown.control.l)="triggerCancel()">
                    <!--Double click action is avoided even for group view as this action opens again Edit Rx and this getting the already opened Edit Rx data is getting changed when opened patient history from Edit Rx EP-2241 -->
                    <wj-flex-grid-column [header]="''" [width]="31">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row">
                            <i *ngIf="!dp.isDetailVisible(row)" (click)="dp.showDetail(row,true); "
                                class="far fa-plus-circle actions "></i>
                            <i *ngIf="dp.isDetailVisible(row)" (click)="dp.hideDetail(row); "
                                class="far fa-minus-circle actions"></i>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']"
                        [visible]="rxheader['isVisible']" [width]="rxheader['width']"
                        *ngFor="let rxheader of wjHeadersForGropView; let i = index">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div
                                *ngIf="(rxheader['hName'] === 'Total CoPay') || (rxheader['hName'] === 'Total Price') || (rxheader['hName'] === 'Total Profit')">
                                {{ item?.[rxheader['hName']] | currency: "$":"symbol":"1.2-2"}}
                            </div>
                            <div
                                *ngIf="!(rxheader['hName'] === 'Total CoPay' || rxheader['hName'] === 'Total Price' || rxheader['hName'] === 'Total Profit'  || rxheader['hName'] === 'Rx#')">
                                {{ item?.[rxheader['hName']] }}
                            </div>
                            <div *ngIf = "( rxheader['hName'] === 'Rx#')" [ngbTooltip]=" item?.['RxHoverOver']" placement = "top" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title"  container="body">
                                {{item?.[rxheader['hName']]}}
                        </div>
                        </ng-template>

                    </wj-flex-grid-column>
                    <ng-template wjFlexGridDetail #dp="wjFlexGridDetail" let-item="item">
                        <!-- <wj-flex-grid #insflex [itemsSource]="showInsideGrid(item)" [isReadOnly]="true"
                            [itemFormatter]="itemFormatter" [columnPicker]="'rxGroupViewDetailsWJ'" [headersVisibility]="'Column'" [selectionMode]="'Row'"
                            (initialized)="initInsideGride(insflex)"
                            (dblclick)="onRowDblclicked(insflex, $event, 'child')"> (updatedView)="updateColumnSize(insflex)" -->
                        <wj-flex-grid #insflex [columnPicker]="'rxGroupViewDetailsWJ'" [itemsSource]="showInsideGrid(item)" [isReadOnly]="true"
                            [headersVisibility]="'Column'" [selectionMode]="'Row'" class="text_transform"  (dblclick)="onRowDblclicked(insflex, $event, 'child')" [itemFormatter]="itemFormatter">
                            <wj-flex-grid-column header="" [width]="31" *ngIf="multiCheck">
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" *ngIf="showCheckBox">
                                    <span>
                                        <input class="form-check-input" type="checkbox"y id="i" name=""
                                            [(ngModel)]="allSelect" [checked]="allSelect"
                                            (change)="checkAll($event?.target?.checked,patientHistory)" />
                                        <label class="form-check-label" for="i"></label>
                                    </span>
                                </ng-template>
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <span class="text-center">
                                        <input class="form-check-input" type="checkbox" [id]="item.RowId" name=""
                                            [(ngModel)]="item.IsSelected" (change)="
                                                            optedRx($event?.target?.checked, item)
                                                        " [checked]="item.IsSelected" />
                                        <label class="form-check-label" [for]="item.RowId">
                                        </label>
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>
                            <!-- Action column is made to hide as this action opens again Edit Rx and this getting the already opened Edit Rx data is getting changed when opened patient history from Edit Rx EP-2241 -->
                            <wj-flex-grid-column [header]="'Action'" [width]="58"
                                *ngIf="!isfromEditRx && isFromModal && activeScreen !== 'DocQueue'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <i class="far fa-edit actions" title="Edit" (click)="routeToEditRx(item)"></i>
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="'Action'" [width]="58"
                                *ngIf="isFromModal && activeScreen === 'DocQueue'">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row="row">
                                    <input type="radio" [id]="row.index" [name]="'PrescriptionSelect'" (change)="
                                            prescriptionSelected( $event?.target?.checked, item, row.index ) "
                                        value="{{ row.index }}" />
                                </ng-template>
                            </wj-flex-grid-column>
                            <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                *ngFor="let rxheader of wjHeadersForGropViewDetals; let i = index" [width]="rxheader['width']">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <div *ngIf="['Total Amount', 'Copay', 'Profit Amount', 'Price'].includes(rxheader['hName'])"
                                        [title]="item[rxheader['hName']]">
                                        {{ item[rxheader['hName']] | currency: "$":"symbol":"1.2-2"}}
                                    </div>
                                    <div *ngIf="!(['Total Amount', 'Copay', 'Profit Amount', 'Price', 'Rx#', 'Drug Name', 'Status'].includes(rxheader['hName']))"
                                        [title]="item[rxheader['hName']]">
                                        {{ item[rxheader['hName']] }}
                                    </div>
                                    <div *ngIf="(rxheader['hName'] === 'Rx#' || rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Status')" [ngbTooltip]="rxheader['hName'] === 'Drug Name' ? item['DrugHoverOver'] : rxheader['hName'] === 'Rx#' ? item['RxHoverOver'] : item['StatusHoverOver']" [placement] = "(cell.col.index>=0 && cell.col.index < 2) ? 'right' : (cell.col.index>=2 && cell.col.index <15)?'top':'left'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                        {{item[rxheader['hName']] }}
                                    </div>
                                </ng-template>
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                    <div [title]="rxheader['hName']">
                                        {{ rxheader['hName'] }}
                                    </div>
                                </ng-template>
                            </wj-flex-grid-column>
                            <app-no-data-found *ngIf="!hasPatientHistory"></app-no-data-found>
                        </wj-flex-grid>
                    </ng-template>
                </wj-flex-grid>
            </div>
            <app-eprx-pagination *ngIf="!showRefillPagination && hasPatientHistory" [HasExport]="true" [TotalCount]="totalCount" [TotalCopayForSelectedRxs]="totalCopayForSelectedRxs"
                (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'RxHistory'"
                [WijmoName]="patientHistory" [PageSize]="pageSize" [PageNumber]="pageNumber" [FromModal]="isFromModal"
                [TotalFiledRxs]="totalFiled">
            </app-eprx-pagination>
            <app-eprx-pagination *ngIf="showRefillPagination && hasPatientHistory" [HasExport]="true" [TotalCount]="totalRefCount" [TotalCopayForSelectedRxs]="totalCopayForSelectedRxs"
                (OnPageSelected)="setRefPage($event)" (OnSizeSelected)="setRefSize($event)" [GridName]="'RxHistory'"
                [WijmoName]="patientHistory" [PageSize]="pageSize" [FromModal]="isFromModal"
                [TotalFiledRxs]="totalFiled"></app-eprx-pagination>
        </span>
    </div>
</div>
<app-erx-change-req *ngIf="activeModal === 'ERxChange'" [RxInfo]="selectedRxForBenefitCheck"
    (IsPopUpClosed)="openBenefitCheckModel()" [SelectedBenefitCheckType]=selectedBenefitCheckType
    [PrescRefillId]="selectedRxForBenefitCheck?.PrescReFill?.Id"
    [DrugId]="selectedRxForBenefitCheck?.Drug?.id"
></app-erx-change-req>
<app-notes-log
*ngIf="isNoteType === 'RxAnnotation' && selectedItemForNoteHistory"
(CloseNotesValue)="isNoteType = '';this.getPatientHistory(true, true)"
[RxInfo]="selectedItemForNoteHistory['selectedRx']"
[OpenAsModal]="true"
[NotesFr]="'RxAnnotation'"
>
</app-notes-log>

<app-drug-missing-form (closeDrugMissingValues)="drugMissingValuesClosingPop($event)" *ngIf="isShowDrugValuesPop" [drugInfo]="drugInfo" ></app-drug-missing-form>

<ng-template #filedRx let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Confirmation</h4>
        <span body>
            There are {{ totalFiled }} Deferred Rxs for this patient. To view
            Deferred Rxs, go to Patient's RX History and select the Deferred Rx
            filter.
        </span>
        <button footer ngbAutofocus type="submit" class="hotkey_primary" (click)="closeModal()" appShortcutKey
            InputKey="o">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>
<!-- <app-rx-shared *ngIf = "selectedRx" [rxFG$]="selectedRx" [IsFromRxHistory]="true"  [PatientId]="'Patient7'"></app-rx-shared> -->
<app-override-log *ngIf="openOverride" [PrivMask]="privType" [OverrideEndpoint]="overrideEndpoint" [CatId]="categoryId"
    (OverrideLoginDet)="OverrideRes($event)"></app-override-log>

    <!-- <app-notes-history *ngIf="isNoteHistoryLog" [SelectedItemForNoteHistory]="selectedItemForNoteHistory" (CloseNoteHistModal)="isNoteHistoryLog = false"></app-notes-history> -->

