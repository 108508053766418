<div *ngIf="isPopUp" class="modal-header">
    <div class="row">
        <div class="col"><span class="eprx--header__heading font_weight_600 pt-1">Drug Search</span></div>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="closeDrugModal()" *ngIf="!IsFromTransferIn">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
</div>
<div class="row erx--drug-body">
    <div class="col-md-12" [ngClass]="searchFrmPage === 'Dash' && !isPopUp ? 'dash--drug--grid' : searchFrmPage === 'header' && !isPopUp ? 'notdash--drug--grid' : ''">
        <div class="exprx--common-block">
            <div class="eprx--block__header block__header" *ngIf="!isPopUp">
                <div class="row">
                    <div class="col"><span class="eprx--header__heading pt-1">Drug Search</span></div>
                    <button type="button" [tabindex]="-1" class="close pr-3 pt-1" aria-label="Close" (click)="ClosePatientDrugPage.emit(true); clearFilters()">
                        <span aria-hidden="true" class="close-button">&times;</span>
                    </button>
                </div>
            </div>
            <div class="eprx--block__content block__content erx--body p-4">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div class="d-flex flex-column pt-1">
                            <eprx-input [AutoFocus]="true" [LabelText]="'Search'" [PlaceHolder]="'Enter Keywords, Drug names, or NDCs to search through the list below and globally'" [ControlName]="'DrugName'" 
                            [FormGroupName]="DrugSuggestFilterFG" [RxSelectId]="'DrugNameMMS'" (TriggerValWhileEnter)="getDrugBySearch()" [TabIndex]="1" [BarcodeDetector]="true" (EmitBarcode)="getBarcodeInfo($event)" (TriggerOnEnterValue)="getGlobalSearchDrugSuggest()">
                            </eprx-input>
                            <span class="help-block" *ngIf="required"> Enter min 3 Chars </span>
                            <div class="search--icon" (click)="clearedBySearchIcon()">
                                <i class="far fa-search"></i>
                            </div>                            
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <eprx-select [Title]="isFrmRx && !checkInvPrivs() ? 'User does not have privilege to change Bucket' : ''"
                            [RxSelectId]="'bucketListFrmDrgsug'" [LabelText]="'Inv. Bucket Name'"
                            [PlaceHolder]="'Bucket Name'" [ControlName]="'BucketId'"
                            (TriggerSelectValue)="getBucketList()" [FormGroupName]="DrugSuggestFilterFG"
                            [BindLabel]="'Name'" [BindLabel2]="'BucketContextType'" [HasBindLabel2]="true"
                            [BindValue]="'Id'" [LabelForId]="'Id'" [LabelTitle1]="'Bucket Name'"
                            [LabelTitle2]="'Bucket Context Type'" [IsDisabled]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId  && (((IsFrmErx || disableBuck)) ? true : false) ? true : false) ||
                            (isFrmRx && !checkInvPrivs()) || bucketFrmPriceChck" [List]="DrugBucketList" [TabIndex]="2">
                        </eprx-select>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 align-button text-right allign-bottom">
                        <button type="submit" #searchButton title="Search" class="hotkey_success pull-right" appShortcutKey appShortcutKey [AltKey]="'f'" (click)="getGlobalSearchDrugSuggest();searchButton.blur()">
                            <span>F</span> Search
                        </button>
                        <button *ngIf="hasMultipleDrugs" type="submit" title="Submit" class="hotkey_success pull-right" appShortcutKey appShortcutKey [AltKey]="'g'" (click)="submitmultipleDrugs();searchButton.blur()">
                            <span>G</span> Submit
                        </button>
                        <button type="button" class="hotkey_success pull-right" appShortcutKey [AltKey]="'l'" (click)="clearFilters()">
                            <span>L</span> clear
                        </button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 pt-2" *ngIf="!singleBucketMode && !(IsFrmErx || disableBuck)">
                        <eprx-check-box [LabelText]="'Show drugs only which are added to this bucket'"
                            [ControlName]="'OnlyShowDrugsAvailableForBucket'" [FormGroupName]="DrugSuggestFilterFG"
                            [AutoFocus]="(updateDrugSuggFilter && updateDrugSuggFilter.BucketId ? true : false) || (isFrmRx && !checkInvPrivs()) || bucketFrmPriceChck" 
                            [rxSelectId]="'OnlyShowDrugsAvailableForBucketSuggest'">
                        </eprx-check-box>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 pt-2">
                        <eprx-check-box [LabelText]="'Show drugs only with available stock Qty'"
                            [ControlName]="'OnlyDrugsHavingQtyInStock'" [FormGroupName]="DrugSuggestFilterFG"
                            [rxSelectId]="'OnlyDrugsHavingQtyInStockSuggest'">
                        </eprx-check-box>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 pt-2">
                        <eprx-check-box [LabelText]="'Show 340B Drugs Only'" [ControlName]="'Is340B'"
                            [FormGroupName]="DrugSuggestFilterFG" [rxSelectId]="'Is340Bsuggest'">
                        </eprx-check-box>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 pt-2 px-0">
                        <eprx-check-box [LabelText]="'Show same Pack Size for non-splittable Equivalent Drugs'"
                            [ControlName]="'ShowNonSplittableEquiDrugs'" [FormGroupName]="DrugSuggestFilterFG"
                            [rxSelectId]="'ShowNonSplittableEquiDrugsSuggest'">
                        </eprx-check-box>
                    </div>                    
                    <div class="col text-right pl-0" (click)="toggleColorLegend()" (keydown.tab)="focusFirstGrid()">
                        <button class="submit print-med-guide background_orange">Color Legend</button>
                    </div>
                </div> 
                <div class="row mt-3">
                    <div id="loader" class="progress-bar-container mb-2" *ngIf="loading">
                        <div class="progress-bar--common" [style.width]="progressValue + '%'"></div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 drugwj-grid">
                        <wj-flex-grid #drugflex [columnPicker]="'drugSuggestListWj'" tabindex="22" [itemsSource]="drugSuggestWJ" [isReadOnly]="true"  [allowSorting]="true" (initialized)="Init(drugflex)" [ngClass]="!drugSugList?.length ? 'no-data':''"  [itemFormatter]="itemFormatter" 
                        [headersVisibility]="'Column'" [selectionMode]="'Row'" (click)="onRowSingleClick(drugflex, $event)" (dblclick)="!hasMultipleDrugs ? onRowDoubleClick(drugflex, $event, 'drug') : ''" (keydown.tab)="focusSecondGrid($event)" class="drug--wjgrid text_transform" (drop)="_storageService.storeWijmoGridDataInSession(drugflex)" (resizedColumn)="_storageService.storeWijmoGridDataInSession(drugflex)">
                        <wj-flex-grid-column [header]="''" [width]="30">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-row="row" let-item="item" let-cell="cell">
                                <span class="text-center">
                                    <input class="form-check-input drug-check-input" type="checkbox" [(ngModel)]="item.isSelected" [checked]="item.isSelected" [id]="item.RowId" name="" (click)="hasMultipleDrugs ? getMultipleselectedDrugByCheck($event?.target?.checked,item,'drug') : getselectedDrugByCheck(item)"/>
                                    <label class="form-check-label" [for]="item.RowId"></label>
                                </span>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                *ngFor="let rxheader of drugListwjHeaders; let i = index" [width]="rxheader['width']">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <div [ngbTooltip]="rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'Pck/Unit AWP' || rxheader['hName'] === 'Made By' || rxheader['hName'] === 'Drug Type' || rxheader['hName'] === 'Brand Name' || rxheader['hName'] === 'Generic Name'  ? drugcellToolTip : ''" [placement]="cell.col.index>10? 'left': 'right'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                        {{item[rxheader['hName']]}}                                        
                                    </div>
                                    <ng-template #drugcellToolTip>
                                        <span>{{item[rxheader['hName']] | uppercase}}</span>
                                </ng-template>
                                </ng-template>
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                    <div [title]="rxheader['hName']">
                                        {{ rxheader['hName'] }}
                                    </div>
                                </ng-template>                                
                        </wj-flex-grid-column>
                        <app-no-data-found *ngIf="!drugSugList?.length"></app-no-data-found>
                        </wj-flex-grid>
                    </div>
                </div>
                <div class="row mt-3 px-3" *ngIf="showEquDrugs">
                    <div id="loader" class="progress-bar-container mb-2" *ngIf="eqvloading">
                        <div class="progress-bar--common" [style.width]="progressValue + '%'"></div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 max-height-radio pt-2 sub--table--grid">
                        <div class="row pt-1">
                            <label class="col-2 custom-radio" *ngFor="let key of ['Generic Equivalent Drugs', 'Brand Equivalent Drugs']">
                              <input type="radio" [id]="key" [checked]="(EquivType ? EquivType : 'Generic Equivalent Drugs') === key" [value]="key" (click)="getEquivTypeValue($event.target.value)">
                              <span class="radio--label" [for]="key">{{ key }}</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 px-0 drugwj-grid">
                        <wj-flex-grid #equflex [headersVisibility]="'Column'" tabindex="22" [itemsSource]="drugEquListWJ" [isReadOnly]="true" [allowSorting]="true"  (dblclick)="!hasMultipleDrugs ? onRowDoubleClick(equflex, $event,'equva') : ''" (keydown.tab)="focusToFirst($event)" (initialized)="Initequ(equflex)"
                                [selectionMode]="'Row'" [itemFormatter]="itemFormattertwo" [columnPicker]="'drugEquListWj'" [ngClass]="!isDataExists && !genericEquDrugsList.length && EquivType === 'Generic Equivalent Drugs' ? 'no-data': !isDataExists && !genericbrnadDrugsList.length && EquivType === 'Brand Equivalent Drugs' ? 'no-data' : ''" class="equlentwj-grid">
                                <wj-flex-grid-column [header]="''" [width]="30">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" wid let-item="item" let-cell="cell">
                                        <span class="text-center">
                                            <input class="form-check-input drug-check-input" type="checkbox" [(ngModel)]="item.isSelected" [checked]="item.isSelected" [id]="item.RowId" name="" (click)="hasMultipleDrugs ? getMultipleselectedDrugByCheck($event?.target?.checked,item,'equva') : selectEqulentDrugByCheck(item,EquivType)"/>
                                            <label class="form-check-label" [for]="item.RowId"></label>
                                        </span>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                *ngFor="let rxheader of drugEquListWjHeaders; let i = index" [width]="rxheader['width']">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                    <div [ngbTooltip]="rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'AWP PCK/Unit' || rxheader['hName'] === 'Made By' || rxheader['hName'] === 'Drug Type' || rxheader['hName'] === 'Brand Name' || rxheader['hName'] === 'Generic Name'  ? drugcellToolTip : ''" [placement]="cell.col.index>10? 'left': 'right'" triggers="mouseover:mouseleave" container="body" tooltipClass="tooltip-title">
                                        {{item[rxheader['hName']]}}                                        
                                    </div>
                                    <ng-template #drugcellToolTip>
                                        <span>{{item[rxheader['hName']] | uppercase}}</span>
                                </ng-template>
                                </ng-template>
                                <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                    <div [title]="rxheader['hName']">
                                        {{ rxheader['hName'] }}
                                    </div>
                                </ng-template>
                                </wj-flex-grid-column>
                        <app-no-data-found *ngIf="(!genericEquDrugsList.length && EquivType === 'Generic Equivalent Drugs') || (!genericbrnadDrugsList.length && EquivType === 'Brand Equivalent Drugs')"></app-no-data-found>
                        </wj-flex-grid>
                    </div>
                </div>                           
            </div>
        </div>
    </div>
</div>

<ng-template #addDrug let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModal()">
        <h4 header>Search Drug</h4>
        <div body *ngIf="allowToAddNewDrug && !isScannedDrugNotFound " class="word_break"><b>Drug: </b><span
                class="font-bigger text-primary">{{DrugSuggestFilterFG?.value['DrugName'] | uppercase}}</span> not found. Would you like to add?
        </div>
        <div body *ngIf="!allowToAddNewDrug && !isScannedDrugNotFound" class="word_break"><b>Drug: </b><span
                class="font-bigger text-primary">{{DrugSuggestFilterFG?.value['DrugName'] | uppercase }}</span> not found.</div>
        <button footer ngbAutofocus id="addOk" type="submit" class="hotkey_primary" appShortcutKey InputKey="y"
            (click)="addNewDrug(true)" (keydown.enter)="addNewDrug(true)" *ngIf="allowToAddNewDrug">
            <b>Y</b> YES
        </button>
        <button *ngIf="!allowToAddNewDrug" footer type="submit" class="hotkey_primary" appShortcutKey InputKey="o"
            (click)="d('Cross click')" (keydown.enter)="d('Cross click')"><b>O</b> OK</button>
        <button footer id="addcDefActn" type="submit" class="hotkey_primary" appShortcutKey InputKey="c"
            (click)="closeModal()" (keydown.enter)="closeModal()" *ngIf="allowToAddNewDrug">
            <b>C</b> Cancel
        </button>
    </app-custom-modal>
</ng-template>