import { Pipe, PipeTransform } from "@angular/core";
import { CommonUtil } from "../utils";

@Pipe({ name: "filterInsurance" })
export class FilterInsurance implements PipeTransform {
    constructor(private _cmnUtils: CommonUtil) {}
    transform(data: any[], rxFG?: any, payRec?: any, frmInprogress?: any): any {
        let result = [];
        if (data && data.length > 0) {
            if (rxFG && rxFG.value["rxType"] === "er") {
                result = data.filter(val => (((val.ActiveStatus === 1 && val.insurerActiveStatus === true) ||
                (rxFG.value["RefillInsu"] && rxFG.value["RefillInsu"]["InsuId"] === val.insuranceid) ||
                (payRec && payRec.length > 0 && payRec.find(rxPay => val.insuType.trim()  === rxPay.Ins_Code.trim())))
                ));
                if (payRec && payRec.length > 0 && payRec.find(rxPay => rxPay.RecType.trim().toUpperCase() === "P")) {
                    result = result.filter(val => !val["IsCash"]);
                }
            } else {
                if (frmInprogress) {
                    data = frmInprogress;
                    result = data.filter(val => (val.ActiveStatus === 1 && val.insurerActiveStatus === true));
                } else {
                    result = data.filter(val => (val.ActiveStatus === 1 && val.insurerActiveStatus === true));
                }
            }
            let M3PInsu = this._cmnUtils.isPatientHasM3P(result);
            result = result.map(item => 
                M3PInsu && !this._cmnUtils.isParentInsuBilled(M3PInsu) && item.parentInsuId ? { ...item, disabled: true, parentInsu: M3PInsu.parentInsuName } : item
            );
        }
        return result;
    }
}
