<div class="editpatient">
    <div class="row content--heading">
        <div class="col-md-8 padding-0">
            <div class="row">
                <div class="icon">
                    <img src="assets/dist/Icons/Icon_rx2.png" />
                </div>
                <div class="col-md-11 padding-0">
                    <div class="content--heading__title padding-0">
                        Pay Records
                        <span class="content--name"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right pull-right col-md-4 padding-0">
            <button class="hotkey_success" autofocus (click)="previousPage()" id="cancelButton">
                <span>C</span>Cancel
            </button>
        </div>
    </div>
    <div class="eprx--block__content">
        <div class="editpatient--body newrx--body edit--drug edit--drug">
            <div class="exprx--common-block">
                <div class="eprx--block__header">
                    <div class="row">
                        <div class="col">
                            <span class="eprx--header__heading">
                                Pay Records</span>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col" *ngIf="payRecrds && payRecrds?.length > 0">
                            <wj-flex-grid [headersVisibility]="'Column'" [selectionMode]="'Row'"
                                [itemsSource]="rxTransmissionWJ" [isReadOnly]="true" [itemFormatter]="itemFormatter" 
                                (keydown.tab)="focusOnCancel($event)">
                                <wj-flex-grid-column [header]="'Action'" [width]="100">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <i class="far fa-edit actions" title="Edit" (click)="openEditModal(item)"></i>
                                        <i class="far fa-trash-alt actions color_dark_grey" title="Delete" *ngIf="item?.RecType !== 'R'"
                                        ></i>
                                        <i class="far fa-trash-alt actions danger-actve" title="Delete"
                                            (click)="deletePayRecord(item)" *ngIf="item?.RecType === 'R'"></i>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Rx#'" [width]="100" [binding]="'PrescNum'"
                                    [format]="'d'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Ref#'" [width]="100" [binding]="'RefillNum'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Fill#'" [width]="100" [binding]="'FillNo'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Rec Type'" [width]="100" [binding]="'RecType'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Cov'" [width]="100" [binding]="'CoverageCD'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Ins.Code'" [width]="100" [binding]="'Ins_Code'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Date'" [width]="100" [binding]="'DatePaid'">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            {{
                                                item?.DatePaid | localTime | date: "MM/dd/yyyy"
                                            }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHPYRPDQ'" [width]="100" [binding]="'OthPYRPDQ'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Ingr.Cost Paid'" [width]="100" [binding]="'ICostPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Fee Paid'" [width]="100" [binding]="'DFEEPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Pat Pay'" [width]="100" [binding]="'PTPayment'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'FSTAXAMT'" [width]="100" [binding]="'FSTaxAmt'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'PSTAXAMT'" [width]="100" [binding]="'PSTaxAmt'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'PSTAXRATE'" [width]="100" [binding]="'PSTaxRate'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'INCAMTPAID'" [width]="100" [binding]="'INCAMTPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'PSFEEPAID'" [width]="100" [binding]="'PSFEEPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHAMTPAID'" [width]="120" [binding]="'OthAMTPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OthPayRecog.'" [width]="120" [binding]="'OthPayRecg'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'NetPaid'" [width]="100" [binding]="'TotAmtPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHPAYQ1'" [width]="100" [binding]="'OthPayQ1'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHPAYAMT1'" [width]="120" [binding]="'OthPayAmt1'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHPAYQ2'" [width]="100" [binding]="'OthPayQ2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHPAYAMT2'" [width]="120" [binding]="'OthPayAmt2'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHPAYQ3'" [width]="100" [binding]="'OthPayQ3'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'OTHPAYAMT3'" [width]="120" [binding]="'OthPayAmt3'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'TAXEXEMPT'" [width]="105" [binding]="'TaxExempt'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'BASISOFREI'" [width]="100" [binding]="'BASISOFREI'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'BOC_DFEE'" [width]="105" [binding]="'BOC_DFEE'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'BOC_COPAY'" [width]="120" [binding]="'BOC_Copay'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'BOC_FSTAX'" [width]="100" [binding]="'BOC_FSTax'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'BOC_PSTAX'" [width]="100" [binding]="'BOC_PSTax'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'REJCODES'" [width]="100" [binding]="'REJCodes'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AMT_PREDED'" [width]="100" [binding]="'Amt_PERDED'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AMT_SALETX'" [width]="100" [binding]="'Amt_SaleTx'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AMT_COPINS'" [width]="100" [binding]="'Amt_COPINS'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AMT_PRODSL'" [width]="100" [binding]="'Amt_PRODSL'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AMT_EXCMAX'" [width]="100" [binding]="'Amt_EXCMAX'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'INSNETPAID'" [width]="100" [binding]="'InsNetPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'PSTaxBasisPaid'" [width]="100"
                                    [binding]="'PSTaxBasisPaid'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AccumDedAmount'" [width]="100"
                                    [binding]="'AccumDedAmount'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'RemDedAmount'" [width]="100" [binding]="'RemDedAmount'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'RemBenifitAmount'" [width]="100"
                                    [binding]="'RemBenefitAmount'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AmtProcFee'" [width]="100" [binding]="'AmtProcFee'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'PatSTaxAmt'" [width]="100" [binding]="'PatSTaxAmt'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AmtCoIns'" [width]="100" [binding]="'AmtCoIns'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'BOC_CoIns'" [width]="100" [binding]="'BOC_CoIns'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'EstGen'" [width]="100" [binding]="'EstGenSavings'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'SpendAccAmtRem'" [width]="100"
                                    [binding]="'SpendAccAmtRem'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'HPIanFundAmt'" [width]="100" [binding]="'HPlanFundAmt'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AmtProvNetSel'" [width]="100"
                                    [binding]="'AmtProvNetSel'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AmtProdSelBrand'" [width]="100"
                                    [binding]="'AmtProdSelBrand'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AmtProdSelNPForm'" [width]="100"
                                    [binding]="'AmtProdSelNPForm'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AmtProdSelNPFormB'" [width]="100"
                                    [binding]="'AmtProdSelNPFormB'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'AmtCovGap'" [width]="100" [binding]="'AmtCovGap'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'IngCostContRAmt'" [width]="100"
                                    [binding]="'IngCostContRAmt'" [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'DFeeContRAmt'" [width]="100" [binding]="'DFeeContRAmt'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'COBBillSeq'" [width]="100" [binding]="'CoverageCD'">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        {{
                                            item?.CoverageCD == 0
                                                ? "P"
                                                : item?.CoverageCD == 1
                                                ? "S"
                                                : item?.CoverageCD == 2
                                                ? "T"
                                                : "Q"
                                        }}
                                    </ng-template>
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'InsNetPaid'" [width]="100" [binding]="'InsNetPaid'"
                                    [format]="'c2'">
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </div>
                        <div *ngIf="!payRecrds || payRecrds?.length === 0" class="content-not-found">
                            <label class="text-display-center padding_left_35px">
                                NO PAY RECORDS FOR THIS RX!
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #PayRecordDeleteWarning let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalSM()">
        <h4 header>Warning!!</h4>
        <span body>Paid records cannot be deleted. </span>
        <button footer ngbAutofocus type="submit" id="payRecordDelete" class="hotkey_primary" (click)="closeModalSM()"
            appShortcutKey InputKey="o">
            <b>O</b> OK
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #BenefitInfoEdit let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeBFModal()">
        <h4 header>Edit Pay Rep Values Record</h4>
        <span body>
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12">
                            <eprx-input [LabelText]="'Rx#'" [PlaceHolder]="''"
                                [ControlName]="'RxNo'" [FormGroupName]="benefitFG" [ReadOnly]="true">
                            </eprx-input>
                        </div>
                        <div class="col-12">
                            <eprx-input [LabelText]="'ReFill#'" [PlaceHolder]="''"
                                [ControlName]="'RfNo'" [FormGroupName]="benefitFG" [ReadOnly]="true">
                            </eprx-input>
                        </div>
                        <div class="col-12">
                            <eprx-input [LabelText]="'Fill#'" [PlaceHolder]="''"
                                [ControlName]="'Fillno'" [FormGroupName]="benefitFG" [ReadOnly]="true">
                            </eprx-input>
                        </div>
                        <div class="col-12">
                            <eprx-input [LabelText]="'Ins. Code'" [PlaceHolder]="''"
                                [ControlName]="'Ins_Code'" [FormGroupName]="benefitFG" [ReadOnly]="true">
                            </eprx-input>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="col-12">
                        <eprx-input [LabelText]="'Field Name'" [PlaceHolder]="''" [ControlName]="'FieldName'"
                            [FormGroupName]="benefitFG" [AutoFocus]="true">
                        </eprx-input>
                    </div>
                    <div class="col-12">
                        <eprx-input [LabelText]="'Field Value1'" [PlaceHolder]="''" [ControlName]="'FieldValue1'" [FormGroupName]="benefitFG"
                            [MaxLength]="2">
                        </eprx-input>
                    </div>
                    <div class="col-12">
                        <eprx-input [LabelText]="'Field Value2'" [PlaceHolder]="''" [ControlName]="'FieldValue2'"
                            [FormGroupName]="benefitFG" [MaskPattern]="'099999.00'" [DropSpclChar]="false">
                        </eprx-input>
                    </div>
                </div>
            </div>


        </span>
        <button footer type="submit" class="hotkey_success" (click)="updateBenefit()"
         appShortcutKey [AltKey]="'s'"> <span>S</span> Save</button>
        <button footer type="submit" class="hotkey_success" (click)="closeBFModal()"
        appShortcutKey [AltKey]="'c'">
            <span>C</span> Close
        </button>
    </app-custom-modal>
</ng-template>

<ng-template #PayRecordEdit let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="closeModalSM()">
        <h4 header>Edit Pay Transmission Record</h4>
        <span body>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-6">
                            <eprx-input [LabelText]="'Fill Number'" [PlaceHolder]="''"
                                [ControlName]="'FillNo'" [FormGroupName]="editPayFG" [ReadOnly]="true">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Insurance Code'" [PlaceHolder]="''"
                                [ControlName]="'Ins_Code'" [FormGroupName]="editPayFG" [ReadOnly]="true">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-date-picker [LabelText]="'Date Paid'" [PlaceHolder]="''"
                            [FormGroupName]="editPayFG" [ControlName]="'DatePaid'" [AutoFocus]="true">
                            </eprx-date-picker>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Coverage Code'" [PlaceHolder]="''"
                                [ControlName]="'CoverageCD'" [FormGroupName]="editPayFG">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Ingredient Cost Paid'" [PlaceHolder]="''"
                                [ControlName]="'ICostPaid'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'0999999999.00'" [BeforeDecimal]="10" [DecimalValues]="2" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('ICostPaid')">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-input [LabelText]="'Basis'" [PlaceHolder]="''"
                                [ControlName]="'BASISOFREI'" [FormGroupName]="editPayFG" [MaxLength]="1">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Dispensing Fee Paid'" [PlaceHolder]="''"
                                [ControlName]="'DFEEPaid'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('DFEEPaid')">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-input [LabelText]="'Basis'" [PlaceHolder]="''"
                                [ControlName]="'BOC_DFEE'" [FormGroupName]="editPayFG" [MaxLength]="1">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Copay'" [PlaceHolder]="''"
                                [ControlName]="'PTPayment'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'0999999999.00'"  [BeforeDecimal]="10" [DecimalValues]="2" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('PTPayment')">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-input [LabelText]="'Basis'" [PlaceHolder]="''"
                                [ControlName]="'BOC_Copay'" [FormGroupName]="editPayFG" [MaxLength]="1">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Flat Sales Tax'" [PlaceHolder]="''"
                                [ControlName]="'FSTaxAmt'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('FSTaxAmt')">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-input [LabelText]="'Basis'" [PlaceHolder]="''"
                                [ControlName]="'BOC_FSTax'" [FormGroupName]="editPayFG" [MaxLength]="1">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Percentage Sales Tax'" [PlaceHolder]="''"
                                [ControlName]="'PSTaxAmt'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('PSTaxAmt')">
                            </eprx-input>
                        </div>
                        <div class="col-2">
                            <eprx-input [LabelText]="'Basis'" [PlaceHolder]="''"
                                [ControlName]="'BOC_PSTax'" [FormGroupName]="editPayFG" [MaxLength]="1">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Tax Rate'" [PlaceHolder]="''"
                                [ControlName]="'PSTaxRate'" [FormGroupName]="editPayFG"
                                [MaskPattern]="'0999.00'" [DropSpclChar]="false" (TriggerSearchValue)="setNumberFormat('PSTaxRate')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Incentive Amount'" [PlaceHolder]="''"
                                [ControlName]="'INCAMTPaid'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('INCAMTPaid')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Professional Service Fee'" [PlaceHolder]="''"
                                [ControlName]="'PSFEEPaid'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('PSFEEPaid')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Other Amount Paid'" [PlaceHolder]="''"
                                [ControlName]="'OthAMTPaid'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('OthAMTPaid')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Other Payer Paid Amount Recognized'" [PlaceHolder]="''"
                                [ControlName]="'OthPayRecg'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('OthPayRecg')">
                            </eprx-input>
                        </div>
                        <!-- <div class="col-6">
                            <eprx-input [LabelText]="'DIR Fee'" [PlaceHolder]="''"
                                [ControlName]="'DIRFee'" [FormGroupName]="editPayFG" [ReadOnly]="true"
                                [InputType]="'CURRENCY'"
                                (TriggerSearchValue)="setNumberFormat('DIRFee')">
                            </eprx-input>
                        </div> -->
                        <div class="col-12">
                            <eprx-input [LabelText]="'Total Amount Paid'" [PlaceHolder]="''"
                                [ControlName]="'TotAmtPaid'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'0999999999.00'"  [BeforeDecimal]="10" [DecimalValues]="2" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('TotAmtPaid')">
                            </eprx-input>
                        </div>
                        <div class="col-8">
                            <eprx-input [LabelText]="'Other Pay Amount1'" [PlaceHolder]="''"
                                [ControlName]="'OthPayAmt1'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('OthPayAmt1')">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Type1'" [PlaceHolder]="''"
                                [ControlName]="'OthPayQ1'" [FormGroupName]="editPayFG" [MaxLength]="2">
                            </eprx-input>
                        </div>
                        <div class="col-8">
                            <eprx-input [LabelText]="'Other Pay Amount2'" [PlaceHolder]="''"
                                [ControlName]="'OthPayAmt2'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('OthPayAmt2')">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Type2'" [PlaceHolder]="''"
                                [ControlName]="'OthPayQ2'" [FormGroupName]="editPayFG" [MaxLength]="2">
                            </eprx-input>
                        </div>
                        <div class="col-8">
                            <eprx-input [LabelText]="'Other Pay Amount3'" [PlaceHolder]="''"
                                [ControlName]="'OthPayAmt3'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('OthPayAmt3')">
                            </eprx-input>
                        </div>
                        <div class="col-4">
                            <eprx-input [LabelText]="'Type3'" [PlaceHolder]="''"
                                [ControlName]="'OthPayQ3'" [FormGroupName]="editPayFG" [MaxLength]="2">
                            </eprx-input>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-6">
                            <eprx-input [LabelText]="'Amount Applied To Periodic Deductible'" [PlaceHolder]="''"
                                [ControlName]="'Amt_PERDED'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('Amt_PERDED')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Amount Applied To Copay/Co-Ins'" [PlaceHolder]="''"
                                [ControlName]="'Amt_COPINS'" [FormGroupName]="editPayFG"
                                [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('Amt_COPINS')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Authorization #'" [PlaceHolder]="''" [ControlName]="'Auth_No'"
                                [FormGroupName]="editPayFG">
                            </eprx-input>
                        </div>

                        <div class="col-6">
                            <eprx-input [LabelText]="'Paid QI'" [PlaceHolder]="''" [ControlName]="'OthPYRPDQ'" [FormGroupName]="editPayFG"
                                [MaxLength]="2">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Tax Exempt'" [PlaceHolder]="''" [ControlName]="'TaxExempt'" [MaxLength]="1"  [DecimalValues]="0" [InputType]="'Number'"
                                [FormGroupName]="editPayFG">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Sales Tax Amount'" [PlaceHolder]="''" [ControlName]="'Amt_SaleTx'"
                                [FormGroupName]="editPayFG" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('Amt_SaleTx')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Amount Appl. Product Selection'" [PlaceHolder]="''" [ControlName]="'Amt_PRODSL'"
                                [FormGroupName]="editPayFG" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('Amt_PRODSL')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Amount Exceeding Max'" [PlaceHolder]="''" [ControlName]="'Amt_EXCMAX'"
                                [FormGroupName]="editPayFG" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('Amt_EXCMAX')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'% Sales Tax Basis Paid'" [PlaceHolder]="''" [ControlName]="'PSTaxBasisPaid'"
                                [FormGroupName]="editPayFG" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('PSTaxBasisPaid')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Accumulated Deductible Amount'" [PlaceHolder]="''" [ControlName]="'AccumDedAmount'"
                                [FormGroupName]="editPayFG" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('AccumDedAmount')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Remaining Deductible Amount'" [PlaceHolder]="''" [ControlName]="'RemDedAmount'"
                                [FormGroupName]="editPayFG" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('RemDedAmount')">
                            </eprx-input>
                        </div>
                        <div class="col-6">
                            <eprx-input [LabelText]="'Remaining Benefit Amount'" [PlaceHolder]="''" [ControlName]="'RemBenefitAmount'"
                                [FormGroupName]="editPayFG" [InputType]="'CURRENCY'" [MaskPattern]="'099999.00'" [DropSpclChar]="false"
                                (TriggerSearchValue)="setNumberFormat('RemBenefitAmount')">
                            </eprx-input>
                        </div>
                        <!-- Currently commented for later use-->
                        <div class="col-6" *ngIf="editPayFG?.controls?.RecType?.value === 'R'">
                            <eprx-input [LabelText]="'Reject Code(s)'" [PlaceHolder]="''"
                                [ControlName]="'REJCodes'" [FormGroupName]="editPayFG" [ReadOnly]="true">
                            </eprx-input>
                            <i class="far fa-pencil-alt edit--insurance" (click)="openRejectCodes()" title="Edit Reject Codes"
                            ></i>
                        </div>
                        <div class="col-12">
                            <div class="editpatient--body newrx--body edit--drug edit--drug">
                                <div class="exprx--common-block">
                                    <div class="eprx--block__header">
                                        <div class="row">
                                            <div class="col">
                                                <span class="eprx--header__heading"> Benefit Stage Information</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="eprx--block__content">
                                        <wj-flex-grid [headersVisibility]="'Column'" [selectionMode]="'Row'" [itemsSource]="benefitInfoWJ"
                                            [isReadOnly]="true">
                                            <wj-flex-grid-column [header]="'Action'" [width]="60">
                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                    <i class="far fa-edit actions" title="Edit" (click)="openBenfitEdit(item)"></i>
                                                </ng-template>
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'Rx No'" [width]="100" [format]="'d'">
                                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                                    {{rxInfo?.Prescription?.PrescNum}}
                                                </ng-template>
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'Fill#'" [width]="100" [binding]="'Fillno'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'Ins Code'" [width]="150" [binding]="'Ins_Code'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'Field Name'" [width]="200" [binding]="'FieldName'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'Field Value1'" [width]="100" [binding]="'FieldValue1'">
                                            </wj-flex-grid-column>
                                            <wj-flex-grid-column [header]="'Field Value2'" [width]="200" [binding]="'FieldValue2'">
                                            </wj-flex-grid-column>
                                            <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                                        </wj-flex-grid>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </span>
        <button footer type="submit" class="hotkey_success" (click)="UpdatePayRecords()"
         appShortcutKey [AltKey]="'u'"> <span>U</span> Update</button>
        <button footer type="submit" class="hotkey_success" (click)="closeModalSM()"
        appShortcutKey [AltKey]="'c'">
            <span>C</span> Cancel
        </button>
    </app-custom-modal>
</ng-template>
<ng-template #RejectcodesEdit let-c="close" let-d="dismiss">
    <div class="modal-header border-top-right-radius_10">
        <h4 class="modal-title"> Add/Edit Reject Codes </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close"  (click)="d('Cross click');closeRejectPopup()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body exprx--common-block erx--body edit--drug newrx--body">
        <div class="row col-md-12">
            <div class="col-md-8 pl-0 padding_top_0_2rem margin_left_2_2">
                <eprx-input [PlaceHolder]="'Add/RejectCodes'" [Title]="'Add Reject Codes'" (TriggerChangeValue)="setTempRx($event)" [InputValue]="tempRxNumber"
                    [HasControl]="false" [MaxLength]="2" (TriggerOnEnterValue)="addRejCode()">
                </eprx-input>
            </div>
            <div class="col-md-3 margin_top_0_3rem padding_left_4rem">
                <img src="assets/img/Vector3.png" height="35" width="35" title="Add Reject Codes" (click)="addRejCode()">
            </div>
        </div>
        <div class="container">
        <div class="col-12">
            <div class="max-height_26vh table-responsive overflow_scroll">
            <table class="table table-bordered unbilled-queue--tabl text-center">
                <thead>
                    <tr class="table--header-rej">
                        <th class="font_size_16 line_height_1_2">Reject Codes</th>
                        <th class="font_size_16 line_height_1_2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of rejectList">
                        <td class="ttext-rej">{{item}}</td>
                        <td class="ttext-rej"><img src="assets/img/del.png" height="15" width="15" title="Remove" (click)="removeRejectCodeModal(item)"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
    </div>
    <div class="modal-footer align-self-center border_top--none" style="border-top:none">
        <button type="button" class="hotkey_success" (click)="c('Close click');closeRejectPopup()" appShortcutKey
            [AltKey]="'c'"><span>C</span> Close</button>
    </div>
</ng-template>
