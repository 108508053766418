import { PatDiagnosis } from "./diagnosis-details.model";
import { DrugBucketInfo } from "./drug-bucket.model";
import { PatientMessageSettings } from "./family-info.model";

// import { Email } from 'src/app/models';
export class PatientInfo {
    Patient: Patient = null;
    Person: Person = null;
    Genders: Gender = null;
    Emails: Email = null;
    Phones: Phone[] = [];
    Addresses: Address = null;
    States: State = null;
    Citites: City = null;
    Languages: Lang = null;
    PatientSplPrograms: PatientSplPrograms = null;
    SplPrograms: SplProgram = null;
    PatSettings: PatSetting = null;
    Insurances: Insurances = null;
    InsuCarrier: InsuCarrier = null;
    // Organizations: Organization = null;
    // InsuPlans: InsuPlan = null;
    InsuGroups: InsuGroups = null;
    InsuCards: InsuCards = null;
    RelationCatogiries: RelationCat = null;
    CoPayCatogiries: CoPayCat = null;
    IdProofs: IdProof = null;
    Drugs: Drug[] = [];
    OtherAllergies: OtherAllergy[] = [];
    PatAllergies: Allergy[] = [];
    // PatientFamily: PatientFamily = null;
    Family: Family = null;
    FamilyInsurance: FamilyInsurance = null;
    FamilyAddress: FamilyAddress = null;
    FamilyPhone: FamilyPhone = null;
    FamilyInsuGroup: FamilyInsuGroup = null;
    FamilyInsuCard: FamilyInsuCard = null;
    IsForceAllergySelection: boolean = null;
    PatientInsurances: PatientInsurances[] = [];
    PatPricings: PatPricings = null;
    Facility: Facility = null;
    PatCustomCat: PatCustomCat = null;
    PatientChartNums: PatientChartNums = null;
    // GSDDAllergies: GSDDAllergy = null;
    PatientDiagnosis: PatDiagnosis[] = [];
    PatientMessageSettings : PatientMessageSettings = null;
}

export class Allergy {
    AllergyClassID: number = null;
    AllergyDescription: string = null;
    AllergyName: string = null;
    LstConfDttm : string = null;
}

export class Patient {
    Id: number = null;
    PharmacyId: number = null;
    MergedId: number = null;
    PrimeInsuId: number = null;
    LanguageId: number = null;
    LanguageCode: string = null;
    DiscountSchId: number = null;
    CreatedById: number = null;
    UpdatedById: number = null;
    Remarks: string = null;
    IsDeceased = false;
    Is340B = false;
    CreatedBy: any = null;
    IsMobileValid = false;
    MobileVerifiedDtTm: any = null;
    InterfaceId: number = 2;

}

export class Person {
    Id: number = null;
    TenantId: number = null;
    FirstName: string = null;
    MiddleName: string = null;
    LastName: string = null;
    Initials: string = null;
    BirthDt: any = null;
    GenderId: number = null;
    MaritalStatusId: number = null;
    ProfPicExtn: string = null;
    PrimeAddsId: number = null;
    PrimePhId: number = null;
    PrimeEmailId: number = null;
    NPINum: string = null;
    IdProofCode: string = null;
    IdProofId: number = null;
    Remarks: string = null;
    ActiveStatusId: number = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class Gender {
    Id: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class Email {
    Id: number = null;
    TenantId: number = null;
    eMailCatId: number = null;
    xeMail: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class Phone {
    Id: number = null;
    TenantId: number = null;
    PhoneCatId: number = null;
    AreaCode: string = null;
    xPhone: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class PerPrefContact {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    ContactType: string = null;
    ContactNumber: string = null;
    Contactname: string = null;
    IsPreferred: boolean = null;
    IsActive: boolean = null;
    CreatedDtTm: any = null;
    ModifiedDtTm: any = null;
    Action: string = null;
}

export class Address {
    Id: number = null;
    TenantId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    ZipCode: string = null;
    CountryId: number = null;
    StateId: number = null;
    CityId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    CityName: string = null;
    StateName: string = null;
    Extension: string = null;
}

export class State {
    Id: number = null;
    CountryId: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class City {
    Id: number = null;
    StateId: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class Lang {
    Id: number = null;
    Name: string = null;
    LangCode: string = null;
    Remarks: string = null;
    IsRightAligned: boolean = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}


export class SplProgram {
    Id: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class PatSetting {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    IsRefillRemSet: boolean = null;
    IsEZCapReqd: boolean = null;
    IsPkgAllowed: boolean = null;
    IsPrintAllowed: boolean = null;
    // IsWorkflowPatient: boolean = null;
}

export class Insurances {
    GroupName: string = null;
    ChFirstName: string = null;
    ChLastName: string = null;
    Relation: string = null;
    InsuPriority: any = null;
    InsurerPhone: any = null;
    InsuPriorityId: any = null;
    Id: number = null;
    TenantId: number = null;
    InsurerId: number = null;
    InsuGrpId: number = null;
    RelCatId: number = null;
    InsuCardId: number = null;
    InsuPriId: number = null;
    IdProofId: number = null;
    IdProofVal: number = null;
    CoPayCatId: number = null;
    CPBrandVal: number = null;
    CPGenericVal: number = null;
    PerNum: string = null;
    Remarks: string = null;
    IsPatAssigned: boolean = null;
    ActiveStatusId: number = null;
    CreatedDtTm: string = null;
    ModifiedDtTm: string = null;
    UpdatedById: number = null;
    CreatedById: number = null;
    IsCash: boolean = null;
    PatientQualifId: number = null;
    PatientQualifName: string = null;
    InsurerActiveStatus: boolean = null;
    RelationName: string = null;
}

export class InsuCarrier {
    ProcessorNum: any = null;
    Name: any = null;
    Id: number = null;
    TenantId: number = null;
    InsurerCode: string = null;
    BINNum: string = null;
    Remarks: string = null;
    insutypeName: any = null;
    insuranceIdName : any = null;
    ParentInsuId : number = null;
}

// export class Organization {
//     Id: number = null;
//     TenantId: number = null;
//     Name: string = null;
//     ContPerson: string = null;
//     WebURL: string = null;
//     PrimeAddsId: number = null;
//     IsDeleted: boolean = null;
//     IsActive: boolean = null;
//     CreatedDtTm: Date = null;
//     ModifiedDtTm: Date = null;
// }

// export class InsuPlan {
//     Id: number = null;
//     TenantId: number = null;
//     InsuCarrierId: number = null;
//     PlanCode: string = null;
//     PharmacyNum: string = null;
//     InsuPlanIdQualifId: number = null;
//     ProcessorNum: string = null;
//     ProcCatId: number = null;
//     PayorId: number = null;
//     IsMedicaidSup: boolean = null;
//     IsMedigapSup: boolean = null;
//     IsMedicareSup: boolean = null;
//     MedicaidAgNum: string = null;
//     Remarks: string = null;
//     IsDeleted: boolean = null;
//     IsActive: boolean = null;
//     CreatedDtTm: Date = null;
//     ModifiedDtTm: Date = null;
// }

export class InsuGroups {
    Id: number = null;
    InsurerId: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class InsuCards {
    Id: number = null;
    TenantId: number = null;
    InsuCatId: number = null;
    PolicyNum: string = null;
    FirstName: string = null;
    LastName: string = null;
    ExpiryDt: any = null;
    IsActive: boolean = null;
    submissionType: any = null;
}

export class RelationCat {
    Id: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class CoPayCat {
    Id: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class IdProof {
    Id: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class Drug {
    IsMac: boolean = null;
    MAC: any = null ;
    MacLastUpdated: Date = null;
    MacUpdatedById: number = null;
    MacUpdatedByName: String = null;
    WAC: any = null;
    WacLastUpdated: Date = null;
    WacUpdatedById: number = null;
    WacUpdatedByName: String = null;
    DrugUnit: any = null;
    MeasureUnitName: any = null;
    DrugFormName: string = null;
    PotencyUnitCode: any = null;
    Id: number = null;
    TenantId: number = null;
    PharmId: number = null;
    DrugTypeId: number = null;
    DrugCode: string = null;
    BillingNDC: string = null;
    BrandOrGenNDC: string = null;
    ReplaceNDC: string = null;
    OldNDC: string = null;
    UPC: string = null;
    AWPPack: any = null;
    UnitPriceAWP: any = null;
    UnitPriceAWP3Digits: any = null;
    DirectUnitPrice: any = null;
    CostPack: any = null;
    UnitPriceCost: any = null;
    UnitPriceCost3Digits: any = null;
    NDCQualiId: number = null;
    LastUpdateDt: string = null;
    QuickCode: string = null;
    Name: string = null;
    GenericName: string = null;
    Strength: string = null;
    ManufactId: number = null;
    ManufactName: string = null;
    BrandName: string = null;
    DrugFormId: number = null;
    DrugUnitId: number = null;
    MeasureUnitId: number = null;
    UnitDoseIndId: number = null;
    PriceSchId: number = null;
    Surcharge: number = null;
    PerOfBrand: number = null;
    IsDiscountable: boolean = null;
    IsExclFrmUpdate: boolean = null;
    IsPriceUpdAuto: boolean = null;
    DrugClass: number = null;
    DrugFormSigCodeId: number = null;
    DrugUseSigCodeId: number = null;
    SigId: number = null;
    DefaultSigCode: string = null;
    Instructions: string = null;
    DefaultQty: any = null;
    DefaultDays: number = null;
    DiagnosisId: any = null;
    Remarks: string = null;
    QtyPack: any = null;
    DiscontDt: Date = null;
    IsFDAApprovedNULL: number = null;
    IsPreferred: boolean = null;
    IsIncInRefilRem: boolean = null;
    IsBranded: boolean = null;
    IsInvMangd: boolean = null;
    Therapeutic: number = null;
    TherapeuticCode: number = null;
    IsWarningCodeChkd: boolean = null;
    WarningCode: string = null;
    IsDeleted: boolean = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    PrimeRxDrugNo: number = null;
    DrugLabelName: String = null;
    DrugFullName: String = null;
    manageinventory: any = null;
    Is340B: boolean = null;
    DrugBucket: DrugBucketInfo = null;
    AlternateCost: number = null;
    DispUnitFormId: any = null;
    VaccineGroupId: any = null;
    VaccineGroupName: string = null;
    IsVaccine:boolean = null;
    // IsTriplicate = false;
    LstConfDttm : string = null;
    licenseTypeId: number = null;
    equivalenceCodes: any = null;
    productModifierIds: any = null;
    productId: number = null;
}

export class OtherAllergy {
    Id: number = null;
    Name: string = null;
    LstConfDttm : string = null;
}

export class GSDDAllergy {
    Id: number = null;
    ClassId: number = null;
}

export class PatientSplPrograms {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    SplProgramId: number = null;
    SplProgram: string = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    CreatedDtTm: Date = null;
}

export class Family {
    Id: number = null;
    TenantId: number = null;
    FamilyInsuId: number = null;
    FamilyAddsId: number = null;
    FamilyPhId: number = null;
    Name: string = null;
    Remarks: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class FamilyInsurance {
    ActiveStatusId: number = null;
    CPBrandVal: number = null;
    CPGenericVal: number = null;
    CoPayCatId: number = null;
    CreatedById: number = null;
    CreatedDtTm: Date = null;
    Id: number = null;
    IdProofId: number = null;
    InsuCardId: number = null;
    InsuGrpId: number = null;
    InsuPriId: number = null;
    InsurerId: number = null;
    IsPatAssigned: boolean = null;
    ModifiedDtTm: Date = null;
    PerNum: string = null;
    RelCatId: number = null;
    Remarks: string = null;
    TenantId: number = null;
    UpdatedById: number = null;
}

export class FamilyAddress {
    Id: number = null;
    TenantId: number = null;
    AddressLine1: string = null;
    AddressLine2: string = null;
    ZipCode: string = null;
    CountryId: number = null;
    StateId: number = null;
    CityId: number = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    StateName: string = null;
    CityName: string = null;
    Extension: string = null;
}

export class FamilyPhone {
    Id: number = null;
    TenantId: number = null;
    PhoneCatId: number = null;
    AreaCode: string = null;
    xPhone: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
}

export class FamilyInsuGroup {
    CreatedDtTm: Date = null;
    Id: number = null;
    InsurerId: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    Name: string = null;
    Remarks: string = null;
}

export class FamilyInsuCard {
    ExpiryDt: Date = null;
    FirstName: string = null;
    Id: number = null;
    InsuCatId: number = null;
    IsActive: boolean = null;
    LastName: string = null;
    PolicyNum: string = null;
    TenantId: number = null;
}

export class PatientChartNums {
    ChartAssigneeId: number = null;
    ChartNum: string = null;
    CreatedDtTm: Date = null;
    Id: number = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    PatientId: number = null;
    TenantId: number = null;
}

export class PatientInsurances {
    Id: number = null;
    InsurerCode: string = null;
    BINNum: string = null;
    Name: string = null;
    ProcessorNum: string = null;
    PolicyNum: string = null;
    InsuGroupName: string = null;
    IsTroop: boolean = null
}

export class PatPricings {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    DiscCode: string = null;
    DiscSchId: number = null;
    BrandPriceCode: string = null;
    BrandPriceSchId: number = null;
    GenericPriceCode: string = null;
    GenPriceSchId: number = null;
    IsAppliedCashRx: boolean = null;
}

export class Facility {
    Id: number = null;
    FacCode: string = null;
    Name: string = null;
    IsCMSQ: boolean = false;
    Facility_Id: string = null;
    POFormCode: string = null;
    POTextCode: string = null;
    Remarks: string = null;
    Beds: string = null;
    FacilityCatId: number = null;
    StandingOrder: string = null;
    PharmServiceCatId: number = null;
    PatResiCatId: number = null;
    IsPrintStndgOrd: boolean = null;
    IsPrintPatTreat: boolean = null;
    IsPrintHOA: boolean = null;
    IsPrintDosePerDay: boolean = null;
    IsRxAdmin: boolean = null;
    FacilityName: string = null;
    Contact: string = null;
    Telephone1: string = null;
    Telephone2: string = null;
    TelePhoneId: number = null;
    SecondTelePhoneId: number = null;
    OrgCreatedDate: string = null;
    Mobile: number = null;
    Fax: number = null;
    Email: string = null;
    Sort: string = null;
    Default_driver: any = null;
    GeneralBucketId: number = null;
    Is340BucketId: number = null;
    MobilePhoneId: number = null;
}

export class PatCustomCat {
    Id: number = null;
    Code: string = null;
    Name: string = null;
    CreatedDtTm: Date = null;
    IsActive: boolean = null;
    ModifiedDtTm: Date = null;
    Remarks: null;
}

export class PatientDiagnosis {
    Id: number = null;
    TenantId: number = null;
    PatientId: number = null;
    DiagnosisId: string = null;
    IsActive: boolean = null;
    CreatedDtTm: Date = null;
    ModifiedDtTm: Date = null;
    Description: any = null;
}
