<div class="newrx--body merge--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-10 padding-0">
                <div class="row">
                    <div class="icon">
                        <i class="far fa-signature text-white font_size_1_25rem"></i>
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0">View Signature By Date
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <button type="button" id="clearButton" class="hotkey_success float-right" appShortcutKey [AltKey]="'l'" (click)="clearData()"> <span>L</span>
                    Clear
                </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding--right__7 ">
            <div class="exprx--common-block erx--body insurance--body">
                <div class="eprx--block__header">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-11">
                                    <div class="eprx--header__heading">
                                        <span><span>View Signature by Date</span></span>
                                    </div>
                                </div>
                                <div class="col-1 padding_left_7rem">
                                    <app-user-guide-icon [ActiveTabId]= "'SignByDate'"></app-user-guide-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <eprx-date-picker [AutoFocus]="true" [RxSelectId]="'fromDate'" [LabelText]="'Date From'" [PlaceHolder]="''" [MarkAsTouched]="InFiltersFrmGrp?.controls?.fromDate?.touched"
                                [ControlName]="'fromDate'" [FormGroupName]="InFiltersFrmGrp" [MinDate]="" [MaxDate]="minDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                            <eprx-date-picker [RxSelectId]="'toDate'" [LabelText]="'Date To'" [PlaceHolder]="''" [MarkAsTouched]="InFiltersFrmGrp?.controls?.toDate?.touched"
                                [ControlName]="'toDate'" [FormGroupName]="InFiltersFrmGrp" [MinDate]="" [MaxDate]="minDate">
                            </eprx-date-picker>
                        </div>
                        <div class="col-md-6 align-button text-right">
                            <button title="Search / Refresh" type="submit" class="hotkey_success" (click)="searchFilters()" (keydown.enter)="searchFilters()" appShortcutKey
                                [AltKey]="'f'" (keydown.tab)="focusToFirst($event)"> <span>F</span>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-md-12">
                                <wj-flex-grid #transHistGrid [headersVisibility]="'Column'" [itemsSource]="tarsnHistWJ" [isReadOnly]="true" 
                                (drop)="this._storageService.storeWijmoGridDataInSession(transHistGrid)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(transHistGrid)"[ngClass]="!totalCount? 'no-data':''"
                                    [selectionMode]="'Row'" [columnPicker]="'primeescSignByDateListWJ'">
                                    <wj-flex-grid-column [header]="'Action'" [width]="'0.8*'">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" class="text-center">
                                            <i class="far fa-signature fa-sm actions actions--Highlightfocus--icon" title="Show Sign" (click)="openSignModal(item)"></i>
                                        </ng-template>
                                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                                            <!-- <div title={{Action}}> -->
                                            Action
                                        <!-- </div> -->
                                        </ng-template>
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                                    *ngFor="let rxheader of wjHeaders; let i = index" [width]="rxheader['width']">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <div *ngIf="rxheader['hName'] === 'Pickup Date'">
                                                {{item[rxheader['hName']] | date :'MM/dd/yyyy'}}
                                            </div>
                                            <div *ngIf=" !(rxheader['hName'] === 'Pickup Date')" [title]="((item[rxheader.hName]+'')|uppercase)">
                                                {{ item[rxheader['hName']] }}
                                            </div>
                                        </ng-template>
                                    </wj-flex-grid-column>
                                    <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                                </wj-flex-grid>
                                <app-eprx-pagination [TotalCount]="totalCount" [PageSize]="Size"  [WijmoName]="transHistGrid"
                                *ngIf="totalCount"
                                (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [HasExport]="true">
                            </app-eprx-pagination>
                    
        <!-- <h3 *ngIf="!transactionHistory ||transactionHistory.length == 0" class="text-center">No transaction history</h3> -->
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<app-patient-sign-record [RPH]="RPH" *ngIf="showPatientSign" [PatientInfo]="rxPatientModel" [SignId]="signId" [SigType]="sigType" [SignatureData]="signatureData" (IsPopupClosed)="showPatientSign=false"></app-patient-sign-record>

