<div class="exprx--common-block">
    <div class="eprx--block__header text-white pl-3 font-weight-bold">
        <div class="row">
            <div class="col-11">
                {{HeaderTitle ? HeaderTitle : 'To Pharmacy Details'}}
            </div>
            <!-- <div class="col-1" *ngIf="selectedpharmacyDetails && !sourcepharmacyInfo">
                <i (click)="openPharmacyEdit()" class="far  fa-edit" title="Pharmacy Edit"></i>
            </div> -->
        </div>
    </div>
    <div class="eprx--block__content  pt-0" [ngClass]="isRequestedInfo ? 'height_230' : 'cards_height_transferIn'">
        <div class="seconday-search--grid  pt-3 mb-0" *ngIf="!sourcepharmacyInfo">
            <app-pharmacy-suggest [ResetThePhramacy]="resetThePhramacy" [IsEdit]="isedit" (CloseEditPharmacy)="isedit=false"
                (selectedPharm)="selectedPharmDetails($event)"></app-pharmacy-suggest>
        </div>
        <div *ngIf="(selectedpharmacyDetails?.ServiceLevels || !selectedpharmacyDetails)" class="row row-space-transfer-in">
            <div class="col-12">
                <label>{{pharmacyabels.pName}}</label>
                <div class="label--data"><span>{{selectedpharmacyDetails?.PharmName ?
                        (selectedpharmacyDetails?.PharmName) : msgConfig.EMPTY_Data}}</span></div>
            </div>
        </div>
        <div *ngIf="(selectedpharmacyDetails?.ServiceLevels || !selectedpharmacyDetails)" class="row row-space-transfer-in">
            <div class="col-4">
                <label>{{pharmacyabels.Phone}}</label>
                <div class="label--data"><span>{{selectedpharmacyDetails?.Phone ?
                        (selectedpharmacyDetails?.Phone | mask : "(000)000-0000") : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col-4">
                <label>{{pharmacyabels.Fax}}</label>
                <div class="label--data"><span>{{selectedpharmacyDetails?.Fax ?
                        (selectedpharmacyDetails?.Fax | mask : '(000)000-0000') : msgConfig.EMPTY_Data}}</span></div>
            </div>
            <div class="col-4">
                <label>{{pharmacyabels.NPI}}</label>
                <div class="label--data"><span>{{selectedpharmacyDetails?.NPINum?.length ?
                        selectedpharmacyDetails?.NPINum : msgConfig.EMPTY_Data}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="(selectedpharmacyDetails?.ServiceLevels || !selectedpharmacyDetails)" class="row row-space-transfer-in">
            <div class="col-12">
                <label>{{pharmacyabels.Address}}</label>
                <div class="label--data"> <span>{{(selectedpharmacyDetails?.addresses) ?
                        selectedpharmacyDetails?.addresses : msgConfig.EMPTY_Data}}</span></div>
            </div>
        </div>
        <div *ngIf="(selectedpharmacyDetails?.ServiceLevels || !sourcepharmacyInfo) && !isRequestedInfo" class="row row-space-transfer-in">
        </div>
        <div class="row row-space-transfer-in col " style="margin-top: 4rem;"
            *ngIf="selectedpharmacyDetails && !selectedpharmacyDetails?.ServiceLevels">
            <div class="fs-18 col-12">
                The selected <span class="font-weight-bold text-uppercase">{{selectedpharmacyDetails?.PharmName ?
                        (selectedpharmacyDetails?.PharmName) : msgConfig.EMPTY_Data}}</span>, located at <span class="text-uppercase font-weight-bold">
                            {{(selectedpharmacyDetails?.addresses) ?
                        selectedpharmacyDetails?.addresses : msgConfig.EMPTY_Data}}
                        </span>, with phone number <span class="font-weight-bold text-uppercase">
                            {{selectedpharmacyDetails?.Phone ?
                            (selectedpharmacyDetails?.Phone | mask : "(000)000-0000") : msgConfig.EMPTY_Data}}
                        </span> does not have the required transfer service available.
            </div>
        </div>
    </div>
</div>