import { RxUtils } from "src/app/utils/rx.util";
import { Component, OnInit, ViewChild, HostListener, Input, OnChanges} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CollectionView } from "@grapecity/wijmo";
import { EditPrescriberService, PatPreDrugModalService, AlertService } from "../../../services";
import * as moment from "moment";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrivMaskGuard } from "src/app/guards";
import { AccCategoryE, RxE } from "src/app/models";
import { NRxUtils, WijimoUtil } from "src/app/utils";
import { FormGroup } from "@angular/forms";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { ColorCode, CommonUtil } from "../../../utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";
import { StorageService } from "src/app/services/storage.service";
@Component({
    selector: "app-prescriber-history",
    templateUrl: "./prescriber-history.component.html"
})
export class PrescHistoryComponent implements OnInit, OnChanges {
    @Input() prescriberId: number;
    @Input() prescriberFG: FormGroup;
    prescriberInfo: any;
    pageNumber = 1;
    pageSize = 15;
    wjHeaders: any;
    totalCount: any;
    pageCount: number;
    isDataExists = true;
    @ViewChild("PrescriberHistory")
    flex: wjcGrid.FlexGrid;

    @ViewChild("Redirect", { static: true })
    Redirect: any;

    prescHistoryList: CollectionView;
    startIndex = 0;
    route: string;
    routeFrom: any;
    openOverride: boolean;
    privType: any;
    routeToRxInfo: any;
    categoryId: any;
    accessPrivCate: any;
    rxPrivs: any;
    prescriberList: any;
    unsubscribe$ : Subject<void> = new Subject();

    @HostListener("window:keydown", ["$event"])
    keyEventAlt(event: KeyboardEvent) {
        if (event.altKey && !this._modalService.hasOpenModals()) {
            if (event.which === 67) { // c
                event.preventDefault();
                this.routeBack();
            }
        }
    }

    constructor(
        private _prescService: EditPrescriberService,
        private _actvRoute: ActivatedRoute,
        private _rxUtils: RxUtils,
        private _router: Router,
        private _privMaskGuard: PrivMaskGuard,
        private _modalService: NgbModal,
        private _nrxUtils: NRxUtils,
        private _patPreDrugModalServ: PatPreDrugModalService,
        private _wijimoUtils: WijimoUtil,
        private _hoverOver: HoverOver,
        private _colorCodeRx: ColorCodeRx,
        private _colorCode: ColorCode,
        private _commUtil: CommonUtil,
        private _alertService: AlertService,
        public _formatsUtil: FormatsUtil,
        public _storageService: StorageService
    ) {
        this.accessPrivCate = AccCategoryE;
        this.rxPrivs = RxE;

        this._actvRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            if (Object.keys(params).length > 0) {
                this.routeFrom = params.fm;
            }
        });
        this.route = window.location.href;
    }

    ngOnInit() {
        this.getInfo();
        this.prescriberInfo = this.prescriberFG.value;
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "prescHistoryListWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    ngOnChanges(changes: any) {
        this.prescriberFG.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (this.prescriberFG.dirty) {
                this.prescriberInfo = this.prescriberFG.value;
            }
        });
    }

    setSize(size: any) {
        this.pageSize = size;
        this.pageNumber = 1;
        this.prescriberFG.controls["PageSize"].patchValue(this.pageSize);
        this.setPage(1);
    }

    setPage(event: any) {
        this.pageNumber = event;
        this.startIndex = this.pageSize * (this.pageNumber - 1);
        this.prescriberFG.controls["PageNumber"].patchValue(this.startIndex);
        this.getInfo();
    }

    getInfo() {
        this._prescService
            .getPrescriberHistory(
                this.prescriberId,
                this.startIndex,
                this.pageSize
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.prescriberList = resp;
                    this.generateList(resp);
                }
            });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("prescHistoryListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("prescHistoryListWJ", storedWJ);
    }

    generateList(data: any) {
        if (data && data.TotalRecordCount) {
            this.isDataExists = true;
            this.totalCount = data.TotalRecordCount;
            this.pageCount = Math.ceil(data.TotalRecordCount / this.pageSize);
            const mapDataSet = [];
                data.ESRxViewModel.map((prescriber, i) => {
                    const j = {};
                    j["RowId"] = i;
                    j["Patient Name"] =
                        prescriber.Patient.lastname +
                        ", " +
                        prescriber.Patient.firstname;
                    j["Ins. Type"] = prescriber.insuranceInfo
                        ? prescriber.insuranceInfo.insuPlan
                            ? prescriber.insuranceInfo.insuPlan.PlanCode
                            : null
                        : null;
                    j["Drug Name"] = prescriber.Drug.drugname;
                    j["Strength"] = prescriber.Drug.strength;
                    j["Form"] = prescriber.Drug.drugformname;
                    j["Tot. Amt"] = prescriber.PrescReFill.UnitPriceUNC;
                    j["Address"] = prescriber.Patient.fulladdress;
                    j["Rx#"] = prescriber.Prescription.PrescNum;
                    j["PrescRefillId"] = prescriber.PrescReFill.Id;
                    j["Ref#"] = prescriber.PrescReFill.ReFillNum;
                    j["Quantity"] = prescriber.Prescription.Qty;
                    j["Date Filled"] = moment.utc(
                        prescriber.Prescription.RxReceiptDtTm
                    ).local().format("MM/DD/YY hh:mm:ss a");
                    j["DrugClass"] = prescriber.Drug.drugclass;
                    j["Status"] = this._commUtil.getRxStatus(prescriber.Prescription.RxStatusId, prescriber.PrescReFill.StatusId, prescriber.RxBill.StatusId);
                    j["IsPicked"] = prescriber.pickupRx && prescriber.pickupRx.IsPickedUp  ? true  : false;
                    j["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(j);
                    const rowId = j["RowId"] >= 0 ? j["RowId"] : "";
                    const RxDetails = rowId >= 0 ?  this.prescriberList.ESRxViewModel[rowId] : null;
                    j["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
                    // return j;
                    mapDataSet.push(j)
                })
                this.prescHistoryList = new CollectionView(mapDataSet,
                    {
                        sortComparer: (a: any, b: any) => {
                            return moment(a).isValid() && moment(b).isValid()
                                ? moment(a).diff(moment(b)) : null;
                        }
                    });
        } else {
            this.totalCount = 0;
            this.isDataExists = false;
            this.prescHistoryList = new CollectionView(null)
        }
    }

    init(flex: wjcGrid.FlexGrid) {
        //flex.columns[0].align = "center";
        flex.focus();
    }

    routeToEditRx(val: any) {
        this.routeToRxInfo = val;
        if (this.checkHasPrivsToAccess(
            this.accessPrivCate.Rx,
            this.rxPrivs.EditRx)) {
                this.routeToRx();
            } else {
                this.openOveride("EditRx");
            }
    }

    async routeToRx(rxNo?: any, rfId?: any) {
        const selectedrxNo = rxNo ? rxNo : +this.routeToRxInfo["Rx#"];
        const selectedrxId = rfId ? rfId : this.routeToRxInfo["PrescRefillId"];
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(selectedrxNo, selectedrxId, false, null, null, null, true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            this._patPreDrugModalServ._patPreDrugModal$.next("Rx");
            const modalRef = this._patPreDrugModalServ.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode.pipe(takeUntil(this.unsubscribe$))
                .subscribe(x => {
                    modalRef.close();
                    if (x === "Refresh") {
                        this.getInfo();
                    }
                    this._patPreDrugModalServ._patPreDrugModal$.next(null);
            });
            modalRef.componentInstance.CloseAndReopen.pipe(takeUntil(this.unsubscribe$))
            .subscribe(d => {
                modalRef.close();
                this._patPreDrugModalServ._patPreDrugModal$.next(null);
                if (d) {
                    this.routeToRx(d["rxNo"], d["rfId"]);
                } else {
                    this.getInfo();
                }
            });
        }
    }

    checkHasPrivsToAccess(category: string, privType: string) {
        return this._privMaskGuard.canActivate(category, privType);
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
            }
    }

    openOveride(content) {
        this.privType = content;
        this.categoryId = "Rx";
        this.openOverride = true;
    }

    routeBack() {
      this._rxUtils.routeBackFromPresc(this.routeFrom);
    }

    focusToFirst(event) {
        if (document.getElementById("saveButton")) {
            event.preventDefault();
            setTimeout(() => {
                document.getElementById("saveButton").focus();
            }, 10);

        }
    }

    editEventOnWijimo(event, grid) {
        event.preventDefault();
        this.routeToEditRx(grid["selectedItems"][0]);
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];
        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
               cell.style.fontWeight = "bold";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const rowId = row && row.dataItem ? row.dataItem["RowId"] : "";
                const RxDetails = rowId >= 0 ? this.prescriberList.ESRxViewModel[rowId] : null;
                const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx");
                if (colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }
    toggleColorLegend() {
        this._alertService.showHelpText({RxStatus: true, DrugName: true, Status: false});
    }

    ngOnDestroy() {
        this._storageService.getWijmoGridDataInSession(this.wjHeaders,"prescHistoryListWJ")
    }
}
