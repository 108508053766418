<div *ngIf="isPopUp" class="modal-header">
    <h4 class="modal-title">Clinic Address</h4>
    <!-- <button type="button" >
         <span aria-hidden="true">&times;</span> -->
    <button class="hotkey_success hotkey_primary--Override" appShortcutKey [AltKey]="'a'"
        (click)="openAddClinicPopUp(clinicData)"><span>A</span>ADD</button>
    <!-- </button> -->
</div>
<div class="exprx--common-block edit-drug" *ngIf="!isPopUp">
    <div class="eprx--block__header large_header">
        <div class="eprx--header__heading">
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="col-md-10">Clinic Address</div>
                    <div class="col-md-2 text-right">
                        <button class="hotkey_success hotkey_primary--Override" title="Add Clinical Address" (keydown.tab)="focusOutFromAdd($event)"
                            autofocus (click)="openAddClinicPopUp(clinicData)" appShortcutKey
                            [AltKey]="'a'"><span>A</span>ADD CLINICAL ADDRESS</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="isPopUp ? 'modal-body erx--body' : 'erx--body'">
    <div class="exprx--common-block edit-drug">
        <div class="eprx--block__content">
            <div class="row">
                <div class="col-md-12">
                    <wj-flex-grid #clinicalAddress [headersVisibility]="'Column'" [itemsSource]="clinicAddressList"
                        [isReadOnly]="true" [selectionMode]="'Row'" [allowSorting]="true"
                        (drop)="this._storageService.storeWijmoGridDataInSession(clinicalAddress)" (resizedColumn)="this._storageService.storeWijmoGridDataInSession(clinicalAddress)"
                        [columnPicker]="isPopUp ? 'prescClinicAddListPopupWJ' : 'prescClinicAddListWJ'" (keydown)="!isPopUp ? openPopOnWijimo(clinicData, $event) : ''"
                        (keydown.tab)="focusToFirst($event)"  (selectionChanged)="selectRadioOnKeyEvents(clinicalAddress)"
                        (initialized)="Init(clinicData, clinicalAddress)" [ngClass]="!totalCount ? 'no-data':''">
                        <wj-flex-grid-column [header]="'Actions'" [width]="87">
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-row = "row">
                                    <input *ngIf="isPopUp" type="radio" [checked]="(item['Id'] === checkedAddress)" id="row.index" (change)="changeAddress($event?.target?.checked,item)"/>
                                    <i class="far  fa-edit  actions ml-3 actions--Highlightfocus--icon" title="Edit" (click)="
                                            editClinicPopUp(clinicData, item)
                                        " ></i>
                                        <i class="far fa-map-marker-alt fa-lge font_size_1_3rem color_lightSkyBlue ml-1"  *ngIf="isPopUp ? (prescriberInfo?.primeAddressId ? (item['Id'] !== prescriberInfo?.primeAddressId) : (item['Id'] !== prescriberInfo?.Id)) : (item['Id'] !== prescriberInfo?.Address?.Id)" title="Click here to make this as Primary Address" (click)="exchangeAddressPopup(exchangeInfo,item)"></i>
                                        <i class="fas fa-map-marker-alt fa-lge font_size_1_3rem color_green ml-1"  *ngIf="isPopUp ? (prescriberInfo?.primeAddressId ? (item['Id'] === prescriberInfo?.primeAddressId) : (item['Id'] === prescriberInfo?.Id)) : (item['Id'] === prescriberInfo?.Address?.Id)" title="Primary Address"></i>
                            </ng-template>
                        </wj-flex-grid-column>
                        <wj-flex-grid-column (initialized)="init(clinicalAddress)" [header]="rxheader['hName']"
                            [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeaders; let i = index"
                            title="{{rxheader['hName']}}" [width]="rxheader['width']" [format]="'D'" >
                            <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                <div *ngIf="!( rxheader['hName'] === 'Active' || rxheader['hName'] === 'Primary Address')" title = {{item[rxheader.hName]|uppercase}}>
                                    {{ item[rxheader['hName']] }}
                                </div>
                                <div class="text-center" *ngIf="
                                        rxheader['hName'] === 'Active' &&
                                        item[rxheader['hName']] === true
                                    ">
                                    <i class="far fa-check " title="Active"></i>
                                </div>
                                <div class="text-center" *ngIf="
                                        rxheader['hName'] === 'Active' &&
                                        item[rxheader['hName']] != true
                                    ">
                                    <i class="far fa-times  fa-uncheck" title="Inactive"></i></div>
                            </ng-template>
                        </wj-flex-grid-column>
                        <app-no-data-found *ngIf="!isDataExists"></app-no-data-found>
                    </wj-flex-grid>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="isPopUp">
    <button type="button" class="hotkey_primary" ngbAutofocus (click)="closeClinicalAddressModal()" appShortcutKey InputKey="o">
        <b>O</b> Ok
    </button>
</div>


<ng-template #warningData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label>The selected address is inactive..Please make it active to set as primary address</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" appShortcutKey InputKey="o" (click)="c('Close click')">
            <b>o</b> OK
        </button>
    </div>
</ng-template>
<ng-template #clinicData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Clinical Information </h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient insurance--body">
        <div class="col-sm-12">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                    <eprx-input [RxSelectId]="'ClinicalSPI'" [LabelText]="'SPI#'" [PlaceHolder]="'SPI#'" [ControlName]="'SPINum'"
                        [FormGroupName]="PrescAdds" [Title]="'SPI#'" [ValidPattern]="spiRegex" [MaxLength]="13"
                        [IsRequired]="true" [InputErrors]="SPINum?.errors" [FormGroupInvalid]="formGroupInvalid"
                        [ErrorDefs]="{
                            required: 'Required',
                            pattern: 'please enter 13 digits'
                        }" [MaskPattern]="'0000000000000'" [AutoFocus]="true">
                    </eprx-input>
                </div>
                <div class="col-md-6 messsage-height">
                    <label class="col-md-12 padding-0 "> Active </label>
                    <eprx-radio [ControlName]="'IsActive'" [FormGroupName]="Address" [IsRequired]="true"
                        [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['AddIsActive1', 'AddIsActive2']" [LabelForId]="">
                    </eprx-radio>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                    <eprx-input [LabelText]="'Address 1'" [RxSelectId]="'AddressLine1'" [PlaceHolder]="'Address 1'" [ControlName]="'AddressLine1'" (TriggerOnChange)="onAddressChange($event.target.value,'Address1')"
                        [FormGroupName]="Address" [Title]="'Address1'" [MaxLength]="25" [IsRequired]="true"
                        [InputErrors]="AddressLine1?.errors" [ErrorDefs]="{ required: 'Required' }">
                    </eprx-input>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                    <eprx-input [LabelText]="'Address 2'" [MaxLength]="25" [PlaceHolder]="'Address 2'" (TriggerOnChange)="onAddressChange($event.target.value,'Address2')"
                        [ControlName]="'AddressLine2'" [FormGroupName]="Address" [Title]="'Address2'">
                    </eprx-input>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                    <app-search-zip [FormGroupName]="AddressForm" [ControlName]="'ZipCode'"
                    [zipExtnCntrl]="'Extension'" [InputErrors]="ZipCode?.errors" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid"
                        (OnZipSelected)="clinicZipSelected($event)"></app-search-zip>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                    <app-search-state [FormGroupName]="AddressForm" [ControlName]="'StateId'"
                        [CityControlName]="'CityId'" [StateSelected]="stateSelected" [InputErrors]="StateId?.errors"
                        [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [IsRequired]="true"
                        (OnStateSelected)="clinicStateValue($event)"></app-search-state>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ">
                    <app-search-city [FormGroupName]="AddressForm" [ControlName]="'CityId'"
                        [StateControlName]="'StateId'" [zipControl]="'ZipCode'" [CitySelected]="citySelected"
                        [ErrorDefs]="{ required: 'Required' }" [FormGroupInvalid]="formGroupInvalid" [IsRequired]="true"
                        (OnCitySelected)="optedCityValue($event)"></app-search-city>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <eprx-input [LabelText]="'Fax#'" [PlaceHolder]="'Fax#'" [Title]="'Fax#'"
                        [ControlName]="'Fax'" [FormGroupName]="Address"
                        [MaskPattern]="'(000)000-0000'" [ShowMask]="true">
                    </eprx-input>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <eprx-input [LabelText]="'Telephone#'" [PlaceHolder]="'Telephone#'" [Title]="'Telephone#'" [ControlName]="'Telephone'"
                    [FormGroupName]="Address" [InputErrors]="Telephone?.errors" [ShowMask]="true"
                        [ErrorDefs]="{
                            pattern: 'please enter 10 digits'
                        }" [MaskPattern]="'(000)000-0000'">
                    </eprx-input>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="saveClinicAdd()" *ngIf="!editMode" appShortcutKey
            [AltKey]="'s'">
            <span>S</span> SAVE
        </button>
        <button type="button" class="hotkey_success" (click)="updateClinicAdd()" *ngIf="editMode" appShortcutKey
            [AltKey]="'u'">
            <span>U</span> Update
        </button>
        <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'">
            <span>C</span> CANCEL
        </button>
    </div>
</ng-template>

<ng-template #exchangeInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <!-- <label>Do you want to change prescriber address?
                        </label> -->
                        Do you want to change the Prescriber's address?<br/><br/>

                        <div class="eprx--block__content">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6">
                                        <!-- <label> FROM: </label> -->
                                        <b>FROM:</b>
                                        <div class="label-data">
                                            {{ prescriberAddrs?.AddressLine1 | uppercase }}
                                        </div>
                                        <!-- <div class="label-data">
                                            {{ prescriberAddrs?.Address?.AddressLine2 | uppercase }}
                                        </div> -->
                                        <div class="label-data">
                                            {{ prescriberAddrs?.CityName | uppercase }}
                                        </div>
                                        <div class="label-data">
                                            {{ prescriberAddrs?.StateName | uppercase }}
                                        </div>
                                        <div class="label-data">
                                            {{ prescriberAddrs?.ZipCode }}
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <!-- <label> TO: </label> -->
                                        <b>TO: </b>
                                        <div class="label-data">
                                            {{ currentAddress?.Address1 | uppercase }}
                                        </div>
                                        <!-- <div class="label-data">
                                            {{ currentAddress?.Address2 | uppercase }}
                                        </div> -->
                                        <div class="label-data">
                                            {{ currentAddress?.City | uppercase }}
                                        </div>
                                        <div class="label-data">
                                            {{ currentAddress?.State | uppercase }}
                                        </div>
                                        <div class="label-data">
                                            {{ currentAddress?.ZipCode }}
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" appShortcutKey (click)="c('Close click')"
            (click)="setPrimaryClinicAddress()" InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" class="hotkey_primary" appShortcutKey InputKey="n" (click)="c('Close click')">
            <b>N</b> NO
        </button>
    </div>
</ng-template>
<ng-template #delete let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure, you want to delete?
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" appShortcutKey (click)="deleteClinicalAdd()"
                    (click)="d('Cross click')" InputKey="y"><b>Y</b> YES </button>
                <button class="hotkey_primary" appShortcutKey InputKey="n" (click)="d('Cross click')">
                    <b>N</b> NO </button>
            </div>
        </div>
    </div>
</ng-template>
