<div class="eprx--block__content presc-Sml" *ngIf="!IsFromErxRequest">
    <div class="row">
        <div class="col-8 sml-box semi-sml">
            <label> Drug Name </label>
            <div class="label--data">
                {{(drugInfo && drugInfo?.drugname) ? drugInfo?.drugname + " " : " "}}&nbsp;{{drugInfo?.strength}}&nbsp;{{drugInfo?.drugformname}}
            </div>
        </div>
        
        <div class="col-2 position-relative" [ngClass]="!Is340BStatus ? 'mr-3' : ''" *ngIf="!isFromErx && drugInfo">
            <app-common-dscsa-info [DrugSlctd]="drugInfo" [IsDrugFormBarcode]="DrugFormBarcode" [FromScreen]="'Captured from Rx Entry'" [PrescrefillId]="PrescrefillId" [RxType]="RxType" [Onchange]="DrugFormBarcode ? true : false"></app-common-dscsa-info>
        </div>
        <div class="col-2 position-relative" *ngIf="Is340BStatus"> 
            <span class="position-340">
                340B 
            </span> 
        </div>
        <div class="col-12" >
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="row">
                        <div class="col-lg-9 pr-0" >
                            <label class="pr-2"> Drug NDC </label> 
                            <app-common-dscsa-info  *ngIf="drugInfo && isFromErx"   [isFromErx]="isFromErx" [DrugSlctd]="drugInfo" [IsDrugFormBarcode]="DrugFormBarcode" [FromScreen]="'Captured from eRx Screen'" [PrescrefillId]="PrescrefillId" [RxType]="'erx'" [Onchange]="DrugFormBarcode ? true : false"></app-common-dscsa-info>
                            <div class="label--data" *ngIf="drugInfo?.drugcode">{{this._formatsUtil.getNDCFormat(drugInfo?.drugcode)}}</div>
                        </div>                   
                        
                        </div> 
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                    <label> Size </label>
                    <div class="label--data"> {{drugInfo?.qtypack? drugInfo?.qtypack.toFixed(3) : "0.000"}} </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 sml-box semi-sml">
                    <label> AWP </label>
                    <div class="label--data"> {{(drugInfo?.unitpriceawp ? (drugInfo?.unitpriceawp | currency: "$":"symbol":"1.3-3") : "$0.000") + " ("+ (drugInfo?.awppack ? (drugInfo?.awppack | currency: "$":"symbol":"1.2-2") : "$0.00") +")"}} </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 sml-box semi-sml">
                    <label> Cost </label>
                    <div class="label--data"> {{(drugInfo?.unitpricecost ? (drugInfo?.unitpricecost | currency: "$":"symbol":"1.3-3") : "$0.000")  + " ("+ (drugInfo?.costpack ? (drugInfo?.costpack | currency: "$":"symbol":"1.2-2") : "$0.00") +")"}} </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <label> Manufacturer </label>
                    <div class="label--data"> {{drugInfo?.manufacturername? drugInfo?.manufacturername : "--"}} </div>
                </div>
                <div class="col-2">
                        <label>  Class </label>
                        <div class="label--data"> {{drugInfo?.drugclass? drugInfo?.drugclass : "0"}} </div>
                </div>
                <div class="col-2 col-md-2 col-sm-6 col-xs-12" >
                    <label> Brand </label>
                    <div class="label--data"> {{drugInfo?.isbranded ? "Y" : "N"}} </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
             
                   <!--   <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12">
                    <label>Price Update </label>
                    <div class="label--data">--</div>
                </div>
                  <div class="ellpise col-lg-4 col-md-12 col-sm-12 col-xs-12 sml-box semi-sml">
                    <label>Remarks</label><br>
                    <span  triggers="mouseover:mouseleave" tooltipClass="search-tooltip-patient" container="body" 
                    [ngbTooltip]="drugInfo?.Drug?.Remarks? drugInfo?.Drug?.Remarks: drugInfo?.notes ? drugInfo?.notes:
                    '--'"> {{drugInfo?.Drug?.Remarks? drugInfo?.Drug?.Remarks: drugInfo?.notes ? drugInfo?.notes:
                        "--"}} </span>
                </div> -->
            </div>
        </div>
       
    </div>
</div>

<div class="eprx--block__content presc-Sml" *ngIf="IsFromErxRequest" [ngClass]="IsFromErxRequest ? 'height_7rem_drug' : ''">
    <div class="row width_100per">

        <div class="col-3 sml-box semi-sml">
            <label> AWP </label>
            <div class="label--data"> {{(drugInfo?.drugcode ? ((drugInfo?.unitpriceawp ? (drugInfo?.unitpriceawp | currency: "$":"symbol":"1.3-3") : "$0.000") + " ("+ (drugInfo?.awppack ? (drugInfo?.awppack | currency: "$":"symbol":"1.2-2") : "$0.00") +")") : "--")}} </div>
        </div>
        <div class="col-3">
            <label> Size </label>
            <div class="label--data"> {{drugInfo?.drugcode ? (drugInfo?.qtypack? drugInfo?.qtypack.toFixed(3) : "0.000") : "--"}} </div>
        </div>

        <div class="col-3 ">
            <label> Manufacture </label>
            <div class="label--data"> {{drugInfo?.manufacturername? drugInfo?.manufacturername : "--"}} </div>
        </div>

        <div class="col-3">
            <label> Brand </label>
            <div class="label--data"> {{drugInfo?.drugcode ? (drugInfo?.isbranded ? "Y" : "N") : "--"}} </div>
        </div>
        <!--  <div class="col-3">
            <label>Price Update </label>
            <div class="label--data">--</div>
        </div>
       <div class="col-3 sml-box semi-sml ellpise">
            <label>Remarks</label> <br>
            <span  tooltipClass="search-tooltip-patient" container="body"  triggers="mouseover:mouseleave"
            [ngbTooltip]="(drugInfoFromEditDrug?.Drug?.Remarks) ? drugInfoFromEditDrug?.Drug?.Remarks : ''"> {{drugInfoFromEditDrug?.Drug?.Remarks? drugInfoFromEditDrug?.Drug?.Remarks:
                "--"}} </span>
        </div> -->

        <div class="col-3">
                <label>  Class </label>
                <div class="label--data"> {{drugInfo?.drugclass || drugInfo?.drugclass == 0 ? drugInfo?.drugclass : "--"}} </div>
        </div>


        <div class="col-3 sml-box semi-sml">
            <label> Cost </label>
            <div class="label--data"> {{(drugInfo?.drugcode ? (drugInfo?.unitpricecost ? (drugInfo?.unitpricecost | currency: "$":"symbol":"1.3-3") : "$0.000")  + " ("+ (drugInfo?.costpack ? (drugInfo?.costpack | currency: "$":"symbol":"1.2-2") : "$0.00") +")" : "--")}} </div>
        </div>


    </div>
</div>
