<div class="row exprx--common-block overflow_y_scroll">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15">
        <div class="box-default ins-box-popup">
            <div class="eprx--block__header">
                <div class="eprx--header__heading">
                    Pricing Information Setting
                </div>
            </div>
            <div class="eprx--block__content ins-body-popup">
                <div class="row">
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="col-sm-12 padding-0">
                            <div class="row insurance--group--icon">
                                <ul class="settings--icons">
                                    <span>
                                        <i class="far fa-usd-circle"></i>
                                    </span>
                                </ul>
                                <span class="settings-label">Pricing</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="col-sm-12 padding-0">
                            <div class="row insurance--group--icon">
                                <ul class="settings--icons">
                                    <span>
                                        <i class="far fa-badge-percent"></i>
                                    </span>
                                </ul>
                                <span class="settings-label">Discount</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="col-sm-12 padding-0">
                            <div class="row insurance--group--icon">
                                <ul class="settings--icons">
                                    <span>
                                        <i class="far fa-money-bill-alt"></i>
                                    </span>
                                </ul>
                                <span class="settings-label">Tax</span>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="col-sm-12 padding-0">
                            <div class="row insurance--group--icon">
                                <ul class="settings--icons">
                                    <span>
                                        <i class="far fa-cog"></i>
                                    </span>
                                </ul>
                                <span class="settings-label">Other</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-select #BasCost [RxSelectId]="'BasCost'" [LabelText]="'Basis of Cost'"
                                    [PlaceHolder]="'Cost Basis'" [ControlName]="'CostBasisId'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')" [IsFirstElem]="true"
                                    [HasMultiple]="false" [Title]="'Basis of Cost'" [HasBindLabel2]="true"
                                    [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Id'"
                                    [List]="dropDownData ? dropDownData?.CostBasis : ''" [Show2Values]="true">
                                </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-select [LabelText]="'Price Code - Brand'" [PlaceHolder]="'Code-Brand'"
                                    [ControlName]="'BrandPriceSchId'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [HasMultiple]="false" [Title]="'Price Code - Brand'" [HasBindLabel2]="true"
                                    [BindLabel]="'PriceCode'" [BindLabel2]="'Name'"
                                    [BindValue]="'Id'" [LabelForId]="'Id'" [List]="(priceSchedule$ | async)?.['PriceScheduleList']" [Show2Values]="true">
                                </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-select [LabelText]="'Price Code - Generic'" [PlaceHolder]="'Code-Generic'"
                                    [ControlName]="'GenPriceSchId'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [HasMultiple]="false" [Title]="'Price Code-Gen'" [HasBindLabel2]="true"
                                    [BindLabel]="'PriceCode'" [BindLabel2]="'Name'"
                                    [BindValue]="'Id'" [LabelForId]="'Id'" [List]="(priceSchedule$ | async)?.['PriceScheduleList']" [Show2Values]="true">
                                </eprx-select>
                            </div>
                            <div class="col-md-12 messsage-height">
                                <label class="col-md-12 padding-0"> Reprice Refill</label>
                                <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsRefillRepriced1', 'IsRefillRepriced2']"
                                    [LabelForId]="" [ControlName]="'IsRefillRepriced'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanOther')">
                                </eprx-radio>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-select [LabelText]="'Copay Type'" [PlaceHolder]="'Copay Type'"
                                    [ControlName]="'CoPayCatId'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [HasMultiple]="false" [Title]="'Copay Type'" [HasBindLabel2]="true"
                                    [BindLabel]="'Name'" [BindLabel2]="'Remarks'" [ShowLabel2]="true" [BindValue]="'Id'"
                                    [LabelForId]="'Id'" [List]="dropDownData ? dropDownData?.CoPayCat : ''" [Show2Values]="true">
                                </eprx-select>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Copay Brand'" [PlaceHolder]="'Copay Brand'"
                                    [ControlName]="'CoPayBrandAmnt'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [MaskPattern]="'099.00'" [DropSpclChar]="false">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Copay Generic'" [PlaceHolder]="'Copay Generic'"
                                    [ControlName]="'CoPayGenAmnt'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [MaskPattern]="'099.00'" [DropSpclChar]="false">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-input [LabelText]="'% Off Brand'" [PlaceHolder]="'Brand Price'"
                                    [ControlName]="'BrandPricePer'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [MaskPattern]="'09.00'" [DropSpclChar]="false">
                                </eprx-input>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-input [LabelText]="'% Off Generic'" [PlaceHolder]="'Generic Price'"
                                    [ControlName]="'GenPricePer'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [MaskPattern]="'09.00'" [DropSpclChar]="false">
                                </eprx-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Flat Sales Tax Amount'" [PlaceHolder]="'Flat Sales Tax Amount'"
                                    [ControlName]="'FlatSalesTaxAmt'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [MaskPattern]="'099999.00'" [DropSpclChar]="false" (TriggerSearchValue)="setNumberFormat('InsuPlanPrice', 'FlatSalesTaxAmt')">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-input [LabelText]="'Tax Rate %'" [PlaceHolder]="'Tax Rate'"
                                    [ControlName]="'TaxRatePer'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [MaskPattern]="'09.00'" [DropSpclChar]="false">
                                </eprx-input>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <eprx-select [LabelText]="'Sales Tax Basis'" [PlaceHolder]="'Sales Tax Basis'"
                                    [ControlName]="'SalesTaxBasisId'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [HasMultiple]="false" [Title]="'Sales Tax Basis'" [HasBindLabel2]="true"
                                    [BindLabel]="'Remarks'" [ShowLabel2]="true" [BindLabel2]="'Name'" [BindValue]="'Id'"
                                    [LabelForId]="'Id'" [List]="dropDownData ? dropDownData?.SalesTaxBasis : ''" [Show2Values]="true">
                                </eprx-select>
                            </div>
                            <div class="col-md-6 messsage-height">
                                <label class="col-md-12 padding-0"> Tax on Rx Item</label>
                                <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsTaxOnRx5', 'IsTaxOnRx6']"
                                    [LabelForId]="" [ControlName]="'IsTaxOnRx'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')">
                                </eprx-radio>
                            </div>
                            <div class="col-md-6 messsage-height">
                                <label class="col-md-12 padding-0"> Tax On OTC Item</label>
                                <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsTaxOnOTC9', 'IsTaxOnOTC10']"
                                    [LabelForId]="" [ControlName]="'IsTaxOnOTC'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')">
                                </eprx-radio>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <div class="row">
                            <div class="col-md-12 messsage-height">
                                <label class="col-md-12 padding-0">U and C Same? </label>
                                <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsUNCSame3', 'IsUNCSame4']"
                                    [LabelForId]="" [ControlName]="'IsUNCSame'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')">
                                </eprx-radio>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <eprx-input [LabelText]="'Prof. Fee'" [PlaceHolder]="'Professional Fee'"
                                    [ControlName]="'ProFee'" [FormGroupName]="insuStngFG.get('InsuPlanPrice')"
                                    [MaskPattern]="'099.00'" [DropSpclChar]="false">
                                </eprx-input>
                            </div>
                            <div class="col-md-6 messsage-height">
                                <label class="col-md-12 padding-0"> Add Prof. Fee to OTC?</label>
                                <eprx-radio [IsDisabled]= "!insuStngFG?.get('InsuPlanPrice').value['IsUNCSame']" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['IsPFForOTC7', 'IsPFForOTC8']"
                                    [LabelForId]="" [ControlName]="'IsPFForOTC'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')">
                                </eprx-radio>
                            </div>
                            <div class="col-md-6 messsage-height">
                                <label class="col-md-12 padding-0"> Allow Zero Billing?</label>
                                <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['IsZeroBillAllowed11', 'IsZeroBillAllowed12']" [LabelForId]="" [ControlName]="'IsZeroBillAllowed'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')">
                                </eprx-radio>
                            </div>
                            <div class="col-md-6 messsage-height">
                                <label class="col-md-12 padding-0"> Allow Zero U&C?</label>
                                <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['IsZeroUNC11', 'IsZeroUNC12']" [LabelForId]="" [ControlName]="'IsZeroUNC'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')">
                                </eprx-radio>
                            </div>
                            <div class="col-md-12 messsage-height">
                                <label class="col-md-12 padding-0" title="Accept Patient Claim Amount Assignment"> Accept Claim Amnt. Asgmt.</label>
                                <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                    [IDForList]="['IsAssigntAccepted13', 'IsAssigntAccepted14']" [LabelForId]="" [ControlName]="'IsAssigntAccepted'"
                                    [FormGroupName]="insuStngFG.get('InsuPlanPrice')">
                                </eprx-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-lg-12 drug--price__info">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding-sm-15 flex--one padding-0">
                <div class="box-default ins-box-popup">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            Other Settings
                        </div>
                    </div>
                    <div class="eprx--block__content ins-body-popup">
                        <div class="row">
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="col-sm-12 padding-0">
                                    <div class="row insurance--group--icon">
                                        <ul class="settings--icons">
                                            <span>
                                                <i class="far fa-user"></i>
                                            </span>
                                        </ul>
                                        <span class="settings-label">Prescriber</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="col-sm-12 padding-0">
                                    <div class="row insurance--group--icon">
                                        <ul class="settings--icons">
                                            <span>
                                                <i class="far fa-file-prescription"></i>
                                            </span>
                                        </ul>
                                        <span class="settings-label">Rx fills</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="col-sm-12 padding-0">
                                    <div class="row insurance--group--icon">
                                        <ul class="settings--icons">
                                            <span>
                                                <i class="far fa-prescription-bottle"></i>
                                            </span>
                                        </ul>
                                        <span class="settings-label">Refills</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="col-sm-12 padding-0">
                                    <div class="row insurance--group--icon">
                                        <ul class="settings--icons">
                                            <span>
                                                <i class="far fa-newspaper"></i>
                                            </span>
                                        </ul>
                                        <span class="settings-label">Miscellaneous</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-select [LabelText]="'Prescriber ID Type'" [PlaceHolder]="'Prescriber Id'"
                                            [ControlName]="'PrescIdCatId'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [HasMultiple]="false"
                                            [Title]="'Prescriber ID Type'" [HasBindLabel2]="true"
                                            [BindLabel]="'PrescIdCatCode'" [ShowLabel2]="true" [BindLabel2]="'Name'"
                                            [BindValue]="'Id'" [LabelForId]="'Id'"
                                            [List]="dropDownData ? dropDownData?.PrescIdCat : ''" [Show2Values]="true">
                                        </eprx-select>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-select [LabelText]="'Primary Care Prescriber ID'"
                                            [PlaceHolder]="'Prime Care Prescriber ID'"
                                            [ControlName]="'PrimePrescIdCatId'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [HasMultiple]="false"
                                            [Title]="'Prime Care Presc Id'" [HasBindLabel2]="true" [ShowLabel2]="true"
                                            [BindLabel]="'PrescIdCatCode'" [BindLabel2]="'Name'" [BindValue]="'Id'"
                                            [LabelForId]="'Id'" [List]="dropDownData ? dropDownData?.PrescIdCat : ''" [Show2Values]="true">
                                        </eprx-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-input [LabelText]="'Max Days Supply ?'" [MaxLength]="3"
                                            [PlaceHolder]="'Max Days'" [ControlName]="'MaxDaysSupply'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [InputType]="'Number'">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-input [LabelText]="'Max Quantity'" [MaxLength]="4" [MaskPattern]="'0999'"
                                            [PlaceHolder]="'Max Quantity*'" [ControlName]="'MaxQty'" [InputType]="'Number'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [DecimalValues]="0"
                                            (TriggerSearchValue)="setNumberFormat('InsuPlanOther', 'MaxQty')" >
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-input [LabelText]="'Order to First Fill Days'" [MaxLength]="3"
                                            [PlaceHolder]="'Order to First Fill Days'" [ControlName]="'OrderToFillDays'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [InputType]="'NUMBER'">
                                        </eprx-input>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-input [LabelText]="'Max Refills'" [PlaceHolder]="'Max Refills'"
                                            [MaxLength]="4" [ControlName]="'MaxRefills'" [InputType]="'NUMBER'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')">
                                        </eprx-input>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-input [LabelText]="'Order To Last Refill'"
                                            [PlaceHolder]="'Order to last refill'" [MaxLength]="3"
                                            [ControlName]="'OrderToLastRefill'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [InputType]="'NUMBER'">
                                        </eprx-input>
                                    </div>
                                  <!---- // bcz of the Partailfill feedback enhancements we commented the code EP-697
                                      <div class="col-md-12 messsage-height">
                                        <label class="col-md-12 padding-0"> Allow Partial Fill?</label>
                                        <eprx-radio [ControlName]="'IsPartialFillAllowed'" [IsDisabled]="isCash"
                                            [FormGroupName]="insuStngFG.get('InsuPlanPrice')" [List]="['Yes', 'No']"
                                            [ValueList]="[true, false]" [IDForList]="['IsFill15', 'IsFill16']" [LabelForId]="">
                                        </eprx-radio>
                                    </div> -->
                                    <!-- // commenting code as it is mentioned to remove in the ticket EP-9862
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <eprx-input [LabelText]="'Refill Due Consumption Threshold (%)'" [PlaceHolder]="'Refill Due Consumption Threshold (%)'"
                                            [MaxLength]="4" [ControlName]="'RefillDueConsumptionThreshold'" [InputType]="'NUMBER'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')">
                                        </eprx-input>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-12">
                                <div class="row">
                                    <div class="col-md-12 messsage-height">
                                        <label class="col-md-12 padding-0"> Sort Order(R,D)</label>
                                        <eprx-radio [ControlName]="'IsSortByRx'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [List]="['Rx#', 'Date']"
                                            [ValueList]="[true, false]" [IDForList]="['IsSortByRx15', 'IsSortByRx16']" [LabelForId]="">
                                        </eprx-radio>
                                    </div>
                                    <div class="col-md-12 messsage-height">
                                        <label class="col-md-12 padding-0">Workman Comp?</label>
                                        <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                            [IDForList]="['IsWorkmanComp17', 'IsWorkmanComp18']" [LabelForId]="" [ControlName]="'IsWorkmanComp'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')">
                                        </eprx-radio>
                                    </div>
                                    <!-- <div class="col-md-12 messsage-height">
                                        <label class="col-md-12 padding-0">Rx Serial# Required?</label>
                                        <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                            [IDForList]="['IsRxSNReqd19', 'IsRxSNReqd20']" [LabelForId]="" [ControlName]="'IsRxSNReqd'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')">
                                        </eprx-radio>
                                    </div> -->
                                    <!-- “EP-4569 : hiding the setting as per Ketan's inputs.” -->
                                    <!-- <div class="col-md-12 messsage-height">
                                        <label class="col-md-12 padding-0">Compound Ingr. Values Can Differ From Claims?</label>
                                        <eprx-radio [List]="['Yes', 'No']" [ValueList]="[true, false]"
                                            [IDForList]="['CompoundIngrcandiffer19', 'CompoundIngrcandiffer20']" [LabelForId]="" [ControlName]="'CompoundIngrcandiffer'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')">
                                        </eprx-radio>
                                    </div> -->
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 padding--left__7 ">
                                        <eprx-select [LabelText]="'Rph ID To Submit'" [PlaceHolder]="'Rph ID To Submit'"
                                            [ControlName]="'RPhIdCatId'"
                                            [FormGroupName]="insuStngFG.get('InsuPlanOther')" [HasMultiple]="false"
                                            [HasBindLabel2]="false" [BindLabel]="'Id'" [BindLabel2]="'Name'" [BindValue]="'Id'" [Show2Values]="true"
                                            [LabelForId]="'Name'" [Title]="'Rph ID To Submit'"
                                            [List]="dropDownData?.PharmacistIdCat" (TriggerOnTabbing)="focusToSave($event)" [DropdownPosition]="'top'">
                                        </eprx-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
