import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DrugBucketInfo, DrugPO, DrugSuggestFilter, ManageInventory } from "../models/drug-bucket.model";
import { BucketService, CommonService, RxService} from "../services";
import { RxStore } from "../store";


@Injectable({
    providedIn: "root"
})

export class DrugBucketUtil {
    is340B: boolean;
    constructor(private _fb: FormBuilder,
        private _buckServ: BucketService,
        private _commServ: CommonService,
        private _rxSvc: RxService,
        private _rxStore: RxStore
        ) {}


    createDrugSuggestFilterFG() {
        return this._fb.group(new DrugSuggestFilter);
    }

    patchDefaultDrugFiltInErx(DrugSuggFilterFG: FormGroup) {
        DrugSuggFilterFG.patchValue(new DrugSuggestFilter);
    }

    patchDefaultDrugSuggFiltFG(DrugSuggFilterFG: FormGroup) {
        DrugSuggFilterFG.patchValue(new DrugSuggestFilter);
        DrugSuggFilterFG.patchValue({
            BucketId: this.getDefaultBucketId(),
            OnlyShowDrugsAvailableForBucket:this.getInventoryStngs("Single_Bucket_Mode")? false : this.getInventoryStngs("Default_Only_Show_Drugs_which_are_added_to_this_Bucket"),

            //OnlyDrugsHavingQtyInStock: this.getInventoryStngs("Default_Show_Drugs_With_Available_Stock_Qty_Only")
        });
    }

    isAdvancedMode() {
        return +this._commServ.getSetttingValue("RxSettings", "Default_Inventory_Mode") === 1;
    }

    createDrugBucketFG() {
        return this._fb.group(new DrugBucketInfo);
    }

    createManageInvFG() {
        return this._fb.group(new ManageInventory);
    }

    createDrugPoFG () {
        return this._fb.group(new DrugPO);
    }

    // tslint:disable-next-line:no-shadowed-variable
    patchDrugBuckPO(DrugPO: FormGroup, DrugBuckInfo: any) {
        DrugPO.patchValue(DrugBuckInfo ? {
            ReorderQty: (+DrugBuckInfo.ReorderQty ? DrugBuckInfo.ReorderQty : 0).toFixed(3),
            TargetQty: (+DrugBuckInfo.TargetQty ? DrugBuckInfo.TargetQty : 0).toFixed(3),
            MinOrderQty: (+DrugBuckInfo.MinOrderQty ? DrugBuckInfo.MinOrderQty : 0).toFixed(3),
            OrderQty: (+DrugBuckInfo.OrderQty ? DrugBuckInfo.OrderQty : 0).toFixed(3),
            PrefVendId: DrugBuckInfo.PrefVendId,
            ContractId: DrugBuckInfo.ContractId
        } : {
            ReorderQty: "0.000",
            TargetQty: "0.000",
            MinOrderQty: "0.000",
            OrderQty: "0.000",
            PrefVendId: null
        });
        // IsAutoPO: null,
    }


    patchDrugPOInfo(DrugPo: FormGroup, DrugBucket: DrugBucketInfo) {
        DrugPo.patchValue({
            BucketId: DrugBucket.Id,
            // IsAutoPO: null,
            ReorderQty: 0,
            TargetQty: 0,
            MinOrderQty: 0,
            OrderQty: 0,
            // PrefVendId: null
        });
    }
    getDefBucketId(val?: boolean) {
        const data = val ? this.getDefault340BBucketId() : this.getDefaultBucketId();
        return data;
    }

    getDefaultBucketId() {
        return +this._commServ.getSetttingValue("RxSettings", "DEF_GENERAL_BUCKET");
    }

    getDefault340BBucketId() {
        return +this._commServ.getSetttingValue("RxSettings", "DEF_340B_BUCKET");
    }

    patchDrugBucketPricing(DrugFG: FormGroup, bucketList: DrugBucketInfo[], buckID?: number, isFromAddDrug?: boolean) {
        if (bucketList && bucketList.length) {
            const defBuck = buckID || this.getDefaultBucketId();
            const bucket: DrugBucketInfo  = defBuck ? bucketList.find(list => list.Id === +defBuck) : bucketList[0];
            if (bucket) {
                if (isFromAddDrug) {
                    return true;
                }
                DrugFG.controls["Drug"].patchValue({
                    CostPack: bucket.CostPack ? bucket.CostPack.toFixed(2) : "0.000",
                    UnitPriceCost3Digits: bucket.UnitPriceCost ? bucket.UnitPriceCost.toFixed(4) : "0.0000",
                    UnitPriceCost: bucket.UnitPriceCost ? bucket.UnitPriceCost.toFixed(4) : "0.0000"
                });
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }

    }

    patchManageInvInfo(ManageInv: FormGroup, DrugBucket: DrugBucketInfo) {
        ManageInv.patchValue({
            BucketId: DrugBucket.Id,
            IsManaged: DrugBucket.IsInvManaged,
            CostPack: (DrugBucket.CostPack ? DrugBucket.CostPack : 0).toFixed(2),
            UnitPriceCost: (DrugBucket.UnitPriceCost ? DrugBucket.UnitPriceCost : 0).toFixed(3),
            IsActive: DrugBucket.IsActive
        });
    }

    // tslint:disable-next-line:no-shadowed-variable
    patchDrugInvINFO(drugId: number, BucketInfo: any, DrugPO: any, DrugInfo?: any, drugInfoFrmRx = null): any {
        const payload = {
            DrugPO: {
                Id: null,
                TenantId: null,
                DrugId: drugId,
                IsAutoPO: DrugPO.IsAutoPO,
                BucketId: BucketInfo.Id,
                ReorderQty: DrugPO.ReorderQty,
                TargetQty: DrugPO.TargetQty,
                MinOrderQty: DrugPO.MinOrderQty,
                OrderQty: DrugPO.OrderQty,
                PrefVendId: DrugPO.PrefVendId
            },
            ManageInventory: {
                Id: null,
                TenantId: null,
                DrugId: drugId,
                BucketId: BucketInfo.Id,
                IsManaged: BucketInfo.IsInvManaged,
                CostPack: drugInfoFrmRx ? drugInfoFrmRx.costpack : DrugInfo && DrugInfo.Drug ? DrugInfo.Drug.CostPack : BucketInfo.CostPack,
                UnitPriceCost: drugInfoFrmRx ? drugInfoFrmRx.unitpricecost : DrugInfo && DrugInfo.Drug ?
                DrugInfo.Drug.UnitPriceCost3Digits : BucketInfo.UnitPriceCost,
                IsActive: BucketInfo.IsActive,
                CreatedDtTm: null,
                ModifiedDtTm: null
            }
        };
        return payload;
    }


    patchDrugBucket(DrugBucketFG: FormGroup, DrugBucket: DrugBucketInfo) {
        DrugBucketFG.patchValue(DrugBucket);
    }

    async getDrugBucketList(drugId: number) {
        const resp = await this._buckServ.getBucketsForDropdowns(drugId).toPromise();
        if (resp) {
            return resp;
        } else {
            return [];
        }
    }

    getInvStatus(bucketList: DrugBucketInfo[], buckID: number) {
        if (bucketList && bucketList.length) {
            const bucket: DrugBucketInfo = bucketList.find(list => list.Id === buckID) || null;
            return bucket.IsInvManaged;
        }
    }

    checkIsInvManaged (ManageInv: ManageInventory): boolean { // in add drug to bucket popup
        return ManageInv.IsManaged;
    }

    checkIsDrugBuckPriceExist (DrugBuckInfo: DrugBucketInfo): boolean {
        return DrugBuckInfo.IsPriceExist;
    }

    checkIsPriceExistOrNot(drugInfo, bucketId = null): boolean {
        if (drugInfo && drugInfo.manageinventory && drugInfo.manageinventory.length) {
            const isBuckets = drugInfo.manageinventory.filter(buck => buck.BucketId === bucketId) || [];
            return isBuckets && isBuckets.length ? true : false;
        }
        return false;
    }

    patchDrugBucketInfo(bucketList: DrugBucketInfo[], DrugBucketFG: FormGroup, buckID?: number): boolean {
        if (bucketList && bucketList.length) {
            const defBuck = buckID || this.getDefaultBucketId();
            const bucket: DrugBucketInfo  = defBuck ? bucketList.find(list => list.Id === +defBuck) : bucketList[0];
            if (bucket) {
                DrugBucketFG.patchValue(bucket);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    patchSaveDrugBucketModal(DrugBuckInfo: DrugBucketInfo, DrugFG: any) {
        if (DrugFG) {
            DrugFG.patchValue({
                DrugBucket: DrugBuckInfo
            });
            DrugFG.updateValueAndValidity();
        }
    }

    checkIsDefaultBucket(bucketList, buckId): boolean {
        if (bucketList && bucketList.length) {
            const bucket = bucketList.find(buck => buck.Id === buckId) || null;
            return bucket ? bucket.IsDefault : false;
        }
        return false;
    }

    checkIs340BBucketOrNot(bucketList, buckId): boolean {
        if (bucketList && bucketList.length) {
            const bucket = bucketList.find(buck => buck.Id === buckId) || null;
            return bucket ? bucket.ContextTypeId !== 1 : false; // 1- gen, 2 - 340b
        }
        return false;
    }


    formatBucketList(bucketsListResp?: any[]): any[] {
        const bucketsList = bucketsListResp && bucketsListResp.length ? bucketsListResp.map(buck => Object.assign({}, buck)) : [];
        if (bucketsList && bucketsList.length) {
            return bucketsList.map(bucket => {
                bucket.ContextTypeId = bucket.ContextTypeId === 1 ? "General Bucket" : "340B Bucket";
                return bucket;
            });
        }
        return [];
    }

    filterDrugBucketListWithBuckId(bucketList: any[], BucketId: number) {
        if (bucketList && bucketList.length && BucketId) {
            return bucketList.filter(buck => buck.Id === BucketId) || null;
        }
        return null;
    }

    getInvSetForPatPrescDrg(settingkey: string) { // get Inventory 340B Setting Based on Key.
        return +this._commServ.getSetttingValue("ThreeFourtyBSettings", settingkey) === 1;
    }

    getInventoryStngs(settingkey: string) {
        return +this._commServ.getSetttingValue("InventorySettings", settingkey) === 1;
    }

    get340BSettingInfo(settingsFor: string) {
        const SettingsFor = ["PATIENT", "PRESCRIBER", "DRUG", "AUTOMARK340B"];
        const is340B =  this._commServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        if (!settingsFor || settingsFor === "") {
            return false;
        }
        if (settingsFor && SettingsFor[0] === settingsFor.toUpperCase()) { // setting related to Patient.
            if (this.getInvSetForPatPrescDrg("Require_Patient_as_340B") && (!this.getInvSetForPatPrescDrg("Require_Prescriber_as_340B") &&
                !this.getInvSetForPatPrescDrg("Require_Drug_as_340B")) && is340B ) {
                    return true; // open popup after patient select.
            }

        } else if (settingsFor && SettingsFor[1] === settingsFor.toUpperCase()) { // setting related to Prescriber
            if (this.getInvSetForPatPrescDrg("Require_Prescriber_as_340B") && (!this.getInvSetForPatPrescDrg("Require_Drug_as_340B")) && is340B ) {
                    return true; // open popup after Prescriber select.
            }

        } else if (settingsFor && SettingsFor[2] === settingsFor.toUpperCase()) { // setting related to Drug
            if (this.getInvSetForPatPrescDrg("Require_Drug_as_340B") && is340B) {
                return true; // open popup after Prescriber select.
            }
        } else if (settingsFor && SettingsFor[3] === settingsFor.toUpperCase()) { // setting related to Drug
            if (this.getInvSetForPatPrescDrg("Automatically_mark_340B_if_Prescriber_and_Ins_are_340B") && is340B) {
                return true; // open popup after Prescriber select.
            }
        }
        return false;
    }

    checkPatPrescDrug340BOrNot(settingsFor) {
        const SettingsFor = ["PRESCRIBER", "DRUG"];
        const is340B =  this._commServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        // 1-pat, 2-pres , 12-pat&presc, 0- not pat & not presc.
        if (SettingsFor && settingsFor.toUpperCase() === SettingsFor[0]) {
            if (this.getInvSetForPatPrescDrg("Require_Patient_as_340B") && is340B) {
                return 1;
            }
        } else if (SettingsFor && settingsFor.toUpperCase() === SettingsFor[1]) {
            if (this.getInvSetForPatPrescDrg("Require_Patient_as_340B")) {
                if (this.getInvSetForPatPrescDrg("Require_Prescriber_as_340B") && is340B) {
                    return 12;
                } else {
                    return 1;
                }
            } else if (this.getInvSetForPatPrescDrg("Require_Prescriber_as_340B") && is340B) {
                return 2;
            }
        }
        return 0;
    }

    async checkPatNPres340b(checkFor: number, PATIENT: FormGroup, PRESCRIBER: FormGroup, settingsFor: string) {
        const SettingsFor = ["PRESCRIBER", "DRUG"];
        let pat340b = false;
        let presc340b:any;
        if (settingsFor && settingsFor.toUpperCase() === SettingsFor[1] && !(PATIENT && PRESCRIBER)) {
            return false;
        }
        if (settingsFor && settingsFor.toUpperCase() === SettingsFor[0] && !PATIENT) {
            return false;
        }
        pat340b = (PATIENT && PATIENT.value  && PATIENT.value.Is340B ) ? PATIENT.value.Is340B : false;
        presc340b = PRESCRIBER ? await this.checkPrescriber340OrNot(PRESCRIBER) : false;

        if (checkFor === 1) {
            return pat340b;
        } else if (checkFor === 2) {
            return presc340b;
        } else if (checkFor === 12) {
            return pat340b && presc340b;
        } else { // checkFor -0
            return true;
        }
    }

    setRxAs340bRx(rxFG: FormGroup, set340B: boolean = true) { // sets rx as 340b rx.
        if (rxFG && rxFG.get("PrescReFill")) { // patch 340b true in rxFG.
            rxFG.get("PrescReFill").patchValue({
                Is340B: set340B
            });
        }
    }

   async checkPrescriber340OrNot(PRESCRIBER: FormGroup) {
        let facValues: any;
        const resp1 = !this._commServ.faciltityDropdownList$["source"]["value"]?.length ? await  this._commServ.getCommonDropDownValues({IsFacility : true , IsPatientCategory: false , IsPayor: false , IsInsuranceList: false}, true).toPromise(): this._commServ.faciltityDropdownList$["source"]["value"]
        facValues =  this._commServ.getSetttingValue("ThreeFourtyBSettings", "Facility_340BSettings")  
         // if prescriber facility is same facilty in 340b settings then the prescriber is 340b prescriber. 
        if (PRESCRIBER && !PRESCRIBER?.value?.facilityId) {
            return false;
        }
        const facResp = !this._commServ.faciltityDropdownList$["source"]["value"]?.length ? (resp1 ? resp1["Facilities"] : []) : resp1;
        // It will be true if there is a match, otherwise false    
        if (facResp?.length && PRESCRIBER?.value?.facilityId?.length) {
            return facResp.some(item => facValues?.includes(item.Code) && PRESCRIBER.value.facilityId.includes(item.Id));
        }
    }

    checkInsurance340BOrNot(PATIENT: FormGroup, rxFG: FormGroup): boolean { // checks whether rx insurance is 340B insurance or not.
        const insuCarrierId = +this._commServ.getSetttingValue("ThreeFourtyBSettings", "Ins_Code_340BSettings");
        const insuId = +rxFG.get("RefillInsu").value.InsuId;
        if (insuId && insuCarrierId) {
            const Insu340B = PATIENT.value.insurance.find(ins => +ins.insuranceid === insuId) || null;
            return Insu340B && +Insu340B.insurerid ? +Insu340B.insurerid === insuCarrierId : false;
        }
        return false;
    }

    checkPatSlctdExcludedIns(PATIENT: FormGroup, rxFG: FormGroup) { // logic for patient selected excluded insu from 340b settings.
        const is340B =  this._commServ.getSetttingValue("ThreeFourtyBSettings", "340B_PHARM") === "1";
        const isEnabled  = +this._commServ.getSetttingValue("ThreeFourtyBSettings",
            "Auto_Exclude_from_340B_for_following_Insurances");
        if (isEnabled === 1 && PATIENT && PATIENT.value.insurance && PATIENT.value.insurance.length && is340B) {
            const insurance = PATIENT.value.insurance.find(ins => +ins.insuranceid === +rxFG.get("RefillInsu").value.InsuId) || null;
            return insurance ? this.check340BHasExcludedIns(insurance.insurerid) : false;
        }
        return false;
    }

    check340BHasExcludedIns(insId: number): boolean { // true - has exclude ins, false -don't have excluded ins.
        let insList = this._commServ.getSetttingValue("ThreeFourtyBSettings", "Excld_Ins_List");
        const InsuranceList = this._commServ.insuranceData$["source"]["value"];
        if (insId && insList && insList !== "" && insList.length && InsuranceList && InsuranceList.length) {
            insList = insList.split(",");
            const refillIns = InsuranceList.find(ins => ins.InsuCarrierId === insId) || null;
            const excludeIns = insList.filter(inscode => inscode.trim().toUpperCase() ===
                refillIns["PlanCode"].trim().toUpperCase()) || null; // PlanCode
            return excludeIns && excludeIns.length ? true : false;
        }
        return false;
    }

    async getDrugBucketListInRxDropDown(DrugId: number) {
        if (this._commServ.bucketList$["source"]["value"] && this._commServ.bucketList$["source"]["value"].length) {
            const bucketList = this._commServ.bucketList$["source"]["value"].map(val => Object.assign(val, {Qty: 0}));
            if (DrugId) {
                const list = await this._rxSvc.getQtySpcToDrugBucket(DrugId).toPromise();
                if (list && list.length > 0 && bucketList && bucketList.length > 0) {
                    list.map(val => {
                        const index = bucketList.findIndex(buck => buck["Id"] === val["BucketId"]);
                        if (index !== null && index > -1) {
                            bucketList[index]["Qty"] = val["BucketQty"];
                            bucketList[index]["BucketContextType"] = val["BucketContextType"];
                        }
                    });
                }
                this._rxStore.storeRxBuckList(bucketList);
            } else {
                this._rxStore.storeRxBuckList([]);
            }
        } else {
            this._rxStore.storeRxBuckList([]);
        }
    }

    getQtyInHand(manageInventory: any, bucketId?: any): any {
        let buckId = this.getDefaultBucketId();
        if(bucketId) {
            buckId = bucketId;
        }
        if (manageInventory && manageInventory.length) {
            const isExist = manageInventory.find(buck => buck.BucketId === +buckId ) || null;
            return isExist ? isExist["QtyInHand"].toFixed(3) : "0.000";
        } else {
            return "0.000";
        }
    }

}
