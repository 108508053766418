import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Subject } from "rxjs-compat/Subject";
import { share } from "rxjs/operators";
import {
    RxBillingCalculationInput,
    RxBillingPriceModel
} from "src/app/models/rx-billing-calculation.model";
import { constant } from "../app.constants";
import { DME, DosageSchedule, PatientHistory, RxInputParams, RxModel, WCOM } from "../models";
import { RxStore } from "../store/rx.store";
import { DUR } from "./../models/rx-popup.model";
import { AlertService } from "./alert.service";
import { CommonService } from "./common.service";

const reqOptns = {
    headers: new HttpHeaders().set("isLoadNotReq", "true")
}
@Injectable({
    providedIn: "root"
})
export class RxService {
    // defaultSettings$: Observable<any>;
    // _defaultSettings$: BehaviorSubject<any>;

    pickupRx$: Observable<any>;
    _pickupRx$: BehaviorSubject<any>;

    _followUpList$: BehaviorSubject<any> = new BehaviorSubject(null) as BehaviorSubject<any>;
    followUpList$: Observable<any> = this._followUpList$.asObservable();

    RXInfoBS$: Observable<any>;
    _RXInfoBS$: BehaviorSubject<any>;

    resetPat$ = new Subject<any>();
    setPatient(patLang) {
        this.resetPat$.next(patLang)
    }
    getPatient() {
        return this.resetPat$.asObservable();
    }

    constructor(private _http: HttpClient, private _alertServ: AlertService, private _commonSvc: CommonService,
        private _rxStore: RxStore) {
        // this._defaultSettings$ = <BehaviorSubject<any>>(
        //     new BehaviorSubject(null)
        // );
        // this.defaultSettings$ = this._defaultSettings$.asObservable();
        this._pickupRx$ = new BehaviorSubject([]) as BehaviorSubject<any>;
        this.pickupRx$ = this._pickupRx$.asObservable();

        this._RXInfoBS$ = new BehaviorSubject(null) as BehaviorSubject<any>;
        this.RXInfoBS$ = this._RXInfoBS$.asObservable();
    }

    savePickupRxInfo(data: any) {
        this._pickupRx$.next(data);
    }

    saveRxInfo(rxInfo: RxModel): Observable<number> {
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Saving Rx")
        };
        rxInfo.DrugPickOffsetAlgorithm = this._commonSvc.getSetttingValue("InventorySettings", "Inventory_Calculation_Based_On_DPV");
        return this._http.post<number>(constant.POST_Rx, rxInfo, loaderOptns);
    }

    saveRxInfoandStore(rxInfo: RxModel): any {
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Saving Rx")
        };
        rxInfo.DrugPickOffsetAlgorithm = this._commonSvc.getSetttingValue("InventorySettings", "Inventory_Calculation_Based_On_DPV");
        return this._http.post<number>(constant.POST_Rx, rxInfo, loaderOptns)
            .subscribe(resp => this._RXInfoBS$.next(resp));
    }

    updateRxInfo(rxInfo: RxModel): Observable<any> {
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Updating Rx Information")
        };
        rxInfo.DrugPickOffsetAlgorithm = this._commonSvc.getSetttingValue("InventorySettings", "Inventory_Calculation_Based_On_DPV");
        return this._http.put<any>(constant.PUT_Rx, rxInfo, loaderOptns);
    }
    updateRxInfoWithDrugChange(rxInfo: RxModel): Observable<any>{
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Updating Rx Information")
        };
        rxInfo.DrugPickOffsetAlgorithm = this._commonSvc.getSetttingValue("InventorySettings", "Inventory_Calculation_Based_On_DPV");
        return this._http.post<any>(constant.POST_NewRxWithDrugChange, rxInfo, loaderOptns);
    }

    updateandgetRxInfo(rxInfo: RxModel, errMSG: string): void {
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Updating Rx Information")
        };
        rxInfo.DrugPickOffsetAlgorithm = this._commonSvc.getSetttingValue("InventorySettings", "Inventory_Calculation_Based_On_DPV");
        this._http.put<boolean>(constant.PUT_Rx, rxInfo, loaderOptns)
            .subscribe(resp => {
                if (resp) {
                    this.getandStoreRxDetails([rxInfo.Prescription.PrescNum], rxInfo.PrescReFill.Id);
                } else {
                    this._alertServ.error(errMSG);
                }
            });
    }

    getandStoreRxDetails(PrescNumarray: any[], PrescRefillId: any, isRecall: boolean = false) {
        const rxDetObj = { "PrescNums": PrescNumarray, IsRecall: isRecall, PrescRefillId: PrescRefillId };
        return this._http.post<any>(constant.POST_Rx_GetRxDetails, rxDetObj)
            .subscribe(resp => resp ? this._rxStore.storeRxInfo(resp[0]) : this._rxStore.storeRxInfo(null));
    }

    getDrugWarnings(patientID: number, gender: string, drugID: number) {
        return this._http.get<string>(
            constant.GET_Drug_Warnings.replace("{drugId}", "" + drugID)
                .replace("{gender}", "" + gender)
                .replace("{patientId}", "" + patientID)
        );
    }

    checkDrugAllergies(patientID: number, drugID: number) {
        return this._http.get<string>(
            constant.GET_Drug_CheckAllergies.replace(
                "{drugId}",
                "" + drugID
            ).replace("{patientId}", "" + patientID)
        );
    }

    getDrugDeceaseContradiction(patientID: number, drugID: number) {
        return this._http.get<string>(
            constant.GET_Drug_DeceaseContradiction.replace(
                "{drugId}",
                "" + drugID
            ).replace("{patientId}", "" + patientID)
        );
    }


    getDuplicateDrugTherapy(patientID: number, drugID: number) {
        return this._http.get<string>(
            constant.GET_Drug_DuplicateDrugTherapy.replace(
                "{drugId}",
                "" + drugID
            ).replace("{patientId}", "" + patientID)
        );
    }

    getDosageScheduleInfo(
        dosageScheduleId: number
    ): Observable<DosageSchedule[]> {
        return this._http.get<DosageSchedule[]>(
            constant.GET_Rx_DosageSchedule.replace(
                "{scheduleId}",
                "" + dosageScheduleId
            )
        );
    }

    getHoaDetailsInfo(
        prescRefillIds: any[]
    ): Observable<any>{
        return this._http.post(
            constant.GET_Rx_GetHOADetails, prescRefillIds
        )
    }

    saveHoaDeatils(
        hodDataArr: any[]
    ): Observable<any>{
        return this._http.post(
            constant.POST_Rx_HOADetails, hodDataArr
        )
    }

    getSigCodeList(startIndex: number, paginCount: number): Observable<any> {
        return this._http.get(
            constant.GET_Rx_SigData.replace(
                "{startIndex}",
                "" + startIndex
            ).replace("{noOfDocuments}", "" + paginCount)
        );
    }

    getSigCodeListBySigCodeOrSigDesc(
        sigCodeOrSigDesc: string
    ): Observable<any> {
        return this._http.post(
            constant.POST_Rx_SigCode_Suggest, {
            SigCode: sigCodeOrSigDesc
        }
        );
    }

    getInsuranceList(patId: number): Observable<any> {
        return this._http.get(
            constant.GET_Rx_BillToInsurances.replace("{patientId}", "" + patId)
        ).pipe(
            share());
    }

    getPatientRxHistory(params): Observable<any> {
        return this._http.post<any>(
            constant.POST_Patient_RxHistory,
            params
        ).pipe(
            share());
    }

    getPricingDetails(
        rxBillingCalculationInput: RxBillingCalculationInput
    ): any {
        return this._http.post<RxBillingPriceModel>(
            constant.POST_Rx_CalculatePricing,
            rxBillingCalculationInput
        );
    }

    getUandCDetails(
        rxBillingCalculationInput: RxBillingCalculationInput
    ): Observable<number> {
        return this._http.post<number>(
            constant.POST_Rx_CalculateUandC,
            rxBillingCalculationInput
        );
    }

    getDemoGraphDetails(patientId: number): Observable<any> {
        return this._http.get<PatientHistory[]>(
            constant.GET_Patient_CheckDemoGraph.replace(
                "{patientId}",
                "" + patientId
            )
        );
    }

    checkPrescriberDEANumber(deaNumber: string): Observable<any> {
        return this._http.get(
            constant.GET_Prescriber_DEANumberCheck.replace(
                "{prescDea}",
                "" + deaNumber
            )
        );
    }

    // getDefaultSettings() {
    //     return this._http
    //         .get(constant.GET_Rx_DefaultSettings)
    //         .subscribe(resp => {
    //             this._defaultSettings$.next(resp);
    //         });
    // }

    // getDefaultSettingsWithoutBS() {
    //     return this._http.get(constant.GET_Rx_DefaultSettings);
    // }

    checkIsValidTechician(techName: string) {
        return this._http.post(
            constant.POST_Rx_IsValidTechnician, { TechnicianName: techName }
        );
    }

    checkIsValidPharmacist(pharmName: string) {
        return this._http.post(constant.POST_Rx_IsValidPharmacist, { PharmacistName: pharmName });
    }

    checkTechicianPassword(techPass: string, techName: string): Observable<any> {
        return this._http.post(
            constant.POST_Rx_ValidateTechnicianPassword, { TechnicianUserName: techName, TechnicianPassword: techPass }
        );
    }

    checkRxPrescNumber(prescnum: number) {
        return this._http.get(
            constant.GET_Rx_IsDuplicatePrescNumber.replace(
                "{prescnum}",
                "" + prescnum
            )
        );
    }

    saveDMEdata(dmeData: DME, rxId: number) {
        return this._http.post(
            constant.POST_Rx_DME.replace("{rxId}", "" + rxId),
            dmeData
        );
    }

    getSigCodeListByLangCode(sigCodeInfo: any) {
        return this._http.post(
            constant.POST_Rx_SigCodesInfoByLangCode,
            sigCodeInfo,
            reqOptns
        );
    }

    getDiagnosisList() {
        return this._http.get(constant.GET_Rx_DiagnosisList,reqOptns);
    }

    getWCOMDefaultValues() {
        return this._http.get(constant.GET_Rx_WCOMDefaultVal, reqOptns);
    }

    checkHasWCOMCopyByPatient(patientId: number, rxId: number): Observable<any> {
        return this._http.get(
            constant.GET_Rx_WCOM_PrevCheck.replace(
                "{PatientId}",
                "" + (patientId ? patientId : 0)
            ).replace(
                "{RxId}",
                "" + (rxId ? rxId : 0)
            ),reqOptns
        );
    }

    getWCOMCarrier(Id: any) {
        return this._http.get(constant.GET_Rx_WCOM_Carrier.replace("{CarrierId}",
            "" + Id));
    }

    getWCOMInfoByPatient(patientId: number) {
        return this._http.get(
            constant.GET_Rx_WCOM.replace(
                "{patientId}",
                "" + patientId
            ),reqOptns
        );
    }

    saveWCOMinfo(wcom: WCOM) {
        return this._http.post(constant.POST_Rx_WCOM, wcom);
    }

    saveFileToInfo(fileToInfo: any): Observable<any> {
        return this._http.post(constant.POST_Rx_RxFile, fileToInfo);
    }

    getRxExtraDefaultValues(refillId: number, noLoader?): Observable<any> {
        if (noLoader) {
            return this._http.get(
                constant.GET_Rx_RxExtraDefaultValues.replace(
                    "{refillid}",
                    "" + refillId
                ), reqOptns
            );
        } else {
            return this._http.get(
                constant.GET_Rx_RxExtraDefaultValues.replace(
                    "{refillid}",
                    "" + refillId
                )
            );
        }
        
    }

    SaveRxExtraValues(refillId: number, extraValues: any) {
        return this._http.post(
            constant.POST_Rx_SaveRxExtraValues.replace(
                "{refillid}",
                "" + refillId
            ),
            extraValues
        );
    }

    getMultiRxExtraVal(data) {
        return this._http.post(constant.POST_Get_Multi_RxExtraVal, data);
    }

    getPrescriptionId(drugClassId: number): Observable<number> {
        return this._http.get<number>(
            constant.GET_Rx_NextPrescriptionId.replace(
                "{drugClassId}",
                "" + drugClassId
            )
        );
    }
    getDurDefaultValues(): Observable<any> {
        return this._http.get(constant.GET_Rx_DurDefaultValues);
    }
    getDurValues(presId: number,prescrefillId : number): Observable<any> {
        return this._http.get(
            constant.GET_Rx_DurValues.replace("{prescriptionNo}", "" + presId).replace("{prescrefillId}","" + prescrefillId)
        );
    }
    getRxData(presNumber: number): Observable<any> {
        return this._http.get(
            constant.GET_RX_REFILL_DATA_DATA.replace("{presNumber}", "" + presNumber)
        );
    }

    addDURValues(payload : any) {
        return this._http.post(
            constant.POST_Rx_DurValues,
            payload
        )
    }
    editDURValues(durData: DUR[], Id: number, prescRefillId: number) {
        return this._http.put(
            constant.POST_RX_EditDur.replace("{durId}", "" + Id).replace("{prescrefillId}","" + prescRefillId),
            durData
        );
    }
    deleteDUR(Id: number, prescRefillId: number, rxId: number) {
        return this._http.delete(
            constant.DELETE_Rx_Dur.replace("{durId}", "" + Id).replace("{prescrefillId}", "" + prescRefillId).replace("{rxId}", "" + rxId)
        );
    }
    getPatientDrugDispHist(patId: number, drugId: number) {
        return this._http.get(
            constant.GET_Patients_Drug_Disp_his.replace(
                "{patientId}",
                "" + patId
            ).replace("{drugId}", "" + drugId)
        );
    }

    getQntyinHand(drugId: number, buckId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_Rx_GetQntyinHand.replace(
                "{drugId}",
                "" + drugId
            ).replace("{bucketId}", "" + buckId)
        );
    }

    getBrowseRxCountbyDateRange(dateModel): Observable<any> {
        return this._http.post<any>(
            constant.POST_RX_BrowseRxCountbyDate,
            dateModel,reqOptns
        );
    }

    getRxByDate(
        data
    ): Observable<any> {
        return this._http.post<any>(constant.GET_RX_BrowseRxInfoByDate, data, reqOptns);
    }

    async getRxFollowUpDropDown() {
        const resp = await this._http.get<any>(constant.GET_Rx_Follow_Up_Tag_DropDownData).toPromise();
        this._followUpList$.next(resp);
    }

    saveRxFollowUpValues(rxTagInfo: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Rx_Follow_Up_Tag, rxTagInfo);
    }

    getRxFollowUpValues(PrescNum: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_Rx_Follow_Up_Tag.replace("{PrescNum}", "" + PrescNum)
        );
    }

    getRxIOUValues(PrescNum: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_Rx_IOU.replace("{PrescRefillId}", "" + PrescNum)
        );
    }

    updateIOUValues(IouInfo: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Rx_IOU, IouInfo);
    }

    deleteIOUValue(IouId: number): Observable<Boolean> {
        return this._http.delete<boolean>(
            constant.DELETE_Rx_IOU.replace("{IouId}", "" + IouId)
        );
    }

    // TODO: need to remove this
    initiateTransmitRxOnly(transRxData: any) {
        const loaderOptns = {
            headers: new HttpHeaders().set("loadingMesssage", "Transmitting Rx")
        };
        return this._http.post<any>(
            constant.POST_Transmit_TransmitClaim,
            transRxData, loaderOptns
        );
    }

    claimTransmitRxOnly(refID: number, statusId: number, drugId: number, qty: number) {
        return this._http.put<any>(
            constant.PUT_Transmit_Continue_Claim.replace("{prescrefillId}", "" + refID)
                .replace("{statusId}", "" + statusId)
                .replace("{drugId}", "" + drugId),
            null
        );
    }

    viewTransmitMessage(transData: any) {
        return this._http.post<any>(
            constant.POST_View_Transmit_Message,
            transData
        );
    }

    getBucketsByDrugId(drugId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_Rx_BucketsbyDrugId.replace("{drugId}", "" + drugId)
        );
    }

    checkIsPickedRx(patId: number) {
        return this._http.get<any>(
            constant.GET_Rx_IsRxPicked.replace("{patientId}", "" + patId)
        );
    }


    // getMarDetails(patId: number) {
    //     return this._http.get<any>(
    //         constant.GET_MAR_Details.replace("{patientId}", "" + patId)
    //     ).pipe(
    //         share());
    // }

    refillRxDetails(refillData: any, loaderNtReq?) {
        let result: any;
        if (loaderNtReq) {
            result = this._http.post(
                constant.GET_RX_RefillRxDetails, refillData, reqOptns
            ).pipe().map((resp: Response) =>  resp),
            share();
            return result;
        } else {
            result = this._http.post(
                constant.GET_RX_RefillRxDetails, refillData
            ).pipe().map((resp: Response) =>  resp),
            share();
            return result;
        }
    }
    refillRxDetailLessData(refillData: any) {
        let result: any;
        result = this._http.post(
                constant.POST_Rx_RefillRxData, refillData, reqOptns
        ).pipe().map((resp: Response) =>  resp),
        share();
        return result;
    }
    async storeRefDetails(refillData: any) {
        this._rxStore.storeRefillRxDetails(refillData);
    }
    getRxNotes(prescriptionId: number): Observable<string> {
        return this._http.get<string>(
            constant.GET_Rx_RxNotes.replace(
                "{prescriptionId}",
                "" + prescriptionId
            )
        );
    }
    

    getRefillInsuList(InsuData:any): Observable<any> {
        return this._http.post(constant.GET_REFILL_INSULIST, InsuData);
    }

    updateRxNotes(notes: RxInputParams): Observable<number> {
        return this._http.put<number>(constant.PUT_Rx_RxNotes, notes);
    }

    updatePharmacistVerification(status: string, rxInputParams): Observable<boolean> {
        return this._http.put<boolean>(constant.PUT_Rx_MarkVerifed
            .replace("{status}", "" + status), rxInputParams);
    }

    updateCopayStatus(paidStatus: boolean, rxInputParams): Observable<boolean> {
        return this._http.put<boolean>(constant.PUT_Rx_ManageCopay
            .replace("{paidStatus}", "" + paidStatus), rxInputParams);
    }



    deleteRx(PrescRefillId, rxOrigin): Observable<number> {
        return this._http.delete<number>(constant.DELETE_Rx
            .replace("{PrescRefillId}", "" + PrescRefillId)
            .replace("{rxOrigin}", "" + rxOrigin));
    }

    getRxFilledSummary(data: any) {
        return this._http.post(constant.POST_RxFilledSummary, data);
    }

    geteRxandDocumentQueueCounts(data: any) {
        return this._http.post(constant.POST_ErxAndDocumentQueue_Count, data);
    }

    getRefillInfo(prescNum: number) {
        return this._http.get<any>(
            constant.GET_Rx_GetRefillInfo.replace("{prescNum}", "" + prescNum)
        );
    }

    // putRxRefillIdsInfo(prescId: number) {
    //     return this._http.put<any>(
    //         constant.PUT_Rx_GetRxRefillIdsInfo.replace("{PrescID}", "" + prescId), null);
    // }

    getPatientSpecificPrescribers(patientID: number) {
        return this._http.get<any>(
            constant.GET_Rx_PatientSpecificPrescribers.replace("{patientId}", "" + patientID)
        );
    }

    saveMARData(data: any) {
        return this._http.post(constant.POST_Rx_SaveMAR, data);
    }

    transmitPatientOnly(patientID: number) {
        return this._http.get(constant.GET_Transmit_PatientOnly.replace("{patientId}", "" + patientID));
    }

    transmitPatientOnlyNw(patientID: number) {
        return this._http.get(constant.GET_Transmit_PatientOnlyNw.replace("{patientId}", "" + patientID));
    }

    getRxDiscount(discountcode: number) {
        return this._http.get(constant.GET_Rx_DiscountAmount.replace("{discountCode}", "" + discountcode));
    }

    getAppUserData(addHdrs?:boolean) {
        if (addHdrs) {
            return this._http.get(constant.GET_AppUsersByTenantId, reqOptns);
        } else {
            return this._http.get(constant.GET_AppUsersByTenantId);
        }
    }

    checkAppUser(data: any) {
        return this._http.post(constant.POST_QuickVerificationUserCheck, data);
    }

    saveVerification(data: any) {
        return this._http.post(constant.POST_SaveQuickVerfication, data);
    }

    postRxAdmin(rxAdmin: any): Observable<any> {
        return this._http.post<any>(constant.POST_Rx_RxAdmin, rxAdmin);
    }

    putRxAdmin(rxAdmin: any): Observable<any> {
        return this._http.put<any>(constant.PUT_Rx_RxAdmin, rxAdmin);
    }

    getPrescNoByDrugClass(drugClass: number): Observable<any> {
        return this._http.get<any>(constant.GET_Rx_PrescNumberByDrugClass.replace("{drugClass}", "" + drugClass));
    }

    getShowRefillData(data): Observable<any> {
        return this._http.post<any>(constant.GET_Rx_ShowRefills, data);
    }

    updateRxStatus(rxBillId: number, status: number, prescRefillId: number): Observable<any> {
        return this._http.post<any>(constant.PUT_Rx_RxBillStatus.replace("{rxBillId}", "" + rxBillId)
            .replace("{status}", "" + status)
            .replace("{prescRefillId}", "" + prescRefillId), null);
    }

    getReverseClaimInfo(rxId: number, refillNo: number, fillNo: number) {
        return this._http.get<any>(constant.GET_Rx_ReverseClaimInfo.replace("{rxId}", "" + rxId)
            .replace("{refillNo}", "" + refillNo).replace("{partialfillno}", "" + fillNo));
    }

    getPickupDetails(Id: number) {
        return this._http.get(constant.GET_Pickup_Details.replace("{prescId}", "" + Id),reqOptns);
    }

    getRxPickupDetails(Id: number) {
        return this._http.get(constant.GET_RxPickup_Details.replace("{prescId}", "" + Id),reqOptns);
    }

    updatePickupDetails(Data: any) {
        return this._http.put(constant.PUT_RxPickup_Update, Data);
    }

    getSignatureDetails(Id: number) {
        return this._http.get(constant.GET_PrimeESC_Image.replace("{rxid}", "" + Id));
    }

    addPickupDetails(Data: any) {
        return this._http.post(constant.POST_Add_Pickup_Details, Data);
    }
    getPickupPrintDetails(prescRefillId: any) {
        return this._http.get(
            constant.GET_Rx_printPickupDetails.replace("{prescRefillId}", "" + prescRefillId));
    }

    editPickupDetails(Data: any) {
        return this._http.put(constant.POST_Add_Pickup_Details, Data);
    }

    getDMEDetails(rxId: any) {
        return this._http.get(constant.GET_Rx_DME.replace("{rxId}", "" + rxId),reqOptns);
    }

    updateDMEdata(dmeData: DME, rxId: number) {
        return this._http.put(
            constant.PUT_Rx_DME.replace("{rxId}", "" + rxId),
            dmeData
        );
    }

    updateRefillNumber(prescNum: any, RefillNum: any) {
        return this._http.put(
            constant.POST_Pres_Refill.replace("{prescNum}", "" + prescNum).replace("{refillNum}", "" + RefillNum),
            " "
        );
    }

    updateErxStatus(erxdata: any): Observable<any> {
        return this._http.put<any>(
            constant.PUT_eRx_UpdateRx, erxdata
        );
    }
    getPriorApprovalUrl(dataToPost: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_PriorApproval, dataToPost
        );

    }

    getRefillQueue(data: any) {
        return this._http.post<any>(constant.POST_Get_Refill_Queue, data, reqOptns);
    }

    addQueueFmReports(data: any) {
        return this._http.post<any>(constant.POST_Add_Queue_Fm_Reports, data);
    }

    addToRefillQueue(data: any) {
        return this._http.post<any>(constant.POST_Add_To_Refill_Queue, data);
    }

    deleteFromQueue(data: any, IsEmptyQue,FromRefQue : boolean = false) {
        return this._http.request("delete", constant.POST_Delete_Refill_Queue.
            replace("{IsCompleteEmpty}", "" + IsEmptyQue)
            .replace("{FromRefQ}","" + FromRefQue), { body: data });
    }

    getRxDefaultValues(drugID: number, patLangName: string) {
        return this._http.get(constant.GET_Rx_DefaultValuesPresc.replace("{DrugId}", "" + drugID).replace("{patLangName}", "" + patLangName));
    }

    saveRxExtraValuesFromTrasns(refId: any, otherCove: any) {
        return this._http.put(constant.PUT_Rx_SaveRxExtraValFrmTr
            .replace("{refillId}", "" + refId)
            .replace("{otherCoverage}", "" + otherCove), {});
    }

    getIslastRefill(prescRefillId: number, prescNum: string) {
        return this._http.get<any>(constant.GET_Rx_IslastRefill
            .replace("{prescRefillId}", "" + prescRefillId)
            .replace("{prescNum}", "" + prescNum));
    }

    getDiscontinueNoteOrdrStatus(prescRefillId: number) {
        return this._http.get<boolean>(constant.GET_DiscontinuedNoteOrderStatus
            .replace("{prescRefillId}", "" + prescRefillId));
    }

    addDiscontinuedNoteOrderStatus(data: any) {
        return this._http.post<any>(constant.POST_RxDiscontinuedNoteOrderStatus, data);
    }

    deleteNewFile(PrescNum, RefillNUm, rfId, fillNo?: any, fileDetails = null) {
        const data = {
            PrescNum: PrescNum,
            RefillNum: RefillNUm,
            PartialFillNo: fillNo ? fillNo : 1,
            PrescRefillId: rfId,
            IsFromFile: fileDetails ? fileDetails.IsFromFile : null,
            DueDateTime: fileDetails ? fileDetails.DueDateTime : null,
            Description: fileDetails ? fileDetails.Description :  null,
            deferredReason: fileDetails  ? fileDetails.deferredReason : null
        };
        return this._http.put<any>(constant.PUT_RxFile, data);
    }

    getBInInsuPayerSetCheck(insurerid: number) {
        return this._http.get<boolean>(constant.GET_Patient_InsuPayerSetCheck
            .replace("{insurerid}", "" + insurerid));
    }

    checkHasPayerCheck(data) {
        return this._http.post(constant.POST_Check_HasPayer, data);
    }

    checkSimilarRxs(patientid: any, prescid: any, drugid: any) {
        return this._http.get<any>(constant.GET_Rx_Similar
            .replace("{patientId}", "" + patientid)
            .replace("{prescriberId}", "" + prescid)
            .replace("{drugId}", "" + drugid));
    }

    getRxDoseCheck(therapeuticCode: any, ageIndays: number, dailyDose: any): Observable<any> {
        let maxDailyDoseData = {
            "therapeuticCode": therapeuticCode,
            "ageIndays": ageIndays,
            "dailyDose": dailyDose
        };
        return this._http.post<any>(constant.POST_Rx_doseCheck, maxDailyDoseData);
    }

    getNextPrevRefilInfo(prevNextFltrs: any) {
        return this._http.post<any>(constant.POST_Rx_NextPrevRefillInfo, prevNextFltrs);
    }

    getACBScore(patientID: any, drugId: any, drugTherapeuticCode: any): Observable<any> {
        return this._http.get<any>(constant.GET_Rx_ACBScoreByDrugTherapeuticCode
            .replace("{DrugTherapeuticCode}", "" + drugTherapeuticCode)
            .replace("{DrugId}", "" + drugId)
            .replace("{PatientId}", "" + patientID));
    }

    getQtySpcToDrugBucket(drugId) {
        return this._http.get<any>(constant.GET_Bucket_Drug_Qty.replace("{drugId}", "" + drugId));
    }

    getStngsFrBatchEdit(data) {
        return this._http.post<any>(constant.POST_Get_StngForBatchEdit, data);
    }

    BatchEditSave(data) {
        return this._http.put(constant.POST_Save_Batch_Edit, data);
    }
    checkForLatestFill(refIds: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_CheckRxs_LatestFill,
            refIds);
    }

    fileMultipleRxs(payload: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_MultipleRX_File,
            payload);
    }

    checkMultipleRxWithLatestFill(payload: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_CheckRxs_LatestFill,
            payload);
    }

    getCummulativeFillDetails(data) {
        return this._http.post(constant.POST_GET_CummalativeDetails, data);
    }

    getSubmissionClarVal(PrescRfId, InsuplanId) {
        return this._http.get(constant.GET_Has_SubmissionCla.replace("{prescrefillId}", "" + PrescRfId)
            .replace("{insuPlanId}", "" + InsuplanId));
    }

    removeSubmissionClarVal(PrescRfId) {
        return this._http.put(constant.POST_Remove_SubmissionCla, { PrescRefillId: PrescRfId });
    }

    putRxDiscontinuedOrderStatus(prescriptionId: number): Observable<any> {
        return this._http.put<any>(constant.PUT_Rx_DiscontinuedOrderStatus.replace("{PrescID}", "" + prescriptionId), null);
    }

    putMultipleRxDisontinuedOrderStatus(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_DiscontinuedMultipleRx, data);
    }

    checkRxExistOrNot(data) {
        return this._http.post(constant.Rx_Exist_Or_Not, data);
    }

    getMandatoryCheckPriorApproval(dataToPost: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_MandatoryCheckPriorApprvl, dataToPost
        );

    }
    getDetailsForNotificationDisplay(data){
        return this._http.post(constant.POST_envelope_Display,data);
    }
    detailsForDisplayNotificationCount(data,addHdrs?:boolean){
        if (addHdrs) {
            return this._http.post(constant.POST_Display_Notification_Count,data, reqOptns);
        } else {
            return this._http.post(constant.POST_Display_Notification_Count,data);
        }
    }
    checkStatusOfError(data){
        return this._http.put(constant.PUT_ControlErrorStatusUpdate,data);
    }
    dataOfcontrolErrorfile(data){
        return this._http.post(constant.POST_ControlErrorDetails,data);
    }
    showNYErrors(data){
        return this._http.post(constant.POST_NY_Error_Details,data);
    }
    // notAutoSubmittedData(data){
    //     return this._http.post(constant.POST_NotAutoSubmit,data);
    // }
    notAutoSubmittedData(data): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(
            constant.POST_NotAutoSubmit,data,
            {
                headers: headers,
                responseType: "blob"
            }
        );
    }
    getRxAnnotationHistory(prescriptionId) {
        return this._http.get(constant.GET_RxAnnonationHistory.replace("{prescrefillId}", "" + prescriptionId));
    }
    postRxAnnotationHistory(data) {
        return this._http.post(constant.POST_RxAnnonationHistory, data);
    }
    postPrintAnnotationHistory(data) {
        let headers = new HttpHeaders();
        headers = headers.set("Accept", "application/pdf");
        return this._http.post(constant.POST_PrintAnnonationHistory, data, {
            headers: headers,
            responseType: "blob"
        });
    }
    getIntakeQueueData(data) {
        return this._http.post(constant.POST_IntakeQueuedata, data);
    }

    checkRxValidwithObj(data){
            return this._http.post(constant.Rx_ExistorNotWithObj,data);
    }
    getIntakeQueueList(payload:any): Observable<any> {
        return this._http.post(constant.Rx_GetIntakeQueue,payload, reqOptns);
    }

    getRxIOUNewValues(Id: any): Observable<any> {
        return this._http.post<any>(constant.POST_RX_IOU,Id);
    }

    deleteIOURecord(payLoad: any): Observable<any> {
        return this._http.post<any>(constant.DELETE_RX_IOURec, payLoad);
    }

    updateIOUOweQty(payload: any): Observable<any> {
        return this._http.put<any>(constant.UPDATE_IOU_OweQty, payload);
    }
    getRxCurrentViewDetails(payload: any) {
        return this._http.post(constant.GET_Rx_CurrentView, payload);
    }

    getPaQueueList(payload: any) : Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("frprescriptionBaseUrl", "true");
        headers = headers.set("isLoadNotReq", "true");
        return this._http.post(constant.GET_PriorAuth_PAQueue, payload, {headers: headers});
    }

    markPAApproved(payload: any) : Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("frprescriptionBaseUrl", "true");
        return this._http.post(constant.POST_PriorAuth_PAApproved, payload, {headers: headers});
    }
    
    sendOrRetryPARequest(payload: any) : Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("frprescriptionBaseUrl", "true");
        return this._http.post(constant.POST_PriorAuth_Send_PARequest, payload, {headers: headers});
    }

    getPAStatusHistory(payload: any,showloader?) : Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set("frprescriptionBaseUrl", "true");
        headers = headers.set("isLoadNotReq", showloader ? "false" : "true");
        return this._http.post(constant.POST_PriorAuth_History, payload, {headers: headers});
    }

    archivePAinfo(payload) {
        let headers = new HttpHeaders();
        headers = headers.set("frprescriptionBaseUrl", "true");
        return this._http.post(constant.POST_PriorAuth_Archive, payload, {headers: headers});
    }

    deletPAinfo(payload) { 
        let headers = new HttpHeaders();
        headers = headers.set("frprescriptionBaseUrl", "true");
        return this._http.post(constant.POST_PriorAuth_Delete, payload, {headers: headers});
    }



}
