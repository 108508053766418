import {
    Component,
    OnInit,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    HostListener
} from "@angular/core";
import {
    NgbModalConfig,
    NgbModal,
    NgbNavChangeEvent
} from "@ng-bootstrap/ng-bootstrap";
import { AlertService, RphVerificationService } from "src/app/services";
import {
    DrugInteraction,
    DuplicateDrugs,
    AdverseReaction,
    DrugInteractionData
} from "src/app/models";
import { CollectionView } from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid"
import * as moment from "moment";
import { NRxUtils } from "src/app/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-check-dur",
    templateUrl: "./check-dur.component.html"
})
export class CheckDurComponent implements OnInit, AfterViewInit, OnDestroy {
    modelRef: any;
    rxInfo: any;
    drugInteractions: DrugInteraction;
    duplicateDrugs: DuplicateDrugs[];
    duplicateDrugsWJ: CollectionView;
    adverseReaction: AdverseReaction[];
    adverseReactionWJ: CollectionView;
    totalDurInfo: any;
    allergiesToShow: any[] = [];
    drugToDrugIntrctns: any[] = [];
    drugDiseaseContrdns: any[] = [];
    drugLifestyles: any[] = [];
    adverseDrugReacts: any[] = [];
    acbScoreInfo: any[] = [];
    pregAndLactInfo: any[] = [];
    patientID: number;
    duplicateDrugsHeadrs: any[] = [];
    duplicateDrugsNewWJ: CollectionView;
    unsubscribe$: Subject<void> = new Subject();
    @Input()
    set RxInfo(data: any) {
        this.rxInfo = data;
    }

    @Input()
    set PatientID(pi: number) {
        this.patientID = pi;
    }

    @Output()
    IsPopUpClosed = new EventEmitter<null>();

    @Output()
    RefreshRxHistory = new EventEmitter<any>();

    @ViewChild("CheckDur", { static: true })
    CheckDur: any;
    selctdTab = "allergyCheck";

    @HostListener("window:keydown", ["$event"])
    keyEventEsc(event: KeyboardEvent) {
        if (event.which === 27 && this.modelRef && this._modalService.hasOpenModals()) { // esc
            this.closeModal();
            event.preventDefault();
        }
    }


    constructor(
        config: NgbModalConfig,
        private _modalService: NgbModal,
        private _cdr: ChangeDetectorRef,
        private _alertService: AlertService,
        private _rphService: RphVerificationService,
        public _formatsUtil:FormatsUtil,
        private _nrxUtils: NRxUtils,

    ) {
        config.backdrop = "static";
    }

    ngOnInit() {
        this.modelRef = this._modalService.open(this.CheckDur, {
            size: "lg",
            keyboard: false, 
            windowClass: "modal-dur modal-center-to-page",
        });
        // this.getDrugInteractionInfo();
        this.getDurInfo();
    }

    public beforeChange($event: any) {
        this.selctdTab = $event;
        this.setDataToTab(this.selctdTab);
    }


    getDurInfo() {
        this._rphService.getPatientDurInfo(this.patientID)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(resp => {
            if (resp) {
                this.totalDurInfo = resp;
                this.allergiesToShow = (this.totalDurInfo && this.totalDurInfo.Allergies && this.totalDurInfo.Allergies.length) ?
                    this.totalDurInfo.Allergies.filter(elm => {
                        if (elm.IsAllergy) {
                            elm["AllergyInfo"] = elm["AllergyInfo"].replaceAll("@$@$", "");
                        }
                        return elm.IsAllergy;
                    }) : [];
                this.duplicateDrugs = this.totalDurInfo && this.totalDurInfo.duplicateDrugs ? this.totalDurInfo.duplicateDrugs : [];
                this.drugToDrugIntrctns = (this.totalDurInfo && this.totalDurInfo.drugToDrugInteraction && this.totalDurInfo.drugToDrugInteraction.length) ? this.totalDurInfo.drugToDrugInteraction.filter(elm => elm.drugToDrugInteraction.length > 0) : [];
                this.drugDiseaseContrdns = this.totalDurInfo.diseaseContradication ? this.totalDurInfo.diseaseContradication.filter(elm => elm.DiseaseContradication.IsShowDDC) : [];
                this.drugLifestyles = this.totalDurInfo && this.totalDurInfo.lifeStyle && this.totalDurInfo.lifeStyle.length? this.totalDurInfo.lifeStyle.filter(elm => elm.lifeStyle.length > 0) : [];
                this.adverseDrugReacts = this.totalDurInfo && this.totalDurInfo.adverseReaction && this.totalDurInfo.adverseReaction.length? this.totalDurInfo.adverseReaction.filter(elm => elm.adverseReaction.length > 0) : [];
                this.acbScoreInfo = this.totalDurInfo.AcbScore ? this.totalDurInfo.AcbScore.filter(elm => elm.AcbScore > 0) : [];
                this.pregAndLactInfo = this.totalDurInfo.pregnancyLactationInfo ? this.totalDurInfo.pregnancyLactationInfo : [];
                
                if(this.duplicateDrugs && this.duplicateDrugs.length > 0) {
                    this.duplicateDrugsHeadrs = ['DRUG 1', 'DRUG 2', 'CLASS NAME'];
                    this.duplicateDrugsNewWJ = new CollectionView(
                        this.duplicateDrugs.map(bi => {
                            const j = {};
                            j["DRUG 1"] = bi["OriginalDrugName"];
                            j["DRUG 2"] = bi["DrugName"];
                            j["Rx1"] = bi["rxNumber"];
                            j["Rx2"] = bi["rxNumber2"];
                            j["CLASS NAME"] = bi["Description"];
                            return j;
                        })
                    );
                }
            }
        });
    }

    RefreshRxHistoryGrid() {
        this.RefreshRxHistory.emit(true);
    }

    getDrugInteractionInfo() {
        const dob = moment(this.rxInfo?.Patient?.dob ? this.rxInfo.Patient.dob : null);
        const days = moment().diff(dob, "days");
        const drugIntData: DrugInteractionData = {
            drugId: this.rxInfo.Drug.id,
            drugTypeId: this.rxInfo.Drug.drugtypeid,
            patientId: this.rxInfo.Patient?.patientid,
            gender: this.rxInfo.Patient.gender,
            islong: true,
            languageCulture: "es-US",
            calledFrom: "RxEntry",
            patLanguage: this.rxInfo.Patient?.langname,
            isFromEditRx: this.rxInfo.rxType == "er" ? true : false,
            isForCount: true,
            ageInDays: days,
            therapeuticCode: this.rxInfo.Drug.therapeuticcode,
            drugClass: this.rxInfo.Drug.drugclass,
            CHECK_DRU_AND_DELIVERY: this._nrxUtils.isSafeForLabrDelivrySet(),
            CHECK_DRU_BEARING_AGE: this._nrxUtils.isChildBearingAgeSet(),
            USE_ACB_SCORING: this._nrxUtils.getSetngKeyVal("PatientSettings", "USE_ACB_SCORING"),
            drugNdc: this.rxInfo.Drug.drugcode,
            diagnosisList: this.rxInfo.Patient?.Diagnosis,
            ddiScanDays: this._nrxUtils.getSettingValue("PatientSettings", "DDI_SCAN_DAYS"),
            INCIDENCE: this._nrxUtils.getSettingValue("PatientSettings", "INCIDENCE"),
            ON_SET: this._nrxUtils.getSettingValue("PatientSettings", "ON_SET"),
            SEVERITY_LEVEL: this._nrxUtils.getSettingValue("PatientSettings", "SEVERITY_LEVEL"),
            USE_ADV_DRUG_REACTION: this._nrxUtils.getSettingValue("PatientSettings", "USE_ADV_DRUG_REACTION"),
            DRUGS_WIT_WARN_ONLY: this._nrxUtils.getSettingValue("PatientSettings", "DRUGS_WIT_WARN_ONLY"),
            PrescNum: this.rxInfo?.Prescription?.PrescNum
        }
        this._rphService
            .getDrugInteractions(
                drugIntData           
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(resp => {
                if (resp) {
                    this.drugInteractions = resp;
                    this.duplicateDrugs = this.drugInteractions.DuplicateDrugs;
                    if (this.duplicateDrugs && this.duplicateDrugs.length > 0) {
                        this.duplicateDrugsWJ = new CollectionView(
                            this.duplicateDrugs
                        );
                    } else {
                        this.duplicateDrugsWJ = null;
                    }
                    this.adverseReaction = this.drugInteractions.AdverseReaction;
                    if (this.adverseReaction && this.adverseReaction.length > 0) {
                        this.adverseReactionWJ = new CollectionView(
                            this.adverseReaction
                        );
                    } else {
                        this.adverseReactionWJ = null;
                    }
                }
            });
    }

    closeModal() {
        if (this.modelRef) {
            this.modelRef.close();
            this.modelRef = null;
        }
        this.IsPopUpClosed.emit(null);
    }

    // To manually trigger change detection for the current component
    ngAfterViewInit() {
        this._cdr.detectChanges();
    }
    init(flex: wjcGrid.FlexGrid) {
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRows();
    }
    setDataToTab(selctdTab) {
        switch(selctdTab) {
            case "drugToDrugIntr": { 
                if(this.drugToDrugIntrctns && this.drugToDrugIntrctns.length > 0) {
                    this.drugToDrugIntrctns.map((elem) => {
                        elem['isExpand'] = false;
                    });
                }
               break; 
            } 
            case "drugDiseCont": { 
                if(this.drugDiseaseContrdns && this.drugDiseaseContrdns.length > 0) {
                    this.drugDiseaseContrdns.map((elem) => {
                        elem['isExpand'] = false;
                    });
                }
               break; 
            } 
            case "drugToLife": { 
                if(this.drugLifestyles && this.drugLifestyles.length > 0) {
                    this.drugLifestyles.map((elem) => {
                        elem['isExpand'] = false;
                    });
                }
               break; 
            } 
            case "advDrugReact": {
                if(this.adverseDrugReacts && this.adverseDrugReacts.length > 0) {
                    this.adverseDrugReacts.map((elem) => {
                        elem['isExpand'] = false;
                    });
                }
               break; 
            } 
            case "pregAndLact": { 
                if(this.pregAndLactInfo && this.pregAndLactInfo.length > 0) {
                    this.pregAndLactInfo.map((elem) => {
                        elem['isExpand'] = false;
                    });
                }
               break; 
            } 
            default: { 
                //statements; 
               break; 
            } 
         } 
    }

    ngOnDestroy() {
        this._cdr.detach();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
