import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as Sentry from "@sentry/browser";
@Component({
  selector: "app-common-warnorconfirm-popup",
  templateUrl: "./common-warnorconfirm-popup.component.html"
})
export class CommonWarnorconfirmPopupComponent implements OnInit {
    @ViewChild("WarnPopup", { static: true }) WarnPopup: any;
    @ViewChild("PAErrorMessage", {static: true})  PAErrorMessage: any;
    @Input() warnMsg: any;
    @Input() okButton: boolean;
    @Input() yesButton: boolean;
    @Input() noButton: boolean;
    @Input() isPopSize: string;
    @Input() IsHeaderText: string;
    @Input() recallButton: boolean;
    @Input() closeButton: boolean;
    @Input() okayFinalButton: boolean;
    @Input() Defer:boolean;
    @Input() IgnoreAndContinue:boolean;
    @Input() CancelButton:boolean;
    @Input() buttonType:boolean;
    @Input() shortcutKey:any;
    @Input() hideCross: boolean;
    @Input() isfromPA : boolean;
    @Input() RxNum: any;



    @Output()
    IsPopUpClosed = new EventEmitter<boolean>();

     @HostListener("window:keydown", ["$event"])
        keyEventAlt(event: KeyboardEvent) {
            if (event.which === 27 && this.isfromPA) {
                this.closeEvent(true);
                event.preventDefault();
            }
        }

    modelRef: any;
  constructor(private _modalService: NgbModal,private _cd: ChangeDetectorRef) { }

  ngOnInit() {
    // Sentry.captureException([
    //   {name: this.warnMsg}
    // ]);
    if(this.isfromPA) {
        this.modelRef = this._modalService.open(this.PAErrorMessage, {centered: true, backdrop: false, keyboard: false, windowClass: this.isPopSize ? this.isPopSize : "large--content"});
    } else {
      this.modelRef = this._modalService.open(this.WarnPopup, {centered: true,
      backdrop: false, keyboard: false, windowClass: this.isPopSize ? this.isPopSize : "large--content"});
      setTimeout(() => {
        if (document.getElementById((this.Defer === false) ? "CancelButton" : "focuschange")) {
            document.getElementById((this.Defer === false) ? "CancelButton" : "focuschange").focus();
        }
      }, 0);
    }
  }
  closeEvent(type) {
    this.closePop();
    this.IsPopUpClosed.emit(type);
  }
  patchQtyDisp(clickedType) {
    this.closePop();
    this.IsPopUpClosed.emit(clickedType);
  }
  closePop() {
    if(this.modelRef) {
      this.modelRef.close();
      this.modelRef = null;
     }
   }
}



