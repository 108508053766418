import { Component, OnInit, Input, EventEmitter, Output, ElementRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ValidateFormFieldsUtils } from "src/app/utils";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "eprx-text-area",
    templateUrl: "./text-area.component.html"
})
export class TextAreaComponent implements OnInit {
    isRequired = false;
    hasFGInvalid: any;
    errorMessage: string;
    inputErrors: any;
    errorDefs: any;
    maxLength = 150;
    cols: number;
    autoFocus = false;

    @Input()
    set LabelText(lt: string) {
        this.labelText = lt;
    }

    @Input()
    set FormGroupName(fg: FormGroup) {
        this.inputFormGroup = fg;
    }

    @Input()
    set ControlName(cn: string) {
        this.custContrlName = cn;
    }

    @Input()
    set PlaceHolder(ph: string) {
        this.placeHolder = ph;
    }

    @Input()
    set Title(title: string) {
        this.titleText = title;
    }

    @Input()
    set ReadOnly(mp: boolean) {
        this.isReadOnly = mp;
    }

    @Input()
    set HasControl(hc: boolean) {
        this.hasControl = hc;
    }

    @Input()
    set InputErrors(ie: any) {
        this.inputErrors = ie;
    }

    @Input()
    set ErrorDefs(ed: object) {
        this.errorDefs = ed;
    }

    @Input()
    set Rows(ir: number) {
        this.rows = ir;
    }

    @Input()
    set AutoFocus(af: boolean) {
        this.autoFocus = af;
    }

    @Input()
    set Cols(ir: number) {
        this.cols = ir;
    }

    @Input()
    set RxSelectId(id: any) {
        this.rxID = id;
    }


    @Input()
    set TabIndex(ti: number) {
        this.tabIndex = ti;
    }

    @Input()
    set IsRequired(ir: boolean) {
        this.isRequired = ir;
    }

    @Input()
    set FormGroupInvalid(hc: boolean) {
        this.hasFGInvalid = hc;
        if (this.hasFGInvalid) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set MarkAsTouched(mt: boolean) {
        if (mt) {
            this.validateAllFormFields(
                this.inputFormGroup,
                this.custContrlName
            );
        }
    }

    @Input()
    set MaxLength(ml: number) {
        if (ml) {
            this.maxLength = ml;
        } else {
            this.maxLength = 150;
        }
    }

    @Input() inputValue: any;

    @Input() IsFromNotes: any;

    @Output()
    TriggerOnShiftTabbing = new EventEmitter<any>();

    @Output()
    TriggerOnTabbing = new EventEmitter<any>();
    @Output()
    TriggerCursorPosition = new EventEmitter<any>();

    constructor(private _validFormFieldsUtils: ValidateFormFieldsUtils, private _element: ElementRef) {}

    labelText: string;
    titleText: string;
    placeHolder: string;
    inputFormGroup: FormGroup;
    custContrlName: string;
    prefix: string;
    value: any;
    isReadOnly: boolean;
    hasControl = true;
    rows = 5;
    rxID: any;
    tabIndex: number;
    excludeRxSelectedIds = ["DiagnosisNote", "OrderNote", "DietNote", "Remarks", "Instructions", "msgTab1",
     "msgTab2", "msgTab3", "message", "sendSms"];
    ngOnInit() {
        if(this.inputValue && !this.IsFromNotes) {
            this.inputValue = this.inputValue.toUpperCase();
        }
        if (!this.excludeRxSelectedIds.includes(this.rxID) && !this.IsFromNotes) {
            if (this.inputFormGroup && this.inputFormGroup.value && this.inputFormGroup.value[this.custContrlName]) {
                if(typeof this.inputFormGroup.value[this.custContrlName] === 'string'){
                    this.inputFormGroup.controls[this.custContrlName].setValue(this.inputFormGroup.value[this.custContrlName]?.trim().toUpperCase());
                } else{
                    this.inputFormGroup.controls[this.custContrlName].setValue(this.inputFormGroup.value[this.custContrlName]);
                }
            }
     }
     }

    validateAllFormFields(
        formGroup?: FormGroup,
        formCntrl?: string
    ) {
        this.errorMessage = this._validFormFieldsUtils.validateFormFields(formGroup, formCntrl, this.errorDefs);
    }

    triggerOnBlur(e) {
        if (this.inputFormGroup && this.inputFormGroup.value && this.inputFormGroup.value[this.custContrlName]) {
            this.inputFormGroup.controls[this.custContrlName].setValue(this.inputFormGroup.value[this.custContrlName]?.trim());
        }
        this.validateAllFormFields(this.inputFormGroup, this.custContrlName);
        this.TriggerOnTabbing.emit(e);
    }

    triggerOnTab(event) {
    this.validateAllFormFields(
        this.inputFormGroup,
        this.custContrlName
    );
    if (this.hasControl) {
        if (this.inputFormGroup.controls[this.custContrlName].invalid) {
            event.preventDefault();
            const input = this._element.nativeElement.getElementsByTagName("textarea");
             input[0].focus();
        }
    }
    this.TriggerOnTabbing.emit(event);
}

    onShiftTabbingEvent(event) {
        if (event.shiftKey && event.key === "Tab") {
            this.TriggerOnShiftTabbing.emit(event);
        }
    }
    getCursorPosition(event) {
        const start = event.target.selectionStart;
        this.TriggerCursorPosition.emit(start);
    }
}
