<div class="editpatient--body newrx--body edit--drug insurance--body">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="row">
                    <div class="icon">
                        <img src="assets/dist/Icons/Icon_rx2.png">
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0">Pickup Rx
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-4 padding-0">
                <button class="hotkey_success"  id="SaveButton" appShortcutKey [AltKey]="'s'" (click)="savePickUp()">
                    <span>S</span>Save</button>
                <button class="hotkey_success" appShortcutKey  [AltKey]="'c'" (click)="previousPage()">
                    <span>C</span>Cancel</button>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                <span>Pick Up</span>
            </div>
        </div>
        <div class="eprx--block__content">
            <div class="row">

                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <label class="col-md-12 padding-0"> Is Picked Up? </label>
                        <eprx-radio [ControlName]="'IsPickedUp'" [FormGroupName]="pickupFG" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['pick1', 'pick0']"
                            [LabelForId]="" (TriggerSelectValue)="setVal()"
                            (keydown.tab)="focusOutFromRadio($event)">
                        </eprx-radio>
                    </div>
                    <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <label class="col-md-12 padding-0"> POS? </label>
                        <eprx-radio [ControlName]="'PickUpPos'" [FormGroupName]="pickupFG" [List]="['Yes', 'No']" [ValueList]="[true, false]" [IDForList]="['pick2', 'pick3']"
                            [LabelForId]="">
                        </eprx-radio>
                    </div> -->
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <eprx-select [LabelText]="'Picked Up From'" [PlaceHolder]="'Picked Up From'" [ControlName]="'PickUpFromId'" [FormGroupName]="pickupFG" [List]="rxPickupFromOptions" [BindLabel]="'Name'" [BindLabel2]="'Remarks'"  [BindValue]="'Id'" [LabelForId]="'Remarks'" [HasMultiple]="false" [IsDisabled]="pickupFG?.value?.IsPickedUp ? false : true" [Show2Values]="true"
                         [RxSelectId]="'pickedUpFrom'">
                        </eprx-select>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <eprx-input [LabelText]="'Transaction ID'" [PlaceHolder]="'Transaction ID'" [IsDisabled]="true" [HasControl]="false" [Title]="pickupFG.value.POSTransId"
                            [ReadOnly]='true' [InputValue]="pickupFG.value.POSTransId">
                        </eprx-input>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <eprx-input [LabelText]="'POS Cashier ID'" [PlaceHolder]="'POS Cashier ID'" [IsDisabled]="true" [HasControl]="false" [Title]="pickupFG.value.POSUserId"
                            [ReadOnly]='true' [InputValue]="pickupFG.value.POSUserId">
                        </eprx-input>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <eprx-date-picker *ngIf="pickupFG.value.IsPickedUp" [AutoFocus]="true"  [LabelText]="'Picked Up Date'" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid" [InputErrors]="formGroupInvalid" (TriggerOnDateSelect)="getPickupDate(true)"
                        [ErrorDefs]="{required: 'Required'}"  [PlaceHolder]="'Picked Up Date'" [ControlName]="'ExpDtTm'"
                        [FormGroupName]="pickupFG" [MinDateString]="pickupMinDate" (TriggerOnDateSelect)="withChangeInDateSetTime()">
                    </eprx-date-picker>

                    <eprx-date-picker *ngIf="!pickupFG.value.IsPickedUp" [AutoFocus]="true"  [LabelText]="'Picked Up Date'"  [FormGroupInvalid]="formGroupInvalid" [InputErrors]="formGroupInvalid"
                         [PlaceHolder]="'Picked Up Date'" [ControlName]="'ExpDtTm'" [FormGroupName]="pickupFG" [MarkAsTouched]="pickupFG?.get('ExpDtTm')?.touched" [MinDateString]="pickupMinDate" [IsDisabled] ="disableDateField">
                    </eprx-date-picker>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                   <!-- <eprx-input [LabelText]="'Pickup Time'" [PlaceHolder]="'Pickup Time'" [ControlName]="'ExpDate'" [FormGroupName]="pickupFG">
                    </eprx-input>-->
                    <label>Pickup Time</label>
                    <div class="input-group" [formGroup]="pickupFG">
                        <input type="text" class="form-control" id="pickUpTabTime"  placeholder="Pickup Time" formControlName="ExpDate" (keydown.tab)="checkPikcUpTimeValidation()" >
                        <div class="input-group-append cursor_pointer pickup_rx "  (click)="getPickTimeForDisplay()">
                          <span class="input-group-text text-white">{{pickTimeForDisplay | uppercase}}</span>
                        </div>
                      </div>
                      <div *ngIf="isPickTimeValid" class="text-danger">
                          Please Enter Valid time format
                      </div>
                </div>

                <div class="col-lg2 mt-4" style="margin-left: 94px; margin-right: 50px;" *ngIf="hasReceiptData">
                    <span>
                        <button class="btn btn-primary p-2" (click)="PrintPOSReceipt()">Print POS Receipt</button>
                    </span>
                </div>

                <div class="col-lg2 mt-4 pull-right text-right" *ngIf="hasSignature">
                    <span>
                        <button class="btn btn-primary p-2" (click)="openPickUpPrint()">Show Signature Details</button>
                    </span>
                </div>
                
            </div>

        </div>
        <div class="eprx--block__content">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6  padding--right__7">
                    <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 padding--right__7">
                            <eprx-input [LabelText]="'Rx#'" [PlaceHolder]="'Rx#'" [IsDisabled]="true" [HasControl]="false"
                            [InputValue]="rxDetails?.Prescription?.PrescNum">
                            </eprx-input>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding--right__7">
                            <eprx-input [LabelText]="'Refill#'" [PlaceHolder]="'Refill#'" [IsDisabled]="true" [HasControl]="false"
                            [InputValue]="rxDetails?.PrescReFill?.ReFillNum ? rxDetails?.PrescReFill?.ReFillNum : 0">
                            </eprx-input>
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 padding--right__7">
                            <eprx-input [LabelText]="'Fill#'" [PlaceHolder]="'Fill#'" [IsDisabled]="true" [HasControl]="false"
                            [InputValue]="getPartialFillNo()"
                           >
                            </eprx-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                            <eprx-input [LabelText]="'Last Name'" [PlaceHolder]="'Last Name'"   [ControlName]="'PkLastName'" [FormGroupName]="pickupFG"   [RxSelectId]="'pickUpLastName'"
                            [ReadOnly]="!pickupFG.value.IsPickedUp" [Title]="this.rxDetails['Patient']['lastname'] | uppercase">
                            </eprx-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                            <eprx-input [LabelText]="'First Name'" [PlaceHolder]="'First Name'" [ControlName]="'PkFirstName'" [FormGroupName]="pickupFG"
                            [ReadOnly]="!pickupFG.value.IsPickedUp"  [Title]=" this.rxDetails['Patient']['firstname'] | uppercase">
                            </eprx-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                            <eprx-input [LabelText]="'Pickup Date'" [PlaceHolder]="'Pickup Date'" [IsDisabled]="true" [HasControl]="false"
                                [ReadOnly]='true' [InputValue]="pickupFG.value.ExpDtTm  | dateFormat">
                            </eprx-input>
                        </div>
                         <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                            <eprx-select [LabelText]="'Relation'" [PlaceHolder]="'Relation'" [ControlName]="'RelationCatId'" [FormGroupName]="pickupFG"
                                [HasMultiple]="false" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''"
                                [Show2Values]="true" [List]="SystemData?.RelationCat ? (SystemData?.RelationCat | filterRelation:'Pick') : []"
                                [IsDisabled]="!pickupFG.value.IsPickedUp" [secondCntrlN]="'RelRemarks'" [bindScnCntrlV]="'Remarks'">
                            </eprx-select>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 padding--right__7">
                            <eprx-input [LabelText]="'Pick Up ID'" [PlaceHolder]="'Pick Up ID'" [ControlName]="'PkIdNum'" [FormGroupName]="pickupFG"
                            [ReadOnly]="!pickupFG.value.IsPickedUp">
                            </eprx-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                            <eprx-select [LabelText]="'Pick Up ID Qualifier'" [PlaceHolder]="'Pick Up ID Qualifier'" [ControlName]="'PkIdProofId'" [FormGroupName]="pickupFG" [HasMultiple]="false"
                                [Show2Values]="true" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''"
                                [List]="SystemData.IdProof" [IsDisabled]="!pickupFG.value.IsPickedUp" [secondCntrlN]="'IdProof'" [bindScnCntrlV]="'Remarks'">
                            </eprx-select>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                            <eprx-input [LabelText]="'Issuing State'" [PlaceHolder]="'Issuing State'" [ControlName]="'PkIdIssuer'" [FormGroupName]="pickupFG" [ReadOnly]="!pickupFG.value.IsPickedUp">
                            </eprx-input>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 ">
                            <eprx-select [LabelText]="'Drop Pick Qualifier'"  [RxSelectId]="'DropPickQ'" [PlaceHolder]="'Drop Pick Qualifier'" [ControlName]="'DropPickQ'" [FormGroupName]="pickupFG" [HasMultiple]="false"
                            [TabIndex]="10"
                                [Show2Values]="true" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''"
                                [List]="SystemData.DroppingOff_PickingUp" [secondCntrlN]="'DropPickQulifierName'" [bindScnCntrlV]="'Remarks'">
                            </eprx-select>
                        </div>
                    </div>
                </div>
                <div [ngClass]="hasSignature ? 'col-lg-3 col-md-3 col-sm-3 col-xs-3' : 'col-lg-6 col-md-6 col-sm-6 col-xs-6'" [formGroup]="pickupFG">
                    <label class="col-md-12 padding-0"> Notes </label>
                    <div class="form-group notes--block height_84_5Per">
                        <textarea class="label--text__area col-md-12 w-99.5 height_99_5Per" (keydown.tab)="focusOnSave($event)" formControlName="Remarks" value="{{ pickUpDetails?.Remarks }}" 
                        [readOnly]="!pickupFG.value.IsPickedUp"></textarea>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="hasSignature">
                    <div class="row mx-0">
                            <div class="col-8">
                                <label class="control-label">Signature</label>
                                <img [src]="imageToShow" class="height_174px width_300px" >
                            </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<div class="editpatient--body newrx--body edit--drug insurance--body" *ngIf="false">
    <div class="editpatient">
        <div class="row content--heading">
            <div class="col-md-8 padding-0">
                <div class="row">
                    <div class="icon">
                        <img src="assets/dist/Icons/Icon_rx2.png">
                    </div>
                    <div class="col-md-11 padding-0">
                        <div class="content--heading__title padding-0">Pickup Rx
                            <span class="content--name"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right pull-right col-md-4 padding-0">
                <button class="hotkey_success"  id="SaveButton" appShortcutKey [AltKey]="'s'" (click)="savePickUp()">
                    <span>S</span>Save</button>
                <button class="hotkey_success" appShortcutKey  [AltKey]="'c'" (click)="previousPage()">
                    <span>C</span>Cancel</button>
            </div>
        </div>
    </div>
    <div class="exprx--common-block">
        <div class="eprx--block__header">
            <div class="eprx--header__heading">
                <span>Pick Up</span>
            </div>
        </div>
        <div class="eprx--block__content">
            <div [formGroup]="newPickUpFG">
                <div *ngFor="let pickUpDet of pickupDetails.controls; let i = index">
                    <div class="eprx--block__content border_AllRadius">
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                            <div class="row">
                                                <label class="col-md-5 pr-0"> Is Picked Up? </label>
                                                <div class="col-1 p-0">
                                                    <eprx-toggle [ControlName]="'IsPickedUp'" [FormGroupName]="pickUpDet" [IsChecked] = "pickUpDet?.get('IsPickedUp').value">
                                                    </eprx-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 pl-0">
                                            <div class="row d-flex align-items-center gap-1">
                                                <label class="col-auto p-0 m-0 ">Drug Name:</label>
                                                <div class="col pl-2 m-0 text-truncate">{{ pickUpDet?.value?.DrugName }}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2">
                                            <div class="row d-flex align-items-center">
                                                <label class="col-2 p-0 m-0">NDC:</label>
                                                <div class="col-10 pl-2 m-0 text-truncate">{{ pickUpDet?.value?.NDC }}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2">
                                            <div class="row d-flex align-items-center">
                                                <label class="col-4 pl-0 m-0">Quantity:</label>
                                                <div class="col-8 pl-0 m-0 text-truncate">{{ pickUpDet?.value?.Qty | number:
                                                    "1.3-3"}}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 col-md-1 p-0" *ngIf="pickUpDet?.value?.IOUQty">
                                            <div class="row d-flex align-items-center">
                                                <label class="col-2 m-0">IOU:</label>
                                                <div class="col-8 m-0 text-truncate">{{ pickUpDet?.value?.IOUQty | number:
                                                    "1.3-3"}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-9">
                                            <div class="row">
                                                <label class="col-3 pr-0">Transaction ID</label>
                                                <div class="col-9">
                                                    <eprx-input [LabelText]="''" [PlaceHolder]="'Transaction ID'" [IsDisabled]="true" [HasControl]="false" [Title]="pickUpDet?.value?.POSTransId" [cssStyle]="true" [ReadOnly]='true' [InputValue]="pickUpDet?.value?.POSTransId">
                                                    </eprx-input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 row pl-4">
                                            <div class="col-1">
                                                <i class="fa-kit fa-notes-line actions font_weight_600 font_size_1_4rem"></i>
                                            </div>
                                            <div class="col-1">
                                                <i title="Print POS Receipt" class="far fa-print actions" aria-hidden="true"></i>
                                            </div>
                                            <div class="col-1">
                                                <i title="Show Signature Details" class="far fa-signature fa-sm actions" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-md-3">
                                    <div class="row">
                                        <label class="col-md-4 p-0"> Drug Name: </label>
                                        <div class="col-lg-8 col-md-8">{{pickUpDet?.value?.DrugName}}</div>
                                    </div>
                                </div> -->
                            </div>
                            <div style="margin-left:-0.7rem !important; margin-right:-0.7rem !important;">
                                <div class="row border-bottom pb-2 mb-3"></div>
                            </div>
                            <div class="row">
                                <div class="col-10 row">
                                    <div class="col-8">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <eprx-input [LabelText]="'Last Name'" [PlaceHolder]="'Last Name'" [ControlName]="'PkLastName'" [FormGroupName]="pickUpDet"   [RxSelectId]="'pickUpLastName'"
                                                [ReadOnly]="!pickUpDet.value.IsPickedUp" [Title]="this.rxDetails['Patient']['lastname'] | uppercase">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                                <eprx-input [LabelText]="'First Name'" [PlaceHolder]="'First Name'" [ControlName]="'PkFirstName'" [FormGroupName]="pickUpDet"
                                                [ReadOnly]="!pickUpDet?.value?.IsPickedUp"  [Title]="rxDetails['Patient']['firstname'] | uppercase">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <eprx-date-picker *ngIf="pickUpDet?.value?.IsPickedUp" [AutoFocus]="true"  [LabelText]="'Picked Up Date'" [IsRequired]="true" [FormGroupInvalid]="formGroupInvalid" [InputErrors]="formGroupInvalid" (TriggerOnDateSelect)="getPickupDate(true)"
                                                    [ErrorDefs]="{required: 'Required'}"  [PlaceHolder]="'Picked Up Date'" [ControlName]="'ExpDtTm'"
                                                    [FormGroupName]="pickUpDet" [MinDateString]="pickupMinDate" (TriggerOnDateSelect)="withChangeInDateSetTime()">
                                                </eprx-date-picker>
                            
                                                <eprx-date-picker *ngIf="!pickUpDet?.value?.IsPickedUp" [AutoFocus]="true"  [LabelText]="'Picked Up Date'"  [FormGroupInvalid]="formGroupInvalid" [InputErrors]="formGroupInvalid"
                                                     [PlaceHolder]="'Picked Up Date'" [ControlName]="'ExpDtTm'" [FormGroupName]="pickUpDet" [MarkAsTouched]="pickUpDet?.get('ExpDtTm')?.touched" [MinDateString]="pickupMinDate" [IsDisabled] ="disableDateField">
                                                </eprx-date-picker>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                 <label>Pickup Time</label>
                                                 <div class="input-group" [formGroup]="pickUpDet">
                                                     <input type="text" class="form-control" id="pickUpTabTime"  placeholder="Pickup Time" formControlName="ExpDate" (keydown.tab)="checkPikcUpTimeValidation()" >
                                                     <div class="input-group-append cursor_pointer pickup_rx "  (click)="getPickTimeForDisplay()">
                                                       <span class="input-group-text text-white">{{pickTimeForDisplay | uppercase}}</span>
                                                     </div>
                                                   </div>
                                                   <div *ngIf="isPickTimeValid" class="text-danger">
                                                       Please Enter Valid time format
                                                   </div>
                                             </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <label>Picked Up From
                                                    &nbsp;
                                                    <span  class="d-inline info_display left_10rem" [ngbTooltip]="instructToolTip" container="body" tooltipClass="pickup--tooltip"  triggers="click" [autoClose]="true" placement="right">𝒊</span>
                                                    <ng-template #instructToolTip>
                                                    <div class="text-left">
                                                        Pick up details and signatures for multiple IOU records can be captured only by performing DPV or from POS and cannot be captured by Manual Pick Up.
                                                    </div>
                                                    </ng-template>
                                                </label>
                                                <div>{{pickUpDet?.value?.PickUpFromId === 1 ? 'POS' : pickUpDet?.value?.PickUpFromId === 2 ? 'Delivery' : pickUpDet?.value?.PickUpFromId === 3 ? 'Delivery on Demand' : pickUpDet?.value?.PickUpFromId === 4 ? 'Pharmacy Pickup' : pickUpDet?.value?.PickUpFromId === 4 ? 'Prime ESC/Manual Entry' :(pickUpDet?.value?.IsPickedUp && !pickUpDet?.value?.PickUpFromId) ? 'Manual' : ''}}</div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                <eprx-input [LabelText]="'POS Cashier ID'" [PlaceHolder]="'POS Cashier ID'" [IsDisabled]="true" [HasControl]="false" [Title]="pickUpDet?.value?.POSUserId"
                                                    [ReadOnly]='true' [InputValue]="pickUpDet?.value?.POSUserId">
                                                </eprx-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                                <eprx-select [LabelText]="'Relation'" [PlaceHolder]="'Relation'" [ControlName]="'RelationCatId'" [FormGroupName]="pickUpDet"
                                                    [HasMultiple]="false" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''"
                                                    [Show2Values]="true" [List]="SystemData?.RelationCat ? (SystemData?.RelationCat | filterRelation:'Pick') : []"
                                                    [IsDisabled]="!pickUpDet?.value?.IsPickedUp" [secondCntrlN]="'RelRemarks'" [bindScnCntrlV]="'Remarks'">
                                                </eprx-select>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ">
                                                <eprx-input [LabelText]="'Pick Up ID'" [PlaceHolder]="'Pick Up ID'" [ControlName]="'PkIdNum'" [FormGroupName]="pickUpDet"
                                                [ReadOnly]="!pickUpDet?.value?.IsPickedUp">
                                                </eprx-input>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <eprx-select [LabelText]="'Pick Up ID Qualifier'" [PlaceHolder]="'Pick Up ID Qualifier'" [ControlName]="'PkIdProofId'" [FormGroupName]="pickUpDet" [HasMultiple]="false"
                                                    [Show2Values]="true" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''"
                                                    [List]="SystemData.IdProof" [IsDisabled]="!pickUpDet?.value?.IsPickedUp" [secondCntrlN]="'IdProof'" [bindScnCntrlV]="'Remarks'">
                                                </eprx-select>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                                                <eprx-input [LabelText]="'Issuing State'" [PlaceHolder]="'Issuing State'" [ControlName]="'PkIdIssuer'" [FormGroupName]="pickUpDet" [ReadOnly]="!pickUpDet?.value?.IsPickedUp">
                                                </eprx-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-3 col-sm-3 col-xs-3">
                                                <eprx-select [LabelText]="'Drop Pick Qualifier'"  [RxSelectId]="'DropPickQ'" [PlaceHolder]="'Drop Pick Qualifier'" [ControlName]="'DropPickQ'" [FormGroupName]="pickUpDet" [HasMultiple]="false"
                                                [TabIndex]="10"
                                                    [Show2Values]="true" [BindLabel]="'Code'" [BindLabel2]="'Remarks'" [BindValue]="'Id'" [LabelForId]="''"
                                                    [List]="SystemData.DroppingOff_PickingUp" [secondCntrlN]="'DropPickQulifierName'" [bindScnCntrlV]="'Remarks'">
                                                </eprx-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <label class="control-label">Signature</label>
                                    <!-- <img [src]="'assets/Qz-tray_action.png'" class="height_174px width_300px" > -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #PICKUPDELETECNFRM let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="c('close')">
        <h4 header> Delete Pickup Detail</h4>
        <span body> Do you want to delete the Pickup detail record ? </span>
        <button footer ngbAutofocus type="submit" class="hotkey_primary"  (click)="c('close')" (click)="resetPickupRx(true)" appShortcutKey
            InputKey="y"><b>Y</b> YES
        </button>
        <button footer ngbAutofocus type="submit" class="hotkey_primary" (click)="resetPickupRx(false);c('close')" appShortcutKey InputKey="n">
            <b>N</b> No
        </button>
    </app-custom-modal>
</ng-template>

<app-patient-sign-record *ngIf="showPatientSign" [rxNumber]="rxNumber" [RPH]="RPH" [PatientInfo]="rxPatientModel" [SignId]="signId" [SigType]="false" [SignatureData]="signatureData" [RefillId]="refillId" (IsPopupClosed)="showPatientSign=false"></app-patient-sign-record>
