import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { AlertService, CommonService, DrugService, ErxService, NotesLogService, PatPreDrugModalService, RxTransferService, UserService } from 'src/app/services';
import { CommonUtil } from 'src/app/utils';
import { PatientInfoUtil } from 'src/app/utils/patient-info.util';
import {  Notes, PharmacyAddress,  RxTransferConfirm } from 'src/app/models/transfer-rx.models';
import { NotesMainCat } from 'src/app/models/notes.enum';
import { SearchNoteParams, SystemData } from 'src/app/models';
import * as moment from 'moment';
import { StorageService } from 'src/app/services/storage.service';
import { TranferableInformationRxsComponent } from '../tranferable-information-rxs/tranferable-information-rxs.component';

@Component({
  selector: 'app-transfer-in',
  templateUrl: './transfer-in.component.html',
  styleUrls: ['./transfer-in.component.scss']
})
export class TransferInComponent implements OnInit {
  eRxModelFG: FormGroup;
  @ViewChild("NonandTransferrableRxs", { static: true })
   NonandTransferrableRxs: any;
   isFullscreenMode: boolean = false;
  @Output() ClosePopUpModal = new EventEmitter();
  @ViewChild("RejectRequest", { static: true })
  RejectRequest: any;
  @ViewChild("TransferRx", { static: true })
  TransferRx: any;
  modelRef: any;
  fullScreenModelRef: any;
  rxsList: any;
  cancelModelRef: any;
  msgConfig = MsgConfig;
  selectedPatientDetails: any;
  selectedTransferTypeDetails: any;
  selectedDruginformation: any;
  awaitInfo: any;
  unsubscribe$: Subject<void> = new Subject();
  selectedRxList: any = [];
  @Input() IsfrmPatHis;
  @Input() currentDetails;
  @Input() SelectedRecords: any;
  @Input() IsFromProcess: any;
  selectedReason: any;
  systemData: SystemData;
 
  @Input()
  set DefaultTransferType(type : any){
    if(type)
    {
      this.selectedTransferTypeDetails = "All";
      this.eRxModelFG.controls['IsTransferIn'].patchValue(true);
      this.eRxModelFG.controls['transferType'].patchValue("3");
    }
  }
  @Input()
  set PatientDetails(patient : any){
     this.selectedPatientDetails = patient;
  }

  @Input()
  set AwaitInfo(awaitInf: any) {
    if (awaitInf) {
      this.awaitInfo = awaitInf;
      if (awaitInf?.Drug)
      {
        this.selectedDruginformation = awaitInf?.Drug?.result[0];
        this.eRxModelFG.controls['drugID'].patchValue(awaitInf?.Drug?.result[0].id);
        this.eRxModelFG.controls['IsTransferIn'].patchValue(false);
        this.eRxModelFG.controls['transferType'].patchValue("2");
      }
      else
       {
        this.selectedTransferTypeDetails = "All";
        this.eRxModelFG.controls['IsTransferIn'].patchValue(true);
        this.eRxModelFG.controls['transferType'].patchValue("3");
       }
    }
  }

  @Input()
  set DrugInformationFrmExtHist(drugInfo : any){
    if(drugInfo){
      // TransType 2 for Drug && 3 for All
      this.selectedDruginformation = drugInfo;
      this.eRxModelFG.controls['drugID'].patchValue(drugInfo?.id);
        this.eRxModelFG.controls['IsTransferIn'].patchValue(false);
      this.eRxModelFG.controls['transferType'].patchValue("2");
    }
  }
  get RxTransferConfirm(): FormGroup {
    return this.eRxModelFG.get("rxTransferConfirm") as FormGroup;
}
  constructor(private _fb:FormBuilder, 
              private _cdr: ChangeDetectorRef,
              private _eRxService:ErxService,
              private _alrtSev: AlertService,
              private _patUtils: PatientInfoUtil,
              private _userService: UserService,
              private _ppdiModalSer: PatPreDrugModalService,
              private _commonUtil: CommonUtil,
              private _commonServ:CommonService,
              private _modalService:NgbModal,
              public _storageService: StorageService,
              private _notesLogSvc: NotesLogService,
               private _drugServ: DrugService) {
    this.eRxModelFG = this._fb.group({
      patientID: new FormControl(null, [Validators.required]),
      destinationPharmacyID: new FormControl(null, [Validators.required]),
          eRxOpID:0,
          RefillorRx: this._commonServ.getSetttingValue("PharmacySettings", "ALLOW_REFILL_TRANS_OUT") === "1" ? true : false,
          targetPharmacy: this._fb.group({
            NCPDPID:  null,
            Npi: new FormControl(null, [Validators.required]),
            BusinessName:  null,
            Specialty: null,
            Address: this._fb.group(new PharmacyAddress()),
            PhoneNumber: []
          }),
          Notes:  this._fb.group(new Notes()),
          MessageType:  null,
          rxTransferConfirm:this._fb.group(new RxTransferConfirm()),
          rxFill : null,
          MessageID: null,
          RelatesToMessageID:  null,
          eRxPrescParams: [],
          prescriptionList: [],
          token:  null,
          drugID:   null,
          IsTransferIn:  false,
          transferType: null,
          drugItem:   null
    });
    this._commonServ.systemData$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
        if (resp) {
            this.systemData = resp;
        }
    });

   }

  ngOnInit() {
    this.eRxModelFG.controls['destinationPharmacyID'].patchValue(
      this._userService.getToken("UniquePharmacyId")
    )
    const microSvcUrlToken = sessionStorage.getItem("CmnMicroSvcToken") ? JSON.parse(this._commonServ.DecryptData(sessionStorage.getItem("CmnMicroSvcToken"))) : "";
    this.eRxModelFG.controls['token'].patchValue(
      microSvcUrlToken
    )
   if(this.SelectedRecords?.length) {
    this.eRxModelFG.controls['transferType'].patchValue("2");
    const isSamePatients = this.SelectedRecords.every(patient => patient.PatientId === this.SelectedRecords[0].PatientId);
    if (this.SelectedRecords?.length  && !isSamePatients) { 
      // selecting multiple records with different patients
      this.ClosePopUpModal.emit([]);
      this._alrtSev.error("Transfer requests can only be initiated for records belonging to the same patient. Please ensure all selected records belong to the same patient to proceed.");
      return;
    } else {
      //for open pop up
        this.openPopup();
        // If patient is not found and selected record count as one
         if(this.SelectedRecords?.length) {
            if (!this.SelectedRecords[0]["PatientId"]) {
              this.addNewPatient();
          }  else
          {
            this.getRxPatientDetailsById(this.SelectedRecords[0].PatientId);
            if(this.SelectedRecords[0].DrugId)
              this.getRxDrugDetailsById(this.SelectedRecords[0].DrugId);
            else {
              this.selectedDruginformation = {
                drugname: this.SelectedRecords[0]["Drug Name"],
                drugclass: this.SelectedRecords[0].DrugClass,
                ndc: this.SelectedRecords[0].DrugNDC,
                DrugDescription: this.SelectedRecords[0].DrugDescription
              }
              this.eRxModelFG.controls['eRxPrescParams'].patchValue([
              {
                rxTransferInOutParam: null,
                Drug : {
                  DRUGDETAIL: [{
                    DRUGNAME: this.SelectedRecords[0]["Drug Name"],
                    DRUGNDC: this.SelectedRecords[0]["DrugNDC"],
                    DRUGDESCRIPTION: this.SelectedRecords[0]["DrugDescription"]
                  }]
                }
              }
              ])
            }
            this.SelectedRecords.shift();
          }
        
        }
    }
   } else {
     // open pop up if no records selected
      this.openPopup();
   }
  }

  openPopup() {
    this.modelRef = this._modalService.open(this.TransferRx, {centered: true,
      backdrop: false, keyboard: false ,windowClass: "max-modal-lg-size transfer-in"});
  }

  addNewPatient() {
    this._ppdiModalSer._patPreDrugModal$.next("patient");
    const modalRef = this._ppdiModalSer.modalInstanceRef;
    const patFG:FormGroup = this._patUtils.generatePatientInfoFG();
   this._patUtils.patchPatientFrmErx(this.SelectedRecords[0].totalItem, patFG);
    modalRef.componentInstance.addPatientModel = patFG;
    modalRef.componentInstance.FrmErx = true;
    modalRef.componentInstance.erxData =  this.SelectedRecords[0].totalItem;
    modalRef.componentInstance.ClosePatientModal
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async resp => {
        modalRef.close();
        if(resp.PatientId)
        {
          this.getRxPatientDetailsById(resp.PatientId);
          this.getRxDrugDetailsById(this.SelectedRecords[0].DrugId);
          this.SelectedRecords.shift();
        }
        this.SelectedRecords.shift();
        this._ppdiModalSer._patPreDrugModal$.next(null);
    });
  }

  getRxDrugDetailsById(drugId) {
    this._commonServ.getRxDrugDetailsById(drugId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp) => {
        if(resp)
          this.selectedDrugDetails(resp);
    });
  }
  
  async getRxPatientDetailsById(PatientId) {
    const patientData = await this._commonServ.getRxPatientDetailsById(PatientId).toPromise();
    if(patientData) 
    {
      this.selectedPatientDetails = patientData;
    }
  }

  selectedDrugDetails(drug: any) {
    this.selectedDruginformation = drug ? drug: null;
      if(drug) {
        this.eRxModelFG.controls['drugItem'].patchValue(drug);
        this.eRxModelFG.controls['eRxPrescParams'].patchValue([
          {
            rxTransferInOutParam: null,
            Drug : {
              DRUGDETAIL: [{
                DRUGNAME: drug.drugname,
                DRUGNDC: drug.drugcode,
                DRUGDESCRIPTION: drug.drugdescription?.length ? drug.drugdescription[0]["descrip"] : drug.drugname
              }]
            }
          }
        ])
      }
       this.eRxModelFG.controls['transferType'].patchValue(drug ? "2" : "3");
  }

  closePopup() {
    if(this.modelRef) {
      this.modelRef.close();
      this.modelRef = null;
    }
    this.awaitInfo = null;
    this._storageService.removedSessionStorage("fromDrugGrid");
      this.ClosePopUpModal.emit(this.SelectedRecords);
  }

  selectedTransTypeDetails(details: any) {
      this._cdr.detectChanges();
      this.selectedTransferTypeDetails = details;
      if(details && details !== "All")
      {
        this.eRxModelFG.controls['drugID'].patchValue(details);
        this.eRxModelFG.controls['IsTransferIn'].patchValue(false);
      }
      else if(details == "All")
      {
        this.eRxModelFG.controls['IsTransferIn'].patchValue(true);
      }
  }

  selectedPatientID(patientId) {
      this.eRxModelFG.controls['patientID'].patchValue(patientId ? patientId : null);
  }

  transferSingleNotes(event) {
        event["PopupScreenCodes"] = event["PopupScreenCodes"]?.length ? event["PopupScreenCodes"]?.toString() : null;
        event["IsfromTransferIn"]   = true;
        event["IsShownAsPopup"]  = event["PopupScreenCodes"]?.length ? true : false;
        event["Name"]  = event["Name"]?.length ? event["Name"].trim() : null;
        this.eRxModelFG.controls['Notes'].patchValue(event);
  }

  async rxTransfer(type) {
    this.RxTransferConfirm.controls['ConfirmResponse'].patchValue(type);
    this.eRxModelFG.controls['eRxOpID'].patchValue(this.currentDetails?.ErxOperationId);
    await this.addNotesToRequest(this.eRxModelFG.value['Notes']);
    if (type === 'InReq') {
      if (this.eRxModelFG.valid && (this.eRxModelFG.value['drugID'] || this.eRxModelFG.value['IsTransferIn'])) {
        this._eRxService.sendTransferInReq(this.eRxModelFG.value, this._commonUtil.getErxVersion()).subscribe((resp: any) => {
          if (resp?.Result) {
            this._alrtSev.success(MsgConfig.Transfer_In_Req);
          } else if (resp?.Message && !resp?.Result) {
            this._alrtSev.error(resp.Message);
          }
          this.closePopup();
        });
      }
    } else if (type === 'Denied') {
      this.cancelModelRef = this._modalService.open(this.RejectRequest, { keyboard: false, centered: true });
    } else {
      this.sendResponseToDestination();
    }
  }
  async sendResponseToDestination(isType?) {
    if(isType ||  this.rxsList?.length)
    {
      await this.patchTheSeletcedRxs();
      this._eRxService.setRxsForTransferInReqProcess(this.eRxModelFG.value).subscribe((resp: any) => {
        if(resp) {
          this.closePopup();
           this._alrtSev.success(MsgConfig.Transfer_In_Req_Process);
        }
      });
    } else {
      this._alrtSev.error(MsgConfig.Unselect_Warn);
    }
  }
  addNotesToRequest(pharmacistNotes) {
    if(pharmacistNotes.xNote && (!pharmacistNotes.NoteCatId && pharmacistNotes.Name)) {
    const notesCatList: any = this._commonServ.notesCatList$["source"]["value"];
    const mainCat = notesCatList.find((val: any) =>  (val.CategoryName.toUpperCase() === NotesMainCat.eRx.toUpperCase()) );
    this._drugServ.addoRUpdateNoteCategory(
      {
        Name: pharmacistNotes.Name,
        NoteCategoryId: mainCat?.CategoryId,
        IsActive: true,
        CreatedDtTm: null,
        Id: 0
      },
      "SAVE"
    ).subscribe((resp: number) => {
      if (resp) {
        this.getNotesSubacatList({
          Name: pharmacistNotes.Name,
          pharmacistNotes:pharmacistNotes
        });
      }
    });
  }
  }
  getNotesSubacatList(catObj?: any) {
    this._notesLogSvc
    .getNotesLog(
      NotesMainCat.eRx,0,
      MsgConfig.Default_Date_Notes,
      moment(Date.now()).format(MsgConfig.DATE_FORMAT_WITH_EIPHEN),
        new SearchNoteParams()
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp: any) => {
        if(resp)
        {
            if(catObj?.Name) {
              let manualSubCat = resp?.NoteCat?.find((item: any) => (catObj.Name.toUpperCase() === item?.Name?.toUpperCase()));
              if(catObj?.pharmacistNotes)
              {
                  catObj.pharmacistNotes.NoteCatId  =  manualSubCat ? manualSubCat.Id : 0, 
                   this.eRxModelFG.controls['Notes'].patchValue(catObj.pharmacistNotes);
              }
            }
        }
    });
  }
  selectedToPharmacy(selectedPharmacy) {
      let phones: any = [];
      if(selectedPharmacy?.Phone) 
        phones.push({Type : 'Phone' , Number: selectedPharmacy.Phone});
      if(selectedPharmacy?.Fax) 
        phones.push({Type : 'Fax' , Number: selectedPharmacy.Fax});
      this.eRxModelFG.controls['targetPharmacy'].patchValue({
        NCPDPID : selectedPharmacy?.NCPDPID,
        Npi : selectedPharmacy?.NPINum,
        BusinessName: selectedPharmacy?.PharmName ? selectedPharmacy?.PharmName : null,
        PhoneNumbers: phones.length ? phones : [],
        Specialty : null,
        Address : {
          AddressLine1: selectedPharmacy?.AddressLine1 ? selectedPharmacy.AddressLine1 : null,
          AddressLine2: selectedPharmacy?.AddressLine2 ? selectedPharmacy.AddressLine2 : null,
          City: selectedPharmacy ? selectedPharmacy.CityName : null,
          State:  selectedPharmacy ? selectedPharmacy.StateName : null,
          ZipCode: selectedPharmacy ? selectedPharmacy.ZipCode : null,
          CountryCode: selectedPharmacy ? selectedPharmacy.CountryCode : null
        }
      })
      const transferInReq = document.getElementById("TransferInRequest")
       if(transferInReq)
        transferInReq.focus();
  }
  cancelRxReasonSelected(selectedRea) {
    if (selectedRea) {
      this.RxTransferConfirm.patchValue({
        ResponseReasonCode: selectedRea?.Code,
        ResponseReason: selectedRea?.Description
      });
    }
  }
  patchTheSeletcedRxs() {
    const eRxPrescParam: any  = [];
    if(this.rxsList?.length) {
      this.rxsList.map((item: any) => {
      eRxPrescParam.push({
        RxId: item.Prescription.PrescNum,
        IsTransfferable: item?.IsTransfferable
      });
    });
    this.eRxModelFG.controls["eRxPrescParams"].patchValue(eRxPrescParam);
}
  }
  acceptOrRejectRequest(isType: string) {
            if(this.cancelModelRef) {
              this.cancelModelRef.close();
              this.cancelModelRef = null;
            }
      this.sendResponseToDestination('Denied');
  }
  getTheSelectedRxs(rxsList) {
      this.rxsList = rxsList;
      console.log(this.rxsList , "Fdg");
  }
  showFullscreenMode() {
    this.isFullscreenMode = true;
    this.fullScreenModelRef = this._modalService.open(this.NonandTransferrableRxs, {centered: true, backdrop: false, windowClass: "max-modal-fullScreen"}); 
  }
  closeRxPopup() {
        if(this.fullScreenModelRef) {
          this.fullScreenModelRef.close();
          this.fullScreenModelRef = null;
        }
  }
}
